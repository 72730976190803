import { Component, Input, OnInit } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { IApiInvestigation, IApiInvestigationRole, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { InvestigationRoleService, UserService } from 'src/app/shared/services';
import { InvestigationRestrictionService } from 'src/app/shared/services/investigation';

@Component({
  selector: 'app-investigation-restriction-modal',
  templateUrl: './investigation-restriction-modal.component.html',
  styleUrls: ['./investigation-restriction-modal.component.scss']
})
export class InvestigationRestrictionModalComponent extends DialogContentBase implements OnInit {
  @Input() public investigation: IApiInvestigation;

  public roles: IApiInvestigationRole[] = [];
  public _users: any = [];
  public users: any = [];
  public selectedRoles: IApiInvestigationRole;
  public selectedUsers: any = [];
  constructor(
    private notifications: NotificationsService,
    private investigationRoleService: InvestigationRoleService,
    private loader: LoaderService,
    public dialogRef: DialogRef,
    private userService: UserService,
    private investigationRestrictionService: InvestigationRestrictionService
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.investigationRoleService.investigationRoles.subscribe(roles => {
      roles = roles.sort((a, b) => a.title.localeCompare(b.title));
      this.roles = roles;
      this.loader.show$(this.userService.get([{ type: IApiUserFilterType.ViewUserWithPosition, value: "true" }], { limit: -1 })).subscribe(users => {
        if (users?.edges?.length) {
          this.users = users.edges
            .map(edge => edge?.node)
            .sort((a, b) => a.firstName.localeCompare(b.firstName))
            .map(user => ({
              ...user,
              title: user.Positions?.length ? user.Positions[0].title : "",
              fullName: `${user.firstName} ${user.lastName}`
            }));
          const groupedPositions: GroupResult[] = groupBy(this.users, [{ field: "title" }]);
          this.users = groupedPositions;
          this._users = this.users;
        } else {
          this.users = [];
        }
      })
    })
  }

  public close = (result = false) => {
    this.dialogRef.close(result);
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

  public save() {
    if (this.selectedUsers?.length) {
      const input = {
        restrictions: this.selectedUsers.map(id => ({ UserId: id, InvestigationId: this.investigation.id })),
      };
      this.loader.show$(this.investigationRestrictionService.add(input)).pipe(
        this.notifications.snackbarPipe("Restrictions added successfully"),
        this.notifications.snackbarErrorPipe("Error adding restrictions; please try again")
      ).subscribe(() => {
        this.close(true);
      });
    }
  }
}
