import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NefcoOfficeService } from 'src/app/shared/services';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { IApiNefcoOffice, IApiNefcoOfficeFilterType } from "../../modules/graphql/types/types";
import { unwrapConnection } from '../../rxjs.pipes';
import { LoaderService } from '../../modules/loader/loader.service';
import _ from 'lodash';

@UntilDestroy()
@Component({
  selector: 'app-nefco-office-multi-select',
  templateUrl: './nefco-office-multi-select.component.html',
  styleUrls: ['./nefco-office-multi-select.component.scss']
})
export class NefcoOfficeMultiSelectComponent implements OnInit, OnChanges {

  @Input() selectedId: Array<string> = [];
  @Output() selectionChanged = new EventEmitter<string[] | null>();

  public selectedValue = [];
  public nefcoOffices: IApiNefcoOffice[];

  constructor(
    private nefcoOfficeService: NefcoOfficeService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.nefcoOfficeService
      .get([
        {
          type: IApiNefcoOfficeFilterType.ViewOnlyNameAndActive,
          value: "true",
        },
        { type: IApiNefcoOfficeFilterType.IsActive, value: "true" },
      ])
      .pipe(untilDestroyed(this), unwrapConnection())
      .subscribe((office) => (this.nefcoOffices = office));
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!_.isEqualWith(this.selectedValue, changes?.selectedId?.currentValue)) {
      this.selectedId = changes?.selectedId?.currentValue || [];
        if (this.selectedId?.length) {
          this.selectedValue = this.selectedId
        } else {
          this.selectedValue = [];
        }
    }
  }

  public searchSelect($event) {
    this.selectedId = $event;
    this.selectionChanged.emit(this.selectedId);
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }

}
