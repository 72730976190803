import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapComponent } from './google-map.component';
import { GoogleMapService } from './google-map.service';
import { GeoGoogleMapsComponent } from '../geo-google-maps/geo-google-maps.component';

@NgModule({
  declarations: [GoogleMapComponent, GeoGoogleMapsComponent],
  imports: [
    CommonModule,
  ],
  providers: [GoogleMapService],
  exports: [
    GoogleMapComponent,
    GeoGoogleMapsComponent
  ]
})
export class GoogleMapsModule {static forRoot(): ModuleWithProviders<GoogleMapsModule> {
  return {
    ngModule: GoogleMapsModule,
    providers: [
      GoogleMapService
    ]
  };
} }
