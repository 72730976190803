import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NefcoOfficeService } from 'src/app/shared/services';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { IApiNefcoOffice, IApiNefcoOfficeFilterType } from "../../modules/graphql/types/types";
import { unwrapConnection } from '../../rxjs.pipes';
import { LoaderService } from '../../modules/loader/loader.service';

@UntilDestroy()
@Component({
  selector: 'app-nefco-office-select',
  templateUrl: './nefco-office-select.component.html',
  styleUrls: ['./nefco-office-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NefcoOfficeSelectComponent),
      multi: true
    }
  ]
})
export class NefcoOfficeSelectComponent implements OnInit, ControlValueAccessor {

  @Input() nefcoOfficeId = '';
  @Input() required = true;
  public nefcoOffices: IApiNefcoOffice[];

  constructor(
    private nefcoOfficeService: NefcoOfficeService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.loaderService
      .show$(
        this.nefcoOfficeService.get([
          { type: IApiNefcoOfficeFilterType.ViewOnlyNameAndActive, value: "true" },
          { type: IApiNefcoOfficeFilterType.IsActive, value: "true" }
        ])
      )
      .pipe(untilDestroyed(this), unwrapConnection())
      .subscribe((office) => (this.nefcoOffices = office));
  }

  public handleSelectionChange(event) {
    this.nefcoOfficeId = event;
    this._propagateChange(this.nefcoOfficeId);
  }

  private _propagateChange = (_: any) => { };

  // Control Value accessor members
  public writeValue(value: any): void {
    if (value !== undefined) {
      this.nefcoOfficeId = value;
    }
  }

  public registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  public registerOnTouched(fn: any): void { }
  public setDisabledState?(isDisabled: boolean): void { }

}
