import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { IApiAddExpenseInput, IApiContactRoleFilterType, IApiDocument, IApiExpense, IApiInvestigationFilter, IApiInvestigationFilterType, IApiInvestigationOrderBy, IApiInvestigationStaff, IApiInvestigationStatusNames, IApiInvoice, IApiInvoiceFilterType, IApiPageInfo, IApiRequestFilter, IApiRiskType, IApiService, IApiServiceStatus, IApiSubService, IApiTimeEntry, IApiUserFilterType } from '../../../../shared/modules/graphql/types/types';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { IPriority } from 'src/app/shared/interfaces/priority.interfaces';
import { InvestigationStatuses, InvestigationRiskTypes, InvestigationPriorities, InvestigationServiceStatuses } from 'src/app/shared/modules/graphql/enums/investigation.enums';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { AuthService, ContactRoleService, ExpenseItemService, ExpenseService, InvestigationService, InvestigationStatusService, InvoiceService, RiskTypesService, ServiceStatusService, SubServiceService } from "src/app/shared/services";
import { NotificationsService } from "src/app/shared/modules/notifications/notifications.service";
import { IApiInvestigation, IApiUser } from '../../../../shared/modules/graphql/types/types';
import { InvestigationDataSource } from 'src/app/shared/services/investigation/investigation.datasource';
import { DocumentSnapshotPipe } from "../../../../shared/pipes/document-snapshot.pipe";
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { CalendarProfileSidebarComponent } from 'src/app/shared/components';
import { DocumentCategories } from 'src/app/shared/modules/graphql/constants/document.constants';
import { PermissionCategories, PermissionActions } from 'src/app/shared/modules/graphql/constants/permission.constants';
import { EnforcePermissionDirective, IEnforcePermissionConfig, EnforcePermissionDisplayModes } from 'src/app/shared/directives/enforce-permission.directive';
import { investigationStatusCreate } from "src/app/shared/helpers/auth-config/investigations.config";
import { forkJoin } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IApiInvestigationRoleNames } from 'src/app/shared/modules/graphql/types/types';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { ViewportScroller } from '@angular/common';
import { InvestigationDocumentsSidebarComponent } from 'src/app/components/shared/investigations';
import { getRiskTypeIcon } from 'src/app/shared/helpers/reactive-form.class';
import { SortDescriptor } from '@progress/kendo-data-query';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';


export enum InvestigationStatusNames {
  'PENDING' = 'Pending',
  'ASSIGNED' = 'Assigned',
  'SCHEDULED' = 'Scheduled',
  'IN_PROGRESS' = 'In Progress',
  'READY_TO_BILL' = 'Ready To Bill',
  'HOLD_FOR_BILLING' = 'Hold For Billing',
  'ONGOING' = 'On Going',
  'ONGOING_PENDING_SECOND_SCENE_EXAM' = 'Ongoing Pending Second Scene Exam',
  'ONGOING_PENDING_THIRD_SCENE_EXAM' = 'Ongoing Pending Third Scene Exam',
  'ONGOING_PENDING_EVIDENCE_EXAM' = 'Ongoing Pending Evidence Exam',
  'ONGOING_PENDING_LITIGATION' = 'Ongoing Pending Litigation',
  'ONGOING_PENDING_DISPOSITION' = 'Ongoing Pending Disposition',
  'ONGOING_PENDING_CRIMINAL_TRIAL' = 'Ongoing Pending Criminal Trial',
  'ONGOING_PENDING_TRIAL' = 'Ongoing Pending Trial',
  'ONGOING_PENDING_SCHEDULING' = 'Ongoing Pending Scheduling',
  'READY_TO_CLOSE' = 'Ready To Close',
  'CLOSED' = 'Closed',
  'CANCELLED' = 'Cancelled'
}

enum BillingStatuses {
  HasReport = "HasReport",
  NoReport = "NoReport",
  Unbilled = "Unbilled"
}

// todo can columns include matching enum types from OrderBy?
export enum Columns {
  createdAt = "createdAt",
  nefcoNumber = "nefcoNumber",
  priority = "priority",
  lossDate = "lossDate",
  riskType = "riskType",
  serviceStatus = "serviceStatus",
  status = "status",
  followUp = "followUp",
  investigationLocation = "investigationLocation",
  contact = "contact",
  billTo = "billTo",
  client = "client",
  assigned = "assigned",
  progress = "progress",
  actions = "actions",
  review = "review",
  contactRole = "contactRole",
  policyClaimNumbers = "policyClaimNumbers",
  comments = "comments",
  editor = "editor"
}

// todo table types should include matching enum types for investigation status
export enum TableType {
  readyToBill = "readyToBill",
  pending = "pending",
  assigned = "assigned",
  progress = "progress",
  ongoing = "ongoing",
  contact = "contact",
  investigations = "investigations",
  user = "user",
  review = "review",
}

export interface IInvestigationTableConfig {
  type?: TableType;
  displayedColumns?: Columns[];
  viewType?: IApiInvestigationStatusNames;
  startWithCount?: boolean;
  contactId?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-dashboard-assigned-table',
  templateUrl: './dashboard-assigned-table.component.html',
  styleUrls: ['./dashboard-assigned-table.component.scss'],
  providers: [DocumentSnapshotPipe],
  // Handles the display of expandable table rows
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class DashboardAssignedTableComponent implements OnInit, AfterViewInit {
  public authConfig = {
    investigationStatusCreate
  };

  private previousFilters: any[] = [];
  private _filters: IApiInvestigationFilter[] = [];
  public filterTypes = IApiInvestigationFilterType;

  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }

  public investigationStatusNames = InvestigationStatusNames;

  private _config: IInvestigationTableConfig = null;
  public get config() {
    return this._config;
  }

  @Input() public set config(val: IInvestigationTableConfig) {
    this._config = val;
  }
  private _count: boolean;
  @Input() public set count(val: boolean) {
    this._count = val;
  }
  public get count() {
    return this._count;
  }
  @Output() total = new EventEmitter<number>();
  @ViewChild(EnforcePermissionDirective) perms: EnforcePermissionDirective;

  private _dataSource: InvestigationDataSource;
  public set dataSource(val) {
    this._dataSource = val;
  }
  public get dataSource() {
    return this._dataSource;
  }

  public investigationServices = []; // Add graphql ENUM here for investigation services - used for checkboxes

  public investigations: IApiInvestigation[] = [];

  public evidenceColumns = ["date", "description", "enteredBy", "rate", "schedule", "billable"];
  public billingColumns = ["date", "staff", "item", "purpose", "nonBillableQuantity", "billableQuantity", "description", "paid", "billable"];
  public invoiceColumns = ["date", "desc", "amount", "qbErrors", "qbSubmitted", "edit", "invoice_num"];

  // todo: is this used?
  public staffMember = "";
  public searchValue = "";
  public InvestigationStatuses = InvestigationStatuses;
  // todo: is this used?
  public InvestigationRiskTypes = InvestigationRiskTypes;
  public InvestigationServiceStatuses = InvestigationServiceStatuses;
  public InvestigationPriorities = InvestigationPriorities;
  public users: IApiUser[] = [];
  public riskTypes: IApiRiskType[] = [];
  public investigationPriorities: IPriority[];
  public pageInfo: IApiPageInfo;
  public pageSize = 25;
  public nextCursor: any = null;
  public prevCursor: any = null;
  public readyToBillTotalResults = 0;
  public sortOrder = SortOrder.ASC;
  public orderBy = IApiInvestigationOrderBy.CreatedAt;
  public type = TableType;
  public contactRoles: string[] = [];

  public quickBookErrors: string[] = [];

  public billingStatusOpts = BillingStatuses;

  public billingStatus: BillingStatuses = BillingStatuses.HasReport;

  public status: any = null;

  public orderByOptions = IApiInvestigationOrderBy;

  public content = false;
  public loaded = false;
  public primaryInvestigator = false;
  public userId = null;

  public serviceStatuses: IApiServiceStatus[] = [];

  public invoices: IApiInvoice[] = [];
  public apiUserFilterType;

  public get displayedColumns() {
    if (!this.config?.displayedColumns) return [];
    return this.config.displayedColumns;
  }

  public get tableType(): typeof TableType {
    return TableType;
  }

  // For creating an expense item for evidence
  private _evidenceExpenseItemId: string;
  private get evidenceExpenseItemId() {
    return this._evidenceExpenseItemId;
  }

  // Get evidence expenses if they exist
  public get evidenceExpenses(): IApiExpense[] {
    return this.expandedElement?.Expenses.sort((a, b) => (a.expenseDate > b.expenseDate) ? 1 : -1).filter(({ ExpenseItem: { id } }) => id === this.evidenceExpenseItemId);
  }

  // This is for rows with expandable data
  private _expandedElement: IApiInvestigation = null;
  public set expandedElement(val: IApiInvestigation) {
    this._expandedElement = val;
    if (!val) {
      this.expandedElementFilteredServices = [];
      this.expandedElementReports = [];
    } else {
      if (val.Services && val.Services.length > 0) this.filterServiceList(val.InvestigationStaff[0].User.id);
      else this.expandedElementFilteredServices = [];
      // Filtering reports
      if (val.Documents && val.Documents.length > 0) {
        this.expandedElementReports = val.Documents.filter((doc) => doc.Type.Category.name === DocumentCategories.REPORT.valueOf());
      }
      else this.expandedElementReports = [];
    }
  }
  public get expandedElement(): IApiInvestigation {
    return this._expandedElement;
  }

  public permissionConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.INVESTIGATION,
    appliedPermissions: {
      All: [PermissionActions.VIEW],
      AllAssigned: [PermissionActions.VIEW],
      Assigned: [PermissionActions.VIEW],
      Own: [PermissionActions.VIEW]
    }
  };

  private permissionAdminConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.INVESTIGATION,
    appliedPermissions: {
      All: [PermissionActions.VIEW, PermissionActions.UPDATE, PermissionActions.CREATE],
      AllAssigned: [],
      Assigned: [],
      Own: []
    }
  };

  public expandedElementFilteredServices: IApiService[] = null;
  public expandedElementReports: IApiDocument[] = [];
  // public expandedElementSortedDocuments: IApiDocumentSnapshot[] = null;

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  // Toggle for comments on table
  public showComments = false;
  public commentLogColumns = ["date", "comment", "user"];
  public displayCommentToggle = false;

  private _isAdmin: boolean;

  private get isAdmin() {
    return this._isAdmin;
  }
  private set isAdmin(val) {
    this._isAdmin = val;
  }

  private user: IApiUser;

  private arrayIsEqual(x, y) {
    return _(x).xorWith(y, _.isEqual).isEmpty();
  }

  public sort: SortDescriptor[] = [{
    field: 'CREATED_AT',
    dir: 'desc'
  }];

  public expandedDetailKeys: any[] = [];
  public tableData: IApiInvestigation[] = [];
  public userViewFilter = IApiUserFilterType.ViewStaffUser;

  constructor(
    private investigationService: InvestigationService,
    private loaderService: LoaderService,
    private notifications: NotificationsService,
    private riskTypeService: RiskTypesService,
    private serviceStatusService: ServiceStatusService,
    private contactRoleService: ContactRoleService,
    private documentSnapshotPipe: DocumentSnapshotPipe,
    private investigationStatusService: InvestigationStatusService,
    private subServiceService: SubServiceService,
    private auth: AuthService,
    private invoiceService: InvoiceService,
    private expenseItemService: ExpenseItemService,
    private expenseService: ExpenseService,
    private route: ActivatedRoute,
    private viewport: ViewportScroller,
    private dialogService: DialogService
  ) {
    this.apiUserFilterType = IApiUserFilterType.ViewStaffUser;
    this.dataSource = new InvestigationDataSource(this.investigationService);
    // Table Initialization / Setup
    this.loaderService.attachObservable(this.dataSource.loading$);

    // Default to sort by newest at top
    this.dataSource.listPage.sortOrder = SortOrder.DESC;
    this.dataSource.listPage.orderBy = IApiInvestigationOrderBy.CreatedAt;

    this.auth.authenticatedUser.pipe(
      take(1)
    ).subscribe(user => this.user = user);

    // Find permissions
    this.auth.hasCategoryPermission(this.permissionAdminConfig.category, this.permissionAdminConfig.appliedPermissions).pipe(
      untilDestroyed(this)
    ).subscribe((perm) => {
      this._isAdmin = perm;
    });

    this.dataSource?.contents$.subscribe((res) => {
      this.tableData = res;
    });
  }

  public pageChange(event) {
    this.showComments = false;
    this.pageOptions?.paginate(event)
  }

  public toggleCommentExpand(show: boolean): void {
    this.showComments = show;
    if (!show) {
      this.expandedDetailKeys = [];
    } else {
      this.expandedDetailKeys = this.tableData.map(
        (product) => product.id
      );
    }
  }

  public displayComments(dataItem: IApiInvestigation) {
    return dataItem.Comments.length > 0;
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'CREATED_AT';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  }

  public getIcon(RiskTypeId: string) {
    return getRiskTypeIcon(RiskTypeId);
  }

  // Investigation actions
  public closeInvestigation(event: MouseEvent, investigation: IApiInvestigation) {
    event.stopPropagation();
    const { id } = investigation;
    const message = "Are you sure you want to mark this investigation as Closed?";
    this.updateInvestigation(id, IApiInvestigationStatusNames.InProgress, message);
  }
  public changeToOngoing(investigation: IApiInvestigation) {
    const { id } = investigation;
    const message = "Are you sure you want to move this investigation On Going?";
    this.updateInvestigation(id, IApiInvestigationStatusNames.Ongoing, message);
  }
  public putOnHold(investigation: IApiInvestigation) {
    const { id } = investigation;
    const message = "Are you sure you want to send this investigation to billing?";
    this.updateInvestigation(id, IApiInvestigationStatusNames.HoldForBilling, message);
  }

  public moveToInProgress(investigation: IApiInvestigation) {
    event.stopPropagation();
    const { id } = investigation;
    const message = "Are you sure you want to move this investigation In Progress?";
    this.updateInvestigation(id, IApiInvestigationStatusNames.InProgress, message);
  }

  public openStaffSidebar(data: IApiInvestigation) {
    try {
      const dialog: DialogRef = this.dialogService.open({
        content: CalendarProfileSidebarComponent,
        width: "55%",
        height: "100vh",
        cssClass: 'right-position calendar-sidebar',
        preventAction: (ev) => {
          return ev !== 'closed' as any;
        },
      });
      const dialogInstance = dialog.content.instance as CalendarProfileSidebarComponent;
      dialogInstance.data = data;
      dialog.result.subscribe((result: DialogCloseResult) => {
      })
    } catch (e) {
      console.log(e);
    }

  }

  public changeFlatRate(investigation: IApiInvestigation) {
    // TODO change the things
  }

  public sendToBilling(event: MouseEvent, investigation: IApiInvestigation) {
    event.stopPropagation(); // Stop propagation so the row doesn't expand when button is clicked
    const { id } = investigation;
    const message = "Are you sure you want to send this investigation to billing?";
    this.updateInvestigation(id, IApiInvestigationStatusNames.ReadyToBill, message);
  }

  private updateInvestigation(id: string, status: IApiInvestigationStatusNames, message: string) {
    this.notifications.kendoConfirm(message).pipe(
      filter(v => !!v)
    ).subscribe(() => this.investigationStatusService.addInvestigationStatus({ InvestigationId: id, name: status, comment: "" }).pipe(
      switchMap(() => this.load())
    ).subscribe());
  }

  public ngOnInit() {
    // Show comment toggle
    this.displayCommentToggle = !!this.displayedColumns.find((a) => a === Columns.comments);

    // This is used to update the graphql call to return fewer sub-fields
    if (this.config.type === TableType.investigations && this.config.viewType === null) this.dataSource.applyFilter(IApiInvestigationFilterType.ViewListView, "true");
    if (this.config.type === TableType.ongoing && this.config.viewType === IApiInvestigationStatusNames.Ongoing) this.dataSource.applyFilter(IApiInvestigationFilterType.ViewOngoingView, "true");
    if (this.config.type === TableType.pending && this.config.viewType === IApiInvestigationStatusNames.Pending) this.dataSource.applyFilter(IApiInvestigationFilterType.ViewPendingView, "true");
    if (this.config.type === TableType.assigned && this.config.viewType === IApiInvestigationStatusNames.Assigned) this.dataSource.applyFilter(IApiInvestigationFilterType.ViewAssignedView, "true");
    if (this.config.type === TableType.progress && this.config.viewType === IApiInvestigationStatusNames.InProgress) this.dataSource.applyFilter(IApiInvestigationFilterType.ViewInprogressView, "true");
    if (this.config.type === TableType.readyToBill && this.config.viewType === IApiInvestigationStatusNames.ReadyToBill) this.dataSource.applyFilter(IApiInvestigationFilterType.ViewReadytobillView, "true");

    // Handle if we just get the count or not, add to filter;
    if (this.count) {
      // if ((this.config.startWithCount || this.config.startWithCount === undefined) && this.config.type !== TableType.contact) this.dataSource.applyFilter(IApiInvestigationFilterType.CountOnly, "1");
      this.dataSource.applyFilter(IApiInvestigationFilterType.CountOnly, "1");
    }
    if (!this.config.viewType) {
      // Default to remove Closed / Archived
      this.dataSource.applyFilter(IApiInvestigationFilterType.Closed, "false");
    }

    // Filter by user if user view
    if (this.config.type === TableType.user) this.dataSource.applyFilter(IApiInvestigationFilterType.User, this.config.contactId);
    if (this.config.type === TableType.review) this.dataSource.applyFilter(IApiInvestigationFilterType.Document, "TECH_REVIEW");

    // Filter by user if contact view & get contactRole filter
    if (this.config.type === TableType.contact) {
      this.dataSource.applyFilter(IApiInvestigationFilterType.Contact, this.config.contactId);
      this.contactRoleService.get([{ type: IApiContactRoleFilterType.ViewListView, value: 'true' }], { sortOrder: SortOrder.ASC, limit: -1 }).pipe(unwrapConnection()).subscribe((r) => {
        const otherRoles = ["Client", "BillTo", "SceneContact", "Insured"];
        const foundContactRoles = r.map(obj => obj.name);
        // Extract role names only
        this.contactRoles = [...otherRoles, ...foundContactRoles].sort();
      });
    }

    if (this.config.viewType !== IApiInvestigationStatusNames.ReadyToBill) {
      this.dataSource.applyFilter(IApiInvestigationFilterType.Status, this.config.viewType);
    } else {
      const defaultFilter = [
        { type: IApiInvestigationFilterType.CountOnly, value: "true" },
      ];

      // TODO - refactor this count
      this.dataSource.applyFilter(IApiInvestigationFilterType.BillingStatus, this.billingStatus);
      // Need the cumulative count of all three queries
      forkJoin([
        this.investigationService.get([
          ...defaultFilter,
          { type: IApiInvestigationFilterType.BillingStatus, value: this.billingStatusOpts.HasReport }
        ], {}),
        this.investigationService.get([
          ...defaultFilter,
          { type: IApiInvestigationFilterType.BillingStatus, value: this.billingStatusOpts.NoReport }
        ], {}),
        this.investigationService.get([
          ...defaultFilter,
          { type: IApiInvestigationFilterType.BillingStatus, value: this.billingStatusOpts.Unbilled }
        ], {})
      ]).subscribe((val) => {
        this.readyToBillTotalResults = val.reduce((p, { totalCount }) => p + totalCount, 0);
      });
    }

    // Initialize URL parameters here instead of child component "app-investigations-filter-card"
    // Only subscribe to the first value with take(1)
    // All future value changes are handled by child component "app-investigations-filter-card"
    this.route.queryParams.pipe(
      untilDestroyed(this),
      take(1),
    ).subscribe((params) => {
      const advancedFilters = [];
      Object.keys(params).forEach((k: IApiInvestigationFilterType) => {
        if (Object.values(IApiInvestigationFilterType).includes(k)) {
          const value = params[k];
          advancedFilters.push({ type: k, value });
        }
      });
      this.filterInvestigations(advancedFilters, false);
    });

    // Watch table data
    this.dataSource.contents$.subscribe(val => {
      this.content = !!(val && val.length);
    });

    // If we started with count only, remove that after the initial load so we actually retrieve data;
    this.load().then(() => this.dataSource.removeFilter(IApiInvestigationFilterType.CountOnly));
  }

  ngAfterViewInit() {

    // TODO - Old pattern, refactor to newer pattern
    this.riskTypeService.riskTypes().subscribe((types) => this.riskTypes = types);

    // Load Silently
    forkJoin([
      this.serviceStatusService.get([], { limit: -1 }).pipe(unwrapConnection()),
      this.expenseItemService.get().pipe(unwrapConnection()),
    ]).subscribe(([serviceStatuses, expenseTypes]) => {

      // Set Found Filters
      this.serviceStatuses = serviceStatuses;
      this._evidenceExpenseItemId = expenseTypes.find((obj) => obj.name === 'Annual Evidence Storage').id;

      // Mark as 'loaded' once filters have completed fetching
      this.loaded = true;
    });
  }

  public calcServiceComplete(service: IApiService): boolean {
    return service.SubServices.find(s => s.isComplete === false) ? false : true;
  }
  public mileage(val: number) {
    return val ? val + 'miles' : '';
  }

  public filterServiceList(id: string) {
    this.expandedElementFilteredServices = this.expandedElement.Services.filter(({ AssignedUsers }) => AssignedUsers.find((u) => u.id === id));
  }

  // public newestDocument(doc: IApiDocument): IApiDocumentSnapshot {
  //   return doc.History.sort((a, b) => moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1)[0];
  // }

  public async load(): Promise<any> {
    this.dataSource.pagingReset();
    await this.dataSource.load();
    this.toggleCommentExpand(this.showComments);
    return this.total.emit(this.config.viewType === IApiInvestigationStatusNames.ReadyToBill ? this.readyToBillTotalResults : this.pageOptions.totalCount);
  }

  // Update the opened row's content without closing it
  public async softLoad(): Promise<any> {
    await this.dataSource.load();
    this.dataSource.contents$.subscribe(arr => {
      const foundObj = arr.find(obj => obj.id === this.expandedElement.id);
      this.expandedElement = foundObj;
    });
  }

  // Filter change detection below
  public filterInvestigations(filterList: IApiInvestigationFilter[], load = true) {

    // If no new filters, return
    if (this.arrayIsEqual(filterList, this.previousFilters)) return;

    this.previousFilters.forEach(({ type }) => this.dataSource.removeFilter(type)); // Remove previous filters and only apply the ones that came in

    // If Admin/Right Permissions, allow searches with closed status or NEFCO# to include closed/archived investigations
    if (this.isAdmin && filterList.find((obj) => (obj.type === IApiInvestigationFilterType.Status && obj.value === IApiInvestigationStatusNames.Closed) || obj.type === IApiInvestigationFilterType.NefcoNumber || obj.type === IApiInvestigationFilterType.PolicyClaimNumber)) {
      this.dataSource.removeFilter(IApiInvestigationFilterType.Closed);
    } else {
      this.dataSource.applyFilter(IApiInvestigationFilterType.Closed, 'false');
    }
    filterList.forEach(({ type, value }) => this.dataSource.applyFilter(type, value));
    if (load) this.load();
    this.previousFilters = filterList;
  }

  public billingStatusChange(val: string) {
    this.dataSource.applyFilter(IApiInvestigationFilterType.BillingStatus, val);
    this.load();
  }

  public searchChange(event: string) {
    this.dataSource.applyFilter(IApiInvestigationFilterType.Search, event);
    this.load();
  }

  // handles changes on the 'staff member' select
  public setUser(userId: string) {
    this.userId = userId;
    if (this.primaryInvestigator) {
      this.dataSource.applyFilter(IApiInvestigationFilterType.UserPrimary, this.userId);
      this.dataSource.removeFilter(IApiInvestigationFilterType.User);
    }
    else {
      this.dataSource.applyFilter(IApiInvestigationFilterType.User, this.userId);
      this.dataSource.removeFilter(IApiInvestigationFilterType.UserPrimary);
    }
    this.load();
  }

  public hasQuickBookError(id: string) {
    // TODO need to push errors into the quickBookErrors array once that workflow is complete
    return this.quickBookErrors.find(e => e === id);
  }

  public openQuickbooksDocument(event: MouseEvent, row: IApiInvestigation) {
    event.stopPropagation();
  }

  public viewInvoice(row: IApiExpense) {
    // TODO implement invoice viewing once that workflow has been completed
  }

  public allExpandedExpenses(row: IApiInvestigation) {
    return [
      ...row.Expenses,
      ...row.TimeEntries,
      ...row.Mileage
    ].filter((obj) => !obj?.InvoiceItems.length);
  }

  public allExpandedInvoices() {
    if (this.expandedElement) {
      this.invoiceService.get([
        { type: IApiInvoiceFilterType.Investigation, value: this.expandedElement.id },
        { type: IApiInvoiceFilterType.FailedOrPending, value: 'true' },
      ]).pipe(unwrapConnection()).subscribe((foundInvoices) => this.invoices = foundInvoices);
    }
  }

  // Calculate % of completed subservices
  public servicesCount(services: IApiService[]) {
    let allSubServices: IApiSubService[] = [];
    let completeSubServices: IApiSubService[] = [];

    services.forEach(obj => allSubServices = [...allSubServices, ...obj.SubServices]);
    completeSubServices = allSubServices.filter((subService) => subService.isComplete);

    return (allSubServices.length ? Math.round((completeSubServices.length / allSubServices.length) * 100) : 0);
  }

  // Invoice Actions
  public resubmitInvoice(invoice: IApiInvoice) {
    this.notifications.kendoConfirm("Are you sure this invoice is ready for resubmission?").pipe(
      filter(v => !!v),
      switchMap(() => this.invoiceService.resubmit(invoice.id)),
      this.notifications.snackbarErrorPipe("Error resubmitting invoice"),
      this.notifications.snackbarPipe("Invoice resubmitted!")
    ).subscribe(() => this.load());
  }

  public deleteInvoice(invoice: IApiInvoice) {
    this.notifications.kendoConfirm("Are you sure you want to delete this invoice?").pipe(
      filter(v => !!v),
      switchMap(() => this.invoiceService.remove(invoice.id)),
      this.notifications.snackbarErrorPipe("Error deleting invoice"),
      this.notifications.snackbarPipe("Invoice deleted.")
    ).subscribe(() => {
      this.load();
    });
  }

  public calculateAmount(invoice: IApiInvoice) {
    return (invoice?.InvoiceLines || []).reduce((prev, { rate, quantity }) => {
      prev += rate * quantity;
      return prev;
    }, 0);
  }

  // Creating evidenceExpense
  public addEvidenceExpense() {
    const input: IApiAddExpenseInput = {
      ExpenseItemId: this.evidenceExpenseItemId,
      InvestigationId: this.expandedElement.id
    };

    this.notifications.kendoConfirm("Are you sure you want to add the current evidence items to an expense? This action will overwrite an existing evidence expense that hasn't been invoiced yet.").pipe(
      filter(v => !!v),
      switchMap(() => this.expenseService.add(input)),
      this.notifications.snackbarErrorPipe(),
      this.notifications.snackbarPipe("Evidence expense successfully added")
    ).subscribe(() => {
      this.load();
    });
  }

  public orderInvestigationStaff(investigationStaff: IApiInvestigationStaff[]) {
    // Order investigation staff correctly
    // 1) Lead Investigator 2) Lead Tech Reviewer/Engineer 3) Remaining staff, alpha order
    if (investigationStaff && investigationStaff.length) {
      // First sort by created Date
      // This way we find the correct investigator / tech reviewer
      investigationStaff = investigationStaff.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);

      // Then find lead investigator / tech reviewer
      const leadInvestigator = investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.Investigator && obj.isPrimary === true)
        || investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.Investigator);

      const leadTech = investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.TechReviewer && obj.isPrimary === true)
        || investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.ElectricalEngineer && obj.isPrimary === true)
        || investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.FireProtectionEngineer && obj.isPrimary === true)
        || investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.TechReviewer)
        || investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.ElectricalEngineer)
        || investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.FireProtectionEngineer);

      // Then sort by alpha order
      investigationStaff = investigationStaff.sort((a, b) => a.User.lastName > b.User.lastName ? 1 : -1);

      // Move items in array
      if (leadTech) {
        investigationStaff = investigationStaff.filter((obj) => obj.id !== leadTech.id);
        investigationStaff.unshift(leadTech);
      }
      if (leadInvestigator) {
        investigationStaff = investigationStaff.filter((obj) => obj.id !== leadInvestigator.id);
        investigationStaff.unshift(leadInvestigator);
      }
      return investigationStaff;
    } else {
      return [];
    }
  }

  public filterValue(filter: IApiInvestigationFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return (value === "true" || value === "false") ? JSON.parse(value) : (value) ? value : null;
  }

  public setFilters(value: string | undefined, type: IApiInvestigationFilterType) {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: value
    }] : filtersCopy;
  }

  public loadFilters(filterList: IApiInvestigationFilter[], load = true) {
    // If no new filters, return
    if (_.isEqual(filterList, this.previousFilters)) return;
    this.previousFilters.forEach(({ type }) => this.dataSource.removeFilter(type)); // Remove previous filters and only apply the ones that came in
    filterList.forEach(({ type, value }) => this.dataSource.applyFilter(type, value));
    if (load) this.load();
    this.previousFilters = filterList;
  }

  public filterTable() {
    this.loadFilters(this.filters);
  }

  public clearFilter() {
    this.filters = [];
    this.dataSource.applyFilter(IApiInvestigationFilterType.ViewAssignedView, "true");
    this.dataSource.applyFilter(IApiInvestigationFilterType.Status, this.config.viewType);
    this.dataSource.applyFilter(IApiInvestigationFilterType.Search, null);
    this.dataSource.applyFilter(IApiInvestigationFilterType.User, null);
    this.dataSource.applyFilter(IApiInvestigationFilterType.RiskType, null);
    this.dataSource.applyFilter(IApiInvestigationFilterType.Priority, null);
    this.load();
  }

  public itemSelected(action, dataItem) {
    switch (action.text) {
      case "Move to In Progress":
        this.moveToInProgress(dataItem);
        break;
      case "View Calendar":
        this.openStaffSidebar(dataItem);
        break;
      default:
        break;
    }
  }
}
