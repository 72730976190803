<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationPhotosCreate" #investigationPhotosCreate="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationPhotosUpdate" #investigationPhotosUpdate="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationPhotosDelete" #investigationPhotosDelete="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationPhotosDownload" #investigationPhotosDownload="appEnforcePermission"></div>
<!-- Permissions -->
<section class="p-3">
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
    <ng-container *ngIf="folders.length && !showNewFolder; else newFolder">
      <ng-container *ngIf="showEditFolder; else folderSelect">
        <kendo-gridlayout-item>
          <div class="kendo-formfield">
            <kendo-label [for]="editInput" text="Edit Photo Folder"></kendo-label>
            <kendo-textbox #editInput autofocus [value]="selectedFolder.name"
              [disabled]="!investigationPhotosUpdate?.enabled" placeholder="Enter Folder Name" fillMode="outline"
              [clearButton]="false"></kendo-textbox>
          </div>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="photo-action">
          <button kendoButton themeColor="secondary" fillMode="link" iconClass="fa-solid fa-save"
            [disabled]="!editInput.value?.length" (click)="setSelectedFolderName(editInput.value)">
          </button>
          <button kendoButton themeColor="secondary" fillMode="link" iconClass="fa-solid fa-circle-xmark"
            (click)="showEditFolder = false">
          </button>
        </kendo-gridlayout-item>
      </ng-container>
      <ng-template #folderSelect>
        <kendo-gridlayout-item>
          <div class="kendo-formfield">
            <kendo-label [for]="photoFolderList" text="Photo Folder"></kendo-label>
            <kendo-dropdownlist #photoFolderList="ngModel" [data]="folders" textField="name" valueField="id"
              fillMode="outline" [valuePrimitive]="false" [disabled]="loader.active" [(ngModel)]="selectedFolder"
              [value]="selectedFolder" [popupSettings]="{appendTo: 'component', animate: false}">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span class="not-placeholder"> {{ dataItem?.name }} </span>
              </ng-template>
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <div class="p-12">
                  <span class="not-placeholder"> {{ dataItem?.name }} </span>
                  <span *ngIf="dataItem?.createdAt" class="photo-created-at"><br /> Date Created: {{ dataItem?.createdAt
                    | date:
                    "MM/dd/yyyy"
                    }}
                  </span>
                </div>
              </ng-template>
            </kendo-dropdownlist>
          </div>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="photo-action">
          <button *ngIf="selectedFolder && investigationPhotosUpdate?.enabled" kendoButton themeColor="secondary"
            fillMode="link" iconClass="fa-solid fa-pencil" (click)="showEditFolder = true">
          </button>
          <button *ngIf="investigationPhotosCreate?.enabled" kendoButton themeColor="secondary" fillMode="link"
            iconClass="fa-solid fa-folder-plus" (click)="showNewFolder = true">
          </button>
        </kendo-gridlayout-item>
      </ng-template>
    </ng-container>
    <ng-template #newFolder>
      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-label [for]="newInput" text="Add Folder Name"></kendo-label>
          <kendo-textbox #newInput autofocus maxlength="255" placeholder="Enter Folder Name" fillMode="outline"
            [clearButton]="false"></kendo-textbox>
        </div>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="photo-action">
        <button kendoButton themeColor="secondary" fillMode="link" iconClass="fa-solid fa-save"
          [disabled]="!newInput.value?.length" (click)="createFolder(newInput.value)">
        </button>
        <button kendoButton *ngIf="folders.length" themeColor="secondary" fillMode="link"
          iconClass="fa-solid fa-circle-xmark" (click)="showNewFolder = false">
        </button>
      </kendo-gridlayout-item>
    </ng-template>
    <kendo-gridlayout-item [colSpan]="3" class="photo-action-right" *ngIf="!showReorder">
      <ng-container *ngIf="selectedFolder">
        <!-- Low Res File Upload, Button Hidden -->
        <ng-container *ngIf="hasLowResPhotos && investigationPhotosUpdate?.enabled">
          <span kendoTooltip position="bottom" [tooltipTemplate]="templateInfo" filter="i"
            tooltipClass="tooltipwidth160">
            <ng-template #templateInfo>
              The filenames need to match the images that you are replacing.
            </ng-template>
            <i class="fa-solid fa-info-circle"></i></span>
          <button kendoButton themeColor="light" fillMode="solid" (click)="fileSelector('LOW_RESOLUTION')"> Replace
            Low-Res Photos</button>
        </ng-container>
        <input type="file" #fileUpload hidden (change)="fileChange($event, fileUpload)"
          accept="image/png, image/jpeg, image/jpg" multiple="multiple" />
        <button *ngIf="investigationPhotosDownload?.enabled" kendoButton themeColor="light" fillMode="solid" (click)="downloadRTF()"> Download Photos RTF</button>
        <kendo-dropdownbutton *ngIf="investigationPhotosCreate?.enabled" class="ml-16" [data]="newUploadAction"
          themeColor="primary" fillMode="solid" buttonClass="grid-action-items" iconClass="fa-solid fa-cloud-upload"
          [popupSettings]="{
              appendTo: 'component',
              animate: false,
              width: 250,
              popupClass: 'grid-action-items-menu'
            }" (itemClick)="openFolderModal($event?.text === 'High-Res (recommended)')"> Upload New Photo
        </kendo-dropdownbutton>
      </ng-container>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  <section class="mt-2 text-center" *ngIf="showReorder; else photoList">
    <div cdkDropList class="reorder-list" (cdkDropListDropped)="reorder($event)" cdkDropListLockAxis="y">
      <div class="listItem" *ngFor="let photo of selectedFolder?.Photos; index as i" cdkDrag [cdkDragData]="photo"
        cdkDragPreviewClass="draggable">
        <span class="index" [innerHTML]="i + 1"></span>
        <img [src]="photo.s3Uri | s3" class="img img-thumbnail" />
        <dl>
          <dt class="pt-2">Filename:</dt>
          <dd [innerHTML]="photo.fileName"></dd><br />
          <dt>Created:</dt>
          <dd [innerHTML]="photo.createdAt | customDate: 'MM/dd/yyyy'"></dd>
        </dl>
      </div>
    </div>
  </section>
  <!-- Photo Grid & Filters-->
  <ng-template #photoList>
    <section class="photo-list-section">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item class="photo-filters">
          <div>
            <input kendoCheckBox type="checkbox"
              *ngIf="selectedFolder?.Photos && (investigationPhotosUpdate?.enabled || investigationPhotosDelete?.enabled)"
              [checked]="visiblePhotos?.length && visiblePhotos?.length === selectedPhotos?.length"
              (change)="checkAll($event.target)" />
            <span class="checkobx-label ml-2">Select All</span>
          </div>
          <kendo-dropdownbutton
            *ngIf="selectedFolder?.Photos && (investigationPhotosUpdate?.enabled || investigationPhotosDelete?.enabled)"
            class="ml-16"
            [data]="investigationPhotosUpdate?.enabled && investigationPhotosDelete?.enabled ? photoListAction : (investigationPhotosDelete?.enabled ? photoListDeleteAction : (investigationPhotosUpdate?.enabled ? photoListUpdateAction :  [] ))"
            themeColor="primary" fillMode="solid" buttonClass="grid-action-items-reverse"
            [disabled]="selectedPhotos?.length < 1" iconClass="fa-solid fa-caret-down" [popupSettings]="{
              appendTo: 'component',
              animate: false,
              width: 250,
              popupClass: 'grid-action-items-menu'
            }" (itemClick)="photoAction($event)"> Actions
          </kendo-dropdownbutton>
          <kendo-buttongroup class="ml-16">
            <button kendoButton [toggleable]="true" [selected]="visiblePhotosFilter === null"
              (click)="changePhotosFilter(null)" title="All">All</button>
            <button kendoButton [toggleable]="true" [selected]="visiblePhotosFilter === true"
              (click)="changePhotosFilter(true)" title="Used">Used</button>
            <button kendoButton [toggleable]="true" [selected]="visiblePhotosFilter === false"
              (click)="changePhotosFilter(false)" title="Unused">Unused</button>
          </kendo-buttongroup>
          <kendo-slider class="ml-16" #columns [smallStep]="25" [min]="25" [value]="25" [max]="100"
            [showButtons]="false">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="text-right">
          <kendo-label>
            <kendo-switch [(ngModel)]="showFilename" class="mr-5" name="showfilename" title=""></kendo-switch>
            Show Filename
          </kendo-label>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <!-- Photo Grid -->
      <div class="flex-list" cdkDropListGroup *ngIf="selectedFolder?.Photos">
        <div cdkDropList (cdkDropListDropped)="reorder($event)" [cdkDropListData]="i"
          style.flexBasis="calc({{columns.value}}% - 8px" *ngFor="let photo of visiblePhotos; let i = index;">
          <div cdkDrag [cdkDragData]="i" (cdkDragEntered)="entered($event)"
            [cdkDragDisabled]="!investigationPhotosUpdate?.enabled">
            <section class="drag-placeholder" *cdkDragPlaceholder></section>
            <ng-container *ngTemplateOutlet="photoTemplate; context: { photo: photo, index: i }"></ng-container>
          </div>
        </div>
      </div>
    </section>
  </ng-template>
</section>

<!-- Photo Card Template -->
<ng-template #photoTemplate let-data="photo" let-index="index">
  <kendo-card width="100%">
    <kendo-card-body>
      <div class="kendo-card-header-flex">
        <span>{{ index + 1 }}</span>
        <span kendoTooltip position="top" [tooltipTemplate]="templateInfoWarning" filter="i"
          *ngIf="data.lowResS3Uri && data.lowResS3Uri === data.s3Uri;" tooltipClass="tooltipwidth160"><i
            class="fa-solid fa-warning"></i>
          <ng-template #templateInfoWarning>Requires high-res image uprev</ng-template></span>
        <input kendoCheckBox type="checkbox"
          *ngIf="investigationPhotosUpdate?.enabled || investigationPhotosDelete?.enabled" [checked]="data.selected"
          (change)="data.selected = !data.selected" />
      </div>
      <div *ngIf="!data?.loaded && data?.s3Uri" class="text-center">
        <kendo-loader type="infinite-spinner" themeColor="primary" size="medium">
        </kendo-loader>
      </div>
      <img class="photo mt-12" *ngIf="data?.s3Uri" (load)="onImageLoad(data)" loading="lazy"
        [src]="(data.s3Uri | s3:0:1250)" [alt]="data.caption" (error)="onError($event, data.s3Uri, 1250)" />
      <p *ngIf="showFilename" [innerHtml]="data.fileName" class="mt-12"></p>
      <kendo-gridlayout #editSection *ngIf="investigationPhotosUpdate?.enabled" [rows]="['auto']"
        [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']" class="mt-12">
        <kendo-gridlayout-item>
          <div class="kendo-formfield">
            <kendo-textbox #editInput autofocus [(ngModel)]="data.caption" (ngModelChange)="photoUpdate.next(data)"
              [tabindex]="999 + index" placeholder="Enter Caption" fillMode="outline"
              [clearButton]="false"></kendo-textbox>
          </div>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="card-action">
          <span *ngIf="investigationPhotosDelete?.enabled" class="trash-span" (click)="deletePhotos([data])"><i
              class="fa-solid fa-trash"></i></span>
          <div class="text-right">
            <kendo-label>
              <kendo-switch *ngIf="investigationPhotosUpdate?.enabled"
                (valueChange)="data.isEvidence = !data.isEvidence; photoUpdate.next(data);" [checked]="data.isEvidence"
                class="mr-5" name="isEvidence" title="Is Evidence"></kendo-switch>
              Evidence
            </kendo-label>
            <kendo-label class="ml-2">
              <kendo-switch *ngIf="investigationPhotosUpdate?.enabled" [checked]="data.isUsed"
                (valueChange)="togglePhotoUse([data], !data.isUsed)" class="mr-5" name="isUsed"
                [title]="'Is Used'"></kendo-switch>
              Used
            </kendo-label>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </kendo-card-body>
  </kendo-card>
</ng-template>
