import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IPayPeriod } from 'src/app/shared/interfaces/time-entry.interfaces';
import { TimeEntryService, TimeEntryTypeService } from 'src/app/shared/services';
import { TimeEntryDataSource } from '../../../../shared/services/time-entry';
import { IApiTimeEntry, IApiTimeEntryFilterType } from "src/app/shared/modules/graphql/types/types";
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import dayjs from 'dayjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/common/shared.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
@UntilDestroy()
@Component({
  selector: 'app-bookkeeping-payroll-entry-list',
  templateUrl: './bookkeeping-payroll-entry-list.component.html',
  styleUrls: ['./bookkeeping-payroll-entry-list.component.scss']
})
export class BookkeepingPayrollEntryListComponent implements OnInit, OnChanges {
  private _dataSource: TimeEntryDataSource;

  public get dataSource(): TimeEntryDataSource {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  /** total hours calc */
  private _totalHours = 0;
  public get totalHours() {
    return this._totalHours;
  }
  public set totalHours(value: number) {
    this._totalHours = value;
  }

  private _payPeriod: IPayPeriod;
  @Input() set payPeriod(val) {
    this._payPeriod = val;
    this.load();
  }

  get payPeriod() {
    return this._payPeriod;
  }

  private _userId: string;
  @Input() set userId(val) {
    this._userId = val;
    this.load();
  }

  get userId() {
    return this._userId;
  }

  private _reload: Date;
  @Input() set reload(val) {
    this._reload = val;
  }

  get reload() {
    return this._reload;
  }

  payrollEntryList: IApiTimeEntry[] = [];

  public sort: SortDescriptor[] = [{
    field: '',
    dir: 'asc'
  }];

  private baseTimeEntries: any[] = [];

  /* maxDate = PayDate */
  public maxDate: Date = dayjs().isBefore(
    dayjs(NefcoDateHelper.currentPayPeriod(new Date()).payDate).subtract(
      2,
      "week"
    )
  )
    ? dayjs(NefcoDateHelper.currentPayPeriod(new Date()).payDate)
        .subtract(2, "week")
        .toDate()
    : NefcoDateHelper.currentPayPeriod(new Date()).payDate;
  /* maxDate = PayDate */

  private getPayPeriod(date: Date) {
    return NefcoDateHelper.payPeriodbyPaidDate(date);
  }

  // Time Totals
  private _timeTotals = [];
  public get timeTotals() {
    return this._timeTotals;
  }
  public showViewTotalDetails = false;
  private pageLoaded = false;
  private payrollReviewLimit = 0;
  private payrollReviewOffset = 0;
  private payrollReviewCurrentPageIndex = 5;
  constructor(
    private loader: LoaderService,
    private timeEntryService: TimeEntryService,
    private timeEntryTypeService: TimeEntryTypeService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
  ) {
    this._dataSource = new TimeEntryDataSource(this.timeEntryService);
    this.loader.attachObservable(this.dataSource.loading$);
  }

  async ngOnInit() {
    this.dataSource.contents$.pipe(untilDestroyed(this)).subscribe(res => {
      this.payrollEntryList = res;
    });

    this.timeEntryTypeService.get([], { sortOrder: SortOrder.ASC, limit: -1 }).pipe(unwrapConnection()).subscribe((types) => {
      types.forEach((obj) => this.baseTimeEntries[obj.name] = { name: obj.name, hours: 0, hoursUnpaid: 0, hoursPaid: 0 });
    });
    
    const queryParams = this.sharedService.getLocalStorageByKey('BOOKKEEPING_PAYROLL');
    await this.router.navigate([], { relativeTo: this.route, queryParams});
    this.route.queryParams.pipe(
      untilDestroyed(this),
      take(1),
    ).subscribe((params) => {
      const {
        offset = 0,
        limit = 0,
        page = 5,
        date = null,
        userId = null,

      } = params;
      this.payrollReviewOffset = parseInt(offset, 10);
      this.payrollReviewLimit = parseInt(limit, 10);
      this.payrollReviewCurrentPageIndex = parseInt(page, 10);
      this.userId = userId;
      this._payPeriod = this.getPayPeriod(date ? new Date(date) : new Date(this.maxDate));
      this.dataSource.applyFilter(IApiTimeEntryFilterType.Payroll, `${this._payPeriod.start},${this._payPeriod.end},${this._payPeriod.payDate}`);
      this.userId ? this.dataSource.applyFilter(IApiTimeEntryFilterType.Employee, this.userId) : this.dataSource.removeFilter(IApiTimeEntryFilterType.Employee);
      this.updateQueryParamsAndSaveInStorage(this._payPeriod.payDate);
      
      this.dataSource.load().then(() => {
        this.calculateTotals();
      });
    });

    this.pageLoaded = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.reloadChild?.firstChange === false){
      this.load();
    }
  }

  private load() {
    if (!this.pageLoaded) return;
    if (this.payPeriod) this.dataSource.applyFilter(IApiTimeEntryFilterType.Payroll, `${this.payPeriod.start},${this.payPeriod.end},${this.payPeriod.payDate}`);
    this.userId ? this.dataSource.applyFilter(IApiTimeEntryFilterType.Employee, this.userId) : this.dataSource.removeFilter(IApiTimeEntryFilterType.Employee);
    this.updateQueryParamsAndSaveInStorage(this.payPeriod.payDate);
    this.dataSource.pagingReset();
    this.dataSource.load().then(() => { 
      this.calculateTotals();
    });
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiTimeEntryFilterType.Employee;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load();
  }

  public get overallTimeTotal() {
    if (this._timeTotals.length) {
      return this._timeTotals.reduce((prev, curr) => {
        prev.hoursPaid += curr.hoursPaid;
        prev.hoursUnpaid += curr.hoursUnpaid;
        prev.hours += curr.hours;
        return prev;
      }, { hoursPaid: 0, hoursUnpaid: 0, hours: 0 });
    }
    else return {};
  }

  private calculateTotals() {

    const timeEntryFilters = [];
    const results = [];
    // Apply needed filters
    if (this.payPeriod) timeEntryFilters.push({ type: IApiTimeEntryFilterType.Payroll, value: `${this.payPeriod.start},${this.payPeriod.end},${this.payPeriod.payDate}` });
    if (this.userId) timeEntryFilters.push({ type: IApiTimeEntryFilterType.Employee, value: this.userId });
    timeEntryFilters.push({ type: IApiTimeEntryFilterType.ViewPayrollTotal, value: "true" });
    timeEntryFilters.push({ type: IApiTimeEntryFilterType.PayRollPaidDateLocal, value: dayjs(this.payPeriod.payDate).format('MM/DD/YYYY') });
    // Grab the data
    this.loader.show$(this.timeEntryService.geTtimeEntryTotalByType(timeEntryFilters, { sortOrder: SortOrder.ASC, limit: -1 })).subscribe((entries) => {
      this._timeTotals = Object.values(entries?.summary);
    });
  }

  public viewTotalDetailsOpen(open: boolean): void {
    this.showViewTotalDetails = open;
    if (this.showViewTotalDetails) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      document.body.classList.remove('kendo-dialog-open');
    }
  }

  private updateQueryParamsAndSaveInStorage(payDate: Date) {
    if (payDate) {
      const data = {
        offset: this.payrollReviewOffset,
        limit: this.payrollReviewLimit,
        page: this.payrollReviewCurrentPageIndex,
        date: payDate.toISOString(),
        userId: this.userId || null,
      };
      this.sharedService.setLocalStorageByKey('BOOKKEEPING_PAYROLL', data);
      const queryParams = this.sharedService.getLocalStorageByKey('BOOKKEEPING_PAYROLL');
      this.router.navigate([], { relativeTo: this.route, queryParams, queryParamsHandling: 'merge'});
    }
  }

}
