import { EnforcePermissionDisplayModes, IEnforcePermissionConfig } from "../../directives/enforce-permission.directive";
import { PermissionActions, PermissionCategories } from "../../modules/graphql/enums/permissions.enums";

export const staff: IEnforcePermissionConfig = {
  category: PermissionCategories.STAFF,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const staffCreateConfig: IEnforcePermissionConfig = {
  ...staff,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  }
};

export const staffUpdateConfig: IEnforcePermissionConfig = {
  ...staff,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  }
};

export const staffContactInformationView = {
  category: PermissionCategories.STAFF_CONTACT_INFORMATION,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: []
  }
};

export const staffContactInformationOwnView = {
  category: PermissionCategories.STAFF_CONTACT_INFORMATION,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.VIEW]
  }
};

export const staffContactInformationUpdate = {
  category: PermissionCategories.STAFF_CONTACT_INFORMATION,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  }
};

export const staffContactInformationOwnUpdate = {
  category: PermissionCategories.STAFF_CONTACT_INFORMATION,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.UPDATE]
  }
};

export const staffUserInformationView = {
  category: PermissionCategories.STAFF_USER_INFORMATION,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: []
  }
};

export const staffUserInformationOwnView = {
  category: PermissionCategories.STAFF_USER_INFORMATION,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.VIEW]
  }
};

export const staffUserInformationUpdate = {
  category: PermissionCategories.STAFF_USER_INFORMATION,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  }
};

export const staffUserInformationOwnUpdate = {
  category: PermissionCategories.STAFF_USER_INFORMATION,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.UPDATE]
  }
};

export const staffEmergencyInformationView = {
  category: PermissionCategories.STAFF_EMERGENCY_CONTACT_INFORMATION,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: []
  }
};

export const staffEmergencyInformationOwnView = {
  category: PermissionCategories.STAFF_EMERGENCY_CONTACT_INFORMATION,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.VIEW]
  }
};

export const staffEmergencyInformationUpdate = {
  category: PermissionCategories.STAFF_EMERGENCY_CONTACT_INFORMATION,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  }
};

export const staffEmergencyInformationOwnUpdate = {
  category: PermissionCategories.STAFF_EMERGENCY_CONTACT_INFORMATION,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.UPDATE]
  }
};

export const staffWorkingHoursView = {
  category: PermissionCategories.STAFF_WORKING_HOURS,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: []
  }
};

export const staffWorkingHourOwnView = {
  category: PermissionCategories.STAFF_WORKING_HOURS,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.VIEW]
  }
};

export const staffWorkingHoursUpdate = {
  category: PermissionCategories.STAFF_WORKING_HOURS,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  }
};

export const staffWorkingHoursOwnUpdate = {
  category: PermissionCategories.STAFF_WORKING_HOURS,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.UPDATE]
  }
};

export const staffCertifications = {
  category: PermissionCategories.STAFF_CERTIFICATIONS,
  appliedPermissions: {
    All: Object.values(PermissionActions),
    AllAssigned: [],
    Assigned: [],
    Own: Object.values(PermissionActions)
  },
  displayMode: EnforcePermissionDisplayModes.Hidden

};

export const staffCertificationsList = {
  category: PermissionCategories.STAFF_CERTIFICATIONS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const staffCertificationsOwnList = {
  category: PermissionCategories.STAFF_CERTIFICATIONS,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.LIST]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const staffCertificationsCreate = {
  category: PermissionCategories.STAFF_CERTIFICATIONS,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const staffCertificationsOwnCreate = {
  category: PermissionCategories.STAFF_CERTIFICATIONS,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.CREATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const staffCertificationsUpdate = {
  category: PermissionCategories.STAFF_CERTIFICATIONS,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const staffCertificationsOwnUpdate = {
  category: PermissionCategories.STAFF_CERTIFICATIONS,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.UPDATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const staffCertificationsDelete = {
  category: PermissionCategories.STAFF_CERTIFICATIONS,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const staffCertificationsOwnDelete = {
  category: PermissionCategories.STAFF_CERTIFICATIONS,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.DELETE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const staffInvestigations: IEnforcePermissionConfig = {
  category: PermissionCategories.STAFF_INVESTIGATIONS,
  appliedPermissions: {
    All: Object.values(PermissionActions),
    AllAssigned: [],
    Assigned: [],
    Own: Object.values(PermissionActions)
  },
  displayMode: EnforcePermissionDisplayModes.Hidden

};

export const staffInvestigationsView: IEnforcePermissionConfig = {
  category: PermissionCategories.STAFF_INVESTIGATIONS,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.VIEW]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const staffInvestigationsList: IEnforcePermissionConfig = {
  category: PermissionCategories.STAFF_INVESTIGATIONS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const staffInvestigationsOwnList: IEnforcePermissionConfig = {
  category: PermissionCategories.STAFF_INVESTIGATIONS,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.LIST]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const staffViewAll: IEnforcePermissionConfig = {
  category: PermissionCategories.STAFF,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const downloadMobileApplicationAll: IEnforcePermissionConfig = {
  category: PermissionCategories.MOBILE_APPLICATION_DOWNLOAD,
  appliedPermissions: {
    All: [PermissionActions.DOWNLOAD],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
}

export const geoFireReportByStateAll: IEnforcePermissionConfig = {
  category: PermissionCategories.GEO_FIRE_REPORT,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
}
