import { IApiNefcoOffice, IApiNefcoOfficeFilter, IApiNefcoOfficeConnection } from '../../modules/graphql/types/types';
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { NefcoOfficeService } from "./nefco-office.service";
import { NefcoPagedDataSource } from '../../helpers/nefco-data-source.class';

export class NefcoOfficeDataSource extends NefcoPagedDataSource<IApiNefcoOffice, NefcoOfficeService, IApiNefcoOfficeConnection> implements DataSource<IApiNefcoOffice> {
  @ApiConnection()
  public load(filters: IApiNefcoOfficeFilter[] = []): Promise<IApiNefcoOfficeConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
