<div [dialogRef]="dialog" (appClickOutside)="cancelAssignment()">
<kendo-dialog-titlebar (close)="cancelAssignment()">
  <div class="modal-title">
    <span>Find {{(data?.role?.title | quiet)?.toLowerCase() || 'investigator'}}</span>
  </div>
</kendo-dialog-titlebar>
<section class="section-content">
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
    <!-- left -->
    <kendo-gridlayout-item>
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
          <kendo-gridlayout-item class="mb-0 address-wrapper">
            <div class="address-sub-div">
              <div>
                <h4>Investigation Date</h4>
                <p>{{data.lossDate | customDate : "MMMM d, y": true }}</p>
              </div>
              <div>
                <h4>Address</h4>
                <p style="max-width: 200px;">{{data.address?.address1}}<ng-container *ngIf="data.address?.address2">
                    {{data.address?.address2}}</ng-container></p>
              </div>
              <div>
                <h4>City</h4>
                <p>{{data.address?.city}}</p>
              </div>
              <div>
                <h4>State</h4>
                <p>{{data.address?.state}}</p>
              </div>
              <div>
                <h4>Zip Code</h4>
                <p>{{data.address?.postal}}</p>
              </div>
            </div>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
        <form #form="ngForm" appDebouncedChange>
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
            <kendo-gridlayout-item>
              <app-user-select-kendo [placeholder]="'Search by investigator'" [userViewFilter]="userViewFilter" name="userSelect" [userId]="requestedUser?.id"
                [(ngModel)]="requestedUser" [investigationRole]="data.role.title"></app-user-select-kendo>
            </kendo-gridlayout-item>
            
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label [for]="Radius" text="Radius"></kendo-label>
                <kendo-dropdownlist #Radius [data]="searchRadii" textField="displayText" valueField="miles" fillMode="outline"
                  [valuePrimitive]="false" name="searchRadius" [disabled]="requestedUser" [(ngModel)]="searchRadius"
                  [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span *ngIf="dataItem?.miles === 'All'" class="placeholder"> {{ dataItem?.displayText }} </span>
                    <span *ngIf="dataItem?.miles !== 'All'" class="not-placeholder"> {{ dataItem?.displayText }} </span>
                  </ng-template>
                </kendo-dropdownlist>
              </kendo-formfield>
            </kendo-gridlayout-item>
            
            <kendo-gridlayout-item style="display: flex;">
              <kendo-formfield>
                <div>
                  <input type="checkbox" 
                    #useAvailabilityFilter 
                    kendoCheckBox
                    id="useAvailabilityFilter"
                    [disabled]="requestedUser"
                    [(ngModel)]="state.useAvailabilityFilter" 
                    name="useAvailabilityFilter"
                    />
                  <kendo-label
                    class="k-checkbox-filter-label ml-12 mr-24"
                    [for]="useAvailabilityFilter"
                    text="Availability"
                  ></kendo-label>
                </div>
              </kendo-formfield>
              <kendo-formfield>
                <div>
                  <input type="checkbox" 
                    #useEligibilityFilter 
                    kendoCheckBox
                    id="useEligibilityFilter"
                    [disabled]="requestedUser"
                    [(ngModel)]="state.useEligibilityFilter"
                    name="useEligibilityFilter"
                    />
                  <kendo-label
                    class="k-checkbox-filter-label ml-12 mr-24"
                    [for]="useEligibilityFilter"
                    text="Eligibility"
                  ></kendo-label>
                </div>
              </kendo-formfield>
              <kendo-formfield>
                <div>
                  <input type="checkbox" 
                    #useFullTimeStatusFilter 
                    kendoCheckBox
                    id="useFullTimeStatusFilter"
                    [disabled]="false"
                    [(ngModel)]="state.useFullTimeStatusFilter" [disabled]="requestedUser" 
                    name="useFullTimeStatusFilter"
                    />
                  <kendo-label
                    class="k-checkbox-filter-label ml-12 mr-24"
                    [for]="useFullTimeStatusFilter"
                    text="Full-Time"
                  ></kendo-label>
                </div>
              </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item>
              <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" class="mr-16" (click)="appliedFilters()">Apply Filters</kendo-button>
              <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearAll()">Clear All</kendo-button>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </form>

        <hr />
        <ng-container *ngIf="data?.role?.title === investigationRoleNames.Investigator">
          <app-nefco-office-select [(ngModel)]="state.nefcoOfficeId"></app-nefco-office-select>
          <hr />
        </ng-container>

      <form #form="ngForm" appDebouncedChange>
        
        <app-kendo-alert *ngIf="showAlert" [showIcon]="true" [iconClass]="'fa-solid fa-triangle-exclamation'"
          [message]="'Please select an investigator.'" [showCloseIcon]="true" (closeIcon)="showAlert = false"></app-kendo-alert>

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']"
          class="mt-24" *ngIf="investigatorResults?.length">
          <kendo-gridlayout-item class="text-right">
            <kendo-button kendoButton themeColor="light" fillMode="solid" size="medium" (click)="handleNameClick(null)"
              [disabled]="loadingResults || !selectedInvestigatorRoute">See All Routes</kendo-button>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
        
        <div class="no-result mt-24" *ngIf="!investigatorResults?.length && !loadingResults">
          <div class="text-center">
            <h2>No Results Found</h2>
            <p>Please use a different set of filter criteria</p>
          </div>
        </div>
        
        <app-investigation-find-investigator-results [investigationId]="data?.investigationId" [users]="investigatorResults"
          [drawerUser]="drawerUser" (selectedUserChange)="selectedInvestigator = $event; showAlert = false"
          (drawerUserChange)="handleDrawerUserChange($event)" (nameClick)="handleNameClick($event)">
        </app-investigation-find-investigator-results>
        <kendo-datapager *ngIf="investigatorResults?.length" [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset"
          [total]="(dataSource?.pageInfo$ | async)?.totalCount" (pageChange)="paginate($event)" class="custom-paginate">
          <ng-template kendoDataPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <div class="k-pager-numbers-wrap">
              <kendo-datapager-prev-buttons></kendo-datapager-prev-buttons>
              <kendo-datapager-numeric-buttons [buttonCount]="2"></kendo-datapager-numeric-buttons>
              <kendo-datapager-next-buttons></kendo-datapager-next-buttons>
            </div>
            <kendo-datapager-info></kendo-datapager-info>
            <kendo-datapager-page-sizes [pageSizes]="[5, 8]"></kendo-datapager-page-sizes>
          </ng-template>
        </kendo-datapager>
      </form>
    </kendo-gridlayout-item>
    <!-- right -->
    <kendo-gridlayout-item>
      <div class="centered-map-container">
        <app-google-map *ngIf="data.address.longitude && data.address.latitude; else mapUnavailable" [lon]="data.address.longitude" [lat]="data.address.latitude" [mapUsers]="mapUsers" [selectedLocation]="selectedInvestigatorRoute"></app-google-map>
        <ng-template #mapUnavailable>
          <h4>Map Unavailable</h4>
          <p>The address for this investigation does not include latitude and longitude values. Mapping capabilities won't be available.</p>
        </ng-template>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</section>
<kendo-dialog-actions layout="end">
  <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="cancelAssignment()">
    Cancel
  </button>
  <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="confirmAssignment()">
    Assign
  </button>
</kendo-dialog-actions>
</div>