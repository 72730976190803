import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { AuthService, DocumentService, DocumentTypeService, InvestigationRoleService, InvestigationStaffService } from "src/app/shared/services";
import { DashboardModalViewComponent } from "../../dashboard-modal/dashboard-modal-view/dashboard-modal-view.component";
import { DashboardDialogType, IDashboardModalData } from "../../dashboard-modal/interfaces";
import { DocumentDataSource } from 'src/app/shared/services/document/document.datasource';
import { IApiAddInvestigationStaffInput, IApiDocument, IApiDocumentOrderBy, IApiInvestigation, IApiInvestigationRole, IApiInvestigationStaff, IApiDocumentFilterType, IApiUser, IApiDocumentFilter, IApiRequest, IApiDocumentTypeOrderBy, IApiDocumentType, IApiUserFilterType, IApiDocumentTypeFilterType, IApiUpdateDocumentInput } from 'src/app/shared/modules/graphql/types/types';
import { IApiInvestigationReportStatus, IApiInvestigationRoleNames } from 'src/app/shared/modules/graphql/types/types';
import { InvestigationReportO365LaunchComponent } from '../../investigations/investigation-report-o365-launch/investigation-report-o365-launch.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap, map, filter } from 'rxjs/operators';
import { Observable } from "rxjs";
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { forkJoin } from 'rxjs';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import _ from 'lodash';
import { SortDescriptor } from '@progress/kendo-data-query';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { DocumentCategories } from 'src/app/shared/modules/graphql/constants/document.constants';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';

import {
  investigationDocumentsUpdate,
  investigationReportsUpdate,
  investigationCauseUpdate,
  investigationReportsInEditorReviewUpdate,
  investigationAssignStaffCreate,
  investigationReportsInTechReviewUpdate,
} from "src/app/shared/helpers/auth-config/investigations.config";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
@UntilDestroy()
@Component({
  selector: 'app-dashboard-reports-table',
  templateUrl: './dashboard-reports-table.component.html',
  styleUrls: ['./dashboard-reports-table.component.scss']
})
export class DashboardReportsTableComponent implements OnInit {
  public authConfig = {
    investigationDocumentsUpdate,
    investigationReportsUpdate,
    investigationCauseUpdate,
    investigationReportsInEditorReviewUpdate,
    investigationAssignStaffCreate,
    investigationReportsInTechReviewUpdate,
  };
  @Output() total = new EventEmitter<number>();
  private _config;
  @Input() public set config(val) {
    this._config = val;
  }
  public get config() {
    return this._config;
  }

  private _dataSource: DocumentDataSource;
  private _totalResults;

  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public get totalResults() {
    return this._totalResults;
  }
  public set totalResults(val) {
    this._totalResults = val;
    this.total.emit(val);
  }

  private previousFilters: IApiDocumentFilter[] = [];
  private _filters: IApiDocumentFilter[] = [];

  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }

  private _documentTypes: IApiDocumentType[] = [];

  public orderByOptions = IApiDocumentOrderBy;

  public investigationRoleNames = IApiInvestigationRoleNames;

  public types = IApiInvestigationReportStatus;

  public staff = null;
  // Will need to be able to search based off location, client, priority, and investigation ID
  public searchValue = null;
  public report = null;
  public staffMember = null;
  public pageSize = 25;
  public riskType = null;
  public filterTypes = IApiDocumentFilterType;

  // TODO - fetch the Selection variables
  public get reportTypes(): IApiDocumentType[] {
    return this._documentTypes;
  }

  public users = [];
  public riskTypes = [];

  private currentUser = null;
  private roles: IApiInvestigationRole[] = [];

  public tableData: IApiDocument[] = [];

  public sortOptions = IApiDocumentOrderBy;

  public sort: SortDescriptor[] = [{
    field: 'CREATED_AT',
    dir: 'desc'
  }];
  public userViewFilter = IApiUserFilterType.ViewStaffUser;


  @ViewChild("reportList", { static: true }) public reportList: DropDownListComponent;

  public editorReviewActions = [];
  public clientReviewActions = [];
  public techReviewActions = [];
  public IApiInvestigationReportStatus = IApiInvestigationReportStatus;
  constructor(
    private documentService: DocumentService,
    private investigationStaff: InvestigationStaffService,
    private auth: AuthService,
    private investigationRoles: InvestigationRoleService,
    private documentTypeService: DocumentTypeService,
    private loader: LoaderService,
    private notification: NotificationsService,
    private dialogService: DialogService,

  ) {
    this.dataSource = new DocumentDataSource(this.documentService);
    this.dataSource.listPage.orderBy = IApiDocumentOrderBy.CreatedAt;
    this.dataSource.listPage.sortOrder = SortOrder.DESC;

    this.dataSource?.contents$.subscribe((res) => {
      this.tableData = res;
    });
  }

  ngOnInit() {
    // for editor tab sort by priority by default
    if (this.types.ReadyForEditor === this.config.type) {
      this.dataSource.listPage.orderBy = IApiDocumentOrderBy.Priority;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
      this.sort = [
        {
          field: IApiDocumentOrderBy.Priority,
          dir: 'asc'
        }
      ];
    }
    // filter by status
    this.loader.attachObservable(this.dataSource.loading$);
    this.dataSource.applyFilter(IApiDocumentFilterType.InvestigationReport, "true"); // only get documents considered reports
    this.dataSource.applyFilter(IApiDocumentFilterType.Status, this.config.viewType);
    if (this.config.viewType === IApiInvestigationReportStatus.TechReview) {
      this.dataSource.applyFilter(IApiDocumentFilterType.AssignedNotEditor, "true");
      this.dataSource.applyFilter(IApiDocumentFilterType.ViewTechReview, "true");
      let staffCreatePerm = false;
      let causePerm = false;
      let techReviewUpdatePerm = false;
      this.auth.hasCategoryPermission(investigationAssignStaffCreate.category, investigationAssignStaffCreate.appliedPermissions)
        .subscribe(res => {
          staffCreatePerm = res;
        })
      this.auth.hasCategoryPermission(investigationCauseUpdate.category, investigationCauseUpdate.appliedPermissions)
        .subscribe(res => {
          causePerm = res;
        })
      this.auth.hasCategoryPermission(investigationReportsInTechReviewUpdate.category, investigationReportsInTechReviewUpdate.appliedPermissions)
        .subscribe(res => {
          techReviewUpdatePerm = res;
        })
      if (staffCreatePerm) this.techReviewActions.push({ text: 'Assign to me' });
      if (causePerm) this.techReviewActions.push({ text: 'Indicate Cause' });
      if (causePerm && techReviewUpdatePerm) this.techReviewActions.push({ text: 'Send Back' }, { text: 'Approve' });
    }
    if (this.config.viewType === IApiInvestigationReportStatus.EditorReview) {
      this.dataSource.applyFilter(IApiDocumentFilterType.ViewEditorReview, "true");
      this.dataSource.applyFilter(IApiDocumentFilterType.Assigned, "true");
      let causePerm = false;
      let editorReviewUpdatePerm = false;

      this.auth.hasCategoryPermission(investigationCauseUpdate.category, investigationCauseUpdate.appliedPermissions)
        .subscribe(res => {
          causePerm = res;
        })
      this.auth.hasCategoryPermission(investigationReportsInEditorReviewUpdate.category, investigationReportsInEditorReviewUpdate.appliedPermissions)
        .subscribe(res => {
          editorReviewUpdatePerm = res;
        })

      if (causePerm) {
        this.editorReviewActions.push({ text: 'Indicate Cause' });
        this.clientReviewActions.push({ text: 'Indicate Cause' });
      }
      if (causePerm && editorReviewUpdatePerm) {
        this.editorReviewActions.push({ text: 'Send Back' }, { text: 'Send to Client Review' }, { text: 'Approve as Final' });
        this.clientReviewActions.push({ text: 'Send Back' }, { text: 'Approve as Final' });
      }
      this.editorReviewActions.push({ text: 'Unassign' });

    }
    if (this.config.viewType === IApiInvestigationReportStatus.ReadyForEditor) {
      this.dataSource.listPage.limit = 100;
      this.dataSource.applyFilter(IApiDocumentFilterType.ViewReadyforeditor, "true");
    }
    this.loader.show$(
      forkJoin(
        [
          this.load(),
          this.getAuthenticated(),
          this.investigationRoles.investigationRoles.pipe(tap((roles) => this.roles = roles))
        ]
      )
    ).subscribe();
    this.documentTypeService.get([{ type: IApiDocumentTypeFilterType.Categories, value: JSON.stringify([DocumentCategories.REPORT.valueOf()]) }], {
      // Need SortOrder or call will error
      // Need to specify a limit or it only returns the first 25
      sortOrder: SortOrder.ASC,
      orderBy: IApiDocumentTypeOrderBy.Name,
      limit: 1000
    }).pipe(
      unwrapConnection(),
      map<IApiDocumentType[], IApiDocumentType[]>(types => types.sort((a, b) => a.name < b.name ? -1 : 1))
    ).subscribe((result) => this._documentTypes = result);
  }

  private getAuthenticated(): Observable<IApiUser> {
    return this.auth.authenticatedUser.pipe(
      untilDestroyed(this),
      tap((user) => this.currentUser = user)
    );
  }
  // Actions via menu

  public assignToMe(doc: IApiDocument) {
    const data: IDashboardModalData = {
      dialogType: DashboardDialogType.ASSIGN,
      nefcoNumber: doc.Investigation.nefcoNumber,
      investigationId: doc.Investigation.id,
      document: doc
    };
    // call document update api directly as it will take care of adding staff to case record as well
    const addStaff: IApiAddInvestigationStaffInput = {
      UserId: this.currentUser?.id,
      InvestigationId: doc.Investigation?.id,
      RoleId: this.roles.find(({ title }) => title === "EDITOR").id,
      isPrimary: false
    };
    const editDocument: IApiUpdateDocumentInput = {
      id: doc?.id,
      EditorId: this.currentUser?.id
    }
    this.loader.show$(
      this.documentService.update(editDocument)
    ).pipe(
      tap(() => {
        const dialog: DialogRef = this.dialogService.open({
          content: DashboardModalViewComponent,
          width: "600px",
          preventAction: (ev) => {
            return ev !== ("closed" as any);
          },
        });

        const dialogInstance = dialog.content.instance as DashboardModalViewComponent;
        dialogInstance.data = data;
        dialog.result
          .pipe(
            filter((v: DialogCloseResult) => {
              return _.isEmpty(v) && _.isObject(v) ? false : !!v;
            })
          ).subscribe(() => {
            this.openInOffice365(doc)
          });
      })
    ).subscribe();

  }


  public assignToMeASCurrentPosition(doc: IApiDocument) {
    const addStaff: IApiAddInvestigationStaffInput = {
      UserId: this.currentUser?.id,
      InvestigationId: doc.Investigation?.id,
      RoleId: this.currentUser?.Positions?.length ? this.currentUser?.Positions[0].id : '',
      isPrimary: false
    };
    this.loader.show$(
      this.investigationStaff.add(addStaff),
    ).pipe(this.notification.snackbarErrorPipe("Unable to assign report")).subscribe((result) => {
      this.notification.notify("Report assigned successfully")
      this.load()
    });
  }

  public approve(doc: IApiDocument) {
    const data: IDashboardModalData = {
      dialogType: DashboardDialogType.APPROVE,
      nefcoNumber: doc.Investigation.nefcoNumber,
      investigationId: doc.Investigation.id,
      document: doc
    };
    const dialog: DialogRef = this.dialogService.open({
      content: DashboardModalViewComponent,
      width: "600px",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as DashboardModalViewComponent;
    dialogInstance.data = data;
    dialog.result
      .pipe(
        filter((v: DialogCloseResult) => {
          return _.isEmpty(v) && _.isObject(v) ? false : !!v;
        })
      ).subscribe(() => {
        this.load()
      });
  }

  public openInOffice365(doc: IApiDocument) {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationReportO365LaunchComponent,
      width: "45%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationReportO365LaunchComponent;
    dialogInstance.report =  doc;
    dialog.result.subscribe(() => this.load());
  }

  public indicateCause(doc: IApiDocument) {
    const modalData: IDashboardModalData = {
      dialogType: DashboardDialogType.INDICATE_CAUSE,
      nefcoNumber: doc.Investigation.nefcoNumber,
      investigationId: doc.Investigation.id,
      document: doc,
    };
    const dialog: DialogRef = this.dialogService.open({
      content: DashboardModalViewComponent,
      width: "600px",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as DashboardModalViewComponent;
    dialogInstance.data = modalData;
    dialog.result
      .pipe(
        filter((v: DialogCloseResult) => {
          return _.isEmpty(v) && _.isObject(v) ? false : !!v;
        })
      ).subscribe(() => {
        this.load()
      });
  }
  public sendBack(doc: IApiDocument) {
    const modalData: IDashboardModalData = {
      dialogType: DashboardDialogType.SENDBACK,
      nefcoNumber: doc.Investigation.nefcoNumber,
      investigationId: doc.Investigation.id,
      document: doc,
    };

    const dialog: DialogRef = this.dialogService.open({
      content: DashboardModalViewComponent,
      width: "600px",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as DashboardModalViewComponent;
    dialogInstance.data = modalData;
    dialog.result
      .pipe(
        filter((v: DialogCloseResult) => {
          return _.isEmpty(v) && _.isObject(v) ? false : !!v;
        })
      ).subscribe(() => {
        this.load()
      });
  }

  public approveAsFinal(doc: IApiDocument) {
    const modalData: IDashboardModalData = {
      dialogType: DashboardDialogType.APPROVE_FINAL,
      nefcoNumber: doc.Investigation.nefcoNumber,
      investigationId: doc.Investigation.id,
      document: doc
    };

    const dialog: DialogRef = this.dialogService.open({
      content: DashboardModalViewComponent,
      width: "600px",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as DashboardModalViewComponent;
    dialogInstance.data = modalData;
    dialog.result
      .pipe(
        filter((v: DialogCloseResult) => {
          return _.isEmpty(v) && _.isObject(v) ? false : !!v;
        })
      ).subscribe(() => {
        this.load()
      });
  }

  public unassign(id) {
    // unassign the report
  }


  // Change filter values
  public setStaff(userId?: string) {
    this.dataSource.applyFilter(IApiDocumentFilterType.User, userId);
    this.load();
  }

  public setSearch(event: string) {
    this.dataSource.applyFilter(IApiDocumentFilterType.Search, event);
  }

  public filterValue(filter: IApiDocumentFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return (value === "true" || value === "false") ? JSON.parse(value) : (value) ? value : null;
  }

  public setFilters(value: string | undefined, type: IApiDocumentFilterType) {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: value
    }] : filtersCopy;
  }

  public loadFilters(filterList: IApiDocumentFilter[], load = true) {
    // If no new filters, return
    if (_.isEqual(filterList, this.previousFilters)) return;
    this.previousFilters.forEach(({ type }) => this.dataSource.removeFilter(type)); // Remove previous filters and only apply the ones that came in
    filterList.forEach(({ type, value }) => this.dataSource.applyFilter(type, value));
    if (load) this.load();
    this.previousFilters = filterList;
  }

  public filterTable() {
    this.loadFilters(this.filters);
  }

  public clearFilter() {
    this.filters = [];
    this.filterTable();
  }

  public async load(): Promise<any> {
    this.dataSource.pagingReset();
    await this.dataSource.load();
    return this.total.emit(this.pageOptions.totalCount);
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'CREATED_AT';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public itemSelected(event, dataItem) {
    switch (event?.text) {
      case 'Indicate Cause':
        this.indicateCause(dataItem);
        break;
      case 'Send Back':
        this.sendBack(dataItem)
        break;
      case 'Approve as Final':
        this.approveAsFinal(dataItem)
        break;
      case 'Approve':
        this.approve(dataItem)
        break;
      case 'Unassign':
        this.unassign(dataItem)
        break;
      case "Assign to me":
        this.assignToMeASCurrentPosition(dataItem);
        break;
      case "Send to Client Review":
        this.approve(dataItem);
        break;
      default:
        break;
    }
  }

}
