import { filter } from 'rxjs/operators';
import { switchAll, toArray } from 'rxjs/operators';
import { DocumentShareService } from './../../../../shared/services/document/document-share/document-share.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { IApiPhotoFolder, IApiPhotoFolderFilterType, IApiInvestigation, IApiDocument, IApiDocumentFilterType } from 'src/app/shared/modules/graphql/types/types';
import { DocumentService, PhotoFolderService } from 'src/app/shared/services';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { DocumentCategories } from 'src/app/shared/modules/graphql/constants/document.constants';
import { DocumentStatus } from 'src/app/shared/modules/graphql/enums/document-snapshot.enums';
import {
  investigationPhotos,
  investigationPhotoShareCreate,
  investigationDocumentsListView,
  investigationDocumentShareCreate,
} from "src/app/shared/helpers/auth-config/investigations.config";
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { forkJoin } from 'rxjs';

interface ISelectableFolder extends IApiPhotoFolder {
  downloadOption: DownloadOptions;
}

interface IData {
  investigation: IApiInvestigation;
}

// Interfaces to allow download options for each photo folder
enum DownloadOptions {
  all = 'all',
  used = 'used',
  unused = 'unused',
  none = 'none'
}

@Component({
  selector: 'app-investigation-download-share-files-modal',
  templateUrl: './investigation-download-share-files-modal.component.html',
  styleUrls: ['./investigation-download-share-files-modal.component.scss']
})
export class InvestigationDownloadShareFilesModalComponent extends DialogContentBase implements OnInit {
  public authConfig = {
    investigationPhotos,
    investigationPhotoShareCreate,
    investigationDocumentsListView,
    investigationDocumentShareCreate,
  };

  @Input() data: IData;

  public error: string = null;

  public photoFolders: ISelectableFolder[] = [];
  public documents: IApiDocument[] = [];
  public selectedDocuments: string[] = [];

  public parties: { [x: string]: boolean } = {};

  public get investigation(): IApiInvestigation {
    return this.data.investigation;
  }

  constructor(
    public loader: LoaderService,
    private photoFolderService: PhotoFolderService,
    private documentService: DocumentService,
    private documentShares: DocumentShareService,
    private notifications: NotificationsService,
    public dialogRef: DialogRef
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.loader.show$(
      forkJoin([
        this.getDocuments(),
        this.getPhotoFolders(),
      ])
    ).subscribe(([documents, photoFolders]) => {
      this.documents = documents;
      this.photoFolders = photoFolders;
      this.photoFolders.map(a => a.downloadOption = DownloadOptions.none);
    });
  }

  public getDocuments() {
    return this.documentService.get([
      {
        type: IApiDocumentFilterType.Investigation,
        value: this.data.investigation.id
      }
    ]).pipe(
      unwrapConnection(),
      switchAll(),
      filter(({ Type: { Category }, Status }: IApiDocument) => (Category.name === DocumentCategories.UPLOAD) || (Category.name === DocumentCategories.REPORT && Status.status === DocumentStatus.FINAL)),
      toArray()
    );
  }

  public getPhotoFolders() {
    return this.photoFolderService.get([
      {
        type: IApiPhotoFolderFilterType.Investigation,
        value: this.data.investigation.id
      },
      {
        type: IApiPhotoFolderFilterType.ViewList,
        value: "true"
      }
    ], {}).pipe(
      unwrapConnection()
    );
  }

  public toggleDocument({ id }: IApiDocument) {
    const idx = this.selectedDocuments.indexOf(id);

    if (idx !== -1) {
      this.selectedDocuments.splice(idx, 1);
    }
    else {
      this.selectedDocuments.push(id);
    }
  }

  public toggleAllDocuments() {
    if (!this.documents.length) return;

    if (this.selectedDocuments.length === this.documents.length) {
      this.selectedDocuments = [];
    }
    else {
      this.selectedDocuments = this.documents.map(({ id }) => id);
    }
  }

  public toggleAllPhotos(event): void {
    event?.target?.checked ? this.photoFolders.map(a => a.downloadOption = DownloadOptions.all) : this.photoFolders.map(a => a.downloadOption = DownloadOptions.none);
  }

  public findSelectedPhotos() {
    const selectedPhotos = this.photoFolders.filter(p => p.downloadOption !== DownloadOptions.none);
    return selectedPhotos?.map(f => {
      let isUsed = null;
      switch (f.downloadOption) {
        case DownloadOptions.all:
          isUsed = null;
          break;
        case DownloadOptions.used:
          isUsed = true;
          break;
        case DownloadOptions.unused:
          isUsed = false
          break;
      }
      return {
        id: f.id,
        isUsed: isUsed
      }
    });
  }

  // Toggle "Select All" checkbox if all are selected
  public allPhotosSelected() {
    const download = this.photoFolders.filter(a => a.downloadOption === DownloadOptions.all).length;
    const all = this.photoFolders.length;
    return !!download && download === all;
  }

  // Check to see if selections are made. If so, share documents
  public share() {
    const { Client, Insured, BillTo } = this.parties;
    // Showing error text rather than closing the modal / using notifications service
    if (Client !== true && Insured !== true && BillTo !== true) {
      this.error = 'Please select a party to share documents with.';
      return;
    }

    if (this.findSelectedPhotos().length < 1 && this.selectedDocuments.length < 1) {
      this.error = 'No files are available to share with your selection.';
      return;
    }

    this.loader.show$(
      this.documentShares.add({
        InvestigationId: this.investigation.id,
        DocumentIds: this.selectedDocuments,
        Folders: this.findSelectedPhotos(),
        ClientId: Client ? this.investigation.Client.id : null,
        BillToId: BillTo ? this.investigation.BillTo.id : null,
        InsuredId: Insured ? this.investigation.Insured.id : null,
      })
    ).subscribe(() => {
      this.notifications.kendoConfirm("Your request to share files has been successfully queued. You will receive a confirmation email when they are ready. You can also find them under Investigation Details > Documents > Shared Documents section once they are available.", "Share Files Request", "", "Ok").subscribe(() => {
        this.close(true);
      });
    });
  }

  public close(result = false) {
    this.dialogRef.close(result);
  }

}
