import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IApiCertificationTypeOrderBy, IApiUser, IApiUserDetailStatus, IApiUserFilterType, IApiUserOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { AuthService, CertificationService, CertificationTypeService, InvestigationRoleService } from 'src/app/shared/services';
import { CreateUpdateStaffComponent } from 'src/app/components/shared/staff/create-update-staff/create-update-staff.component';

import { StateService } from 'src/app/shared/services/state.service';
import { UserDataSource, UserService } from 'src/app/shared/services/user';
import {
  staffCreateConfig, staffViewAll, staffInvestigationsList, staffCertificationsList, staffInvestigationsOwnList,
  staffCertificationsOwnList, staffEmergencyInformationView, staffEmergencyInformationOwnView, staffUserInformationView,
  staffUserInformationOwnView, staffContactInformationOwnView, staffContactInformationView
} from "src/app/shared/helpers/auth-config/staff.config";
import { filter, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
export interface IStaffListConfig {
  permissionsView?: boolean;
  permissionsType?: any; // Add interface for what roles can be passed in
  columns?: StaffListColumns[];
  selectableMode?: boolean;
}

export enum StaffListColumns {
  NAME = "name",
  NOTIFICATION_ENABLED = "notificationEnabled",
  POSITION = "position",
  ADDRESS = "address",
  PHONE = "phone",
  EMAIL = "email",
  CERTIFICATIONS = "certifications",
  ACTIONS = "actions",
  LOCATION = "location",
  SELECT = "select"
}

@UntilDestroy()
@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.scss']
})
export class StaffListComponent implements OnInit {

  public authConfig = {
    staffCreateConfig,
    staffViewAll,
    staffInvestigationsList,
    staffCertificationsList,
    staffInvestigationsOwnList,
    staffCertificationsOwnList,
    staffEmergencyInformationView,
    staffEmergencyInformationOwnView,
    staffUserInformationView,
    staffUserInformationOwnView,
    staffContactInformationOwnView,
    staffContactInformationView
  };


  @ViewChild('staffCertificationsList') staffCertificationsListPerm;
  @ViewChild('staffCertificationsOwnList') staffCertificationsOwnListPerm;
  @ViewChild('staffInvestigationsList') staffInvestigationsListPerm;
  @ViewChild('staffInvestigationsOwnList') staffInvestigationsOwnListPerm;

  // Fetch different data off of the type of permissions that are being viewed
  @Input() config: IStaffListConfig = {
    permissionsView: false,
    permissionsType: null,
    columns: null,
    selectableMode: false
  };
  @Output() done: EventEmitter<IApiUser[]> = new EventEmitter();
  @ViewChild("staffViewAll") staffViewAllPer;
  @ViewChild("staffEmergencyInformationView") staffEmergencyInformationViewPer;
  @ViewChild("staffEmergencyInformationOwnView") staffEmergencyInformationOwnViewPer;
  @ViewChild("staffUserInformationView") staffUserInformationViewPer;
  @ViewChild("staffUserInformationOwnView") staffUserInformationOwnViewPer;
  @ViewChild("staffContactInformationOwnView") staffContactInformationOwnViewPer;
  @ViewChild("staffContactInformationView") staffContactInformationViewPer;

  public columnsToDisplay = [StaffListColumns.NOTIFICATION_ENABLED, StaffListColumns.NAME, StaffListColumns.POSITION, StaffListColumns.ADDRESS, StaffListColumns.PHONE, StaffListColumns.EMAIL, StaffListColumns.CERTIFICATIONS, StaffListColumns.ACTIONS];
  public content: IApiUser[] = [];
  public selection = new SelectionModel<any>(true, []);
  public get isAllSelected(): boolean {
    return this.selection.selected.length === this.content.length;
  }

  public search = "";
  public orderByOptions = IApiUserOrderBy;

  public selectOpts = {
    positions: [],
    statuses: IApiUserDetailStatus,
    certifications: []
  };

  private _dataSource: UserDataSource;

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  // Status Filter
  public statusFilter = this.selectOpts.statuses.Active;
  public authenticatedUserId = null;
  public sort: SortDescriptor[] = [
    {
      field: IApiUserOrderBy.Lastname,
      dir: "asc",
    },
  ];

  public actionItems = [{
    text: 'Disable Notifications',
  },{
    text: 'Enable Notifications',
  },{
    text: 'View Profile',
  },{
    text: 'View Certifications',
  },{
    text: 'View Investigations',
  }];
  constructor(
    public stateService: StateService,
    private router: Router,
    private userService: UserService,
    private investigationRoleService: InvestigationRoleService,
    private certificationTypeService: CertificationTypeService,
    private loader: LoaderService,
    private certificationService: CertificationService,
    private authService: AuthService,
    private notifications: NotificationsService,
    private dialogService: DialogService,
  ) {
    this.loader.show$(forkJoin([
      // Get Certifications
      this.certificationTypeService.get([], {
        sortOrder: SortOrder.ASC,
        orderBy: IApiCertificationTypeOrderBy.Title,
        limit: -1
      }).pipe(
        unwrapConnection(),
        tap((certifications) => {
          this.selectOpts.certifications = certifications;
        })
      ),
      // Get Investigation Roles / Positions
      this.investigationRoleService.investigationRoles.pipe(
        tap((roles) => this.selectOpts.positions = roles)
      )
    ])
    ).subscribe();
  }

  public ngOnInit() {
    if (this.config.permissionsView) {
      this.columnsToDisplay = this.config.columns;
    } else if (this.config.selectableMode) {
      this.columnsToDisplay = [StaffListColumns.SELECT, StaffListColumns.NAME, StaffListColumns.POSITION, StaffListColumns.ADDRESS, StaffListColumns.PHONE, StaffListColumns.EMAIL];
    }

    this._dataSource = new UserDataSource(this.userService);
    this.loader.attachObservable(this._dataSource.loading$);
    this._dataSource.applyFilter(IApiUserFilterType.IsInactive, 'true');
    this._dataSource.applyFilter(IApiUserFilterType.ViewListView, 'true');
    this._dataSource.applyFilter(IApiUserFilterType.Status, this.selectOpts.statuses.Active);
    this._dataSource.listPage.orderBy = IApiUserOrderBy.Lastname;
    this.load();

    this.certificationService.get().pipe(
      untilDestroyed(this),
    ).subscribe();

    this.dataSource.contents$.pipe(untilDestroyed(this)).subscribe((data) => this.content = data);
    this.authService.authenticatedUser.subscribe((u) => this.authenticatedUserId = u.id);
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiUserOrderBy.Lastname;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load();
  };

  // public createStaff = () => this.router.navigate(['/admin/staff/manage']);

  public createStaff() {
    const dialog: DialogRef = this.dialogService.open({
      content: CreateUpdateStaffComponent,
      width: "80%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as CreateUpdateStaffComponent;
    dialogInstance.data = {};
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((res) => {
      if (res === true){
        this.load();
      }
    });
  }

  public deactivateStaff(id) {
    // TODO deactivate staff member based on ID
  }

  // Filter change hooks
  public onSearchChange() {
    this.dataSource.applyFilter(IApiUserFilterType.Search, this.search);
    this.load();
  }

  public onPositionChange(event: any) {
    this.dataSource.applyFilter(
      IApiUserFilterType.Position,
      event?.title === "All Position" ? null : JSON.stringify(event?.title)
    );
    this.load();
  }

  public onTimeChange(event: any) {
    this.dataSource.applyFilter(IApiUserFilterType.IsFullTime, event.value || undefined);
    this.load();
  }

  public onStatusChange(event: any) {
    this.dataSource.applyFilter(IApiUserFilterType.Status, event.value);
    this.load();
  }

  public onStateChange(event: any) {
    this.dataSource.applyFilter(IApiUserFilterType.State, event);
    this.load();
  }

  public onCertificationChange(event: any) {
    this.dataSource.applyFilter(IApiUserFilterType.Certification, event?.id);
    this.load();
  }

  public masterToggle() {
    this.isAllSelected ? this.selection.clear() : this.content.forEach(row => this.selection.select(row));
  }

  public removeChip(sel) {
    const index = this.selection.selected.indexOf(sel);

    if (index >= 0) {
      this.selection.selected.splice(index, 1);
    }
  }

  public save() {
    this.done.emit(this.selection.selected);
  }

  private load() {
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public profileView(userId) {
    return this.staffViewAllPer?.enabled &&
      (
        (this.staffEmergencyInformationViewPer?.enabled || (this.staffEmergencyInformationOwnViewPer?.enabled && this.authenticatedUserId === userId)) ||
        (this.staffUserInformationViewPer?.enabled || (this.staffUserInformationOwnViewPer?.enabled && this.authenticatedUserId === userId)) ||
        (this.staffContactInformationViewPer?.enabled || (this.staffContactInformationOwnViewPer?.enabled && this.authenticatedUserId === userId))
      )
  }

  public updateUser(user: IApiUser, enabled: boolean = false) {
    this.userService.updateUser({ id: user?.id, notificationEnabled: enabled }).pipe(this.notifications.snackbarPipe(enabled ? 'Notifications Enabled' : 'Notifications Disabled')).subscribe();
  }

  public filteredActionItems(staff: IApiUser) {
    return this.actionItems.filter(item => {
      switch (item.text) {
        case 'Disable Notifications':
          return !!staff?.notificationEnabled; // Show if notifications are enabled
        case 'Enable Notifications':
          return !staff?.notificationEnabled; // Show if notifications are disabled
        case 'View Profile':
          return this.profileView(staff.id);
        case 'View Certifications':
          return this.staffCertificationsListPerm.enabled || (this.staffCertificationsOwnListPerm.enabled && this.authenticatedUserId === staff.id)
        case 'View Investigations':
          return this.staffInvestigationsListPerm.enabled || (this.staffInvestigationsOwnListPerm.enabled && this.authenticatedUserId === staff.id)
        default:
          return true;
      }
    });
  }
  

  public itemSelected(action: { text: string }, dataItem: IApiUser) {
    switch (action.text) {
      case "Disable Notifications":
        this.updateUser(dataItem, false); dataItem.notificationEnabled = false;
        break;
      case "Enable Notifications":
        this.updateUser(dataItem, true); dataItem.notificationEnabled = true;
        break;
      case "View Profile":
        this.router.navigate(['/', 'staff', dataItem.id]);
        break;
      case "View Certifications":
        this.router.navigate(['/', 'staff', dataItem.id], { queryParams: { tab: 'certifications' } });
        break;
      case "View Investigations":
        this.router.navigate(['/', 'staff', dataItem.id], { queryParams: { tab: 'investigations' } });
        break;
      default:
        break;
    }
  }
}
