import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { pipe } from 'rxjs/internal/util/pipe';
import { switchMap } from 'rxjs/operators';
import { ReactiveFormHelper } from 'src/app/shared/helpers/reactive-form.class';
import { IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiUpdateInvestigationInput } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { InvestigationService } from 'src/app/shared/services/';


@Component({
  selector: 'app-reject-reactivate-modal',
  templateUrl: './reject-reactivate-modal.component.html',
  styleUrls: ['./reject-reactivate-modal.component.scss']
})
export class RejectReactivateModalComponent implements OnInit {

  public form = this.fb.group({
    description: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]]
  });

  public bodyMessage = 'this is body';

  @Input() opened: boolean = false;
  @Input() id: string = '';
  @Input() mode: string = '';
  @Input() minWidth: number = 600;
  @Input() width: number = 600;
  @Input() headerIcon: string = null;
  @Output() closeModel = new EventEmitter<string>();


  constructor(
    private fb: UntypedFormBuilder,
    private loader: LoaderService,
    private investigationService: InvestigationService,
    private notifications: NotificationsService) { }

  ngOnInit(): void {
    if (!this.mode) {
      this.closeModel.emit('close')
    }
  }

  close(): void {
    this.closeModel.emit('close')
  }

  submit(): void {
    if (this.form.valid) {
      if (this.mode === 'reject') {
        this.loader.show$(
          this.investigationService.reject(this.id).pipe(
            switchMap(() =>
              this.investigationService.update({
                id: this.id, History: {
                  InvestigationId: this.id,
                  updateCategory: IApiInvestigationUpdateCategories.Detail,
                  updateType: IApiInvestigationUpdateTypes.Delete,
                  comment: this.form.value.description?.trim() || ''
                }
              } as IApiUpdateInvestigationInput)),
            this.notifications.snackbarErrorPipe(),
            this.notifications.snackbarPipe("Investigation has been rejected")
          )
        ).subscribe((response) => {
          this.closeModel.emit('rejected')
        }, () => {
          this.closeModel.emit('close')
        })
      } else {
        this.loader.show$(
          this.investigationService.restore(this.id).pipe(
            switchMap(() =>
              this.investigationService.update({
                id: this.id, History: {
                  InvestigationId: this.id,
                  updateCategory: IApiInvestigationUpdateCategories.Detail,
                  updateType: IApiInvestigationUpdateTypes.Reactivate,
                  comment: this.form.value.description?.trim() || ''
                }
              } as IApiUpdateInvestigationInput)),
            this.notifications.snackbarErrorPipe(),
            this.notifications.snackbarPipe("Investigation has been Reactivated")
          )
        ).subscribe((response) => {
          this.closeModel.emit('reactivated')
        }, () => {
          this.closeModel.emit('close')
        })
      }

    } else {
      this.form?.markAllAsTouched();
    }
  }

}
