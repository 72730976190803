<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationStatusCreate" #investigationStatusCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationStatusList" #investigationStatusList="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <!-- START INVESTIGATION LIST FILTERS  -->
  <app-investigations-filter-card
    (filters)="filterInvestigations($event)"
    [userViewFilter]="apiUserFilterType"
    [showComments]="showComments"
    (commentToggle)="toggleCommentExpand($event)"
    *ngIf="config.type === type.investigations"
  >
  </app-investigations-filter-card>
  <!-- END INVESTIGATION LIST FILTERS  -->

  <div
    class="filter-controls"
    *ngIf="config.type !== type.investigations && loaded"
  >
    <ng-container *ngIf="config.type === type.user || config.type === type.contact">
      <kendo-gridlayout
        [rows]="['auto']"
        [gap]="{ rows: 5, cols: 16 }"
        [cols]="filterColumns"
        class="m-24"
      >
        <kendo-gridlayout-item class="mb-0" *ngIf="config.type === type.user">
          <div class="kendo-formfield">
            <kendo-label [for]="Status" text="Status"></kendo-label>
            <kendo-dropdownlist
              #Status
              [data]="InvestigationStatuses | keyvalue"
              textField="value"
              valueField="value"
              fillMode="outline"
              [valuePrimitive]="true"
              [defaultItem]="{ key: null, value: 'All' }"
              [value]="filterValue(filterTypes.Status) || null"
              [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250
              }"
              (selectionChange)="setStatus($event?.value !== 'All' ? $event?.value : null)"
            >
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="dataItem?.value === 'All'" class="placeholder">
                  {{ dataItem?.value }}
                </span>
                <span
                  *ngIf="dataItem?.value !== 'All'"
                  class="not-placeholder"
                >
                  {{ dataItem?.value }}
                </span>
              </ng-template>
            </kendo-dropdownlist>
          </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="mb-0" *ngIf="config.type === type.contact">
          <div class="kendo-formfield">
            <kendo-label text="Contact Role"></kendo-label>
            <kendo-dropdownlist
              [data]="contactRoles | keyvalue"
              textField="value"
              valueField="value"
              fillMode="outline"
              [valuePrimitive]="true"
              [defaultItem]="{ key: null, value: 'All' }"
              [value]="status || null"
              [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250
              }"
              (selectionChange)="setContactRole($event)"
            >
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="dataItem?.value === 'All'" class="placeholder">{{ dataItem?.value }}</span>
                <span *ngIf="dataItem?.value !== 'All'" class="not-placeholder">{{ dataItem?.value }}</span>
              </ng-template>
            </kendo-dropdownlist>
          </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="mb-0">
          <div class="kendo-formfield left-search-icon">
            <kendo-label text="Search"></kendo-label>
            <div style="position: relative">
              <i class="fa fa-search" aria-hidden="true"></i>
              <kendo-textbox
                name="searchBy"
                [(ngModel)]="searchValue"
                (appDebouncedInputChange)="searchChange($event)"
                (ngModelChange)="!searchValue ? searchChange(null) : null"
                placeholder="Search"
                fillMode="outline"
                [clearButton]="true"
              >
              </kendo-textbox>
            </div>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>

    <ng-container
      *ngIf="config.type !== type.user && config.type !== type.contact"
    >
      <div class="page-filters">
        <kendo-gridlayout
          [rows]="['auto']"
          [gap]="{ rows: 5, cols: 16 }"
          [cols]="filterColumns"
        >
          <!--START Search Company Filter -->
          <ng-container *ngIf="config.type !== type.pending">
            <kendo-gridlayout-item>
              <div class="kendo-formfield left-search-icon">
                <kendo-label [for]="searchtextbox" text="Search"></kendo-label>
                <div style="position: relative">
                  <i class="fa fa-search" aria-hidden="true"></i>
                  <kendo-textbox
                    #searchtextbox
                    [value]="searchValue"
                    (valueChange)="setFilters($event, filterTypes.Search)"
                    placeholder="Search"
                    fillMode="outline"
                    [clearButton]="false"
                  >
                  </kendo-textbox>
                </div>
              </div>
            </kendo-gridlayout-item>
          </ng-container>
          <!-- END Search Company Filter -->

          <!--START Search by staff member Filter -->
          <ng-container *ngIf="config.type !== type.pending">
            <kendo-gridlayout-item>
              <app-user-select-kendo
                label=""
                [userViewFilter]="userViewFilter"
                placeholder="Search by staff member"
                [userId]="userId"
                [includeInactive]="false"
                (selectionChanged)="setFiltersForUser($event?.id)"
              ></app-user-select-kendo>
            </kendo-gridlayout-item>
          </ng-container>
          <!-- END Search by staff member Filter -->

          <!-- START status filter -->
          <kendo-gridlayout-item *ngIf="config.type === type.ongoing">
            <div class="kendo-formfield">
              <kendo-label [for]="Status" text="Status"></kendo-label>
              <kendo-dropdownlist
                #Status
                [data]="InvestigationStatuses | keyvalue"
                textField="value"
                valueField="value"
                fillMode="outline"
                [valuePrimitive]="true"
                [defaultItem]="{ key: null, value: 'All' }"
                [value]="filterValue(filterTypes.Status) || null"
                [popupSettings]="{
                  appendTo: 'component',
                  animate: false,
                  width: 250
                }"
                required
                (selectionChange)="
                  setFilters(
                    $event?.value !== 'All' ? $event?.value : null,
                    filterTypes.Status
                  )
                "
                [disabled]="!investigationStatusList?.enabled"
              >
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span *ngIf="dataItem?.value === 'All'" class="placeholder">
                    {{ dataItem?.value }}
                  </span>
                  <span
                    *ngIf="dataItem?.value !== 'All'"
                    class="not-placeholder"
                  >
                    {{ dataItem?.value }}
                  </span>
                </ng-template>
              </kendo-dropdownlist>
            </div>
          </kendo-gridlayout-item>
          <!-- END status filter -->

          <!-- START Source Filter -->
          <ng-container *ngIf="config.type === type.pending">
            <kendo-gridlayout-item>
              <div class="kendo-formfield">
                <kendo-label [for]="dropdownlist1" text="Source"></kendo-label>
                <kendo-dropdownlist
                  #dropdownlist1
                  [data]="investigationSource"
                  textField="name"
                  valueField="name"
                  fillMode="outline"
                  [valuePrimitive]="true"
                  [defaultItem]="{ id: null, name: 'All' }"
                  [value]="filterValue(filterTypes.InvestigationSource)"
                  [popupSettings]="{
                    appendTo: 'component',
                    animate: false,
                    width: 250
                  }"
                  (selectionChange)="
                    setFilters($event?.name, filterTypes.InvestigationSource)
                  "
                >
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span *ngIf="dataItem?.name === 'All'" class="placeholder">
                      {{ dataItem.name }}
                    </span>
                    <span
                      *ngIf="dataItem?.name !== 'All'"
                      class="not-placeholder"
                    >
                      {{ dataItem.name }}
                    </span>
                  </ng-template>
                </kendo-dropdownlist>
              </div>
            </kendo-gridlayout-item>
          </ng-container>
          <!-- END Source Filter -->

          <!-- START Risk Type Filter -->
          <kendo-gridlayout-item>
            <div class="kendo-formfield">
              <kendo-label text="Risk Type"></kendo-label>
              <kendo-dropdownlist
                [data]="riskTypes"
                textField="name"
                valueField="name"
                fillMode="outline"
                [valuePrimitive]="true"
                [defaultItem]="{ id: null, name: 'All' }"
                [value]="filterValue(filterTypes.RiskType)"
                [popupSettings]="{
                  appendTo: 'component',
                  animate: false,
                  width: 250
                }"
                (selectionChange)="
                  setFilters($event?.name, filterTypes.RiskType)
                "
              >
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span *ngIf="dataItem?.name === 'All'" class="placeholder">
                    {{ dataItem.name }}
                  </span>
                  <span
                    *ngIf="dataItem?.name !== 'All'"
                    class="not-placeholder"
                  >
                    {{ dataItem.name }}
                  </span>
                </ng-template>
              </kendo-dropdownlist>
            </div>
          </kendo-gridlayout-item>
          <!-- END Risk Type Filter -->

          <!-- START Priority Filter -->
          <ng-container
            *ngIf="
              config.type !== type.readyToBill && config.type !== type.review && config.type !== type.ongoing
            "
          >
            <kendo-gridlayout-item>
              <div class="kendo-formfield">
                <kendo-label [for]="Priority" text="Priority"></kendo-label>
                <kendo-dropdownlist
                  #Priority
                  [data]="investigationPriorities"
                  textField="name"
                  valueField="name"
                  fillMode="outline"
                  [valuePrimitive]="true"
                  [defaultItem]="{ id: null, name: 'All' }"
                  [value]="filterValue(filterTypes.Priority)"
                  [popupSettings]="{
                    appendTo: 'component',
                    animate: false,
                    width: 250
                  }"
                  (selectionChange)="
                    setFilters($event?.name, filterTypes.Priority)
                  "
                >
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span *ngIf="dataItem?.name === 'All'" class="placeholder">
                      {{ dataItem?.name }}
                    </span>
                    <span
                      *ngIf="dataItem?.name !== 'All'"
                      class="not-placeholder"
                    >
                      {{ dataItem?.name }}
                    </span>
                  </ng-template>
                </kendo-dropdownlist>
              </div>
            </kendo-gridlayout-item>
          </ng-container>
          <!-- END Priority Filter -->

          <!-- START Nefco Office Filter -->
          <kendo-gridlayout-item [colSpan]="1"
            *ngIf="config.type === type.readyToBill">
            <app-nefco-office-multi-select
            [selectedId]="filterNefcoOfficeValue(filterTypes.NefcoOfficeIdIn)"
            (selectionChanged)="setNefcoOffice($event)"
            >
            </app-nefco-office-multi-select>
          </kendo-gridlayout-item>
          <!-- END Nefco Office Filter -->

          <kendo-gridlayout-item
            class="mb-16 filter-actions"
            [colSpan]="config.type === type.readyToBill || config.type === type.ongoing || config.type === type.progress ? 1 : 2"
            [col]="0"
            [row]="0"
          >
            <div
              class="mr-16 comments-filter"
              *ngIf="config.type !== tableType.readyToBill"
            >
              <kendo-label text=""></kendo-label>
              <div class="comment-toggle-group">
                <kendo-button
                  kendoButton
                  [themeColor]="showComments === true ? 'light' : 'base'"
                  fillMode="solid"
                  iconClass="fa-solid fa-comment"
                  (click)="toggleCommentExpand(true)"
                >
                </kendo-button>
                <kendo-button
                  kendoButton
                  [themeColor]="showComments === false ? 'light' : 'base'"
                  fillMode="solid"
                  iconClass="fa-solid fa-comment-slash"
                  (click)="toggleCommentExpand(false)"
                >
                </kendo-button>
              </div>
            </div>
            <div class="mr-16 applied-filter">
              <kendo-label text=""></kendo-label>
              <div>
                <kendo-button
                  kendoButton
                  themeColor="primary"
                  fillMode="solid"
                  size="medium"
                  (click)="filterTable()"
                  >Apply Filters</kendo-button
                >
              </div>
            </div>
            <div class="mt-5 clear-filter">
              <kendo-label text=""></kendo-label>
              <div>
                <kendo-button
                  kendoButton
                  themeColor="primary"
                  fillMode="link"
                  size="medium"
                  (click)="clearFilter()"
                  >Clear All</kendo-button
                >
              </div>
            </div>
          </kendo-gridlayout-item>

          <!-- START Priority Filter -->
          <ng-container *ngIf="config.type === type.ongoing">
            <kendo-gridlayout-item>
              <div class="kendo-formfield">
                <kendo-label [for]="Priority" text="Priority"></kendo-label>
                <kendo-dropdownlist
                  #Priority
                  [data]="investigationPriorities"
                  textField="name"
                  valueField="name"
                  fillMode="outline"
                  [valuePrimitive]="true"
                  [defaultItem]="{ id: null, name: 'All' }"
                  [value]="filterValue(filterTypes.Priority)"
                  [popupSettings]="{
                    appendTo: 'component',
                    animate: false,
                    width: 250
                  }"
                  (selectionChange)="
                    setFilters($event?.name, filterTypes.Priority)
                  "
                >
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span *ngIf="dataItem?.name === 'All'" class="placeholder">
                      {{ dataItem?.name }}
                    </span>
                    <span
                      *ngIf="dataItem?.name !== 'All'"
                      class="not-placeholder"
                    >
                      {{ dataItem?.name }}
                    </span>
                  </ng-template>
                </kendo-dropdownlist>
              </div>
            </kendo-gridlayout-item>
          </ng-container>
          <!-- END Priority Filter -->

          <!-- START Nefco Office Filter -->
          <kendo-gridlayout-item [colSpan]="config.type === type.ongoing || config.type === type.progress ? 1 : 2"
            *ngIf="config.type === type.progress || config.type === type.ongoing">
            <app-nefco-office-multi-select
            [selectedId]="filterNefcoOfficeValue(filterTypes.NefcoOfficeIdIn)"
            (selectionChanged)="setNefcoOffice($event)"
            >
            </app-nefco-office-multi-select>
          </kendo-gridlayout-item>
          <!-- END Nefco Office Filter -->
        </kendo-gridlayout>
      </div>
    </ng-container>

    <!-- Show Primary Investigator Only -->
    <div
      class="mb-16 d-flex filter-checkboxes"
      *ngIf="
        config.type === tableType.ongoing || config.type === tableType.progress
      "
    >
      <div class="d-flex align-items-center">
        <input
          type="checkbox"
          name="primaryOnly"
          #showPrimaryInvestigatorOnly
          kendoCheckBox
          [(ngModel)]="primaryInvestigator"
          (change)="showPrimary($event?.target?.checked)"
        />
        <kendo-label
          class="ml-12 mr-24 k-checkbox-filter-label"
          [for]="showPrimaryInvestigatorOnly"
          [text]="
            config.type === tableType.ongoing
              ? 'Show Primary Only'
              : 'Show Primary Investigator Only'
          "
        ></kendo-label>
      </div>
      <div class="d-flex align-items-center" *ngIf="config.type === tableType.progress">
        <input
          type="checkbox"
          name="unBilledEntryOnly"
          #showUnBilledEntryOnly
          kendoCheckBox
          [(ngModel)]="unBilledOnly"
          (change)="showUnBilledOnly($event?.target?.checked)"
        />
        <kendo-label
          class="ml-12 mr-24 k-checkbox-filter-label"
          [for]="showUnBilledEntryOnly"
          [text]="
           'Show Unbilled Diary Entries Only'
          "
        ></kendo-label>
      </div>
      <div class="d-flex align-items-center" *ngIf="config.type === tableType.progress">
        <input
          type="checkbox"
          name="markCloseEntryOnly"
          #showMarkClosed
          kendoCheckBox
          [(ngModel)]="markCloseOnly"
          (change)="showMarkClosedOnly($event?.target?.checked)"
        />
        <kendo-label
          class="ml-12 mr-24 k-checkbox-filter-label"
          [for]="showMarkClosed"
          [text]="
           'Show Marked to Close Investigations Only'
          "
        ></kendo-label>
      </div>
    </div>
    <div
      class="mb-16 d-flex justify-content-between"
      *ngIf="config.type === type.readyToBill"
    >
      <kendo-gridlayout-item>
        <kendo-formfield>
          <div class="kendo-radio">
            <input
              type="radio"
              id="NoReport"
              name="NoReport"
              [(ngModel)]="readyToBill"
              kendoRadioButton
              [value]="billingStatusOpts.NoReport"
              (change)="billingStatusChange(billingStatusOpts.NoReport)"
            />
            <label class="mr-24 k-radio-label" for="NoReport"
              >Ready To Bill / No Report</label
            >
            <input
              type="radio"
              id="HasReport"
              name="NoReport"
              [(ngModel)]="readyToBill"
              kendoRadioButton
              [value]="billingStatusOpts.HasReport"
              (change)="billingStatusChange(billingStatusOpts.HasReport)"
            />
            <label class="mr-24 k-radio-label" for="HasReport"
              >Ready To Bill / Has Report</label
            >
            <input
              type="radio"
              id="ongoingUnbilled"
              name="NoReport"
              [(ngModel)]="readyToBill"
              kendoRadioButton
              [value]="billingStatusOpts.Unbilled"
              (change)="billingStatusChange(billingStatusOpts.Unbilled)"
            />
            <label class="k-radio-label" for="ongoingUnbilled"
              >Ongoing Unbilled</label
            >
          </div>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </div>
  </div>

  <hr
    [ngClass]="
      config.type === type.investigations ? 'divider-line' : 'divider-line1'
    "
  />
  <kendo-grid
    class="{{
      config.type === type.readyToBill ? ' to-bill' : 'investigation-list'
    }}"
    [data]="{
      data: tableData,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }"
    (pageChange)="pageChange($event)"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [navigatable]="false"
    [kendoGridExpandDetailsBy]="'id'"
    [(expandedDetailKeys)]="expandedDetailKeys"
    (detailExpand)="onCellClick($event)"
  >
    <!-- view indicator -->
    <kendo-grid-column *ngIf="(config.type === type.pending)" field="NEFCO_NUMBER" title=""
      [width]="8" class="id-column" [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="config.type === type.pending">
          <div kendoTooltip position="right" title="Unread" tooltipClass="tooltipwidthauto" class="red-circle-icon" *ngIf="
                  dataItem?.InvestigationSource?.name === 'Online Intake Form' &&
                  dataItem?.firstViewDate === null
                ">
            <i class="fa-solid fa-circle-dot"></i>
          </div>
          <div kendoTooltip position="right" title="Waiting for reply" tooltipClass="tooltipwidthauto"
            *ngIf="isWaitingForReply(dataItem)">
            <i class="fa-solid fa-phone-volume"></i>
          </div>
          <div class="red-circle-icon" kendoTooltip position="right"
            title="Declined, need to assign a different investigator" tooltipClass="tooltipwidthauto"
            *ngIf="isAssnRequestDeclined(dataItem)">
            <i class="fa-solid fa-phone-slash"></i>
          </div>
        </ng-container>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="(config.type === type.readyToBill)" field="DOCUMENT_ACTIVE_IN_DRAFT" title="" [width]="8"
      class="id-column" [headerClass]="'sort-header'" [sortable]="true">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div kendoTooltip position="right" title="Active In Draft" tooltipClass="tooltipwidthauto" class="red-circle-icon" *ngIf="
                    dataItem?.ActiveAndDraft > 0
                  ">
          <i class="fa-solid fa-circle-dot"></i>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="config.type === type.progress" field="UNBILLED_ENTRY" [sortable]="true"
      [headerClass]="'sort-header'" title="" [width]="10" class="id-column">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem?.UnBilledEntries > 0;else second">
          <div kendoTooltip position="right" title="Billable time has not been sent to client" tooltipClass="tooltipwidth140" class="green-icon" *ngIf="dataItem?.UnBilledEntries >= 0">
            <i class="fa-solid fa-money-bill"></i>
          </div>
        </ng-container>
        <ng-template #second>
          <ng-container
            *ngIf="dataItem?.InvestigationMarkClose[0]?.markClosed === true && dataItem?.UnBilledEntries === 0;else third">
            <div class="red-circle-icon"
              *ngIf="dataItem?.InvestigationMarkClose[0]?.markClosed === true && dataItem?.UnBilledEntries === 0">
              <i class="fa-solid fa-minus-circle"></i>
            </div>
          </ng-container>
          <ng-template #third>-</ng-template>
        </ng-template>
      </ng-template>
    </kendo-grid-column>

    <!-- created at -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('createdAt') > -1"
      field="CREATED_AT"
      title="Created"
      [width]="30"
      class="id-column font-weight600"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span
          *ngIf="config.type === type.pending"
          class="text-transform-lowercase"
          >{{ dataItem.createdAt | customDate : "MM/dd/yyyy, hh:mma" }}<br
        /></span>
        <span *ngIf="config.type === type.pending" class="phone-number">{{
          dataItem?.InvestigationSource?.name === "Online Intake Form"
            ? "Via Online Form"
            : "Manual"
        }}</span>
        <span
          *ngIf="config.type !== type.pending"
          class="text-transform-lowercase"
          >{{ dataItem.createdAt | customDate : "MM/dd/yyyy" }}</span
        >
      </ng-template>
    </kendo-grid-column>

    <!-- nefco number -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('nefcoNumber') > -1"
      field="NEFCO_NUMBER"
      title="{{ config.type === type.investigations ? 'ID#' : 'ID' }}"
      [width]="25"
      class="id-column"
      [headerClass]="
        config.type !== type.progress && config.type !== type.readyToBill
          ? 'sort-header'
          : 'nocursor'
      "
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          target="_blank"
          [routerLink]="['/investigations/', dataItem?.id]"
          tel=""
          class="text-decoration"
        >
          {{ dataItem?.nefcoNumber || "Unassigned" }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <!-- priority -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('priority') > -1"
      field="PRIORITY"
      title="Priority"
      [width]="20"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-badge
          class="{{
            'fw600 ' +
              (dataItem?.Priority?.name === 'Low' ||
              dataItem?.Priority?.name === 'Medium'
                ? 'fcolor'
                : '')
          }}"
          position="none"
          size="medium"
          rounded="full"
          themeColor="{{
            dataItem?.Priority?.name === 'Low'
              ? 'tertiary'
              : dataItem?.Priority?.name === 'Medium'
              ? 'warning'
              : dataItem?.Priority?.name === 'High'
              ? 'error'
              : ''
          }}"
          >{{ dataItem?.Priority?.name }}</kendo-badge
        >
        <ng-container *ngIf="!dataItem?.Priority?.name">-</ng-container>
      </ng-template>
    </kendo-grid-column>

    <!-- lossDate -->
    <kendo-grid-column
      *ngIf="(columnsNameList?.indexOf('lossDate') > -1 &&  config.type !== type.readyToBill) || (readyToBill !== billingStatusOpts.HasReport && config.type === type.readyToBill && columnsNameList?.indexOf('lossDate') > -1 )"
      field="LOSS_DATE" title="Loss Date" [width]="25" [sortable]="true" [headerClass]="'sort-header'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{
          dataItem?.lossDate
          | customDate
          : (config.type === type.investigations
          ? "MMM d, y"
          : "MM/dd/yyyy") : true
          }}</span>
      </ng-template>
    </kendo-grid-column>

    <!-- riskType -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('riskType') > -1"
      field="RiskType"
      title="Risk Type"
      [width]="30"
      [sortable]="false"
      class="font-weight600"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="risk-type">
          <img
            class="risk-type-img"
            src="{{ getIcon(dataItem?.RiskType?.name) }}"
          />
          <span class="text-overflow">{{ dataItem?.RiskType?.name }}</span>
        </div>
      </ng-template>
    </kendo-grid-column>
    <!-- Report Submitted -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('recentSubmittedDocumentDate') > -1"
      field="RECENT_SUBMITTED_CREATE_DATE" title="Submitted Date" [width]="30" [sortable]="true"
      [headerClass]="'sort-header'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span> {{ (dataItem?.RecentSubmittedDocument?.Status?.createdAt | customDate : "MM/dd/yyyy") || '-' }}</span>
      </ng-template>
    </kendo-grid-column>
    <!-- Report Type -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('recentSubmittedDocumentType') > -1 && readyToBill === billingStatusOpts.HasReport"
      field="RECENT_SUBMITTED_TYPE" title="Report Type" [width]="30" [sortable]="true" class="font-weight600"
      [headerClass]="'sort-header'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span> {{ dataItem?.RecentSubmittedDocument?.Type?.name || '-' }}</span>
      </ng-template>
    </kendo-grid-column>

    <!-- LossAddress -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('lossAddress') > -1"
      field="lossAddress"
      title="Loss Address"
      [width]="30"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem?.LossAddress; else locationElse">
          <ng-container *ngIf="dataItem?.LossAddress?.address1">
            <span>{{ dataItem?.LossAddress?.address1 }}<br /></span>
          </ng-container>
          <ng-container *ngIf="dataItem?.LossAddress?.address2">
            <span>{{ dataItem?.LossAddress?.address2 }}<br /></span>
          </ng-container>
          <ng-container *ngIf="dataItem?.LossAddress?.address3">
            <span>{{ dataItem?.LossAddress?.address3 }}<br /></span>
          </ng-container>
          <span>
            {{
              dataItem?.LossAddress?.city
                ? dataItem?.LossAddress?.city + ","
                : ""
            }}
            {{ dataItem?.LossAddress?.state }}
            {{ dataItem?.LossAddress?.postal }}
          </span>
        </ng-container>
        <ng-template #locationElse>-</ng-template>
      </ng-template>
    </kendo-grid-column>

    <!-- Location -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('investigationLocation') > -1"
      field="InvestigationAddress"
      title="Location"
      [width]="35"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem?.InvestigationAddress; else locationElse">
          <ng-container *ngIf="dataItem?.InvestigationAddress?.address1">
            <span>{{ dataItem?.InvestigationAddress?.address1 }}<br /></span>
          </ng-container>
          <span>
            {{ dataItem?.InvestigationAddress?.city }},
            {{ dataItem?.InvestigationAddress?.state }}
            {{ dataItem?.InvestigationAddress?.postal }}
          </span>
        </ng-container>
        <ng-template #locationElse>-</ng-template>
      </ng-template>
    </kendo-grid-column>

    <!-- policyClaimNumbers -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('policyClaimNumbers') > -1"
      field="Detail"
      title="Policy/Claim #"
      [width]="30"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>Policy#:</span> {{ dataItem?.Detail?.policyNumber || "-" }}<br />
        <span>Claim#:</span> {{ dataItem?.Detail?.claimNumber || "-" }}
      </ng-template>
    </kendo-grid-column>

    <!-- Insured -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('contact') > -1"
      field="Insured"
      title="Insured"
      [width]="30"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem?.Insured | insuredName }}<br /></span>
        <span class="phone-number" *ngIf="dataItem?.Insured?.Phones">{{
          (dataItem?.Insured?.Phones | primaryAssociation)?.number
            | phoneFormat : "US"
        }}</span>
      </ng-template>
    </kendo-grid-column>

    <!-- Bill To -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('billTo') > -1"
      field="BillTo"
      title="Bill To"
      [width]="30"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container
          *ngIf="
            !dataItem?.BillTo &&
              !dataItem?.BillToBranch?.BillingAddress &&
              !dataItem?.BillTo?.BillingAddress;
            else billToInfo
          "
          >-</ng-container
        >
        <ng-template #billToInfo>
          <span *ngIf="dataItem?.BillTo"
            >{{ dataItem?.BillTo | fullName }} <br
          /></span>
          <ng-container *ngIf="dataItem?.BillToBranch; else BillToAddress">
            <ng-container
              *ngIf="dataItem?.BillToBranch?.BillingAddress?.address1"
            >
              <span
                >{{ dataItem?.BillToBranch?.BillingAddress?.address1 }}<br
              /></span>
            </ng-container>
            <span>
              {{ dataItem?.BillToBranch?.BillingAddress?.city }},
              {{ dataItem?.BillToBranch?.BillingAddress?.state }}
              {{ dataItem?.BillToBranch?.BillingAddress?.postal }}
            </span>
          </ng-container>
          <ng-template #BillToAddress>
            <ng-container *ngIf="dataItem?.BillTo?.BillingAddress?.address1">
              <span
                >{{ dataItem?.BillTo?.BillingAddress?.address1 }}<br
              /></span>
            </ng-container>
            <span>
              {{ dataItem?.BillTo?.BillingAddress?.city }},
              {{ dataItem?.BillTo?.BillingAddress?.state }}
              {{ dataItem?.BillTo?.BillingAddress?.postal }}
            </span>
          </ng-template>
        </ng-template>
      </ng-template>
    </kendo-grid-column>

    <!-- Client -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('client') > -1"
      field="Client"
      title="Client"
      [width]="config.type === type.investigations ?  35 : 30"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container
          *ngIf="
            !dataItem?.Client?.firstName &&
              !dataItem?.Client?.lastName &&
              !dataItem?.ClientBranch;
            else cliendInfo
          "
          >-</ng-container
        >
        <ng-template #cliendInfo>
          <span *ngIf="dataItem?.Client"
            >{{ dataItem?.Client | fullName }} <br
          /></span>
          <span *ngIf="dataItem?.ClientBranch?.Company?.name"
            >{{ dataItem?.ClientBranch?.Company?.name }}<br
          /></span>
          <span> {{ dataItem?.ClientBranch?.name }}</span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>

    <!-- Assigned -->
    <!-- <kendo-grid-column
      *ngIf="
        columnsNameList?.indexOf('assigned') > -1 &&
        config.type === type.investigations
      "
      field="InvestigationStaff"
      title="Assigned"
      [width]="config.type === type.investigations ?  30 : 25"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem?.InvestigationStaff">
          <span
            class="d-block"
            *ngFor="
              let staff of orderInvestigationStaff(
                dataItem?.InvestigationStaff
              );
              let i = index
            "
            >{{ staff.User | fullName }}</span
          >
        </ng-container>
        <ng-container *ngIf="dataItem?.InvestigationStaff?.length === 0"
          >-</ng-container
        >
      </ng-template>
    </kendo-grid-column> -->

    <!-- Assigned with badge -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('assigned') > -1"
      field="InvestigationStaff"
      title="Assigned"
      [width]="35"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem?.InvestigationStaff?.length > 0">
          <div class="dflex assignedmore">
            <div class="assigned">
              <span
                *ngFor="
                  let staff of dataItem?.assigned | slice : 0 : 2;
                  let i = index
                "
              >
                <kendo-badge
                  *ngIf="i < 2"
                  class="{{
                    'fw600 assigned-badge' +
                      (i === 1 ? ' fcolor' : '') +
                      (i === 1 ? ' mt-5' : '')
                  }}"
                  position="none"
                  size="medium"
                  rounded="full"
                  themeColor="{{
                    i === 0 ? 'primary' : i === 1 ? 'tertiary' : ''
                  }}"
                >
                  <span>{{ staff.User | fullName }}</span>
                </kendo-badge>
              </span>
            </div>
            <!-- <div
              kendoTooltip
              position="top"
              *ngIf="dataItem?.assigned?.length > 2"
              [tooltipTemplate]="assignTooltip"
              filter="a[href]"
              tooltipClass="tooltipwidth200"
            >
              <a href="javascript:void(0)" class="more-assigne primary-color"
                >+{{ dataItem?.assigned?.length - 2 }}</a
              >
              <ng-template #assignTooltip widt>
                <span
                  class="dflex"
                  *ngFor="
                    let staff of dataItem?.assigned
                      | slice : 2 : dataItem?.assigned?.length
                  "
                >
                  {{ staff.User | fullName }}
                </span>
              </ng-template>
            </div> -->
          </div>
        </ng-container>
        <ng-container *ngIf="dataItem?.InvestigationStaff?.length === 0"
          >-</ng-container
        >
      </ng-template>
    </kendo-grid-column>

    <!-- Status -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('status') > -1"
      field="status"
      title="Status"
      [width]="20"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="text-overflow">
          {{
            dataItem?.status === "PENDING"
              ? "Pending"
              : dataItem?.status === "" || !dataItem?.status
              ? "-"
              : investigationStatusNames[dataItem?.status]
          }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <!-- Follow Up -->
    <kendo-grid-column
      class="text-center"
      *ngIf="columnsNameList?.indexOf('followUp') > -1"
      field="followUp"
      title="Follow Up"
      [width]="15"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span><i class="right-circle-icon fa-solid fa-circle-check"></i></span>
      </ng-template>
    </kendo-grid-column>

    <!-- Conflict -->
    <kendo-grid-column
      class="text-center"
      *ngIf="columnsNameList?.indexOf('conflicts') > -1"
      field="isConflict"
      title="Conflict"
      [width]="10"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <i
          *ngIf="
            dataItem?.conflictCheckRun &&
            !dataItem?.LinkedInvestigations?.length
          "
          class="right-circle-icon fa-solid fa-circle-check"
        ></i>
        <i
          *ngIf="
            !dataItem?.conflictCheckRun ||
            dataItem?.LinkedInvestigations?.length
          "
          class="minus-circle-icon fa-solid fa-circle-minus"
        ></i>
      </ng-template>
    </kendo-grid-column>

    <!-- Progress -->
    <kendo-grid-column
      class="text-center text-overfloe-unset"
      *ngIf="columnsNameList?.indexOf('progress') > -1"
      field="Services"
      title="Progress"
      [width]="20"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-badge
          [class]="'fw600 ' +
          (dataItem?.Progress === 100 ? '' : ' fcolor')"
          position="none"
          size="medium"
          rounded="full"
          [themeColor]="dataItem?.Progress < 100
          ? 'tertiary'
          : dataItem?.Progress === 100
          ? 'success'
          : ''"
          >{{ dataItem?.Progress }}%</kendo-badge
        >
      </ng-template>
    </kendo-grid-column>

    <!-- Action -->
    <kendo-grid-column
      *ngIf="columnsNameList?.indexOf('actions') > -1"
      field="action"
      title="Action"
      [width]="config.type === type.investigations ?  15 : 25"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngIf="config.type === type.pending">
          <button
            kendoButton
            themeColor="primary"
            fillMode="link"
            [routerLink]="['/investigations', dataItem.id]"
          >
            View Details
          </button>
        </div>
        <div *ngIf="config.type === type.investigations">
          <button
          kendoButton
          class="mr-8"
          themeColor="secondary"
          fillMode="link"
          iconClass="fa-solid fa-arrow-up-right-from-square"
          (click)="openInvestigationSidebar(dataItem)"
          >
          </button>
        </div>
        <ng-container *ngIf="config.type === type.progress && investigationStatusCreate?.enabled">
          <kendo-dropdownbutton
            [data]="dataItem?.InvestigationMarkClose[0]?.markClosed !== true ?[{ text: 'Send to Billing' },{ text: 'Mark to Close' },] : [{ text: 'Keep as Active' }]"
            themeColor="primary" fillMode="link" buttonClass="grid-action-items" iconClass="fa-solid fa-ellipsis"
            (itemClick)="itemSelected($event, dataItem)" [popupSettings]="{
                              appendTo: 'component',
                              animate: false,
                              width: 250,
                              popupClass: 'grid-action-items-menu'
                            }">
          </kendo-dropdownbutton>
        </ng-container>

        <ng-container *ngIf="config.type === type.readyToBill && investigationStatusCreate?.enabled">
            <kendo-dropdownbutton
              [data]="
                readyToBill === billingStatusOpts.Unbilled &&
                dataItem?.status === 'ONGOING'
                  ? [
                      { text: 'Hold for Billing' },
                      { text: 'Move to In Progress' }
                    ]
                  : (readyToBill === billingStatusOpts.HasReport ||
                      readyToBill === billingStatusOpts.NoReport) &&
                    dataItem?.status === 'HOLD_FOR_BILLING'
                  ? [
                      { text: 'Change to Ongoing' },
                      { text: 'Move to In Progress' }
                    ]
                  : [
                      { text: 'Change to Ongoing' },
                      { text: 'Hold for Billing' },
                      { text: 'Move to In Progress' }
                    ]
              "
              themeColor="primary"
              fillMode="link"
              buttonClass="grid-action-items"
              iconClass="fa-solid fa-ellipsis"
              (itemClick)="itemSelected($event, dataItem)"
              [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250,
                popupClass: 'grid-action-items-menu'
              }"
            >
            </kendo-dropdownbutton>
        </ng-container>

        <div *ngIf="config.type === type.ongoing && investigationStatusCreate?.enabled">
          <button
            kendoButton
            themeColor="primary"
            fillMode="link"
            (click)="closeInvestigation($event, dataItem)"
          >
            Close
          </button>
        </div>
      </ng-template>
    </kendo-grid-column>

    <ng-template
      *ngIf="config.type !== type.readyToBill"
      kendoGridDetailTemplate
      let-dataItem
      [kendoGridDetailTemplateShowIf]="displayComments"
    >
      <kendo-grid
        [data]="dataItem?.Comments?.slice(0, 5)"
        [pageable]="false"
        [scrollable]="'none'"
        [className]="'detail-items'"
      >
        <kendo-grid-column
          field="createdAt"
          title="Date/Time"
          [width]="30"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span style="text-transform: lowercase">
              {{ dataItem?.createdAt | customDate : "MM/dd/yy, h:mma" }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="text"
          title="Comment"
          [width]="120"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <p class="word-break-p">
              {{(dataItem?.showMore) ? (dataItem?.text || '-') : dataItem?.text |
              slice:0:showMoreChar}}
              <span
                *ngIf="!dataItem?.showMore && dataItem?.text?.length > showMoreChar">...</span>
              <a href="javascript:;" class="primary-color"
                *ngIf="!dataItem?.showMore && dataItem?.text?.length > showMoreChar"
                (click)="dataItem['showMore']=true">Show More</a>
              <a href="javascript:;" class="primary-color" *ngIf="dataItem?.showMore"
                (click)="dataItem['showMore']=false">Show Less</a>
            </p>
            <!-- <span class="text-overflow">{{ dataItem?.text }}</span> -->
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="CreatedBy"
          title="User"
          [width]="50"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem?.CreatedBy | fullName }}
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </ng-template>
    <ng-template
      *ngIf="config.type === type.readyToBill"
      kendoGridDetailTemplate
      let-dataItemMain
    >
      <!-- Bill Info -->
      <div class="time-expence-title">
        <span class="title">Time & Expenses</span>
        <button
          kendoButton
          themeColor="primary"
          fillMode="solid"
          (click)="createInvoice($event, dataItemMain)"
        >
          Create Invoice
        </button>
      </div>
      <!-- Billing Data -->
      <kendo-grid
        [data]="timeAndExpenses"
        [pageable]="false"
        [scrollable]="'none'"
        [className]="'detail-items'"
      >
        <!-- Date -->
        <kendo-grid-column
          field="createdAt"
          title="Date"
          [width]="30"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span style="text-transform: lowercase">
              {{ (dataItem?.createdAt | customDate : "MM/dd/yyyy") || "-" }}
            </span>
          </ng-template>
        </kendo-grid-column>

        <!-- Staff -->
        <kendo-grid-column
          field="User"
          title="Staff"
          [width]="30"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="">{{ (dataItem.User | fullName) || "-" }}</span>
          </ng-template>
        </kendo-grid-column>

        <!-- Item -->
        <kendo-grid-column
          field="ExpenseItem"
          title="Item"
          [width]="30"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem?.ExpenseItem?.name || "-" }}
          </ng-template>
        </kendo-grid-column>

        <!-- Purpose -->
        <kendo-grid-column
          field="ExpenseItem"
          title="Purpose"
          [width]="40"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span
              class="text-overflow"
              *ngIf="dataItem.__typename === 'TimeEntry'; else expenseCell"
            >
              {{ dataItem?.Type.name || "-" }}
            </span>
            <ng-template #expenseCell>
              <span class="text-overflow">{{
                dataItem?.ExpensePurpose?.name || "-"
              }}</span>
            </ng-template>
          </ng-template>
        </kendo-grid-column>

        <!-- Non-Billable -->
        <kendo-grid-column
          field="ExpenseItem"
          title="Non-Billable"
          [width]="30"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.__typename === 'TimeEntry'; else expenseCell">
            </span>
            <ng-template #expenseCell>
              <span>{{
                dataItem.ExpenseItem?.name === "Mileage"
                  ? mileage(dataItem?.nonBillableQuantity)
                  : (dataItem?.nonBillableQuantity || 0 | currency)
              }}</span>
            </ng-template>
          </ng-template>
        </kendo-grid-column>

        <!-- Billable -->
        <kendo-grid-column
          field="ExpenseItem"
          title="Billable"
          [width]="30"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span
              *ngIf="dataItem?.__typename === 'TimeEntry'; else expenseCell"
            >
              {{ dataItem?.hours }}hrs
            </span>
            <ng-template #expenseCell>
              <span>
                {{
                  dataItem?.ExpenseItem?.name === "Mileage"
                    ? mileage(dataItem?.billableQuantity)
                    : (dataItem?.billableQuantity || 0 | currency)
                }}
              </span>
            </ng-template>
          </ng-template>
        </kendo-grid-column>

        <!-- description -->
        <kendo-grid-column
          field="description"
          title="Description"
          [width]="50"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span
              class="text-overflow"
              *ngIf="dataItem?.__typename === 'TimeEntry'; else expenseCell"
            >
              {{ dataItem?.description || "-" }}
            </span>
            <ng-template #expenseCell>
              <span class="text-overflow">{{
                dataItem?.description || "-"
              }}</span>
            </ng-template>
          </ng-template>
        </kendo-grid-column>

        <!-- Paid Billable -->
        <kendo-grid-column
          field="description"
          title="Paid Billable"
          [width]="30"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{
              dataItem?.paidDate
                ? (dataItem?.paidDate | customDate : "MM/dd/yyyy")
                : "N/A"
            }}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          field="id"
          title="Action"
          [width]="20"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <button
              kendoButton
              themeColor="primary"
              fillMode="link"
              (click)="editExpense(dataItem, $event, dataItemMain)"
            >
              Edit
            </button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>

      <!-- Evicence info -->
      <div class="time-expence-title">
        <div>
          <span class="mb-8 title">Evidence</span>
          <p class="muted" *ngIf="evidenceExpenses?.length">
            Initial evidence expense was created on
            {{ evidenceExpenses[0]?.expenseDate | customDate : "MM/dd/yyyy" }}.
          </p>
        </div>
        <button
          kendoButton
          themeColor="primary"
          fillMode="solid"
          (click)="addEvidenceExpense(dataItemMain)"
          [disabled]="(evidenceExpenses?.length && evidenceExpenses?.[0]?.InvoiceItems?.length) || evidenceExpenses?.length > 1"
        >
          CREATE FIRST EVIDENCE EXPENSE
        </button>
      </div>
      <kendo-grid
        *ngIf="dataItemMain?.EvidenceBilling"
        [data]="evidenceExpanded"
        [pageable]="false"
        [scrollable]="'none'"
        [className]="'detail-items'"
      >
        <!-- Date -->
        <kendo-grid-column
          field="createdAt"
          title="Arrival Date"
          [width]="30"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span style="text-transform: lowercase">
              {{ (dataItem?.createdAt | customDate : "MM/dd/yyyy") || "-" }}
            </span>
          </ng-template>
        </kendo-grid-column>

        <!-- description -->
        <kendo-grid-column
          field="description"
          title="Description"
          [width]="50"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="text-overflow">{{
              dataItem?.description || "-"
            }}</span>
          </ng-template>
        </kendo-grid-column>

        <!-- Staff -->
        <kendo-grid-column
          field="User"
          title="Entered By"
          [width]="30"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="">{{ (dataItem?.CreatedBy | fullName) || "-" }}</span>
          </ng-template>
        </kendo-grid-column>

        <!-- Rate -->
        <kendo-grid-column
          field="rate"
          title="Rate"
          [width]="20"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItemMain?.EvidenceBilling?.rate || "-" }}
          </ng-template>
        </kendo-grid-column>

        <!-- Schedule -->
        <kendo-grid-column
          field="rate"
          title="Schedule"
          [width]="20"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItemMain?.EvidenceBilling?.BillingFrequency?.name || "-" }}
          </ng-template>
        </kendo-grid-column>

        <!-- Billable -->
        <kendo-grid-column
          field="billable"
          title="Billable"
          [width]="25"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{
              expandedElement?.EvidenceBilling?.billable ? "Yes" : "No"
            }}
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>

      <!-- Invoices, Failed Pending info -->
      <div class="time-expence-title">
        <span class="title">Invoices, Failed &amp; Pending</span>
      </div>
      <kendo-grid
        [data]="invoices"
        [pageable]="false"
        [scrollable]="'none'"
        [className]="'detail-items'"
      >
        <!-- Date -->
        <kendo-grid-column
          field="date"
          title="Date"
          [width]="30"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span style="text-transform: lowercase">
              {{ (dataItem?.createdAt | customDate : "MM/dd/yyyy") || "-" }}
            </span>
          </ng-template>
        </kendo-grid-column>

        <!-- description -->
        <kendo-grid-column
          field="description"
          title="Description"
          [width]="50"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="text-overflow">{{ dataItem?.details || "-" }}</span>
          </ng-template>
        </kendo-grid-column>

        <!-- amount -->
        <kendo-grid-column
          field="amount"
          title="Amount"
          [width]="20"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="">
              ${{ calculateAmount(dataItem) | number : "1.2-2" }}
            </span>
          </ng-template>
        </kendo-grid-column>

        <!-- import errors -->
        <kendo-grid-column
          field="id"
          title="Import Errors"
          [width]="40"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-panelbar class="error-panelbar" *ngIf="dataItem?.QbErrors?.length">
              <kendo-panelbar-item *ngFor="let error of dataItem?.QbErrors" [expanded]="false">
                <ng-template kendoPanelBarItemTitle>
                  <h3 class="mb-0">{{error?.statusCode}}</h3>
                </ng-template>
                <ng-template kendoPanelBarContent>
                  <p class="p-12" [innerHtml]="error.statusMessage"></p>
                </ng-template>
              </kendo-panelbar-item>
            </kendo-panelbar>
          </ng-template>
        </kendo-grid-column>

        <!-- Exported -->
        <kendo-grid-column
          field="exported"
          title="Exported?"
          [width]="25"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-badge
              *ngIf="dataItem?.qbExported && !dataItem?.QbErrors.length"
              class="mr-16 fw600 high line-height16"
              position="none"
              size="small"
              rounded="full"
              themeColor="success"
              >Completed</kendo-badge
            >
            <kendo-badge
              *ngIf="dataItem?.qbTicketId && dataItem?.QbErrors.length"
              class="mr-16 fw600 high line-height16"
              position="none"
              size="small"
              rounded="full"
              themeColor="error"
              >Failed</kendo-badge
            >
            <kendo-badge
              *ngIf="
                (!dataItem?.qbExported && !dataItem?.qbTicketId) ||
                (!dataItem?.qbExported &&
                  dataItem?.qbTicketId &&
                  !dataItem?.QbErrors.length)
              "
              class="mr-16 fw600 high line-height16"
              position="none"
              size="small"
              rounded="full"
              themeColor="warning"
              >Pending</kendo-badge
            >
          </ng-template>
        </kendo-grid-column>

        <!-- invoice id -->
        <kendo-grid-column
          field="invoiceid"
          title="Invoice #"
          [width]="30"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem?.invoiceNumber || "-" }}
          </ng-template>
        </kendo-grid-column>

        <!-- Actions -->
        <kendo-grid-column
          field="id"
          title="Actions"
          [width]="30"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container *ngIf="!dataItem?.qbExported">
              <button
                kendoButton
                class="mr-8"
                themeColor="secondary"
                fillMode="link"
                iconClass="fa-solid fa-arrows-rotate"
                (click)="resubmitInvoice(dataItem)"
              ></button>
              <button
                kendoButton
                class="mr-8"
                themeColor="secondary"
                fillMode="link"
                iconClass="fa-solid fa-pencil"
                (click)="openQbDialog(dataItem)"
              ></button>
              <button
                kendoButton
                themeColor="secondary"
                fillMode="link"
                iconClass="fa-solid fa-trash"
                (click)="deleteInvoice(dataItem)"
              ></button>
            </ng-container>
            <button
              kendoButton
              themeColor="secondary"
              fillMode="link"
              iconClass="fa-solid fa-list"
              (click)="openQbDialog(dataItem, true)"
            ></button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </ng-template>
    <ng-template kendoGridNoRecordsTemplate>
      <p class="mt-24 mb-24">There are no results to display.</p>
    </ng-template>
  </kendo-grid>
</section>
<app-scroll-to-top *ngIf="config.type === type.investigations && loaded"></app-scroll-to-top>
