import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import dayjs from 'dayjs';
import { IApiInvestigation, IApiInvestigationOnScene, IApiInvestigationRoleNames, IApiInvestigationStaff } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { AuthService, InvestigationOnSceneService } from 'src/app/shared/services';
import {
  investigationScheduledDateUpdateAll,
  investigationScheduledDateUpdateOwn
} from "src/app/shared/helpers/auth-config/investigations.config";
import { InvestigationOnSceneStatus } from 'src/app/shared/modules/graphql/enums/investigation.enums';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { filter, switchMap } from 'rxjs/operators';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);
@Component({
  selector: 'app-investigation-edit-schedule-date',
  templateUrl: './investigation-edit-schedule-date.component.html',
  styleUrls: ['./investigation-edit-schedule-date.component.scss']
})
export class InvestigationEditScheduleDateComponent extends DialogContentBase implements OnInit {

  @Input() opened: boolean = false;
  @Input() minWidth: number = 800;
  @Input() width: number = 800;
  @Input() headerIcon: string = null;
  @Input() investigationStaff: IApiInvestigationStaff[] = null;
  @Input() investigation: IApiInvestigation = null;
  @Input() investigationOnScenes: IApiInvestigationOnScene[] = null;
  @Input() mode: string = 'NEXT_SCHEDULE';
  private removeScheduledEntryIds = [];

  public currentDate = new Date(dayjs().startOf('day').toDate());

  public staffList = [];
  public authConfig = {
    investigationScheduledDateUpdateAll,
    investigationScheduledDateUpdateOwn
  }
  public currentUser;
  public hasAllPermission: boolean = false;
  public scheduledFormEntry = [];
  constructor(
    private fb: UntypedFormBuilder,
    public dialog: DialogRef,
    private investigationOnSceneService: InvestigationOnSceneService,
    private loader: LoaderService,
    private auth: AuthService,
    private notifications: NotificationsService
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    this.staffList = this.staffSort(this.investigationStaff);
    this.setForm();
  }

  convert(scheduledDate, timezone) {
    const chicagoTime = dayjs(scheduledDate).tz(timezone);
    const hours = chicagoTime.hour();
    const minutes = chicagoTime.minute();
    const date = new Date(scheduledDate);
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setDate(chicagoTime.date());
    return date;
  }
  setForm() {
    this.auth.authenticatedUser.subscribe((u) => {
      this.currentUser = u;
      this.auth.hasCategoryPermission(this.authConfig.investigationScheduledDateUpdateAll.category, this.authConfig.investigationScheduledDateUpdateAll.appliedPermissions).subscribe((result) => {
        if (result === false) {
          this.investigationOnScenes = this.investigationOnScenes?.filter((onScene) => onScene?.User?.id === u.id);
        } else {
          this.hasAllPermission = true;
        }
        this.investigationOnScenes = this.investigationOnScenes?.filter((onScene) => onScene?.status === InvestigationOnSceneStatus.SCHEDULED);
        if (this.investigationOnScenes?.length) {
          this.investigationOnScenes?.forEach((onScene) => {
            const date = this.convert(onScene.scheduledDate, this.investigation?.timezone);
            this.addScheduledEntry({
              id: onScene?.id,
              scheduledDate: date,
              scheduledTime: date,
              UserId: onScene?.User?.id,
            });
          })
        } else {
          this.addScheduledEntry();
        }
      })
    }
    );

  }

  public trackByIndex(index: number) {
    return index + "_data";
  }


  addScheduledEntry(data?) {
    this.scheduledFormEntry.push({
      id: data?.id || '',
      scheduledDate: data?.scheduledDate || null,
      scheduledTime: data?.scheduledTime || '',
      InvestigationId: this.investigation.id || '',
      UserId: this.hasAllPermission === false ? this.currentUser.id : (data?.UserId || ''),
      timezone: data?.timezone || ''
    })
  }

  public removeScheduledEntry(index: number) {
    if (this.scheduledFormEntry[index]?.id) {
      this.removeScheduledEntryIds.push(this.scheduledFormEntry[index]?.id);
    }
    this.scheduledFormEntry.splice(index, 1);
  }

  close(): void {
    this.dialog.close();
  }

  submit(): void {
    const entries = this.scheduledFormEntry?.map(onScene => {
      const scheduledDate = dayjs(dayjs(onScene?.scheduledDate).format('YYYY-MM-DD') + 'T' + dayjs(onScene?.scheduledTime).format('HH:mm:ss')).tz(this.investigation.timezone, true).toISOString();
      return {
        id: onScene.id,
        InvestigationId: onScene?.InvestigationId,
        UserId: onScene?.UserId,
        scheduledDate: scheduledDate
      }
    })
    this.loader.show$(
      this.investigationOnSceneService.updateInvestigationOnSceneScheduledDate(
        {
          removeIds: this.removeScheduledEntryIds,
          data: entries
        }
      )
    ).subscribe(data => this.dialog.close(true))
  }

  deleteSingle(): void {
    this.notifications.kendoConfirm(
      "Are you sure you want to delete this on scene investigation? All data associated with this on scene investigation will be removed from Extranet 3.",
      "Delete On Scene Investigation?",
      "No, Don’t Delete",
      "Yes, Delete",
      600,
      "fa-solid fa-circle-exclamation fa-xl",
      this.investigation?.nefcoNumber ? `Investigation ID: ${this.investigation?.nefcoNumber}` : ""
    )
      .pipe(
        filter((v) => !!v),
        switchMap(() =>
          this.loader.show$(
            this.investigationOnSceneService.updateInvestigationOnSceneScheduledDate(
              {
                removeIds: [this.scheduledFormEntry[0]?.id],
                data: []
              }
            )
          ))
      )
      .subscribe(() => {
        this.dialog.close(true)
      });
  }

  public staffSort(list: IApiInvestigationStaff[]) {
    if (!list || !list.length) return [];
    let list_ = [...list];
    list_ = list?.filter((user) => user?.Role?.title === IApiInvestigationRoleNames.Investigator);
    list_.map(a => {
      a['userName'] = `${a?.User?.firstName} ${a?.User?.lastName}`;
      a['userId'] = a?.User?.id;
    });
    return list_.sort((a, b) => a.User?.lastName > b.User.lastName ? 1 : -1);
  }

}
