import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { groupBy } from '@progress/kendo-data-query';
import { IApiCompanyType, IApiCompanyTypeFilterType, IApiContact, IApiIntake } from 'src/app/shared/modules/graphql/types/types';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { forkJoin } from 'rxjs';
import { ReactiveFormHelper } from 'src/app/shared/helpers/reactive-form.class';
import { IState } from 'src/app/shared/interfaces/state.interfaces';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiCompanyFilterType, IApiContactRole, IApiContactRoleFilterType, IApiRejectContactInput, IApiUpdateAndReviewContactInput } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { removeTypename } from 'src/app/shared/rxjs.pipes';
import { CompanyTypeService, ContactRoleService, ContactService, PortalService, StateService } from 'src/app/shared/services';
import { sortBy } from 'lodash';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  templateUrl: './investigation-client-review-modal.component.html',
  styleUrls: ['./investigation-client-review-modal.component.scss']
})
export class InvestigationClientReviewModalComponent extends DialogContentBase implements OnInit {

  @Input() public set email(value: string) {
    this.form?.controls.email.setValue(value);
    this._email = value;
  }

  public get email(): string {
    return this._email;
  }

  @Input() public set id(value: string) {
    this.form?.controls.contactId.setValue(value);
    this._id = value;
  }

  public get id(): string {
    return this._id;
  }

  private _email: string;
  private _id: string;
  public contactRole: IApiContactRole[] = [];
  public stateList: Array<IState>;
  public stateFiltered: Array<IState>;
  public groupedDataStates: any;
  public carrierList: any[] = [];
  public isHiddenCarrier = false;
  public isSearching = false;
  public newCarrier = true;
  public showCompanyInformation: boolean = true;
  public showInsuranceCarrier: boolean = true;
  public currentContactInfo = null;
  public existingContactSelection: boolean = false;
  public selectedExistingContact = null;
  @ViewChild("companyList", { static: true }) public companyList: any;

  public form = this.fb.group({
    contactId: [this.id],
    email: [this.email, [Validators.required, ReactiveFormHelper.emailValidator, ReactiveFormHelper.noWhitespaceValidator]],
    company: this.fb.group({
      companyName: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      companyAddress: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      companyCity: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      companyState: ['', [Validators.required]],
      companyZip: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      companyPhone: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
    }),
    insuranceCarrierName: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
    jobTitle: ['', [Validators.required]],
    firstName: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
    contactNumber: ['', ReactiveFormHelper.noWhitespaceValidator],
    contactExtension: [''],
    address: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
    city: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
    state: ['', [Validators.required]],
    zip: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
    phone: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
    extension: [''],
    contactIsAdjuster: [false],
    branchId: [null],
    companyId: [null],
    companyTypeText: "",
    existingContactId: [""],
    companyTypeId: ['', [Validators.required]],
  })
  public submitted = false;
  public jobTitleList: IApiContactRole[] = [];
  public emailAddressCopied: boolean = false;
  public phoneNumberCopied: boolean = false;
  public companyTypesList: IApiCompanyType[] = [];
  public companyTypes: IApiCompanyType[] = [];
  private customInsuranceCarrierCase;

  constructor(
    public dialog: DialogRef,
    public fb: UntypedFormBuilder,
    public portalService: PortalService,
    public stateService: StateService,
    public loader: LoaderService,
    public contactService: ContactService,
    public notification: NotificationsService,
    private contactRoleService: ContactRoleService,
    private companyTypeService: CompanyTypeService,
    private clipboard: Clipboard
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    this.loader.show$(
      this.companyTypeService
        .get()
        .pipe(unwrapConnection(), removeTypename()))
      .subscribe((types) => {
        this.companyTypes = types;
        this.companyTypesList = types;
        this.companyTypeOptionsSet();
        //call get current contact after roles api
        if (this.id) {
          this.getCurrentContact();
        }
      });
    this.contactRoleService
      .get(
        [
          {
            type: IApiContactRoleFilterType.ViewListView,
            value: "true",
          },
        ],
        { sortOrder: SortOrder.ASC, limit: -1 }
      )
      .pipe(unwrapConnection(), removeTypename())
      .subscribe((jobTitleList) => {
        this.contactRole = this.jobTitleList = jobTitleList;
      });
    this.stateList = this.stateService.allStates;
    this.stateFiltered = this.stateService.allStates;
    this.groupedDataStates = groupBy(this.stateFiltered, [{ field: "type" }]);
  }

  companyTypeOptionsSet() {
    if (this.existingContactSelection) {
      this.companyTypes = this.companyTypesList;
    } else {
      this.companyTypes = this.companyTypesList?.filter(type => type?.isIntake === true);
    }
  }


  private getCurrentContact() {
    this.loader.show$(this.portalService.getCurrentContactById(this.id)).subscribe(res => {
      const currentContactInfo: IApiIntake = res?.data?.getCurrentContactById || null;
      this.currentContactInfo = currentContactInfo;

      if (currentContactInfo) {
         this.customInsuranceCarrierCase = !currentContactInfo?.Contact?.Branches[0]?.Company?.Type?.name && currentContactInfo?.ClientInvestigationNote?.insuranceCarrierName ? this.companyTypes?.find(type => type?.name === 'Insurance Company') : null;
        const name = `${currentContactInfo?.Contact?.firstName || ''} ${currentContactInfo?.Contact?.lastName || ''}`
        this.form.patchValue({ contactId: currentContactInfo?.Contact?.id });
        this.form.patchValue({ firstName: name });
        this.form.patchValue({ jobTitle: currentContactInfo?.Contact?.jobTitle });
        this.form.patchValue({ companyTypeId: currentContactInfo?.Contact?.Branches[0]?.Company?.Type?.id || this.customInsuranceCarrierCase?.id || '' })

        const contactNumber = currentContactInfo?.Contact?.Phones?.find(item => item?.isPrimary) || (currentContactInfo?.Contact?.Phones ? currentContactInfo?.Contact?.Phones[0] : null);
        this.form.patchValue({ contactNumber: contactNumber?.number || '', contactExtension: contactNumber?.extension || '' })
        if (currentContactInfo?.Contact?.Branches[0]?.Company?.Type?.name === 'Insurance Company' || this.customInsuranceCarrierCase?.id) {
          if (currentContactInfo?.Contact?.Branches?.length > 0) {
            this.form.patchValue({ branchId: currentContactInfo?.Contact?.Branches[0].id || null });
            this.form.patchValue({ companyId: currentContactInfo?.Contact?.Branches[0].Company?.id || null });
            this.form.patchValue({ insuranceCarrierName: currentContactInfo?.Contact?.Branches[0]?.Company?.name || '' });
            const primaryAddress: any = currentContactInfo?.Contact?.Branches[0]?.Addresses?.sort((a: any, b: any) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
            const primaryContact = currentContactInfo?.Contact?.Branches[0]?.Phones.find(item => item.isPrimary);
            if (primaryAddress?.length &&
              primaryAddress[0].address1 &&
              primaryAddress[0].city &&
              primaryAddress[0].state &&
              primaryAddress[0].postal) {
              this.form.patchValue({ address: primaryAddress[0]?.address1 || '' });
              this.form.patchValue({ city: primaryAddress[0]?.city || '' });
              this.form.patchValue({ state: primaryAddress[0]?.state || '' });
              this.form.patchValue({ zip: primaryAddress[0]?.postal || '' });
              this.form.patchValue({ phone: primaryContact?.number || '' });
              this.form.patchValue({ extension: primaryContact?.extension || '' });
            }
            this.form.get('address').disable();
            this.form.get('city').disable();
            this.form.get('state').disable();
            this.form.get('zip').disable();
            this.form.get('phone').disable();
            this.form.get('extension').disable();

          } else {
            this.form.patchValue({ branchId: null });
            this.form.patchValue({ insuranceCarrierName: currentContactInfo?.ClientInvestigationNote?.insuranceCarrierName || '' });
            this.form.patchValue({ address: currentContactInfo?.ClientInvestigationNote?.address || '' })
            this.form.patchValue({ city: currentContactInfo?.ClientInvestigationNote?.city || '' })
            this.form.patchValue({ state: currentContactInfo?.ClientInvestigationNote?.state || '' })
            this.form.patchValue({ zip: currentContactInfo?.ClientInvestigationNote?.zip || '' })
            this.form.patchValue({ phone: currentContactInfo?.ClientInvestigationNote?.phone || '' })
            this.form.patchValue({ extension: currentContactInfo?.ClientInvestigationNote?.extension || '' })
          }
        }
        else if (currentContactInfo?.Contact?.Branches[0]?.Company?.Type?.name === 'Other') {
          this.form.patchValue({
            company: {
              companyName: currentContactInfo?.Contact?.Branches[0]?.Company?.name || '',
              companyAddress: currentContactInfo?.Contact?.Branches[0]?.Addresses[0]?.address1 || '',
              companyCity: currentContactInfo?.Contact?.Branches[0]?.Addresses[0]?.city || '',
              companyState: currentContactInfo?.Contact?.Branches[0]?.Addresses[0]?.state || '',
              companyZip: currentContactInfo?.Contact?.Branches[0]?.Addresses[0]?.postal || '',
              companyPhone: currentContactInfo?.Contact?.Branches[0]?.Phones[0]?.number || ''
            }
          })
        }
        else {
          if (currentContactInfo?.Contact?.Branches?.length > 0) {
            this.form.patchValue({
              company: {
                companyName: currentContactInfo?.Contact?.Branches[0]?.Company?.name || '',
                companyAddress: currentContactInfo?.Contact?.Branches[0]?.Addresses[0]?.address1 || '',
                companyCity: currentContactInfo?.Contact?.Branches[0]?.Addresses[0]?.city || '',
                companyState: currentContactInfo?.Contact?.Branches[0]?.Addresses[0]?.state || '',
                companyZip: currentContactInfo?.Contact?.Branches[0]?.Addresses[0]?.postal || '',
                companyPhone: currentContactInfo?.Contact?.Branches[0]?.Phones[0]?.number || ''
              }
            })
          }
          if (currentContactInfo?.company?.id) {
            this.form.patchValue({ companyId: currentContactInfo?.company?.id })
            this.form.patchValue({ branchId: currentContactInfo?.company?.MainOffice?.id || (currentContactInfo?.company?.Branches?.length && currentContactInfo?.company?.Branches[0]?.id) || null });
            this.form.patchValue({ companyId: currentContactInfo?.company?.id || null });

            this.form.patchValue({ insuranceCarrierName: currentContactInfo?.company?.name || '' });
            this.form.patchValue({ address: (currentContactInfo?.company?.MainOffice?.Addresses?.length && currentContactInfo?.company?.MainOffice?.Addresses[0]?.address1) || (currentContactInfo?.company?.Branches?.length && currentContactInfo?.company?.Branches[0]?.Addresses[0]?.address1) || '' })
            this.form.patchValue({ city: (currentContactInfo?.company?.MainOffice?.Addresses?.length && currentContactInfo?.company?.MainOffice?.Addresses[0]?.city) || (currentContactInfo?.company?.Branches?.length && currentContactInfo?.company?.Branches[0]?.Addresses[0]?.city) || '' })
            this.form.patchValue({ state: (currentContactInfo?.company?.MainOffice?.Addresses?.length && currentContactInfo?.company?.MainOffice?.Addresses[0]?.state) || (currentContactInfo?.company?.Branches?.length && currentContactInfo?.company?.Branches[0]?.Addresses[0]?.state) || '' })
            this.form.patchValue({ zip: (currentContactInfo?.company?.MainOffice?.Addresses?.length && currentContactInfo?.company?.MainOffice?.Addresses[0]?.postal) || (currentContactInfo?.company?.Branches?.length && currentContactInfo?.company?.Branches[0]?.Addresses[0]?.postal) || '' })
            this.form.patchValue({ phone: (currentContactInfo?.company?.MainOffice?.Phones?.length && currentContactInfo?.company?.MainOffice?.Phones[0]?.number) || (currentContactInfo?.company?.Branches?.length && currentContactInfo?.company?.Branches[0]?.Phones[0]?.number) || '' })
            this.form.patchValue({ extension: (currentContactInfo?.company?.MainOffice?.Phones?.length && currentContactInfo?.company?.MainOffice?.Phones[0]?.extension) || (currentContactInfo?.company?.Branches?.length && currentContactInfo?.company?.Branches[0]?.Phones[0]?.extension) || '' })

          } else {
            this.form.patchValue({ branchId: null });
            this.form.patchValue({ insuranceCarrierName: currentContactInfo?.ClientInvestigationNote?.insuranceCarrierName || '' });
            this.form.patchValue({ address: currentContactInfo?.ClientInvestigationNote?.address || '' })
            this.form.patchValue({ city: currentContactInfo?.ClientInvestigationNote?.city || '' })
            this.form.patchValue({ state: currentContactInfo?.ClientInvestigationNote?.state || '' })
            this.form.patchValue({ zip: currentContactInfo?.ClientInvestigationNote?.zip || '' })
            this.form.patchValue({ phone: currentContactInfo?.ClientInvestigationNote?.phone || '' })
            this.form.patchValue({ extension: currentContactInfo?.ClientInvestigationNote?.extension || '' })
          }
        }
        this.form.get('address').disable();
        this.form.get('city').disable();
        this.form.get('state').disable();
        this.form.get('zip').disable();
        this.form.get('phone').disable();
        this.form.get('extension').disable();
        this.form.get('firstName').updateValueAndValidity();
        this.form.get('jobTitle').updateValueAndValidity();
        this.form.get('branchId').updateValueAndValidity();
        this.form.get('insuranceCarrierName').updateValueAndValidity();
        this.form.get('address').updateValueAndValidity();
        this.form.get('city').updateValueAndValidity();
        this.form.get('state').updateValueAndValidity();
        this.form.get('phone').updateValueAndValidity();
        this.form.get('extension').updateValueAndValidity();
        this.companyTypeChange(currentContactInfo?.Contact?.Branches[0]?.Company?.Type || this.customInsuranceCarrierCase)
      }
    });
  }

  public onClose(text: string): void {
    this.dialog.close(text);
  }

  public handleFilter(value) {
    this.stateFiltered = this.stateList.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    this.groupedDataStates = groupBy(this.stateFiltered, [{ field: "type" }]);
  }

  public clearInsuredCarrier() {
    const value = this.form.get('insuranceCarrierName').value;
    if (!value) {
      this.selectedCarrier(null);
    }
  }

  public selectedCarrier(item: any = null) {
    this.newCarrier = false;
    this.submitted = false;
    if (item) {
      this.form.patchValue({ insuranceCarrierName: item?.name });
      this.form.patchValue({ address: item?.address1 });
      this.form.patchValue({ city: item?.city });
      this.form.patchValue({ state: item?.state });
      this.form.patchValue({ zip: item?.postal });
      this.form.patchValue({ phone: item?.phone });
      this.form.patchValue({ extension: item?.extension });
      this.form.patchValue({ branchId: item?.branchId });
      this.form.patchValue({ companyId: item?.id });
      this.form.get('address').disable();
      this.form.get('city').disable();
      this.form.get('state').disable();
      this.form.get('zip').disable();
      this.form.get('phone').disable();
      this.form.get('extension').disable();
      this.newCarrier = false;
    } else {
      this.form.patchValue({ address: '' });
      this.form.patchValue({ city: '' });
      this.form.patchValue({ state: '' });
      this.form.patchValue({ zip: '' });
      this.form.patchValue({ phone: '' });
      this.form.patchValue({ extension: '' });
      this.form.patchValue({ branchId: null });
      this.form.patchValue({ companyId: null });
      this.form.get('address').disable();
      this.form.get('city').disable();
      this.form.get('state').disable();
      this.form.get('zip').disable();
      this.form.get('phone').disable();
      this.form.get('extension').disable();
      this.newCarrier = true;
    }
    this.form.get('address').updateValueAndValidity();
    this.form.get('city').updateValueAndValidity();
    this.form.get('state').updateValueAndValidity();
    this.form.get('zip').updateValueAndValidity();
    this.form.get('phone').updateValueAndValidity();
    this.form.get('extension').updateValueAndValidity();
    this.form.get('branchId').updateValueAndValidity();
    this.form.get('companyId').updateValueAndValidity();
    this.isHiddenCarrier = true;
    this.isSearching = false;

  }

  public findCarrier() {
    const value = this.form.get('insuranceCarrierName').value || '';
    if (!value) {
      this.form.get('insuranceCarrierName').markAsTouched();
      return;
    }
    this.isHiddenCarrier = false;
    this.loader.show$(
      this.portalService.getCompanies([{
        type: IApiCompanyFilterType.ViewListView,
        value: 'true'
      }, {
        type: IApiCompanyFilterType.Search,
        value: value
      }], { limit: 10 }).pipe(
        unwrapConnection(),
        removeTypename()
      )
    ).subscribe(results => {
      const data = results || [];
      const carrierArray = [];
      data.map(item => {
        if (item?.Branches?.length) {
          item?.Branches?.map(branch => {
            if (branch?.Addresses?.length) {
              const primaryAddress: any = branch?.Addresses?.sort((a: any, b: any) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
              const primaryPhones = branch?.Phones?.find((item: any) => item?.isPrimary);
              if (
                primaryAddress?.length &&
                primaryAddress[0].address1 &&
                primaryAddress[0].city &&
                primaryAddress[0].state &&
                primaryAddress[0].country &&
                primaryAddress[0].postal
              ) {
                carrierArray.push({
                  id: item?.id,
                  name: item?.name,
                  branchName: branch?.name,
                  branchId: branch?.id,
                  address1: primaryAddress[0]?.address1 || "",
                  address2: primaryAddress[0]?.address2 || "",
                  city: primaryAddress[0]?.city || "",
                  state: primaryAddress[0]?.state || "",
                  country: primaryAddress[0]?.country || "",
                  postal: primaryAddress[0]?.postal || "",
                  phone: primaryPhones?.number || "",
                  extension: primaryPhones?.extension || ""
                });
              }
            }
          })
        }
      })
      this.isSearching = true;
      this.carrierList = sortBy(carrierArray, ["state", "city"]);  //key point: Passing in an array of key names
    });
  }

  private scrollToNotificationControl() {
    document.querySelector(".client-approved-reject .k-window-content").scroll({
      top: 0,
      behavior: "smooth"
    });
  }

  public approved() {
    const values = this.form.value;
    this.submitted = false;
    if (!values.branchId && this.form.valid && values.companyTypeText != 'Other') {
      this.submitted = true;
      this.scrollToNotificationControl();
      return;
    }

    if (this.form.valid) {

      // send params based on jobtitle value
      let removeCompanyId = null;
      let company = {
      };
      if (values.companyTypeText === 'Insurance Company') {
        // values.branchId = this.currentContactInfo.company.id;
      } else if (values.companyTypeText === 'Other') {
        if (this.currentContactInfo?.Contact?.Branches[0]?.Company?.Type?.name !== 'Insurance Company' && !this.customInsuranceCarrierCase)
          company = {
            id: this.existingContactSelection ? this.selectedExistingContact?.Branches[0]?.Company?.id : this.currentContactInfo?.Contact?.Branches[0]?.Company?.id,
            name: values.company.companyName,
            branchId: this.existingContactSelection ? this.selectedExistingContact?.Branches[0]?.id : this.currentContactInfo?.Contact?.Branches[0]?.id
          };
        else {
          company = {
            name: values.company.companyName,
          }
        }
        if (this.existingContactSelection) removeCompanyId = this.currentContactInfo?.Contact?.Branches[0]?.Company?.id;
      } else {
        if (this.currentContactInfo?.Contact?.Branches[0]?.Company?.Type?.name !== 'Insurance Company' && !this.customInsuranceCarrierCase)
          company = {
            id: this.existingContactSelection ? this.selectedExistingContact?.Branches[0]?.Company?.id : this.currentContactInfo?.Contact?.Branches[0]?.Company?.id,
            name: values.company.companyName,
            branchId: this.existingContactSelection ? this.selectedExistingContact?.Branches[0]?.id : this.currentContactInfo?.Contact?.Branches[0]?.id
          };
        else {
          company = {
            name: values.company.companyName,
          }
        }
        if (this.existingContactSelection) removeCompanyId = this.currentContactInfo?.Contact?.Branches[0]?.Company?.id;
      }
      if ((this.currentContactInfo?.Contact?.Branches[0]?.Company?.Type?.name !== 'Insurance Company' && !this.existingContactSelection && !this.customInsuranceCarrierCase) || (this.existingContactSelection && values.companyTypeText !== 'Insurance Company')) {
        company['updateAddress'] = {
          id: this.currentContactInfo?.Contact?.Branches[0]?.Addresses[0]?.id,
          address1: values.company.companyAddress || '',
          city: values.company.companyCity || '',
          state: values.company.companyState || '',
          postal: values.company.companyZip || '',
          country: ReactiveFormHelper.findCountryByStateCode(this.stateService.allStates, values.company.companyState)
        };
        company['updatePhone'] = {
          id: this.currentContactInfo?.Contact?.Branches[0]?.Phones[0]?.id,
          number: values.company.companyPhone
        }
      } else if (!this.existingContactSelection) {
        company['address'] = {
          address1: values.company.companyAddress || '',
          isPrimary: true,
          city: values.company.companyCity || '',
          state: values.company.companyState || '',
          postal: values.company.companyZip || '',
          country: ReactiveFormHelper.findCountryByStateCode(this.stateService.allStates, values.company.companyState)
        };
        company['phone'] = {
          number: values.company.companyPhone || '',
          TypeId: "",
          isPrimary: true,
        }
      }
      let contactPhone = null;
      let contactPhoneUpdate = null;
      if (!this.existingContactSelection && values.contactNumber) {
        const contactNumber = this.currentContactInfo?.Contact?.Phones?.find(item => item?.isPrimary) || (this.currentContactInfo?.Contact?.Phones ? this.currentContactInfo?.Contact?.Phones[0] : null);
        if (contactNumber?.id) {
          contactPhoneUpdate = {
            number: values.contactNumber,
            id: contactNumber?.id,
            extension: values?.contactExtension || ''
          }
        } else {
          contactPhone = {
            number: values.contactNumber,
            extension: values?.contactExtension || '',
            TypeId: ""
          }
        }

      }

      const data: IApiUpdateAndReviewContactInput = {
        id: this.existingContactSelection ? this.currentContactInfo?.Contact?.id : values.contactId,
        firstName: values.firstName,
        jobTitle: values.jobTitle,
        CompanyAssignment: values.companyId,
        BranchAssignment: {
          BranchId: values.branchId || '',
          isPrimary: false,
          startDate: new Date()
        },
        company,
        existingContactId: values?.existingContactId,
        removeCompanyId,
        contactPhone: contactPhone,
        contactPhoneUpdate: contactPhoneUpdate,
        companyTypeId: values?.companyTypeId
      };
      this.loader.show$(this.contactService.approved(data))
        .pipe(
          this.notification.snackbarErrorPipe("Unable to approved client, please try again")
        ).subscribe(() => {
          this.notification.notify("Client approved successfully!");
          this.onClose('approved')
        })
    } else {
      this.form.markAllAsTouched();
    }

  }

  public reject() {
    if (this.form.valid) {
      const values = this.form.value;
      const data: IApiRejectContactInput = {
        id: values.contactId
      };
      this.loader.show$(this.contactService.reject(data))
        .pipe(
          this.notification.snackbarErrorPipe("Unable to reject client, please try again")
        ).subscribe(() => {
          this.notification.notify("Client rejected successfully!");
          this.onClose('reject')
        })
    } else {
      this.form.markAllAsTouched();
    }
  }

  public companyTypeChange(record) {
    this.form.get('companyTypeText').setValue(record?.name || this.companyList?.dataItem?.name || '');
    this.form.get('companyTypeText').updateValueAndValidity();
    if (this.companyList?.dataItem?.name === 'Insurance Company') {
      // Set Insurance carrier required and company information optional
      this.form.get('insuranceCarrierName').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.get('address').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.get('city').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.get('state').setValidators([Validators.required]);
      this.form.get('zip').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.get('phone').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);

      this.form.controls.company.get('companyName').clearValidators();
      this.form.controls.company.get('companyAddress').clearValidators();
      this.form.controls.company.get('companyCity').clearValidators();
      this.form.controls.company.get('companyState').clearValidators();
      this.form.controls.company.get('companyZip').clearValidators();
      this.form.controls.company.get('companyPhone').clearValidators();

      this.showCompanyInformation = false;
      this.showInsuranceCarrier = true;

    } else if (this.companyList?.dataItem?.name === 'Other') {
      // Set company information required and Insurance carrier optional
      this.form.get('insuranceCarrierName').clearValidators();
      this.form.get('address').clearValidators();
      this.form.get('city').clearValidators();
      this.form.get('state').clearValidators();
      this.form.get('zip').clearValidators();
      this.form.get('phone').clearValidators();

      this.form.controls.company.get('companyName').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.controls.company.get('companyAddress').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.controls.company.get('companyCity').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.controls.company.get('companyState').setValidators([Validators.required]);
      this.form.controls.company.get('companyZip').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      if (this.existingContactSelection) {
        this.form.controls.company.get('companyPhone').clearValidators();
      } else {
        this.form.controls.company.get('companyPhone').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      }

      this.showCompanyInformation = true;
      this.showInsuranceCarrier = false;
    } else {
      this.form.get('insuranceCarrierName').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.get('address').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.get('city').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.get('state').setValidators([Validators.required]);
      this.form.get('zip').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.get('phone').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      // Set Both required
      this.form.controls.company.get('companyName').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.controls.company.get('companyAddress').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.controls.company.get('companyCity').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.controls.company.get('companyState').setValidators([Validators.required]);
      this.form.controls.company.get('companyZip').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      if (this.existingContactSelection) {
        this.form.controls.company.get('companyPhone').clearValidators();
      } else {
        this.form.controls.company.get('companyPhone').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      }
      this.showCompanyInformation = true;
      this.showInsuranceCarrier = true;
    }

    this.form.controls.company.get('companyName').updateValueAndValidity();
    this.form.controls.company.get('companyAddress').updateValueAndValidity();
    this.form.controls.company.get('companyCity').updateValueAndValidity();
    this.form.controls.company.get('companyState').updateValueAndValidity();
    this.form.controls.company.get('companyZip').updateValueAndValidity();
    this.form.controls.company.get('companyPhone').updateValueAndValidity();
    this.form.get('insuranceCarrierName').updateValueAndValidity();
    this.form.get('address').updateValueAndValidity();
    this.form.get('city').updateValueAndValidity();
    this.form.get('state').updateValueAndValidity();
    this.form.get('zip').updateValueAndValidity();
    this.form.get('phone').updateValueAndValidity();
  }

  updateContactSelection(event) {
    this.selectedExistingContact = null;
    this.existingContactSelection = true;
    if (!event?.id) {
      this.form.reset();
      return;
    }
    this.loader.show$(this.contactService.getById(event?.id)).subscribe(res => {
      const contactInfo: IApiContact = res;
      this.selectedExistingContact = contactInfo;
      if (contactInfo) {
        const branch = contactInfo?.BranchAssignments?.find(item => item.endDate == null)?.Branch;
        const primaryAddress: any = branch?.Addresses?.sort((a: any, b: any) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
        const primaryContact = branch?.Phones?.find(item => item.isPrimary);
        const name = `${contactInfo?.firstName || ''} ${contactInfo?.lastName || ''}`
        this.form.patchValue({ existingContactId: contactInfo?.id });
        this.form.patchValue({ firstName: name });
        this.form.patchValue({ jobTitle: contactInfo?.Role?.name });
        if (branch?.Company?.Type?.name === 'Insurance Company') {
          if (branch) {
            this.form.patchValue({ branchId: branch?.id || null });
            this.form.patchValue({ companyId: branch?.Company?.id || null });
            this.form.patchValue({ insuranceCarrierName: branch?.Company?.name || '' });

            if (primaryAddress?.length &&
              primaryAddress[0].address1 &&
              primaryAddress[0].city &&
              primaryAddress[0].state &&
              primaryAddress[0].postal) {
              this.form.patchValue({ address: primaryAddress[0]?.address1 || '' });
              this.form.patchValue({ city: primaryAddress[0]?.city || '' });
              this.form.patchValue({ state: primaryAddress[0]?.state || '' });
              this.form.patchValue({ zip: primaryAddress[0]?.postal || '' });
              this.form.patchValue({ phone: primaryContact?.number || '' });
              this.form.patchValue({ extension: primaryContact?.extension || '' });
            }
            this.form.get('address').disable();
            this.form.get('city').disable();
            this.form.get('state').disable();
            this.form.get('zip').disable();
            this.form.get('phone').disable();
            this.form.get('extension').disable();

          }
        }
        else if (branch?.Company?.Type?.name === 'Other') {
          this.form.controls.company.get('companyName').setValue(branch?.Company?.name || '');
          if (primaryAddress?.length &&
            primaryAddress[0].address1 &&
            primaryAddress[0].city &&
            primaryAddress[0].state &&
            primaryAddress[0].postal)
            this.form.patchValue({
              company: {
                companyAddress: primaryAddress[0]?.address1 || '',
                companyCity: primaryAddress[0]?.city || '',
                companyState: primaryAddress[0]?.state || '',
                companyZip: primaryAddress[0]?.postal || '',
                companyPhone: primaryContact?.number || ''
              }
            })
        }
        else {
          if (branch) {
            this.form.controls.company.get('companyName').setValue(branch?.Company?.name || '');
            if (primaryAddress?.length &&
              primaryAddress[0].address1 &&
              primaryAddress[0].city &&
              primaryAddress[0].state &&
              primaryAddress[0].postal)
              this.form.patchValue({
                company: {
                  companyAddress: primaryAddress[0]?.address1 || '',
                  companyCity: primaryAddress[0]?.city || '',
                  companyState: primaryAddress[0]?.state || '',
                  companyZip: primaryAddress[0]?.postal || '',
                  companyPhone: primaryContact?.number || ''
                }
              })
          }
        }
        this.form.get('address').disable();
        this.form.get('city').disable();
        this.form.get('state').disable();
        this.form.get('zip').disable();
        this.form.get('phone').disable();
        this.form.get('extension').disable();
        this.form.get('firstName').updateValueAndValidity();
        this.form.get('jobTitle').updateValueAndValidity();
        this.form.get('branchId').updateValueAndValidity();
        this.form.get('insuranceCarrierName').updateValueAndValidity();
        this.form.get('address').updateValueAndValidity();
        this.form.get('city').updateValueAndValidity();
        this.form.get('state').updateValueAndValidity();
        this.form.get('phone').updateValueAndValidity();
        this.form.get('extension').updateValueAndValidity();
        this.form.patchValue({ jobTitle: contactInfo?.Role?.name });
        this.form.patchValue({ companyTypeId: branch?.Company?.Type?.id });
        this.companyTypeChange(contactInfo?.Branches[0]?.Company?.Type)
      }
    });
  }

  switchToExistingContactSearch(value) {
    this.form.controls.contactNumber.setValidators([ReactiveFormHelper.noWhitespaceValidator, Validators.pattern(/^\d{10}$/)]);
    this.form.controls.contactNumber.updateValueAndValidity();
    this.existingContactSelection = value;
    this.selectedExistingContact = null;
    this.customInsuranceCarrierCase = null;
    this.companyTypeOptionsSet();
    if (value === true) {
      this.form.get('email').clearValidators();
      this.form.get('email').updateValueAndValidity();
      this.form.reset();
    } else {
      this.form.reset();
      this.form.get('email').setValidators([Validators.required, ReactiveFormHelper.emailValidator, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.get('email').updateValueAndValidity();
      this.form.patchValue({
        email: this.email
      })
      this.getCurrentContact()
    }
  }

  copyText(value: any) {
    this.clipboard.copy(value);
  }

  copyEmailAddress() {
    this.emailAddressCopied = true;
    this.copyText(this.email);
    setTimeout(() => {
      this.emailAddressCopied = false;
    }, 3000)
  }

  copyPhoneNumber() {
    this.phoneNumberCopied = true;
    this.copyText(this.form.controls.contactNumber?.value || '');
    setTimeout(() => {
      this.phoneNumberCopied = false;
    }, 3000)
  }

  setContactPhoneValidator(field, event) {
    if (field === 'contactExtension' && event) {
      this.form.controls.contactNumber.setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator, Validators.pattern(/^\d{10}$/)]);
    } else {
      this.form.controls.contactNumber.setValidators([ReactiveFormHelper.noWhitespaceValidator, Validators.pattern(/^\d{10}$/)]);
    }
    this.form.controls.contactNumber.updateValueAndValidity();
  }
}
