import { filter, switchMap, tap } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CompanyBranchCreateModalComponent, ICompanyBranchCreateUpdateData } from '../company-branch-create-modal/company-branch-create-modal.component';

import { CompanyService } from '../../../../shared/services';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { ContactsListComponent, IContactListConfig } from '../../contacts/contacts-list/contacts-list.component';
import { CompanyBranchContactSelectComponent } from '../company-branch-contact-select/company-branch-contact-select.component';
import { removeTypename } from 'src/app/shared/rxjs.pipes';
import { IApiCompany } from 'src/app/shared/modules/graphql/types/types';
import { Observable } from "rxjs";
import { CompanyBranchListComponent } from '../company-branch-list/company-branch-list.component';
import { forkJoin } from 'rxjs';
import {
  companiesBranchesList,
  companiesBranchesCreate
} from "src/app/shared/helpers/auth-config/companies.config";

import {
  contacts,
  contactsCreateConfig
} from "src/app/shared/helpers/auth-config/contacts.config";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import _ from 'lodash';

@UntilDestroy()
@Component({
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss']
})
export class CompanyManagementComponent implements OnInit {

  public authConfig = {
    companiesBranchesList,
    companiesBranchesCreate,
    contacts,
    contactsCreateConfig
  };

  @ViewChild("contactList") contactList: ContactsListComponent;
  @ViewChild("branchList") branchList: CompanyBranchListComponent;

  public selectedTab = 0;
  public company: IApiCompany;

  public contactListConfig: IContactListConfig = {
    hideLists: true,
    companyId: "",
    hideAddContact: true,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private companyService: CompanyService,
    private loader: LoaderService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.route.params.pipe(
      untilDestroyed(this),
      switchMap((params) => this.loader.show$(this.getCompany(params.companyId)))
    ).subscribe();
    // TODO add ability to go directly to branches with a selected branch - see contact edit modal where a user can click on a branch, brings them here with branchId and tab=branches in params
    this.route.queryParams.pipe(
      untilDestroyed(this),
    ).subscribe(({ tab }) => {
      switch (tab) {
        case "branches":
          this.selectedTab = 1;
          break;
        case "contacts":
          this.selectedTab = 2;
          break;
        default:
          this.selectedTab = 0;
      }
    });

    const { companyId } = this.route.snapshot.params;
    this.contactListConfig.companyId = companyId;
  }

  private getCompany(id: string): Observable<IApiCompany> {
    return this.companyService.getById(id).pipe(
      removeTypename(),
      tap((company) => this.company = company)
    );
  }

  public goBack() {
    return this.router.navigate(['/admin', 'companies', 'list']);
  }

  public addContact() {
    const dialog: DialogRef = this.dialogService.open({
      content: CompanyBranchContactSelectComponent,
      width: "80%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });
    const dialogInstance = dialog.content.instance as CompanyBranchContactSelectComponent;
    dialogInstance.data = { company: this.company };
    dialog.result.subscribe((result)=> {
      if(result === true){
        this.reload();
      }
    })
  }

  public reload() {
    this.rehydrate().subscribe();
  }

  public rehydrate(): Observable<any> {
    return this.loader.show$(
      this.getCompany(this.company.id).pipe(
        switchMap(() => forkJoin([
          this.branchList ? this.branchList.load() : [],
          this.contactList ? this.contactList.load() : []
        ]))
      )
    );
  }

  public addBranch() {
    const dialog: DialogRef = this.dialogService.open({
      content: CompanyBranchCreateModalComponent,
      width: "80%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });
    const data: ICompanyBranchCreateUpdateData = {
      companyId: this.company.id,
      companyName: this.company.name
    }
    const dialogInstance = dialog.content.instance as CompanyBranchCreateModalComponent;
    dialogInstance.data = data;
    dialog.result
      .pipe(
        filter((v: DialogCloseResult) => {
          return _.isEmpty(v) ? false : !!v;
        }),
        switchMap(() => this.rehydrate())
      )
      .subscribe();
  }
}
