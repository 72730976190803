import { Component, Input, OnInit } from "@angular/core";
import { SortDescriptor } from "@progress/kendo-data-query";
import { IApiInvestigationMetrics, IApiInvestigationMetricsFilterType } from "src/app/shared/modules/graphql/types/types";
import { InvestigationStatusNames } from "src/app/shared/helpers/helper";
import { getRiskTypeIcon } from "src/app/shared/helpers/reactive-form.class";
import { SortOrder } from "src/app/shared/modules/graphql/enums/generic.enums";
import {
  IApiInvestigationOrderBy,
  IApiInvestigationStatusNames,
  IApiSortOrder,
} from "src/app/shared/modules/graphql/types/types";
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { NotificationsService } from "src/app/shared/modules/notifications/notifications.service";
import { DownloadHelper, InvestigationReportService, InvestigationService } from "src/app/shared/services";
import { InvestigationReportDataSource } from "src/app/shared/services/investigation/investigation-report/investigation-report.datasource";

export enum Columns {
	nefcoNumber = "nefcoNumber",
	status = "status",
	lossDate = "lossDate",
	insured = "insured",
	dateReceived = "dateReceived",
	expenseDate = "expenseDate",
	riskType = "riskType",
	address = "address",
	city = "city",
	state = "state",
	postal = "postal",
	claimNumber = "claimNumber",
	policyNumber = "policyNumber",
	billTo = "billTo",
	investigator = "investigator",
	billedInvoiceTotal = "billedInvoiceTotal",
	riskDate = "riskDate",
	evidenceStatusDate = "evidenceStatusDate",
	evidenceStatus = "evidenceStatus",
	evidenceNumber = "evidenceNumber",
	description = "description",
	evidenceLocation = "evidenceLocation",
  billingRule = "billingRule",
  investigatorOnSceneDate = "investigatorOnSceneDate",
  daysToInsuredContacted = 'daysToInsuredContacted',
  daysToOnScene = 'daysToOnScene',
  daysToWrittenSummary = 'daysToWrittenSummary',
  docuSketch = 'docuSketch',
  client= 'client',
  clientEmail = 'clientEmail',
  billToEmail = 'billToEmail'
}

export interface IInvestigationTableConfig {
  type?: ReportTableType;
  displayedColumns?: Columns[];
  viewType?: IApiInvestigationStatusNames;
  startWithCount?: boolean;
  contactId?: string;
  filterNumber?: number;
}

export enum ReportTableType {
  investigation = "investigationReport",
  evidence = "evidencenReport",
}

export interface IReportTableConfig {
  type?: ReportTableType;
  displayedColumns?: Columns[];
  viewType?: IApiInvestigationStatusNames;
  startWithCount?: boolean;
  contactId?: string;
  filterNumber?: number;
}

@Component({
  selector: "app-report-table",
  templateUrl: "./report-table.component.html",
  styleUrls: ["./report-table.component.scss"],
})
export class ReportTableComponent implements OnInit {
  @Input() public set config(val: IReportTableConfig) {
    this._config = val;
    this.columnsNameList = val?.displayedColumns;
  }

  private _config: IReportTableConfig = null;
  public get config() {
    return this._config;
  }
  public columnsNameList: Array<string> = [];
  public filterColumns = [`repeat(${5},minmax(0,1fr))`];
  public type = ReportTableType;
  public tableData: IApiInvestigationMetrics[] = [];

  private _dataSource: InvestigationReportDataSource;
  public set dataSource(val) {
    this._dataSource = val;
  }
  public get dataSource() {
    return this._dataSource;
  }
  public sort: SortDescriptor[] = [{
    field: 'CREATED_AT',
    dir: 'desc'
  }];
  public investigationStatusNames = InvestigationStatusNames;

  constructor(
    private loaderService: LoaderService,
    private investigationReportService: InvestigationReportService,
    private notifications: NotificationsService,
  ) {
    this.dataSource = new InvestigationReportDataSource(this.investigationReportService);
    // Table Initialization / Setup
    this.loaderService.attachObservable(this.dataSource.loading$);

    // Default to sort by newest at top
    this.dataSource.listPage.sortOrder = SortOrder.DESC;
    this.dataSource.applyFilter(IApiInvestigationMetricsFilterType.IsInvestigatorPrimary, "1");
    this.dataSource.applyFilter(IApiInvestigationMetricsFilterType.ExcludeCancelled, "1");
    this.dataSource.listPage.orderBy = IApiInvestigationOrderBy.CreatedAt;

    this.dataSource?.contents$.subscribe((res) => {
      this.tableData = res;
    });

  }

  ngOnInit(): void {
    this.filterColumns = [
      `repeat(${this.config?.filterNumber || 5},minmax(0,1fr))`,
    ];
    this.load();
  }

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public getIcon(RiskTypeId: string) {
    return getRiskTypeIcon(RiskTypeId);
  }


  public clearFilter(filterList) {
    filterList.forEach(({ type }) => this.dataSource.removeFilter(type));
    this.load();
  }

  public filterReport(filterList) {
    filterList.forEach(({ type, value }) => this.dataSource.applyFilter(type, value));
    this.load();
  }

  public applyFilter(filterList) {
    filterList.forEach(({ type, value }) => this.dataSource.applyFilter(type, value));
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'CREATED_AT';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public async load(): Promise<any> {
    this.dataSource.pagingReset();
    await this.dataSource.load();
  }

  public async exportCsv(): Promise<any> {
    let tabName = 'InvestigationsReport';
    const params = {
      filters: this.dataSource?.lastFilters,
      orderBy: this.dataSource?.listPage?.orderBy,
      sortOrder: this.dataSource?.listPage?.sortOrder === 'ASC' ? IApiSortOrder.Asc : IApiSortOrder.Desc,
    };
    // download investigation report
    this.loaderService.show$(this.investigationReportService.csvReport(params).pipe(
      this.notifications.snackbarErrorPipe("Error downloading report; please try again"),
    )).subscribe((res) => {
      this.notifications.kendoConfirm("Your request to export Company Investigation Report has been successfully submitted. You will receive a confirmation email when they are ready.", "Company Investigation Report Download", "", "Ok").subscribe();
    });
  }


}
