import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IApiAddInvestigationHistoryInput, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiRequest, IApiUpdateInvestigationInput } from 'src/app/shared/modules/graphql/types/types';
import { filter, switchMap, tap } from 'rxjs/operators';
import { InvestigationRequestModalComponent } from '../investigation-request-modal/investigation-request-modal.component';
import { InvestigationService, RequestService } from 'src/app/shared/services';
import { NotificationsService } from "src/app/shared/modules/notifications/notifications.service";

import {
  investigationRequestsCreate,
  investigationRequestsUpdate,
  investigationRequestsDelete,
  investigationDocumentsCreate
} from "src/app/shared/helpers/auth-config/investigations.config";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { InvestigationHistoryKendoModalComponent } from '../investigation-history-kendo-modal/investigation-history-kendo-modal.component';
import _ from 'lodash';
@Component({
  selector: 'app-investigation-requests',
  templateUrl: './investigation-requests.component.html',
  styleUrls: ['./investigation-requests.component.scss']
})
export class InvestigationRequestsComponent {

  public authConfig = {
    investigationRequestsCreate,
    investigationRequestsUpdate,
    investigationRequestsDelete,
    investigationDocumentsCreate
  };

  @Input() requests: IApiRequest[];
  @Output() deleteItemEvent = new EventEmitter<any>();
  @Output() addCommentEvent = new EventEmitter<string>();

  public displayedColumns = ["update", "updatedBy", "comment", "status"];

  constructor(
    private requestService: RequestService,
    private notifications: NotificationsService,
    private investigationService: InvestigationService,
    private dialogService: DialogService
  ) { }

  public addComment(id) {
    this.addCommentEvent.emit(id);
  }

  // Update Request
  public openUpdateRequest(request: IApiRequest) {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationRequestModalComponent,
      width: "60%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationRequestModalComponent;
    dialogInstance.data = request;
    dialog.result
      .pipe(
        filter((v: DialogCloseResult) => {
          return _.isEmpty(v) ? false : !!v;
        })
      )
      .subscribe((res) => {
        if (res) {
          this.addCommentEvent.emit(request.id);
        }
      });
  }

  // Delete Request
  public removeRequest(id: string, investigation: IApiUpdateInvestigationInput) {
    this.notifications.kendoConfirm("Are you sure you want to delete this request?").pipe(
      filter(result => !!result),
      switchMap(() => {
        const dialog: DialogRef = this.dialogService.open({
          content: InvestigationHistoryKendoModalComponent,
          width: "40%",
          preventAction: (ev) => {
            return ev !== 'closed' as any;
          },
        });

        const dialogInstance = dialog.content.instance as InvestigationHistoryKendoModalComponent;
        dialogInstance.data = {
          // shallow copy so updates don't mutate locally
          investigation: { ...investigation },
          selectedCategory: IApiInvestigationUpdateCategories.Requests,
          selectedType: IApiInvestigationUpdateTypes.Delete,
          noteOnly: true
        }
        return dialog.result.pipe(
          filter((v) => !!v),
          tap((v: IApiAddInvestigationHistoryInput) => (investigation as IApiUpdateInvestigationInput).History = v),
          switchMap(() => this.investigationService.update(investigation as IApiUpdateInvestigationInput))
        );
      }),
      switchMap(() => this.requestService.remove(id)),
      this.notifications.snackbarErrorPipe("Error deleting request"),
      this.notifications.snackbarPipe("Request deleted successfully.")
    ).subscribe((result) => {
      this.deleteItemEvent.emit(result);
    });
  }

}
