import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { InvestigationService } from '../../services';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { IApiGeoFireReportByStateArray, IApiInvestigation, IApiMapMode } from '../graphql/types/types';
import { getRiskTypeIconWhite } from '../../helpers/reactive-form.class';
import { LoaderService } from '../loader/loader.service';
import dayjs from 'dayjs';


@Component({
  selector: 'app-geo-google-maps',
  templateUrl: './geo-google-maps.component.html',
  styleUrls: ['./geo-google-maps.component.scss']
})
export class GeoGoogleMapsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('mapContainer') private mapContainer: ElementRef;
  @Input() public set states(val) {
    this._cleanMarkers();
    this._states = val;
    if (val) {
      // not state mode
      if (this.mode === IApiMapMode.Individual)
        this._setMarkerByBounds();
      else if (this.mode === IApiMapMode.State) {
        // state mode
        this._setMarkerByState();
      } else {
        this._setMarkerByCounty();
      }
    }
    this.focusMap();
  }
  @Input() public set centerLocation(val) {
    // if zip code is not in the view then reset to default map
    if (val?.location) {
      let zoom = 12;
      if (val?.zoom && this._map) {
        const bound = this._map.getBounds();
        if (!bound?.contains(val?.location)) {
          zoom = val?.zoom;
        }
      }
      this.setMapCenter(val?.location, zoom);
    }
  }
  @Output() public mapSetting = new EventEmitter<any>();

  private _states: IApiGeoFireReportByStateArray[] = [];
  private _mapEl: HTMLElement;
  private _map?: google.maps.Map;
  private clusterer: MarkerClusterer;
  private mode = IApiMapMode.State;
  private stateMarkers = [];
  private infoWindow: google.maps.InfoWindow;
  private dragListener;
  private zoomListener;
  public get mapZoom(){
    if(this._map){
      return this._map.getZoom();
    }
  }

  constructor(private readonly _elementRef: ElementRef, private investigationService: InvestigationService, private loader: LoaderService) { }

  public ngOnInit() {
    this._mapEl = this._elementRef.nativeElement.querySelector('.map-container');
    this._map = new google.maps.Map(this._mapEl, {
      center: new google.maps.LatLng(39, -95),
      zoom: 5,
      mapId: "GEO_MAP",
      fullscreenControl: false,
      streetViewControl: false,
    });
    this.infoWindow = new google.maps.InfoWindow({
      content: '',
      ariaLabel: "INFO_WINDOW",
      minWidth: 180,
    });
  }

  ngAfterViewInit() {
    this.dragListener = google.maps.event.addListener(this._map, "dragend", () => {
      this.reloadMap();
    });
    this.zoomListener = google.maps.event.addListener(this._map, "zoom_changed", () => {
      this.reloadMap();
    });

    this._setMarkerByState();
    // this._resetBounds();
  }

  private getBounds() {
    const bounds = this._map.getBounds();
    const ne = bounds.getNorthEast();
    const neLat = ne.lat();
    const neLng = ne.lng();
    const se = bounds.getSouthWest();
    const seLat = se.lat();
    const seLng = se.lng();
    return { neLat, neLng, seLat, seLng }
  }

  public reloadMap() {
    this.infoWindow.close();
    const zoom = this._map.getZoom();
    const { neLat, neLng, seLat, seLng } = this.getBounds();
    if (zoom >= 8) {
      this.mode = IApiMapMode.Individual;
      this.mapSetting.emit({
        bound: JSON.stringify({
          minLat: Math.min(neLat, seLat),
          maxLat: Math.max(neLat, seLat),
          minLng: Math.min(neLng, seLng),
          maxLng: Math.max(neLng, seLng),
        }),
        mode: IApiMapMode.Individual
      });
    } else if (zoom < 8 && zoom > 5) {
      this.mapSetting.emit({
        bound: JSON.stringify({
          minLat: Math.min(neLat, seLat),
          maxLat: Math.max(neLat, seLat),
          minLng: Math.min(neLng, seLng),
          maxLng: Math.max(neLng, seLng),
        }),
        mode: IApiMapMode.City
      });
      this.mode = IApiMapMode.City;
    } else {
      if (this.mode !== IApiMapMode.State) {
        this.mapSetting.emit({
          bound: null,
          mode: IApiMapMode.State
        });
      }
      this.mode = IApiMapMode.State;
    }
  }

  private getInvestigationById(id) {
    return this.loader.show$(this.investigationService.getInvestigationByIdForGeoFire(id))
  }

  private _setMarkerByBounds() {
    const markers = this._states?.map((position, i) => {
      // create risk type marker
      const roundStateMarker = document.createElement('div');
      roundStateMarker.classList.add('risk-type-marker');
      const imgElement = document.createElement('img');
      imgElement.src = getRiskTypeIconWhite(position?.riskType);
      roundStateMarker.appendChild(imgElement);

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: position.lat, lng: position.lng },
        content: roundStateMarker
      });

      marker.addListener("click", () => {
        this.getInvestigationById(position.InvestigationId).subscribe(result => {
          this.infoWindow.setContent(this.getRiskTypeView(result));
          this.focusMap();
          this.infoWindow.open({
            anchor: marker,
            map: this._map,
          });
        })
      });

      return marker;
    });
    if (this.clusterer) {
      this.clusterer.addMarkers(markers);
    } else {
      this.clusterer = new MarkerClusterer({ markers, map: this._map });
    }
  }

  private _cleanMarkers() {
    if (this.clusterer)
      this.clusterer.clearMarkers();
    if (this.stateMarkers?.length) {
      this.stateMarkers?.map(marker => marker.map = null)
    }
  }

  private _setMarkerByCounty() {
    const markers = this._states?.map((position, i) => {
      const priceTag = document.createElement('div');
      priceTag.className = 'risk-type-marker county-marker';
      priceTag.textContent = position?.count?.toString() || position?.state;

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: position.lat, lng: position.lng },
        content: priceTag,
        map: this._map,
      });
      this.stateMarkers.push(marker);
      marker.addListener("click", (mark) => {
        this.infoWindow.setContent(this.getStateInfoView(position));
        this.infoWindow.open({
          anchor: marker,
          map: this._map,
        });
      });
      return marker;
    });
    if (this.clusterer) {
      this.clusterer.addMarkers(markers);
    } else {
      this.clusterer = new MarkerClusterer({ markers, map: this._map });
    }
  }

  private _setMarkerByState() {
    this.stateMarkers = [];
    this._states?.map((position, i) => {
      const priceTag = document.createElement('div');
      priceTag.className = 'round-state-marker';
      priceTag.textContent = position?.count?.toString() || position?.state;
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: position.lat, lng: position.lng },
        content: priceTag,
        map: this._map,
      });
      this.stateMarkers.push(marker);
      marker.addListener("click", (mark) => {
        this.infoWindow.setContent(this.getStateInfoView(position));
        this.infoWindow.open({
          anchor: marker,
          map: this._map,
        });
      });
    });
  }

  private reduceInvestigationData(investigation: IApiInvestigation) {
    return {
      "Investigation ID": investigation?.nefcoNumber || '-',
      "Status": investigation?.status || '-',
      "Risk Type": investigation?.RiskType?.name || '-',
      "Cause Of Fire": investigation?.Detail?.cause || '-',
      'Created Date': dayjs(investigation?.receivedDate).format('MM/DD/YYYY') || '-',
      'Zip Code': investigation?.LossAddress?.postal || '-',
      'Staff Member': investigation?.InvestigationStaff?.length ? investigation?.InvestigationStaff[0]?.User?.firstName + ' ' + investigation?.InvestigationStaff[0]?.User?.lastName : '-'
    }
  }

  getRiskTypeView(investigation: IApiInvestigation) {
    const investigationRiskInfoView = document.createElement('div');
    investigationRiskInfoView.classList.add('investigation-risk-info-view');
    const finalInvestigation = this.reduceInvestigationData(investigation);
    // Loop through the data to create <p> elements
    for (const [key, value] of Object.entries(finalInvestigation)) {

      const pElement = document.createElement('p'); // Create <p> element

      // Create and append the title span
      const titleSpan = document.createElement('span');
      titleSpan.classList.add('title');
      titleSpan.textContent = key;
      pElement.appendChild(titleSpan);

      // Create and append the content span
      const contentSpan = document.createElement('span');
      contentSpan.classList.add('content');
      if (key === 'Investigation ID') {
        contentSpan.classList.add('text-decoration');
        const hyperLink = document.createElement('a');
        hyperLink.setAttribute('href', window.location.origin + '/investigations/' + investigation?.id);
        hyperLink.setAttribute('target', '_blank');
        hyperLink.textContent = value;
        contentSpan.appendChild(hyperLink);
      } else {
        contentSpan.textContent = value;
      }

      pElement.appendChild(contentSpan);

      // Append the <p> element to the parent div
      investigationRiskInfoView.appendChild(pElement);
    };
    return investigationRiskInfoView;
  }

  private getStateInfoView = (state: IApiGeoFireReportByStateArray) => {
    const newDiv = document.createElement('div');
    newDiv.className = 'google-map-info-window-state';
    // add title
    const title = document.createElement('p');
    title.textContent = 'Total Investigations';
    newDiv.appendChild(title);

    // add count
    const count = document.createElement('p');
    count.className = 'count';
    count.textContent = state?.count?.toString();
    newDiv.appendChild(count);
    // add risk types
    for (const risk of state?.riskTypes) {
      const p = document.createElement('p');
      const span = `<span class='risk-count'>${risk?.count}</span>`
      p.innerHTML = `${risk.type} ${span}`
      p.className = 'mb-0';
      newDiv.appendChild(p);
    };
    return newDiv;
  }

  private focusMap() {
    setTimeout(() => {
      if (this.mapContainer?.nativeElement) {
        this.mapContainer.nativeElement.click()
      }
    }, 1);
  }

  ngOnDestroy(): void {
    if (this.dragListener) {
      google.maps.event.removeListener(this.dragListener)
    }
    if (this.zoomListener) {
      google.maps.event.removeListener(this.zoomListener)
    }
  }

  setMapCenter(location, zoom = 12) {
    this._map.setCenter(location);
    this._map.setZoom(zoom);
    this.reloadMap();
  }

}
