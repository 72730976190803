import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-access-restricted',
  templateUrl: './access-restricted.component.html',
  styleUrls: ['./access-restricted.component.scss']
})
export class AccessRestrictedComponent {

  public nefcoNumber = "";

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe((params)=> {
      if(params?.nefcoNumber){
        this.nefcoNumber = params?.nefcoNumber;
      }
    })
   }

  public goToDashboard() {
    this.router.navigate(['/']);
  }

}
