<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span>{{ data?.id ? "Edit" : "Add" }} NEFCO Office</span>
    </div>
  </kendo-dialog-titlebar>
  <section class="section-content">
    <form #form="ngForm" name="form">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']" class="mt-16">
        <kendo-gridlayout-item [colSpan]="3">
          <kendo-label class="asterisk" text="Enter NEFCO Office name"></kendo-label>
          <br />
          <br />
          <kendo-formfield>
            <kendo-label class="asterisk" text="NEFCO Office Name"></kendo-label>
            <kendo-textbox appNoWhiteSpace name="name" fillMode="outline" #nefcoOfficeName="ngModel" [clearButton]="false"
              placeholder="Enter NEFCO Office Name" [(ngModel)]="model.name" required [disabled]="model.name === 'NEFCO'"></kendo-textbox>
            <kendo-formerror *ngIf="!nefcoOfficeName?.valid && (nefcoOfficeName?.dirty || nefcoOfficeName?.touched) && nefcoOfficeName?.errors?.required">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span>
                NEFCO office name cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [colSpan]="3" class="mb-0">
          <kendo-label class="asterisk" text="Enter NEFCO Office location."></kendo-label>
          <br />
          <br />
          <app-address [name]="'address0'" [config]="{hideType: true, hideAddress2: true, columnCount: 3}" [(ngModel)]="model.Address" [(address)]="model.Address"
            (addressChanged)="model.Address = $event"></app-address>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="Status">
              <kendo-switch [(ngModel)]="model.isActive" class="ml-16" name="isActive" title="Is Active" [disabled]="model.name === 'NEFCO'"></kendo-switch>
            </kendo-label>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </section>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="onSubmit()"
      [disabled]="!form.valid && (form?.dirty || form?.touched)">Save</button>
  </kendo-dialog-actions>
</div>
