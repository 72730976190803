<!-- Permissions -->
<div [appEnforcePermission]="authConfig.companiesBillingRulesCreate"
  #companiesBillingRulesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.companiesBillingRulesUpdate"
  #companiesBillingRulesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.companiesBillingRulesDelete"
  #companiesBillingRulesDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.companiesEligeabilityCreate"
  #companiesEligeabilityCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.companiesEligeabilityUpdate"
  #companiesEligeabilityUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.companiesEligeabilityDelete"
  #companiesEligeabilityDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.companiesRequiredServicesCreate"
  #companiesRequiredServicesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.companiesRequiredServicesUpdate"
  #companiesRequiredServicesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.companiesRequiredServicesDelete"
  #companiesRequiredServicesDelete="appEnforcePermission"></div>
<!-- Permissions -->
<div [dialogRef]="dialog" (appClickOutside)="close()" [ngSwitch]="viewType">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span *ngIf="!data; else editTitle">Create New Company</span>
      <ng-template #editTitle>
        <span class="text-center">Edit {{ company.name }}</span>
      </ng-template>
    </div>
  </kendo-dialog-titlebar>
  <ng-container *ngSwitchCase="viewTypes.Company">
    <form #companyForm="ngForm">
      <kendo-card class="m-2" width="100%">
        <kendo-card-header>
          Company Information
        </kendo-card-header>
        <kendo-card-body>
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
            <kendo-gridlayout-item class="mb-0">
              <kendo-formfield>
                <kendo-label class="asterisk" [for]="companyName" text="Name"></kendo-label>
                <kendo-textbox #companyName="ngModel" appNoWhiteSpace [(ngModel)]="company.name" name="companyName"
                  placeholder="Enter company name" fillMode="outline" [clearButton]="false" required></kendo-textbox>
                <kendo-formerror>
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="!companyName?.valid && (companyName?.dirty || companyName?.touched)">Company name
                    cannot be left blank</span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <kendo-formfield>
                <kendo-label [for]="description" text="Description"></kendo-label>
                <kendo-textbox #description="ngModel" [(ngModel)]="company.description" name="description"
                  placeholder="Enter description" fillMode="outline" [clearButton]="false"></kendo-textbox>
              </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item>
              <kendo-formfield class="kendo-formfield">
                <kendo-label [for]="type" class="asterisk" text="Type"></kendo-label>
                <kendo-dropdownlist #type="ngModel" [data]="companyTypes" textField="name" valueField="id"
                  fillMode="outline" [valuePrimitive]="true" [defaultItem]="{id: null, name: 'Select Type'}" name="type"
                  [(ngModel)]="company.Type.id" #investigationId="ngModel" required>
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem?.name}} </span>
                    <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem?.name}} </span>
                  </ng-template>
                  <ng-template kendoDropDownListNoDataTemplate>
                  </ng-template>
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="!type?.valid && (type?.dirty || type?.touched)">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="type?.errors?.required">
                    Type cannot be left blank
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label text="Additional Information"></kendo-label>
                <kendo-textarea name="notes" #additionalInfo="ngModel" [(ngModel)]="company.additionalInfo"
                  placeholder="Enter additional information" fillMode="outline" resizable="both"
                  appNoWhiteSpace></kendo-textarea>
              </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="checkbox-item">
              <kendo-formfield>
                <div style="margin-top: 6px;">
                  <input type="checkbox" name="enterPropertyEnabled" kendoCheckBox #permissionToEnterProperty
                    [(ngModel)]="company.enterPropertyEnabled" />
                  <kendo-label class="k-checkbox-filter-label ml-12 mr-24" [for]="permissionToEnterProperty" text="Permission to Enter
                  Property Notification"></kendo-label>
                </div>
              </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="checkbox-item">
              <kendo-formfield>
                <div style="margin-top: 6px;">
                  <input type="checkbox" name="recordedInterviewNotification" kendoCheckBox
                    #recordedInterviewNotification [(ngModel)]="company.recordedInterviewNotification" />
                  <kendo-label class="k-checkbox-filter-label ml-12 mr-24" [for]="recordedInterviewNotification"
                    text="Recorded interview notification"></kendo-label>
                </div>
              </kendo-formfield>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </kendo-card-body>
      </kendo-card>
      <div class="mt-16">
        <app-company-branch-create-modal class="mt-16" *ngIf="!data" [config]="officeConfig"
          (branchChange)="branchChanged($event)"></app-company-branch-create-modal>
      </div>
      <!-- Billing Information -->
      <kendo-card class="m-2 mt-16" width="100%"
        *ngIf="companiesBillingRulesCreate?.enabled || companiesBillingRulesUpdate?.enabled || companiesBillingRulesDelete?.enabled">
        <kendo-card-header>
          Billing Information
        </kendo-card-header>
        <kendo-card-body>
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label text="Special Billing Instructions"></kendo-label>
                <kendo-textarea name="notes" #billingInstructions="ngModel" name="billingInstructions"
                  [(ngModel)]="company.billingInstructions" placeholder="Enter special billing instructions"
                  fillMode="outline" resizable="both" appNoWhiteSpace></kendo-textarea>
              </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label text="Quickbooks List Id"></kendo-label>
                <kendo-textbox name="notes" #qbListId="ngModel" name="qbListId" [(ngModel)]="company.qbListId"
                  placeholder="Enter Quickbooks List Id" fillMode="outline" resizable="both"
                  appNoWhiteSpace></kendo-textbox>
              </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item [colSpan]="3">
              <kendo-formfield>
                <kendo-label>
                  <kendo-switch [(ngModel)]="overrideStandardFees" class="mr-5" name="overrideFees" title="Override Standard
              Fees"></kendo-switch>
                  Override Standard Fees
                </kendo-label>
              </kendo-formfield>
              <ng-container *ngIf="overrideStandardFees">
                <ng-container *ngFor="let rule of company.BillingRules; let i = index;">
                  <ng-container *ngTemplateOutlet="billingRule; context: { rule: rule, index: i }"></ng-container>
                </ng-container>
              </ng-container>
              <button kendoButton class="mt-3" themeColor="primary" fillMode="link" iconClass="fas fa-plus"
                [disabled]="!overrideStandardFees" (click)="addBillingRule();">add another</button>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item [colSpan]="3">
              <kendo-formfield>
                <kendo-label>
                  <kendo-switch [(ngModel)]="hasFlatRate" class="mr-5" name="hasFlatRate"
                    title="Override Standard Fees"></kendo-switch>
                  Flat Rate for Standard
                  Investigations
                </kendo-label>
              </kendo-formfield>
              <kendo-gridlayout class="mt-2" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
                [cols]="['repeat(4,minmax(0,1fr))']">
                <ng-container *ngFor="let flatRate of company.FlatRates; let i = index;">
                  <kendo-gridlayout-item>
                    <app-risk-types-select [(ngModel)]="flatRate.Risk.id" [name]="'flatRateType' + i"></app-risk-types-select>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item class="mb-0 flex-div">
                    <kendo-formfield class="w-80">
                      <kendo-label [text]="'Rate'" class="asterisk" [for]="flatRateField"></kendo-label>
                      <div class="numeric-text-suffix">
                        <kendo-numerictextbox placeholder="Enter rate" name="flatRateField" fillMode="outline"
                          [autoCorrect]="false" [(ngModel)]="flatRate.value" #flatRateField="ngModel" required="true">
                        </kendo-numerictextbox>
                      </div>
                      <kendo-formhint>$ / hr</kendo-formhint>
                      <kendo-formerror *ngIf="!flatRateField?.valid && (flatRateField?.dirty || flatRateField?.touched)">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="flatRateField?.errors?.required">
                          Rate cannot be left blank
                        </span>
                      </kendo-formerror>
                    </kendo-formfield>
                    <div class="flex-btn-div">
                      <button class="ml-2" kendoButton themeColor="error" (click)="removeFlatRate(i)" fillMode="link"
                        [iconClass]="'fa-solid fa-times-circle fa-lg'"></button>
                    </div>
                  </kendo-gridlayout-item>
                </ng-container>
              </kendo-gridlayout>
              <button kendoButton class="mt-3" themeColor="primary" fillMode="link" iconClass="fas fa-plus"
                [disabled]="!hasFlatRate" (click)="addFlatRate();">add another</button>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </kendo-card-body>
      </kendo-card>
      <!-- Eligibility Information -->
      <kendo-card class="m-2 mt-16" width="100%"
        *ngIf="companiesEligeabilityCreate?.enabled || companiesEligeabilityUpdate?.enabled || companiesEligeabilityDelete?.enabled">
        <kendo-card-header>
          Eligibility Information
        </kendo-card-header>
        <kendo-card-body>
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
            <kendo-gridlayout-item>
              <p>Certifications required by this company</p>
              <kendo-chip *ngFor="let cert of company.RequiredCertifications; let i = index;" class="mr-16 mb-16 fw600"
                [size]="'medium'" [rounded]="'full'" [fillMode]="'solid'" [themeColor]="'base'" [removable]="true"
                (remove)="removeCertification(i)">
                {{cert?.title}}
              </kendo-chip>
              <br *ngIf="company?.RequiredCertifications?.length" />
              <button kendoButton themeColor="primary" (click)="viewType = viewTypes.Certification"
                fillMode="link">Select
                Certification</button>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item>
              <p> Staff excluded from assignment</p>
              <kendo-chip *ngFor="let staff of company.ExcludedStaff; let i = index;" class="mr-16 mb-16 fw600"
                [size]="'medium'" [rounded]="'full'" [fillMode]="'solid'" [themeColor]="'base'" [removable]="true"
                (remove)="removeStaff(i)">
                {{staff | fullName}}
              </kendo-chip>
              <br *ngIf="company.ExcludedStaff?.length" />
              <button kendoButton themeColor="primary" (click)="viewType = viewTypes.Staff" fillMode="link">Select
                Staff</button>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </kendo-card-body>
      </kendo-card>
      <!-- Services -->
      <kendo-card class="m-2 mt-16" width="100%"
        *ngIf="companiesRequiredServicesCreate?.enabled || companiesRequiredServicesUpdate?.enabled || companiesRequiredServicesDelete?.enabled">
        <kendo-card-header>
          Services
        </kendo-card-header>
        <kendo-card-body>
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
            <kendo-gridlayout-item>
              <p>Services required by this company</p>
              <kendo-chip *ngFor="let service of company.RequiredServices; let i = index;" class="mr-16 mb-16 fw600"
                [size]="'medium'" [rounded]="'full'" [fillMode]="'solid'" [themeColor]="'base'" [removable]="true"
                (remove)="removeService(i)">
                {{service.name}}
              </kendo-chip>
              <br *ngIf="company.RequiredServices?.length" />
              <button kendoButton themeColor="primary" (click)="viewType = viewTypes.Services" fillMode="link">Select
                services</button>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </kendo-card-body>
      </kendo-card>
    </form>
  </ng-container>
  <!-- view modes for selection lists -->
  <ng-container *ngSwitchCase="viewTypes.Certification">
    <app-certifications-list [selectableMode]="true"
      (done)="closeCertificationSelection($event)"></app-certifications-list>
  </ng-container>
  <ng-container *ngSwitchCase="viewTypes.Services">
    <app-service-type-list [selectableMode]="true" (done)="closeServiceSelection($event)"></app-service-type-list>
  </ng-container>
  <ng-container *ngSwitchCase="viewTypes.Staff">
    <app-staff-list [config]="staffListConfig" (done)="closeStaffSelection($event)"></app-staff-list>
  </ng-container>
  <kendo-dialog-actions layout="end" [ngClass]="{'d-none': viewType !== viewTypes.Company}">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary"
      [disabled]="!company?.name?.trim() || !company?.Type?.id || mainOfficeInvalid()" class="mr-16" (click)="save()">
      Save
    </button>
  </kendo-dialog-actions>
</div>
<ng-template #billingRule let-rule="rule" let-index="index">
  <kendo-gridlayout class="mt-2" [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
    <kendo-gridlayout-item class="mb-0">
      <kendo-formfield>
        <kendo-label [for]="selectedExpenseType" text="Category"></kendo-label>
        <kendo-dropdownlist [data]="categories" textField="name" valueField="id" fillMode="outline"
          [valuePrimitive]="true" [name]="'selectedExpenseType' + index" #selectedExpenseType [(ngModel)]="rule.type"
          (valueChange)="typeChange(index)">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem?.name}} </span>
            <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem?.name}} </span>
          </ng-template>
          <ng-template kendoDropDownListNoDataTemplate>
          </ng-template>
        </kendo-dropdownlist>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="mb-0" *ngIf="rule.type === formExpenseType.EXPENSE">
      <kendo-formfield>
        <kendo-label [for]="expenseType" text="Expense Type"></kendo-label>
        <kendo-dropdownlist [data]="expenseItems" textField="name" valueField="id" [name]="'expenseType' + index"
          fillMode="outline" [valuePrimitive]="true" #expenseType="ngModel"
          [defaultItem]="{id: null, name:'Expense Type'}" [(ngModel)]="rule.ExpenseItem.id" required>
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem?.name}} </span>
            <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem?.name}} </span>
          </ng-template>
          <ng-template kendoDropDownListNoDataTemplate>
          </ng-template>
        </kendo-dropdownlist>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="mb-0" *ngIf="rule.type === formExpenseType.HOURS">
      <kendo-formfield>
        <kendo-label [for]="hoursType" text="Hours Type"></kendo-label>
        <kendo-dropdownlist [data]="timeEntryTypes" textField="name" valueField="id" [name]="'hoursType' + index"
          fillMode="outline" [valuePrimitive]="true" #hoursType="ngModel" [defaultItem]="{id: null, name:'Hours Type'}"
          [(ngModel)]="rule.TimeEntryType.id">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem?.name}} </span>
            <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem?.name}} </span>
          </ng-template>
          <ng-template kendoDropDownListNoDataTemplate>
          </ng-template>
        </kendo-dropdownlist>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="mb-0">
      <kendo-formfield>
        <kendo-label [text]="'Rate'" class="asterisk" [for]="rate"></kendo-label>
        <div class="numeric-text-suffix">
          <kendo-numerictextbox placeholder="Enter rate" name="rate" fillMode="outline" [autoCorrect]="false"
            [(ngModel)]="rule.value" #rate="ngModel" required="true">
          </kendo-numerictextbox>
        </div>
        <kendo-formhint>$ / hr</kendo-formhint>
        <kendo-formerror *ngIf="!rate?.valid && (rate?.dirty || rate?.touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="rate?.errors?.required">
            Rate cannot be left blank
          </span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="mb-0 flex-div">
      <kendo-formfield class="w-80">
        <kendo-label text="Notes"></kendo-label>
        <kendo-textarea name="ruleNotes" #ruleNotes="ngModel" [name]="'ruleNotes' + index" [(ngModel)]="rule.notes"
          placeholder="Enter notes" fillMode="outline" resizable="both" appNoWhiteSpace></kendo-textarea>
      </kendo-formfield>
      <div class="flex-btn-div">
        <button kendoButton themeColor="error" class="m-2" (click)="removeBillingRule(index)" fillMode="link"
          [iconClass]="'fa-solid fa-times-circle fa-lg'"></button>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</ng-template>
