import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import moment from 'moment';
import { AuthService, DocumentService, DocumentTypeService, ExpenseService } from 'src/app/shared/services';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { IApiAddExpenseInput, IApiDocumentType, IApiExpense, IApiExpenseFilter, IApiExpenseFilterType, IApiExpenseItem, IApiExpenseMileageTotal, IApiExpenseOrderBy, IApiInvestigation, IApiTimeEntry, IApiUpdateExpenseInput } from "src/app/shared/modules/graphql/types/types";
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { filter, switchMap } from 'rxjs/operators';
import { ExpenseDataSource } from 'src/app/shared/services/expenses';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IInvestigationTimeExpenseModalData, InvestigationTimeAndExpModalKendoComponent } from '../../investigations/investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { expenseUnlinkedMileageDelete, expenseUnlinkedMileageDeleteOwn, expenseInvestigationMileageDelete, 
  expenseInvestigationMileageDeleteOwn, expenseInvestigationExpensesDelete, expenseInvestigationExpensesDeleteOwn, expenseUnlinkeExpenseDelete, 
  expenseUnlinkeExpenseDeleteOwn, expenseUnlinkedExpenseUpdate, expenseUnlinkedExpenseUpdateOwn, expenseInvestigationExpensesUpdate, expenseInvestigationExpensesUpdateOwn, expenseInvestigationMileageUpdate, expenseInvestigationMileageUpdateOwn, expenseUnlinkedMileageUpdateOwn, expenseUnlinkedMileageUpdate, expenseUnlinkedMileageCreate, expenseInvestigationMileageCreate, expenseUnlinkedExpenseCreate, expenseInvestigationExpensesCreate } from 'src/app/shared/helpers/auth-config/time-expenses.config';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';

interface IUpdateExpenseInputAndReceipt extends IApiUpdateExpenseInput {
  Receipt?: {
    key: string,
    filename: string,
    size: number
  };
}

@Component({
  selector: 'app-time-exp-expenses',
  templateUrl: './time-exp-expenses.component.html',
  styleUrls: ['./time-exp-expenses.component.scss']
})
export class TimeExpExpensesComponent implements OnInit {

  public authConfig = {
    expenseUnlinkedExpenseCreate,
    expenseInvestigationExpensesCreate,
    expenseInvestigationMileageCreate,
    expenseUnlinkedMileageCreate,
    expenseUnlinkedMileageUpdate,
    expenseUnlinkedMileageUpdateOwn,
    expenseInvestigationMileageUpdate,
    expenseInvestigationMileageUpdateOwn,
    expenseInvestigationExpensesUpdate, 
    expenseInvestigationExpensesUpdateOwn,
    expenseUnlinkedExpenseUpdate, 
    expenseUnlinkedExpenseUpdateOwn,
    expenseUnlinkedMileageDelete,
    expenseUnlinkedMileageDeleteOwn,
    expenseInvestigationMileageDelete,
    expenseInvestigationMileageDeleteOwn,
    expenseInvestigationExpensesDelete, 
    expenseInvestigationExpensesDeleteOwn,
    expenseUnlinkeExpenseDelete, 
    expenseUnlinkeExpenseDeleteOwn
  }

  @ViewChild('expenseUnlinkedMileageUpdate') expenseUnlinkedMileageUpdatePermission;
  @ViewChild('expenseUnlinkedMileageUpdateOwn') expenseUnlinkedMileageUpdateOwnPermission;
  @ViewChild('expenseInvestigationMileageUpdate') expenseInvestigationMileageUpdatePermission;
  @ViewChild('expenseInvestigationMileageUpdateOwn') expenseInvestigationMileageUpdateOwnPermission;
  @ViewChild('expenseInvestigationExpensesUpdate') expenseInvestigationExpensesUpdatePermission;
  @ViewChild('expenseInvestigationExpensesUpdateOwn') expenseInvestigationExpensesUpdateOwnPermission;
  @ViewChild('expenseUnlinkedExpenseUpdate') expenseUnlinkedExpenseUpdatePermission;
  @ViewChild('expenseUnlinkedExpenseUpdateOwn') expenseUnlinkedExpenseUpdateOwnPermission;
  @ViewChild('expenseUnlinkedMileageDelete') expenseUnlinkedMileageDeletePermission;
  @ViewChild('expenseUnlinkedMileageDeleteOwn') expenseUnlinkedMileageDeleteOwnPermission;
  @ViewChild('expenseInvestigationMileageDelete') expenseInvestigationMileageDeletePermission;
  @ViewChild('expenseInvestigationMileageDeleteOwn') expenseInvestigationMileageDeleteOwnPermission;
  @ViewChild('expenseInvestigationExpensesDelete') expenseInvestigationExpensesDeletePermission;
  @ViewChild('expenseInvestigationExpensesDeleteOwn') expenseInvestigationExpensesDeleteOwnPermission;
  @ViewChild('expenseUnlinkeExpenseDelete') expenseUnlinkeExpenseDeletePermission;
  @ViewChild('expenseUnlinkeExpenseDeleteOwn') expenseUnlinkeExpenseDeleteOwnPermission;
  @Input() userId: string;
  @Input() investigations: IApiInvestigation[];
  @Input() expenseItems: IApiExpenseItem[];

  public displayedColumns = ["date", "invId", "expense", "purpose", "qty", "receipt", "auth", "paid", "actions"];

  // Private variables
  private _expenses: IApiExpense[] = [];
  private _expenseFilter: IApiExpenseFilter[] = [];
  private _selectedInvestigation = null;
  private _expenseForm: IApiAddExpenseInput;
  private _mileageForm: IApiAddExpenseInput;

  // Variables for filters
  private _selectedMonth = NefcoDateHelper.dateFilterStringUTCFromLocal(moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  );
  private dateRangeFilter: string;
  // Document Upload
  public documentTypes: IApiDocumentType[];

  public get selectedInvestigation() {
    return this._selectedInvestigation;
  }
  public set selectedInvestigation(val) {
    this._selectedInvestigation = val;
  }

  public get mileageForm() {
    return this._mileageForm;
  }
  public set mileageForm(val) {
    this._mileageForm = val;
  }

  public get expenseForm() {
    return this._expenseForm;
  }
  public set expenseForm(val) {
    this._expenseForm = val;
  }

  public get expenses() {
    return this._expenses;
  }
  public set expenses(val: IApiExpense[]) {
    this._expenses = val;
  }

  public expenseMileageCount: IApiExpenseMileageTotal;

  public sort: SortDescriptor[] = [{
    field: 'EXPENSE_DATE',
    dir: 'desc'
  }];

  private _dataSource: ExpenseDataSource;

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }
  public authenticatedUserId = null;

  constructor(
    private expenseService: ExpenseService,
    private notifications: NotificationsService,
    private documentService: DocumentService,
    private documentTypeService: DocumentTypeService,
    private loader: LoaderService,
    private dialogService: DialogService,
    public auth: AuthService,
  ) {
    this.dataSource = new ExpenseDataSource(this.expenseService);
    this.dataSource.listPage.limit = 5;
    this.dataSource.listPage.sortOrder = SortOrder.DESC;
    this.dataSource.listPage.orderBy = IApiExpenseOrderBy.ExpenseDate;
    this.loader.attachObservable(this.dataSource.loading$);

    this.expenseForm = {
      expenseDate: null,
      billableQuantity: null,
      nonBillableQuantity: null,
      nefcoVehicle: 0,
      outOfPocket: 1,
      authorizedBy: '',
      authorizedDate: null,
      description: '',
      ExpenseItemId: '',
      ExpensePurposeId: '',
      InvestigationId: null,
      paidDate: null
    };

    this.mileageForm = {
      expenseDate: null,
      billableQuantity: null,
      nonBillableQuantity: null,
      nefcoVehicle: 0,
      outOfPocket: 0,
      authorizedBy: '',
      authorizedDate: null,
      description: '',
      ExpenseItemId: '',
      ExpensePurposeId: '',
      InvestigationId: null,
      paidDate: null
    };
  }

  public applyFilters() {
    this.setExpenseFilters(this.dateRangeFilter || this._selectedMonth, IApiExpenseFilterType.EntryDateRange)
    this.getExpenses();
  }

  public dateRangeChange(event) {
    this.dateRangeFilter = event;
    this.applyFilters();
  }

  public dateChange(event) {
    this.dateRangeFilter = '';
    this._selectedMonth = event;
    this.applyFilters();
  }

  public investigationChanged(event) {
    if (event)
      this.setExpenseFilters(event, IApiExpenseFilterType.Investigation);
    else
      this.dataSource.removeFilter(IApiExpenseFilterType.Investigation);
    this.getExpenses();
  }

  public async addExpense(e: IUpdateExpenseInputAndReceipt, mileage = false) {
    const key = e.Receipt ? e.Receipt.key : null;
    const filename = e.Receipt ? e.Receipt.filename : null;
    let DocumentId = null;

    if (!!key) {
      await this.documentService.add({
        uri: '',
        s3Uri: key,
        title: filename,
        caption: filename,
        // Is there a better way to find and return only "Other"?
        TypeId: this.documentTypes.filter(t => t.name === "Other")[0]?.id,
      }).pipe(
        this.notifications.snackbarErrorPipe("Error uploading document; please try again")
      ).toPromise().then((r) => {
        DocumentId = r.id;
      });
    }

    const input = {
      ...e,
      DocumentId,
    };

    mileage ? input.outOfPocket = 0 : input.nefcoVehicle = 0;

    delete input.Receipt;
    delete input.id;

    this.loader.show$(
      this.expenseService.add(input as IApiAddExpenseInput).pipe(
        this.notifications.snackbarPipe("Expense/Mileage item added."),
        this.notifications.snackbarErrorPipe("Error adding expense/mileage item.")
      )
    ).subscribe(() => this.applyFilters());
  }

  public editExpense(item?: IApiExpense) {

    const expenseItem = (item as IApiExpense)?.expenseDate ? { ...item } : {
      id: null,
      nefcoVehicle: 0,
      expenseDate: new Date(),
      outOfPocket: 0,
      billableQuantity: null,
      nonBillableQuantity: null,
      ExpenseItem: { id: null, name: null },
      ExpensePurpose: { id: null, name: null }
    };

    const data: IInvestigationTimeExpenseModalData = {
      investigationId: item.Investigation?.id,
      nefcoNumber: item.Investigation?.nefcoNumber,
      expense: expenseItem as IApiExpense,
      time: null,
      staff: null,
      adminView: false,
      showStaffMember: false
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpModalKendoComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpModalKendoComponent;
    dialogInstance.data = data;
    dialogInstance.documentTypes = this.documentTypes;
    dialogInstance.investigations = this.investigations;
    dialogInstance.expenseItems = this.expenseItems;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) this.getExpenses();
    })
  }

  public deleteExpense(entry) {

    this.notifications.kendoConfirm(
      "Are you sure you want to delete this expense entry?",
      "Delete Expense Entry?",
      "No, Don’t Delete",
      "Yes, Delete"
    )
      .pipe(
        filter((v) => !!v),
        switchMap(() => this.expenseService.remove(entry.id))
      )
      .subscribe(() => {
        this.getExpenses();
      });
  }

  private setExpenseFilters(value: string | undefined, type: IApiExpenseFilterType) {
    const filtersCopy = this._expenseFilter.filter(f => f.type !== type);
    this._expenseFilter = [...filtersCopy, {
      type: type,
      value: value
    }]
    this._expenseFilter.map(item => {
      this.dataSource.applyFilter(item.type, item.value);
    })
  }

  private getExpenses() {
    this.getExpenseMileageTotal()
    this.dataSource.load()
    this.dataSource?.contents$.subscribe((res) => {
      this.expenses = res;
      setTimeout(() => {
        try {
          document.getElementsByTagName('body')?.[0].click();
        } catch (error) { }
      }, 100);
    });
  }

  private getExpenseMileageTotal() {
    this.expenseService.getExpenseMileageTotal(this.dataSource.lastFilters, this.pageOptions.getPageOptions())
      .subscribe(response => this.expenseMileageCount = response);

  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'EXPENSE_DATE';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.getExpenses();
  }

  ngOnInit() {
    this.auth.authenticatedUser.subscribe((u) => this.authenticatedUserId = u.id);
    this.setExpenseFilters(this.userId, IApiExpenseFilterType.User);
    this.setExpenseFilters(this._selectedMonth, IApiExpenseFilterType.EntryDateRange);
    this.setExpenseFilters("true", IApiExpenseFilterType.ExpenseFilterOutForUser);
    this.setExpenseFilters("true", IApiExpenseFilterType.ExcludeNefcoVehicle);
    this.getExpenses();

    // Document Types
    this.documentTypeService.get([], { sortOrder: SortOrder.ASC, limit: -1 }).pipe(
      unwrapConnection(),
    ).subscribe((result) => {
      this.documentTypes = result;
    });
  }


  // check permission for edit
  public checkEditPermission = (row) => {
    if (row?.ExpenseItem?.name === 'Mileage') {
      if (row?.Investigation) {
        if (this.expenseInvestigationMileageUpdatePermission?.enabled || (this.expenseInvestigationMileageUpdateOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      } else {
        if (this.expenseUnlinkedMileageUpdatePermission?.enabled || (this.expenseUnlinkedMileageUpdateOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      }
    } else {
      if (row?.Investigation) {
        if (this.expenseInvestigationExpensesUpdatePermission?.enabled || (this.expenseInvestigationExpensesUpdateOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      } else {
        if (this.expenseUnlinkedExpenseUpdatePermission?.enabled || (this.expenseUnlinkedExpenseUpdateOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      }
    }
  }

  // check permission for delete
  public checkDeletePermission = (row) => {
    if (row?.ExpenseItem?.name === 'Mileage') {
      if (row?.Investigation) {
        if (this.expenseInvestigationMileageDeletePermission?.enabled || (this.expenseInvestigationMileageDeleteOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      } else {
        if (this.expenseUnlinkedMileageDeletePermission?.enabled || (this.expenseUnlinkedMileageDeleteOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      }
    } else {
      if (row?.Investigation) {
        if (this.expenseInvestigationExpensesDeletePermission?.enabled || (this.expenseInvestigationExpensesDeleteOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      } else {
        if (this.expenseUnlinkeExpenseDeletePermission?.enabled || (this.expenseUnlinkeExpenseDeleteOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      }
    }
  }
}
