import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Expense, ExpensePurpose } from 'src/app/shared/modules/graphql/interfaces/expense.interfaces';
import { ExpenseDataSource, ExpenseService } from 'src/app/shared/services/expenses';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IApiDocumentType, IApiExpense, IApiExpenseItem, IApiExpenseOrderBy, IApiInvestigation } from 'src/app/shared/modules/graphql/types/types';
import { InvestigationTimeAndExpModalKendoComponent } from '../investigations';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { IInvestigationTimeExpenseModalData } from '../investigations/investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { filter, switchMap } from 'rxjs/operators';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { ExpenseActionType } from 'src/app/shared/modules/graphql/enums/expense.enums';
import { AuthService } from 'src/app/shared/services';
import { expenseReportNefcoCardDeleteOwn, expenseReportNefcoCardCreate, expenseReportNefcoCardUpdate, expenseReportNefcoCardDelete, expenseReportNefcoCardUpdateOwn } from 'src/app/shared/helpers/auth-config/time-expenses.config';

export interface IConfig {
  admin: boolean;
  reimbursable?: boolean;
}

export interface ISelectEvent {
  selected: boolean;
  data: Expense[]; // TODO Add expense interface
}

@Component({
  selector: 'app-expense-items-view',
  templateUrl: './expense-items-view.component.html',
  styleUrls: ['./expense-items-view.component.scss']
})

export class ExpenseItemsViewComponent {

  public authConfig = {
    expenseReportNefcoCardCreate,
    expenseReportNefcoCardUpdate,
    expenseReportNefcoCardDelete,
    expenseReportNefcoCardUpdateOwn,
    expenseReportNefcoCardDeleteOwn
  }

  @Input() public expenses: Expense[];
  @Input() public pageOptions: ListPage;
  @Input() public dataSource: ExpenseDataSource;
  @Input() public expenseItems: IApiExpenseItem[];
  @Input() public expensePurposes: ExpensePurpose[];
  @Input() public investigations: IApiInvestigation[];
  @Input() public documentTypes?: IApiDocumentType[] = [];
  @Input() public config: IConfig;
  @Input() public tab: string;

  // Let parent know of selection changes
  @Output() public selection = new EventEmitter<ISelectEvent>();

  // reload after edit, delete
  @Output() public reload = new EventEmitter<boolean>();

  public actionType: typeof ExpenseActionType = ExpenseActionType;
  public displayedColumns = ["date", "invId", "expense", "purpose", "description", "qty", "auth"];
  public footerCols: string[] = null;

  private _selected: any;
  public get selected() {
    return this._selected;
  }
  public set selected(val) {
    this._selected = val;
    this.selection.emit(val);
  }

  public expenseTotal: number = null;

  public showColumn = true;

  public sort: SortDescriptor[] = [{
    field: IApiExpenseOrderBy.CreatedAt,
    dir: 'desc'
  }];

  constructor(
    private dialogService: DialogService,
    private expenseService: ExpenseService,
    private notifications: NotificationsService,
    public auth: AuthService
  ) { }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiExpenseOrderBy.CreatedAt;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.reload.emit(true);
  }

  public expenseAction(item?: IApiExpense, actionType?: ExpenseActionType) {
    if (actionType === ExpenseActionType.DUPLICATE && item?.ExpenseItem?.name !== 'Mileage') {
      if ((item.billableQuantity + item.nonBillableQuantity) > 100) {
        return this.notifications.kendoConfirm(
          "You are not able to duplicate this expense entry as it exceeds $100.00. Please add a new expense entry.",
          "Duplicate Expense Entry",
          "",
          "Okay"
        )
        .pipe(
          filter((v) => !!v),
        )
        .subscribe(() => {
          return;
        });
      }
    }
    const expenseItem = (actionType === ExpenseActionType.UPDATE) ? { ...item } : (actionType === ExpenseActionType.DUPLICATE) ? { ...item, id: null } : {
      id: null,
      nefcoVehicle: false,
      expenseDate: new Date(),
      outOfPocket: false,
      billableQuantity: null,
      nonBillableQuantity: null,
      ExpenseItem: { id: null, name: null },
      ExpensePurpose: { id: null, name: null }
    };

    const data: IInvestigationTimeExpenseModalData = {
      investigationId: item.Investigation?.id,
      nefcoNumber: item.Investigation?.nefcoNumber,
      expense: expenseItem as IApiExpense,
      time: null,
      staff: null,
      adminView: false,
      showStaffMember: false,
      actionType
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpModalKendoComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpModalKendoComponent;
    dialogInstance.data = data;
    dialogInstance.documentTypes = this.documentTypes;
    dialogInstance.investigations = this.investigations;
    dialogInstance.expenseItems = this.expenseItems;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) this.reload.emit(true);
    })
  }

  public deleteExpense(entry) {

    this.notifications.kendoConfirm(
      "Are you sure you want to delete this expense entry?",
      "Delete Expense Entry?",
      "No, Don’t Delete",
      "Yes, Delete"
    )
      .pipe(
        filter((v) => !!v),
        switchMap(() => this.expenseService.remove(entry.id))
      )
      .subscribe(() => {
        this.reload.emit(true);
      });
  }

}
