import { LoaderService } from '../../../../shared/modules/loader/loader.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { Observable, forkJoin, from } from 'rxjs';
import { Component, ViewChild, AfterViewInit, OnInit, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { IApiAddInvestigationStaffInput, IApiAssignInvestigatorInput, IApiInvestigation, IApiInvestigationRole, IApiInvestigationRoleNames, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiUser, IApiUserFilterType, IApiUserOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { DebouncedChangeDirective } from 'src/app/shared/directives/debounced-change.directive';
import { IMapUser } from 'src/app/shared/modules/google-maps/google-map.interfaces';

import { InvestigationService, InvestigationStaffService, UserService } from 'src/app/shared/services';
import { IApiAddress } from 'src/app/shared/modules/graphql/types/types';
import { IDrawerUser } from 'src/app/shared/interfaces/user.interfaces';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { CalendarProfileSidebarComponent } from 'src/app/shared/components';
import { UserDataSource, UserDirectionService } from 'src/app/shared/services/user';
import { DialogContentBase, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { QuietPipe } from 'src/app/shared/pipes';
import { PageChangeEvent } from '@progress/kendo-angular-grid';

export interface IFindInvestigatorModalState {
  address: IApiAddress;
  investigationId: string;
  isFirstAssignment: boolean;
  selectedInvestigatorId?: string;
  investigatorResults?: IApiUser[];
  role: IApiInvestigationRole;
  useAvailabilityFilter?: boolean;
  useEligibilityFilter?: boolean;
  useRequiredHoursFilter?: boolean;
  useFullTimeStatusFilter?: boolean;
  isInvestigationUserSidenavOpen?: boolean;
  mapUsers: IMapUser[];
  clientId: string;
  nefcoOfficeId: string;
}

export interface IFindInvestigatorModalData {
  lossDate: string;
  address: IApiAddress;
  investigationId: string;
  role: IApiInvestigationRole;
  clientId: string;
  isFirstAssignment: boolean;
  nefcoOfficeId: string;
}

@UntilDestroy()
@Component({
  selector: 'app-investigation-find-investigator-modal-kendo',
  templateUrl: './investigation-find-investigator-modal-kendo.component.html',
  styleUrls: ['./investigation-find-investigator-modal-kendo.component.scss']
})
export class InvestigationFindInvestigatorModalKendoComponent extends DialogContentBase implements OnInit, AfterViewInit {
  @ViewChild(DebouncedChangeDirective) formChanges;

  @Input() public data: IFindInvestigatorModalData

  private _state: IFindInvestigatorModalState;

  public loadingResults = true;

  public get state(): IFindInvestigatorModalState {
    return this._state;
  }

  public set state(value) {
    this._state = value;
  }

  public mapUsers;
  public existingUserDirection = [];

  private _dataSource: UserDataSource;
  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public requestedUser = null;

  public investigationDate = new Date().toISOString();
  public searchRadii = [
    { miles: null, displayText: 'All' },
    { miles: 100, displayText: '100 miles' },
    { miles: 150, displayText: '150 miles' },
    { miles: 200, displayText: '200 miles' }
  ];
  public searchRadius = this.searchRadii[1];
  // public userSelectConfig: IUserSelectConfig;
  public drawerUser: IDrawerUser;
  public investigatorResults: IApiUser[] = [];

  public investigationId: string;
  public role: IApiInvestigationRole;
  public selectedInvestigator?: IApiUser;
  public selectedInvestigatorRoute = null; // Address/location for the investigator
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public showAlert = false;
  public investigationRoleNames = IApiInvestigationRoleNames;
  constructor(
    public dialog: DialogRef,
    private userService: UserService,
    private userDirectionService: UserDirectionService,
    private notifications: NotificationsService,
    private loader: LoaderService,
    private investigationService: InvestigationService,
    private investigationStaffService: InvestigationStaffService,
    private quietPipe: QuietPipe,
    private dialogService: DialogService
  ) {
    super(dialog);
    this.dataSource = new UserDataSource(this.userService);
    this.loader.attachObservable(this._dataSource.loading$);
    // setup user for the drawer
    this.drawerUser = { id: null, email: null, firstName: null, lastName: null, createdAt: null, updatedAt: null, Availability: [] };
  }

  public ngOnInit() {
    this._state = {
      ...this.data,
      useAvailabilityFilter: false,
      useEligibilityFilter: false,
      // useRequiredHoursFilter: false,
      useFullTimeStatusFilter: false,
      mapUsers: []
    };
    // this.pageInit();
    this.appliedFilters();
    this.dataSource.contents$.pipe(
      untilDestroyed(this),
      switchMap((users) => {
        this.loader.showOther();
        return this._toMapUsers(users);
      }),
      tap(() => {
        this.loader.hideOther();
      }),
      this.notifications.snackbarErrorPipe("Unable to get directions. Please try again."),
    ).subscribe((content) => {
      this.loadingResults = false;
      this._state.mapUsers = content;
      this.investigatorResults = content;
    });
    this.dataSource.load();
  }

  public pageInit() {
    this.pageOptions.orderBy = IApiUserOrderBy.Proximity;
    this.pageOptions.limit = 8;
    this.pageOptions.sortOrder = SortOrder.ASC;
  }

  public async appliedFilters() {
    const availabilityHoursToCheck = "48";
    this.dataSource.applyFilter(IApiUserFilterType.ViewAssignmentView, 'true');
    // reset filters
    this.dataSource.removeFilter(IApiUserFilterType.RequiredHours);
    this.dataSource.removeFilter(IApiUserFilterType.TimeEntry);
    this.dataSource.removeFilter(IApiUserFilterType.IsFullTime);
    this.dataSource.removeFilter(IApiUserFilterType.Eligibility);
    this.dataSource.removeFilter(IApiUserFilterType.UserId);
    this.dataSource.removeFilter(IApiUserFilterType.Availability);
    this.dataSource.removeFilter(IApiUserFilterType.Proximity);
    this.dataSource.removeFilter(IApiUserFilterType.Position);

    this.pageInit();

    // supercedes all other filters
    if (this.requestedUser) {
      this.dataSource.applyFilter(IApiUserFilterType.UserId, this.requestedUser.id);
      // Needs to be sort/order for query to work.
      this.dataSource.listPage.orderBy = IApiUserOrderBy.Firstname;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    else {
      // default filters
      this._dataSource.applyFilter(IApiUserFilterType.Proximity, JSON.stringify([this._state.address.latitude, this._state.address.longitude, this.searchRadius.miles]));
      this._dataSource.applyFilter(IApiUserFilterType.Position, JSON.stringify(this.data.role.title));
      this._dataSource.applyFilter(IApiUserFilterType.AddressType, 'Home');

      // adjustable filters, add if selected
      if (this._state.useAvailabilityFilter) this.dataSource.applyFilter(IApiUserFilterType.Availability, availabilityHoursToCheck);
      // TODO uncomment this when workload filter is re-implemented
      // if (this._state.useRequiredHoursFilter) {
      //   this.dataSource.applyFilter(IApiUserFilterType.RequiredHours, "true");
      //   this.dataSource.applyFilter(IApiUserFilterType.TimeEntry, `${dateRange.start},${dateRange.end}`);
      // }
      if (this._state.useEligibilityFilter) this.dataSource.applyFilter(IApiUserFilterType.Eligibility, this._state.investigationId);
      if (this._state.useFullTimeStatusFilter) this.dataSource.applyFilter(IApiUserFilterType.IsFullTime, "true");
    }
    await this.dataSource.load();
  }

  public clearAll(): void {
    this.requestedUser = null;
    this.searchRadius = this.searchRadii[1];
    this._state = {
      ...this.data,
      useAvailabilityFilter: false,
      useEligibilityFilter: false,
      // useRequiredHoursFilter: false,
      useFullTimeStatusFilter: false,
      mapUsers: []
    };

    this.pageInit();
    this.appliedFilters();
  }

  public confirmAssignment() {
    this.showAlert = false;
    if (!this.selectedInvestigator) {
      this.showAlert = true;
      return;
    }
    this.notifications.kendoConfirm(
      `Are you sure you want to assign this ${this.quietPipe.transform(this.data?.role?.title)?.toLowerCase() || 'investigator'} to the investigation?`,
      "Investigation Assignment",
      "No, Don’t Assign",
      "Yes, Assign"
    ).pipe(
      filter(r => !!r)
    ).subscribe(() => {
      if (this.data?.role?.title === IApiInvestigationRoleNames.Investigator) {
        const input: IApiAssignInvestigatorInput = {
          UserId: this.selectedInvestigator?.id,
          InvestigationId: this.data?.investigationId,
          TechReviewerId: this.selectedInvestigator?.TechReviewer?.id
        };
        this.loader.show$(this.investigationStaffService.assignInvestigator(input).pipe(
          this.notifications.snackbarPipe("Investigator successfully assigned."),
          this.notifications.snackbarErrorPipe("Error assigning investigator."),
          switchMap(() => {
            return forkJoin([
              this.historyAdd(),
              ...(this.data.nefcoOfficeId !== this.state.nefcoOfficeId ? [this.historyAddAndUpdateNefcoOffice()] : [])
            ]);
          })
        )).subscribe(() => {
          this.dialog.close(true);
        });
      }
      else {
        const input: IApiAddInvestigationStaffInput = {
          UserId: this.selectedInvestigator?.id,
          InvestigationId: this.data?.investigationId,
          TechReviewerId: this.selectedInvestigator?.TechReviewer?.id,
          RoleId: this.data?.role?.id
        };

        this.loader.show$(this.investigationStaffService.add(input)).pipe(
          this.notifications.snackbarPipe("Staff successfully assigned."),
          this.notifications.snackbarErrorPipe("Error assigning staff."),
          switchMap(() => {
            return this.historyAdd();
          })
        ).subscribe(() => this.dialog.close(true));
      }
    })
  }

  private historyAdd(): Observable<IApiInvestigation> {
    return this.investigationService.update({
      id: this.data.investigationId,
      History: {
          InvestigationId: this.data.investigationId,
          updateCategory: IApiInvestigationUpdateCategories.Staff,
          updateType: IApiInvestigationUpdateTypes.Create,
          comment: `Staff member ${this.selectedInvestigator.firstName} ${this.selectedInvestigator.lastName} added to investigation with role ${this.data.role.title}`
        }
    });
  }

  private historyAddAndUpdateNefcoOffice(): Observable<IApiInvestigation> {
    return this.investigationService.update({
      id: this.data.investigationId,
      NefcoOfficeId: this.state.nefcoOfficeId,
      History: {
        InvestigationId: this.data.investigationId,
        updateType: IApiInvestigationUpdateTypes.Update,
        updateCategory: IApiInvestigationUpdateCategories.Detail,
        comment: "Nefco office updated",
      },
    });
  }

  public cancelAssignment() {
    this.dialog.close(null);
  }

  public handleDrawerUserChange(user: IApiUser) {
    const { firstName, lastName, msGraphId, id, email } = user;
    const data: any = {
      id,
      User: {
        id,
        firstName,
        lastName,
        msGraphId,
        email
      }
    };
    try {
      const dialog: DialogRef = this.dialogService.open({
        content: CalendarProfileSidebarComponent,
        width: "55%",
        height: "100vh",
        cssClass: 'right-position calendar-sidebar',
        preventAction: (ev) => {
          return ev !== 'closed' as any;
        },
      });
      const dialogInstance = dialog.content.instance as CalendarProfileSidebarComponent;
      dialogInstance.data = data;
      dialog.result.subscribe();
    } catch (e) {
      console.log(e);
    }
  }

  public handleNameClick(user) {
    this.selectedInvestigatorRoute = user;
    this.mapUsers = user ? this._state.mapUsers.filter((u) => u === user) : this._state.mapUsers;
  }

  private _toMapUsers(users: IApiUser[]): Observable<IApiUser[]> {

    return from((async () => {
      this.mapUsers = await this.userDirectionService.getUserDirection(users, this._state, this.existingUserDirection);
      this._state.mapUsers = this.mapUsers?.length ? [...this.mapUsers] : [];
      if (users?.length) {
        this.existingUserDirection = [...this.mapUsers];
      }
      return this.mapUsers;
    })()); // Call it
  }

  public paginate(event: PageChangeEvent) {
    if (!this.pageOptions) return;
    // have to manually set loading due to more complicated flow
    this.loadingResults = true;
    this.pageOptions.paginate(event);
  }

}
