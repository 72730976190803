<section>
  <h4 class="page-title">Payroll</h4>
  <div class="content-wrapper p-0">
    <kendo-tabstrip #tabstrip [scrollable]="{ buttonScrollSpeed: 500 }" class="bookkeeping-payroll-manage-tabs">
      <kendo-tabstrip-tab>
        <ng-template kendoTabTitle>
          <span>Payroll Review</span>
        </ng-template>
        <ng-template kendoTabContent>
          <div class="content">
            <app-bookkeeping-payroll-filter [payPeriod]="payPeriod"
            (dateRangeChange)="onDateRangeChanged($event)" (userSelectionChange)="userSelectionChange($event)"
            (downloadCsv)="downloadCsv($event)" (markAsPaid)="markAsPaid()" [selectedTimeEntries]="selectedTimeEntries"
            (markAllAsPaid)="markAllAsPaid()" [selectedTab]="'payroll-review'" (clearAll)="clearAll()"></app-bookkeeping-payroll-filter>
          <app-bookkeeping-payroll-review [userId]="selectedUser" [payPeriod]="payPeriod" [reload]="reloadChild"
            [(selectAllEntries)]="selectAllEntries" (payTimeEntries)="toggleSelected($event)"
            (selectReset)="selectReset()" (_selectAllEntriesChanged)="_selectAllEntriesChanged($event)"></app-bookkeeping-payroll-review>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab>
        <ng-template kendoTabTitle>
          <span>Payroll Entry List</span>
        </ng-template>
        <ng-template kendoTabContent>
          <div class="content">
            <app-bookkeeping-payroll-filter [payPeriod]="payPeriod"
            (dateRangeChange)="onDateRangeChanged($event)" (userSelectionChange)="userSelectionChange($event)"
            (downloadCsv)="downloadCsv($event)" (markAsPaid)="markAsPaid()" [selectedTimeEntries]="selectedTimeEntries"
            (markAllAsPaid)="markAllAsPaid()" [selectedTab]="'payroll-entry-list'" (clearAll)="clearAll()"></app-bookkeeping-payroll-filter>
          <app-bookkeeping-payroll-entry-list [userId]="selectedUser" [payPeriod]="payPeriod" [reload]="reloadChild"></app-bookkeeping-payroll-entry-list>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</section>