<app-investigation-metrics-filters
  (applyFilter)="applyFilter($event)"
  (clearFilter)="applyFilter($event)"
  [showNefcoOfficeFilter]="false"
></app-investigation-metrics-filters>
<app-investigation-metrics-table
  [tabIndex]="tabIndex"
  [dataSource]="dataSource"
  [data]="tableData"
  [sort]="sort"
  [pageOptions]="pageOptions"
  (pageChange)="pageChange($event)"
  (sortChange)="sortChange($event)"
></app-investigation-metrics-table>
