import { Pipe, PipeTransform } from '@angular/core';
import { IApiInvestigation, IApiInvestigationRoleNames } from 'src/app/shared/modules/graphql/types/types';
import { IApiContact, IApiInsuredParty, IApiInvestigationStaff, IApiUser } from '../modules/graphql/types/types';
import dayjs from 'dayjs';

@Pipe({ name: 'fullName' })
export class StandardNamePipe implements PipeTransform {
  // TODO: update to use common name interface according to the spec, this is just a basic implementation
  transform(nameEntity?: IApiUser | IApiContact, formal = false): string {
    if (!nameEntity) return '';
    let val = '';
    if (formal) val = `${nameEntity.lastName}, ${nameEntity.firstName}`;
    else val = `${nameEntity.firstName} ${nameEntity.lastName}`;
    return val;
  }
}

@Pipe({ name: 'insuredName' })
export class StandardInsuredNamePipe implements PipeTransform {
  // TODO: update to use common name interface according to the spec, this is just a basic implementation
  transform(nameEntity?: IApiInsuredParty, formal = false): string {
    if (!nameEntity) return '';
    let val = '';
    val = nameEntity.insuredName || nameEntity.contactName
    return val;
  }
}

@Pipe({ name: 'investigationStaffRole' })
export class InvestigationStaffRolePipe implements PipeTransform {
  transform(staff: IApiInvestigationStaff[], investigationRole: IApiInvestigationRoleNames, primary = false): IApiUser[] {

    let foundStaff = staff ? staff.filter(obj => obj?.Role?.title === investigationRole).sort((a, b) => a.createdAt > b.createdAt ? 1 : -1) : [];

    if (primary && foundStaff.length) {
      // See if someone is primary. If no primary person, return first person assigned with that role
      const primaryStaff = foundStaff.filter(obj => obj.isPrimary === true);
      foundStaff = primaryStaff.length ? primaryStaff : [foundStaff[0]];
    }
    return foundStaff.length ? foundStaff.map(obj => {
      return obj.User;
    }) : [];
  }
}


@Pipe({ name: 'MobileInvestigationListActionFilter' })
export class MobileInvestigationListActionFilter implements PipeTransform {
  transform(actionItem: any[], investigation: IApiInvestigation): any[] {
    let actionItems = actionItem;
    if (!investigation?.NearOnScene) {
      actionItems = actionItems?.filter(item => item.text !== 'Update Scene Status')
    } 
    if (investigation?.VehicleCount === 0 || investigation?.Vehicles?.length === 0) {
      actionItems = actionItems?.filter(item => item.text !== 'View Vehicle Information')
    }
    return actionItems;
  }
}
