<section>
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
    <kendo-gridlayout-item [colSpan]="2">
      <div class="kendo-formfield date-range-wrapper">
        <kendo-daterange style="gap: 0;" #dateRangePicker>
          <kendo-label text="Received Date (Start)" class="mr-16 width100">
            <kendo-dateinput #startDateRange #startDateInput="ngModel" name="startDateInput" calendarType="classic"
              class="dateinput width100" placeholder="Start Date" [fillMode]="'outline'" kendoDateRangeStartInput
              [(ngModel)]="range.startDate" (blur)="updateDateFilters()" (ngModelChange)="updateDateFilters()"
              (click)="focusDateInput(true)"></kendo-dateinput>
          </kendo-label>
          <kendo-label text="Received Date (End)" class="width100">
            <kendo-dateinput #endDateRange #endDateInput="ngModel" name="endDateInput" calendarType="classic"
              class="mr-0 dateinput width100" placeholder="End Date" [fillMode]="'outline'" kendoDateRangeEndInput
              [(ngModel)]="range.endDate" (blur)="updateDateFilters()" (ngModelChange)="updateDateFilters()"
              (click)="focusDateInput(false)"></kendo-dateinput>
          </kendo-label>
          <kendo-daterange-popup #dateRangePickerPopup autoCorrectOn="blur"></kendo-daterange-popup>
        </kendo-daterange>
      </div>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <app-state-multi-select-kendo placeholder="Select state" label="Loss State"
        [selectedId]="filterByIdValue(filterTypes.LossStateIn)"
        (selectionChanged)="setFilters($event, filterTypes.LossStateIn)">
      </app-state-multi-select-kendo>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <kendo-formfield class="kendo-formfield">
        <kendo-label text="Zip Code"></kendo-label>
        <kendo-multiselect [allowCustom]="true" name='zip' fillMode="outline" textField="zip"
          [(ngModel)]="selectedZipCodes" textField="zip" valueField="zip" (valueChange)="onZipChange($event)"
          [valueNormalizer]="valueNormalizer" placeholder="Type zip code and press enter">
          <ng-template kendoDropDownListNoDataTemplate>
          </ng-template>
        </kendo-multiselect>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="filter-actions">
      <div class="mr-16 applied-filter">
        <kendo-label text=""></kendo-label>
        <div>
          <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="appliedFilters()">Apply
            Filters</kendo-button>
        </div>
      </div>
      <div class="mt-5 clear-filter">
        <kendo-label text=""></kendo-label>
        <div>
          <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearAll()">Clear
            All</kendo-button>
        </div>
      </div>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <app-user-select-kendo label="Staff Member" placeholder="Search staff member" [userViewFilter]="userViewFilter"
        [userId]="filterValue(filterTypes.User)" [includeInactive]="true"
        (selectionChanged)="setFilters($event?.id, filterTypes.User)"></app-user-select-kendo>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <app-company-select-kendo label="Client (Company)" placeholder="Search Client (Company)"
        [selectedId]="filterByIdValue(filterTypes.Companies)"
        (selectionChanged)="setFilters($event, filterTypes.Companies)"></app-company-select-kendo>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <app-risk-type-multi-select-kendo placeholder="Select type" label="Risk Type"
        [selectedId]="filterByIdValue(filterTypes.RiskTypeIn)"
        (selectionChanged)="setFilters($event, filterTypes.RiskTypeIn)">
      </app-risk-type-multi-select-kendo>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <div class="kendo-formfield">
        <kendo-label [for]="Status" text="Status"></kendo-label>
        <kendo-dropdownlist #Status [data]="investigationStatuses | keyvalue" textField="value" valueField="value"
          fillMode="outline" [valuePrimitive]="true" [defaultItem]="{key: null, value: 'All'}"
          [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" required
          (selectionChange)="setFilters($event?.value !== 'All' ? $event?.value : undefined , filterTypes.Status)">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span *ngIf="dataItem?.value === 'All'" class="placeholder"> {{ dataItem?.value }} </span>
            <span *ngIf="dataItem?.value !== 'All'" class="not-placeholder"> {{ dataItem?.value }} </span>
          </ng-template>
        </kendo-dropdownlist>
      </div>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <app-causes-multi-select-kendo placeholder="Select cause" label="Cause of Fire"
        [selectedId]="filterByIdValue(filterTypes.CauseIn)"
        (selectionChanged)="setFilters($event, filterTypes.CauseIn)">
      </app-causes-multi-select-kendo>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</section>
<section class="zip-code-wrapper" *ngIf="selectedZipCodes?.length && showZipModel">
  <div class="header">
    <h3>Selected Zip Codes</h3>
    <button class="close-action" kendoButton themeColor="secondary" fillMode="link" (click)="showZipModel = false"
      [iconClass]="'fa-solid fa-close fa-lg'"></button>
  </div>
  <div class="content">
    <ng-container *ngIf="invalidZipCodes?.length">
      <p class="invalid-zip-code-text">
        The selected zip codes are not valid. Please select a different set of criteria.
      </p>
      <span *ngFor="let zip of invalidZipCodes" class="invalid-zip-codes">
        {{zip}}
      </span>
    </ng-container>
    <ng-container *ngIf="validZipCodesWithAddress?.length">
      <div class="mt-12">
        <p *ngFor="let zip of validZipCodesWithAddress" class="valid-zip-code-wrap"
          (click)="setMapCenter.emit(zip?.location)">
          {{zip?.formatted_address || zip?.zip}}
        </p>
      </div>
    </ng-container>

  </div>
</section>
