import { Component, OnInit } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiInvestigationEditorTimelog, IApiInvestigationEditorTimelogAverage, IApiInvestigationMetricsFilter, IApiInvestigationMetricsFilterType } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { InvestigationReportTimelogDatasource } from 'src/app/shared/services/reports/investigation-report-timelog/investigation-report-timelog.datasource';
import { InvestigationReportTimelogService } from 'src/app/shared/services/reports/investigation-report-timelog/investigation-report-timelog.service';

@Component({
  selector: 'app-investigation-report-timelog',
  templateUrl: './investigation-report-timelog.component.html',
  styleUrls: ['./investigation-report-timelog.component.scss']
})
export class InvestigationReportTimelogComponent implements OnInit {

  public tableData: IApiInvestigationEditorTimelog[] = [];
  private _dataSource: InvestigationReportTimelogDatasource;

  public set dataSource(val) {
    this._dataSource = val;
  }
  
  public get dataSource() {
    return this._dataSource;
  }
  
  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public sort: SortDescriptor[] = [{
    field: 'CREATED_AT',
    dir: 'desc'
  }];

  public reportTotal: IApiInvestigationEditorTimelogAverage;

  constructor(
    private loaderService: LoaderService,
    private investigationTimelogService: InvestigationReportTimelogService,
  ) { 
    this.dataSource = new InvestigationReportTimelogDatasource(this.investigationTimelogService);
    this.loaderService.attachObservable(this.dataSource.loading$);

    this.dataSource?.contents$.subscribe((res) => {
      this.tableData = (res || []).map(item => {
        return {
          ...item,
          data: JSON.parse(item.data)
        }
      });
    });
  }

  ngOnInit(): void {
    this.load();
  }

  public applyFilter(filters: IApiInvestigationMetricsFilter[]) {
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    filters?.map(filter => this.dataSource.applyFilter(filter?.type, filter?.value));
    this.dataSource.pagingReset();
    this.load();
  }

  public clearFilter(filters: IApiInvestigationMetricsFilter[]) {
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.dataSource.pagingReset();
    this.load();
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public async load(): Promise<any> {
    this.dataSource.pagingReset();
    await this.dataSource.load();
    this.investigationTimelogService.getTotal(this.dataSource.lastFilters).subscribe(res => {
      this.reportTotal = res;
    });
  }

}
