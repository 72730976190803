import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "src/app/shared/shared.module";
import { DashboardModalModule } from "../dashboard-modal/dashboard-modal.module";

import {
  DashboardTabsComponent,
  DashboardReportsTableComponent,
  DashboardRequestsTableComponent,
  DashboardInvestigatorsTableComponent,
  DashboardAssignedTableComponent,
} from "./index";
import { InvestigationRestrictionComponent } from './investigation-restriction/investigation-restriction.component';


@NgModule({
  declarations: [
    DashboardTabsComponent,
    DashboardReportsTableComponent,
    DashboardRequestsTableComponent,
    DashboardInvestigatorsTableComponent,
    DashboardAssignedTableComponent,
    InvestigationRestrictionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModalModule,
  ],
})
export class DashboardModule { }
