import gql from 'graphql-tag';
import { buildConnectionQuery } from '../../../helpers.class';

const nodeFragment = `
    id
    Investigation {
      id
    }
    User {
      id
      firstName
      lastName
    }
    CreatedBy {
      id
      firstName
      lastName
    }
    createdAt
    updatedAt
`;

const listFragment = `
    id
    Investigation {
      id
      nefcoNumber
    }
    User {
      id
      firstName
      lastName
      Roles {
        id
        name
      }
    }
    CreatedBy {
      id
      firstName
      lastName
    }
    createdAt
    updatedAt
`;

export const investigationRestrictionsConnectionQuery = gql`${buildConnectionQuery("InvestigationRestriction", "investigationRestrictionConnection", listFragment)}`;


export const addInvestigationRestrictionMutation = gql`mutation AddInvestigationRestriction($input: AddMultipleInvestigationRestrictionInput!) {
  addInvestigationRestriction(input: $input) {
      ${nodeFragment}
  }
}`;

export const removeInvestigationRestrictionMutation = gql`mutation RemoveInvestigationRestriction($input: RemoveMultipleInvestigationRestrictionInput!) {
  removeInvestigationRestriction(input: $input) {
    success
  }
}`;
