<kendo-formfield>
    <kendo-label [ngClass]="required ? 'asterisk' : ''" text="State"></kendo-label>
    <kendo-dropdownlist [data]="states" [name]="name" #state="ngModel" [(ngModel)]="stateValue" textField="text"
        valueField="value" fillMode="outline" [valuePrimitive]="true"
        [defaultItem]="{value: null, text: 'Select State'}" [required]="required" [disabled]="disabled"
        (selectionChange)="handleSelectionChange($event)" [popupSettings]="{
        appendTo: 'component',
        animate: false,
        width: 250
      }">
        <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span *ngIf="!dataItem?.value" class="placeholder">
                {{ dataItem?.text }}
            </span>
            <span *ngIf="dataItem?.value" class="not-placeholder">
                {{ dataItem?.text }}
            </span>
        </ng-template>
    </kendo-dropdownlist>
    <kendo-formerror *ngIf="state?.errors && (state?.dirty || state?.touched)">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span *ngIf="state?.errors.required">Please select State</span>
    </kendo-formerror>
</kendo-formfield>