import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { PortalService, StateService } from "src/app/shared/services";
import { groupBy } from "@progress/kendo-data-query";
import { IState } from "src/app/shared/interfaces/state.interfaces";
import {
  IApiCompanyFilterType,
  IApiCompanyType,
  IApiContactRole,
  IApiContactRoleFilterType,
} from "src/app/shared/modules/graphql/types/types";
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { forkJoin } from "rxjs";
import { SortOrder } from "src/app/shared/modules/graphql/enums/generic.enums";
import { unwrapConnection, removeTypename } from "src/app/shared/rxjs.pipes";
import { ReactiveFormHelper } from "src/app/shared/helpers/reactive-form.class";
import { sortBy } from 'lodash';
@Component({
  selector: "app-your-company-contact-information",
  templateUrl: "./your-company-contact-information.component.html",
  styleUrls: ["./your-company-contact-information.component.scss"],
})
export class YourCompanyContactInformationComponent implements OnInit, OnChanges {
  @Input() formGroupName: string;
  @Input() isCurrentContact: boolean = false;
  ClientInvestigationNote: UntypedFormGroup;
  public stateList: Array<IState>;
  public stateFiltered: Array<IState>;
  public groupedDataStates: any;
  public carrierList: any[] = [];
  public isHiddenCarrier = false;
  public isSearching = false;
  public newCarrier = true;
  public showInsuranceCarrier: boolean = true;
  public showCompanyInformation: boolean = true;
  @Input() companyTypes: IApiCompanyType[] = [];
  @ViewChild("companyList", { static: true }) public companyList: any;

  constructor(
    private rootFormGroup: FormGroupDirective,
    public stateService: StateService,
    private loader: LoaderService,
    private portalService: PortalService
  ) { }

  ngOnInit(): void {
    this.ClientInvestigationNote = this.rootFormGroup.control.get(
      this.formGroupName
    ) as UntypedFormGroup;

    this.stateList = this.stateService.allStates;
    this.stateFiltered = this.stateService.allStates;
    this.groupedDataStates = groupBy(this.stateFiltered, [{ field: "type" }]);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isCurrentContact?.currentValue && this.companyList?.dataItem?.name) {
      this.newCarrier = false;
      this.companyTypeChange();
    }
  }

  public handleFilter(value) {
    this.stateFiltered = this.stateList.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    this.groupedDataStates = groupBy(this.stateFiltered, [{ field: "type" }]);
  }

  public clearInsuredCarrier() {
    const value = this.ClientInvestigationNote.get('insuranceCarrierName').value;
    if (!value) {
      this.selectedCarrier(null);
    }
  }

  public selectedCarrier(item: any = null) {
    this.newCarrier = false;
    if (item) {
      this.ClientInvestigationNote.patchValue({ insuranceCarrierName: item?.name });
      this.ClientInvestigationNote.patchValue({ address: item?.address1 });
      this.ClientInvestigationNote.patchValue({ city: item?.city });
      this.ClientInvestigationNote.patchValue({ state: item?.state });
      this.ClientInvestigationNote.patchValue({ zip: item?.postal });
      this.ClientInvestigationNote.patchValue({ phone: item?.phone });
      this.ClientInvestigationNote.patchValue({ branchId: item?.branchId });
      this.ClientInvestigationNote.patchValue({ companyId: item?.id });
      this.ClientInvestigationNote.patchValue({ extension: item?.extension });
      this.ClientInvestigationNote.get('address').disable();
      this.ClientInvestigationNote.get('city').disable();
      this.ClientInvestigationNote.get('state').disable();
      this.ClientInvestigationNote.get('zip').disable();
      this.ClientInvestigationNote.get('phone').disable();
      this.ClientInvestigationNote.get('extension').disable();
      this.newCarrier = false;
    } else {
      this.ClientInvestigationNote.patchValue({ address: '' });
      this.ClientInvestigationNote.patchValue({ city: '' });
      this.ClientInvestigationNote.patchValue({ state: '' });
      this.ClientInvestigationNote.patchValue({ zip: '' });
      this.ClientInvestigationNote.patchValue({ phone: '' });
      this.ClientInvestigationNote.patchValue({ branchId: null });
      this.ClientInvestigationNote.patchValue({ companyId: null });
      this.ClientInvestigationNote.patchValue({ extension: '' });
      this.ClientInvestigationNote.get('address').enable();
      this.ClientInvestigationNote.get('city').enable();
      this.ClientInvestigationNote.get('state').enable();
      this.ClientInvestigationNote.get('zip').enable();
      this.ClientInvestigationNote.get('phone').enable();
      this.ClientInvestigationNote.get('extension').enable();
      this.newCarrier = true;
    }
    this.ClientInvestigationNote.get('address').updateValueAndValidity();
    this.ClientInvestigationNote.get('city').updateValueAndValidity();
    this.ClientInvestigationNote.get('state').updateValueAndValidity();
    this.ClientInvestigationNote.get('zip').updateValueAndValidity();
    this.ClientInvestigationNote.get('phone').updateValueAndValidity();
    this.ClientInvestigationNote.get('branchId').updateValueAndValidity();
    this.ClientInvestigationNote.get('companyId').updateValueAndValidity();
    this.ClientInvestigationNote.get('extension').updateValueAndValidity();
    this.isHiddenCarrier = true;
    this.isSearching = false;

  }

  public findCarrier() {
    const value = this.ClientInvestigationNote.get('insuranceCarrierName').value || '';
    if (!value) {
      this.ClientInvestigationNote.get('insuranceCarrierName').markAsTouched();
      return;
    }
    this.isHiddenCarrier = false;
    this.loader.show$(
      this.portalService.getCompanies([{
        type: IApiCompanyFilterType.ViewListView,
        value: 'true'
      }, {
        type: IApiCompanyFilterType.Search,
        value: value
      }], { limit: 10 }).pipe(
        unwrapConnection(),
        removeTypename()
      )
    ).subscribe(results => {
      const data = results || [];
      const carrierArray = [];
      data.map(item => {
        if (item?.Branches?.length) {
          item?.Branches?.map(branch => {
            if (branch?.Addresses?.length) {
              const primaryAddress: any = branch?.Addresses?.sort((a: any, b: any) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
              const primaryPhones = branch?.Phones?.find((item: any) => item?.isPrimary);
              if (
                primaryAddress?.length &&
                primaryAddress[0].address1 &&
                primaryAddress[0].city &&
                primaryAddress[0].state &&
                primaryAddress[0].country &&
                primaryAddress[0].postal
              ) {
                carrierArray.push({
                  id: item?.id,
                  name: item?.name,
                  branchName: branch?.name,
                  branchId: branch?.id,
                  address1: primaryAddress[0]?.address1 || "",
                  address2: primaryAddress[0]?.address2 || "",
                  city: primaryAddress[0]?.city || "",
                  state: primaryAddress[0]?.state || "",
                  country: primaryAddress[0]?.country || "",
                  postal: primaryAddress[0]?.postal || "",
                  phone: primaryPhones?.number || "",
                  extension: primaryPhones?.extension || "",
                });
              }
            }
          })
        }
      })
      this.isSearching = true;
      this.carrierList = sortBy(carrierArray, ["state", "city"]);  //key point: Passing in an array of key names
    });
  }

  public companyTypeChange() {
    this.ClientInvestigationNote.get('companyTypeText').setValue(this.companyList?.dataItem?.name || '');
    this.ClientInvestigationNote.get('companyTypeText').updateValueAndValidity();
    if (this.companyList?.dataItem?.name === 'Insurance Company') {
      // Set Insurance carrier required and company information optional
      this.ClientInvestigationNote.get('insuranceCarrierName').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.ClientInvestigationNote.get('address').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.ClientInvestigationNote.get('city').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.ClientInvestigationNote.get('state').setValidators([Validators.required]);
      this.ClientInvestigationNote.get('zip').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.ClientInvestigationNote.get('phone').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);

      this.ClientInvestigationNote.controls.company.get('companyName').clearValidators();
      this.ClientInvestigationNote.controls.company.get('companyAddress').clearValidators();
      this.ClientInvestigationNote.controls.company.get('companyCity').clearValidators();
      this.ClientInvestigationNote.controls.company.get('companyState').clearValidators();
      this.ClientInvestigationNote.controls.company.get('companyZip').clearValidators();
      this.ClientInvestigationNote.controls.company.get('companyPhone').clearValidators();

      this.showCompanyInformation = false;
      this.showInsuranceCarrier = true;

    } else if (this.companyList?.dataItem?.name === 'Other') {
      // Set company information required and Insurance carrier optional
      this.ClientInvestigationNote.get('insuranceCarrierName').clearValidators();
      this.ClientInvestigationNote.get('address').clearValidators();
      this.ClientInvestigationNote.get('city').clearValidators();
      this.ClientInvestigationNote.get('state').clearValidators();
      this.ClientInvestigationNote.get('zip').clearValidators();
      this.ClientInvestigationNote.get('phone').clearValidators();

      if (this.isCurrentContact) {
        this.ClientInvestigationNote.controls.company.get('companyPhone').clearValidators();
      } else {
        this.ClientInvestigationNote.controls.company.get('companyPhone').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      }
      this.ClientInvestigationNote.controls.company.get('companyName').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.ClientInvestigationNote.controls.company.get('companyAddress').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.ClientInvestigationNote.controls.company.get('companyCity').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.ClientInvestigationNote.controls.company.get('companyState').setValidators([Validators.required]);
      this.ClientInvestigationNote.controls.company.get('companyZip').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.showCompanyInformation = true;
      this.showInsuranceCarrier = false;
    } else {
      this.ClientInvestigationNote.get('insuranceCarrierName').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.ClientInvestigationNote.get('address').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.ClientInvestigationNote.get('city').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.ClientInvestigationNote.get('state').setValidators([Validators.required]);
      this.ClientInvestigationNote.get('zip').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.ClientInvestigationNote.get('phone').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      // Set Both required
      if (this.isCurrentContact) {
        this.ClientInvestigationNote.controls.company.get('companyPhone').clearValidators();
      } else {
        this.ClientInvestigationNote.controls.company.get('companyPhone').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      }
      this.ClientInvestigationNote.controls.company.get('companyName').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.ClientInvestigationNote.controls.company.get('companyAddress').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.ClientInvestigationNote.controls.company.get('companyCity').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.ClientInvestigationNote.controls.company.get('companyState').setValidators([Validators.required]);
      this.ClientInvestigationNote.controls.company.get('companyZip').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.showCompanyInformation = true;
      this.showInsuranceCarrier = true;
    }

    this.ClientInvestigationNote.controls.company.get('companyName').updateValueAndValidity();
    this.ClientInvestigationNote.controls.company.get('companyAddress').updateValueAndValidity();
    this.ClientInvestigationNote.controls.company.get('companyCity').updateValueAndValidity();
    this.ClientInvestigationNote.controls.company.get('companyState').updateValueAndValidity();
    this.ClientInvestigationNote.controls.company.get('companyZip').updateValueAndValidity();
    this.ClientInvestigationNote.controls.company.get('companyPhone').updateValueAndValidity();
    this.ClientInvestigationNote.get('insuranceCarrierName').updateValueAndValidity();
    this.ClientInvestigationNote.get('address').updateValueAndValidity();
    this.ClientInvestigationNote.get('city').updateValueAndValidity();
    this.ClientInvestigationNote.get('state').updateValueAndValidity();
    this.ClientInvestigationNote.get('zip').updateValueAndValidity();
    this.ClientInvestigationNote.get('phone').updateValueAndValidity();
  }

  setContactPhoneValidator(field, event) {
    if (field === 'extension' && event) {
      this.ClientInvestigationNote.controls.ContactPhone.get('number').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator, Validators.pattern(/^\d{10}$/)]);
    } else {
      this.ClientInvestigationNote.controls.ContactPhone.get('number').setValidators([ReactiveFormHelper.noWhitespaceValidator, Validators.pattern(/^\d{10}$/)]);
    }
    this.ClientInvestigationNote.controls.ContactPhone.get('number').updateValueAndValidity();
  }

}
