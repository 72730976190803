import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { NefcoOfficeListComponent } from './nefco-office-list/nefco-office-list.component';
import { NefcoOfficeFormModalComponent } from './nefco-office-form-modal/nefco-office-form-modal.component';


@NgModule({
  declarations: [
    NefcoOfficeListComponent,
    NefcoOfficeFormModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class NefcoOfficeModule {}
