import { IClientDetails } from './../investigation-client/investigation-client.component';
import { IApiUpdateInvestigationInput, IApiUpdateAddressInput, IApiInvestigationUpdateTypes, IApiInvestigationUpdateCategories, IApiAddInvestigationPartyInput, IApiAddress, IApiAddInvestigationV2Input, IApiAddVehicleInput, IApiAddInvestigationPartyV2Input, IApiContactRoleFilterType, IApiContactRole, IApiInvestigationParty, IApiContact } from './../../../../shared/modules/graphql/types/types';
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from "@ngneat/until-destroy";
import { delay, filter, skip, switchMap, tap, mergeMap, take } from 'rxjs/operators';
import { IApiInvestigation, IApiUser, IApiVehicle, IApiVehicleFilterType } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { ContactRoleService, InvestigationDetailService, InvestigationPartyService, InvestigationService, VehicleService } from 'src/app/shared/services/';
import { InvestigationLossAddressCheckModalComponent } from '../investigation-loss-address-check-modal/investigation-loss-address-check-modal.component';
import { Investigation } from '../investigation.class';
import { DebouncedChangeDirective } from "src/app/shared/directives/debounced-change.directive";
import { ICheckableInvestigation } from "../investigation-loss-address-check-table/investigation-loss-address-check-table.component";
import { GoogleMapService } from 'src/app/shared/modules/google-maps/google-map.service';
import { CreateUpdateContactComponent } from '../../contacts/create-update-contact/create-update-contact.component';
import _ from 'lodash';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { InvestigationInsuredPartyModalComponent } from '../investigation-insured-party-modal/investigation-insured-party-modal.component';
import { of } from 'rxjs';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { InvestigationSelectContactCompanyModalComponent } from '../investigation-select-contact-company-modal/investigation-select-contact-company-modal.component';
import { CanAddComponentDeactivate } from 'src/app/shared/route.guards';
import dayjs from 'dayjs';
import { timezones } from 'src/app/shared/helpers/helper';

@UntilDestroy()
@Component({
  selector: 'app-investigation-create',
  templateUrl: './investigation-create.component.html',
  styleUrls: ['./investigation-create.component.scss'],
  providers: [DatePipe]
})
export class InvestigationCreateComponent implements OnInit, AfterViewInit, CanAddComponentDeactivate {
  @ViewChild(DebouncedChangeDirective) formChanges;
  @ViewChild("form") form: NgForm;
  @ViewChild("formLinedInv") formLinedInv: NgForm;

  private _baseModel: IApiInvestigation = {
    id: "",
    LinkedInvestigations: []
  };

  public get baseModel(): IApiInvestigation {
    return this._baseModel;
  }

  // setter allows patching of base model and update model w/o firing valueChanges subscription
  public set baseModel(val: IApiInvestigation) {
    this._baseModel = val;
  }

  private _model: IApiAddInvestigationV2Input | IApiUpdateInvestigationInput;
  public get model() {
    return this._model;
  }

  public get investigation() {
    return { ...this._baseModel };
  }

  // TODO - should this delete the investigation? (gateway does now... currently disabled)
  public isRejected: boolean;
  public isInvestigationAddressSameAsLoss = true;

  // separated from model.lossDate so it set the DateTime correctly when saved
  private _lossTime = null;
  public get lossTime() {
    return this._lossTime;
  }

  public set lossTime(val) {
    this._lossTime = val;
  }

  // Loss Date, separated from model.lossDate due to form changes
  private _lossDate = new Date();
  public get lossDate() {
    return this._lossDate;
  }

  public set lossDate(val) {
    this._lossDate = val;
  }

  /** The main investigation object */
  public get id(): string {
    return (this.model as IApiUpdateInvestigationInput).id;
  }

  public vehicles: IApiVehicle[] = [];

  /** The current authorized user */
  public user: IApiUser;

  public mapsLoaded = false;

  // flags
  public wasSaveSuccesssful = false;
  public lastSavedDate = "";
  public addressCheckComplete = false;
  public hasSuccesfulInvestigationProximityCheck = false;
  public showAddParty = true;
  public showLinkedInvestigations = false;
  public showVehicles = false;
  public showBilling = false;
  public investigationRejectReactivateModal = false;
  public investigationRejectReactivateModalMode = '';
  public resetForm = false;
  public rejectedToggle = false;

  public timeZones = timezones;
  constructor(
    private investigationService: InvestigationService,
    private investigationDetailService: InvestigationDetailService,
    public datepipe: DatePipe,
    private notifications: NotificationsService,
    private activatedRoute: ActivatedRoute,
    private loader: LoaderService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private _mapService: GoogleMapService,
    private dialogService: DialogService,
    private contactRoleService: ContactRoleService,
  ) {
    this._model = {
      id: null,
      lossDate: new Date(),
      LossAddress: null,
      InvestigationAddress: null,
      ClientId: null,
      ClientBranchId: null,
      BillToId: null,
      BillToBranchId: null,
      CompanyId: null,
      Detail: {
        id: null,
        riskDescription: "",
        policyNumber: "",
        claimNumber: "",
        specialInstructions: "",
        // non-nullable, has to be at least an empty string
        InvestigationId: ""
      },
      receivedDate: new Date(),
      conflictCheckRun: false,
      newBillTo: false,
      newClient: false,
      isXactimate: false,
      isSymbility: false,
    };

    // this.auth.authenticatedUser.subscribe(user => { this.user = user; });
  }

  // See if Loss & Scene/Investigation addresses are the same
  public get addressCompare(): boolean {
    const lossAddress = { ...this._model?.LossAddress as IApiAddress };
    const investAddress = { ...this._model?.InvestigationAddress as IApiAddress };

    delete lossAddress?.id;
    delete lossAddress?.TypeId;
    delete investAddress?.id;
    delete investAddress?.TypeId;

    return _.isEqual(lossAddress, investAddress);
  }

  public linkInvestigation(ctl: HTMLInputElement) {
    this.loader.show$(
      this.investigationService.getById(ctl.value)
    ).subscribe(inv => {
      if (!inv) {
        this.notifications.alert("That investigation could not be found. Please try again.");
        return;
      }
      else if (!this._baseModel.LinkedInvestigations.find(({ id }) => inv.id === id)) {
        this._baseModel.LinkedInvestigations.push(inv);
        this.syncLinkedInvestigations();
        this.save();
      }

      ctl.value = "";
      ctl.focus();
    });
  }

  /** Opens LossAddressCheckModal */
  public checkLossAddress() {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationLossAddressCheckModalComponent,
      width: '80%',
      maxWidth: '80%',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    if(this.lossDate && this.lossTime){
      this.model.lossDate = this.calculateLossDate();
    }
    const dialogInstance = dialog.content.instance as InvestigationLossAddressCheckModalComponent;
    dialogInstance.data = this.model as IApiUpdateInvestigationInput;
    dialog.result
      .pipe(filter((v) => !_.isEmpty(v)))
      .subscribe((response: any) => {
        const { result, conflictCheckRun } = response as { result: ICheckableInvestigation[], conflictCheckRun: boolean };
        // update this by hand since we may not have an investigation yet (buffers until created)
        if (result && result.length) {
          // remove any that were unlinked
          this._baseModel.LinkedInvestigations = this._baseModel.LinkedInvestigations.reduce((acc, curr) => {
            const found = result.find(({ id }) => id === curr.id);
            if (!found || found.selected) {
              acc.push(curr);
              // by-ref change so the filter below only picks up new items
              if (found) found.selected = false;
            }
            return acc;
          }, []);

          // now add all the newly selected
          this._baseModel.LinkedInvestigations.push(
            ...result.filter(({ selected }) => selected).map(({ selected, ...i }) => i as IApiInvestigation)
          );

          this.syncLinkedInvestigations();
          // Don't save during creation (when theres no id), leave that to the "Create Investigation" button
          if (this.id) this.save();

          this.showLinkedInvestigations = true;
        }

        this.model.conflictCheckRun = conflictCheckRun;
        this.addressCheckComplete = conflictCheckRun;
      }, (error) => {
        console.log("Address Check Error:: ", error);
      });
  }

  public syncLinkedInvestigations(): void {
    this.model.LinkedInvestigationIds = this._baseModel.LinkedInvestigations.map(({ id }) => id);
  }

  private createInvestigation() {
    if (!this.calculateLossDate()) {
      this.notifications.alert("Please select loss Date and loss Time");
      return of(null);
    }
    /* start validate linked investigation */
    this.linkedInvestigationModel.map((item, index) => {
      if (!item.isValid) {
        this.formLinedInv.form.get(`id_${index}`).setErrors({ isInvalidId: true });
      }
    });
    /* end validate linked investigation */
    if (this.linkedInvestigationModel.some(item => !item.isValid)) return of(null);

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationInsuredPartyModalComponent,
      width: 600,
      maxWidth: 600,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    if (this.isInvestigationAddressSameAsLoss) this.model.InvestigationAddress = { ...this.model.LossAddress };
    /* is client docusketchRequested No. isSymbility and isXactimate should be false */
    if (!this.model.docusketchRequested) {
      this.model.isSymbility = false;
      this.model.isXactimate = false;
    }
    // sequelize magic setters will delete the old addresses and add new ones - remove existing ids
    delete (this.model.LossAddress as IApiUpdateAddressInput)?.id;
    delete (this.model.InvestigationAddress as IApiUpdateAddressInput)?.id;
    delete (this.model as IApiUpdateInvestigationInput).id;
    delete (this.model as IApiUpdateInvestigationInput).Detail.id;

    // Capture & create insured, then save investigation
    const userInfo = dialog.content.instance as InvestigationInsuredPartyModalComponent;
    userInfo.data = {
      message: "Please enter information for the insured party.",
      // Seeded data... replace?
      role: "Insured",
      lossAddress: this.model.LossAddress,
      disableCancel: true,
      isAdd: true
    };


    return dialog.result.pipe(
      switchMap((insured: any) => {
        /* START Cancel Insured Modal Handle */
        if (insured === 'Cancel' || insured === 'Closed') {
          return of(null);
        }
        /* END Cancel Insured Modal Handle */
        this.model.InsuredId = insured.id;
        // for some reason, the above didn't delete the loss address id, need to do it again here
        if (!this.model.LossAddress?.address1 && !this.model.LossAddress.state && !this.model.LossAddress.city && !this.model.LossAddress.postal) {
          this.model.LossAddress.address1 = insured?.Addresses[0]?.address1;
          this.model.LossAddress.state = insured?.Addresses[0]?.state;
          this.model.LossAddress.city = insured?.Addresses[0]?.city;
          this.model.LossAddress.postal = insured?.Addresses[0]?.postal;
        }
        if (this.isInvestigationAddressSameAsLoss) this.model.InvestigationAddress = { ...this.model.LossAddress };
        delete (this.model.LossAddress as IApiUpdateAddressInput)?.id;
        delete (this.model.InvestigationAddress as IApiUpdateAddressInput)?.id;
        return this.loader.show$(this.investigationService.addV2({
          ...this.model,
          Vehicles: this.vehiclesModel,
          Parties: this.partyModel,
          LinkedInvestigationIds: this.linkedInvestigationModel.map(item => item.investigationId),
          lossDate: this.calculateLossDate(),
        } as IApiAddInvestigationV2Input));
      }),
      this.notifications.snackbarErrorPipe(),
      this.notifications.snackbarPipe("Investigation Created!"),
      this.notifications.resetDirtyFormPipe(() => true),
      tap(({ id }) => this.router.navigate(["/investigations", id]))
    );
  }

  public save(close = false) {
    this.createInvestigation().subscribe((result) => {
      if (result) {
        this.lastSavedDate = result.updatedAt;
      }
    });
  }

  public restoreInvestigation() {
    this.notifications.kendoConfirm("Are you sure you want to restore this investigation?").pipe(
      filter(v => !!v),
      switchMap(() => this.investigationService.restore(this.id)),
      this.notifications.snackbarErrorPipe(),
      this.notifications.snackbarPipe("Investigation successfully restored")
    ).subscribe(() => this.reloadInvestigation());
  }

  public saveBillingDetail({ form }: NgForm) {
    const { id, budgetAmount = 0, billingInstructionNotes = "" } = this.investigation.Detail;
    this.investigationDetailService.update({
      id,
      budgetAmount,
      billingInstructionNotes
    }).pipe(
      switchMap(() => {
        return this.investigationService.update({
          id: this.id,
          // This saves the billingInstructions
          billingInstructions: this.model.billingInstructions,
          History: {
            InvestigationId: this.id,
            updateCategory: IApiInvestigationUpdateCategories.Detail,
            updateType: IApiInvestigationUpdateTypes.Update,
            comment: `"Investigation Billing Information" updated`
          }
        });
      }),
      this.notifications.snackbarErrorPipe("Error saving Billing Information"),
      this.notifications.snackbarPipe("Billing Information Saved")
    ).subscribe(() => form.markAsPristine());
  }

  public ngAfterViewInit() {
    this.activatedRoute.data.pipe(
      take(1),
      filter(({ investigation: v }) => !!v),
      // Stops ExpressionChangedAfterEvaluation error
      delay(250),
      tap(({ investigation }) => this.setBaseModel(investigation)),
      // changes fire when the model above updates. Capture a single emission here (so we wait for the digest) and then mark it as pristine.
      mergeMap(() => this.formChanges.valueChanges$.pipe(
        take(1)
      ))
    ).subscribe(() => {
      this.form.form.markAsPristine();
    });
    this.form.valueChanges.subscribe(() => {
      this.resetForm = false;
    })
  }

  private setBaseModel(investigation: IApiInvestigation, setLossDate = true) {
    this._baseModel = investigation;
    this._model = Investigation.getUpdateInputObject(this._baseModel);
    // Setting base time, account for EST/EDT & prevent rewrites on reloadInvestigation()
    if (setLossDate) {
      this._lossTime = this._model?.lossDate ? NefcoDateHelper.toEstToUtcTime(this._model.lossDate) : new Date();
      this._lossDate = new Date(dayjs(this._model.lossDate).utc().format('MM/DD/YYYY'));
    }

    this.vehicles = this._baseModel.Vehicles;
    this.showVehicles = !!this.vehicles.length;

    if (this._baseModel.Detail.billingInstructionNotes || this._baseModel.Detail.budgetAmount) this.showBilling = true;
    if (this._baseModel.LinkedInvestigations.length) this.showLinkedInvestigations = true;

    if (this._baseModel.LinkedInvestigations.length) this.showLinkedInvestigations = true;
    this.addressCheckComplete = this._baseModel.conflictCheckRun;
    if (!this.addressCompare) this.isInvestigationAddressSameAsLoss = false;
  }

  public reloadInvestigation() {
    this.loader.show$(
      this.investigationService.getById(this._baseModel.id)
    ).pipe(
      tap((investigation) => {
        this.setBaseModel(investigation, false);
      }),
    ).subscribe();
  }

  public ngOnInit() {
    this.initGoogleMaps();
  }

  public setClientInfo(value?: IClientDetails) {
    const { Client, BillTo, Company, BillToBranch, ClientBranch, newBillTo, newClient } = value || { Client: null, BillTo: null, Company: null, BillToBranch: null, ClientBranch: null };
    if (Client) this.model.ClientId = Client.id;
    if (ClientBranch) this.model.ClientBranchId = ClientBranch.id;
    if (BillTo) this.model.BillToId = BillTo.id;
    if (BillToBranch) this.model.BillToBranchId = BillToBranch.id;
    if (Company) this.model.CompanyId = Company.id;
    if (newBillTo) this.model.newBillTo = newBillTo;
    if (newClient) this.model.newClient = newClient;
    this.form.form.markAsDirty();
  }

  onReset() {
  }

  public setTimezone(timezone) {
    this._model.timezone = timezone;
  }

  public setLossAddress(event) {
    // Remove TypeId for Comparison
    const eventCompare = { ...event };
    delete eventCompare.TypeId;

    // Current address to correct format for compare
    const currentLossAddress = { ...this.model.LossAddress as IApiAddress };
    delete currentLossAddress.Type;
    delete currentLossAddress.isPrimary;

    if (!currentLossAddress.hasOwnProperty('address2')) {
      currentLossAddress.address2 = null;
    }

    // To Mark form as dirty for Update
    if (!_.isEqual(currentLossAddress, eventCompare)) {
      this.model.LossAddress = event;
      this.form.form.markAsDirty();
    }

    // Redraw for "check" button
    this.ref.detectChanges();
  }

  public setInvestigationAddress(event) {
    // Remove TypeId for Comparison
    const eventCompare = { ...event };
    delete eventCompare.TypeId;

    // Current address to correct format for compare
    const currentInvestAddress = { ...this.model.InvestigationAddress as IApiAddress };
    delete currentInvestAddress.Type;
    delete currentInvestAddress.isPrimary;

    if (!currentInvestAddress.hasOwnProperty('address2')) {
      currentInvestAddress.address2 = null;
    }

    // To Mark form as dirty for Update
    if (!_.isEqual(currentInvestAddress, eventCompare)) {
      this.model.InvestigationAddress = event;
      this.form.form.markAsDirty();
    }

    this.ref.detectChanges();
  }

  private initGoogleMaps() {
    this._mapService.loadGoogleMapApi().subscribe(() => {
      this.mapsLoaded = true;
    });

    /* get roles */
    this.contactRoleService.get([{ type: IApiContactRoleFilterType.ViewListView, value: 'true' }], { sortOrder: SortOrder.ASC, limit: -1 }).pipe(
      unwrapConnection()
    ).subscribe((roles) => {
      this.roles = roles;
    });
  }

  // Need to run this to properly combine lossDate and lossTime for save and convert to EST/EDT
  public calculateLossDate(): string {
    return NefcoDateHelper.toUtcTimeNew(this.lossDate, this.lossTime);
  }

  public openRejectReactivateModel(mode): void {
    this.investigationRejectReactivateModalMode = mode;
    this.investigationRejectReactivateModal = true;
  }

  public investigationRejectReactivateModalResponse(action) {
    this.investigationRejectReactivateModal = false;
    this.investigationRejectReactivateModalMode = ''
    if (action === 'rejected' || action === 'reactivated') {
      this.reloadInvestigation()
    }
  }

  public vehiclesModel: IApiAddVehicleInput[] = [];
  public partyModel: IApiAddInvestigationPartyV2Input[] = [];
  public linkedInvestigationModel: { id: string; isValid: boolean, investigationId: string }[] = [];

  public addVehicle() {
    const vehicle: IApiAddVehicleInput = {
      vehicleType: '',
      make: '',
      model: '',
      year: '',
      vinNumber: '',
      stockNumber: ''
    };
    this.vehiclesModel.push(vehicle);
  }

  public removeVehicle(index: number) {
    this.vehiclesModel.splice(index, 1);
  }


  public roles: IApiContactRole[] = [];
  public enableAddParty = false
  public roleId = '';
  public parties: IApiInvestigationParty[] = [];

  public addContact() {
    const role = this.roles.find(item => item.id === this.roleId);
    if (role.isPrivate) this.addNewContact(role);
    else this.selectContact(role);
  }

  public addNewContact(role: IApiContactRole) {
    const dialog: DialogRef = this.dialogService.open({
      content: CreateUpdateContactComponent,
      width: '80%',
      maxWidth: '90%',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as CreateUpdateContactComponent;
    dialogInstance.data = {
      message: `Please enter information for the ${role.name}.`,
      role: role.name,
      disableRoleSelect: true,
      partyAdd: true,
    };
    dialog.result
      .pipe(filter((v) => !_.isEmpty(v)))
      .subscribe((result: any) => {
        this.roleId = '';
        this.addItem(role, result, true)
      }, () => {
        this.roleId = '';
      });
  }

  public selectContact(role: IApiContactRole) {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationSelectContactCompanyModalComponent,
      width: '80%',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as InvestigationSelectContactCompanyModalComponent;
    dialogInstance.data = {
      role: role,
      partyAdd: true,
    };
    dialog.result
      .pipe(filter((v) => !_.isEmpty(v)))
      .subscribe(({ contact, newContact }: any) => {
        this.roleId = '';
        this.addItem(role, contact, newContact)
      }, () => {
        this.roleId = '';
      });
  }

  public addItem(role: IApiContactRole, contact?: IApiContact, newContact = false) {
    const party = {
      id: null,
      isActive: true,
      Role: role,
      Contact: contact || {
        id: null,
        firstName: "",
        lastName: "",
        Phones: [{
          id: null,
          number: "",
          TypeId: null,
          Type: null,
          isPrimary: true
        }],
        Emails: [{
          id: null,
          address: "",
          isPrimary: true,
          Type: null,
          TypeId: null,
        }],
        Addresses: [{
          id: null,
          isPrimary: true,
          Type: null,
          address1: "",
          city: "",
          state: "",
          postal: "",
          country: "US"
        }]
      }
    };

    this.parties.push(party);
    this.partyModel = this.parties.map(item => {
      return {
        RoleId: item.Role.id,
        ContactId: item.Contact.id,
        newContact: newContact,
      }
    })
    return party;
  }

  public removeParty(index: number) {
    this.parties.splice(index, 1);
    this.partyModel = this.parties.map(item => {
      return {
        RoleId: item.Role.id,
        ContactId: item.Contact.id
      }
    })
  }

  public editContact(contactId: string) {
    const dialog: DialogRef = this.dialogService.open({
      content: CreateUpdateContactComponent,
      width: '80%',
      maxWidth: '90%',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as CreateUpdateContactComponent;
    dialogInstance.data = { contactId };
    dialog.result
      .pipe(filter((v) => !_.isEmpty(v)))
      .subscribe(() => { });
  }


  public addLinkedInvestigation() {
    const linked = {
      id: '',
      isValid: false,
      investigationId: ''
    };
    this.linkedInvestigationModel.push(linked);
  }

  public removeLinkedInvestigation(index: number) {
    this.linkedInvestigationModel.splice(index, 1);
  }

  public createLinkInvestigation(id: string, index: number) {
    const invId = (id || '').trim();
    this.loader.show$(
      this.investigationService.getById(invId)
    ).subscribe(inv => {
      if (inv) {
        if (!this.linkedInvestigationModel.find((item) => item.investigationId === inv.id)) {
          this.linkedInvestigationModel.find(item => (item.id).trim() === invId).investigationId = inv.id;
        }
        this.linkedInvestigationModel.find(item => (item.id).trim() === invId).isValid = true;
        this.formLinedInv.form.get(`id_${index}`).setErrors(null);
      } else {
        this.linkedInvestigationModel.find(item => (item.id).trim() === invId).isValid = false;
        this.formLinedInv.form.get(`id_${index}`).setErrors({ isInvalidId: true });
      }
    });
  }

  public updateInvalid(index) {
    this.linkedInvestigationModel[index].isValid = false;
  }

  public createInv() {
    this.linkedInvestigationModel.map((item, index) => {
      if (!item.isValid) {
        this.formLinedInv.form.get(`id_${index}`).setErrors({ isInvalidId: true });
      }
    })
  }

  public isValidCreateInv() {
    this.linkedInvestigationModel.map((item, index) => {
      if (!item.isValid) {
        this.formLinedInv.form.get(`id_${index}`).setErrors({ isInvalidId: true });
      }
    });
    if (
      !this.form.form.dirty ||
      !this.form.form.valid ||
      !this.model.LossAddress ||
      (!this.model.Detail.policyNumber && !this.model.Detail.claimNumber) ||
      this.linkedInvestigationModel.some(item => !item.isValid)
    ) {
      return false;
    } else {
      return true;
    }
  }

  public clearPage = () => {
    this.resetForm = true;
    this._model = {
      id: null,
      lossDate: new Date(),
      LossAddress: null,
      InvestigationAddress: null,
      ClientId: null,
      ClientBranchId: null,
      BillToId: null,
      BillToBranchId: null,
      CompanyId: null,
      RiskTypeId: null,
      Detail: {
        id: null,
        // non-nullable, has to be at least an empty string
        riskDescription: "",
        policyNumber: "",
        claimNumber: "",
        specialInstructions: "",
        InvestigationId: "",
        notes: "",
        vehicleNotes: "",
        partyNotes: "",
        linkedInvestigationNotes: ""
      },
      receivedDate: new Date(),
      conflictCheckRun: false,
    };
    this.vehiclesModel = [];
    this.partyModel = [];
    this.linkedInvestigationModel = [];
    this.roleId = '';
    this.enableAddParty = false;
    this.parties = [];
  }

  get validateForm() {
    return (
      !this.form?.form?.valid ||
      !this.model?.LossAddress ||
      !this.model?.LossAddress?.address1 ||
      !this.model?.LossAddress?.city ||
      !this.model?.LossAddress?.state ||
      !this.model?.LossAddress?.postal ||
      (this.isInvestigationAddressSameAsLoss === false &&
        (!this.model?.InvestigationAddress?.address1 ||
          !this.model?.InvestigationAddress?.city ||
          !this.model?.InvestigationAddress?.state ||
          !this.model?.InvestigationAddress?.postal)) ||
      (!this.model?.Detail?.policyNumber && !this.model?.Detail?.claimNumber) ||
      (this.model?.docusketchRequested && ((!this.model.isSymbility && !this.model.isXactimate) || (this.model.isSymbility && this.model.isXactimate)))
      || !this.model.RiskTypeId
    );
  }
}
