import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { IApiServiceType } from '../../modules/graphql/types/types';
import { unwrapConnection } from '../../rxjs.pipes';
import { ServiceTypeService } from '../../services';

@Component({
  selector: 'app-service-type-list',
  templateUrl: './service-type-list.component.html',
  styleUrls: ['./service-type-list.component.scss']
})
export class ServiceTypeListComponent implements OnInit {

  @Output() done: EventEmitter<IApiServiceType[]> = new EventEmitter();
  @Input() selectableMode = false;

  // Selection ability
  public selection = new SelectionModel<any>(true, []);
  public content: IApiServiceType[];
  public get isAllSelected(): boolean {
    return this.selection.selected.length === this.content.length;
  }

  public columnsToDisplay = ["select", "NAME", "SERVICES"];

  constructor(
    private serviceTypes: ServiceTypeService,
  ) { }

  ngOnInit(): void {
    this.serviceTypes.get([], {}).pipe(unwrapConnection()).subscribe((types) => this.content = types);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle() {
    this.isAllSelected ? this.selection.clear() : this.content.forEach(row => this.selection.select(row));
  }

  public removeChip(sel) {
    const index = this.selection.selected.indexOf(sel);

    if (index >= 0) {
      this.selection.selected.splice(index, 1);
    }
  }

  public save() {
    this.done.emit(this.selection.selected);
  }

  public selectRow(row: IApiServiceType) {
    this.selection.toggle(row);
  }

}
