import { Component, OnInit } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsFilterType, IApiInvestigationTimelog } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { InvestigationTimelogDatasource, InvestigationTimelogService } from 'src/app/shared/services/reports';

@Component({
  selector: 'app-investigation-timelog',
  templateUrl: './investigation-timelog.component.html',
  styleUrls: ['./investigation-timelog.component.scss']
})
export class InvestigationTimelogComponent implements OnInit {
  public tableData: IApiInvestigationTimelog[] = [];
  private _dataSource: InvestigationTimelogDatasource;
  public set dataSource(val) {
    this._dataSource = val;
  }
  public get dataSource() {
    return this._dataSource;
  }
  public sort: SortDescriptor[] = [{
    field: 'CREATED_AT',
    dir: 'desc'
  }];

  constructor(
    private notifications: NotificationsService,
    private loaderService: LoaderService,
    private investigationTimelogService: InvestigationTimelogService,
  ) { 
    this.dataSource = new InvestigationTimelogDatasource(this.investigationTimelogService);
    // Table Initialization / Setup
    this.loaderService.attachObservable(this.dataSource.loading$);

    // Default to sort by newest at top
    this.dataSource.listPage.sortOrder = SortOrder.DESC;
    this.dataSource.applyFilter(IApiInvestigationMetricsFilterType.ExcludeUnassigned, "true");
    this.dataSource.listPage.orderBy = "CREATED_AT";

    this.dataSource?.contents$.subscribe((res) => {
      this.tableData = res;
    });
  }

 
  ngOnInit(): void {
    this.load();
  }
  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public applyFilter(filters: IApiInvestigationMetricsFilter[]) {
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    filters?.map(filter => this.dataSource.applyFilter(filter?.type, filter?.value));
    this.dataSource.pagingReset();
    this.load();
  }

  public clearFilter(filters: IApiInvestigationMetricsFilter[]) {
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.dataSource.pagingReset();
    this.load();
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }


  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = "CREATED_AT";
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  };

  public async load(): Promise<any> {
    this.dataSource.pagingReset();
    await this.dataSource.load();
  }


}
