import { finalize, tap, take, switchMap, map } from 'rxjs/operators';
import { Observable, Subscription, MonoTypeOperatorFunction, pipe, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class LoaderService {

  private _subscriptions: Subscription[] = [];

  public displayText = '';
  public displaySubText = '';
  public _active = false;
  public _activeOther = false;

  public get active(): boolean {
    return this._active;
  }

  public get activeOther(): boolean {
    return this._activeOther;
  }

  public set active(val: boolean) {
    this._active = val;
  }

  public set activeOther(val: boolean) {
    this._activeOther = val;
  }

  constructor() {}

  public hide() {
    this.active = false;
  }

  public hideOther() {
    this.activeOther = false;
  }

  // create an observable stream that shows/hides loader automatically
  public show$<T>(o: Observable<T>): Observable<T> {
    return of(true).pipe(
      take(1),
      this.showPipe<T>(o)
    );
  }

  public show() {
    this.active = true;
  }

  public showOther() {
    this.activeOther = true;
  }

  // NOTE: This observable must complete itself or this will get wonky!
  public attachObservable(o: Observable<boolean>) {
    this._subscriptions.push(o.subscribe(r => this.active = r));
  }

  public showPipe<T>(o: Observable<T>, finalizeHide = true): MonoTypeOperatorFunction<T> {
    return pipe(
      tap(() => this.show()),
      switchMap(() => o),
      finalizeHide ? this.finalizeHidePipe() : tap(),
    );
  }

  public finalizeHidePipe<T>(): MonoTypeOperatorFunction<T> {
    return pipe(
      finalize(() => this.hide())
    );
  }

}
