<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageNefcoOfficeCreate" #manageNefcoOfficeCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageNefcoOfficeUpdate" #manageNefcoOfficeUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageNefcoOfficeDelete" #manageNefcoOfficeDelete="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <div class="page-header">
    <h4 class="mb-0 page-title">Manage NEFCO Office</h4>
    <div *ngIf="manageNefcoOfficeCreate?.enabled">
      <kendo-button kendoButton [themeColor]="'primary'" fillMode="solid" iconClass="fas fa-plus" (click)="addOrUpdate()">New Branch</kendo-button>
    </div>
  </div>

  <kendo-grid 
    class="nefco-office-list td-16-8" 
    [data]="{
      data: (dataSource?.contents$ | async) || [],
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }" 
    [pageSize]="pageOptions?.limit" 
    [skip]="pageOptions?.offset" 
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }" 
    (pageChange)="pageChange($event)"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)">

    <kendo-grid-column [field]="orderByOptions.Name" title="Name" [width]="15" [sortable]="true" class="font-weight600"
      [headerClass]="'sort-header'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [innerHTML]="dataItem?.name"></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="NAME" title="Address" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container
          *ngIf="dataItem?.Address?.address1 || dataItem?.Address?.city || dataItem?.Address?.state || dataItem?.Address?.postal; else noValue">
          <ng-container *ngIf="dataItem?.Address?.address1">
            <span>{{ dataItem?.Address?.address1 }}<br /></span>
          </ng-container>
          <span>
            {{ dataItem?.Address?.city ? dataItem?.Address?.city + "," : "" }}
            {{ dataItem?.Address?.state }}
            {{ dataItem?.Address?.postal }}
          </span>
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="orderByOptions.TotalInvestigations" title="Total Investigations" [width]="10" [sortable]="true"
      [headerClass]="'sort-header'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem?.AssignedInvestigationCount || 0}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="orderByOptions.Status" title="Status" [width]="15" [sortable]="true"
      [headerClass]="'sort-header'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-badge [ngClass]="dataItem.isActive ? 'fw600' : 'fw600 fcolor'" position="none" size="medium" rounded="full"
          [themeColor]="dataItem.isActive ? 'primary' : 'tertiary'">{{dataItem.isActive ? 'Active' : 'Inactive'}}</kendo-badge>
      </ng-template>
    </kendo-grid-column>

    <!-- Action -->
    <kendo-grid-column field="id" title="Actions" [width]="10" [sortable]="false" [headerClass]="'nocursor'"
      *ngIf="manageNefcoOfficeUpdate?.enabled || manageNefcoOfficeDelete?.enabled">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoButton class="mr-8" themeColor="secondary" fillMode="link" iconClass="fa-solid fa-pencil"
          (click)="addOrUpdate(dataItem);"></button>
        <button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-trash"
          (click)="deleteOffice(dataItem?.id)" *ngIf="dataItem.name !== 'NEFCO'"></button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <ng-template #noValue>
    <span>-</span>
  </ng-template>
</section>