<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span>Restrict Staff Member</span>
      <div class="modal-subtitle"> Investigation ID: {{ investigation?.nefcoNumber || 'Unassigned' }}</div>
    </div>
  </kendo-dialog-titlebar>
  <section class="section-content">
    <form #form="ngForm" name="form">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']"
        class="mt-16">
        <kendo-gridlayout-item>
          <kendo-label text=" Select a staff member to restrict on this investigation."></kendo-label>
          <br />
          <br />
          <kendo-formfield class="kendo-formfield">
            <kendo-label #staffMember text="Staff Members"></kendo-label>
            <kendo-multiselect #staffMember [checkboxes]="true" [autoClose]="false" textField="fullName" valueField="id"
              [valuePrimitive]="true" [data]="users" [rounded]="'full'" [(ngModel)]="selectedUsers" name="users"
              [tagMapper]="tagMapper"
              [popupSettings]="{popupClass:'kendo-group-multiselect'}" tagMode="multiple"
              [placeholder]="'Select Staff Members'" [fillMode]="'outline'">
              <ng-template kendoMultiSelectItemTemplate let-dataItem>
                <span style="padding-left: 5px;">{{ dataItem?.fullName }}</span>
              </ng-template>
            </kendo-multiselect>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </section>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="save()"
      [disabled]="!selectedUsers?.length">
      Save
    </button>
  </kendo-dialog-actions>
</div>
