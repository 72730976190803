import { Component, Input, OnInit } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { IApiInvestigation, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { InvestigationService } from 'src/app/shared/services';

@Component({
  selector: 'app-investigation-edit-nefco-office',
  templateUrl: './investigation-edit-nefco-office.component.html',
  styleUrls: ['./investigation-edit-nefco-office.component.scss']
})
export class InvestigationEditNefcoOfficeComponent extends DialogContentBase implements OnInit {

  @Input() investigation: IApiInvestigation;
  public nefcoOfficeId: string = '';
  constructor(
    public dialogRef: DialogRef,
    private investigationService: InvestigationService,
    private loaderService: LoaderService,
    private notificationService: NotificationsService
  ) {
    super(dialogRef);
   }

  ngOnInit(): void {
    this.nefcoOfficeId = this.investigation.NefcoOffice.id;
  }

  public save() {
    const body = {
      id: this.investigation.id,
      NefcoOfficeId: this.nefcoOfficeId,
    };
    this.loaderService
      .show$(
        this.investigationService.update({
          ...body,
          History: {
            InvestigationId: body.id,
            updateType: IApiInvestigationUpdateTypes.Update,
            updateCategory: IApiInvestigationUpdateCategories.Detail,
            comment: "Nefco office updated",
          },
        })
      )
      .pipe(
        this.notificationService.snackbarErrorPipe(),
        this.notificationService.snackbarPipe("Nefco office updated")
      )
      .subscribe(() => this.dialog.close(true));
  }
}
