import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { groupBy } from '@progress/kendo-data-query';
import moment from 'moment';
import { IApiAddDocumentSentInput, IApiInvestigation } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { DocumentService } from 'src/app/shared/services';

export type IContactClientDetails = {
  id?: string; 
  text?: string; 
  type?: string; 
  field?: string; 
  companyName?: string;
};

@Component({
  selector: 'app-markas-sent',
  templateUrl: './markas-sent.component.html',
  styleUrls: ['./markas-sent.component.scss']
})
export class MarkasSentComponent extends DialogContentBase implements OnInit {

  @ViewChild("form") form: NgForm;
  @Input() public data: {investigation?: IApiInvestigation; documentIds?: Array<string>; documentTypeIds?: Array<string>} = {};
  public sendNote: string = '';
  public sendDate = null;
  public clientContact = null;
  public openInstructions: boolean = false;
  public groupedDataClientContactList: Array<IContactClientDetails> = [];
  public selectedContact: Array<IContactClientDetails> = [];
  public selectedContactIds: Array<string> = [];

  constructor(
    public dialog: DialogRef,
    private documentService: DocumentService,
    private loader: LoaderService,
  ) {
    super(dialog);
   }

  ngOnInit(): void {
    if (this?.data?.investigation?.BillTo) {
      this.groupedDataClientContactList.push({
        id: `billto_${this?.data?.investigation?.BillTo?.id}`, 
        text: `${this?.data?.investigation?.BillTo?.firstName} ${this?.data?.investigation?.BillTo?.lastName}`,
        companyName: `${this?.data?.investigation?.BillToBranch?.Company?.name}`,
        type: 'Bill To'
      });
    }
    if (this?.data?.investigation?.Client) {
      this.groupedDataClientContactList.push({
        id: `client_${this?.data?.investigation?.Client?.id}`, 
        text: `${this?.data?.investigation?.Client?.firstName} ${this?.data?.investigation?.Client?.lastName}`,
        companyName: `${this?.data?.investigation?.ClientBranch?.Company?.name}`,
        type: 'Client'
      });
    }
    if (this?.data?.investigation?.Parties?.length > 0) {
      this?.data?.investigation?.Parties.map(obj => {
        this.groupedDataClientContactList.push({
          id: `additional_${obj?.Contact?.id}`, 
          text: `${obj?.Contact?.firstName} ${obj?.Contact?.lastName}`,
          companyName: `${obj?.Contact?.Branches?.[0]?.Company?.name || ''}`,
          type: 'Additional Contacts'
        });
      });
    }
    if (this?.data?.investigation?.Insured) {
      let contactName = this?.data?.investigation?.Insured?.insuredName;
      let companyName = '';
      if (this?.data?.investigation?.Insured?.contactName) {
        contactName = this?.data?.investigation?.Insured?.contactName;
        companyName = this?.data?.investigation?.Insured?.insuredName;
      } 
      this.groupedDataClientContactList.push({
        id: `insured_${this?.data?.investigation?.Insured?.id}`, 
        text: contactName,
        companyName: companyName,
        type: 'Additional Contacts'
      });
    }
    this.groupedDataClientContactList = groupBy(this.groupedDataClientContactList, [
      { field: "type" },
    ]);
  }

  public disabledDates = (date: Date): boolean => {
    return moment(date).isAfter();
  };

  public close() {
    this.dialog.close(null);
  }

  public toggleInst() {
    this.openInstructions = !this.openInstructions;
  }
  public markSent() {
    this.form.control.markAllAsTouched();
    if (this.form?.valid) {
      let input: IApiAddDocumentSentInput = {
        "DocumentIds": this.data.documentIds,
        "DocumentTypeIds": this.data.documentTypeIds,
        "sendDate": this.sendDate,
        "specialInstructions": this.data?.investigation?.Detail?.specialInstructions,
        "note": this.sendNote,
        "InvestigationId": this.data?.investigation?.id
      };
      if (this.selectedContact?.length) {
        let contactIds = [];
        this.selectedContact.map((obj) => {
          if (obj?.type === "Bill To") {
            input['BillToId'] = obj?.id.replace("billto_", "");
          }
          if (obj?.type === "Client") {
            input['ClientId'] = obj?.id.replace("client_", "");
          }
          if (obj?.type === "Additional Contacts") {
            if (obj.id.includes('insured_')) {
              input['InsuredId'] = obj?.id.replace("insured_", "");
            } else {
              contactIds.push(obj?.id.replace("additional_", ""));
            }
          }
        })
        if (contactIds?.length > 0) {
          input['ContactIds'] = contactIds;
        }
      }
      
      this.loader.show$(this.documentService.sentDocument(input)).subscribe((res) => {
        this.dialog.close('sent');
      });
    }
  }

  public valueChange(event) {
    this.selectedContactIds = event?.map(obj => obj.id);
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 2 ? tags : [tags];
  }



}
