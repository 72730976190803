import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ListPage } from "src/app/shared/helpers/list-page.class";
import { IGqlBaseOptions } from "src/app/shared/modules/graphql/interfaces";
import { GqlInvestigationRestrictionService } from "src/app/shared/modules/graphql/services/investigation/investigation-restriction/investigation-restriction.service";
import { IApiAddMultipleInvestigationRestrictionInput, IApiInvestigationRestriction, IApiInvestigationRestrictionConnection, IApiInvestigationRestrictionFilter, IApiRemoveMultipleInvestigationRestrictionInput } from "src/app/shared/modules/graphql/types/types";

@Injectable()
export class InvestigationRestrictionService {

  constructor(
    private gqlInvestigationRestrictionService: GqlInvestigationRestrictionService
  ) { }

  public get(filters: IApiInvestigationRestrictionFilter[] = [], options?: IGqlBaseOptions): Observable<IApiInvestigationRestrictionConnection> {
    return this.gqlInvestigationRestrictionService.get(filters, options || new ListPage());
  }

  public add(input: IApiAddMultipleInvestigationRestrictionInput): Observable<IApiInvestigationRestriction[]> {
    return this.gqlInvestigationRestrictionService.addInvestigationRestriction(input);
  }

  public remove(input: IApiRemoveMultipleInvestigationRestrictionInput): Observable<boolean> {
    return this.gqlInvestigationRestrictionService.removeInvestigationRestriction(input);
  }
}
