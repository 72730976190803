import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IApiPhotoFolder, IApiPhotoFolderFilterType, IApiInvestigation, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { PhotoFolderService, S3Service, AuthService, InvestigationTemplatDownloadService } from 'src/app/shared/services';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';

// Interfaces to allow download options for each photo folder
enum DownloadOptions {
  all = 'all',
  used = 'used',
  unused = 'unused',
  none = 'none'
}

interface ISelectablePhotoFolder extends IApiPhotoFolder {
  downloadOption: DownloadOptions;
}

@Component({
  selector: 'app-investigation-download-photos-modal',
  templateUrl: './investigation-download-photos-modal.component.html',
  styleUrls: ['./investigation-download-photos-modal.component.scss']
})
export class InvestigationDownloadPhotosModalComponent extends DialogContentBase implements OnInit, OnDestroy {

  public error: string = null;
  @Input() data = null;

  private _investigation: IApiInvestigation;
  public get investigation() {
    return this._investigation;
  }

  public photosFolders: ISelectablePhotoFolder[] = [];
  public downloading = false;
  public downloadPercentage = 0;
  public uniqueId = "";
  private currentUser: IApiUser;
  private downloadPhotos: Subscription;
  public label = {
    visible: true,
    position: "end",
    format: "percent",
  };
  constructor(
    private photoFolderService: PhotoFolderService,
    private investigationTemplateDownload: InvestigationTemplatDownloadService,
    private loader: LoaderService,
    private s3Service: S3Service,
    private auth: AuthService,
    public dialog: DialogRef,
    private notifications: NotificationsService
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    this._investigation = this.data.investigation;
    this.loader.show$(this.photoFolderService.get([{ type: IApiPhotoFolderFilterType.Investigation, value: this.investigation.id }, { type: IApiPhotoFolderFilterType.ViewList, value: "true" }], {})
      .pipe(unwrapConnection())
    ).subscribe(photoData => {
      this.photosFolders = photoData;
      this.photosFolders.map(a => a.downloadOption = DownloadOptions.none);
    });

    this.auth.authenticatedUser.subscribe((u) => {
      this.currentUser = u;
      this.uniqueId = `${Date.now()}_${this.currentUser.id}`;
    });
  }

  // "Select All" photos checkbox
  public checkAll(event): void {
    event.target.checked ? this.photosFolders.map(a => a.downloadOption = DownloadOptions.all) : this.photosFolders.map(a => a.downloadOption = DownloadOptions.none);
  }

  // Toggle "Select All" checkbox if all are selected
  public allSelected() {
    const download = this.photosFolders.filter(a => a.downloadOption === DownloadOptions.all).length;
    const all = this.photosFolders.length;
    return !!download && download === all;
  }

  // Find & Format Selected Photo Folder URIs
  public findUris() {
    const selectedPhotos = this.photosFolders.filter(a => a.downloadOption !== DownloadOptions.none);
    return selectedPhotos?.map(f => {
      let isUsed = null;
      switch (f.downloadOption) {
        case DownloadOptions.all:
          isUsed = null;
          break;
        case DownloadOptions.used:
          isUsed = true;
          break;
        case DownloadOptions.unused:
          isUsed = false
          break;
      }
      return {
        id: f.id,
        isUsed: isUsed
      }
    });
  }

  public download(low = null) {
    const uploadUris = this.findUris();
    if (uploadUris.length < 1) {
      this.error = 'No photos are available to download with your selection.';
      return;
    }
    this.error = null;
    this.downloading = true;
    const input = { Folders: uploadUris, InvestigationId: this.investigation?.id, UserId: this.currentUser.id, uniqueId: this.uniqueId, env: environment.env, lowRes: low };
    if(low === null){
      delete input.lowRes;
    }
    this.loader.show$(this.investigationTemplateDownload.generatePhotoDownload(input)
      .pipe(this.notifications.snackbarErrorPipe()))
      .subscribe(() => {
        this.notifications.kendoConfirm("Your photo download request has been successfully queued. You will receive a confirmation email when it is ready.", "Photo Download Request", "", "Ok").subscribe(() => {
          this.close(true);
        });
        this.downloading = false;
      }, error => {
        console.log(error);
        this.downloading = false;
      });
  }

  close(data = false) {
    this.dialog.close(data);
  }

  ngOnDestroy(): void {
    this.downloadPhotos?.unsubscribe();
  }
}
