import gql from 'graphql-tag';

export const generateTemplateDownloadMutation = gql`query GenerateTemplateDownload($input: TemplateDownloadGenerateInput!) {
  generateTemplateDownload(input: $input)
}`;

export const generatePhotoDownloadMutation = gql`query GeneratePhotoDownload($input: PhotoDownloadInput!) {
  generatePhotoDownload(input: $input)
}`;

export const generatePhotoRTFMutation = gql`query GeneratePhotoRTF($input: PhotoDownloadRTFInput!) {
  generatePhotoRTF(input: $input)
}`;

export const generateEvidenceListQuery = gql`query GenerateEvidenceListCSV($input: ID!) {
  generateEvidenceListCSV(input: $input)
}`;

export const generateEvidenceListCSVbyId = gql`query GenerateEvidenceListCSVbyId($input: EvidenceListDownloadByIdInput!) {
  generateEvidenceListCSVbyId(input: $input)
}`;

export const generatePayrollListCSV = gql`query GeneratePayrollListCSV($input: PayrollDownloadInput!) {
  generatePayrollListCSV(input: $input)
}`;

export const templateDataQuery = gql`query TemplateData {
  templateData
}`;
