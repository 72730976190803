<section class="content-part m-0">
    <kendo-gridlayout class="pt-16 pl-16 pr-16 position-fixed-top" id="mobile-search-header" [rows]="['auto']" [gap]="{rows: 0, cols: 16}"
        [cols]="['repeat(3,minmax(0,1fr))']">
        <kendo-gridlayout-item colSpan="2">
            <kendo-formfield class="left-search-icon">
                <div style="position: relative;">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <kendo-textbox #searchBy placeholder="Search" [(ngModel)]="textSearch" fillMode="outline" (ngModelChange)="changedText($event)"
                        [clearButton]="true" [disabled]="loaderService.active">
                    </kendo-textbox>
                </div>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="text-right">
            <button kendoButton themeColor="base" fillMode="solid" [iconClass]="'fa-solid fa-arrow-up-short-wide'"
                (click)="drawerFilter.toggle(); addBodyClass()"></button>
            <button kendoButton themeColor="base" fillMode="solid" [iconClass]="'fa-solid fa-sliders'"
                class="ml-16" (click)="drawerSort.toggle(); addBodyClass()"></button>
        </kendo-gridlayout-item>
    </kendo-gridlayout>


    <div class="investigation-list pt-16 pb-16" id="mobile-investigation-list"  appScrollNearEnd [threshold]="1" (nearEnd)="onNearEndScroll()">
        <ng-container *ngFor="let investigation of data; trackBy: trackByIndex" >
            <kendo-card class="ml-16 mr-16 mb-16" [width]="''"
            [ngStyle]="investigation?.NearOnScene && {'border': '2px solid #2196F3', 'border-radius': '10px'}">
                <kendo-card-header>
                    <div class="header-id-action mb-8">
                        <a [routerLink]="['/investigations', investigation?.id]" class="mobile-id-link" style="padding-left: 38px;">
                            ID: {{ investigation?.nefcoNumber || "Unassigned" }}
                        </a>

                        <kendo-dropdownbutton class="actions" [data]="actionItem | MobileInvestigationListActionFilter: investigation" themeColor="primary" fillMode="link"
                            buttonClass="grid-action-items-reverse" [iconClass]="'fa-solid fa-ellipsis'"
                            (itemClick)="actionSelected($event?.text, investigation)" [popupSettings]="{
                                    appendTo: 'root',
                                    animate: false,
                                    width: 250,
                                    popupClass: 'grid-action-items-menu mt-12'
                                }">
                        </kendo-dropdownbutton>
                    </div>
                    <div class="header-date text-center">
                        <kendo-badge *ngIf="investigation?.NearOnScene else createdDateShow"
                        class="fw600" position="none" size="medium" rounded="full" themeColor="primary">
                            Scene Exam Date: {{investigation?.NearOnScene?.scheduledDate | dateWithTimezone : "MM/DD/YYYY" : investigation?.timezone}} at
                            {{investigation?.NearOnScene?.scheduledDate | dateWithTimezone : "hh:mm a" : investigation?.timezone}}
                        </kendo-badge>
                        <ng-template #createdDateShow>
                            <kendo-badge class="fw600 fcolor" position="none" size="medium" rounded="full" themeColor="tertiary">
                                Created Date: {{investigation?.createdAt | customDate : "MM/dd/yyyy"}} at
                                {{investigation?.createdAt | customDate : "hh:mm a"}}
                            </kendo-badge>
                        </ng-template>
                    </div>
                </kendo-card-header>
                <kendo-card-body>
                    <div class="column">
                        <div class="equal mb-16">
                            <h6 class="kendo-caption"><small>Location</small></h6>
                            <h6 class="kendo-caption-body">
                                <ng-container *ngIf="investigation?.InvestigationAddress; else locationEmpty">
                                    <ng-container *ngIf="investigation?.InvestigationAddress?.address1">
                                        <span>{{ investigation?.InvestigationAddress?.address1 }}</span>
                                    </ng-container>
                                    <ng-container *ngIf="investigation?.InvestigationAddress?.address2">
                                        <span>{{ investigation?.InvestigationAddress?.address2 }}</span>
                                    </ng-container>
                                    <br />
                                    <span>
                                    {{ investigation?.InvestigationAddress?.city }},
                                    {{ investigation?.InvestigationAddress?.state }}
                                    {{ investigation?.InvestigationAddress?.postal }}
                                    </span>
                                </ng-container>
                                <ng-template #locationEmpty>-</ng-template>
                            </h6>
                        </div>
                        <div class="equal mb-16" *ngIf="!investigation?.NearOnScene">
                          <h6 class="kendo-caption"><small>Has insured been contacted?</small></h6>
                          <h6 class="kendo-caption-body">
                              <span>{{ investigation?.InsuredContacted ? 'Yes' : 'No'}}</span>
                          </h6>
                      </div>

                        <ng-container *ngIf="investigation.showMore">
                            <hr class="divider-line mb-16" />

                            <div class="equal" *ngIf="investigation?.NearOnScene">
                                <h6 class="kendo-caption"><small>Status</small></h6>
                                <h6 class="kendo-caption-body">
                                    <kendo-badge *ngIf="investigation?.NearOnScene else noValue"
                                    [ngClass]="(investigation?.NearOnScene?.status === investigationOnSceneStatus.EN_ROUTE ||
                                    investigation?.NearOnScene?.status === investigationOnSceneStatus.OFF_SCENE) ? 'fw600 fcolor' : 'fw600'
                                    " position="none" size="medium" rounded="full" themeColor="{{
                                    investigation?.NearOnScene?.status === investigationOnSceneStatus.SCHEDULED
                                        ? 'primary'
                                        : investigation?.NearOnScene?.status === investigationOnSceneStatus.EN_ROUTE
                                        ? 'warning'
                                        : investigation?.NearOnScene?.status === investigationOnSceneStatus.ON_SCENE
                                        ? 'success'
                                        : investigation?.NearOnScene?.status === investigationOnSceneStatus.OFF_SCENE
                                        ? 'tertiary'
                                        : ''
                                    }}">{{investigation?.NearOnScene?.status}}</kendo-badge>
                                </h6>
                            </div>

                            <div class="equal">
                                <h6 class="kendo-caption"><small>Insured</small></h6>
                                <h6 class="kendo-caption-body">
                                    <span *ngIf="investigation?.Insured else noValue">{{ investigation?.Insured | insuredName }}</span>
                                    <br />
                                    <a href="tel:{{(investigation?.Insured?.Phones | primaryAssociation)?.number}}" (click)="addInsuredContacted(investigation)" class="linkmd mt-4"
                                        *ngIf="(investigation?.Insured?.Phones | primaryAssociation)?.number">
                                        {{(investigation?.Insured?.Phones | primaryAssociation)?.number | phoneFormat : "US" }}
                                    </a>
                                </h6>
                            </div>

                            <div class="equal">
                                <h6 class="kendo-caption"><small>Client</small></h6>
                                <h6 class="kendo-caption-body">
                                    <ng-container *ngIf="!investigation?.Client?.firstName &&
                                    !investigation?.Client?.lastName &&
                                    !investigation?.ClientBranch; else cliendInfo
                                    ">-</ng-container>
                                    <ng-template #cliendInfo>
                                        <span *ngIf="investigation?.Client">{{ investigation?.Client | fullName }} <br /></span>
                                        <span *ngIf="investigation?.ClientBranch?.Company?.name">
                                            {{ investigation?.ClientBranch?.Company?.name}}
                                            <br />
                                        </span>
                                        <span>{{ investigation?.ClientBranch?.name }}</span>
                                    </ng-template>
                                </h6>
                            </div>

                            <div class="equal">
                                <h6 class="kendo-caption"><small>Client Email</small></h6>
                                <h6 class="kendo-caption-body">
                                    <a href="mailto:{{((investigation.Client?.Emails | primaryAssociation)?.address)}}" class="linkmd"
                                        *ngIf="(investigation.Client?.Emails | primaryAssociation)?.address else noValue">
                                        {{(investigation.Client?.Emails | primaryAssociation)?.address}}
                                    </a>
                                </h6>
                            </div>

                            <div class="equal">
                                <h6 class="kendo-caption"><small>Client Phone</small></h6>
                                <h6 class="kendo-caption-body">
                                    <a href="tel:{{((investigation.Client?.Phones | primaryAssociation)?.number)}}" class="linkmd"
                                        *ngIf="(investigation.Client?.Phones | primaryAssociation)?.number else noValue"
                                        (click)="clientCallHistorySave(investigation)">
                                        {{(investigation.Client?.Phones | primaryAssociation)?.number | phoneFormat : "US" }}
                                    </a>
                                </h6>
                            </div>

                            <div class="equal">
                                <h6 class="kendo-caption"><small>Loss Date</small></h6>
                                <h6 class="kendo-caption-body">
                                    {{investigation?.lossDate | customDate : "MM/dd/yyyy" : true}} at
                                    {{investigation?.lossDate | customDate : "hh:mm a" : true}}
                                </h6>
                            </div>

                            <div class="equal">
                                <h6 class="kendo-caption"><small>Risk Type</small></h6>
                                <h6 class="kendo-caption-body">
                                    <div class="risk-type">
                                        <img
                                          class="risk-type-img"
                                          src="{{ getIcon(investigation?.RiskType?.name) }}"
                                        />
                                        <span class="text-overflow" style="margin-left: 4px;">{{ investigation?.RiskType?.name }}</span>
                                      </div>
                                </h6>
                            </div>

                            <div class="equal">
                                <h6 class="kendo-caption"><small>Assigned</small></h6>
                                <h6 class="kendo-caption-body">
                                    <ng-container *ngIf="investigation?.InvestigationStaff?.length > 0 else noValue">
                                        <div class="dflex assignedmore">
                                          <div class="assigned">
                                            <ng-container
                                              *ngFor="
                                                let staff of investigation?.assigned | slice : 0 : 2;
                                                let i = index
                                              "
                                            >
                                              <kendo-badge
                                                *ngIf="i < 2"
                                                class="{{
                                                  'fw600 assigned-badge' +
                                                    (i === 1 ? ' fcolor' : '') +
                                                    (i === 1 ? ' mt-5' : '')
                                                }}"
                                                position="none"
                                                size="medium"
                                                rounded="full"
                                                themeColor="{{
                                                  i === 0 ? 'primary' : i === 1 ? 'tertiary' : ''
                                                }}"
                                              >
                                                <span>{{ staff.User | fullName }}</span>
                                              </kendo-badge>
                                              <br />
                                            </ng-container>
                                          </div>
                                        </div>
                                      </ng-container>
                                </h6>
                            </div>

                            <div class="equal">
                                <h6 class="kendo-caption"><small>Claim Number</small></h6>
                                <h6 class="kendo-caption-body">{{investigation?.Detail?.claimNumber || '-'}}</h6>
                            </div>

                            <div class="equal">
                                <h6 class="kendo-caption"><small>Policy Number</small></h6>
                                <h6 class="kendo-caption-body">{{investigation?.Detail?.policyNumber || '-'}}</h6>
                            </div>
                        </ng-container>
                    </div>
                    <ng-template #noValue>-</ng-template>
                </kendo-card-body>
                <kendo-card-footer>
                    <a href="javascript:void(0)" class="linkmd" (click)="investigation.showMore = !investigation.showMore">
                        {{investigation.showMore ? 'Show Less' : 'Show More' }}
                    </a>
                </kendo-card-footer>
            </kendo-card>
        </ng-container>
        <ng-container *ngIf="data?.length === 0 && loaderService.active === false">
            <kendo-card class="pt-48 pb-48 ml-16 mr-16" [width]="''">
                <kendo-card-body class="text-center no-results-found">
                    <h6 class="mb-16">No results found</h6>
                    <p>We did not find any investigations matching your search. Please try searching again.</p>
                </kendo-card-body>
            </kendo-card>
        </ng-container>
    </div>

</section>

<!-- Filter Drawer -->
<kendo-drawer #drawerFilter [mini]="false" [mode]="'overlay'" [animation]="true">
    <ng-template kendoDrawerTemplate>
        <section class="content-part safe-area-padding-top">
            <div class="header mb-16">
                <h6>Filters</h6>
                <button class="close-action" kendoButton themeColor="primary" fillMode="link"
                    [iconClass]="drawerFilter.expanded && 'fa-solid fa-times fa-xl'"
                    (click)="drawerFilter.toggle(); addBodyClass(false)"></button>
            </div>

            <hr class="divider-line mb-16" />

            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
                <kendo-gridlayout-item class="mb-24">
                    <div class="kendo-formfield left-search-icon">
                        <kendo-label text="City"></kendo-label>
                        <div style="position: relative;">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <kendo-textbox [value]="filterValue(filterTypes.LossCity) || ''"
                                (valueChange)="setFilters($event, filterTypes.LossCity)" placeholder="Search loss city"
                                fillMode="outline" [clearButton]="false">
                            </kendo-textbox>
                        </div>
                    </div>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item class="mb-24">
                    <div class="kendo-formfield left-search-icon">
                        <kendo-label text="Investigation ID"></kendo-label>
                        <div style="position: relative;">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <kendo-textbox [value]="filterValue(filterTypes.SearchNefcoNumber) || ''"
                                (valueChange)="setFilters($event, filterTypes.SearchNefcoNumber)"
                                placeholder="Search by Nefco Number" fillMode="outline" [clearButton]="false">
                            </kendo-textbox>
                        </div>
                    </div>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item class="mb-24">
                    <div class="kendo-formfield" style="display: flex; flex-direction: column;">
                        <kendo-label class="mb-16" text="View By"></kendo-label>
                        <div class="k-checkbox-wrap">
                            <input class="mb-16" #createdDate type="checkbox" kendoCheckBox [(ngModel)]="viewByCreatedDate" />
                            <kendo-label [for]="createdDate"
                                class="k-checkbox-label font-weight-500 checkbox-margin mobile-color mb-16"
                                style="margin-bottom: 0" text="Created Date"></kendo-label>
                        </div>
                        <div class="k-checkbox-wrap">
                            <input #scheduleExamDate type="checkbox" kendoCheckBox [(ngModel)]="viewByScheduleDate" />
                            <kendo-label [for]="scheduleExamDate"
                                class="k-checkbox-label font-weight-500 checkbox-margin mobile-color"
                                style="margin-bottom: 0" text="Scheduled Exam Date"></kendo-label>
                        </div>
                    </div>
                </kendo-gridlayout-item>
            </kendo-gridlayout>

            <kendo-gridlayout class="pl-16 pr-16 text-center" [rows]="['auto']" [gap]="{rows: 16, cols: 16}"
                [cols]="['repeat(1,minmax(0,1fr))']" style="position: absolute; left:0; right:0; bottom: 0;z-index: 1;">

                <hr class="divider-line mb-24" />

                <kendo-gridlayout-item class="mb-0">
                    <kendo-button kendoButton themeColor="primary" fillMode="solid" size="large"
                        (click)="applyFilters(); drawerFilter.toggle(); addBodyClass(false);" style="width: 100%;">
                        Apply Filters
                    </kendo-button>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item class="mb-24">
                    <kendo-button kendoButton themeColor="primary" fillMode="link" size="large"
                    (click)="clearFilter(); drawerFilter.toggle(); addBodyClass(false);"
                        style="width: 100%;">
                        Clear All
                    </kendo-button>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </section>
    </ng-template>
</kendo-drawer>

<!-- Sort Drawer -->
<kendo-drawer #drawerSort [mini]="false" [mode]="'overlay'" [animation]="true">
    <ng-template kendoDrawerTemplate>
        <section class="content-part safe-area-padding-top">
            <div class="header mb-16">
                <h6>Sort By</h6>
                <button class="close-action" kendoButton themeColor="primary" fillMode="link"
                    [iconClass]="drawerSort.expanded && 'fa-solid fa-times fa-xl'"
                    (click)="drawerSort.toggle(); addBodyClass(false)"></button>
            </div>

            <hr class="divider-line mb-16" />

            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
                <kendo-gridlayout-item class="mb-24">
                    <div class="kendo-formfield" style="display: flex; flex-direction: column;">
                        <kendo-label class="mb-16" text="Created Date"></kendo-label>
                        <div class="kendo-radio mb-16">
                            <input kendoRadioButton name="createdAt" type="radio" id="createdDateNTO" [(ngModel)]="sortModel.createdAt"
                                [value]="'DESC'" />
                            <label class="k-radio-label" for="createdDateNTO">Created Date (Newest to Oldest)</label>
                        </div>
                        <div class="kendo-radio mb-16">
                            <input kendoRadioButton name="createdAt" type="radio" id="createdDateOTN" [(ngModel)]="sortModel.createdAt"
                                [value]="'ASC'" />
                            <label class="k-radio-label" for="createdDateOTN">Created Date (Oldest to Newest)</label>
                        </div>

                        <kendo-label class="mb-16" text="Scheduled Exam Date"></kendo-label>
                        <div class="kendo-radio mb-16">
                            <input kendoRadioButton name="scheduleDate" type="radio" id="scheduleDateNTO" [(ngModel)]="sortModel.scheduledDate"
                                [value]="'DESC'" />
                            <label class="k-radio-label" for="scheduleDateNTO">Scheduled Exam Date (Newest to Oldest)</label>
                        </div>
                        <div class="kendo-radio">
                            <input kendoRadioButton name="scheduleDate" type="radio" id="scheduleDateOTN" [(ngModel)]="sortModel.scheduledDate"
                                [value]="'ASC'" />
                            <label class="k-radio-label" for="scheduleDateOTN">Scheduled Exam Date (Oldest to Newest)</label>
                        </div>
                    </div>
                </kendo-gridlayout-item>
            </kendo-gridlayout>

            <kendo-gridlayout class="pl-16 pr-16 text-center" [rows]="['auto']" [gap]="{rows: 16, cols: 16}"
                [cols]="['repeat(1,minmax(0,1fr))']" style="position: absolute; left:0; right:0; bottom: 0;z-index: 1;">

                <hr class="divider-line mb-24" />

                <kendo-gridlayout-item class="mb-0">
                    <kendo-button kendoButton themeColor="primary" fillMode="solid" size="large"
                        (click)="applySort(); drawerSort.toggle(); addBodyClass(false)" style="width: 100%;">
                        Apply
                    </kendo-button>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item class="mb-24">
                    <kendo-button kendoButton themeColor="primary" fillMode="link" size="large"
                    (click)="clearSort(); drawerSort.toggle(); addBodyClass(false)"
                        style="width: 100%;">
                        Clear All
                    </kendo-button>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </section>
    </ng-template>
</kendo-drawer>

