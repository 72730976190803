import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvestigationReportComponent } from './investigation-report/investigation-report.component';
import { ReportModuleRouter } from './report.router';
import { SharedModule } from 'src/app/shared/shared.module';
import { InvestigationMetricsComponent } from './investigation-metrics/investigation-metrics.component';
import { InvestigationMetricsFiltersComponent } from './investigation-metrics/investigation-metrics-filters/investigation-metrics-filters.component';
import { InvestigationMetricsStateComponent } from './investigation-metrics/investigation-metrics-state/investigation-metrics-state.component';
import { InvestigationMetricsInvestigatorComponent } from './investigation-metrics/investigation-metrics-investigator/investigation-metrics-investigator.component';
import { InvestigationMetricsTableComponent } from './investigation-metrics/investigation-metrics-table/investigation-metrics-table.component';
import { InvestigationMatricsBilltoCompanyComponent } from './investigation-metrics/investigation-matrics-billto-company/investigation-matrics-billto-company.component';
import { InvestigationMetricsContactComponent } from './investigation-metrics/investigation-metrics-contact/investigation-metrics-contact.component';
import { InvestigationMetricsBasicComponent } from './investigation-metrics/investigation-metrics-basic/investigation-metrics-basic.component';
import { InvestigationMetricsRiskTypeComponent } from './investigation-metrics/investigation-metrics-risk-type/investigation-metrics-risk-type.component';
import { InvestigationMetricsClientCompanyComponent } from './investigation-metrics/investigation-metrics-client-company/investigation-metrics-client-company.component';
import { InvestigationMetricsTechReviewerComponent } from './investigation-metrics/investigation-metrics-tech-reviewer/investigation-metrics-tech-reviewer.component';
import { InvestigationMetricsDeterminationComponent } from './investigation-metrics/investigation-metrics-determination/investigation-metrics-determination.component';
import { InvestigationTimelogComponent } from './investigation-timelog/investigation-timelog.component';
import { QuarterlyUtilizationReportComponent } from './quarterly-utilization-report/quarterly-utilization-report.component';
import { QuarterlyHourReportComponent } from './quarterly-hour-report/quarterly-hour-report.component';
import { ReportSentMetricByEditorComponent } from './report-sent-metric-report/by-editor/report-sent-metric-by-editor.component';
import { InvestigationReportTimelogComponent } from './investigation-report-timelog/investigation-report-timelog.component';
import { InvestigationMetricsDocusketchReqeustedComponent } from './investigation-metrics/investigation-metrics-docusketch-requested/investigation-metrics-docusketch-requested.component';
import { ReportSentMetricTabsComponent } from './report-sent-metric-report/report-sent-metric-tabs.component';
import { ReportSentMetricFiltersComponent } from './report-sent-metric-report/report-sent-metric-filters/report-sent-metric-filters.component';
import { ByReportTypeComponent } from './report-sent-metric-report/by-report-type/report-sent-metric-by-report-type.component';
import { GeoFireReportComponent } from './investigation-metrics/geo-fire-report/geo-fire-report.component';
import { GeoFireReportFiltersComponent } from './investigation-metrics/geo-fire-report-filters/geo-fire-report-filters.component';

@NgModule({
  declarations: [
    InvestigationReportComponent,
    InvestigationMetricsComponent,
    InvestigationMetricsFiltersComponent,
    InvestigationMetricsInvestigatorComponent,
    InvestigationMetricsTableComponent,
    InvestigationMatricsBilltoCompanyComponent,
    InvestigationMetricsStateComponent,
    InvestigationMetricsContactComponent,
    InvestigationMetricsBasicComponent,
    InvestigationMetricsRiskTypeComponent,
    InvestigationMetricsClientCompanyComponent,
    InvestigationMetricsTechReviewerComponent,
    InvestigationMetricsDeterminationComponent,
    InvestigationTimelogComponent,
    QuarterlyUtilizationReportComponent,
    QuarterlyHourReportComponent,
    ReportSentMetricByEditorComponent,
    InvestigationReportTimelogComponent,
    InvestigationMetricsDocusketchReqeustedComponent,
    ReportSentMetricTabsComponent,
    ReportSentMetricFiltersComponent,
    ByReportTypeComponent,
    GeoFireReportComponent,
    GeoFireReportFiltersComponent,
  ],
  imports: [
    CommonModule,
    ReportModuleRouter,
    SharedModule
  ]
})
export class ReportModule { }
