<kendo-formfield class="left-search-icon multi">
  <kendo-label [text]="'NEFCO Office'"></kendo-label>
  <div style="position: relative;">
      <i class="fa fa-search" aria-hidden="true"></i>
      <kendo-multiselect #multiSelect 
          [checkboxes]="true" 
          [autoClose]="false" 
          [valuePrimitive]="true"
          [data]="nefcoOffices" 
          [(ngModel)]="selectedValue"
          [rounded]="'full'" 
          (valueChange)="searchSelect($event)" 
          valueField="id"
          textField="name"
          [tagMapper]="tagMapper"
          [placeholder]="'Select office'" 
          [fillMode]="'outline'"
          [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}">
          <ng-template kendoMultiSelectItemTemplate let-dataItem>
          <span style="padding-left: 5px;">{{ dataItem?.name }}</span>
          </ng-template>
      </kendo-multiselect>
  </div>
</kendo-formfield>