import { PermissionActions, PermissionCategories, PermissionScopes } from './../modules/graphql/enums/permissions.enums';
import { IGqlPermissionSet } from '../modules/graphql/services/authorization/authorization.interfaces';
import { Directive, Input, HostBinding, ElementRef, Renderer2, OnInit, HostListener, TemplateRef, ViewContainerRef } from '@angular/core';
import { AssignedDelegate } from "../types/permissions.types";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthService } from "../services";

export enum EnforcePermissionDisplayModes {
  Disabled,
  Hidden,
  StandAlone
}

export interface IEnforcePermissionConfig {
  category: PermissionCategories;
  appliedPermissions?: IGqlPermissionSet;
  assignedDelegate?: AssignedDelegate;
  displayMode?: EnforcePermissionDisplayModes;
}

export interface IEnforcePermissionSpecify {
  scope: PermissionScopes;
  action: PermissionActions;
}

@UntilDestroy()
@Directive({
  selector: '[appEnforcePermission]',
  exportAs: 'appEnforcePermission',
})
export class EnforcePermissionDirective implements OnInit {

  // by default, any component bound to this directive will be disabled until enabled by permissions
  private _enabled = false;
  private _overlayElement: HTMLElement;

  private _elementClass: string[] = [];
  @Input() appEnforcePermission: IEnforcePermissionConfig;
  @Input() specify?: IEnforcePermissionSpecify;

  @Input("class")
  @HostBinding("class")
  get elementClass(): string {
    return this._elementClass.join(" ");
  }
  set elementClass(val: string) {
    this._elementClass = val ? val.split(" ") : [];
  }
  @HostBinding("hidden") hidden = false;

  private _overlayClass = "enforce-overlay";

  public get enabled() {
    return this._enabled;
  }

  public get scopes() {
    return PermissionScopes;
  }
  public get actions() {
    return PermissionActions;
  }
  public get categories() {
    return PermissionCategories;
  }

  @HostListener("focusin") focus() {
    if (!this.appEnforcePermission || this.appEnforcePermission.displayMode === EnforcePermissionDisplayModes.StandAlone) return;

    // create a focus trap if disabling
    if (!this._enabled) {
      const focusInpt = this.elementRef.nativeElement.querySelector(`input`);

      focusInpt.focus();
      focusInpt.select();
    }
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private auth: AuthService,
  ) {
  }

  ngOnInit() {
    const {
      category,
      appliedPermissions,
      assignedDelegate,
      displayMode = EnforcePermissionDisplayModes.Hidden
    } = this.appEnforcePermission;

    // TODO revisit this... was causing issues
    // if (displayMode !== EnforcePermissionDisplayModes.StandAlone) {
    //   const { nativeElement } = this.elementRef;

    //   // create a new overlay div directly inside that will sit atop the z-index stack and prevent interaction
    //   this._overlayElement = this.renderer.createElement("div");
    //   this._overlayElement.classList.add(this._overlayClass);

    //   // create a dummy input to focus-trap if attempting to tab-into while disabled
    //   this._overlayElement.appendChild(this.renderer.createElement("input"));

    //   this.renderer.appendChild(nativeElement, this._overlayElement);
    // }

    if (!this.specify && !appliedPermissions) return;

    const permissionSet = this.specify ? {
      [this.specify.scope]: [this.specify.action]
    } : appliedPermissions;

    this.auth.hasCategoryPermission(category, permissionSet, assignedDelegate).pipe(
      untilDestroyed(this)
    ).subscribe(enabled => {
      this._enabled = enabled;

      switch (displayMode) {
        case EnforcePermissionDisplayModes.Hidden:
          // Hidden attribute doesn't reliably work, add a class that will hide the element
          this.hidden = !enabled;
          if (!enabled) this._elementClass.push("protected-element");
          break;
        case EnforcePermissionDisplayModes.Disabled:
          this._overlayElement.style.display = !enabled ? "block" : "none";
          break;
        case EnforcePermissionDisplayModes.StandAlone:
          break;
      }
    });

  }

  public async checkPermission(scope: PermissionScopes, action: PermissionActions) {
    return await this.auth.hasCategoryPermission(this.appEnforcePermission.category, {
      [scope]: [action]
    }).toPromise();
  }
}
