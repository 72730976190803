import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PortalAuthService } from 'src/app/shared/services/portal-services';


@Injectable()
export class PortalGuard  {

    constructor(
        private router: Router,
        private auth: PortalAuthService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.auth.user) {
            return true;
        } else {
            this.router.navigate(["portal", "login"]);
            return false;
        }
    }
}