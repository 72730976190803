import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GoogleMapService } from 'src/app/shared/modules/google-maps/google-map.service';
import { IApiGeoFireReportByStateArray, IApiInvestigationFilter, IApiInvestigationFilterType, IApiMapMode } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { InvestigationService } from 'src/app/shared/services';

export enum MAP_MODE {
  "STATE" = "STATE",
  "BOUND" = "BOUND",
};


@Component({
  selector: 'app-geo-fire-report',
  templateUrl: './geo-fire-report.component.html',
  styleUrls: ['./geo-fire-report.component.scss']
})
export class GeoFireReportComponent implements OnInit {

  public mapsLoaded: boolean = false;
  public states: IApiGeoFireReportByStateArray[] = [];
  public filters: IApiInvestigationFilter[] = [];
  public mode = MAP_MODE.STATE;
  public centerLocation = null;
  constructor(
    private _mapService: GoogleMapService,
    private investigationService: InvestigationService,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadStates();
    this.initGoogleMaps();
  }

  loadStates() {
    const filter = { type: IApiInvestigationFilterType.Cancelled, value: "false" };
    const isMode = this.filters?.find(f => f.type === IApiInvestigationFilterType.MapMode);
    const finalFilters = [...this.filters, filter];
    if (!isMode) {
      finalFilters.push({ type: IApiInvestigationFilterType.MapMode, value: IApiMapMode.State });
    }
    this.loaderService.show$(this.investigationService.getGeoFireReportByState(finalFilters)).subscribe(data => {
      this.states = data.states;
      this.cdr.detectChanges();
    });
  }


  private initGoogleMaps() {
    this._mapService.loadGoogleMapApi().subscribe(() => {
      this.mapsLoaded = true;
    });
  }

  public changeFilters(filters: IApiInvestigationFilter[]) {
    const hadBoundFilter = this.filters?.filter(f => f.type === IApiInvestigationFilterType.MapBounds || f.type === IApiInvestigationFilterType.MapMode) || [];
    this.filters = [...filters, ...hadBoundFilter];
    this.loadStates();
  }

  public boundChanged(data) {
    const { bound, mode } = data;
    this.filters = this.filters?.filter(f => f.type !== IApiInvestigationFilterType.MapBounds && f.type !== IApiInvestigationFilterType.MapMode);
    if (bound) {
      this.filters.push({
        type: IApiInvestigationFilterType.MapBounds,
        value: bound
      })
    }
    this.filters.push({
      type: IApiInvestigationFilterType.MapMode,
      value: mode || IApiMapMode.State
    })
    this.loadStates();
  }

  public setMapCenterZip(location, zoom = null) {
    this.centerLocation = {
      location: { ...location },
      zoom: zoom
    }
  }

  public filterWithCenter(data) {
    const hadBoundFilter = this.filters?.filter(f => f.type === IApiInvestigationFilterType.MapBounds || f.type === IApiInvestigationFilterType.MapMode) || [];
    this.filters = [...data?.filters, ...hadBoundFilter];
    this.setMapCenterZip(data.center, 5);
  }
}
