import { KeyValue } from "@angular/common";

export enum InvestigationStatusNames {
  'PENDING' = 'Pending',
  'ASSIGNED' = 'Assigned',
  'SCHEDULED' = 'Scheduled',
  'IN_PROGRESS' = 'In Progress',
  'READY_TO_BILL' = 'Ready To Bill',
  'HOLD_FOR_BILLING' = 'Hold For Billing',
  'ONGOING' = 'Ongoing',
  'ONGOING_PENDING_SECOND_SCENE_EXAM' = 'Ongoing Pending Second Scene Exam',
  'ONGOING_PENDING_THIRD_SCENE_EXAM' = 'Ongoing Pending Third Scene Exam',
  'ONGOING_PENDING_EVIDENCE_EXAM' = 'Ongoing Pending Evidence Exam',
  'ONGOING_PENDING_LITIGATION' = 'Ongoing Pending Litigation',
  'ONGOING_PENDING_DISPOSITION' = 'Ongoing Pending Disposition',
  'ONGOING_PENDING_CRIMINAL_TRIAL' = 'Ongoing Pending Criminal Trial',
  'ONGOING_PENDING_TRIAL' = 'Ongoing Pending Trial',
  'ONGOING_PENDING_SCHEDULING' = 'Ongoing Pending Scheduling',
  'READY_TO_CLOSE' = 'Ready To Close',
  'CLOSED' = 'Closed',
  'CANCELLED' = 'Cancelled'
};

export enum RequestStatuses {
  'SUBMITTED' = 'Submitted',
  'REJECTED' = 'Rejected',
  'IN_PROGRESS' = 'In Progress',
  'COMPLETE' = 'Complete'
};

// Order by ascending property key
export const keyAscOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
  return b.key > a.key ? -1 : (a.key > b.key ? 1 : 0);
}

// Order by descending property key
export const keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
  return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
}

// Preserve original property order
export const originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
  return 0;
}

// Order by ascending property value
export const valueAscOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
  return a.value.localeCompare(b.value);
}


// Mapping of Time entry types with quarterly hour report
// Note: If we making any changes in below code then please update same in the Frontend as well

export const quarterlyHourReportTypeKeys = {
  TRAINING: "Training",
  FIRE_INVESTIGATION: "Fire Investigation",
  TECH_REVIEWER: "Tech Reviewer",
  ELECTRICAL_ENGINEER: "Electrical Engineer",
  EVIDENCE_TECHNICIAN: "Evidence Technician",
  HAZMAT: "Hazmat"
};

export const quarterlyHourReportTypes = {
  [quarterlyHourReportTypeKeys.TRAINING]: ["Training Hours"],
  [quarterlyHourReportTypeKeys.FIRE_INVESTIGATION]: ["Testimony Hours: Fire Investigator Hours", "Investigation Hours: Fire Investigator Hours", "Investigation Hours: (CA) Fire Investigator Hours", "Testimony Hours: (CA) Fire Investigator Hours"],
  [quarterlyHourReportTypeKeys.TECH_REVIEWER]: ["Investigation Hours: Tech Review Engineer", "Investigation Hours: Tech Review Investigator", "Investigation Hours: (CA) Tech Review Investigator"],
  [quarterlyHourReportTypeKeys.ELECTRICAL_ENGINEER]: ["Investigation Hours: Electrical Engineering Hours", "Testimony Hours: Electrical Engineering Hours", "Investigation Hours: (CA) Electrical Engineering Hours", "Testimony Hours: (CA) Electrical Engineering Hours"],
  [quarterlyHourReportTypeKeys.EVIDENCE_TECHNICIAN]: ["Investigation Hours: Evidence Technician Hours", "Investigation Hours: (CA) Evidence Technician Hours"],
  [quarterlyHourReportTypeKeys.HAZMAT]: ["Investigation Hours: Hazmat Hours", "Investigation Hours: (CA) Hazmat Hours"]
};

export const getNotificationIcon = (key: string) => {
  switch (key) {
    case 'EVIDENCE':
      return 'fa-solid fa-box';
    case 'CERTIFICATION':
      return 'fa-solid fa-award';
    case 'INVESTIGATION':
      return 'fa-solid fa-clipboard';
    case 'SERVICE':
      return 'fa-solid fa-user-gear';
    case 'REQUEST':
      return 'fa-solid fa-file-circle-check';
    case 'PHOTO':
      return 'fa-solid fa-camera';
    case 'TIME':
      return 'fa-solid fa-money-bill';
    case 'EXPENSE':
      return 'fa-solid fa-money-bill';
    case 'REPORT':
      return 'fa-solid fa-square-poll-vertical';
    case 'DOCUMENT':
      return 'fa-solid fa-file';
    case 'BILLING':
      return 'fa-solid fa-dollar-sign';
    case 'COMPANY':
      return 'fa-solid fa-building';
    case 'CONTACT':
      return 'fa-solid fa-address-book';
    default:
      return 'fa-solid fa-file';
  }
}

export const imageMimeType = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/gif",
  "image/avif",
  "image/bmp",
  "image/heic",
  "image/heic-sequence",
  "image/heif",
  "image/heif-sequence",
  "image/tiff",
  "image/x-tga",
  "image/x-ms-bmp",
];

export const wordMimeTypes = [
  "application/msword", // for .doc files
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // for .docx files
  "application/vnd.openxmlformats-officedocument.wordprocessingml.documentapplication/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template"
];

export const excelMimeTypes = [
  "application/vnd.ms-excel", // for .xls files
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // for .xlsx files
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.oasis.opendocument.spreadsheet"
];

export const csvMimeTypes = [
  "text/csv"
];

export const pdfMimeType = [
  "application/pdf"
];

export const zipMimeType = [
  "application/zip",
  "application/octet-stream",
  "application/x-zip-compressed",
  "application/x-7z-compressed",
  "application/CDFV2",
  "application/cdfv2",
  "multipart/x-zip",
  "model/vnd.usdz+zip"
];

export const videoMimeType = [
  "video/x-msvideo",
  "audio/mpeg",
  "audio/mpeg2",
  "audio/mp3",
  "video/mp4",
  "video/ogg",
  "video/webm",
  "video/3gpp",
  "video/3gpp2",
  "video/avi",
  "video/msvideo",
  "application/mp4",
  "application/x-troff-msvideo",
  "audio/wav",
  "audio/x-wav",
  "audio/wave",
  "audio/x-pn-wav",
  "audio/vnd.wav",
  "audio/vnd.wave",
  "video/mov",
  "video/MP2T",
  "video/mpeg",
  "video/quicktime",
  "video/vnd.avi",
  "video/x-flv",
  "video/x-m4v",
  "video/x-matroska",
  "video/x-ms-asf",
  "video/x-ms-wm",
  "video/x-ms-wmv",
  "audio/AMR",
  "audio/mp4",
  "audio/ogg",
  "audio/x-aiff",
  "audio/x-hx-aac-adts",
  "audio/x-m4a",
  "audio/x-ms-wma",
  "audio/amr",
];

export const dcmMimeType = [
  "application/dicom",
  "application/x-dicom",
  "application/dicom"
];

export const textMimeType = [
  "text/x-vcard",
  "text/plain",
  "text/csv",
  "text/rtf",
  "application/vnd.oasis.opendocument.text",
  "message/rfc822",
  "application/rtf"
];

export const htmlMimeType = [
  "multipart/related"
];

export const messageMimeType = [
  ".msg",
  "application/vnd.ms-outlook",
  "application/CDFV2"
];

export const vCardMimeType = [
  "application/vnd.ms-office"
];

export const pptMimeType = [
  "application/vnd.openxmlformats-officedocument.presentationml.presentation"
];

export const psMimeType = [
  "application/postscript"
]

export const timezones = [
  { key: "Pacific/Honolulu", value: "Pacific/Honolulu" },
  { key: "America/Anchorage", value: "America/Anchorage" },
  { key: "America/Los_Angeles", value: "America/Los_Angeles" },
  { key: "America/Phoenix", value: "America/Phoenix" },
  { key: "America/Denver", value: "America/Denver" },
  { key: "America/Chicago", value: "America/Chicago" },
  { key: "America/New_York", value: "America/New_York" },
  { key: "America/Indiana/Indianapolis", value: "America/Indiana/Indianapolis" },
];
