import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root' // Make sure the guard is provided in root or module level
})
export class MobileAuthGuard  {

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate(next: ActivatedRouteSnapshot) {
    return this.auth.isAuthenticated$.pipe(
      tap(loggedIn => {
        if (!loggedIn) {
         this.router.navigate(['login'])
        }
      }),
      map(loggedIn => loggedIn)
    );
  }
}
