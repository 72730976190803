import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CompanyTypeService, EmailTypeService, LocalStorageService, PortalAuthService, PortalService, StateService } from 'src/app/shared/services';
import { IPortalTokenResponse } from '../interfaces/portal.interfaces';
import { ReactiveFormHelper } from 'src/app/shared/helpers/reactive-form.class';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AssignmentSentComponent } from '../assignment-sent/assignment-sent.component';
import { SharedService } from 'src/app/common/shared.service';
import { Subscription } from 'rxjs';
import { removeTypename, unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { IApiClientDetail, IApiClientUserDetail, IApiCompanyType, IApiCompanyTypeFilterType, IApiEmailType, IApiPhoneType, IApiPhoneTypeOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { AuthService as Auth } from '@auth0/auth0-angular';
import { IApiIntake } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import moment from "moment";
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';

@Component({
  selector: 'app-intake-form',
  templateUrl: './intake-form.component.html',
  styleUrls: ['./intake-form.component.scss']
})
export class IntakeFormComponent implements OnDestroy, OnInit {

  public get authUser(): IPortalTokenResponse {
    return this.portalAuth.user;
  }

  public form = this.fb.group({
    email: ['', [Validators.required, ReactiveFormHelper.emailValidator, ReactiveFormHelper.noWhitespaceValidator]],
    Emails: this.fb.array([]),
    ClientInvestigationNote: this.fb.group({
      insuranceCarrierName: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      jobTitle: ['', [Validators.required, Validators.maxLength(255), ReactiveFormHelper.noWhitespaceValidator]],
      companyTypeText: [''],
      companyTypeId: ['', [Validators.required]],
      company: this.fb.group({
        companyName: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
        companyAddress: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
        companyCity: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
        companyState: ['', [Validators.required]],
        companyZip: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
        companyPhone: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      }),
      firstName: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      address: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      city: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      phone: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      extension: [
        "",
      ],
      ContactPhone: this.fb.group({
        number: ['', [ReactiveFormHelper.noWhitespaceValidator]],
        extension: ['']
      }),
      contactIsAdjuster: [false],
      branchId: [null],
      companyId: [null]
    }),
    lossInformation: this.fb.group({
      claimNumber: [''],
      policyNumber: [''],
      lossDate: ['', [Validators.required]],
      lossTime: ['', []],
      RiskTypeId: ['', [Validators.required]],
      vehicleMake: ['', [ReactiveFormHelper.noWhitespaceValidator]],
      vehicleModel: ['', [ReactiveFormHelper.noWhitespaceValidator]],
      vehicleYear: [''],
      vehicleVinOrOtherId: ['', [ReactiveFormHelper.noWhitespaceValidator]],
      vehicleStock: ['', [ReactiveFormHelper.noWhitespaceValidator]],
      sameLocation: [null],
      lossVehicleAddress: [''],
      lossVehicleCity: [''],
      lossVehicleState: [''],
      lossVehicleZipcode: [''],
      lossAddress: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      lossCity: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      lossState: ['', [Validators.required]],
      lossZipcode: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      publicAdjuster: ['', [ReactiveFormHelper.noWhitespaceValidator]],
      adjusterPhoneNumber: ['', [ReactiveFormHelper.noWhitespaceValidator]],
      fireMarshal: ['', [ReactiveFormHelper.noWhitespaceValidator]],
      marshalPhoneNumber: ['', [ReactiveFormHelper.noWhitespaceValidator]]
    }),
    insuredInformation: this.fb.group({
      insuredType: [true, [Validators.required]],
      insuredName: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      companyName: [''],
      contactName: [''],
      nickName: ['', [Validators.required]],
      other: [''],
      additionalContacts: this.fb.array([]),
      insuredSameAsLossAddress: [false, [Validators.required]],
      insuredAddress: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      insuredCity: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      insuredState: ['', [Validators.required]],
      insuredZipcode: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      insuredContactNumber: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
    }),
    lossDetailsSpecialInstructions: this.fb.group({
      description: ['', [ReactiveFormHelper.noWhitespaceValidator]],
      docusketchRequested: [null, [Validators.required]],
      isXactimate: [false],
      isSymbility: [false],
    }),
    createPassword: this.fb.group({
      newPassword: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      confirmPassword: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]]
    }),
  });
  public notifyModel: boolean = false;
  public clickEventsubscription: Subscription;
  // fetch email types or use enum
  public emailTypes: IApiEmailType[] = [];
  public clientEmail: string;
  public existingClientDetails: IApiClientDetail;

  public submitted = false;
  public submissionError = false;
  public claimAlreadyExists = false;
  public isCurrentContact = false;
  public phoneTypes: IApiPhoneType[] = [];
  public companyTypes: IApiCompanyType[] = [];


  constructor(
    private portalAuth: PortalAuthService,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private local: LocalStorageService,
    public sharedService: SharedService,
    public emailTypeService: EmailTypeService,
    public portalService: PortalService,
    private el: ElementRef,
    private auth0: Auth,
    private loader: LoaderService,
    private stateService: StateService,
    private notifications: NotificationsService,
    private companyTypeService: CompanyTypeService
  ) {
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
      this.openConfirmModel();
    })
  }

  get additionalEmailFieldAsFormArray(): UntypedFormArray {
    return this.form.get('Emails') as UntypedFormArray;
  }

  ngOnInit() {
    this.clientEmail = this.local.getValueFromSession('formemail');
    if (!this.clientEmail) {
      this.clientEmail = this.local.getValueFromSession('clientemail');
    }
    this.form.patchValue({ email: this.clientEmail });
    this.checkAuth0Created();
    this.emailTypeService.get().pipe(
      unwrapConnection(),
      removeTypename()
    ).subscribe((types) => {
      this.emailTypes = types;
    });
    this.portalService
      .getContactType([], { orderBy: IApiPhoneTypeOrderBy.Name, sortOrder: SortOrder.ASC, limit: -1 })
      .pipe(unwrapConnection(), removeTypename())
      .subscribe((types) => {
        this.phoneTypes = types;
      });
    this.companyTypeService
      .get([{
        type: IApiCompanyTypeFilterType.IsIntake,
        value: "true"
      }])
      .pipe(unwrapConnection(), removeTypename())
      .subscribe((types) => {
        this.companyTypes = types;
      });
  }

  private checkAuth0Created() {
    this.form.patchValue({ email: this.clientEmail });
    this.loader.show$(
      this.portalService.checkClientExist(this.clientEmail, true))
      .subscribe((res: IApiClientUserDetail) => {
        this.existingClientDetails = res?.clientDetail;
        if (this.existingClientDetails?.email) {
          this.form.removeControl('createPassword');
          this.form.updateValueAndValidity();
          if (res?.contact) {
            this.getCurrentContact();
          }
        }
      });
  }

  private getCurrentContact() {
    this.loader.show$(this.portalService.getCurrentContact(this.clientEmail)).subscribe(res => {
      const currentContactInfo: IApiIntake = res?.data?.getCurrentContact || null;
      if (currentContactInfo) {
        const branch = currentContactInfo?.Contact?.BranchAssignments?.find(item => item.endDate == null)?.Branch;
        const primaryAddress: any = branch?.Addresses?.sort((a: any, b: any) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
        const primaryContact = branch?.Phones?.find(item => item.isPrimary);
        const contactNumber = currentContactInfo?.Contact?.Phones?.find(item => item?.isPrimary) || (currentContactInfo?.Contact?.Phones ? currentContactInfo?.Contact?.Phones[0] : null);
        const name = `${currentContactInfo?.Contact?.firstName || ''} ${currentContactInfo?.Contact?.lastName || ''}`
        this.isCurrentContact = true;
        this.form.get('ClientInvestigationNote').patchValue({ firstName: name })
        if (contactNumber?.number)
          this.form.get('ClientInvestigationNote').patchValue({
            ContactPhone: {
              number: contactNumber?.number,
              extension: contactNumber?.extension || ""
            }
          });

        const companyType = branch?.Company?.Type || this.companyTypes?.find(type => type?.name === 'Insurance Company');
        this.form.get('ClientInvestigationNote').patchValue({ jobTitle: currentContactInfo?.Contact?.jobTitle || currentContactInfo?.Contact?.Role?.name || '' })
        this.form.get('ClientInvestigationNote').patchValue({ companyTypeId: companyType?.id || '' })
        this.form.get('ClientInvestigationNote').patchValue({ companyTypeText: companyType?.name || '' })

        if (currentContactInfo?.Contact?.Emails?.length) {
          currentContactInfo.Contact.Emails.map(item => {
            if (item.Type.name === 'Other') {
              this.addAdditionalEmail(item.address);
            }
          });
        }
        if (companyType?.name === 'Insurance Company') {
          if (branch) {
            this.form.get('ClientInvestigationNote').patchValue({ branchId: branch?.id || null });
            this.form.get('ClientInvestigationNote').patchValue({ insuranceCarrierName: branch?.Company?.name || '' });
            if (primaryAddress?.length &&
              primaryAddress[0].address1 &&
              primaryAddress[0].city &&
              primaryAddress[0].state &&
              primaryAddress[0].postal) {
              this.form.get('ClientInvestigationNote').patchValue({ address: primaryAddress[0]?.address1 || '' })
              this.form.get('ClientInvestigationNote').patchValue({ city: primaryAddress[0]?.city || '' })
              this.form.get('ClientInvestigationNote').patchValue({ state: primaryAddress[0]?.state || '' })
              this.form.get('ClientInvestigationNote').patchValue({ zip: primaryAddress[0]?.postal || '' })
              this.form.get('ClientInvestigationNote').patchValue({ phone: primaryContact?.number || '' })
              this.form.get('ClientInvestigationNote').patchValue({ extension: primaryContact?.extension || '' })
            }
            this.form.get('ClientInvestigationNote').get('address').disable();
            this.form.get('ClientInvestigationNote').get('city').disable();
            this.form.get('ClientInvestigationNote').get('state').disable();
            this.form.get('ClientInvestigationNote').get('zip').disable();
            this.form.get('ClientInvestigationNote').get('phone').disable();
            this.form.get('ClientInvestigationNote').get('extension').disable();
          } else {
            this.form.get('ClientInvestigationNote').patchValue({ insuranceCarrierName: currentContactInfo?.ClientInvestigationNote?.insuranceCarrierName || '' });
            this.form.get('ClientInvestigationNote').patchValue({ address: currentContactInfo?.ClientInvestigationNote?.address || '' })
            this.form.get('ClientInvestigationNote').patchValue({ city: currentContactInfo?.ClientInvestigationNote?.city || '' })
            this.form.get('ClientInvestigationNote').patchValue({ state: currentContactInfo?.ClientInvestigationNote?.state || '' })
            this.form.get('ClientInvestigationNote').patchValue({ zip: currentContactInfo?.ClientInvestigationNote?.zip || '' })
            this.form.get('ClientInvestigationNote').patchValue({ phone: currentContactInfo?.ClientInvestigationNote?.phone || '' })
            this.form.get('ClientInvestigationNote').patchValue({ extension: currentContactInfo?.ClientInvestigationNote?.extension || '' })
            this.form.get('ClientInvestigationNote').get('address').enable();
            this.form.get('ClientInvestigationNote').get('city').enable();
            this.form.get('ClientInvestigationNote').get('state').enable();
            this.form.get('ClientInvestigationNote').get('zip').enable();
            this.form.get('ClientInvestigationNote').get('phone').enable();
            this.form.get('ClientInvestigationNote').get('extension').enable();
          }
        } else if (companyType?.name === 'Other') {
          this.form.get('ClientInvestigationNote').patchValue({
            company: {
              companyName: branch?.Company?.name || ''
            }
          }); if (primaryAddress?.length &&
            primaryAddress[0].address1 &&
            primaryAddress[0].city &&
            primaryAddress[0].state &&
            primaryAddress[0].postal)
            this.form.get('ClientInvestigationNote').patchValue({
              company: {
                companyAddress: primaryAddress[0]?.address1 || '',
                companyCity: primaryAddress[0]?.city || '',
                companyState: primaryAddress[0]?.state || '',
                companyZip: primaryAddress[0]?.postal || '',
                companyPhone: primaryContact?.number || ''
              }
            })
        }
        else {
          this.form.get('ClientInvestigationNote').patchValue({
            company: {
              companyName: branch?.Company?.name || ''
            }
          });
          if (primaryAddress?.length &&
            primaryAddress[0].address1 &&
            primaryAddress[0].city &&
            primaryAddress[0].state &&
            primaryAddress[0].postal)
            this.form.get('ClientInvestigationNote').patchValue({
              company: {
                companyAddress: primaryAddress[0]?.address1 || '',
                companyCity: primaryAddress[0]?.city || '',
                companyState: primaryAddress[0]?.state || '',
                companyZip: primaryAddress[0]?.postal || '',
                companyPhone: primaryContact?.number || ''
              }
            })
          this.form.get('ClientInvestigationNote').get('address').enable();
          this.form.get('ClientInvestigationNote').get('city').enable();
          this.form.get('ClientInvestigationNote').get('state').enable();
          this.form.get('ClientInvestigationNote').get('zip').enable();
          this.form.get('ClientInvestigationNote').get('phone').enable();
        }
        this.form.get('ClientInvestigationNote').get('company').get('companyName').updateValueAndValidity();
        this.form.get('ClientInvestigationNote').get('company').get('companyAddress').updateValueAndValidity();
        this.form.get('ClientInvestigationNote').get('company').get('companyCity').updateValueAndValidity();
        this.form.get('ClientInvestigationNote').get('company').get('companyState').updateValueAndValidity();
        this.form.get('ClientInvestigationNote').get('company').get('companyZip').updateValueAndValidity();
        this.form.get('ClientInvestigationNote').get('company').get('companyPhone').updateValueAndValidity();
        this.form.get('ClientInvestigationNote').get('firstName').updateValueAndValidity();
        this.form.get('ClientInvestigationNote').get('jobTitle').updateValueAndValidity();
        this.form.get('ClientInvestigationNote').get('branchId').updateValueAndValidity();
        this.form.get('ClientInvestigationNote').get('insuranceCarrierName').updateValueAndValidity();
        this.form.get('ClientInvestigationNote').get('address').updateValueAndValidity();
        this.form.get('ClientInvestigationNote').get('city').updateValueAndValidity();
        this.form.get('ClientInvestigationNote').get('state').updateValueAndValidity();
        this.form.get('ClientInvestigationNote').get('phone').updateValueAndValidity();
        this.form.get('ClientInvestigationNote').get('companyTypeText').updateValueAndValidity();
      }
    });
  }

  public addEmail(email = ''): any {
    return this.fb.group({
      address: [email, [Validators.required, ReactiveFormHelper.noWhitespaceValidator, Validators.email, Validators.compose([ReactiveFormHelper.emailValidator])]],
    });
  }

  public addAdditionalEmail(email = ''): void {
    this.additionalEmailFieldAsFormArray.push(this.addEmail(email));
  }

  public removeAdditionalEmail(i: number): void {
    this.additionalEmailFieldAsFormArray.removeAt(i);
  }

  public submitForm() {
    let data = {
      input: {
        companyTypeId: '',
        Contact: {},
        ClientInvestigationNote: { company: {} },
        Detail: {},
        Investigation: {},
        Insured: { insuredAddressPhone: {}, details: { Contact: {} } },
        company: { address: {}, name: "", phone: {} }
      }
    };
    let formData = this.form.value;
    const contactOfficeType = this.emailTypes.find(a => a.name == 'Work');
    const contactOtherType = this.emailTypes.find(a => a.name == 'Other');

    const Emails = [{
      "TypeId": contactOfficeType ? contactOfficeType.id : '',
      "address": formData?.email,
      "isPrimary": true
    }];
    if (formData && formData?.Emails && formData?.Emails?.length > 0) {
      formData?.Emails.map((obj) => {
        Emails.push({
          "TypeId": contactOtherType ? contactOtherType.id : '',
          "address": obj?.address,
          "isPrimary": false
        })
      });
    }
    if (formData?.ClientInvestigationNote?.ContactPhone?.number) {
      const phone = {
        "isPrimary": true,
        "TypeId": this.phoneTypes?.find(a => a.name === 'Cell')?.id,
        "number": formData?.ClientInvestigationNote?.ContactPhone?.number,
        "extension": formData?.ClientInvestigationNote?.ContactPhone?.extension || ''
      }
      data.input.Contact['Phones'] = [phone];
    }
    delete formData?.ClientInvestigationNote?.ContactPhone;
    data.input.Contact['Emails'] = [...Emails];
    data.input.ClientInvestigationNote = JSON.parse(JSON.stringify(formData?.ClientInvestigationNote));

    if (formData?.ClientInvestigationNote) {
      if (formData?.ClientInvestigationNote?.jobTitle) {
        data.input.Contact['jobTitle'] = formData?.ClientInvestigationNote?.jobTitle;
      }
      data.input.Contact['firstName'] = formData?.ClientInvestigationNote?.firstName?.split(" ")[0] || "";
      data.input.Contact['lastName'] = formData?.ClientInvestigationNote?.firstName?.split(" ")?.slice(1, formData?.ClientInvestigationNote?.firstName?.length)?.join(" ") || "";
    }
    if (!this.existingClientDetails?.email) {
      data.input['password'] = formData?.createPassword?.newPassword;
    }
    if (formData?.ClientInvestigationNote?.branchId) {
      data.input.Contact['BranchAssignment'] = {
        "isPrimary": false,
        "startDate": new Date(),
        "BranchId": formData?.ClientInvestigationNote?.branchId
      };
    }

    if (formData?.ClientInvestigationNote?.companyTypeText != 'Insurance Company') {
      data.input.company['name'] = formData?.ClientInvestigationNote?.company?.companyName;
      data.input.company.address = {
        isPrimary: true,
        address1: formData?.ClientInvestigationNote?.company?.companyAddress,
        city: formData?.ClientInvestigationNote?.company?.companyCity,
        state: formData?.ClientInvestigationNote?.company?.companyState,
        postal: formData?.ClientInvestigationNote?.company?.companyZip,
        country: ReactiveFormHelper.findCountryByStateCode(this.stateService.allStates, formData?.ClientInvestigationNote?.company?.companyState)
      }
      data.input.company.phone = {
        TypeId: "",
        isPrimary: true,
        number: formData?.ClientInvestigationNote?.company?.companyPhone
      }
    } else {
      delete data.input.company;
    }
    data.input['companyTypeId'] = formData?.ClientInvestigationNote?.companyTypeId;
    delete data.input.ClientInvestigationNote['companyTypeId'];
    delete data.input.ClientInvestigationNote['companyTypeText'];

    delete data.input.ClientInvestigationNote.company;

    if (formData?.lossInformation) {
      data.input.Detail['claimNumber'] = formData?.lossInformation?.claimNumber;
      data.input.Detail['policyNumber'] = formData?.lossInformation?.policyNumber;
      data.input.Detail['riskDescription'] = formData?.lossDetailsSpecialInstructions?.description;
      data.input.Detail['isSameLossLocation'] = formData?.lossInformation?.sameLocation;

      if (formData?.lossInformation?.sameLocation === false) {
        data.input.Detail['vehicleAddress'] = formData?.lossInformation?.lossVehicleAddress;
        data.input.Detail['state'] = formData?.lossInformation?.lossVehicleState;
        data.input.Detail['city'] = formData?.lossInformation?.lossVehicleCity;
        data.input.Detail['zip'] = formData?.lossInformation?.lossVehicleZipcode;
      }
      if (formData?.lossInformation?.vehicleMake) {
        data.input.Detail['make'] = formData?.lossInformation?.vehicleMake;
      }
      if (formData?.lossInformation?.vehicleModel) {
        data.input.Detail['model'] = formData?.lossInformation?.vehicleModel;
      }
      if (formData?.lossInformation?.vehicleYear) {
        data.input.Detail['year'] = formData?.lossInformation?.vehicleYear;
      }
      if (formData?.lossInformation?.vehicleVinOrOtherId) {
        data.input.Detail['vinNumber'] = formData?.lossInformation?.vehicleVinOrOtherId;
      }
      if (formData?.lossInformation?.vehicleStock) {
        data.input.Detail['stockNumber'] = formData?.lossInformation?.vehicleStock;
      }
      data.input.Detail['publicAdjusterName'] = formData?.lossInformation?.publicAdjuster;
      data.input.Detail['publicAdjusterPhoneNumber'] = formData?.lossInformation?.adjusterPhoneNumber;
      data.input.Detail['fireMarshalName'] = formData?.lossInformation?.fireMarshal;
      data.input.Detail['fireMarshalPhoneNumber'] = formData?.lossInformation?.marshalPhoneNumber;

      if (formData?.lossInformation?.lossDate) {
        let time = '00:00:00';
        if (formData?.lossInformation?.lossTime) {
          time = moment(formData?.lossInformation?.lossTime).format('HH:mm:ss');;
        }
        data.input.Investigation['lossDate'] = this.calculateLossDate(formData?.lossInformation?.lossDate, `${time}`);
      }
      data.input.Investigation['RiskTypeId'] = formData?.lossInformation?.RiskTypeId;
      data.input.Investigation['docusketchRequested'] = formData?.lossDetailsSpecialInstructions?.docusketchRequested;
      data.input.Investigation['isXactimate'] = formData?.lossDetailsSpecialInstructions?.isXactimate;
      data.input.Investigation['isSymbility'] = formData?.lossDetailsSpecialInstructions?.isSymbility;

      data.input.Investigation['receivedDate'] = (new Date()).toISOString();
      if (formData?.ClientInvestigationNote?.companyId) {
        data.input.Investigation['CompanyId'] = formData?.ClientInvestigationNote?.companyId;
      }
      data.input.Investigation['LossAddress'] = {
        "address1": formData?.lossInformation?.lossAddress,
        "address2": "",
        "city": formData?.lossInformation?.lossCity,
        "state": formData?.lossInformation?.lossState,
        "postal": formData?.lossInformation?.lossZipcode,
        "country": "",
        "TypeId": "",
      }
    }

    //Insured
    data.input.Insured.details['InvestigationId'] = "";
    data.input.Insured.details['RoleId'] = "";
    if (formData?.insuredInformation) {
      data.input.Insured.details.Contact['nickName'] = formData?.insuredInformation?.nickName;
      if (formData?.insuredInformation?.nickName === "Other") {
        data.input.Insured.details.Contact['nickName'] = formData?.insuredInformation?.other;
      }
      data.input.Insured.details.Contact['firstName'] = "";
      data.input.Insured.details.Contact['lastName'] = "";
      if (formData?.insuredInformation?.companyName) {
        data.input.Insured.details.Contact['insuredName'] = formData?.insuredInformation?.companyName;
        data.input.Insured.details.Contact['firstName'] = formData?.insuredInformation?.contactName;
      } else {
        data.input.Insured.details.Contact['firstName'] = formData?.insuredInformation?.insuredName;
      }
      data.input.Insured.details.Contact['Addresses'] = {
        "address1": formData?.insuredInformation?.insuredSameAsLossAddress ? formData?.lossInformation?.lossAddress : formData?.insuredInformation?.insuredAddress,
        "city": formData?.insuredInformation?.insuredSameAsLossAddress ? formData?.lossInformation?.lossCity : formData?.insuredInformation?.insuredCity,
        "state": formData?.insuredInformation?.insuredSameAsLossAddress ? formData?.lossInformation?.lossState : formData?.insuredInformation?.insuredState,
        "postal": formData?.insuredInformation?.insuredSameAsLossAddress ? formData?.lossInformation?.lossZipcode : formData?.insuredInformation?.insuredZipcode,
        "address2": "",
        "country": "",
        "TypeId": "",
      }
      data.input.Insured.insuredAddressPhone = {
        "isPrimary": false,
        "TypeId": "",
        "number": formData?.insuredInformation?.insuredContactNumber || "",
      }

      if (formData?.insuredInformation?.additionalContacts && formData?.insuredInformation?.additionalContacts?.length > 0) {
        let Phones = [];
        formData?.insuredInformation?.additionalContacts.map((obj, index) => {
          Phones.push({
            "isPrimary": (index === 0) ? true : false,
            "TypeId": obj.typeId,
            "number": obj.phoneNumber,
            "extension": obj.extension || "",
          });
        });
        data.input.Insured.details.Contact['Phones'] = Phones;
      }
    }
    this.submitted = false;
    this.submissionError = false;
    this.claimAlreadyExists = false;
    if (this.form.valid) {
      delete data['input'].ClientInvestigationNote['jobTitle'];
      delete data['input'].ClientInvestigationNote['firstName'];
      delete data['input'].ClientInvestigationNote['branchId'];
      delete data['input'].ClientInvestigationNote['companyId'];
      this.loader.displayText = 'Submitting Assignment';
      this.loader.show$(this.portalService.createIntake(data))
        .subscribe(res => {
          this.loader.displayText = '';
          this.confirmation();
        }, (err) => {
          this.loader.displayText = '';
          if (err?.message?.includes('Claim number already exists')) {
            this.claimAlreadyExists = true;
          } else {
            this.submissionError = true;
          }
          this.scrollToNotificationControl();
        });
    } else {
      this.submitted = true;
      this.form?.markAllAsTouched();
      this.scrollToNotificationControl();
    }
  }

  private scrollToNotificationControl() {
    const notificationError: HTMLElement = this.el.nativeElement.querySelector(
      "#form-invalid"
    );
    window.scroll({
      top: this.getTopOffset(notificationError),
      left: 0,
      behavior: "smooth"
    });
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  private resetFormFields(formGroup: UntypedFormGroup) {
    const notToReset = ['email'];
    Object.keys(formGroup.controls).forEach(field => {
      let control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        if (!notToReset.includes(field)) {
          control.reset();
        }
      } else if (control instanceof UntypedFormGroup) {
        this.resetFormFields(control);
      } else if (control instanceof UntypedFormArray) {
        if (field === 'Emails') {
          control.controls = [];
        } else if (field === 'additionalContacts') {
          control.controls = [control.controls[0]];
        }
        control.controls.forEach((fieldArray) => {
          if (fieldArray instanceof UntypedFormGroup) {
            this.resetFormFields(fieldArray);
          }
        })
      }
    });
  }


  public clearForm(): void {
    this.submitted = false;
    this.submissionError = false;
    this.claimAlreadyExists = false;
    const clientInvestigationNote = this.form.controls.ClientInvestigationNote as UntypedFormGroup;
    clientInvestigationNote?.controls.ContactPhone?.get('number').setValidators([ReactiveFormHelper.noWhitespaceValidator, Validators.pattern(/^\d{10}$/)]);
    clientInvestigationNote?.controls.ContactPhone?.get('number').updateValueAndValidity();
    this.resetFormFields(this.form);
  }

  public scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  public openConfirmModel() {

    this.notifications.kendoConfirm(
      "Are you sure you want to leave this page? Any changes made to the investigation form will not be saved.",
      "Go to Home",
      "No, Don’t Leave",
      "Yes, Leave",
      550
    )
      .subscribe((res) => {
        if (res) {
          this.backToHome();
        }
      });
  }

  public backToHome() {
    this.clearForm();
    this.local.removePortalEmail();
    this.loader.show();
    this.auth0.logout({
      logoutParams: {
        federated: true,
        returnTo: 'https://www.nefco.net',
      }
    });
  }

  public logout() {
    this.local.removePortalEmail();
    this.loader.show();
    this.auth0.logout({
      logoutParams: {
        federated: true,
        returnTo: window.origin + '/portal/login',
      }
    });
  }

  ngOnDestroy() {
    if (this.clickEventsubscription) {
      this.clickEventsubscription.unsubscribe();
    }
  }

  public confirmation() {
    const dialog: DialogRef = this.dialogService.open({
      content: AssignmentSentComponent,
      width: 600,
      maxWidth: 600,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    dialog.result.subscribe((result: DialogCloseResult) => {
      if (result === 'go-back') {
        this.backToHome();
      } else if (result === 'submitted') {
        this.clearForm();
        this.scrollToTop();
        this.checkAuth0Created();
      }
    });
  }

  public convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return { hours, minutes };
  }

  // Need to run this to properly combine lossDate and lossTime for save and convert to EST/EDT
  public calculateLossDate(lossDate, lossTime): string {
    return NefcoDateHelper.toUtcTime(lossDate, lossTime);
  }


}
