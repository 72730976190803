import { connectionQuery } from './../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { investigationConnectionQuery, addInvestigationMutation, removeInvestigationMutation, getInvestigationById, updateInvestigationMutation, restoreInvestigationMutation, markInvestigationViewed, investigationAssignmentSMSMutation, addInvestigationV2Mutation, updateInvestigationStaffScheduledDate, refreshPendingInvestigationSubscrption, getClosestInvestigatorByInvestigationId, getInvestigationByIdForSidebar, getInvestigationByIdForMobile, getGeoFireReportByStateQuery, getInvestigationByIdForGeoFire, refreshInProgressInvestigationSubscrption } from './investigation.operations';
import { IApiAddInvestigationV2Input, IApiGeoFireReportByStateConnection, IApiInvestigationAssignmentSms, IApiInvestigationAssignmentSmsInput, IApiInvestigationConnection, IApiInvestigationFilterType, IApiInvestigationStatusNames, IApiMutationUpdateInvestigationStaffScheduledDateArgs, IApiUserWithDistance } from '../../types/types';
import { safeResolve } from '../../rxjs';
import {
  IApiInvestigationFilter,
  IApiAddInvestigationInput,
  IApiUpdateInvestigationInput,
  IApiInvestigation
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlInvestigationService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getInvestigations(filters: IApiInvestigationFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationConnection> {

    // IApiInvestigationFilterType.Closed was interfering with IApiInvestigationFilterType.ActiveAndClosed
    // Remove "Closed" if "ActiveAndClosed" filter present
    if (filters.find(obj => obj.type === IApiInvestigationFilterType.ActiveAndClosed)) {
      filters = filters.filter(obj => obj.type !== IApiInvestigationFilterType.Closed);
    }

    const countFilters = filters.find(obj => obj.type === IApiInvestigationFilterType.CountOnly && obj.value === 'true');
    const detailFilters = filters.find(obj => (obj.type === IApiInvestigationFilterType.BillingStatus) || (obj.type === IApiInvestigationFilterType.Status && obj.value === IApiInvestigationStatusNames.InProgress));
    const viewType = filters.find(obj =>
      obj.type === IApiInvestigationFilterType.AppList ||
      obj.type === IApiInvestigationFilterType.ViewListView ||
      obj.type === IApiInvestigationFilterType.ViewOngoingView ||
      obj.type === IApiInvestigationFilterType.ViewPendingView ||
      obj.type === IApiInvestigationFilterType.ViewAssignedView ||
      obj.type === IApiInvestigationFilterType.ViewInprogressView ||
      obj.type === IApiInvestigationFilterType.ViewReadytobillView ||
      obj.type === IApiInvestigationFilterType.ViewOnlyNefconumber ||
      obj.type === IApiInvestigationFilterType.ViewProximityCheck
    );
    return this.apollo.query(connectionQuery(investigationConnectionQuery(!countFilters && detailFilters ? true : false, viewType), filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addInvestigation(input: IApiAddInvestigationInput): Observable<IApiInvestigation> {
    return this.apollo.mutate({
      mutation: addInvestigationMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public addInvestigationV2(input: IApiAddInvestigationV2Input): Observable<IApiInvestigation> {
    return this.apollo.mutate({
      mutation: addInvestigationV2Mutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeInvestigation(id: string) {
    return this.apollo.mutate({
      mutation: removeInvestigationMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public rejectInvestigation(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeInvestigationMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateInvestigation(input: IApiUpdateInvestigationInput): Observable<IApiInvestigation> {
    return this.apollo.mutate({
      mutation: updateInvestigationMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public restoreInvestigation(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: restoreInvestigationMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public getInvestigationById(id: string): Observable<IApiInvestigation> {
    return this.apollo.query({
      query: getInvestigationById,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public getInvestigationByIdMobile(id: string, isMobile: boolean = false): Observable<IApiInvestigation> {
    return this.apollo.query({
      query: getInvestigationByIdForMobile,
      variables: { id, isMobile }
    }).pipe(
      safeResolve(true)
    );
  }

  public getInvestigationByIdForSidebar(id: string): Observable<IApiInvestigation> {
    return this.apollo.query({
      query: getInvestigationByIdForSidebar,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public getInvestigationByIdForGeoFire(id: string): Observable<IApiInvestigation> {
    return this.apollo.query({
      query: getInvestigationByIdForGeoFire,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }



  public markInvestigationViewed(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: markInvestigationViewed,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public investigationAssignmentSMS(input: IApiInvestigationAssignmentSmsInput): Observable<IApiInvestigationAssignmentSms> {
    return this.apollo.mutate({
      mutation: investigationAssignmentSMSMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateInvestigationStaffScheduledDate(input: any): Observable<boolean> {
    return this.apollo.mutate({
      mutation: updateInvestigationStaffScheduledDate,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getClosestInvestigatorByInvestigationIdQuery(id: string): Observable<IApiUserWithDistance> {
    return this.apollo.query({
      query: getClosestInvestigatorByInvestigationId,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public refreshPendingInvestigation(): Observable<boolean> {
    return this.apollo.subscribe({
      query: refreshPendingInvestigationSubscrption,
    }).pipe(
      safeResolve(true)
    );
  }

  public geoFireReportByState(filters?: IApiInvestigationFilter[]): Observable<IApiGeoFireReportByStateConnection> {
    return this.apollo.query({
      query: getGeoFireReportByStateQuery,
      variables: { filters }
    }).pipe(
      safeResolve(true)
    );
  }

  public refreshInProgressInvestigation(): Observable<boolean> {
    return this.apollo.subscribe({
      query: refreshInProgressInvestigationSubscrption,
    }).pipe(
      safeResolve(true)
    );
  }

}
