export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
  Decimal: any;
  Upload: any;
};


export type IApiAbortMultipartUploadInput = {
  key: Scalars['String'];
  uploadId: Scalars['String'];
};

export type IApiAddAddressInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  addressableId?: Maybe<Scalars['String']>;
  addressableType?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  postal: Scalars['String'];
  state: Scalars['String'];
  TypeId?: Maybe<Scalars['ID']>;
};

export type IApiAddAddressTypeInput = {
  name?: Maybe<Scalars['String']>;
};

export type IApiAddBillingRuleCategoryInput = {
  name: Scalars['String'];
};

export type IApiAddBillingRuleInput = {
  CompanyId?: Maybe<Scalars['ID']>;
  ExpenseItemId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  TimeEntryTypeId?: Maybe<Scalars['ID']>;
  value: Scalars['Float'];
};

export type IApiAddBillingRuleTypeInput = {
  CategoryId: Scalars['ID'];
  name: Scalars['String'];
};

export type IApiAddBranchInput = {
  additionalInformation?: Maybe<Scalars['String']>;
  Addresses?: Maybe<Array<Maybe<IApiAddAddressInput>>>;
  CompanyId?: Maybe<Scalars['ID']>;
  Contacts?: Maybe<Array<Maybe<Scalars['ID']>>>;
  Emails?: Maybe<Array<Maybe<IApiAddEmailInput>>>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  Phones?: Maybe<Array<Maybe<IApiAddPhoneInput>>>;
};

export type IApiAddCertificationAgencyInput = {
  name: Scalars['String'];
};

export type IApiAddCertificationInput = {
  Comments?: Maybe<Array<Maybe<Scalars['ID']>>>;
  DocumentId?: Maybe<Scalars['ID']>;
  expiresAt?: Maybe<Scalars['Date']>;
  issuedAt: Scalars['Date'];
  status?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  TypeId: Scalars['ID'];
  UserId: Scalars['ID'];
};

export type IApiAddCertificationTypeInput = {
  CertificationAgencyId?: Maybe<Scalars['ID']>;
  isRestriction?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  requiresRenewal?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type IApiAddCommentInput = {
  commentableId: Scalars['ID'];
  commentableType: IApiCommentableType;
  text: Scalars['String'];
};

export type IApiAddCompanyInput = {
  additionalInfo?: Maybe<Scalars['String']>;
  AddMainOffice?: Maybe<IApiAddBranchInput>;
  billingInstructions?: Maybe<Scalars['String']>;
  BillingRules?: Maybe<Array<Maybe<IApiAddBillingRuleInput>>>;
  BranchIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ContactIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  description?: Maybe<Scalars['String']>;
  enterPropertyEnabled?: Maybe<Scalars['Boolean']>;
  ExcludedStaffIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  FlatRates?: Maybe<Array<Maybe<IApiAddFlatRateInput>>>;
  name: Scalars['String'];
  qbListId?: Maybe<Scalars['String']>;
  recordedInterviewNotification?: Maybe<Scalars['Boolean']>;
  RequiredCertificationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  RequiredServiceIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  TypeId: Scalars['ID'];
};

export type IApiAddCompanyTypeInput = {
  name: Scalars['String'];
};

export type IApiAddContactBranchAssignmentInput = {
  BranchId: Scalars['ID'];
  ContactId: Scalars['ID'];
  endDate?: Maybe<Scalars['Date']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type IApiAddContactInput = {
  additionalInformation?: Maybe<Scalars['String']>;
  Addresses?: Maybe<Array<Maybe<IApiAddAddressInput>>>;
  AddressIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  BranchAssignment?: Maybe<IApiAddNewContactBranchAssignmentInput>;
  BranchIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  claimNumber?: Maybe<Scalars['String']>;
  companyNotRequired?: Maybe<Scalars['Boolean']>;
  EmailIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  Emails?: Maybe<Array<Maybe<IApiAddEmailInput>>>;
  firstName: Scalars['String'];
  independentAdjuster?: Maybe<Scalars['Boolean']>;
  insuredName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isInternal?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  PhoneIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  Phones?: Maybe<Array<Maybe<IApiAddPhoneInput>>>;
  policyNumber?: Maybe<Scalars['String']>;
  qbListId?: Maybe<Scalars['String']>;
  RoleId?: Maybe<Scalars['ID']>;
  suffix?: Maybe<Scalars['String']>;
  thirdPartyAdministrator?: Maybe<Scalars['Boolean']>;
};

export type IApiAddContactRoleInput = {
  isPrivate?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type IApiAddDetail = {
  city?: Maybe<Scalars['String']>;
  claimNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  fireMarshalName?: Maybe<Scalars['String']>;
  fireMarshalPhoneNumber?: Maybe<Scalars['String']>;
  isSameLossLocation?: Maybe<Scalars['Boolean']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  publicAdjusterName?: Maybe<Scalars['String']>;
  publicAdjusterPhoneNumber?: Maybe<Scalars['String']>;
  riskDescription?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  vehicleAddress?: Maybe<Scalars['String']>;
  vinNumber?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type IApiAddDocumentApprovalInput = {
  comment: Scalars['String'];
  DocumentSnapshotId: Scalars['ID'];
  isApproved: Scalars['Boolean'];
};

export type IApiAddDocumentCategoryInput = {
  name: Scalars['String'];
};

export type IApiAddDocumentCollectionInput = {
  collectionStatus?: Maybe<Scalars['String']>;
  DocumentIds: Array<Maybe<Scalars['ID']>>;
  InvestigationId: Scalars['ID'];
};

export type IApiAddDocumentInput = {
  caption?: Maybe<Scalars['String']>;
  InvestigationId?: Maybe<Scalars['ID']>;
  s3Uri?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  TypeId: Scalars['ID'];
  uri?: Maybe<Scalars['String']>;
};

export type IApiAddDocumentSentInput = {
  BillToId?: Maybe<Scalars['ID']>;
  ClientId?: Maybe<Scalars['ID']>;
  ContactIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  DocumentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  DocumentTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  InsuredId?: Maybe<Scalars['ID']>;
  InvestigationId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  sendDate?: Maybe<Scalars['Date']>;
  specialInstructions?: Maybe<Scalars['String']>;
};

export type IApiAddDocumentShareInput = {
  BillToId?: Maybe<Scalars['ID']>;
  ClientId?: Maybe<Scalars['ID']>;
  DocumentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  Folders?: Maybe<Array<Maybe<IApiPhotoFolderDownloadInput>>>;
  InsuredId?: Maybe<Scalars['ID']>;
  InvestigationId: Scalars['ID'];
  PhotoIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type IApiAddDocumentSnapshotInput = {
  comment?: Maybe<Scalars['String']>;
  CreatedById: Scalars['ID'];
  DocumentId: Scalars['ID'];
  s3Uri?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type IApiAddDocumentTemplateInput = {
  oneDriveItemId: Scalars['ID'];
};

export type IApiAddDocumentTypeInput = {
  CategoryId?: Maybe<Scalars['ID']>;
  defaultTitle?: Maybe<Scalars['String']>;
  DocumentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  isCourtDocument: Scalars['Boolean'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  SubServiceTypeId?: Maybe<Scalars['ID']>;
  templateId?: Maybe<Scalars['String']>;
  TemplateId?: Maybe<Scalars['ID']>;
  templateName?: Maybe<Scalars['String']>;
};

export type IApiAddEmailInput = {
  address: Scalars['String'];
  emailableId?: Maybe<Scalars['ID']>;
  emailableType?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  TypeId: Scalars['ID'];
};

export type IApiAddEmailTypeInput = {
  name?: Maybe<Scalars['String']>;
};

export type IApiAddEvidenceBillingFrequencyInput = {
  name: Scalars['String'];
};

export type IApiAddEvidenceBillingInput = {
  billable: Scalars['Boolean'];
  BillingFrequencyId?: Maybe<Scalars['ID']>;
  currentExpensed?: Maybe<Scalars['Date']>;
  InvestigationId: Scalars['ID'];
  lastExpensed?: Maybe<Scalars['Date']>;
  notes?: Maybe<Scalars['String']>;
  override: Scalars['Boolean'];
  palletCount: Scalars['String'];
  rate: Scalars['Float'];
  startDate?: Maybe<Scalars['Date']>;
};

export type IApiAddEvidenceInput = {
  Comments?: Maybe<Array<Maybe<IApiAddCommentInput>>>;
  description: Scalars['String'];
  EvidenceStorageLocationId?: Maybe<Scalars['ID']>;
  InvestigationId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  Status: IApiEvidenceStatusType;
  SubServiceId?: Maybe<Scalars['ID']>;
  takenFrom: Scalars['String'];
};

export type IApiAddEvidenceStatusInput = {
  comment: Scalars['String'];
  EvidenceId: Scalars['ID'];
  name: IApiEvidenceStatusType;
};

export type IApiAddEvidenceStorageLocationInput = {
  additionalDetails: Scalars['String'];
  Address?: Maybe<IApiAddAddressInput>;
  companyName?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  hasDetails?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isNefco?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  Phone?: Maybe<IApiAddPhoneInput>;
  type: Scalars['String'];
};

export type IApiAddEvidenceTransferInput = {
  comment?: Maybe<Scalars['String']>;
  DocumentId?: Maybe<Scalars['ID']>;
  EvidenceIds: Array<Maybe<Scalars['ID']>>;
  FromLocationId: Scalars['ID'];
  receivedComment?: Maybe<Scalars['String']>;
  receivedDate?: Maybe<Scalars['Date']>;
  ShippingCarrierId: Scalars['ID'];
  ToLocationId: Scalars['ID'];
  trackingNumber?: Maybe<Scalars['String']>;
};

export type IApiAddExpenseCategoryInput = {
  details?: Maybe<Scalars['String']>;
  ExpensePurposeIds: Array<Maybe<Scalars['ID']>>;
  name: Scalars['String'];
};

export type IApiAddExpenseInput = {
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedDate?: Maybe<Scalars['Date']>;
  billableQuantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  DocumentId?: Maybe<Scalars['ID']>;
  expenseDate?: Maybe<Scalars['Date']>;
  ExpenseItemId: Scalars['ID'];
  ExpensePurposeId?: Maybe<Scalars['ID']>;
  InvestigationId?: Maybe<Scalars['ID']>;
  nefcoVehicle?: Maybe<Scalars['Int']>;
  nonBillableQuantity?: Maybe<Scalars['Float']>;
  outOfPocket?: Maybe<Scalars['Int']>;
  paidDate?: Maybe<Scalars['Date']>;
  receipts?: Maybe<Array<Maybe<Scalars['ID']>>>;
  status?: Maybe<Scalars['Int']>;
  UserId?: Maybe<Scalars['ID']>;
};

export type IApiAddExpenseItemInput = {
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  descriptionRequired?: Maybe<Scalars['Boolean']>;
  ExpenseApplicationId: Scalars['String'];
  ExpenseCategoryId: Scalars['String'];
  ExpenseTypeId: Scalars['String'];
  name: Scalars['String'];
  value?: Maybe<Scalars['Decimal']>;
};

export type IApiAddExpensePurposeInput = {
  active?: Maybe<Scalars['Boolean']>;
  details?: Maybe<Scalars['String']>;
  ExpenseCategoryIds: Array<Maybe<Scalars['ID']>>;
  name: Scalars['String'];
};

export type IApiAddFlatRateInput = {
  RiskTypeId: Scalars['ID'];
  value: Scalars['Float'];
};

export type IApiAddInsuredContactedInput = {
  date: Scalars['Date'];
  InvestigationId: Scalars['ID'];
  UserId: Scalars['ID'];
};

export type IApiAddInsuredDetails = {
  details?: Maybe<IApiAddInvestigationPartyInput>;
  insuredAddressPhone?: Maybe<IApiAddPhoneInput>;
};

export type IApiAddInsuredPartyInput = {
  Addresses?: Maybe<Array<Maybe<IApiAddAddressInput>>>;
  contactName?: Maybe<Scalars['String']>;
  Emails?: Maybe<Array<Maybe<IApiAddEmailInput>>>;
  insuredName?: Maybe<Scalars['String']>;
  Phones?: Maybe<Array<Maybe<IApiAddPhoneInput>>>;
  suffix?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type IApiAddIntakeInput = {
  ClientInvestigationNote?: Maybe<IApiAddNewClientInvestigationNote>;
  company?: Maybe<IApiCustomCompany>;
  companyTypeId?: Maybe<Scalars['ID']>;
  Contact?: Maybe<IApiAddContactInput>;
  Detail?: Maybe<IApiAddDetail>;
  Insured?: Maybe<IApiAddInsuredDetails>;
  Investigation?: Maybe<Array<Maybe<IApiAddInvestigationInput>>>;
  password?: Maybe<Scalars['String']>;
};

export type IApiAddInvestigationDetailInput = {
  billingInstructionNotes?: Maybe<Scalars['String']>;
  budgetAmount?: Maybe<Scalars['Decimal']>;
  claimNumber?: Maybe<Scalars['String']>;
  InvestigationId: Scalars['ID'];
  linkedInvestigationNotes?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  otherContactName?: Maybe<Scalars['String']>;
  otherContactPhone?: Maybe<Scalars['String']>;
  partyNotes?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  riskDescription?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
  staffNotes?: Maybe<Scalars['String']>;
  vehicleNotes?: Maybe<Scalars['String']>;
};

export type IApiAddInvestigationHistoryInput = {
  comment: Scalars['String'];
  InvestigationId: Scalars['ID'];
  requiresFollowup?: Maybe<Scalars['Boolean']>;
  updateCategory: IApiInvestigationUpdateCategories;
  updateType: IApiInvestigationUpdateTypes;
};

export type IApiAddInvestigationInput = {
  billingInstructions?: Maybe<Scalars['String']>;
  BillToBranchId?: Maybe<Scalars['ID']>;
  BillToId?: Maybe<Scalars['ID']>;
  ClientBranchId?: Maybe<Scalars['ID']>;
  ClientId?: Maybe<Scalars['ID']>;
  CompanyId?: Maybe<Scalars['ID']>;
  conflictCheckRun?: Maybe<Scalars['Boolean']>;
  Detail?: Maybe<IApiAddInvestigationDetailInput>;
  DocumentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  docusketchRequested?: Maybe<Scalars['Boolean']>;
  EvidenceBillingIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  EvidenceIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  InsuredId?: Maybe<Scalars['ID']>;
  InvestigationAddress?: Maybe<IApiAddAddressInput>;
  isIncendiary?: Maybe<Scalars['Boolean']>;
  isOngoing?: Maybe<Scalars['Boolean']>;
  isRetainerReceived?: Maybe<Scalars['Boolean']>;
  isSymbility?: Maybe<Scalars['Boolean']>;
  isXactimate?: Maybe<Scalars['Boolean']>;
  LinkedInvestigationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  LossAddress?: Maybe<IApiAddAddressInput>;
  lossDate: Scalars['Date'];
  markAsClosed?: Maybe<Scalars['Boolean']>;
  nefcoNumber?: Maybe<Scalars['String']>;
  onHold?: Maybe<Scalars['Boolean']>;
  PartyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  PriorityId?: Maybe<Scalars['ID']>;
  receivedDate?: Maybe<Scalars['Date']>;
  RequestIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  riskDescription?: Maybe<Scalars['String']>;
  RiskTypeId?: Maybe<Scalars['ID']>;
  SceneContactId?: Maybe<Scalars['ID']>;
  ServiceIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  StaffIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  timezone?: Maybe<Scalars['String']>;
  VehicleIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type IApiAddInvestigationMarkCloseInput = {
  InvestigationId: Scalars['ID'];
  markClosed?: Maybe<Scalars['Boolean']>;
};

export type IApiAddInvestigationOnSceneInput = {
  CreatedById?: Maybe<Scalars['ID']>;
  InvestigationId: Scalars['ID'];
  source?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  UserId: Scalars['ID'];
};

export type IApiAddInvestigationPartyInput = {
  Contact?: Maybe<IApiAddContactInput>;
  ContactId?: Maybe<Scalars['ID']>;
  InvestigationId: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  newContact?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  RoleId: Scalars['ID'];
};

export type IApiAddInvestigationPartyV2Input = {
  ContactId: Scalars['ID'];
  newContact?: Maybe<Scalars['Boolean']>;
  RoleId: Scalars['ID'];
};

export type IApiAddInvestigationPriorityInput = {
  InvestigationId: Scalars['ID'];
  name: Scalars['String'];
};

export type IApiAddInvestigationRestrictionInput = {
  InvestigationId: Scalars['ID'];
  UserId: Scalars['ID'];
};

export type IApiAddInvestigationRoleInput = {
  PermissionRoleId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type IApiAddInvestigationStaffInput = {
  clientRequested?: Maybe<Scalars['Boolean']>;
  InvestigationId: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  RoleId: Scalars['ID'];
  scheduledSceneExamDate?: Maybe<Scalars['Date']>;
  TechReviewerId?: Maybe<Scalars['ID']>;
  UserId: Scalars['ID'];
};

export type IApiAddInvestigationStatusInput = {
  comment: Scalars['String'];
  InvestigationId: Scalars['ID'];
  name: Scalars['String'];
  requiresFollowUp?: Maybe<Scalars['Boolean']>;
};

export type IApiAddInvestigationV2Input = {
  billingInstructions?: Maybe<Scalars['String']>;
  BillToBranchId?: Maybe<Scalars['ID']>;
  BillToId?: Maybe<Scalars['ID']>;
  ClientBranchId?: Maybe<Scalars['ID']>;
  ClientId?: Maybe<Scalars['ID']>;
  CompanyId?: Maybe<Scalars['ID']>;
  conflictCheckRun?: Maybe<Scalars['Boolean']>;
  Detail?: Maybe<IApiAddInvestigationDetailInput>;
  DocumentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  docusketchRequested?: Maybe<Scalars['Boolean']>;
  EvidenceBillingIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  EvidenceIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  InsuredId?: Maybe<Scalars['ID']>;
  InvestigationAddress?: Maybe<IApiAddAddressInput>;
  isIncendiary?: Maybe<Scalars['Boolean']>;
  isOngoing?: Maybe<Scalars['Boolean']>;
  isRetainerReceived?: Maybe<Scalars['Boolean']>;
  isSymbility?: Maybe<Scalars['Boolean']>;
  isXactimate?: Maybe<Scalars['Boolean']>;
  LinkedInvestigationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  LossAddress?: Maybe<IApiAddAddressInput>;
  lossDate: Scalars['Date'];
  markAsClosed?: Maybe<Scalars['Boolean']>;
  nefcoNumber?: Maybe<Scalars['String']>;
  newBillTo?: Maybe<Scalars['Boolean']>;
  newClient?: Maybe<Scalars['Boolean']>;
  onHold?: Maybe<Scalars['Boolean']>;
  Parties?: Maybe<Array<Maybe<IApiAddInvestigationPartyV2Input>>>;
  PartyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  PriorityId?: Maybe<Scalars['ID']>;
  receivedDate?: Maybe<Scalars['Date']>;
  RequestIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  riskDescription?: Maybe<Scalars['String']>;
  RiskTypeId?: Maybe<Scalars['ID']>;
  SceneContactId?: Maybe<Scalars['ID']>;
  ServiceIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  StaffIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  timezone?: Maybe<Scalars['String']>;
  VehicleIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  Vehicles?: Maybe<Array<Maybe<IApiAddVehicleInput>>>;
};

export type IApiAddInvoiceInput = {
  BillToBranchId?: Maybe<Scalars['ID']>;
  BillToId?: Maybe<Scalars['ID']>;
  details?: Maybe<Scalars['String']>;
  InvestigationId?: Maybe<Scalars['ID']>;
  InvoiceItems?: Maybe<Array<Maybe<IApiInvoiceItemInput>>>;
  InvoiceLines?: Maybe<Array<Maybe<IApiInvoiceLineInput>>>;
  invoiceNumber?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  qbStatus?: Maybe<Scalars['String']>;
};

export type IApiAddInvoiceQbErrorInput = {
  statusCode?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  statusSeverity?: Maybe<Scalars['String']>;
};

export type IApiAddMultipleInvestigationRestrictionInput = {
  restrictions?: Maybe<Array<Maybe<IApiAddInvestigationRestrictionInput>>>;
};

export type IApiAddNefcoOfficeInput = {
  Address?: Maybe<IApiAddAddressInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type IApiAddNewClientInvestigationNote = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactIsAdjuster?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Scalars['String']>;
  insuranceCarrierName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type IApiAddNewContactBranchAssignmentInput = {
  BranchId: Scalars['ID'];
  endDate?: Maybe<Scalars['Date']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type IApiAddNotificationMethodSettingInput = {
  isDisabled?: Maybe<Scalars['Boolean']>;
  method: IApiNotificationMethodType;
  NotificationTypeId: Scalars['ID'];
  PermissionRoleId?: Maybe<Scalars['ID']>;
  UserId?: Maybe<Scalars['ID']>;
};

export type IApiAddOrUpdateAddressInput = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  postal?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  TypeId?: Maybe<Scalars['ID']>;
};

export type IApiAddPermissionRoleInput = {
  name: Scalars['String'];
  TimeEntryTypeId?: Maybe<Scalars['ID']>;
};

export type IApiAddPhoneInput = {
  extension?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  phoneableId?: Maybe<Scalars['ID']>;
  phoneableType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  TypeId: Scalars['ID'];
};

export type IApiAddPhoneTypeInput = {
  name?: Maybe<Scalars['String']>;
};

export type IApiAddPhotoFolderInput = {
  InvestigationId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  SubServiceId?: Maybe<Scalars['ID']>;
};

export type IApiAddPhotoInput = {
  caption?: Maybe<Scalars['String']>;
  createExpense?: Maybe<Scalars['Boolean']>;
  fileName: Scalars['String'];
  fileSizeKB?: Maybe<Scalars['Int']>;
  FolderId: Scalars['ID'];
  height: Scalars['Int'];
  isEvidence?: Maybe<Scalars['Boolean']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  lowResS3Uri?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  s3Uri?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export type IApiAddPhotoThumbnailInput = {
  fileSizeKB: Scalars['Int'];
  Photo: Scalars['ID'];
  scale: Scalars['Decimal'];
  uri?: Maybe<Scalars['String']>;
};

export type IApiAddRequestInput = {
  Address?: Maybe<IApiAddAddressInput>;
  authorizedBy?: Maybe<Scalars['String']>;
  ContactId?: Maybe<Scalars['ID']>;
  InvestigationId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  RequestTypeId: Scalars['ID'];
};

export type IApiAddRequestStatusInput = {
  comment?: Maybe<Scalars['String']>;
  CreatedById?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  RequestId: Scalars['ID'];
};

export type IApiAddRequestTypeInput = {
  name?: Maybe<Scalars['String']>;
};

export type IApiAddress = IApiNode & {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  addressableType?: Maybe<Scalars['String']>;
  addressableTypeId?: Maybe<Scalars['ID']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isPrimary?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  postal?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  Type?: Maybe<IApiAddressType>;
  TypeId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiAddressConnection = {
  __typename?: 'AddressConnection';
  edges?: Maybe<Array<Maybe<IApiAddressEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiAddressEdge = {
  __typename?: 'AddressEdge';
  cursor: Scalars['String'];
  node: IApiAddress;
};

export type IApiAddressFilter = {
  type?: Maybe<IApiAddressFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiAddressFilterType {
  AddressableId = 'ADDRESSABLE_ID',
  AddressableType = 'ADDRESSABLE_TYPE'
}

export enum IApiAddressOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiAddressType = IApiNode & {
  __typename?: 'AddressType';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiAddressTypeConnection = {
  __typename?: 'AddressTypeConnection';
  edges?: Maybe<Array<Maybe<IApiAddressTypeEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiAddressTypeEdge = {
  __typename?: 'AddressTypeEdge';
  cursor: Scalars['String'];
  node: IApiAddressType;
};

export type IApiAddressTypeFilter = {
  type?: Maybe<IApiAddressTypeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiAddressTypeFilterType {
  FromLocation = 'FROM_LOCATION'
}

export enum IApiAddressTypeOrderBy {
  Name = 'NAME'
}

export type IApiAddS3Input = {
  data?: Maybe<Array<Maybe<IApiS3FileInput>>>;
};

export type IApiAddServiceInput = {
  AssignedUserIds: Array<Maybe<Scalars['ID']>>;
  AssignedUsers?: Maybe<Array<Maybe<IApiUpdateUserInput>>>;
  authorizedBy?: Maybe<Scalars['String']>;
  InvestigationId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  TypeId: Scalars['ID'];
};

export type IApiAddServiceStatusInput = {
  commment?: Maybe<Scalars['String']>;
  CreatedById: Scalars['ID'];
  name: Scalars['String'];
  ServiceId: Scalars['ID'];
};

export type IApiAddServiceTypeInput = {
  clientApprovalRequired?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nefcoApprovalRequired?: Maybe<Scalars['Boolean']>;
  RequiredDocumentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  SubServiceTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type IApiAddSettingsInput = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type IApiAddShippingCarrierInput = {
  name: Scalars['String'];
};

export type IApiAddSubServiceInput = {
  AssignedUserId?: Maybe<Scalars['ID']>;
  InvestigationId: Scalars['ID'];
  isComplete?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  ServiceId?: Maybe<Scalars['ID']>;
  TypeId: Scalars['ID'];
};

export type IApiAddSubServiceTypeInput = {
  canToggleVisibility: Scalars['Boolean'];
  name: Scalars['String'];
  RequiredDocuments: Array<Maybe<Scalars['ID']>>;
  ServiceType: Scalars['ID'];
};

export type IApiAddTimeEntryInput = {
  description?: Maybe<Scalars['String']>;
  hours: Scalars['Float'];
  InvestigationId?: Maybe<Scalars['ID']>;
  nonBillableHours?: Maybe<Scalars['Float']>;
  paidDate?: Maybe<Scalars['Date']>;
  TypeId: Scalars['ID'];
  UserId?: Maybe<Scalars['ID']>;
  workday: Scalars['Date'];
};

export type IApiAddTimeEntryTypeInput = {
  activated?: Maybe<Scalars['Boolean']>;
  additionalDetails?: Maybe<Scalars['String']>;
  appliesTo: Scalars['String'];
  name: Scalars['String'];
  nonStandard?: Maybe<Scalars['Boolean']>;
  qbAccount?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

export type IApiAddUserDetailInput = {
  additionalInformation?: Maybe<Scalars['String']>;
  driversLicenseNumber?: Maybe<Scalars['String']>;
  driversLicenseState?: Maybe<Scalars['String']>;
  emergencyContactInfo?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  fullTime?: Maybe<Scalars['Boolean']>;
  hasNefcoCreditCard?: Maybe<Scalars['Boolean']>;
  hasNefcoVehicle?: Maybe<Scalars['Boolean']>;
  payRateHourly?: Maybe<Scalars['Float']>;
  requiredHoursQuarterly?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  UserId?: Maybe<Scalars['ID']>;
  vehicleInsuranceCompany?: Maybe<Scalars['String']>;
  vehicleRegistrationState?: Maybe<Scalars['String']>;
};

export type IApiAddUserInput = {
  AddressIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  CertificationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  email: Scalars['String'];
  EmailIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  firstName: Scalars['String'];
  InvestigationRoles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  msGraphId?: Maybe<Scalars['ID']>;
  nickname?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  permissionId?: Maybe<Scalars['String']>;
  PermissionIds: Array<Maybe<Scalars['ID']>>;
  PhoneIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  Positions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  RegionalManagerId?: Maybe<Scalars['ID']>;
  ServiceIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  suffix?: Maybe<Scalars['String']>;
  TechReviewerId?: Maybe<Scalars['ID']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twilioPhoneNumber?: Maybe<Scalars['String']>;
};

export type IApiAddVehicleInput = {
  insuranceCompany?: Maybe<Scalars['String']>;
  InvestigationId?: Maybe<Scalars['ID']>;
  make: Scalars['String'];
  model: Scalars['String'];
  policyNumber?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  UserId?: Maybe<Scalars['ID']>;
  vehicleType?: Maybe<Scalars['String']>;
  vinNumber?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type IApiAdvanceInvestigationReportInput = {
  comment?: Maybe<Scalars['String']>;
  documentId: Scalars['ID'];
  reject?: Maybe<Scalars['Boolean']>;
  sendToFinal?: Maybe<Scalars['Boolean']>;
};

export type IApiApproveTimeEntryInput = {
  id: Scalars['ID'];
};

export type IApiAssignInvestigatorInput = {
  InvestigationId: Scalars['ID'];
  scheduledSceneExamDate?: Maybe<Scalars['Date']>;
  TechReviewerId?: Maybe<Scalars['ID']>;
  UserId: Scalars['ID'];
};

export type IApiAssignPermissionRolePermissionInput = {
  permissionActionId: Scalars['ID'];
  permissionCategoryId: Scalars['ID'];
  permissionRoleId: Scalars['ID'];
  permissionScopeId: Scalars['ID'];
};

export type IApiAssignPermissionRoleUserInput = {
  permissionRoleId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type IApiAuthorizationResponse = {
  __typename?: 'AuthorizationResponse';
  EffectivePermissions?: Maybe<Array<Maybe<IApiEffectivePermissionCategory>>>;
  User: IApiUser;
};

export type IApiBaseSubscriptionField = {
  __typename?: 'BaseSubscriptionField';
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type IApiBillingRule = IApiNode & {
  __typename?: 'BillingRule';
  Companies?: Maybe<Array<Maybe<IApiCompany>>>;
  createdAt?: Maybe<Scalars['Date']>;
  ExpenseItem?: Maybe<IApiExpenseItem>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  TimeEntryType?: Maybe<IApiTimeEntryType>;
  updatedAt?: Maybe<Scalars['Date']>;
  value: Scalars['Float'];
};

export type IApiBillingRuleCategory = IApiNode & {
  __typename?: 'BillingRuleCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  Types?: Maybe<Array<Maybe<IApiBillingRuleType>>>;
};

export type IApiBillingRuleCategoryConnection = {
  __typename?: 'billingRuleCategoryConnection';
  edges?: Maybe<Array<Maybe<IApiBillingRuleCategoryEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiBillingRuleCategoryEdge = {
  __typename?: 'BillingRuleCategoryEdge';
  cursor: Scalars['String'];
  node: IApiBillingRuleCategory;
};

export type IApiBillingRuleCategoryFilter = {
  type?: Maybe<IApiBillingRuleCategoryFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiBillingRuleCategoryFilterType {
  CreatedAt = 'CREATED_AT'
}

export enum IApiBillingRuleCategoryOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type IApiBillingRuleConnection = {
  __typename?: 'billingRuleConnection';
  edges?: Maybe<Array<Maybe<IApiBillingRuleEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiBillingRuleEdge = {
  __typename?: 'BillingRuleEdge';
  cursor: Scalars['String'];
  node: IApiBillingRule;
};

export type IApiBillingRuleFilter = {
  type?: Maybe<IApiBillingRuleFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiBillingRuleFilterType {
  CreatedAt = 'CREATED_AT'
}

export enum IApiBillingRuleOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiBillingRuleType = IApiNode & {
  __typename?: 'BillingRuleType';
  BillingRules?: Maybe<Array<Maybe<IApiBillingRule>>>;
  Category?: Maybe<IApiBillingRuleCategory>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiBillingRuleTypeConnection = {
  __typename?: 'billingRuleTypeConnection';
  edges?: Maybe<Array<Maybe<IApiBillingRuleTypeEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiBillingRuleTypeEdge = {
  __typename?: 'BillingRuleTypeEdge';
  cursor: Scalars['String'];
  node: IApiBillingRuleType;
};

export type IApiBillingRuleTypeFilter = {
  type?: Maybe<IApiBillingRuleTypeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiBillingRuleTypeFilterType {
  CreatedAt = 'CREATED_AT'
}

export enum IApiBillingRuleTypeOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type IApiBranch = IApiNode & {
  __typename?: 'Branch';
  additionalInformation?: Maybe<Scalars['String']>;
  Addresses?: Maybe<Array<Maybe<IApiAddress>>>;
  BillingAddress?: Maybe<IApiAddress>;
  Company?: Maybe<IApiCompany>;
  ContactAssignments?: Maybe<Array<Maybe<IApiContactBranchAssignment>>>;
  Contacts?: Maybe<Array<Maybe<IApiContact>>>;
  createdAt?: Maybe<Scalars['Date']>;
  Emails?: Maybe<Array<Maybe<IApiEmail>>>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isPrimary?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  Phones?: Maybe<Array<Maybe<IApiPhone>>>;
  startDate?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiBranchConnection = {
  __typename?: 'BranchConnection';
  edges?: Maybe<Array<Maybe<IApiBranchEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiBranchEdge = {
  __typename?: 'BranchEdge';
  cursor: Scalars['String'];
  node: IApiBranch;
};

export type IApiBranchFilter = {
  type?: Maybe<IApiBranchFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiBranchFilterType {
  Company = 'COMPANY'
}

export enum IApiBranchOrderBy {
  Name = 'NAME'
}

export type IApiCertification = IApiNode & {
  __typename?: 'Certification';
  CertificationTypeId?: Maybe<Scalars['ID']>;
  Comments?: Maybe<Array<Maybe<IApiComment>>>;
  Document?: Maybe<IApiDocument>;
  expiresAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  issuedAt?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  Type?: Maybe<IApiCertificationType>;
  User?: Maybe<IApiUser>;
};

export type IApiCertificationAgency = IApiNode & {
  __typename?: 'CertificationAgency';
  CertificationTypes?: Maybe<Array<Maybe<IApiCertificationType>>>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiCertificationAgencyConnection = {
  __typename?: 'CertificationAgencyConnection';
  edges?: Maybe<Array<Maybe<IApiCertificationAgencyEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiCertificationAgencyEdge = {
  __typename?: 'CertificationAgencyEdge';
  cursor: Scalars['String'];
  node: IApiCertificationAgency;
};

export type IApiCertificationAgencyFilter = {
  type?: Maybe<IApiCertificationAgencyFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiCertificationAgencyFilterType {
  Name = 'NAME'
}

export enum IApiCertificationAgencyOrderBy {
  Name = 'NAME',
  State = 'STATE'
}

export type IApiCertificationConnection = {
  __typename?: 'certificationConnection';
  edges?: Maybe<Array<Maybe<IApiCertificationEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiCertificationEdge = {
  __typename?: 'CertificationEdge';
  cursor: Scalars['String'];
  node: IApiCertification;
};

export type IApiCertificationFilter = {
  type?: Maybe<IApiCertificationFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiCertificationFilterType {
  ExpiresAt = 'EXPIRES_AT',
  Id = 'ID',
  IssuedAt = 'ISSUED_AT',
  Restriction = 'RESTRICTION',
  Search = 'SEARCH',
  State = 'STATE',
  Status = 'STATUS',
  Title = 'TITLE',
  Type = 'TYPE',
  User = 'USER'
}

export enum IApiCertificationOrderBy {
  ExpiresAt = 'EXPIRES_AT',
  IssuedAt = 'ISSUED_AT',
  Status = 'STATUS',
  Title = 'TITLE',
  Type = 'TYPE',
  User = 'USER'
}

export enum IApiCertificationStatusType {
  AwaitingRenewal = 'AWAITING_RENEWAL',
  Current = 'CURRENT',
  Expired = 'EXPIRED',
  RenewalStarted = 'RENEWAL_STARTED',
  RenewalSubmitted = 'RENEWAL_SUBMITTED'
}

export type IApiCertificationType = IApiNode & {
  __typename?: 'CertificationType';
  CertificationAgency?: Maybe<IApiCertificationAgency>;
  Certifications?: Maybe<Array<Maybe<IApiCertification>>>;
  CompaniesRequiring?: Maybe<Array<Maybe<IApiCompany>>>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isRestriction?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  requiresRenewal?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiCertificationTypeConnection = {
  __typename?: 'CertificationTypeConnection';
  edges?: Maybe<Array<Maybe<IApiCertificationTypeEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiCertificationTypeEdge = {
  __typename?: 'CertificationTypeEdge';
  cursor: Scalars['String'];
  node: IApiCertificationType;
};

export type IApiCertificationTypeFilter = {
  type?: Maybe<IApiCertificationTypeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiCertificationTypeFilterType {
  Agency = 'AGENCY',
  Restriction = 'RESTRICTION',
  State = 'STATE',
  Title = 'TITLE'
}

export enum IApiCertificationTypeOrderBy {
  Agency = 'AGENCY',
  State = 'STATE',
  Title = 'TITLE'
}

export type IApiChangeOnSceneStatusInput = {
  data?: Maybe<Array<Maybe<IApiChangeOnSceneStatusInputType>>>;
  status?: Maybe<Scalars['String']>;
};

export type IApiChangeOnSceneStatusInputType = {
  eventId?: Maybe<Scalars['String']>;
  InvestigationId: Scalars['ID'];
  scheduledDate: Scalars['Date'];
  UserId: Scalars['ID'];
};

export type IApiClientDetail = {
  __typename?: 'ClientDetail';
  app_metadata?: Maybe<Scalars['JSON']>;
  blocked?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  family_name?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  identities?: Maybe<Array<IApiIdentity>>;
  last_ip?: Maybe<Scalars['String']>;
  last_login?: Maybe<Scalars['String']>;
  logins_count?: Maybe<Scalars['Int']>;
  multifactor?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  phone_verified?: Maybe<Scalars['Boolean']>;
  picture?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  user_metadata?: Maybe<Scalars['JSON']>;
  username?: Maybe<Scalars['String']>;
};

export type IApiClientInvestigationNote = {
  __typename?: 'ClientInvestigationNote';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  ContactId?: Maybe<Scalars['ID']>;
  contactIsAdjuster?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  extension?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insuranceCarrierName?: Maybe<Scalars['String']>;
  InvestigationId?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  zip?: Maybe<Scalars['String']>;
};

export type IApiClientUserDetail = {
  __typename?: 'ClientUserDetail';
  clientDetail?: Maybe<IApiClientDetail>;
  contact?: Maybe<Scalars['Boolean']>;
};

export type IApiComment = IApiNode & {
  __typename?: 'Comment';
  commentableId: Scalars['ID'];
  commentableType?: Maybe<IApiCommentableType>;
  createdAt?: Maybe<Scalars['Date']>;
  CreatedBy?: Maybe<IApiUser>;
  id: Scalars['ID'];
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  UpdatedBy?: Maybe<IApiUser>;
};

export enum IApiCommentableType {
  Certification = 'CERTIFICATION',
  Document = 'DOCUMENT',
  Evidence = 'EVIDENCE',
  Investigation = 'INVESTIGATION'
}

export type IApiCommentConnection = {
  __typename?: 'CommentConnection';
  edges?: Maybe<Array<Maybe<IApiCommentEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiCommentEdge = {
  __typename?: 'CommentEdge';
  cursor: Scalars['String'];
  node: IApiComment;
};

export type IApiCommentFilter = {
  type?: Maybe<IApiCommentFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiCommentFilterType {
  CommentableId = 'COMMENTABLE_ID'
}

export enum IApiCommentOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiCompaniesConnection = {
  __typename?: 'CompaniesConnection';
  edges?: Maybe<Array<Maybe<IApiCompanyEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiCompany = IApiNode & {
  __typename?: 'Company';
  additionalInfo?: Maybe<Scalars['String']>;
  billingInstructions?: Maybe<Scalars['String']>;
  BillingRules?: Maybe<Array<Maybe<IApiBillingRule>>>;
  Branches?: Maybe<Array<Maybe<IApiBranch>>>;
  Contacts?: Maybe<Array<Maybe<IApiContact>>>;
  description?: Maybe<Scalars['String']>;
  enterPropertyEnabled?: Maybe<Scalars['Boolean']>;
  ExcludedStaff?: Maybe<Array<Maybe<IApiUser>>>;
  FlatRates?: Maybe<Array<Maybe<IApiFlatRate>>>;
  id: Scalars['ID'];
  Investigations?: Maybe<Array<Maybe<IApiInvestigation>>>;
  MainOffice?: Maybe<IApiBranch>;
  name: Scalars['String'];
  qbListId?: Maybe<Scalars['String']>;
  recordedInterviewNotification?: Maybe<Scalars['Boolean']>;
  RequiredCertifications?: Maybe<Array<Maybe<IApiCertificationType>>>;
  RequiredServices?: Maybe<Array<Maybe<IApiServiceType>>>;
  Type?: Maybe<IApiCompanyType>;
};

export type IApiCompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor: Scalars['String'];
  node: IApiCompany;
};

export type IApiCompanyFilter = {
  type?: Maybe<IApiCompanyFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiCompanyFilterType {
  CompanyType = 'COMPANY_TYPE',
  Ids = 'IDS',
  Name = 'NAME',
  Search = 'SEARCH',
  State = 'STATE',
  ViewListView = 'VIEW_LIST_VIEW',
  ViewOnlyName = 'VIEW_ONLY_NAME'
}

export enum IApiCompanyOrderBy {
  Name = 'NAME'
}

export type IApiCompanyType = IApiNode & {
  __typename?: 'CompanyType';
  Companies?: Maybe<Array<Maybe<IApiCompany>>>;
  id: Scalars['ID'];
  isIntake?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type IApiCompanyTypeConnection = {
  __typename?: 'companyTypeConnection';
  edges?: Maybe<Array<Maybe<IApiCompanyTypeEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiCompanyTypeEdge = {
  __typename?: 'CompanyTypeEdge';
  cursor: Scalars['String'];
  node: IApiCompanyType;
};

export type IApiCompanyTypeFilter = {
  type?: Maybe<IApiCompanyTypeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiCompanyTypeFilterType {
  IsIntake = 'IS_INTAKE',
  Name = 'NAME'
}

export enum IApiCompanyTypeOrderBy {
  Name = 'NAME'
}

export type IApiCompleteMultipartUploadInput = {
  key: Scalars['String'];
  parts: Array<Maybe<IApiPartsInput>>;
  uploadId: Scalars['String'];
};

export type IApiContact = IApiNode & {
  __typename?: 'Contact';
  additionalInformation?: Maybe<Scalars['String']>;
  Addresses?: Maybe<Array<Maybe<IApiAddress>>>;
  BillingAddress?: Maybe<IApiAddress>;
  BranchAssignments?: Maybe<Array<Maybe<IApiContactBranchAssignment>>>;
  Branches?: Maybe<Array<Maybe<IApiBranch>>>;
  claimNumber?: Maybe<Scalars['String']>;
  companyNotRequired?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  Emails?: Maybe<Array<Maybe<IApiEmail>>>;
  endDate?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  independentAdjuster?: Maybe<Scalars['Boolean']>;
  insuredName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  Phones?: Maybe<Array<Maybe<IApiPhone>>>;
  policyNumber?: Maybe<Scalars['String']>;
  qbListId?: Maybe<Scalars['String']>;
  Role?: Maybe<IApiContactRole>;
  startDate?: Maybe<Scalars['Date']>;
  suffix?: Maybe<Scalars['String']>;
  thirdPartyAdministrator?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
};


export type IApiContactBranchesArgs = {
  filters?: Maybe<Array<Maybe<IApiContactFilter>>>;
};

export type IApiContactBranchAssignment = {
  __typename?: 'ContactBranchAssignment';
  Branch?: Maybe<IApiBranch>;
  BranchId?: Maybe<Scalars['ID']>;
  Contact?: Maybe<IApiContact>;
  ContactId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiContactConnection = {
  __typename?: 'ContactConnection';
  edges?: Maybe<Array<Maybe<IApiContactEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiContactEdge = {
  __typename?: 'ContactEdge';
  cursor: Scalars['String'];
  node: IApiContact;
};

export type IApiContactFilter = {
  type?: Maybe<IApiContactFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiContactFilterType {
  Branch = 'BRANCH',
  Company = 'COMPANY',
  CompanyState = 'COMPANY_STATE',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  FullName = 'FULL_NAME',
  IdIn = 'ID_IN',
  IsActive = 'IS_ACTIVE',
  IsContactActive = 'IS_CONTACT_ACTIVE',
  LastName = 'LAST_NAME',
  Position = 'POSITION',
  RoleVisibility = 'ROLE_VISIBILITY',
  Search = 'SEARCH',
  State = 'STATE',
  ViewListView = 'VIEW_LIST_VIEW'
}

export enum IApiContactOrderBy {
  Branch = 'BRANCH',
  Company = 'COMPANY',
  CreatedAt = 'CREATED_AT',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  FullName = 'FULL_NAME',
  LastName = 'LAST_NAME'
}

export type IApiContactRole = IApiNode & {
  __typename?: 'ContactRole';
  Contacts?: Maybe<Array<Maybe<IApiContact>>>;
  ContactType?: Maybe<IApiContactType>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isPrivate?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiContactRoleConnection = {
  __typename?: 'ContactRoleConnection';
  edges?: Maybe<Array<Maybe<IApiContactRoleEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiContactRoleEdge = {
  __typename?: 'ContactRoleEdge';
  cursor: Scalars['String'];
  node: IApiContactRole;
};

export type IApiContactRoleFilter = {
  type?: Maybe<IApiContactRoleFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiContactRoleFilterType {
  Name = 'NAME',
  ViewListView = 'VIEW_LIST_VIEW'
}

export enum IApiContactRoleOrderBy {
  Name = 'NAME'
}

export type IApiContactType = {
  __typename?: 'ContactType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiCreateInvestigationReportInput = {
  documentTypeId: Scalars['ID'];
  investigationId: Scalars['ID'];
};

export type IApiCreateMultipartResponse = {
  __typename?: 'CreateMultipartResponse';
  key: Scalars['String'];
  uploadId: Scalars['String'];
};

export type IApiCustomCompany = {
  address?: Maybe<IApiAddAddressInput>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<IApiAddPhoneInput>;
};

export type IApiCustomCompanyReview = {
  address?: Maybe<IApiAddAddressInput>;
  branchId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<IApiAddPhoneInput>;
  updateAddress?: Maybe<IApiUpdateAddressInput>;
  updatePhone?: Maybe<IApiUpdatePhoneInput>;
};



export type IApiDetail = {
  __typename?: 'Detail';
  city?: Maybe<Scalars['String']>;
  claimNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  fireMarshalName?: Maybe<Scalars['String']>;
  fireMarshalPhoneNumber?: Maybe<Scalars['String']>;
  isSameLossLocation?: Maybe<Scalars['Boolean']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  publicAdjusterName?: Maybe<Scalars['String']>;
  publicAdjusterPhoneNumber?: Maybe<Scalars['String']>;
  riskDescription?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  vehicleAddress?: Maybe<Scalars['String']>;
  vinNumber?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type IApiDistanceOptions = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type IApiDocument = IApiNode & {
  __typename?: 'Document';
  caption?: Maybe<Scalars['String']>;
  Comments?: Maybe<Array<Maybe<IApiComment>>>;
  createdAt?: Maybe<Scalars['Date']>;
  CreatedBy?: Maybe<IApiUser>;
  DocumentCollection?: Maybe<IApiDocumentCollection>;
  Editor?: Maybe<IApiUser>;
  History?: Maybe<Array<Maybe<IApiDocumentSnapshot>>>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  s3Uri?: Maybe<Scalars['String']>;
  Shares?: Maybe<Array<Maybe<IApiDocumentShare>>>;
  status?: Maybe<Scalars['String']>;
  Status?: Maybe<IApiDocumentSnapshot>;
  title: Scalars['String'];
  Type?: Maybe<IApiDocumentType>;
  updatedAt?: Maybe<Scalars['Date']>;
  UpdatedBy?: Maybe<IApiUser>;
  uploadError?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type IApiDocumentApproval = IApiNode & {
  __typename?: 'DocumentApproval';
  comment: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  CreatedBy?: Maybe<IApiUser>;
  DocumentSnapshot?: Maybe<IApiDocumentSnapshot>;
  id: Scalars['ID'];
  isApproved: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiDocumentApprovalConnection = {
  __typename?: 'DocumentApprovalConnection';
  edges?: Maybe<Array<Maybe<IApiDocumentApprovalEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiDocumentApprovalEdge = {
  __typename?: 'DocumentApprovalEdge';
  cursor: Scalars['String'];
  node: IApiDocumentApproval;
};

export type IApiDocumentApprovalFilter = {
  type?: Maybe<IApiDocumentApprovalFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiDocumentApprovalFilterType {
  Name = 'NAME'
}

export enum IApiDocumentApprovalOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiDocumentCategory = IApiNode & {
  __typename?: 'DocumentCategory';
  createdAt?: Maybe<Scalars['Date']>;
  DocumentTypes?: Maybe<Array<Maybe<IApiDocumentType>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiDocumentCategoryConnection = {
  __typename?: 'DocumentCategoryConnection';
  edges?: Maybe<Array<Maybe<IApiDocumentCategoryEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiDocumentCategoryEdge = {
  __typename?: 'DocumentCategoryEdge';
  cursor: Scalars['String'];
  node: IApiDocumentCategory;
};

export type IApiDocumentCategoryFilter = {
  type?: Maybe<IApiDocumentCategoryFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiDocumentCategoryFilterType {
  Download = 'DOWNLOAD',
  Report = 'REPORT',
  Upload = 'UPLOAD'
}

export enum IApiDocumentCategoryOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type IApiDocumentCollection = IApiNode & {
  __typename?: 'DocumentCollection';
  collectionStatus: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  Documents?: Maybe<Array<Maybe<IApiDocument>>>;
  id: Scalars['ID'];
  Investigation: IApiInvestigation;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiDocumentCollectionConnection = {
  __typename?: 'DocumentCollectionConnection';
  edges?: Maybe<Array<Maybe<IApiDocumentCollectionEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiDocumentCollectionEdge = {
  __typename?: 'DocumentCollectionEdge';
  cursor: Scalars['String'];
  node: IApiDocumentCollection;
};

export type IApiDocumentCollectionFilter = {
  type?: Maybe<IApiDocumentCollectionFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiDocumentCollectionFilterType {
  Name = 'NAME'
}

export enum IApiDocumentCollectionOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiDocumentConnection = {
  __typename?: 'DocumentConnection';
  edges?: Maybe<Array<Maybe<IApiDocumentEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiDocumentEdge = {
  __typename?: 'DocumentEdge';
  cursor: Scalars['String'];
  node: IApiDocument;
};

export type IApiDocumentFilter = {
  type?: Maybe<IApiDocumentFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiDocumentFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  AssignedNotEditor = 'ASSIGNED_NOT_EDITOR',
  AssignedToUser = 'ASSIGNED_TO_USER',
  Category = 'CATEGORY',
  CountOnly = 'COUNT_ONLY',
  CreatedAt = 'CREATED_AT',
  CreatedBy = 'CREATED_BY',
  Investigation = 'INVESTIGATION',
  InvestigationReport = 'INVESTIGATION_REPORT',
  Readyforeditor = 'READYFOREDITOR',
  Risktype = 'RISKTYPE',
  Search = 'SEARCH',
  Status = 'STATUS',
  Title = 'TITLE',
  Type = 'TYPE',
  User = 'USER',
  ViewEditorReview = 'VIEW_EDITOR_REVIEW',
  ViewReadyforeditor = 'VIEW_READYFOREDITOR',
  ViewTechReview = 'VIEW_TECH_REVIEW'
}

export enum IApiDocumentOrderBy {
  CreatedAt = 'CREATED_AT',
  CreatedBy = 'CREATED_BY',
  Editor = 'EDITOR',
  Priority = 'PRIORITY',
  SubmittedAt = 'SUBMITTED_AT',
  Title = 'TITLE'
}

export type IApiDocumentSent = IApiNode & {
  __typename?: 'DocumentSent';
  BillTo?: Maybe<IApiContact>;
  Client?: Maybe<IApiContact>;
  Contact?: Maybe<IApiContact>;
  createdAt?: Maybe<Scalars['Date']>;
  Document?: Maybe<IApiDocument>;
  DocumentType?: Maybe<IApiDocumentType>;
  id: Scalars['ID'];
  Insured?: Maybe<IApiInsuredParty>;
  Investigation?: Maybe<IApiInvestigation>;
  note?: Maybe<Scalars['String']>;
  sendDate?: Maybe<Scalars['Date']>;
  specialInstructions?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiDocumentSentConnection = {
  __typename?: 'DocumentSentConnection';
  edges?: Maybe<Array<Maybe<IApiDocumentSentEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiDocumentSentEdge = {
  __typename?: 'DocumentSentEdge';
  cursor: Scalars['String'];
  node: IApiDocumentSent;
};

export type IApiDocumentSentFilter = {
  type?: Maybe<IApiDocumentSentFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiDocumentSentFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  ClientCompany = 'CLIENT_COMPANY',
  ClientContact = 'CLIENT_CONTACT',
  Editors = 'EDITORS',
  Investigation = 'INVESTIGATION',
  Investigations = 'INVESTIGATIONS',
  Own = 'OWN',
  ReportBy = 'REPORT_BY',
  ReportType = 'REPORT_TYPE',
  SendDateRange = 'SEND_DATE_RANGE'
}

export enum IApiDocumentSentOrderBy {
  CreatedAt = 'CREATED_AT',
  SendDate = 'SEND_DATE'
}

export type IApiDocumentShare = IApiNode & {
  __typename?: 'DocumentShare';
  BillTo?: Maybe<IApiContact>;
  Client?: Maybe<IApiContact>;
  createdAt?: Maybe<Scalars['Date']>;
  Document?: Maybe<IApiDocument>;
  id: Scalars['ID'];
  Insured?: Maybe<IApiContact>;
  updatedAt?: Maybe<Scalars['Date']>;
  User?: Maybe<IApiUser>;
};

export type IApiDocumentShareConnection = {
  __typename?: 'DocumentShareConnection';
  edges?: Maybe<Array<Maybe<IApiDocumentShareEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiDocumentShareEdge = {
  __typename?: 'DocumentShareEdge';
  cursor: Scalars['String'];
  node: IApiDocumentShare;
};

export type IApiDocumentShareFilter = {
  type?: Maybe<IApiDocumentShareFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiDocumentShareFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  Email = 'EMAIL',
  Investigation = 'INVESTIGATION',
  Name = 'NAME',
  Own = 'OWN'
}

export enum IApiDocumentShareOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiDocumentSnapshot = IApiNode & {
  __typename?: 'DocumentSnapshot';
  ApprovalHistory?: Maybe<Array<Maybe<IApiDocumentApproval>>>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  CreatedBy?: Maybe<IApiUser>;
  Document?: Maybe<IApiDocument>;
  id: Scalars['ID'];
  s3Uri?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  uri?: Maybe<Scalars['String']>;
};

export type IApiDocumentSnapshotConnection = {
  __typename?: 'DocumentSnapshotConnection';
  edges?: Maybe<Array<Maybe<IApiDocumentSnapshotEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiDocumentSnapshotEdge = {
  __typename?: 'DocumentSnapshotEdge';
  cursor: Scalars['String'];
  node: IApiDocumentSnapshot;
};

export type IApiDocumentSnapshotFilter = {
  type?: Maybe<IApiDocumentSnapshotFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiDocumentSnapshotFilterType {
  Name = 'NAME'
}

export enum IApiDocumentSnapshotOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiDocumentTemplate = IApiNode & {
  __typename?: 'DocumentTemplate';
  createdAt?: Maybe<Scalars['Date']>;
  DocumentTypes?: Maybe<Array<Maybe<IApiDocumentType>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  oneDriveItemId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiDocumentTemplateConnection = {
  __typename?: 'DocumentTemplateConnection';
  edges?: Maybe<Array<Maybe<IApiDocumentTemplateEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiDocumentTemplateEdge = {
  __typename?: 'DocumentTemplateEdge';
  cursor: Scalars['String'];
  node: IApiDocumentTemplate;
};

export type IApiDocumentTemplateFilter = {
  type?: Maybe<IApiDocumentTemplateFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiDocumentTemplateFilterType {
  OneDriveItemId = 'ONE_DRIVE_ITEM_ID'
}

export enum IApiDocumentTemplateOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiDocumentType = IApiNode & {
  __typename?: 'DocumentType';
  Category?: Maybe<IApiDocumentCategory>;
  createdAt?: Maybe<Scalars['Date']>;
  defaultTitle?: Maybe<Scalars['String']>;
  Documents?: Maybe<Array<Maybe<IApiDocument>>>;
  id: Scalars['ID'];
  isCourtDocument: Scalars['Boolean'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  SubServiceTypeId?: Maybe<Scalars['ID']>;
  Template?: Maybe<IApiDocumentTemplate>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiDocumentTypeConnection = {
  __typename?: 'DocumentTypeConnection';
  edges?: Maybe<Array<Maybe<IApiDocumentTypeEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiDocumentTypeEdge = {
  __typename?: 'DocumentTypeEdge';
  cursor: Scalars['String'];
  node: IApiDocumentType;
};

export type IApiDocumentTypeFilter = {
  type?: Maybe<IApiDocumentTypeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiDocumentTypeFilterType {
  Categories = 'CATEGORIES',
  Category = 'CATEGORY',
  Name = 'NAME',
  ViewListName = 'VIEW_LIST_NAME'
}

export enum IApiDocumentTypeOrderBy {
  Category = 'CATEGORY',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type IApiDocumentZipDownload = {
  DocumentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  InvestigationId: Scalars['ID'];
  PhotoIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type IApiEffectivePermissionAction = {
  __typename?: 'EffectivePermissionAction';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiEffectivePermissionCategory = {
  __typename?: 'EffectivePermissionCategory';
  EffectivePermissionScopes?: Maybe<Array<Maybe<IApiEffectivePermissionScope>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiEffectivePermissionScope = {
  __typename?: 'EffectivePermissionScope';
  EffectivePermissionActions?: Maybe<Array<Maybe<IApiEffectivePermissionAction>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiEmail = IApiNode & {
  __typename?: 'Email';
  address: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  emailableId?: Maybe<Scalars['ID']>;
  emailableType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
  Type: IApiEmailType;
  TypeId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiEmailAddress = {
  __typename?: 'emailAddress';
  email?: Maybe<Scalars['String']>;
  emailableId?: Maybe<Scalars['String']>;
  emailableType?: Maybe<Scalars['String']>;
};

export type IApiEmailConnection = {
  __typename?: 'EmailConnection';
  edges?: Maybe<Array<Maybe<IApiEmailEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiEmailContactInput = {
  id: Scalars['ID'];
  investigationId: Scalars['ID'];
  investigatorId: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};

export type IApiEmailEdge = {
  __typename?: 'EmailEdge';
  cursor: Scalars['String'];
  node: IApiEmail;
};

export type IApiEmailFilter = {
  type?: Maybe<IApiEmailFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiEmailFilterType {
  Name = 'NAME'
}

export enum IApiEmailOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiEmailType = IApiNode & {
  __typename?: 'EmailType';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiEmailTypeConnection = {
  __typename?: 'EmailTypeConnection';
  edges?: Maybe<Array<Maybe<IApiEmailTypeEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiEmailTypeEdge = {
  __typename?: 'EmailTypeEdge';
  cursor: Scalars['String'];
  node: IApiEmailType;
};

export type IApiEmailTypeFilter = {
  type?: Maybe<IApiEmailTypeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiEmailTypeFilterType {
  FromLocation = 'FROM_LOCATION'
}

export enum IApiEmailTypeOrderBy {
  Name = 'NAME'
}

export type IApiEvidence = IApiNode & {
  __typename?: 'Evidence';
  Comments?: Maybe<Array<Maybe<IApiComment>>>;
  createdAt?: Maybe<Scalars['Date']>;
  CreatedBy?: Maybe<IApiUser>;
  CurrentTransfer?: Maybe<IApiEvidenceTransfer>;
  description: Scalars['String'];
  evidenceNumber: Scalars['Int'];
  EvidenceStorageLocation?: Maybe<IApiEvidenceStorageLocation>;
  id: Scalars['ID'];
  Investigation: IApiInvestigation;
  InvoiceItems?: Maybe<Array<Maybe<IApiInvoiceItem>>>;
  notes?: Maybe<Scalars['String']>;
  Photos?: Maybe<Array<Maybe<IApiPhoto>>>;
  row?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  StatusHistory?: Maybe<Array<Maybe<IApiEvidenceStatus>>>;
  SubService?: Maybe<IApiSubService>;
  takenFrom: Scalars['String'];
  Transfers?: Maybe<Array<Maybe<IApiEvidenceTransfer>>>;
  ueNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiEvidenceBilling = IApiNode & {
  __typename?: 'EvidenceBilling';
  billable?: Maybe<Scalars['Boolean']>;
  BillingFrequency?: Maybe<IApiEvidenceBillingFrequency>;
  createdAt?: Maybe<Scalars['Date']>;
  currentExpensed?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  lastExpensed?: Maybe<Scalars['Date']>;
  notes?: Maybe<Scalars['String']>;
  override?: Maybe<Scalars['Boolean']>;
  palletCount: Scalars['String'];
  rate?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiEvidenceBillingConnection = {
  __typename?: 'EvidenceBillingConnection';
  edges?: Maybe<Array<Maybe<IApiEvidenceBillingEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiEvidenceBillingEdge = {
  __typename?: 'EvidenceBillingEdge';
  cursor: Scalars['String'];
  node: IApiEvidenceBilling;
};

export type IApiEvidenceBillingFilter = {
  type?: Maybe<IApiEvidenceBillingFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiEvidenceBillingFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  Investigation = 'INVESTIGATION',
  Own = 'OWN'
}

export type IApiEvidenceBillingFrequency = IApiNode & {
  __typename?: 'EvidenceBillingFrequency';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  months?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiEvidenceBillingFrequencyConnection = {
  __typename?: 'EvidenceBillingFrequencyConnection';
  edges?: Maybe<Array<Maybe<IApiEvidenceBillingFrequencyEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiEvidenceBillingFrequencyEdge = {
  __typename?: 'EvidenceBillingFrequencyEdge';
  cursor: Scalars['String'];
  node: IApiEvidenceBillingFrequency;
};

export type IApiEvidenceBillingFrequencyFilter = {
  type?: Maybe<IApiEvidenceBillingFrequencyFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiEvidenceBillingFrequencyFilterType {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export enum IApiEvidenceBillingFrequencyOrderBy {
  CreatedAt = 'CREATED_AT'
}

export enum IApiEvidenceBillingOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiEvidenceConnection = {
  __typename?: 'EvidenceConnection';
  edges?: Maybe<Array<Maybe<IApiEvidenceEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiEvidenceEdge = {
  __typename?: 'EvidenceEdge';
  cursor: Scalars['String'];
  node: IApiEvidence;
};

export type IApiEvidenceFilter = {
  type?: Maybe<IApiEvidenceFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiEvidenceFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  CompanyIds = 'COMPANY_IDS',
  Date = 'DATE',
  Destroyed = 'DESTROYED',
  Disposed = 'DISPOSED',
  Ids = 'IDS',
  Investigation = 'INVESTIGATION',
  InvestigationIds = 'INVESTIGATION_IDS',
  InvestigatorIds = 'INVESTIGATOR_IDS',
  IsBillable = 'IS_BILLABLE',
  IsNonBillable = 'IS_NON_BILLABLE',
  Location = 'LOCATION',
  Locations = 'LOCATIONS',
  Own = 'OWN',
  Search = 'SEARCH',
  State = 'STATE',
  Status = 'STATUS',
  Statuses = 'STATUSES',
  Ue = 'UE'
}

export type IApiEvidenceListDownloadByIdInput = {
  EvidenceIds: Array<Maybe<Scalars['ID']>>;
};

export enum IApiEvidenceOrderBy {
  CreatedAt = 'CREATED_AT',
  EvidenceNumber = 'EVIDENCE_NUMBER',
  Investigation = 'INVESTIGATION'
}

export type IApiEvidenceStatus = IApiNode & {
  __typename?: 'EvidenceStatus';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  CreatedBy?: Maybe<IApiUser>;
  Evidence?: Maybe<IApiEvidence>;
  id: Scalars['ID'];
  name: IApiEvidenceStatusType;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiEvidenceStatusConnection = {
  __typename?: 'EvidenceStatusConnection';
  edges?: Maybe<Array<Maybe<IApiEvidenceStatusEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiEvidenceStatusEdge = {
  __typename?: 'EvidenceStatusEdge';
  cursor: Scalars['String'];
  node: IApiEvidenceStatus;
};

export type IApiEvidenceStatusFilter = {
  type?: Maybe<IApiEvidenceStatusFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiEvidenceStatusFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  Evidence = 'EVIDENCE',
  Own = 'OWN'
}

export enum IApiEvidenceStatusOrderBy {
  CreatedAt = 'CREATED_AT'
}

export enum IApiEvidenceStatusType {
  AwaitingDisposal = 'AWAITING_DISPOSAL',
  Destroyed = 'DESTROYED',
  DisposalRequested = 'DISPOSAL_REQUESTED',
  Disposed = 'DISPOSED',
  Shipped = 'SHIPPED',
  Stored = 'STORED'
}

export type IApiEvidenceStorageLocation = IApiNode & {
  __typename?: 'EvidenceStorageLocation';
  additionalDetails: Scalars['String'];
  Address?: Maybe<IApiAddress>;
  companyName?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  Evidence?: Maybe<IApiEvidence>;
  hasDetails?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isNefco?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  Phone?: Maybe<IApiPhone>;
  Transfers?: Maybe<Array<Maybe<IApiEvidenceTransfer>>>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiEvidenceStorageLocationConnection = {
  __typename?: 'EvidenceStorageLocationConnection';
  edges?: Maybe<Array<Maybe<IApiEvidenceStorageLocationEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiEvidenceStorageLocationEdge = {
  __typename?: 'EvidenceStorageLocationEdge';
  cursor: Scalars['String'];
  node: IApiEvidenceStorageLocation;
};

export type IApiEvidenceStorageLocationFilter = {
  type?: Maybe<IApiEvidenceStorageLocationFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiEvidenceStorageLocationFilterType {
  IsActive = 'IS_ACTIVE',
  Locations = 'LOCATIONS',
  Name = 'NAME',
  State = 'STATE',
  ViewListView = 'VIEW_LIST_VIEW',
  ViewWithoutTransfers = 'VIEW_WITHOUT_TRANSFERS'
}

export enum IApiEvidenceStorageLocationOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  State = 'STATE'
}

export enum IApiEvidenceStorageLocationType {
  Storage = 'STORAGE',
  Transfer = 'TRANSFER'
}

export type IApiEvidenceTransfer = IApiNode & {
  __typename?: 'EvidenceTransfer';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  Evidence: Array<Maybe<IApiEvidence>>;
  FromLocation?: Maybe<IApiEvidenceStorageLocation>;
  id: Scalars['ID'];
  receivedComment?: Maybe<Scalars['String']>;
  receivedDate?: Maybe<Scalars['Date']>;
  ShippingCarrier: IApiShippingCarrier;
  ShippingDocument?: Maybe<IApiDocument>;
  ToLocation?: Maybe<IApiEvidenceStorageLocation>;
  trackingNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiEvidenceTransferConnection = {
  __typename?: 'EvidenceTransferConnection';
  edges?: Maybe<Array<Maybe<IApiEvidenceTransferEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiEvidenceTransferEdge = {
  __typename?: 'EvidenceTransferEdge';
  cursor: Scalars['String'];
  node: IApiEvidenceTransfer;
};

export type IApiEvidenceTransferFilter = {
  type?: Maybe<IApiEvidenceTransferFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiEvidenceTransferFilterType {
  FromLocation = 'FROM_LOCATION'
}

export enum IApiEvidenceTransferOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiExpense = IApiNode & {
  __typename?: 'Expense';
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedDate?: Maybe<Scalars['Date']>;
  billableQuantity?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  Document?: Maybe<IApiDocument>;
  expenseDate?: Maybe<Scalars['Date']>;
  ExpenseItem: IApiExpenseItem;
  ExpensePurpose?: Maybe<IApiExpensePurpose>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  InvoiceItems?: Maybe<Array<Maybe<IApiInvoiceItem>>>;
  nefcoVehicle?: Maybe<Scalars['Int']>;
  nonBillableQuantity?: Maybe<Scalars['Float']>;
  outOfPocket?: Maybe<Scalars['Int']>;
  paidDate?: Maybe<Scalars['Date']>;
  receipts?: Maybe<Array<Maybe<IApiReceiptTempRemoveTodo>>>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  User?: Maybe<IApiUser>;
};

export type IApiExpenseApplication = IApiNode & {
  __typename?: 'ExpenseApplication';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type IApiExpenseApplicationConnection = {
  __typename?: 'ExpenseApplicationConnection';
  edges?: Maybe<Array<Maybe<IApiExpenseApplicationEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiExpenseApplicationEdge = {
  __typename?: 'ExpenseApplicationEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiExpenseApplication>;
};

export type IApiExpenseApplicationFilter = {
  type?: Maybe<IApiExpenseApplicationFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiExpenseApplicationFilterType {
  Name = 'NAME'
}

export enum IApiExpenseApplicationOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type IApiExpenseCategory = IApiNode & {
  __typename?: 'ExpenseCategory';
  createdAt: Scalars['Date'];
  details?: Maybe<Scalars['String']>;
  ExpensePurposes?: Maybe<Array<Maybe<IApiExpensePurpose>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type IApiExpenseCategoryConnection = {
  __typename?: 'ExpenseCategoryConnection';
  edges?: Maybe<Array<Maybe<IApiExpenseCategoryEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiExpenseCategoryEdge = {
  __typename?: 'ExpenseCategoryEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiExpenseCategory>;
};

export type IApiExpenseCategoryFilter = {
  type?: Maybe<IApiExpenseCategoryFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiExpenseCategoryFilterType {
  Purpose = 'PURPOSE'
}

export enum IApiExpenseCategoryOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type IApiExpenseConnection = {
  __typename?: 'ExpenseConnection';
  edges?: Maybe<Array<Maybe<IApiExpenseEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiExpenseEdge = {
  __typename?: 'ExpenseEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiExpense>;
};

export type IApiExpenseFilter = {
  type?: Maybe<IApiExpenseFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiExpenseFilterType {
  CreatedatDate = 'CREATEDAT_DATE',
  DateRange = 'DATE_RANGE',
  Defaults = 'DEFAULTS',
  EntryDate = 'ENTRY_DATE',
  EntryDateRange = 'ENTRY_DATE_RANGE',
  EntryMonth = 'ENTRY_MONTH',
  EntryWeek = 'ENTRY_WEEK',
  EntryYear = 'ENTRY_YEAR',
  EvidenceBilling = 'EVIDENCE_BILLING',
  EvidenceBillingBillto = 'EVIDENCE_BILLING_BILLTO',
  EvidenceBillingClient = 'EVIDENCE_BILLING_CLIENT',
  EvidenceBillingCreatedby = 'EVIDENCE_BILLING_CREATEDBY',
  EvidenceBillingItem = 'EVIDENCE_BILLING_ITEM',
  EvidenceBillingSearch = 'EVIDENCE_BILLING_SEARCH',
  ExcludeNefcoVehicle = 'EXCLUDE_NEFCO_VEHICLE',
  ExpenseDate = 'EXPENSE_DATE',
  ExpenseDateRange = 'EXPENSE_DATE_RANGE',
  ExpenseFilterOutForUser = 'EXPENSE_FILTER_OUT_FOR_USER',
  ExpenseItem = 'EXPENSE_ITEM',
  ExpenseMonth = 'EXPENSE_MONTH',
  ExpensePaid = 'EXPENSE_PAID',
  ExpensePaidDate = 'EXPENSE_PAID_DATE',
  ExpensePaidDateRange = 'EXPENSE_PAID_DATE_RANGE',
  ExpensePurpose = 'EXPENSE_PURPOSE',
  ExpenseType = 'EXPENSE_TYPE',
  ExpenseWeek = 'EXPENSE_WEEK',
  ExpenseYear = 'EXPENSE_YEAR',
  Investigation = 'INVESTIGATION',
  Invoice = 'INVOICE',
  InvoicedDate = 'INVOICED_DATE',
  IsInvoiced = 'IS_INVOICED',
  Mileage = 'MILEAGE',
  NefcoVehicle = 'NEFCO_VEHICLE',
  OutOfPocket = 'OUT_OF_POCKET',
  PocketExpenseMileage = 'POCKET_EXPENSE_MILEAGE',
  Search = 'SEARCH',
  Status = 'STATUS',
  User = 'USER',
  ViewBookkeepingReport = 'VIEW_BOOKKEEPING_REPORT',
  ViewBookkeepingView = 'VIEW_BOOKKEEPING_VIEW',
  ViewEvidenceBillingList = 'VIEW_EVIDENCE_BILLING_LIST',
  ViewListView = 'VIEW_LIST_VIEW'
}

export type IApiExpenseItem = IApiNode & {
  __typename?: 'ExpenseItem';
  active?: Maybe<Scalars['Boolean']>;
  AppliesTo?: Maybe<IApiExpenseApplication>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  descriptionRequired?: Maybe<Scalars['Boolean']>;
  ExpenseCategory?: Maybe<IApiExpenseCategory>;
  ExpenseReimbursementRate?: Maybe<Array<Maybe<IApiExpenseReimbursementRate>>>;
  ExpenseType?: Maybe<IApiExpenseType>;
  id: Scalars['ID'];
  isFlatRate?: Maybe<Scalars['Boolean']>;
  isQuantity?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  qbAccount?: Maybe<Scalars['String']>;
  reimbursementRate?: Maybe<Scalars['Float']>;
  summary?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Date']>;
  value?: Maybe<Scalars['Float']>;
};

export type IApiExpenseItemConnection = {
  __typename?: 'ExpenseItemConnection';
  edges?: Maybe<Array<Maybe<IApiExpenseItemEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiExpenseItemEdge = {
  __typename?: 'ExpenseItemEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiExpenseItem>;
};

export type IApiExpenseItemFilter = {
  type?: Maybe<IApiExpenseItemFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiExpenseItemFilterType {
  Application = 'APPLICATION',
  Category = 'CATEGORY',
  CreatedatDate = 'CREATEDAT_DATE',
  ExpenseDate = 'EXPENSE_DATE',
  ExpenseInvoiced = 'EXPENSE_INVOICED',
  ExpenseInvoicedDate = 'EXPENSE_INVOICED_DATE',
  ExpenseItemName = 'EXPENSE_ITEM_NAME',
  ExpensePaid = 'EXPENSE_PAID',
  ExpensePaidDate = 'EXPENSE_PAID_DATE',
  ExpenseStaff = 'EXPENSE_STAFF',
  Id = 'ID',
  Summary = 'SUMMARY',
  Type = 'TYPE'
}

export enum IApiExpenseItemOrderBy {
  AppliesTo = 'APPLIES_TO',
  CreatedAt = 'CREATED_AT',
  ExpenseCategory = 'EXPENSE_CATEGORY',
  ExpenseType = 'EXPENSE_TYPE',
  Name = 'NAME'
}

export type IApiExpenseMileageTotal = {
  __typename?: 'ExpenseMileageTotal';
  billableExpense?: Maybe<Scalars['Float']>;
  billableMileage?: Maybe<Scalars['Float']>;
  nonBillableExpense?: Maybe<Scalars['Float']>;
  nonBillableMileage?: Maybe<Scalars['Float']>;
  totalExpense?: Maybe<Scalars['Float']>;
  totalMileage?: Maybe<Scalars['Float']>;
};

export enum IApiExpenseOrderBy {
  CreatedAt = 'CREATED_AT',
  ExpenseDate = 'EXPENSE_DATE',
  Investigation = 'INVESTIGATION',
  Invoice = 'INVOICE',
  PaidDate = 'PAID_DATE',
  Staff = 'STAFF',
  Type = 'TYPE'
}

export type IApiExpensePurpose = IApiNode & {
  __typename?: 'ExpensePurpose';
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  details?: Maybe<Scalars['String']>;
  ExpenseCategories?: Maybe<Array<Maybe<IApiExpenseCategory>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiExpensePurposeConnection = {
  __typename?: 'ExpensePurposeConnection';
  edges?: Maybe<Array<Maybe<IApiExpensePurposeEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiExpensePurposeEdge = {
  __typename?: 'ExpensePurposeEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiExpensePurpose>;
};

export type IApiExpensePurposeFilter = {
  type?: Maybe<IApiExpensePurposeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiExpensePurposeFilterType {
  Name = 'NAME'
}

export enum IApiExpensePurposeOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type IApiExpenseReimbursementRate = IApiNode & {
  __typename?: 'ExpenseReimbursementRate';
  ExpenseItemId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  reimbursementRate?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
};

export type IApiExpenseReportTotal = {
  __typename?: 'ExpenseReportTotal';
  billableExpense?: Maybe<Scalars['Float']>;
  nonBillableExpense?: Maybe<Scalars['Float']>;
  totalExpense?: Maybe<Scalars['Float']>;
};

export type IApiExpenseSubmitReport = {
  date: Scalars['String'];
  type: Scalars['String'];
  userId: Scalars['ID'];
};

export type IApiExpenseType = IApiNode & {
  __typename?: 'ExpenseType';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type IApiExpenseTypeConnection = {
  __typename?: 'ExpenseTypeConnection';
  edges?: Maybe<Array<Maybe<IApiExpenseTypeEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiExpenseTypeEdge = {
  __typename?: 'ExpenseTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiExpenseType>;
};

export type IApiExpenseTypeFilter = {
  type?: Maybe<IApiExpenseTypeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiExpenseTypeFilterType {
  Name = 'NAME'
}

export enum IApiExpenseTypeOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type IApiFlatRate = IApiNode & {
  __typename?: 'FlatRate';
  Company?: Maybe<IApiCompany>;
  id: Scalars['ID'];
  Risk?: Maybe<IApiRiskType>;
  value?: Maybe<Scalars['Float']>;
};

export type IApiGenerateReportXmlInput = {
  dateRange: Scalars['String'];
  expenseMileageType: Scalars['Int'];
  isEntryDate: Scalars['Boolean'];
  status: Scalars['Int'];
  users?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IApiGeoFireReportByStateArray = {
  __typename?: 'GeoFireReportByStateArray';
  count?: Maybe<Scalars['Int']>;
  InvestigationId?: Maybe<Scalars['ID']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  riskType?: Maybe<Scalars['String']>;
  riskTypes?: Maybe<Array<Maybe<IApiRiskTypeTotal>>>;
  state?: Maybe<Scalars['String']>;
};

export type IApiGeoFireReportByStateConnection = {
  __typename?: 'GeoFireReportByStateConnection';
  states?: Maybe<Array<Maybe<IApiGeoFireReportByStateArray>>>;
};

export type IApiHealthCheckApi = {
  __typename?: 'healthCheckApi';
  message?: Maybe<Scalars['String']>;
};

export type IApiIdentity = {
  __typename?: 'Identity';
  connection: Scalars['String'];
  isSocial: Scalars['Boolean'];
  provider: Scalars['String'];
  user_id: Scalars['String'];
};

export type IApiInsuredContacted = IApiNode & {
  __typename?: 'InsuredContacted';
  createdAt?: Maybe<Scalars['Date']>;
  CreatedBy?: Maybe<IApiUser>;
  CreatedById?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  InvestigationId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  User?: Maybe<IApiUser>;
  UserId?: Maybe<Scalars['ID']>;
};

export type IApiInsuredParty = IApiNode & {
  __typename?: 'InsuredParty';
  Addresses?: Maybe<Array<Maybe<IApiAddress>>>;
  contactName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  Emails?: Maybe<Array<Maybe<IApiEmail>>>;
  id: Scalars['ID'];
  insuredName?: Maybe<Scalars['String']>;
  Phones?: Maybe<Array<Maybe<IApiPhone>>>;
  suffix?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiInsuredPartyConnection = {
  __typename?: 'InsuredPartyConnection';
  edges?: Maybe<Array<Maybe<IApiInsuredPartyEdge>>>;
  pageInfo?: Maybe<IApiPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInsuredPartyEdge = {
  __typename?: 'InsuredPartyEdge';
  cursor: Scalars['String'];
  node: IApiInsuredParty;
};

export type IApiInsuredPartyFilter = {
  type?: Maybe<IApiInsuredPartyFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInsuredPartyFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  Ids = 'IDS',
  InsuredName = 'INSURED_NAME',
  Own = 'OWN'
}

export enum IApiInsuredPartyOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiIntake = {
  __typename?: 'Intake';
  AuthCreated?: Maybe<Scalars['Boolean']>;
  ClientInvestigationNote?: Maybe<IApiClientInvestigationNote>;
  company?: Maybe<IApiCompany>;
  Contact?: Maybe<IApiContact>;
};

export type IApiInvestigation = IApiNode & {
  __typename?: 'Investigation';
  ActiveAndDraft?: Maybe<Scalars['Float']>;
  billingInstructions?: Maybe<Scalars['String']>;
  BillTo?: Maybe<IApiContact>;
  BillToBranch?: Maybe<IApiBranch>;
  Client?: Maybe<IApiContact>;
  ClientBranch?: Maybe<IApiBranch>;
  Comments?: Maybe<Array<Maybe<IApiComment>>>;
  Company?: Maybe<IApiCompany>;
  conflictCheckRun?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  CreatedBy?: Maybe<IApiUser>;
  deletedAt?: Maybe<Scalars['Date']>;
  Detail?: Maybe<IApiInvestigationDetail>;
  Documents?: Maybe<Array<Maybe<IApiDocument>>>;
  docusketchRequested?: Maybe<Scalars['Boolean']>;
  Evidence?: Maybe<Array<Maybe<IApiEvidence>>>;
  EvidenceBilling?: Maybe<IApiEvidenceBilling>;
  Expenses?: Maybe<Array<Maybe<IApiExpense>>>;
  firstViewDate?: Maybe<Scalars['Date']>;
  History?: Maybe<Array<Maybe<IApiInvestigationHistory>>>;
  id: Scalars['ID'];
  Insured?: Maybe<IApiInsuredParty>;
  InsuredContacted?: Maybe<IApiInsuredContacted>;
  InvestigationAddress?: Maybe<IApiAddress>;
  InvestigationAssignmentSmsHistory?: Maybe<Array<Maybe<IApiInvestigationAssignmentSms>>>;
  InvestigationMarkClose?: Maybe<Array<Maybe<IApiInvestigationMarkClose>>>;
  InvestigationRestriction?: Maybe<Array<Maybe<IApiInvestigationRestriction>>>;
  InvestigationSource?: Maybe<IApiInvestigationSource>;
  InvestigationStaff?: Maybe<Array<Maybe<IApiInvestigationStaff>>>;
  isIncendiary?: Maybe<Scalars['Boolean']>;
  isOngoing?: Maybe<Scalars['Boolean']>;
  isRejected?: Maybe<Scalars['Boolean']>;
  isRetainerReceived?: Maybe<Scalars['Boolean']>;
  isSymbility?: Maybe<Scalars['Boolean']>;
  isXactimate?: Maybe<Scalars['Boolean']>;
  LinkedInvestigations?: Maybe<Array<Maybe<IApiInvestigation>>>;
  LossAddress?: Maybe<IApiAddress>;
  lossDate?: Maybe<Scalars['Date']>;
  markAsClosed?: Maybe<Scalars['Boolean']>;
  Mileage?: Maybe<Array<Maybe<IApiExpense>>>;
  NearOnScene?: Maybe<IApiInvestigationOnScene>;
  nefcoNumber?: Maybe<Scalars['String']>;
  NefcoOffice?: Maybe<IApiNefcoOffice>;
  Notifications?: Maybe<Array<Maybe<IApiNotification>>>;
  onHold?: Maybe<Scalars['Boolean']>;
  OnSceneHistory?: Maybe<Array<Maybe<IApiInvestigationOnScene>>>;
  Parties?: Maybe<Array<Maybe<IApiInvestigationParty>>>;
  PhotoFolders?: Maybe<Array<Maybe<IApiPhotoFolder>>>;
  Priority?: Maybe<IApiInvestigationPriority>;
  Progress?: Maybe<Scalars['Float']>;
  receivedDate?: Maybe<Scalars['Date']>;
  RecentSubmittedDocument?: Maybe<IApiDocument>;
  Requests?: Maybe<Array<Maybe<IApiRequest>>>;
  RiskType?: Maybe<IApiRiskType>;
  SceneContact?: Maybe<IApiContact>;
  scheduledDate?: Maybe<Scalars['String']>;
  Services?: Maybe<Array<Maybe<IApiService>>>;
  status?: Maybe<Scalars['String']>;
  StatusHistory?: Maybe<Array<Maybe<IApiInvestigationStatus>>>;
  TimeEntries?: Maybe<Array<Maybe<IApiTimeEntry>>>;
  timezone?: Maybe<Scalars['String']>;
  ueNumber?: Maybe<Scalars['String']>;
  UnBilledEntries?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Date']>;
  VehicleCount?: Maybe<Scalars['Int']>;
  Vehicles?: Maybe<Array<Maybe<IApiVehicle>>>;
};

export type IApiInvestigationAssignmentSms = {
  __typename?: 'InvestigationAssignmentSMS';
  action?: Maybe<Scalars['String']>;
  AssignedUserId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  message: Scalars['String'];
  messageSid?: Maybe<Scalars['String']>;
  messageStatus?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  UserId?: Maybe<Scalars['ID']>;
};

export enum IApiInvestigationAssignmentSmsAction {
  AssignmentResponse = 'ASSIGNMENT_RESPONSE',
  NewAssignmentRequest = 'NEW_ASSIGNMENT_REQUEST'
}

export enum IApiInvestigationAssignmentSmsDirection {
  Inbound = 'inbound',
  Outbound = 'outbound'
}

export type IApiInvestigationAssignmentSmsInput = {
  InvestigationId: Scalars['ID'];
  UserId: Scalars['ID'];
};

export enum IApiInvestigationAssignmentSmsSource {
  Admin = 'ADMIN',
  System = 'SYSTEM'
}

export enum IApiInvestigationAssignmentSmsStatus {
  Accepted = 'accepted',
  Canceled = 'canceled',
  Delivered = 'delivered',
  Failed = 'failed',
  PartiallyDelivered = 'partially_delivered',
  Queued = 'queued',
  Read = 'read',
  Received = 'received',
  Receiving = 'receiving',
  Scheduled = 'scheduled',
  Sending = 'sending',
  Sent = 'sent',
  Undelivered = 'undelivered'
}

export type IApiInvestigationAssignmentSmsTwilioInput = {
  action?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  direction: Scalars['String'];
  from?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  messageSid: Scalars['String'];
  messageStatus: Scalars['String'];
  to: Scalars['String'];
};

export type IApiInvestigationConnection = {
  __typename?: 'InvestigationConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationEdge>>>;
  pageInfo?: Maybe<IApiPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationDetail = IApiNode & {
  __typename?: 'InvestigationDetail';
  billingInstructionNotes?: Maybe<Scalars['String']>;
  budgetAmount?: Maybe<Scalars['Float']>;
  cause?: Maybe<IApiInvestigationDetailCause>;
  claimNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  evidenceGatheringComplete?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  linkedInvestigationNotes?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  otherContactName?: Maybe<Scalars['String']>;
  otherContactPhone?: Maybe<Scalars['String']>;
  partyNotes?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  riskDescription?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
  staffNotes?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  vehicleNotes?: Maybe<Scalars['String']>;
};

export enum IApiInvestigationDetailCause {
  Accidental = 'ACCIDENTAL',
  AccidentalSubrogrationFile = 'ACCIDENTAL_SUBROGRATION_FILE',
  Incendiary = 'INCENDIARY',
  Null = 'NULL',
  Undetermined = 'UNDETERMINED'
}

export type IApiInvestigationDetailConnection = {
  __typename?: 'InvestigationDetailConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationDetailEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationDetailEdge = {
  __typename?: 'InvestigationDetailEdge';
  cursor: Scalars['String'];
  node: IApiInvestigationDetail;
};

export type IApiInvestigationDetailFilter = {
  type?: Maybe<IApiInvestigationDetailFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvestigationDetailFilterType {
  CreatedAt = 'CREATED_AT',
  InvestigationId = 'INVESTIGATION_ID',
  Name = 'NAME'
}

export enum IApiInvestigationDetailOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiInvestigationEdge = {
  __typename?: 'InvestigationEdge';
  cursor: Scalars['String'];
  node: IApiInvestigation;
};

export type IApiInvestigationEditorTimelog = {
  __typename?: 'InvestigationEditorTimelog';
  data?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
};

export type IApiInvestigationEditorTimelogAverage = {
  __typename?: 'InvestigationEditorTimelogAverage';
  onSceneToReportSent?: Maybe<Scalars['Float']>;
  onSceneToReportsUploaded?: Maybe<Scalars['Float']>;
};

export type IApiInvestigationEditorTimelogConnection = {
  __typename?: 'InvestigationEditorTimelogConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationEditorTimelogEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationEditorTimelogEdge = {
  __typename?: 'InvestigationEditorTimelogEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvestigationEditorTimelog>;
};

export type IApiInvestigationFilter = {
  type?: Maybe<IApiInvestigationFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvestigationFilterType {
  ActiveAndClosed = 'ACTIVE_AND_CLOSED',
  AppList = 'APP_LIST',
  AppSearch = 'APP_SEARCH',
  AppSort = 'APP_SORT',
  AppViewBy = 'APP_VIEW_BY',
  Archived = 'ARCHIVED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  BillingStatus = 'BILLING_STATUS',
  BillingStatusUnBilled = 'BILLING_STATUS_UN_BILLED',
  Cancelled = 'CANCELLED',
  Cause = 'CAUSE',
  CauseIn = 'CAUSE_IN',
  Closed = 'CLOSED',
  Companies = 'COMPANIES',
  Company = 'COMPANY',
  CompanySearch = 'COMPANY_SEARCH',
  Contact = 'CONTACT',
  ContactRole = 'CONTACT_ROLE',
  ContactSearch = 'CONTACT_SEARCH',
  CountOnly = 'COUNT_ONLY',
  CreatedAt = 'CREATED_AT',
  CreatedBy = 'CREATED_BY',
  Document = 'DOCUMENT',
  Evidence = 'EVIDENCE',
  EvidenceDateEnd = 'EVIDENCE_DATE_END',
  EvidenceDateStart = 'EVIDENCE_DATE_START',
  EvidenceLocation = 'EVIDENCE_LOCATION',
  EvidenceSearch = 'EVIDENCE_SEARCH',
  EvidenceStatus = 'EVIDENCE_STATUS',
  EvidenceStatusDisposed = 'EVIDENCE_STATUS_DISPOSED',
  Exclude = 'EXCLUDE',
  Insured = 'INSURED',
  InvestigationRole = 'INVESTIGATION_ROLE',
  InvestigationSource = 'INVESTIGATION_SOURCE',
  IsDeleted = 'IS_DELETED',
  IsIncendiary = 'IS_INCENDIARY',
  IsOnHold = 'IS_ON_HOLD',
  IsOngoing = 'IS_ONGOING',
  IsProximal = 'IS_PROXIMAL',
  LossCity = 'LOSS_CITY',
  LossState = 'LOSS_STATE',
  LossStateIn = 'LOSS_STATE_IN',
  LossZip = 'LOSS_ZIP',
  MapBounds = 'MAP_BOUNDS',
  MapMode = 'MAP_MODE',
  NefcoNumber = 'NEFCO_NUMBER',
  NefcoOfficeIdIn = 'NEFCO_OFFICE_ID_IN',
  OnScene = 'ON_SCENE',
  Party = 'PARTY',
  PolicyClaimNumber = 'POLICY_CLAIM_NUMBER',
  Priority = 'PRIORITY',
  ReceivedDate = 'RECEIVED_DATE',
  RiskType = 'RISK_TYPE',
  RiskTypeIn = 'RISK_TYPE_IN',
  Search = 'SEARCH',
  SearchNefcoNumber = 'SEARCH_NEFCO_NUMBER',
  Service = 'SERVICE',
  ServiceStatus = 'SERVICE_STATUS',
  ShowMarkCloseOnly = 'SHOW_MARK_CLOSE_ONLY',
  Source = 'SOURCE',
  Status = 'STATUS',
  StreetAddress = 'STREET_ADDRESS',
  User = 'USER',
  UserPrimary = 'USER_PRIMARY',
  ViewAssignedView = 'VIEW_ASSIGNED_VIEW',
  ViewInprogressView = 'VIEW_INPROGRESS_VIEW',
  ViewListView = 'VIEW_LIST_VIEW',
  ViewOngoingView = 'VIEW_ONGOING_VIEW',
  ViewOnlyNefconumber = 'VIEW_ONLY_NEFCONUMBER',
  ViewPendingView = 'VIEW_PENDING_VIEW',
  ViewProximityCheck = 'VIEW_PROXIMITY_CHECK',
  ViewReadytobillView = 'VIEW_READYTOBILL_VIEW'
}

export type IApiInvestigationHistory = {
  __typename?: 'InvestigationHistory';
  comment: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  CreatedBy?: Maybe<IApiUser>;
  CreatedById: Scalars['ID'];
  id: Scalars['ID'];
  InvestigationId: Scalars['ID'];
  requiresFollowup?: Maybe<Scalars['Boolean']>;
  updateCategory: IApiInvestigationUpdateCategories;
  updateType: IApiInvestigationUpdateTypes;
};

export type IApiInvestigationMarkClose = IApiNode & {
  __typename?: 'InvestigationMarkClose';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  markClosed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  User?: Maybe<IApiUser>;
};

export type IApiInvestigationMetrics = IApiNode & {
  __typename?: 'InvestigationMetrics';
  BillTo?: Maybe<IApiContact>;
  Client?: Maybe<IApiContact>;
  Company?: Maybe<IApiCompany>;
  DaysToInsuredContacted?: Maybe<Scalars['Float']>;
  DaysToOnScene?: Maybe<Scalars['Float']>;
  DaysToWrittenSummary?: Maybe<Scalars['Float']>;
  Detail?: Maybe<IApiInvestigationDetail>;
  docusketchRequested?: Maybe<Scalars['Boolean']>;
  Evidence?: Maybe<Array<Maybe<IApiEvidence>>>;
  EvidenceBilling?: Maybe<IApiEvidenceBilling>;
  Expenses?: Maybe<Array<Maybe<IApiExpense>>>;
  id: Scalars['ID'];
  Insured?: Maybe<IApiInsuredParty>;
  InvestigationStaff?: Maybe<Array<Maybe<IApiInvestigationStaff>>>;
  InvoiceTotal?: Maybe<Scalars['Float']>;
  LossAddress?: Maybe<IApiAddress>;
  lossDate?: Maybe<Scalars['Date']>;
  nefcoNumber?: Maybe<Scalars['String']>;
  OnSceneDate?: Maybe<Scalars['Date']>;
  receivedDate?: Maybe<Scalars['Date']>;
  RiskType?: Maybe<IApiRiskType>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type IApiInvestigationMetricsBasic = {
  __typename?: 'InvestigationMetricsBasic';
  data?: Maybe<Scalars['JSON']>;
  year?: Maybe<Scalars['String']>;
};

export type IApiInvestigationMetricsBasicConnection = {
  __typename?: 'InvestigationMetricsBasicConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationMetricsBasicEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationMetricsBasicEdge = {
  __typename?: 'InvestigationMetricsBasicEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvestigationMetricsBasic>;
};

export type IApiInvestigationMetricsBillToCompany = {
  __typename?: 'InvestigationMetricsBillToCompany';
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiInvestigationMetricsBillToCompanyConnection = {
  __typename?: 'InvestigationMetricsBillToCompanyConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationMetricsBillToCompanyEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationMetricsBillToCompanyEdge = {
  __typename?: 'InvestigationMetricsBillToCompanyEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvestigationMetricsBillToCompany>;
};

export type IApiInvestigationMetricsClientCompany = {
  __typename?: 'InvestigationMetricsClientCompany';
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiInvestigationMetricsClientCompanyConnection = {
  __typename?: 'InvestigationMetricsClientCompanyConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationMetricsClientCompanyEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationMetricsClientCompanyEdge = {
  __typename?: 'InvestigationMetricsClientCompanyEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvestigationMetricsClientCompany>;
};

export type IApiInvestigationMetricsClientContact = {
  __typename?: 'InvestigationMetricsClientContact';
  companyId?: Maybe<Scalars['ID']>;
  companyName?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
};

export type IApiInvestigationMetricsClientContactConnection = {
  __typename?: 'InvestigationMetricsClientContactConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationMetricsClientContactEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationMetricsClientContactEdge = {
  __typename?: 'InvestigationMetricsClientContactEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvestigationMetricsClientContact>;
};

export type IApiInvestigationMetricsConnection = {
  __typename?: 'InvestigationMetricsConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationMetricsEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationMetricsDetermination = {
  __typename?: 'InvestigationMetricsDetermination';
  data?: Maybe<Scalars['JSON']>;
  determination?: Maybe<Scalars['String']>;
};

export type IApiInvestigationMetricsDeterminationConnection = {
  __typename?: 'InvestigationMetricsDeterminationConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationMetricsDeterminationEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationMetricsDeterminationEdge = {
  __typename?: 'InvestigationMetricsDeterminationEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvestigationMetricsDetermination>;
};

export type IApiInvestigationMetricsDocusketchRequested = {
  __typename?: 'InvestigationMetricsDocusketchRequested';
  data?: Maybe<Scalars['JSON']>;
  year?: Maybe<Scalars['String']>;
};

export type IApiInvestigationMetricsDocusketchRequestedConnection = {
  __typename?: 'InvestigationMetricsDocusketchRequestedConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationMetricsDocusketchRequestedEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationMetricsDocusketchRequestedEdge = {
  __typename?: 'InvestigationMetricsDocusketchRequestedEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvestigationMetricsDocusketchRequested>;
};

export type IApiInvestigationMetricsEdge = {
  __typename?: 'InvestigationMetricsEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvestigationMetrics>;
};

export type IApiInvestigationMetricsFilter = {
  type?: Maybe<IApiInvestigationMetricsFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvestigationMetricsFilterType {
  BillToCompany = 'BILL_TO_COMPANY',
  BillingRuleCompany = 'BILLING_RULE_COMPANY',
  CauseOfFire = 'CAUSE_OF_FIRE',
  Client = 'CLIENT',
  ClientContact = 'CLIENT_CONTACT',
  CurrentClient = 'CURRENT_CLIENT',
  Editor = 'EDITOR',
  EvidenceStatus = 'EVIDENCE_STATUS',
  ExcludeCancelled = 'EXCLUDE_CANCELLED',
  ExcludeUnassigned = 'EXCLUDE_UNASSIGNED',
  HasEvidence = 'HAS_EVIDENCE',
  Ids = 'IDS',
  Investigator = 'INVESTIGATOR',
  IsInvestigatorPrimary = 'IS_INVESTIGATOR_PRIMARY',
  LossState = 'LOSS_STATE',
  NefcoOffice = 'NEFCO_OFFICE',
  ReceivedDate = 'RECEIVED_DATE',
  ReportExport = 'REPORT_EXPORT',
  RiskType = 'RISK_TYPE',
  TechReviewer = 'TECH_REVIEWER',
  ViewBy = 'VIEW_BY'
}

export type IApiInvestigationMetricsInvestigator = {
  __typename?: 'InvestigationMetricsInvestigator';
  data?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
};

export type IApiInvestigationMetricsInvestigatorConnection = {
  __typename?: 'InvestigationMetricsInvestigatorConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationMetricsInvestigatorEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationMetricsInvestigatorEdge = {
  __typename?: 'InvestigationMetricsInvestigatorEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvestigationMetricsInvestigator>;
};

export enum IApiInvestigationMetricsOrderBy {
  CauseOfFire = 'CAUSE_OF_FIRE',
  CompanyName = 'COMPANY_NAME',
  ContactName = 'CONTACT_NAME',
  CreatedAt = 'CREATED_AT',
  Editor = 'EDITOR',
  Investigator = 'INVESTIGATOR',
  LossState = 'LOSS_STATE',
  NefcoNumber = 'NEFCO_NUMBER',
  OnSceneToNotesUploaded = 'ON_SCENE_TO_NOTES_UPLOADED',
  OnSceneToPhotosUploaded = 'ON_SCENE_TO_PHOTOS_UPLOADED',
  ReceivedDate = 'RECEIVED_DATE',
  RiskType = 'RISK_TYPE',
  TechReviewer = 'TECH_REVIEWER',
  Total = 'TOTAL',
  UntilFirstMileageEntered = 'UNTIL_FIRST_MILEAGE_ENTERED',
  UntilOnScene = 'UNTIL_ON_SCENE',
  UserName = 'USER_NAME'
}

export type IApiInvestigationMetricsRiskTypeConnection = {
  __typename?: 'InvestigationMetricsRiskTypeConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationMetricsRiskTypeEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationMetricsRiskTypeEdge = {
  __typename?: 'InvestigationMetricsRiskTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvestigationRiskTypeMetricsNode>;
};

export type IApiInvestigationMetricsState = {
  __typename?: 'InvestigationMetricsState';
  data?: Maybe<Scalars['JSON']>;
  state?: Maybe<Scalars['String']>;
};

export type IApiInvestigationMetricsStateConnection = {
  __typename?: 'InvestigationMetricsStateConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationMetricsStateEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationMetricsStateEdge = {
  __typename?: 'InvestigationMetricsStateEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvestigationMetricsState>;
};

export type IApiInvestigationMetricsTechReviewer = {
  __typename?: 'InvestigationMetricsTechReviewer';
  data?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
};

export type IApiInvestigationMetricsTechReviewerConnection = {
  __typename?: 'InvestigationMetricsTechReviewerConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationMetricsTechReviewerEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationMetricsTechReviewerEdge = {
  __typename?: 'InvestigationMetricsTechReviewerEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvestigationMetricsTechReviewer>;
};

export type IApiInvestigationOnScene = IApiNode & {
  __typename?: 'InvestigationOnScene';
  createdAt?: Maybe<Scalars['Date']>;
  CreatedBy?: Maybe<IApiUser>;
  eventId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  LatestInvestigationOnScene?: Maybe<IApiInvestigationOnScene>;
  scheduledDate?: Maybe<Scalars['Date']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  User?: Maybe<IApiUser>;
  UserId?: Maybe<Scalars['ID']>;
};

export type IApiInvestigationOnSceneConnection = {
  __typename?: 'InvestigationOnSceneConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationOnSceneEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationOnSceneDate = {
  eventId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  InvestigationId: Scalars['ID'];
  notifyClient?: Maybe<Scalars['Boolean']>;
  scheduledDate: Scalars['Date'];
  UserId: Scalars['ID'];
};

export type IApiInvestigationOnSceneEdge = {
  __typename?: 'InvestigationOnSceneEdge';
  cursor: Scalars['String'];
  node: IApiInvestigationOnScene;
};

export type IApiInvestigationOnSceneFilter = {
  type?: Maybe<IApiInvestigationOnSceneFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvestigationOnSceneFilterType {
  CountOnly = 'COUNT_ONLY',
  CreatedAt = 'CREATED_AT',
  InvestigationId = 'INVESTIGATION_ID',
  InvestigationIdIn = 'INVESTIGATION_ID_IN',
  OnSceneStatus = 'ON_SCENE_STATUS',
  SceneExamState = 'SCENE_EXAM_STATE',
  ScheduledDate = 'SCHEDULED_DATE',
  ScheduledDateExact = 'SCHEDULED_DATE_EXACT',
  Source = 'SOURCE',
  Status = 'STATUS',
  UserId = 'USER_ID',
  UserIdIn = 'USER_ID_IN'
}

export enum IApiInvestigationOnSceneOrderBy {
  CreatedAt = 'CREATED_AT',
  NefcoNumber = 'NEFCO_NUMBER',
  OnSceneStatus = 'ON_SCENE_STATUS',
  ScheduledDate = 'SCHEDULED_DATE',
  UserName = 'USER_NAME'
}

export enum IApiInvestigationOnSceneStatus {
  EnRoute = 'EN_ROUTE',
  OffScene = 'OFF_SCENE',
  OnScene = 'ON_SCENE',
  Scheduled = 'SCHEDULED'
}

export enum IApiInvestigationOrderBy {
  CreatedAt = 'CREATED_AT',
  DocumentActiveInDraft = 'DOCUMENT_ACTIVE_IN_DRAFT',
  LossDate = 'LOSS_DATE',
  NefcoNumber = 'NEFCO_NUMBER',
  Priority = 'PRIORITY',
  RecentSubmittedCreateDate = 'RECENT_SUBMITTED_CREATE_DATE',
  RecentSubmittedType = 'RECENT_SUBMITTED_TYPE',
  UnbilledEntry = 'UNBILLED_ENTRY'
}

export type IApiInvestigationParty = IApiNode & {
  __typename?: 'InvestigationParty';
  Contact?: Maybe<IApiContact>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  isActive?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  Role?: Maybe<IApiContactRole>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiInvestigationPartyConnection = {
  __typename?: 'InvestigationPartyConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationPartyEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationPartyEdge = {
  __typename?: 'InvestigationPartyEdge';
  cursor: Scalars['String'];
  node: IApiInvestigationParty;
};

export type IApiInvestigationPartyFilter = {
  type?: Maybe<IApiInvestigationPartyFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvestigationPartyFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  Investigation = 'INVESTIGATION',
  Name = 'NAME',
  Own = 'OWN'
}

export enum IApiInvestigationPartyOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiInvestigationPriority = IApiNode & {
  __typename?: 'InvestigationPriority';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  Investigations?: Maybe<Array<Maybe<IApiInvestigation>>>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiInvestigationPriorityConnection = {
  __typename?: 'InvestigationPriorityConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationPriorityEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationPriorityEdge = {
  __typename?: 'InvestigationPriorityEdge';
  cursor: Scalars['String'];
  node: IApiInvestigationPriority;
};

export type IApiInvestigationPriorityFilter = {
  type?: Maybe<IApiInvestigationPriorityFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvestigationPriorityFilterType {
  Name = 'NAME'
}

export enum IApiInvestigationPriorityOrderBy {
  CreatedAt = 'CREATED_AT'
}

export enum IApiInvestigationReportStatus {
  ClientReview = 'CLIENT_REVIEW',
  Draft = 'DRAFT',
  EditorReview = 'EDITOR_REVIEW',
  Final = 'FINAL',
  ReadyForEditor = 'READY_FOR_EDITOR',
  TechReview = 'TECH_REVIEW',
  TechReview_2 = 'TECH_REVIEW_2'
}

export type IApiInvestigationRestriction = IApiNode & {
  __typename?: 'InvestigationRestriction';
  createdAt?: Maybe<Scalars['Date']>;
  CreatedBy?: Maybe<IApiUser>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  updatedAt?: Maybe<Scalars['Date']>;
  User?: Maybe<IApiUser>;
};

export type IApiInvestigationRestrictionConnection = {
  __typename?: 'InvestigationRestrictionConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationRestrictionEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationRestrictionEdge = {
  __typename?: 'InvestigationRestrictionEdge';
  cursor: Scalars['String'];
  node: IApiInvestigationRestriction;
};

export type IApiInvestigationRestrictionFilter = {
  type?: Maybe<IApiInvestigationRestrictionFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvestigationRestrictionFilterType {
  CountOnly = 'COUNT_ONLY',
  Investigation = 'INVESTIGATION',
  NefcoNumber = 'NEFCO_NUMBER',
  Search = 'SEARCH',
  User = 'USER'
}

export enum IApiInvestigationRestrictionOrderBy {
  CreatedAt = 'CREATED_AT',
  NefcoNumber = 'NEFCO_NUMBER'
}

export type IApiInvestigationRiskTypeMetricsNode = {
  __typename?: 'InvestigationRiskTypeMetricsNode';
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiInvestigationRole = IApiNode & {
  __typename?: 'InvestigationRole';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  PermissionRole?: Maybe<IApiPermissionRole>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiInvestigationRoleConnection = {
  __typename?: 'InvestigationRoleConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationRoleEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationRoleEdge = {
  __typename?: 'InvestigationRoleEdge';
  cursor: Scalars['String'];
  node: IApiInvestigationRole;
};

export type IApiInvestigationRoleFilter = {
  type?: Maybe<IApiInvestigationRoleFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvestigationRoleFilterType {
  Name = 'NAME'
}

export enum IApiInvestigationRoleNames {
  AdministrativeStaff = 'ADMINISTRATIVE_STAFF',
  AutoExport = 'AUTO_EXPORT',
  Billing = 'BILLING',
  Editor = 'EDITOR',
  ElectricalEngineer = 'ELECTRICAL_ENGINEER',
  FireProtectionEngineer = 'FIRE_PROTECTION_ENGINEER',
  HvacExpert = 'HVAC_EXPERT',
  Insured = 'INSURED',
  Investigator = 'INVESTIGATOR',
  Marketing = 'MARKETING',
  RegionalManager = 'REGIONAL_MANAGER',
  Sales = 'SALES',
  TechReviewer = 'TECH_REVIEWER'
}

export enum IApiInvestigationRoleOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiInvestigationSource = {
  __typename?: 'InvestigationSource';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type IApiInvestigationStaff = IApiNode & {
  __typename?: 'InvestigationStaff';
  clientRequested?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  Investigation: IApiInvestigation;
  isDefault?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  LatestOnSceneStatus?: Maybe<IApiInvestigationOnScene>;
  Role: IApiInvestigationRole;
  scheduledSceneExamDate?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  User: IApiUser;
};

export type IApiInvestigationStaffConnection = {
  __typename?: 'InvestigationStaffConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationStaffEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationStaffEdge = {
  __typename?: 'InvestigationStaffEdge';
  cursor: Scalars['String'];
  node: IApiInvestigationStaff;
};

export type IApiInvestigationStaffFilter = {
  type?: Maybe<IApiInvestigationStaffFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvestigationStaffFilterType {
  CountOnly = 'COUNT_ONLY',
  Investigation = 'INVESTIGATION',
  Investigator = 'INVESTIGATOR',
  Name = 'NAME',
  OnScene = 'ON_SCENE',
  OnSceneStatus = 'ON_SCENE_STATUS',
  RoleId = 'ROLE_ID',
  RoleName = 'ROLE_NAME',
  SceneExamState = 'SCENE_EXAM_STATE'
}

export enum IApiInvestigationStaffOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  NefcoNumber = 'NEFCO_NUMBER',
  OnSceneStatus = 'ON_SCENE_STATUS',
  ScheduledSceneExamDate = 'SCHEDULED_SCENE_EXAM_DATE'
}

export type IApiInvestigationStatus = IApiNode & {
  __typename?: 'InvestigationStatus';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  name?: Maybe<Scalars['String']>;
  requiresFollowUp?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  User?: Maybe<IApiUser>;
};

export type IApiInvestigationStatusConnection = {
  __typename?: 'InvestigationStatusConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationStatusEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationStatusEdge = {
  __typename?: 'InvestigationStatusEdge';
  cursor: Scalars['String'];
  node: IApiInvestigationStatus;
};

export type IApiInvestigationStatusFilter = {
  type?: Maybe<IApiInvestigationStatusFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvestigationStatusFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  Name = 'NAME',
  Own = 'OWN'
}

export enum IApiInvestigationStatusNames {
  Assigned = 'ASSIGNED',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  HoldForBilling = 'HOLD_FOR_BILLING',
  InProgress = 'IN_PROGRESS',
  Ongoing = 'ONGOING',
  OngoingPendingCriminalTrial = 'ONGOING_PENDING_CRIMINAL_TRIAL',
  OngoingPendingDisposition = 'ONGOING_PENDING_DISPOSITION',
  OngoingPendingEvidenceExam = 'ONGOING_PENDING_EVIDENCE_EXAM',
  OngoingPendingLitigation = 'ONGOING_PENDING_LITIGATION',
  OngoingPendingScheduling = 'ONGOING_PENDING_SCHEDULING',
  OngoingPendingSecondSceneExam = 'ONGOING_PENDING_SECOND_SCENE_EXAM',
  OngoingPendingThirdSceneExam = 'ONGOING_PENDING_THIRD_SCENE_EXAM',
  OngoingPendingTrial = 'ONGOING_PENDING_TRIAL',
  Pending = 'PENDING',
  ReadyToBill = 'READY_TO_BILL',
  ReadyToClose = 'READY_TO_CLOSE',
  Scheduled = 'SCHEDULED'
}

export enum IApiInvestigationStatusOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiInvestigationTimelog = {
  __typename?: 'InvestigationTimelog';
  id: Scalars['ID'];
  investigator?: Maybe<Scalars['String']>;
  nefcoNumber?: Maybe<Scalars['String']>;
  onSceneToNotesUploaded?: Maybe<Scalars['Float']>;
  onSceneToPhotosUploaded?: Maybe<Scalars['Float']>;
  receivedDate?: Maybe<Scalars['Date']>;
  techReviewer?: Maybe<Scalars['String']>;
  untilFirstMileageEntered?: Maybe<Scalars['Float']>;
  untilOnScene?: Maybe<Scalars['Float']>;
};

export type IApiInvestigationTimelogConnection = {
  __typename?: 'InvestigationTimelogConnection';
  edges?: Maybe<Array<Maybe<IApiInvestigationTimelogEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvestigationTimelogEdge = {
  __typename?: 'InvestigationTimelogEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvestigationTimelog>;
};

export enum IApiInvestigationUpdateCategories {
  Detail = 'DETAIL',
  Documents = 'DOCUMENTS',
  Evidence = 'EVIDENCE',
  Parties = 'PARTIES',
  Photo = 'PHOTO',
  Requests = 'REQUESTS',
  Services = 'SERVICES',
  Staff = 'STAFF',
  Vehicles = 'VEHICLES'
}

export enum IApiInvestigationUpdateTypes {
  Assign = 'ASSIGN',
  Create = 'CREATE',
  Delete = 'DELETE',
  Reactivate = 'REACTIVATE',
  Update = 'UPDATE'
}

export enum IApiInvestigationViewType {
  ListView = 'LIST_VIEW',
  OngoingView = 'ONGOING_VIEW'
}

export type IApiInvestigatorVerifyIvr = {
  __typename?: 'InvestigatorVerifyIVR';
  data?: Maybe<IApiInvestigatorVerifyIvrData>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiInvestigatorVerifyIvrCallInput = {
  action?: Maybe<Scalars['String']>;
  callDuration?: Maybe<Scalars['Int']>;
  called: Scalars['String'];
  caller: Scalars['String'];
  callSid: Scalars['String'];
  callStatus?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  digit?: Maybe<Scalars['String']>;
  direction: Scalars['String'];
  from: Scalars['String'];
  metadata?: Maybe<Scalars['String']>;
  to: Scalars['String'];
};

export type IApiInvestigatorVerifyIvrData = {
  __typename?: 'InvestigatorVerifyIVRData';
  investigations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  investigatorId?: Maybe<Scalars['ID']>;
};

export type IApiInvestigatorVerifyIvrInput = {
  callData: IApiInvestigatorVerifyIvrCallInput;
  number: Scalars['String'];
};

export type IApiInvoice = IApiNode & {
  __typename?: 'Invoice';
  billDate: Scalars['Date'];
  BillTo?: Maybe<IApiContact>;
  BillToBranch?: Maybe<IApiBranch>;
  createdAt: Scalars['Date'];
  createdBy: IApiUser;
  createdById?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  InvoiceCalculation?: Maybe<IApiInvoiceCalculation>;
  InvoiceLines?: Maybe<Array<Maybe<IApiInvoiceLine>>>;
  invoiceNumber?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  QbErrors?: Maybe<Array<Maybe<IApiInvoiceQbError>>>;
  qbExported?: Maybe<Scalars['Boolean']>;
  qbStatus?: Maybe<Scalars['String']>;
  qbTicketId?: Maybe<Scalars['String']>;
  status?: Maybe<IApiInvoiceStatus>;
  statusHistory?: Maybe<Array<Maybe<IApiInvoiceStatus>>>;
  updatedAt: Scalars['Date'];
};


export type IApiInvoiceStatusArgs = {
  name?: Maybe<Scalars['String']>;
};

export enum IApiInvoiceableTypeTypes {
  Evidence = 'EVIDENCE',
  Expense = 'EXPENSE',
  Hours = 'HOURS',
  Miles = 'MILES',
  TimeEntry = 'TIME_ENTRY',
  Tolls = 'TOLLS'
}

export type IApiInvoiceCalculation = IApiNode & {
  __typename?: 'InvoiceCalculation';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  subTotal?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  updatedAt: Scalars['Date'];
};

export type IApiInvoiceCalculationConnection = {
  __typename?: 'InvoiceCalculationConnection';
  edges?: Maybe<Array<Maybe<IApiInvoiceCalculationEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvoiceCalculationEdge = {
  __typename?: 'InvoiceCalculationEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvoiceCalculation>;
};

export type IApiInvoiceCalculationFilter = {
  type?: Maybe<IApiInvoiceCalculationFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvoiceCalculationFilterType {
  Invoice = 'INVOICE'
}

export type IApiInvoiceCalculationOrderBy = {
  type?: Maybe<IApiInvoiceCalculationOrderByType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvoiceCalculationOrderByType {
  CreatedAt = 'CREATED_AT'
}

export type IApiInvoiceConnection = {
  __typename?: 'InvoiceConnection';
  edges?: Maybe<Array<Maybe<IApiInvoiceEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvoiceEdge = {
  __typename?: 'InvoiceEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvoice>;
};

export type IApiInvoiceFilter = {
  type?: Maybe<IApiInvoiceFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvoiceFilterType {
  CreatedBy = 'CREATED_BY',
  FailedOrPending = 'FAILED_OR_PENDING',
  Investigation = 'INVESTIGATION',
  InvoiceDate = 'INVOICE_DATE',
  InvoiceNumber = 'INVOICE_NUMBER',
  NotSubmitted = 'NOT_SUBMITTED',
  QbTicketId = 'QB_TICKET_ID',
  Search = 'SEARCH',
  Status = 'STATUS',
  Unlinked = 'UNLINKED',
  Workdate = 'WORKDATE'
}

export type IApiInvoiceItem = IApiNode & {
  __typename?: 'InvoiceItem';
  createdAt: Scalars['Date'];
  Expense?: Maybe<IApiExpense>;
  id: Scalars['ID'];
  Invoice: IApiInvoice;
  Invoiceable?: Maybe<Scalars['JSON']>;
  invoiceableId: Scalars['ID'];
  invoiceableType?: Maybe<Scalars['String']>;
  InvoiceId: Scalars['ID'];
  qbAccount?: Maybe<Scalars['String']>;
  TimeEntry?: Maybe<IApiTimeEntry>;
  updatedAt: Scalars['Date'];
};

export type IApiInvoiceItemConnection = {
  __typename?: 'invoiceItemConnection';
  edges?: Maybe<Array<Maybe<IApiInvoiceItemEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvoiceItemEdge = {
  __typename?: 'InvoiceItemEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvoiceItem>;
};

export type IApiInvoiceItemFilter = {
  type?: Maybe<IApiInvoiceItemFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvoiceItemFilterType {
  Investigation = 'INVESTIGATION',
  Invoice = 'INVOICE',
  Staff = 'STAFF',
  Type = 'TYPE'
}

export type IApiInvoiceItemInput = {
  invoiceableId: Scalars['ID'];
  invoiceableType: IApiInvoiceableTypeTypes;
  invoiceId?: Maybe<Scalars['ID']>;
};

export type IApiInvoiceItemOrderBy = {
  type?: Maybe<IApiInvoiceItemOrderByType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvoiceItemOrderByType {
  CreatedAt = 'CREATED_AT'
}

export type IApiInvoiceLine = IApiNode & {
  __typename?: 'InvoiceLine';
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  expenseType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  Invoice?: Maybe<IApiInvoice>;
  InvoiceItem?: Maybe<IApiInvoiceItem>;
  qbAccount?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  rate: Scalars['Float'];
  staffNames?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiInvoiceLineConnection = {
  __typename?: 'InvoiceLineConnection';
  edges?: Maybe<Array<Maybe<IApiInvoiceLineEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvoiceLineEdge = {
  __typename?: 'InvoiceLineEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvoiceLine>;
};

export type IApiInvoiceLineFilter = {
  type?: Maybe<IApiInvoiceLineFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvoiceLineFilterType {
  Invoice = 'INVOICE'
}

export type IApiInvoiceLineInput = {
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  expenseType?: Maybe<Scalars['String']>;
  qbAccount?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  staffNames?: Maybe<Scalars['String']>;
};

export type IApiInvoiceLineOrderBy = {
  type?: Maybe<IApiInvoiceLineOrderByType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvoiceLineOrderByType {
  LineNumber = 'LINE_NUMBER'
}

export enum IApiInvoiceOrderBy {
  CreatedAt = 'CREATED_AT',
  InvoiceNumber = 'INVOICE_NUMBER',
  Status = 'STATUS'
}

export type IApiInvoiceQbError = {
  __typename?: 'InvoiceQbError';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  statusCode?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  statusSeverity?: Maybe<Scalars['String']>;
};

export type IApiInvoiceStatus = IApiNode & {
  __typename?: 'InvoiceStatus';
  billDate: Scalars['Date'];
  createdAt: Scalars['Date'];
  createdBy: IApiUser;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  Invoice?: Maybe<IApiInvoice>;
  name: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type IApiInvoiceStatusConnection = {
  __typename?: 'InvoiceStatusConnection';
  edges?: Maybe<Array<Maybe<IApiInvoiceStatusEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiInvoiceStatusEdge = {
  __typename?: 'InvoiceStatusEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiInvoiceStatus>;
};

export type IApiInvoiceStatusFilter = {
  type?: Maybe<IApiInvoiceStatusFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvoiceStatusFilterType {
  CreatedBy = 'CREATED_BY',
  Invoice = 'INVOICE',
  Status = 'STATUS'
}

export type IApiInvoiceStatusOrderBy = {
  type?: Maybe<IApiInvoiceStatusOrderByType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiInvoiceStatusOrderByType {
  CreatedAt = 'CREATED_AT'
}


export enum IApiMapMode {
  City = 'CITY',
  Individual = 'INDIVIDUAL',
  State = 'STATE'
}

export type IApiMarkAllExpensesAsPaid = {
  dateRange: Scalars['String'];
  isEntryDate: Scalars['Boolean'];
  type: Scalars['Int'];
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IApiMarkAllPaidTimeEntryInput = {
  paidDate: Scalars['Date'];
};

export type IApiMarkNotificationsAsReadOrUnreadInput = {
  ids: Array<Maybe<Scalars['ID']>>;
  viewed: Scalars['Boolean'];
};

export type IApiMarkSelectedExpensesAsPaid = {
  expenseIds: Array<Maybe<Scalars['ID']>>;
};

export type IApiMutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  abortMultipartUpload: Scalars['Boolean'];
  activateExpenseItem: Scalars['Boolean'];
  addAddress: IApiAddress;
  addAddressType: IApiAddressType;
  addBillingRule: IApiBillingRule;
  addBillingRuleCategory: IApiBillingRuleCategory;
  addBillingRuleType: IApiBillingRuleType;
  addBranch: IApiBranch;
  addCallHistoryIVR: Scalars['Boolean'];
  addCertification: IApiCertification;
  addCertificationAgency: IApiCertificationAgency;
  addCertificationType: IApiCertificationType;
  addComment: IApiComment;
  addCompany: IApiCompany;
  addCompanyType: IApiCompanyType;
  addContact: IApiContact;
  addContactRole: IApiContactRole;
  addDocument: IApiDocument;
  addDocumentApproval: IApiDocumentApproval;
  addDocumentCategory: IApiDocumentCategory;
  addDocumentCollection: IApiDocumentCollection;
  addDocumentSent: Scalars['Boolean'];
  addDocumentShare: IApiDocumentShare;
  addDocumentSnapshot: IApiDocumentSnapshot;
  addDocumentTemplate: IApiDocumentTemplate;
  addDocumentType: IApiDocumentType;
  addEmail: IApiEmail;
  addEmailType: IApiEmailType;
  addEvidence: IApiEvidence;
  addEvidenceBilling: IApiEvidenceBilling;
  addEvidenceBillingFrequency: IApiEvidenceBillingFrequency;
  addEvidenceStatus: IApiEvidenceStatus;
  addEvidenceStorageLocation: IApiEvidenceStorageLocation;
  addEvidenceTransfer: IApiEvidenceTransfer;
  addExpense: IApiExpense;
  addExpenseCategory: IApiExpenseCategory;
  addExpenseItem: IApiExpenseItem;
  addExpensePurpose: IApiExpensePurpose;
  addInsuredContacted: IApiInsuredContacted;
  addInsuredParty: IApiInsuredParty;
  addInvestigation: IApiInvestigation;
  addInvestigationDetail: IApiInvestigationDetail;
  addInvestigationMarkClose: IApiInvestigationMarkClose;
  addInvestigationOnScene: IApiInvestigationOnScene;
  addInvestigationParty: IApiInvestigationParty;
  addInvestigationPriority: IApiInvestigationPriority;
  addInvestigationRestriction: Array<Maybe<IApiInvestigationRestriction>>;
  addInvestigationRole: IApiInvestigationRole;
  addInvestigationStaff: IApiInvestigationStaff;
  addInvestigationStatus: IApiInvestigationStatus;
  addInvestigationV2: IApiInvestigation;
  addInvoice: Scalars['Boolean'];
  addNefcoOffice: IApiNefcoOffice;
  addNotificationMethodSetting: IApiNotificationMethodSetting;
  addPermissionRole: IApiPermissionRole;
  addPhone: IApiPhone;
  addPhoneType: IApiPhoneType;
  addPhoto: IApiPhoto;
  addPhotoBulk: Scalars['Boolean'];
  addPhotoFolder: IApiPhotoFolder;
  addPhotoThumbnail: IApiPhotoThumbnail;
  addRequest: IApiRequest;
  addRequestStatus: IApiRequestStatus;
  addRequestType: IApiRequestType;
  addService: IApiService;
  addServiceStatus: IApiServiceStatus;
  addServiceType: IApiServiceType;
  addSettings: IApiSettings;
  addShippingCarrier: IApiShippingCarrier;
  addSubService: IApiSubService;
  addSubServiceType: IApiSubServiceType;
  addTimeEntry: IApiTimeEntry;
  addTimeEntryType: IApiTimeEntryType;
  addUser: IApiUser;
  addUserDetail: IApiUserDetail;
  addUserSignature: IApiUserSignature;
  addVehicle: IApiVehicle;
  advanceReport: Scalars['Boolean'];
  approveTimeEntry: IApiTimeEntry;
  assignInvestigator: IApiInvestigationStaff;
  assignPermissionRolePermission: Scalars['Boolean'];
  assignPermissionRoleUser: Scalars['Boolean'];
  changeOnSceneStatus: Scalars['Boolean'];
  completeMultipartUpload: Scalars['Boolean'];
  createIntake: IApiIntake;
  createMultipartUpload: IApiCreateMultipartResponse;
  createReport: IApiDocument;
  createSignedUrl: IApiSignedUrlResponse;
  deactivateExpenseItem: Scalars['Boolean'];
  deleteNotification: Scalars['Boolean'];
  emailContact?: Maybe<Scalars['Boolean']>;
  expenseSubmitReport: Scalars['Boolean'];
  getUploadMultipartPartUrl: IApiSignedUrlResponse;
  investigationAssignmentSMS?: Maybe<IApiInvestigationAssignmentSms>;
  investigationAssignmentSMSTwilio: Scalars['Boolean'];
  investigationStatusChangeIVR: IApiInvestigatorVerifyIvr;
  investigatorVerifyIVR: IApiInvestigatorVerifyIvr;
  markAllAsPaid: Scalars['Boolean'];
  markAllPaid: Scalars['Boolean'];
  markInvestigationViewed: Scalars['Boolean'];
  markNotificationsAsReadOrUnread: Scalars['Boolean'];
  markSelectedAsPaid: Scalars['Boolean'];
  onSceneUpdate: IApiUser;
  positionPhoto?: Maybe<Scalars['Boolean']>;
  rejectContact: IApiRejectContactPayload;
  rejectNewInvestigation: Scalars['Boolean'];
  removeAddress: IApiRemoveAddressPayload;
  removeAddressType: Scalars['Boolean'];
  removeBillingRule: Scalars['Boolean'];
  removeBillingRuleCategory: Scalars['Boolean'];
  removeBillingRuleType: Scalars['Boolean'];
  removeBranch: Scalars['Boolean'];
  removeCertification: Scalars['Boolean'];
  removeCertificationAgency: IApiRemoveCertificationAgencyPayload;
  removeCertificationType: Scalars['Boolean'];
  removeComment: Scalars['Boolean'];
  removeCompany: Scalars['Boolean'];
  removeCompanyType: Scalars['Boolean'];
  removeContact: IApiRemoveContactPayload;
  removeContactRole: Scalars['Boolean'];
  removeDocument: Scalars['Boolean'];
  removeDocumentApproval: IApiRemoveDocumentApprovalPayload;
  removeDocumentCategory: Scalars['Boolean'];
  removeDocumentCollection: IApiRemoveDocumentCollectionPayload;
  removeDocumentShare: Scalars['Boolean'];
  removeDocumentSnapshot: IApiRemoveDocumentSnapshotPayload;
  removeDocumentTemplate: Scalars['Boolean'];
  removeDocumentType: Scalars['Boolean'];
  removeEmail: IApiRemoveEmailPayload;
  removeEmailType: Scalars['Boolean'];
  removeEvidence: Scalars['Boolean'];
  removeEvidenceBilling: Scalars['Boolean'];
  removeEvidenceBillingFrequency: IApiRemoveEvidenceBillingFrequencyPayload;
  removeEvidenceStatus: IApiRemoveEvidenceStatusPayload;
  removeEvidenceStorageLocation: Scalars['Boolean'];
  removeEvidenceTransfer: Scalars['Boolean'];
  removeExpense: Scalars['Boolean'];
  removeExpenseCategory: Scalars['Boolean'];
  removeExpenseItem: Scalars['Boolean'];
  removeExpensePurpose: Scalars['Boolean'];
  removeInvestigation: Scalars['Boolean'];
  removeInvestigationDetail: IApiRemoveInvestigationDetailPayload;
  removeInvestigationParty: Scalars['Boolean'];
  removeInvestigationPriority: IApiRemoveInvestigationPriorityPayload;
  removeInvestigationRestriction: IApiRemoveInvestigationRestrictionPayload;
  removeInvestigationRole: IApiRemoveInvestigationRolePayload;
  removeInvestigationStaff: Scalars['Boolean'];
  removeInvestigationStatus: IApiRemoveInvestigationStatusPayload;
  removeInvoice: Scalars['Boolean'];
  removeNefcoOffice: Scalars['Boolean'];
  removeNotificationMethodSetting: Scalars['Boolean'];
  removePermissionRole: Scalars['Boolean'];
  removePermissionRolePermission: Scalars['Boolean'];
  removePermissionRoleUser: Scalars['Boolean'];
  removePhone: IApiRemovePhonePayload;
  removePhoneType: Scalars['Boolean'];
  removePhoto: Scalars['Boolean'];
  removePhotoBulk: Scalars['Boolean'];
  removePhotoFolder: Scalars['Boolean'];
  removePhotoThumbnail: Scalars['Boolean'];
  removeRequest: Scalars['Boolean'];
  removeRequestStatus: Scalars['Boolean'];
  removeRequestType: Scalars['Boolean'];
  removeService: Scalars['Boolean'];
  removeServiceStatus: Scalars['Boolean'];
  removeServiceType: Scalars['Boolean'];
  removeSettings: Scalars['Boolean'];
  removeShippingCarrier: Scalars['Boolean'];
  removeSubService: Scalars['Boolean'];
  removeSubServiceType: Scalars['Boolean'];
  removeTimeEntry: Scalars['Boolean'];
  removeTimeEntryType: Scalars['Boolean'];
  removeUser: IApiRemoveUserPayload;
  removeUserDetail: Scalars['Boolean'];
  removeVehicle: Scalars['Boolean'];
  replaceLowResPhotos?: Maybe<Scalars['Boolean']>;
  restoreInvestigation: Scalars['Boolean'];
  resubmitInvoice: Scalars['Boolean'];
  sendSMSToInvestigator: IApiSendSmsToInvestigator;
  toggleEvidenceStorageLocationActivation: Scalars['Boolean'];
  toggleExpensePurposeActivation: Scalars['Boolean'];
  toggleTimeEntryTypeActivation: Scalars['Boolean'];
  updateAddress: IApiAddress;
  updateAddressType: IApiAddressType;
  updateAndReviewContact: IApiContact;
  updateAssignment?: Maybe<IApiContactBranchAssignment>;
  updateBillingRule: IApiBillingRule;
  updateBillingRuleCategory: IApiBillingRuleCategory;
  updateBillingRuleType: IApiBillingRuleType;
  updateBranch: IApiBranch;
  updateCertification: IApiCertification;
  updateCertificationAgency: IApiCertificationAgency;
  updateCertificationType: IApiCertificationType;
  updateComment: IApiComment;
  updateCompany: IApiCompany;
  updateCompanyByInvoice: Scalars['Boolean'];
  updateCompanyType: IApiCompanyType;
  updateContact: IApiContact;
  updateContactByInvoice: Scalars['Boolean'];
  updateContactRole: IApiContactRole;
  updateDocument: IApiDocument;
  updateDocumentApproval: IApiDocumentApproval;
  updateDocumentCategory: IApiDocumentCategory;
  updateDocumentCollection: IApiDocumentCollection;
  updateDocumentShare: IApiDocumentShare;
  updateDocumentSnapshot: IApiDocumentSnapshot;
  updateDocumentTemplate: IApiDocumentTemplate;
  updateDocumentType: IApiDocumentType;
  updateDownloadZipUriHook: Scalars['Boolean'];
  updateEmail: IApiEmail;
  updateEmailType: IApiEmailType;
  updateEvidence: IApiEvidence;
  updateEvidenceBilling: IApiEvidenceBilling;
  updateEvidenceBillingFrequency: IApiEvidenceBillingFrequency;
  updateEvidenceStatus: IApiEvidenceStatus;
  updateEvidenceStorageLocation: IApiEvidenceStorageLocation;
  updateEvidenceTransfer: IApiEvidenceTransfer;
  updateExpense: IApiExpense;
  updateExpenseCategory: IApiExpenseCategory;
  updateExpenseItem: IApiExpenseItem;
  updateExpensePurpose: IApiExpensePurpose;
  updateInsuredParty: IApiInsuredParty;
  updateInvestigation: IApiInvestigation;
  updateInvestigationDetail: IApiInvestigationDetail;
  updateInvestigationOnScene: IApiInvestigationOnScene;
  updateInvestigationOnSceneScheduledDate: Scalars['Boolean'];
  updateInvestigationParty: IApiInvestigationParty;
  updateInvestigationPriority: IApiInvestigationPriority;
  updateInvestigationRole: IApiInvestigationRole;
  updateInvestigationStaff: IApiInvestigationStaff;
  updateInvestigationStaffScheduledDate: Scalars['Boolean'];
  updateInvestigationStatus: IApiInvestigationStatus;
  updateInvoice: Scalars['Boolean'];
  updateNefcoOffice: IApiNefcoOffice;
  updateNefcoOfficeQB: Scalars['Boolean'];
  updateNotification: IApiNotification;
  updateNotificationMethodSetting: IApiNotificationMethodSetting;
  updateNotificationMethodSettingV2: Scalars['Boolean'];
  updatePermissionRole: IApiPermissionRole;
  updatePhone: IApiPhone;
  updatePhoneType: IApiPhoneType;
  updatePhoto: IApiPhoto;
  updatePhotoBulk: Scalars['Boolean'];
  updatePhotoFolder: IApiPhotoFolder;
  updatePhotoThumbnail: IApiPhotoThumbnail;
  updateProgress: Scalars['Boolean'];
  updateRequest: IApiRequest;
  updateRequestStatus: IApiRequestStatus;
  updateRequestType: IApiRequestType;
  updateService: IApiService;
  updateServiceStatus: IApiServiceStatus;
  updateServiceType: IApiServiceType;
  updateSettings: IApiSettings;
  updateShippingCarrier: IApiShippingCarrier;
  updateSubService: IApiSubService;
  updateSubServiceType: IApiSubServiceType;
  updateTimeEntry: IApiTimeEntry;
  updateTimeEntryType: IApiTimeEntryType;
  updateUser: IApiUser;
  updateUserDetail: IApiUserDetail;
  updateUserProfile: IApiUser;
  updateVehicle: IApiVehicle;
  uploadPhotoThumbnail: IApiPhotoThumbnail;
};


export type IApiMutationAbortMultipartUploadArgs = {
  input: IApiAbortMultipartUploadInput;
};


export type IApiMutationActivateExpenseItemArgs = {
  id: Scalars['ID'];
};


export type IApiMutationAddAddressArgs = {
  input: IApiAddAddressInput;
};


export type IApiMutationAddAddressTypeArgs = {
  input: IApiAddAddressTypeInput;
};


export type IApiMutationAddBillingRuleArgs = {
  input: IApiAddBillingRuleInput;
};


export type IApiMutationAddBillingRuleCategoryArgs = {
  input: IApiAddBillingRuleCategoryInput;
};


export type IApiMutationAddBillingRuleTypeArgs = {
  input: IApiAddBillingRuleTypeInput;
};


export type IApiMutationAddBranchArgs = {
  input: IApiAddBranchInput;
};


export type IApiMutationAddCallHistoryIvrArgs = {
  input: IApiInvestigatorVerifyIvrCallInput;
};


export type IApiMutationAddCertificationArgs = {
  input: IApiAddCertificationInput;
};


export type IApiMutationAddCertificationAgencyArgs = {
  input: IApiAddCertificationAgencyInput;
};


export type IApiMutationAddCertificationTypeArgs = {
  input: IApiAddCertificationTypeInput;
};


export type IApiMutationAddCommentArgs = {
  input: IApiAddCommentInput;
};


export type IApiMutationAddCompanyArgs = {
  input: IApiAddCompanyInput;
};


export type IApiMutationAddCompanyTypeArgs = {
  input: IApiAddCompanyTypeInput;
};


export type IApiMutationAddContactArgs = {
  input: IApiAddContactInput;
};


export type IApiMutationAddContactRoleArgs = {
  input: IApiAddContactRoleInput;
};


export type IApiMutationAddDocumentArgs = {
  input: IApiAddDocumentInput;
};


export type IApiMutationAddDocumentApprovalArgs = {
  input: IApiAddDocumentApprovalInput;
};


export type IApiMutationAddDocumentCategoryArgs = {
  input: IApiAddDocumentCategoryInput;
};


export type IApiMutationAddDocumentCollectionArgs = {
  input: IApiAddDocumentCollectionInput;
};


export type IApiMutationAddDocumentSentArgs = {
  input: IApiAddDocumentSentInput;
};


export type IApiMutationAddDocumentShareArgs = {
  input: IApiAddDocumentShareInput;
};


export type IApiMutationAddDocumentSnapshotArgs = {
  input: IApiAddDocumentSnapshotInput;
};


export type IApiMutationAddDocumentTemplateArgs = {
  input: IApiAddDocumentTemplateInput;
};


export type IApiMutationAddDocumentTypeArgs = {
  input: IApiAddDocumentTypeInput;
};


export type IApiMutationAddEmailArgs = {
  input: IApiAddEmailInput;
};


export type IApiMutationAddEmailTypeArgs = {
  input: IApiAddEmailTypeInput;
};


export type IApiMutationAddEvidenceArgs = {
  input: IApiAddEvidenceInput;
};


export type IApiMutationAddEvidenceBillingArgs = {
  input: IApiAddEvidenceBillingInput;
};


export type IApiMutationAddEvidenceBillingFrequencyArgs = {
  input: IApiAddEvidenceBillingFrequencyInput;
};


export type IApiMutationAddEvidenceStatusArgs = {
  input: IApiAddEvidenceStatusInput;
};


export type IApiMutationAddEvidenceStorageLocationArgs = {
  input: IApiAddEvidenceStorageLocationInput;
};


export type IApiMutationAddEvidenceTransferArgs = {
  input: IApiAddEvidenceTransferInput;
};


export type IApiMutationAddExpenseArgs = {
  input: IApiAddExpenseInput;
};


export type IApiMutationAddExpenseCategoryArgs = {
  input: IApiAddExpenseCategoryInput;
};


export type IApiMutationAddExpenseItemArgs = {
  input: IApiAddExpenseItemInput;
};


export type IApiMutationAddExpensePurposeArgs = {
  input: IApiAddExpensePurposeInput;
};


export type IApiMutationAddInsuredContactedArgs = {
  input?: Maybe<IApiAddInsuredContactedInput>;
};


export type IApiMutationAddInsuredPartyArgs = {
  input?: Maybe<IApiAddInsuredPartyInput>;
};


export type IApiMutationAddInvestigationArgs = {
  input: IApiAddInvestigationInput;
};


export type IApiMutationAddInvestigationDetailArgs = {
  input: IApiAddInvestigationDetailInput;
};


export type IApiMutationAddInvestigationMarkCloseArgs = {
  input: IApiAddInvestigationMarkCloseInput;
};


export type IApiMutationAddInvestigationOnSceneArgs = {
  input: IApiAddInvestigationOnSceneInput;
};


export type IApiMutationAddInvestigationPartyArgs = {
  input: IApiAddInvestigationPartyInput;
};


export type IApiMutationAddInvestigationPriorityArgs = {
  input: IApiAddInvestigationPriorityInput;
};


export type IApiMutationAddInvestigationRestrictionArgs = {
  input: IApiAddMultipleInvestigationRestrictionInput;
};


export type IApiMutationAddInvestigationRoleArgs = {
  input: IApiAddInvestigationRoleInput;
};


export type IApiMutationAddInvestigationStaffArgs = {
  input: IApiAddInvestigationStaffInput;
};


export type IApiMutationAddInvestigationStatusArgs = {
  input: IApiAddInvestigationStatusInput;
};


export type IApiMutationAddInvestigationV2Args = {
  input: IApiAddInvestigationV2Input;
};


export type IApiMutationAddInvoiceArgs = {
  input: IApiAddInvoiceInput;
};


export type IApiMutationAddNefcoOfficeArgs = {
  input: IApiAddNefcoOfficeInput;
};


export type IApiMutationAddNotificationMethodSettingArgs = {
  input: IApiAddNotificationMethodSettingInput;
};


export type IApiMutationAddPermissionRoleArgs = {
  input: IApiAddPermissionRoleInput;
};


export type IApiMutationAddPhoneArgs = {
  input: IApiAddPhoneInput;
};


export type IApiMutationAddPhoneTypeArgs = {
  input: IApiAddPhoneTypeInput;
};


export type IApiMutationAddPhotoArgs = {
  input: IApiAddPhotoInput;
};


export type IApiMutationAddPhotoBulkArgs = {
  input: Array<Maybe<IApiAddPhotoInput>>;
};


export type IApiMutationAddPhotoFolderArgs = {
  input: IApiAddPhotoFolderInput;
};


export type IApiMutationAddPhotoThumbnailArgs = {
  input: IApiAddPhotoThumbnailInput;
};


export type IApiMutationAddRequestArgs = {
  input: IApiAddRequestInput;
};


export type IApiMutationAddRequestStatusArgs = {
  input: IApiAddRequestStatusInput;
};


export type IApiMutationAddRequestTypeArgs = {
  input: IApiAddRequestTypeInput;
};


export type IApiMutationAddServiceArgs = {
  input: IApiAddServiceInput;
};


export type IApiMutationAddServiceStatusArgs = {
  input: IApiAddServiceStatusInput;
};


export type IApiMutationAddServiceTypeArgs = {
  input: IApiAddServiceTypeInput;
};


export type IApiMutationAddSettingsArgs = {
  input: IApiAddSettingsInput;
};


export type IApiMutationAddShippingCarrierArgs = {
  input: IApiAddShippingCarrierInput;
};


export type IApiMutationAddSubServiceArgs = {
  input: IApiAddSubServiceInput;
};


export type IApiMutationAddSubServiceTypeArgs = {
  input: IApiAddSubServiceTypeInput;
};


export type IApiMutationAddTimeEntryArgs = {
  input: IApiAddTimeEntryInput;
};


export type IApiMutationAddTimeEntryTypeArgs = {
  input: IApiAddTimeEntryTypeInput;
};


export type IApiMutationAddUserArgs = {
  input: IApiAddUserInput;
};


export type IApiMutationAddUserDetailArgs = {
  input: IApiAddUserDetailInput;
};


export type IApiMutationAddUserSignatureArgs = {
  input: IApiUserSignatureInput;
};


export type IApiMutationAddVehicleArgs = {
  input: IApiAddVehicleInput;
};


export type IApiMutationAdvanceReportArgs = {
  input: IApiAdvanceInvestigationReportInput;
};


export type IApiMutationApproveTimeEntryArgs = {
  input: IApiApproveTimeEntryInput;
};


export type IApiMutationAssignInvestigatorArgs = {
  input: IApiAssignInvestigatorInput;
};


export type IApiMutationAssignPermissionRolePermissionArgs = {
  input: IApiAssignPermissionRolePermissionInput;
};


export type IApiMutationAssignPermissionRoleUserArgs = {
  input: IApiAssignPermissionRoleUserInput;
};


export type IApiMutationChangeOnSceneStatusArgs = {
  input: IApiChangeOnSceneStatusInput;
};


export type IApiMutationCompleteMultipartUploadArgs = {
  input: IApiCompleteMultipartUploadInput;
};


export type IApiMutationCreateIntakeArgs = {
  input: IApiAddIntakeInput;
};


export type IApiMutationCreateMultipartUploadArgs = {
  input: IApiS3FileInput;
};


export type IApiMutationCreateReportArgs = {
  input: IApiCreateInvestigationReportInput;
};


export type IApiMutationCreateSignedUrlArgs = {
  input: IApiS3FileInput;
};


export type IApiMutationDeactivateExpenseItemArgs = {
  id: Scalars['ID'];
};


export type IApiMutationDeleteNotificationArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiMutationEmailContactArgs = {
  fileInput?: Maybe<Scalars['Upload']>;
  input?: Maybe<IApiEmailContactInput>;
};


export type IApiMutationExpenseSubmitReportArgs = {
  input?: Maybe<IApiExpenseSubmitReport>;
};


export type IApiMutationGetUploadMultipartPartUrlArgs = {
  input: IApiUploadMultipartPartUrlInput;
};


export type IApiMutationInvestigationAssignmentSmsArgs = {
  input: IApiInvestigationAssignmentSmsInput;
};


export type IApiMutationInvestigationAssignmentSmsTwilioArgs = {
  input: IApiInvestigationAssignmentSmsTwilioInput;
};


export type IApiMutationInvestigationStatusChangeIvrArgs = {
  input: IApiInvestigatorVerifyIvrCallInput;
};


export type IApiMutationInvestigatorVerifyIvrArgs = {
  input: IApiInvestigatorVerifyIvrInput;
};


export type IApiMutationMarkAllAsPaidArgs = {
  input?: Maybe<IApiMarkAllExpensesAsPaid>;
};


export type IApiMutationMarkAllPaidArgs = {
  input: IApiMarkAllPaidTimeEntryInput;
};


export type IApiMutationMarkInvestigationViewedArgs = {
  id: Scalars['ID'];
};


export type IApiMutationMarkNotificationsAsReadOrUnreadArgs = {
  input: IApiMarkNotificationsAsReadOrUnreadInput;
};


export type IApiMutationMarkSelectedAsPaidArgs = {
  input?: Maybe<IApiMarkSelectedExpensesAsPaid>;
};


export type IApiMutationOnSceneUpdateArgs = {
  input: IApiUpdateUserOnSceneInput;
};


export type IApiMutationPositionPhotoArgs = {
  input: IApiPositionPhotoInput;
};


export type IApiMutationRejectContactArgs = {
  input: IApiRejectContactInput;
};


export type IApiMutationRejectNewInvestigationArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveAddressArgs = {
  input: IApiRemoveAddressInput;
};


export type IApiMutationRemoveAddressTypeArgs = {
  input: Scalars['ID'];
};


export type IApiMutationRemoveBillingRuleArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveBillingRuleCategoryArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveBillingRuleTypeArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveBranchArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveCertificationArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveCertificationAgencyArgs = {
  input: IApiRemoveCertificationAgencyInput;
};


export type IApiMutationRemoveCertificationTypeArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveCommentArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveCompanyArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveCompanyTypeArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveContactArgs = {
  input: IApiRemoveContactInput;
};


export type IApiMutationRemoveContactRoleArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveDocumentArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveDocumentApprovalArgs = {
  input: IApiRemoveDocumentApprovalInput;
};


export type IApiMutationRemoveDocumentCategoryArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveDocumentCollectionArgs = {
  input: IApiRemoveDocumentCollectionInput;
};


export type IApiMutationRemoveDocumentShareArgs = {
  input: Scalars['ID'];
};


export type IApiMutationRemoveDocumentSnapshotArgs = {
  input: IApiRemoveDocumentSnapshotInput;
};


export type IApiMutationRemoveDocumentTemplateArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveDocumentTypeArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveEmailArgs = {
  input: IApiRemoveEmailInput;
};


export type IApiMutationRemoveEmailTypeArgs = {
  input: Scalars['ID'];
};


export type IApiMutationRemoveEvidenceArgs = {
  input: IApiRemoveEvidenceInput;
};


export type IApiMutationRemoveEvidenceBillingArgs = {
  input: IApiRemoveEvidenceBillingInput;
};


export type IApiMutationRemoveEvidenceBillingFrequencyArgs = {
  input: IApiRemoveEvidenceBillingFrequencyInput;
};


export type IApiMutationRemoveEvidenceStatusArgs = {
  input: IApiRemoveEvidenceStatusInput;
};


export type IApiMutationRemoveEvidenceStorageLocationArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveEvidenceTransferArgs = {
  input: Scalars['ID'];
};


export type IApiMutationRemoveExpenseArgs = {
  input: Scalars['ID'];
};


export type IApiMutationRemoveExpenseCategoryArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveExpenseItemArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveExpensePurposeArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveInvestigationArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveInvestigationDetailArgs = {
  input: IApiRemoveInvestigationDetailInput;
};


export type IApiMutationRemoveInvestigationPartyArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveInvestigationPriorityArgs = {
  input: IApiRemoveInvestigationPriorityInput;
};


export type IApiMutationRemoveInvestigationRestrictionArgs = {
  input: IApiRemoveMultipleInvestigationRestrictionInput;
};


export type IApiMutationRemoveInvestigationRoleArgs = {
  input: IApiRemoveInvestigationRoleInput;
};


export type IApiMutationRemoveInvestigationStaffArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveInvestigationStatusArgs = {
  input: IApiRemoveInvestigationStatusInput;
};


export type IApiMutationRemoveInvoiceArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveNefcoOfficeArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveNotificationMethodSettingArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemovePermissionRoleArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemovePermissionRolePermissionArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemovePermissionRoleUserArgs = {
  input: IApiRemovePermissionRoleUserInput;
};


export type IApiMutationRemovePhoneArgs = {
  input: IApiRemovePhoneInput;
};


export type IApiMutationRemovePhoneTypeArgs = {
  input: Scalars['ID'];
};


export type IApiMutationRemovePhotoArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemovePhotoBulkArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};


export type IApiMutationRemovePhotoFolderArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemovePhotoThumbnailArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveRequestArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveRequestStatusArgs = {
  input: IApiRemoveRequestStatusInput;
};


export type IApiMutationRemoveRequestTypeArgs = {
  input: IApiRemoveRequestTypeInput;
};


export type IApiMutationRemoveServiceArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveServiceStatusArgs = {
  input: IApiRemoveServiceStatusInput;
};


export type IApiMutationRemoveServiceTypeArgs = {
  input: IApiRemoveServiceTypeInput;
};


export type IApiMutationRemoveSettingsArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveShippingCarrierArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveSubServiceArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveSubServiceTypeArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveTimeEntryArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveTimeEntryTypeArgs = {
  id: Scalars['ID'];
};


export type IApiMutationRemoveUserArgs = {
  input: IApiRemoveUserInput;
};


export type IApiMutationRemoveUserDetailArgs = {
  input: IApiRemoveUserDetailInput;
};


export type IApiMutationRemoveVehicleArgs = {
  id: Scalars['ID'];
};


export type IApiMutationReplaceLowResPhotosArgs = {
  input: IApiReplaceLowResPhotosInput;
};


export type IApiMutationRestoreInvestigationArgs = {
  id: Scalars['ID'];
};


export type IApiMutationResubmitInvoiceArgs = {
  id: Scalars['ID'];
};


export type IApiMutationSendSmsToInvestigatorArgs = {
  input: IApiSendSmsToInvestigatorInput;
};


export type IApiMutationToggleEvidenceStorageLocationActivationArgs = {
  id: Scalars['ID'];
};


export type IApiMutationToggleExpensePurposeActivationArgs = {
  id: Scalars['ID'];
};


export type IApiMutationToggleTimeEntryTypeActivationArgs = {
  id: Scalars['ID'];
};


export type IApiMutationUpdateAddressArgs = {
  input: IApiUpdateAddressInput;
};


export type IApiMutationUpdateAddressTypeArgs = {
  input: IApiUpdateAddressTypeInput;
};


export type IApiMutationUpdateAndReviewContactArgs = {
  input: IApiUpdateAndReviewContactInput;
};


export type IApiMutationUpdateAssignmentArgs = {
  input: IApiUpdateContactBranchAssignmentInput;
};


export type IApiMutationUpdateBillingRuleArgs = {
  input: IApiUpdateBillingRuleInput;
};


export type IApiMutationUpdateBillingRuleCategoryArgs = {
  input: IApiUpdateBillingRuleCategoryInput;
};


export type IApiMutationUpdateBillingRuleTypeArgs = {
  input: IApiUpdateBillingRuleTypeInput;
};


export type IApiMutationUpdateBranchArgs = {
  input: IApiUpdateBranchInput;
};


export type IApiMutationUpdateCertificationArgs = {
  input: IApiUpdateCertificationInput;
};


export type IApiMutationUpdateCertificationAgencyArgs = {
  input: IApiUpdateCertificationAgencyInput;
};


export type IApiMutationUpdateCertificationTypeArgs = {
  input: IApiUpdateCertificationTypeInput;
};


export type IApiMutationUpdateCommentArgs = {
  input: IApiUpdateCommentInput;
};


export type IApiMutationUpdateCompanyArgs = {
  input: IApiUpdateCompanyInput;
};


export type IApiMutationUpdateCompanyByInvoiceArgs = {
  input: IApiUpdateCompanyByInvoiceInput;
};


export type IApiMutationUpdateCompanyTypeArgs = {
  input: IApiUpdateCompanyTypeInput;
};


export type IApiMutationUpdateContactArgs = {
  input: IApiUpdateContactInput;
};


export type IApiMutationUpdateContactByInvoiceArgs = {
  input: IApiUpdateContactByInvoiceInput;
};


export type IApiMutationUpdateContactRoleArgs = {
  input: IApiUpdateContactRoleInput;
};


export type IApiMutationUpdateDocumentArgs = {
  input: IApiUpdateDocumentInput;
};


export type IApiMutationUpdateDocumentApprovalArgs = {
  input: IApiUpdateDocumentApprovalInput;
};


export type IApiMutationUpdateDocumentCategoryArgs = {
  input: IApiUpdateDocumentCategoryInput;
};


export type IApiMutationUpdateDocumentCollectionArgs = {
  input: IApiUpdateDocumentCollectionInput;
};


export type IApiMutationUpdateDocumentShareArgs = {
  input: IApiUpdateDocumentShareInput;
};


export type IApiMutationUpdateDocumentSnapshotArgs = {
  input: IApiUpdateDocumentSnapshotInput;
};


export type IApiMutationUpdateDocumentTemplateArgs = {
  input: IApiUpdateDocumentTemplateInput;
};


export type IApiMutationUpdateDocumentTypeArgs = {
  input: IApiUpdateDocumentTypeInput;
};


export type IApiMutationUpdateDownloadZipUriHookArgs = {
  input: IApiUpdateDownloadZipUriHookInput;
};


export type IApiMutationUpdateEmailArgs = {
  input: IApiUpdateEmailInput;
};


export type IApiMutationUpdateEmailTypeArgs = {
  input: IApiUpdateEmailTypeInput;
};


export type IApiMutationUpdateEvidenceArgs = {
  input: IApiUpdateEvidenceInput;
};


export type IApiMutationUpdateEvidenceBillingArgs = {
  input: IApiUpdateEvidenceBillingInput;
};


export type IApiMutationUpdateEvidenceBillingFrequencyArgs = {
  input: IApiUpdateEvidenceBillingFrequencyInput;
};


export type IApiMutationUpdateEvidenceStatusArgs = {
  input: IApiUpdateEvidenceStatusInput;
};


export type IApiMutationUpdateEvidenceStorageLocationArgs = {
  input: IApiUpdateEvidenceStorageLocationInput;
};


export type IApiMutationUpdateEvidenceTransferArgs = {
  input: IApiUpdateEvidenceTransferInput;
};


export type IApiMutationUpdateExpenseArgs = {
  input: IApiUpdateExpenseInput;
};


export type IApiMutationUpdateExpenseCategoryArgs = {
  input: IApiUpdateExpenseCategoryInput;
};


export type IApiMutationUpdateExpenseItemArgs = {
  input?: Maybe<IApiUpdateExpenseItemInput>;
};


export type IApiMutationUpdateExpensePurposeArgs = {
  input: IApiUpdateExpensePurposeInput;
};


export type IApiMutationUpdateInsuredPartyArgs = {
  input: IApiUpdateInsuredPartyInput;
};


export type IApiMutationUpdateInvestigationArgs = {
  input: IApiUpdateInvestigationInput;
};


export type IApiMutationUpdateInvestigationDetailArgs = {
  input: IApiUpdateInvestigationDetailInput;
};


export type IApiMutationUpdateInvestigationOnSceneArgs = {
  input: IApiUpdateInvestigationOnSceneInput;
};


export type IApiMutationUpdateInvestigationOnSceneScheduledDateArgs = {
  input: IApiUpdateInvestigationOnSceneScheduledDate;
};


export type IApiMutationUpdateInvestigationPartyArgs = {
  input: IApiUpdateInvestigationPartyInput;
};


export type IApiMutationUpdateInvestigationPriorityArgs = {
  input: IApiUpdateInvestigationPriorityInput;
};


export type IApiMutationUpdateInvestigationRoleArgs = {
  input: IApiUpdateInvestigationRoleInput;
};


export type IApiMutationUpdateInvestigationStaffArgs = {
  input: IApiUpdateInvestigationStaffInput;
};


export type IApiMutationUpdateInvestigationStaffScheduledDateArgs = {
  input: IApiUpdateScheduledDateInput;
};


export type IApiMutationUpdateInvestigationStatusArgs = {
  input: IApiUpdateInvestigationStatusInput;
};


export type IApiMutationUpdateInvoiceArgs = {
  input: IApiUpdateInvoiceInput;
};


export type IApiMutationUpdateNefcoOfficeArgs = {
  input: IApiUpdateNefcoOfficeInput;
};


export type IApiMutationUpdateNefcoOfficeQbArgs = {
  input: IApiUpdateNefcoOfficeFromQbInput;
};


export type IApiMutationUpdateNotificationArgs = {
  input: IApiUpdateNotificationInput;
};


export type IApiMutationUpdateNotificationMethodSettingArgs = {
  input: IApiUpdateNotificationMethodSettingInput;
};


export type IApiMutationUpdateNotificationMethodSettingV2Args = {
  input: IApiUpdateNotificationMethodSettingInputV2;
};


export type IApiMutationUpdatePermissionRoleArgs = {
  input: IApiUpdatePermissionRoleInput;
};


export type IApiMutationUpdatePhoneArgs = {
  input: IApiUpdatePhoneInput;
};


export type IApiMutationUpdatePhoneTypeArgs = {
  input: IApiUpdatePhoneTypeInput;
};


export type IApiMutationUpdatePhotoArgs = {
  input: IApiUpdatePhotoInput;
};


export type IApiMutationUpdatePhotoBulkArgs = {
  input: Array<Maybe<IApiUpdatePhotoInput>>;
};


export type IApiMutationUpdatePhotoFolderArgs = {
  input: IApiUpdatePhotoFolderInput;
};


export type IApiMutationUpdatePhotoThumbnailArgs = {
  input: IApiUpdatePhotoThumbnailInput;
};


export type IApiMutationUpdateProgressArgs = {
  input: IApiUpdateProgressInput;
};


export type IApiMutationUpdateRequestArgs = {
  input: IApiUpdateRequestInput;
};


export type IApiMutationUpdateRequestStatusArgs = {
  input: IApiUpdateRequestStatusInput;
};


export type IApiMutationUpdateRequestTypeArgs = {
  input: IApiUpdateRequestTypeInput;
};


export type IApiMutationUpdateServiceArgs = {
  input: IApiUpdateServiceInput;
};


export type IApiMutationUpdateServiceStatusArgs = {
  input: IApiUpdateServiceStatusInput;
};


export type IApiMutationUpdateServiceTypeArgs = {
  input: IApiUpdateServiceTypeInput;
};


export type IApiMutationUpdateSettingsArgs = {
  input: IApiUpdateSettingsInput;
};


export type IApiMutationUpdateShippingCarrierArgs = {
  input: IApiUpdateShippingCarrierInput;
};


export type IApiMutationUpdateSubServiceArgs = {
  input: IApiUpdateSubServiceInput;
};


export type IApiMutationUpdateSubServiceTypeArgs = {
  input: IApiUpdateSubServiceTypeInput;
};


export type IApiMutationUpdateTimeEntryArgs = {
  input: IApiUpdateTimeEntryInput;
};


export type IApiMutationUpdateTimeEntryTypeArgs = {
  input: IApiUpdateTimeEntryTypeInput;
};


export type IApiMutationUpdateUserArgs = {
  input: IApiUpdateUserInput;
};


export type IApiMutationUpdateUserDetailArgs = {
  input: IApiUpdateUserDetailInput;
};


export type IApiMutationUpdateUserProfileArgs = {
  input: IApiUpdateUserProfileInput;
};


export type IApiMutationUpdateVehicleArgs = {
  input: IApiUpdateVehicleInput;
};


export type IApiMutationUploadPhotoThumbnailArgs = {
  fileInput?: Maybe<Scalars['Upload']>;
  input?: Maybe<IApiAddPhotoThumbnailInput>;
};

export type IApiNefcoOffice = IApiNode & {
  __typename?: 'NefcoOffice';
  Address?: Maybe<IApiAddress>;
  AssignedInvestigationCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  qbListId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiNefcoOfficeConnection = {
  __typename?: 'NefcoOfficeConnection';
  edges?: Maybe<Array<Maybe<IApiNefcoOfficeEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiNefcoOfficeEdge = {
  __typename?: 'NefcoOfficeEdge';
  cursor: Scalars['String'];
  node: IApiNefcoOffice;
};

export type IApiNefcoOfficeFilter = {
  type?: Maybe<IApiNefcoOfficeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiNefcoOfficeFilterType {
  IsActive = 'IS_ACTIVE',
  ViewOnlyNameAndActive = 'VIEW_ONLY_NAME_AND_ACTIVE'
}

export enum IApiNefcoOfficeOrderBy {
  Name = 'NAME',
  Status = 'STATUS',
  TotalInvestigations = 'TOTAL_INVESTIGATIONS'
}

export type IApiNode = {
  id: Scalars['ID'];
};

export type IApiNotification = IApiNode & {
  __typename?: 'Notification';
  createdAt?: Maybe<Scalars['Date']>;
  event?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  isImportant?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  method?: Maybe<IApiNotificationMethodType>;
  Notifiable?: Maybe<Scalars['JSON']>;
  notifiableId?: Maybe<Scalars['ID']>;
  redirectUrl?: Maybe<Scalars['String']>;
  Sender?: Maybe<IApiUser>;
  severity?: Maybe<Scalars['String']>;
  status?: Maybe<IApiNotificationStatusType>;
  title?: Maybe<Scalars['String']>;
  Type?: Maybe<IApiNotificationType>;
  updatedAt?: Maybe<Scalars['Date']>;
  User?: Maybe<IApiUser>;
  viewed?: Maybe<Scalars['Boolean']>;
};

export type IApiNotificationEdge = {
  __typename?: 'NotificationEdge';
  cursor: Scalars['String'];
  node: IApiNotification;
};

export enum IApiNotificationEntityType {
  Billing = 'BILLING',
  Branch = 'BRANCH',
  Certification = 'CERTIFICATION',
  Company = 'COMPANY',
  Contact = 'CONTACT',
  Document = 'DOCUMENT',
  Evidence = 'EVIDENCE',
  Expense = 'EXPENSE',
  External = 'EXTERNAL',
  Investigation = 'INVESTIGATION',
  InvestigationStaff = 'INVESTIGATION_STAFF',
  Photo = 'PHOTO',
  Report = 'REPORT',
  Request = 'REQUEST',
  Service = 'SERVICE',
  Staff = 'STAFF',
  Time = 'TIME',
  TimeAndExpense = 'TIME_AND_EXPENSE'
}

export enum IApiNotificationEventActionType {
  AddedToInvestigation = 'ADDED_TO_INVESTIGATION',
  Attention = 'ATTENTION',
  Created = 'CREATED',
  Deleted = 'DELETED',
  Due = 'DUE',
  Expired = 'EXPIRED',
  Expiring = 'EXPIRING',
  Incomplete = 'INCOMPLETE',
  Overdue = 'OVERDUE',
  Pending = 'PENDING',
  Updated = 'UPDATED'
}

export enum IApiNotificationEventDetailType {
  AddExpert = 'ADD_EXPERT',
  AddExpertApproved = 'ADD_EXPERT_APPROVED',
  AfaEtlUpload = 'AFA_ETL_UPLOAD',
  Approved = 'APPROVED',
  AssignedEditorReview = 'ASSIGNED_EDITOR_REVIEW',
  BillingInfo = 'BILLING_INFO',
  BillingRulesAdded = 'BILLING_RULES_ADDED',
  BillingRulesUpdated = 'BILLING_RULES_UPDATED',
  Canceled = 'CANCELED',
  CaptionsOneDay = 'CAPTIONS_ONE_DAY',
  ClientRequestEstimate = 'CLIENT_REQUEST_ESTIMATE',
  ClientRequestEstimateAuthorized = 'CLIENT_REQUEST_ESTIMATE_AUTHORIZED',
  ClientRequestEstimateComplete = 'CLIENT_REQUEST_ESTIMATE_COMPLETE',
  ClosedToOpen = 'CLOSED_TO_OPEN',
  CommentAdded = 'COMMENT_ADDED',
  CompanyBranch = 'COMPANY_BRANCH',
  CompanyBranchDeactivated = 'COMPANY_BRANCH_DEACTIVATED',
  CompanyBranchDeactivatedThreeDays = 'COMPANY_BRANCH_DEACTIVATED_THREE_DAYS',
  CompletedFirePoliceReportRequest = 'COMPLETED_FIRE_POLICE_REPORT_REQUEST',
  Created = 'CREATED',
  Deactivated = 'DEACTIVATED',
  Deleted = 'DELETED',
  DisposalAwaiting = 'DISPOSAL_AWAITING',
  DisposalComplete = 'DISPOSAL_COMPLETE',
  DisposalRequested = 'DISPOSAL_REQUESTED',
  DocumentUploadedCourt = 'DOCUMENT_UPLOADED_COURT',
  DraftThreeDays = 'DRAFT_THREE_DAYS',
  DraftTwoDays = 'DRAFT_TWO_DAYS',
  Error = 'ERROR',
  EtlUpload = 'ETL_UPLOAD',
  EtlUploadFourDays = 'ETL_UPLOAD_FOUR_DAYS',
  EtlUploadOneDay = 'ETL_UPLOAD_ONE_DAY',
  EtlUploadThreeDays = 'ETL_UPLOAD_THREE_DAYS',
  EtlUploadTwoDays = 'ETL_UPLOAD_TWO_DAYS',
  EvidenceCompleteNoEvidence = 'EVIDENCE_COMPLETE_NO_EVIDENCE',
  EvidenceUploadOneDay = 'EVIDENCE_UPLOAD_ONE_DAY',
  Expired = 'EXPIRED',
  ExpiringOneMonths = 'EXPIRING_ONE_MONTHS',
  ExpiringSixMonths = 'EXPIRING_SIX_MONTHS',
  ExpiringThreeMonths = 'EXPIRING_THREE_MONTHS',
  FireDepartmentReport = 'FIRE_DEPARTMENT_REPORT',
  FireMarshallReport = 'FIRE_MARSHALL_REPORT',
  FoilReport = 'FOIL_REPORT',
  FoilReportComplete = 'FOIL_REPORT_COMPLETE',
  FourDays = 'FOUR_DAYS',
  FourteenDays = 'FOURTEEN_DAYS',
  FourteenDaysPast = 'FOURTEEN_DAYS_PAST',
  FourteenDaysPastUnlinked = 'FOURTEEN_DAYS_PAST_UNLINKED',
  Incendiary = 'INCENDIARY',
  IncompleteOneDay = 'INCOMPLETE_ONE_DAY',
  IncompleteTwoDays = 'INCOMPLETE_TWO_DAYS',
  Individual = 'INDIVIDUAL',
  Interview = 'INTERVIEW',
  InterviewFourDays = 'INTERVIEW_FOUR_DAYS',
  InterviewThreeDays = 'INTERVIEW_THREE_DAYS',
  InterviewTwoDays = 'INTERVIEW_TWO_DAYS',
  InvestigationAccepted = 'INVESTIGATION_ACCEPTED',
  InvestigatorAssigned = 'INVESTIGATOR_ASSIGNED',
  InvestigatorAssignedFourDays = 'INVESTIGATOR_ASSIGNED_FOUR_DAYS',
  InvestigatorAssignedSevenDays = 'INVESTIGATOR_ASSIGNED_SEVEN_DAYS',
  InvestigatorAssignedThreeDays = 'INVESTIGATOR_ASSIGNED_THREE_DAYS',
  InvestigatorAssignedTwoDays = 'INVESTIGATOR_ASSIGNED_TWO_DAYS',
  InvestigatorNotes = 'INVESTIGATOR_NOTES',
  InvestigatorNotesFiveDays = 'INVESTIGATOR_NOTES_FIVE_DAYS',
  InvestigatorNotesFourDays = 'INVESTIGATOR_NOTES_FOUR_DAYS',
  InvestigatorNotesThreeDays = 'INVESTIGATOR_NOTES_THREE_DAYS',
  InvestigatorNotesTwoDays = 'INVESTIGATOR_NOTES_TWO_DAYS',
  Invoice = 'INVOICE',
  LighteningStrike = 'LIGHTENING_STRIKE',
  LighteningStrikeReportComplete = 'LIGHTENING_STRIKE_REPORT_COMPLETE',
  LightningReport = 'LIGHTNING_REPORT',
  LightningReportComplete = 'LIGHTNING_REPORT_COMPLETE',
  MileageFourteenDaysPast = 'MILEAGE_FOURTEEN_DAYS_PAST',
  MileageFourteenDaysPastUnlinked = 'MILEAGE_FOURTEEN_DAYS_PAST_UNLINKED',
  OnHold = 'ON_HOLD',
  OneDay = 'ONE_DAY',
  Ongoing = 'ONGOING',
  PhotoOverdue = 'PHOTO_OVERDUE',
  PhotoOverdueFourDays = 'PHOTO_OVERDUE_FOUR_DAYS',
  PhotoOverdueOneDay = 'PHOTO_OVERDUE_ONE_DAY',
  PhotoOverdueThreeDays = 'PHOTO_OVERDUE_THREE_DAYS',
  PhotoOverdueTwoDays = 'PHOTO_OVERDUE_TWO_DAYS',
  PrivateCase = 'PRIVATE_CASE',
  ProduceAssets = 'PRODUCE_ASSETS',
  ProduceAssetsComplete = 'PRODUCE_ASSETS_COMPLETE',
  PropertyAuthorizationStateFarm = 'PROPERTY_AUTHORIZATION_STATE_FARM',
  PropertyAuthorizationStateFarmFiveDays = 'PROPERTY_AUTHORIZATION_STATE_FARM_FIVE_DAYS',
  PropertyAuthorizationStateFarmFourDays = 'PROPERTY_AUTHORIZATION_STATE_FARM_FOUR_DAYS',
  PropertyAuthorizationStateFarmThreeDays = 'PROPERTY_AUTHORIZATION_STATE_FARM_THREE_DAYS',
  PropertyAuthorizationStateFarmTwoDays = 'PROPERTY_AUTHORIZATION_STATE_FARM_TWO_DAYS',
  PropertyAuthorizationStillwater = 'PROPERTY_AUTHORIZATION_STILLWATER',
  PropertyAuthorizationStillwaterTwoDays = 'PROPERTY_AUTHORIZATION_STILLWATER_TWO_DAYS',
  ReadyEditorReview = 'READY_EDITOR_REVIEW',
  ReadySecondReview = 'READY_SECOND_REVIEW',
  Rejected = 'REJECTED',
  Renewed = 'RENEWED',
  RequestReceived = 'REQUEST_RECEIVED',
  RetainerReceived = 'RETAINER_RECEIVED',
  SceneDiagram = 'SCENE_DIAGRAM',
  SceneDiagramThreeDays = 'SCENE_DIAGRAM_THREE_DAYS',
  SceneDiagramTwoDays = 'SCENE_DIAGRAM_TWO_DAYS',
  SceneExam = 'SCENE_EXAM',
  SceneExamFourDays = 'SCENE_EXAM_FOUR_DAYS',
  SceneExamPhotos = 'SCENE_EXAM_PHOTOS',
  SceneExamTwoDays = 'SCENE_EXAM_TWO_DAYS',
  SecondTechReview = 'SECOND_TECH_REVIEW',
  SecondTechReviewOneDay = 'SECOND_TECH_REVIEW_ONE_DAY',
  SentBackClientReview = 'SENT_BACK_CLIENT_REVIEW',
  SentBackEditorReview = 'SENT_BACK_EDITOR_REVIEW',
  SentBackTechReview = 'SENT_BACK_TECH_REVIEW',
  SentToTechReviewer = 'SENT_TO_TECH_REVIEWER',
  ServiceCompleteDocumentsNot = 'SERVICE_COMPLETE_DOCUMENTS_NOT',
  StaffAssigned = 'STAFF_ASSIGNED',
  TechReview = 'TECH_REVIEW',
  TechReviewOneDay = 'TECH_REVIEW_ONE_DAY',
  TechReviewTwoDays = 'TECH_REVIEW_TWO_DAYS',
  ThreeDays = 'THREE_DAYS',
  TimeEntry = 'TIME_ENTRY',
  TimeEntryFourDays = 'TIME_ENTRY_FOUR_DAYS',
  TimeEntryThreeDays = 'TIME_ENTRY_THREE_DAYS',
  TimeEntryTwoDays = 'TIME_ENTRY_TWO_DAYS',
  TimeFourteenDaysPast = 'TIME_FOURTEEN_DAYS_PAST',
  TimeFourteenDaysPastUnlinked = 'TIME_FOURTEEN_DAYS_PAST_UNLINKED',
  Transfer = 'TRANSFER',
  TwoDays = 'TWO_DAYS',
  Updated = 'UPDATED',
  VisitOneDay = 'VISIT_ONE_DAY',
  VisitSixHours = 'VISIT_SIX_HOURS',
  VisitThreeHours = 'VISIT_THREE_HOURS'
}

export enum IApiNotificationEventType {
  Cron = 'CRON',
  Event = 'EVENT'
}

export type IApiNotificationFilter = {
  type?: Maybe<IApiNotificationFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiNotificationFilterType {
  AssignedTo = 'ASSIGNED_TO',
  IsViewed = 'IS_VIEWED',
  Method = 'METHOD',
  Owned = 'OWNED',
  Title = 'TITLE',
  Type = 'TYPE'
}

export type IApiNotificationMethodSetting = IApiNode & {
  __typename?: 'NotificationMethodSetting';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isDisabled?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isSystem?: Maybe<Scalars['Boolean']>;
  method?: Maybe<IApiNotificationMethodType>;
  NotificationType?: Maybe<IApiNotificationType>;
  PermissionRole?: Maybe<IApiPermissionRole>;
  sendAll?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  User?: Maybe<IApiUser>;
};

export type IApiNotificationMethodSettingEdge = {
  __typename?: 'NotificationMethodSettingEdge';
  cursor: Scalars['String'];
  node: IApiNotificationMethodSetting;
};

export type IApiNotificationMethodSettingFilter = {
  type?: Maybe<IApiNotificationMethodSettingFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiNotificationMethodSettingFilterType {
  Method = 'METHOD',
  NotificationType = 'NOTIFICATION_TYPE',
  PermissionRole = 'PERMISSION_ROLE'
}

export enum IApiNotificationMethodSettingOrderBy {
  CreatedAt = 'CREATED_AT',
  NotificationType = 'NOTIFICATION_TYPE',
  PermissionRole = 'PERMISSION_ROLE'
}

export type IApiNotificationMethodSettingsConnection = {
  __typename?: 'NotificationMethodSettingsConnection';
  edges?: Maybe<Array<Maybe<IApiNotificationMethodSettingEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum IApiNotificationMethodType {
  AngularDashboard = 'ANGULAR_DASHBOARD',
  Email = 'EMAIL',
  Sms = 'SMS',
  System = 'SYSTEM'
}

export enum IApiNotificationOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiNotificationsConnection = {
  __typename?: 'NotificationsConnection';
  edges?: Maybe<Array<Maybe<IApiNotificationEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum IApiNotificationSendTo {
  Other = 'OTHER',
  RegionalManager = 'REGIONAL_MANAGER',
  User = 'USER'
}

export enum IApiNotificationStatusType {
  Failed = 'FAILED',
  NotSendable = 'NOT_SENDABLE',
  Success = 'SUCCESS'
}

export type IApiNotificationType = IApiNode & {
  __typename?: 'NotificationType';
  action?: Maybe<IApiNotificationEventActionType>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  detail?: Maybe<IApiNotificationEventDetailType>;
  emailTemplate?: Maybe<Scalars['String']>;
  entity?: Maybe<IApiNotificationEntityType>;
  eventType?: Maybe<IApiNotificationEventType>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  MethodSettings?: Maybe<Array<Maybe<IApiNotificationMethodSetting>>>;
  sendTo?: Maybe<Scalars['String']>;
  smsTemplate?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiNotificationTypeEdge = {
  __typename?: 'NotificationTypeEdge';
  cursor: Scalars['String'];
  node: IApiNotificationType;
};

export type IApiNotificationTypeFilter = {
  type?: Maybe<IApiNotificationTypeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiNotificationTypeFilterType {
  Action = 'ACTION',
  Entity = 'ENTITY',
  Key = 'KEY',
  PermissionRoleId = 'PERMISSION_ROLE_ID',
  RecipientId = 'RECIPIENT_ID',
  Type = 'TYPE'
}

export enum IApiNotificationTypeOrderBy {
  CreatedAt = 'CREATED_AT',
  Entity = 'ENTITY'
}

export type IApiNotificationTypesConnection = {
  __typename?: 'NotificationTypesConnection';
  edges?: Maybe<Array<Maybe<IApiNotificationTypeEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiOnScene = {
  __typename?: 'OnScene';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  InvestigationId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiPageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type IApiPartsInput = {
  ETag: Scalars['String'];
  PartNumber: Scalars['Float'];
};

export type IApiPayrollDownloadInput = {
  includeUnpaid?: Maybe<Scalars['Boolean']>;
  paidDate: Scalars['Date'];
  preview?: Maybe<Scalars['Boolean']>;
};

export type IApiPermissionAction = {
  __typename?: 'PermissionAction';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IApiPermissionCategory = {
  __typename?: 'PermissionCategory';
  AvailableScopes?: Maybe<Array<Maybe<IApiPermissionScope>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  Roles?: Maybe<Array<Maybe<IApiPermissionRole>>>;
};

export type IApiPermissionRole = {
  __typename?: 'PermissionRole';
  AssignedPermissions?: Maybe<Array<Maybe<IApiPermissionRolePermission>>>;
  AssignedUserCount?: Maybe<Scalars['Int']>;
  AssignedUsers?: Maybe<Array<Maybe<IApiUser>>>;
  EnabledNotifications?: Maybe<Array<Maybe<IApiNotificationEntityType>>>;
  id: Scalars['ID'];
  InvestigationRole?: Maybe<Array<Maybe<IApiInvestigationRole>>>;
  name: Scalars['String'];
  TimeEntryType?: Maybe<IApiTimeEntryType>;
  TimeEntryTypeId?: Maybe<Scalars['ID']>;
};

export type IApiPermissionRoleConnection = {
  __typename?: 'PermissionRoleConnection';
  edges?: Maybe<Array<Maybe<IApiPermissionRoleEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiPermissionRoleEdge = {
  __typename?: 'PermissionRoleEdge';
  cursor: Scalars['String'];
  node: IApiPermissionRole;
};

export type IApiPermissionRoleFilter = {
  type?: Maybe<IApiPermissionRoleFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiPermissionRoleFilterType {
  Name = 'NAME'
}

export enum IApiPermissionRoleOrderBy {
  Name = 'NAME'
}

export type IApiPermissionRolePermission = {
  __typename?: 'PermissionRolePermission';
  Action: IApiPermissionAction;
  Category: IApiPermissionCategory;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  Scope: IApiPermissionScope;
};

export type IApiPermissionScope = {
  __typename?: 'PermissionScope';
  AvailableActions?: Maybe<Array<Maybe<IApiPermissionAction>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  PermissionCategoryId: Scalars['ID'];
};

export type IApiPhone = IApiNode & {
  __typename?: 'Phone';
  createdAt?: Maybe<Scalars['Date']>;
  extension?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPrimary?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  phoneableId?: Maybe<Scalars['ID']>;
  phoneableType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  Type: IApiPhoneType;
  TypeId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiPhoneConnection = {
  __typename?: 'PhoneConnection';
  edges?: Maybe<Array<Maybe<IApiPhoneEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiPhoneEdge = {
  __typename?: 'PhoneEdge';
  cursor: Scalars['String'];
  node: IApiPhone;
};

export type IApiPhoneFilter = {
  type?: Maybe<IApiPhoneFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiPhoneFilterType {
  Name = 'NAME'
}

export enum IApiPhoneOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiPhoneType = IApiNode & {
  __typename?: 'PhoneType';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiPhoneTypeConnection = {
  __typename?: 'PhoneTypeConnection';
  edges?: Maybe<Array<Maybe<IApiPhoneTypeEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiPhoneTypeEdge = {
  __typename?: 'PhoneTypeEdge';
  cursor: Scalars['String'];
  node: IApiPhoneType;
};

export type IApiPhoneTypeFilter = {
  type?: Maybe<IApiPhoneTypeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiPhoneTypeFilterType {
  FromLocation = 'FROM_LOCATION'
}

export enum IApiPhoneTypeOrderBy {
  Name = 'NAME'
}

export type IApiPhoto = IApiNode & {
  __typename?: 'Photo';
  caption: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  fileName: Scalars['String'];
  fileSizeKB: Scalars['Int'];
  Folder?: Maybe<IApiPhotoFolder>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isEvidence?: Maybe<Scalars['Boolean']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  lowResS3Uri?: Maybe<Scalars['String']>;
  lowResUri?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  s3Uri?: Maybe<Scalars['String']>;
  uri: Scalars['String'];
  User?: Maybe<IApiUser>;
  width?: Maybe<Scalars['Int']>;
};

export type IApiPhotoConnection = {
  __typename?: 'PhotoConnection';
  edges?: Maybe<Array<Maybe<IApiPhotoEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiPhotoDownloadInput = {
  env: Scalars['String'];
  Folders?: Maybe<Array<Maybe<IApiPhotoFolderDownloadInput>>>;
  InvestigationId: Scalars['ID'];
  lowRes?: Maybe<Scalars['Boolean']>;
  Photos?: Maybe<Array<Maybe<Scalars['ID']>>>;
  uniqueId: Scalars['String'];
  UserId: Scalars['ID'];
};

export type IApiPhotoDownloadProgressResponse = {
  __typename?: 'photoDownloadProgressResponse';
  error?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Float']>;
  uniqueId?: Maybe<Scalars['String']>;
  UserId?: Maybe<Scalars['ID']>;
  zipUrl?: Maybe<Scalars['String']>;
};

export type IApiPhotoDownloadRtfInput = {
  InvestigationId: Scalars['ID'];
};

export type IApiPhotoEdge = {
  __typename?: 'PhotoEdge';
  cursor: Scalars['String'];
  node: IApiPhoto;
};

export type IApiPhotoFilter = {
  type?: Maybe<IApiPhotoFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiPhotoFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  Evidence = 'EVIDENCE',
  Folder = 'FOLDER',
  Investigation = 'INVESTIGATION',
  User = 'USER'
}

export type IApiPhotoFolder = IApiNode & {
  __typename?: 'PhotoFolder';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  name: Scalars['String'];
  PhotoCount?: Maybe<Scalars['Int']>;
  Photos?: Maybe<Array<Maybe<IApiPhoto>>>;
  SubService?: Maybe<IApiSubService>;
  updatedAt?: Maybe<Scalars['Date']>;
  User?: Maybe<IApiUser>;
};

export type IApiPhotoFolderConnection = {
  __typename?: 'PhotoFolderConnection';
  edges?: Maybe<Array<Maybe<IApiPhotoFolderEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiPhotoFolderDownloadInput = {
  id: Scalars['ID'];
  isUsed?: Maybe<Scalars['Boolean']>;
};

export type IApiPhotoFolderEdge = {
  __typename?: 'PhotoFolderEdge';
  cursor: Scalars['String'];
  node: IApiPhotoFolder;
};

export type IApiPhotoFolderFilter = {
  type?: Maybe<IApiPhotoFolderFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiPhotoFolderFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  Investigation = 'INVESTIGATION',
  Name = 'NAME',
  User = 'USER',
  ViewList = 'VIEW_LIST'
}

export enum IApiPhotoFolderOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export enum IApiPhotoOrderBy {
  CreatedAt = 'CREATED_AT',
  Position = 'POSITION'
}

export type IApiPhotoThumbnail = IApiNode & {
  __typename?: 'PhotoThumbnail';
  fileSizeKB: Scalars['Int'];
  id: Scalars['ID'];
  Photo?: Maybe<IApiPhoto>;
  scale: Scalars['Decimal'];
  uri: Scalars['String'];
};

export type IApiPhotoThumbnailConnection = {
  __typename?: 'PhotoThumbnailConnection';
  edges?: Maybe<Array<Maybe<IApiPhotoThumbnailEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiPhotoThumbnailEdge = {
  __typename?: 'PhotoThumbnailEdge';
  cursor: Scalars['String'];
  node: IApiPhotoThumbnail;
};

export type IApiPhotoThumbnailFilter = {
  type?: Maybe<IApiPhotoThumbnailFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiPhotoThumbnailFilterType {
  Name = 'NAME'
}

export enum IApiPhotoThumbnailOrderBy {
  Name = 'NAME'
}

export type IApiPositionPhotoInput = {
  newPosition: Scalars['Int'];
  photoId: Scalars['ID'];
};

export type IApiQuarterlyHourReport = {
  __typename?: 'QuarterlyHourReport';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  quarterlyGoal?: Maybe<Scalars['String']>;
  TimeEntry?: Maybe<Array<Maybe<IApiTimeEntryTypeData>>>;
};

export type IApiQuarterlyHourReportConnection = {
  __typename?: 'QuarterlyHourReportConnection';
  edges?: Maybe<Array<Maybe<IApiQuarterlyHourReportEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiQuarterlyHourReportEdge = {
  __typename?: 'QuarterlyHourReportEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiQuarterlyHourReport>;
};

export type IApiQuarterlyUtilizationReport = IApiNode & {
  __typename?: 'QuarterlyUtilizationReport';
  Addresses?: Maybe<Array<Maybe<IApiAddress>>>;
  firstName?: Maybe<Scalars['String']>;
  hoursWorked?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  quarterlyGoal?: Maybe<Scalars['String']>;
  quarterlyGoalPercentage?: Maybe<Scalars['String']>;
};

export type IApiQuarterlyUtilizationReportConnection = {
  __typename?: 'QuarterlyUtilizationReportConnection';
  edges?: Maybe<Array<Maybe<IApiQuarterlyUtilizationReportEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiQuarterlyUtilizationReportEdge = {
  __typename?: 'QuarterlyUtilizationReportEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiQuarterlyUtilizationReport>;
};

export type IApiQuarterlyUtilizationReportFilter = {
  type?: Maybe<IApiQuarterlyUtilizationReportFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiQuarterlyUtilizationReportFilterType {
  StaffType = 'STAFF_TYPE',
  State = 'STATE',
  TimeEntryTypeTotal = 'TIME_ENTRY_TYPE_TOTAL',
  Users = 'USERS',
  WorkDayRange = 'WORK_DAY_RANGE'
}

export enum IApiQuarterlyUtilizationReportOrderBy {
  HoursWorked = 'HOURS_WORKED',
  QuarterlyGoal = 'QUARTERLY_GOAL',
  QuarterlyGoalPercentage = 'QUARTERLY_GOAL_PERCENTAGE',
  TimeEntryTypeTotal = 'TIME_ENTRY_TYPE_TOTAL',
  UserName = 'USER_NAME'
}

export type IApiQuery = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  accountSummaryExpenseReport: Scalars['String'];
  accountSummaryHoursReport: Scalars['String'];
  addressConnection: IApiAddressConnection;
  addressTypeConnection: IApiAddressTypeConnection;
  authorization?: Maybe<IApiAuthorizationResponse>;
  availabilityByUsers: Scalars['JSON'];
  billingRuleCategoryConnection: IApiBillingRuleCategoryConnection;
  billingRuleConnection: IApiBillingRuleConnection;
  billingRuleTypeConnection: IApiBillingRuleTypeConnection;
  bookkeepingExpenseConnection: IApiExpenseConnection;
  bookkeepingExpenseReportTotal: IApiExpenseReportTotal;
  bookkeepingTimeEntryConnection: IApiTimeEntryConnection;
  branchesConnection: IApiBranchConnection;
  certificationAgencyConnection: IApiCertificationAgencyConnection;
  certificationConnection: IApiCertificationConnection;
  certificationTypeConnection: IApiCertificationTypeConnection;
  checkPrimaryEmail?: Maybe<IApiEmailAddress>;
  commentConnection: IApiCommentConnection;
  companiesConnection: IApiCompaniesConnection;
  companyTypeConnection: IApiCompanyTypeConnection;
  contactRoleConnection: IApiContactRoleConnection;
  contactsConnection: IApiContactConnection;
  createUserAndResetPassword?: Maybe<Scalars['Boolean']>;
  documentApprovalConnection: IApiDocumentApprovalConnection;
  documentCategoryConnection: IApiDocumentCategoryConnection;
  documentCollectionConnection: IApiDocumentCollectionConnection;
  documentConnection: IApiDocumentConnection;
  documentSentConnection: IApiDocumentSentConnection;
  documentShareConnection: IApiDocumentShareConnection;
  documentSnapshotConnection: IApiDocumentSnapshotConnection;
  documentTemplateConnection: IApiDocumentTemplateConnection;
  documentTypeConnection: IApiDocumentTypeConnection;
  documentZipDownload: Scalars['String'];
  emailConnection: IApiEmailConnection;
  emailTypeConnection: IApiEmailTypeConnection;
  evidenceBillingConnection: IApiEvidenceBillingConnection;
  evidenceBillingFrequencyConnection: IApiEvidenceBillingFrequencyConnection;
  evidenceConnection: IApiEvidenceConnection;
  evidenceListCSV: Scalars['String'];
  evidenceStatusConnection: IApiEvidenceStatusConnection;
  evidenceStorageLocationConnection: IApiEvidenceStorageLocationConnection;
  evidenceTransferConnection: IApiEvidenceTransferConnection;
  expenseApplicationConnection: IApiExpenseApplicationConnection;
  expenseCategoryConnection: IApiExpenseCategoryConnection;
  expenseConnection: IApiExpenseConnection;
  expenseFilterTypes?: Maybe<Array<Maybe<IApiExpenseFilterType>>>;
  expenseItemConnection: IApiExpenseItemConnection;
  expenseMainConnection: IApiExpenseConnection;
  expenseMileageTotal: IApiExpenseMileageTotal;
  expenseOrderBy?: Maybe<Array<Maybe<IApiExpenseOrderBy>>>;
  expensePurposeConnection: IApiExpensePurposeConnection;
  expenseReportTotal: IApiExpenseReportTotal;
  expenseTypeConnection: IApiExpenseTypeConnection;
  generateEvidenceListCSV: Scalars['String'];
  generateEvidenceListCSVbyId: Scalars['String'];
  generateInvestigationAndEvidenceCSV: Scalars['String'];
  generateInvestigationMetricsCSV: Scalars['String'];
  generatePayrollListCSV: Scalars['String'];
  generatePhotoDownload: Scalars['String'];
  generatePhotoRTF: Scalars['String'];
  generateReportXML: Scalars['String'];
  generateTemplateDownload: Scalars['String'];
  geoFireReportByState: IApiGeoFireReportByStateConnection;
  getAddressById: IApiAddress;
  getAddressTypeById: IApiAddressType;
  getBranchById?: Maybe<IApiBranch>;
  getByContactId?: Maybe<Array<Maybe<IApiContactBranchAssignment>>>;
  getByEmail: Array<Maybe<IApiDocumentShare>>;
  getClientUserDetail?: Maybe<IApiClientUserDetail>;
  getClosestInvestigatorByInvestigationId?: Maybe<IApiUserWithDistance>;
  getCommentById: IApiComment;
  getCompanyById?: Maybe<IApiCompany>;
  getConsolidatedNotificationMethodSettings: Array<Maybe<IApiNotificationMethodSetting>>;
  getContactById: IApiContact;
  getContactRoleById: IApiContact;
  getCurrentContact: IApiIntake;
  getCurrentContactById: IApiIntake;
  getDocumentApprovalById: IApiDocumentApproval;
  getDocumentById: IApiDocument;
  getDocumentCategoryById: IApiDocumentCategory;
  getDocumentCollectionById: IApiDocumentCollection;
  getDocumentShareById: IApiDocumentShare;
  getDocumentSnapshotById: IApiDocumentSnapshot;
  getDocumentTemplateById: IApiDocumentTemplate;
  getDocumentTypeById: IApiDocumentType;
  getEmailById: IApiEmail;
  getEmailTypeById: IApiEmailType;
  getEvidenceBillingById: IApiEvidenceBilling;
  getEvidenceBillingFrequencyById: IApiEvidenceBillingFrequency;
  getEvidenceById: IApiEvidence;
  getEvidenceStatusById: IApiEvidenceStatus;
  getEvidenceStorageLocationById: IApiEvidenceStorageLocation;
  getEvidenceTransferById: IApiEvidenceTransfer;
  getExpenseReportTotal: Scalars['String'];
  getInsuranceCompanies?: Maybe<IApiCompaniesConnection>;
  getInvestigationById?: Maybe<IApiInvestigation>;
  getInvestigationDetailById: IApiInvestigationDetail;
  getInvestigationPartyById: IApiInvestigationParty;
  getInvestigationPriorityById: IApiInvestigationPriority;
  getInvestigationRoleById: IApiInvestigationRole;
  getInvestigationSource?: Maybe<Array<Maybe<IApiInvestigationSource>>>;
  getInvestigationStaffById: IApiInvestigationStaff;
  getInvestigationStatusById: IApiInvestigationStatus;
  getInvoiceById: IApiInvoice;
  getNefcoOfficeById: IApiNefcoOffice;
  getNotificationById: IApiNotification;
  getNotificationMethodSettingById: IApiNotificationMethodSetting;
  getNotificationTypeById: IApiNotificationType;
  getPermissionActions: Array<Maybe<IApiPermissionAction>>;
  getPermissionCategories: Array<Maybe<IApiPermissionCategory>>;
  getPermissionRoleById: IApiPermissionRole;
  getPermissionScopes: Array<Maybe<IApiPermissionScope>>;
  getPhoneById: IApiPhone;
  getPhoneTypeById: IApiPhoneType;
  getPublicContactRole: IApiContactRoleConnection;
  getRequestById: IApiRequest;
  getRequestStatusById: IApiRequestStatus;
  getRequestTypeById: IApiRequestType;
  getServiceById: IApiService;
  getServiceStatusById: IApiServiceStatus;
  getServiceTypeById: IApiServiceType;
  getShippingCarrierById: IApiShippingCarrier;
  getSubServiceById: IApiSubService;
  getSubServiceTypeById: IApiSubServiceType;
  getUserById: IApiUser;
  getUserByMsGraphToken: IApiTokenUser;
  getUserDetailById: IApiUserDetail;
  getVehicleById: IApiVehicle;
  healthCheck?: Maybe<IApiHealthCheckApi>;
  insuredPartyConnection: IApiInsuredPartyConnection;
  investigationDetailConnection: IApiInvestigationDetailConnection;
  investigationEditorTimelogAverageConnection: IApiInvestigationEditorTimelogAverage;
  investigationEditorTimelogConnection: IApiInvestigationEditorTimelogConnection;
  investigationMetricsBasicConnection: IApiInvestigationMetricsBasicConnection;
  investigationMetricsBillToCompanyConnection: IApiInvestigationMetricsBillToCompanyConnection;
  investigationMetricsClientCompanyConnection: IApiInvestigationMetricsClientCompanyConnection;
  investigationMetricsClientContactConnection: IApiInvestigationMetricsClientContactConnection;
  investigationMetricsConnection: IApiInvestigationMetricsConnection;
  investigationMetricsDeterminationConnection: IApiInvestigationMetricsDeterminationConnection;
  investigationMetricsDocusketchRequestedConnection: IApiInvestigationMetricsDocusketchRequestedConnection;
  investigationMetricsInvestigatorConnection: IApiInvestigationMetricsInvestigatorConnection;
  investigationMetricsRiskTypesConnection: IApiInvestigationMetricsRiskTypeConnection;
  InvestigationMetricsStateConnection: IApiInvestigationMetricsStateConnection;
  investigationMetricsTechReviewerConnection: IApiInvestigationMetricsTechReviewerConnection;
  investigationOnSceneConnection: IApiInvestigationOnSceneConnection;
  investigationPartyConnection: IApiInvestigationPartyConnection;
  investigationPriorityConnection: IApiInvestigationPriorityConnection;
  investigationRestrictionConnection: IApiInvestigationRestrictionConnection;
  investigationRoleConnection: IApiInvestigationRoleConnection;
  investigationsConnection: IApiInvestigationConnection;
  investigationStaffConnection: IApiInvestigationStaffConnection;
  investigationStatusConnection: IApiInvestigationStatusConnection;
  investigationTimelogConnection: IApiInvestigationTimelogConnection;
  invoiceCalculationConnection: IApiInvoiceCalculationConnection;
  invoiceCalculationFilterTypes?: Maybe<Array<Maybe<IApiInvoiceCalculationFilterType>>>;
  invoiceCalculationOrderBy?: Maybe<Array<Maybe<IApiInvoiceCalculationOrderByType>>>;
  InvoiceConnection: IApiInvoiceConnection;
  InvoiceItemConnection: IApiInvoiceItemConnection;
  invoiceLineConnection: IApiInvoiceLineConnection;
  invoiceLineFilterTypes?: Maybe<Array<Maybe<IApiInvoiceLineFilterType>>>;
  invoiceLineOrderBy?: Maybe<Array<Maybe<IApiInvoiceLineOrderByType>>>;
  invoiceStatusConnection: IApiInvoiceStatusConnection;
  invoiceStatusFilterTypes?: Maybe<Array<Maybe<IApiInvoiceStatusFilterType>>>;
  invoiceStatusOrderBy?: Maybe<Array<Maybe<IApiInvoiceStatusOrderByType>>>;
  nefcoOfficeConnection: IApiNefcoOfficeConnection;
  node?: Maybe<IApiNode>;
  nodes?: Maybe<Array<Maybe<IApiNode>>>;
  notificationMethodSettingsConnection: IApiNotificationMethodSettingsConnection;
  notificationsConnection: IApiNotificationsConnection;
  notificationTypesConnection: IApiNotificationTypesConnection;
  PermissionRoleConnection: IApiPermissionRoleConnection;
  phoneConnection: IApiPhoneConnection;
  phoneTypeConnection: IApiPhoneTypeConnection;
  photoConnection: IApiPhotoConnection;
  photoFolderConnection: IApiPhotoFolderConnection;
  photoThumbnailConnection: IApiPhotoThumbnailConnection;
  quarterlyHourReportConnection: IApiQuarterlyHourReportConnection;
  quarterlyHourReportCSV: Scalars['String'];
  quarterlyUtilizationReportConnection: IApiQuarterlyUtilizationReportConnection;
  reportSentConnection: IApiReportSentConnection;
  reportSentExportConnection: Scalars['String'];
  requestsConnection: IApiRequestsConnection;
  requestStatusConnection: IApiRequestStatusConnection;
  requestTypesConnection: IApiRequestTypesConnection;
  resetPassword?: Maybe<Scalars['Boolean']>;
  riskTypes?: Maybe<Array<Maybe<IApiRiskType>>>;
  servicesConnection: IApiServicesConnection;
  serviceStatusConnection: IApiServiceStatusConnection;
  serviceTypesConnection: IApiServiceTypesConnection;
  settingsConnection: IApiSettingsConnection;
  shippingCarrierConnection: IApiShippingCarrierConnection;
  subServicesConnection: IApiSubServicesConnection;
  subServiceTypesConnection: IApiSubServiceTypesConnection;
  templateData: Scalars['String'];
  timeEntryConnection: IApiTimeEntryConnection;
  timeEntryMainConnection: IApiTimeEntryConnection;
  timeEntryStatusConnection: IApiTimeEntryStatusConnection;
  timeEntryTotal: IApiTimeEntryTotal;
  timeEntryTotalByType: IApiTimeEntryTotalByTypeSummary;
  timeEntryTypeConnection: IApiTimeEntryTypeConnection;
  userDetailConnection: IApiUserDetailConnection;
  usersConnection: IApiUsersConnection;
  vehicleConnection: IApiVehicleConnection;
};


export type IApiQueryAccountSummaryExpenseReportArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiExpenseItemFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiExpenseItemOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryAccountSummaryHoursReportArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiTimeEntryTypeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiTimeEntryTypeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryAddressConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiAddressFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiAddressOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryAddressTypeConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiAddressTypeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiAddressTypeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryAvailabilityByUsersArgs = {
  input: IApiUserAvailabilityInput;
};


export type IApiQueryBillingRuleCategoryConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiBillingRuleCategoryFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiBillingRuleCategoryOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryBillingRuleConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiBillingRuleFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiBillingRuleOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryBillingRuleTypeConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiBillingRuleTypeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiBillingRuleTypeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryBookkeepingExpenseConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiExpenseFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiExpenseOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryBookkeepingExpenseReportTotalArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiExpenseFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiExpenseOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryBookkeepingTimeEntryConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiTimeEntryFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiTimeEntryOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryBranchesConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiBranchFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiBranchOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryCertificationAgencyConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiCertificationAgencyFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiCertificationAgencyOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryCertificationConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiCertificationFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiCertificationOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryCertificationTypeConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiCertificationTypeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiCertificationTypeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryCheckPrimaryEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type IApiQueryCommentConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filter?: Maybe<Array<Maybe<IApiCommentFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiCommentOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryCompaniesConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiCompanyFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiCompanyOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryCompanyTypeConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiCompanyTypeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiCompanyTypeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryContactRoleConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiContactRoleFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiContactRoleOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryContactsConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiContactFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiContactOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryCreateUserAndResetPasswordArgs = {
  email?: Maybe<Scalars['String']>;
};


export type IApiQueryDocumentApprovalConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiDocumentApprovalFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiDocumentApprovalOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryDocumentCategoryConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiDocumentCategoryFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiDocumentCategoryOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryDocumentCollectionConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiDocumentCollectionFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiDocumentCollectionOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryDocumentConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiDocumentFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiDocumentOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryDocumentSentConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiDocumentSentFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiDocumentSentOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryDocumentShareConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiDocumentShareFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiDocumentShareOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryDocumentSnapshotConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiDocumentSnapshotFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiDocumentSnapshotOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryDocumentTemplateConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiDocumentTemplateFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiDocumentTemplateOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryDocumentTypeConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiDocumentTypeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiDocumentTypeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryDocumentZipDownloadArgs = {
  input?: Maybe<IApiDocumentZipDownload>;
};


export type IApiQueryEmailConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiEmailFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiEmailOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryEmailTypeConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiEmailTypeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiEmailTypeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryEvidenceBillingConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiEvidenceBillingFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiEvidenceBillingOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryEvidenceBillingFrequencyConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiEvidenceBillingFrequencyFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiEvidenceBillingFrequencyOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryEvidenceConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiEvidenceFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiEvidenceOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryEvidenceListCsvArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiEvidenceFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiEvidenceOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryEvidenceStatusConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiEvidenceStatusFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiEvidenceStatusOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryEvidenceStorageLocationConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiEvidenceStorageLocationFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiEvidenceStorageLocationOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryEvidenceTransferConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiEvidenceTransferFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiEvidenceTransferOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryExpenseApplicationConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiExpenseApplicationFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiExpenseApplicationOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryExpenseCategoryConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiExpenseCategoryFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiExpenseCategoryOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryExpenseConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiExpenseFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiExpenseOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryExpenseItemConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiExpenseItemFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiExpenseItemOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryExpenseMainConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiExpenseFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiExpenseOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryExpenseMileageTotalArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiExpenseFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiExpenseOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryExpensePurposeConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiExpensePurposeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiExpensePurposeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryExpenseReportTotalArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiExpenseFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiExpenseOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryExpenseTypeConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiExpenseTypeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiExpenseTypeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryGenerateEvidenceListCsvArgs = {
  input: Scalars['ID'];
};


export type IApiQueryGenerateEvidenceListCsVbyIdArgs = {
  input?: Maybe<IApiEvidenceListDownloadByIdInput>;
};


export type IApiQueryGenerateInvestigationAndEvidenceCsvArgs = {
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryGenerateInvestigationMetricsCsvArgs = {
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryGeneratePayrollListCsvArgs = {
  input?: Maybe<IApiPayrollDownloadInput>;
};


export type IApiQueryGeneratePhotoDownloadArgs = {
  input?: Maybe<IApiPhotoDownloadInput>;
};


export type IApiQueryGeneratePhotoRtfArgs = {
  input?: Maybe<IApiPhotoDownloadRtfInput>;
};


export type IApiQueryGenerateReportXmlArgs = {
  input?: Maybe<IApiGenerateReportXmlInput>;
};


export type IApiQueryGenerateTemplateDownloadArgs = {
  input?: Maybe<IApiTemplateDownloadGenerateInput>;
};


export type IApiQueryGeoFireReportByStateArgs = {
  filters?: Maybe<Array<Maybe<IApiInvestigationFilter>>>;
};


export type IApiQueryGetAddressByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetAddressTypeByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetBranchByIdArgs = {
  id: Scalars['ID'];
};


export type IApiQueryGetByContactIdArgs = {
  id: Scalars['ID'];
};


export type IApiQueryGetByEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type IApiQueryGetClientUserDetailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type IApiQueryGetClosestInvestigatorByInvestigationIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetCommentByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetCompanyByIdArgs = {
  id: Scalars['ID'];
};


export type IApiQueryGetConsolidatedNotificationMethodSettingsArgs = {
  userId?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetContactByIdArgs = {
  id: Scalars['ID'];
};


export type IApiQueryGetContactRoleByIdArgs = {
  id: Scalars['ID'];
};


export type IApiQueryGetCurrentContactArgs = {
  email: Scalars['String'];
};


export type IApiQueryGetCurrentContactByIdArgs = {
  id: Scalars['ID'];
};


export type IApiQueryGetDocumentApprovalByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetDocumentByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetDocumentCategoryByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetDocumentCollectionByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetDocumentShareByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetDocumentSnapshotByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetDocumentTemplateByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetDocumentTypeByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetEmailByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetEmailTypeByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetEvidenceBillingByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetEvidenceBillingFrequencyByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetEvidenceByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetEvidenceStatusByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetEvidenceStorageLocationByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetEvidenceTransferByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetExpenseReportTotalArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiUserFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiUserOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryGetInsuranceCompaniesArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiCompanyFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiCompanyOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryGetInvestigationByIdArgs = {
  id?: Maybe<Scalars['ID']>;
  isMobile?: Maybe<Scalars['Boolean']>;
};


export type IApiQueryGetInvestigationDetailByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetInvestigationPartyByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetInvestigationPriorityByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetInvestigationRoleByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetInvestigationStaffByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetInvestigationStatusByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetInvoiceByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetNefcoOfficeByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetNotificationByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetNotificationMethodSettingByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetNotificationTypeByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetPermissionRoleByIdArgs = {
  id?: Maybe<Scalars['String']>;
};


export type IApiQueryGetPhoneByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetPhoneTypeByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetPublicContactRoleArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiContactRoleFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiContactRoleOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryGetRequestByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetRequestStatusByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetRequestTypeByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetServiceByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetServiceStatusByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetServiceTypeByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetShippingCarrierByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetSubServiceByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetSubServiceTypeByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetUserByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetUserByMsGraphTokenArgs = {
  msGraphId: Scalars['String'];
  token: Scalars['String'];
};


export type IApiQueryGetUserDetailByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryGetVehicleByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IApiQueryInsuredPartyConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInsuredPartyFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInsuredPartyOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationDetailConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationDetailFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationDetailOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationEditorTimelogAverageConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationEditorTimelogConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationMetricsBasicConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationMetricsBillToCompanyConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  isReport?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationMetricsClientCompanyConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  isReport?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationMetricsClientContactConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  isReport?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationMetricsConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationMetricsDeterminationConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationMetricsDocusketchRequestedConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationMetricsInvestigatorConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationMetricsRiskTypesConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationMetricsStateConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationMetricsTechReviewerConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationOnSceneConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationOnSceneFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationOnSceneOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationPartyConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationPartyFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationPartyOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationPriorityConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationPriorityFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationPriorityOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationRestrictionConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationRestrictionFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationRestrictionOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationRoleConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationRoleFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationRoleOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationsConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationStaffConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationStaffFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationStaffOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationStatusConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationStatusFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationStatusOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvestigationTimelogConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvestigationMetricsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvestigationMetricsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvoiceCalculationConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filter?: Maybe<Array<Maybe<IApiInvoiceCalculationFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvoiceCalculationOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvoiceConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvoiceFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvoiceOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvoiceItemConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiInvoiceItemFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvoiceItemOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvoiceLineConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filter?: Maybe<Array<Maybe<IApiInvoiceLineFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvoiceLineOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryInvoiceStatusConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filter?: Maybe<Array<Maybe<IApiInvoiceStatusFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiInvoiceStatusOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryNefcoOfficeConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiNefcoOfficeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiNefcoOfficeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryNodeArgs = {
  id: Scalars['ID'];
};


export type IApiQueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


export type IApiQueryNotificationMethodSettingsConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiNotificationMethodSettingFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiNotificationMethodSettingOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryNotificationsConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiNotificationFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiNotificationOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryNotificationTypesConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiNotificationTypeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiNotificationTypeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryPermissionRoleConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiPermissionRoleFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiPermissionRoleOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryPhoneConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiPhoneFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiPhoneOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryPhoneTypeConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiPhoneTypeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiPhoneTypeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryPhotoConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiPhotoFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiPhotoOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryPhotoFolderConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiPhotoFolderFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiPhotoFolderOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryPhotoThumbnailConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiPhotoThumbnailFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiPhotoThumbnailOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryQuarterlyHourReportConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiQuarterlyUtilizationReportFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiQuarterlyUtilizationReportOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryQuarterlyHourReportCsvArgs = {
  filters?: Maybe<Array<Maybe<IApiQuarterlyUtilizationReportFilter>>>;
  orderBy?: Maybe<IApiQuarterlyUtilizationReportOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryQuarterlyUtilizationReportConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiQuarterlyUtilizationReportFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiQuarterlyUtilizationReportOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryReportSentConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiDocumentSentFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiDocumentSentOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryReportSentExportConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiDocumentSentFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiDocumentSentOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryRequestsConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiRequestFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiRequestOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryRequestStatusConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiRequestStatusFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiRequestStatusOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryRequestTypesConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiRequestTypeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiRequestTypeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryResetPasswordArgs = {
  email?: Maybe<Scalars['String']>;
};


export type IApiQueryServicesConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiServiceFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiServiceOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryServiceStatusConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiServiceStatusFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiServiceStatusOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryServiceTypesConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiServiceTypeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiServiceTypeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQuerySettingsConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiSettingsFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiSettingsOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryShippingCarrierConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiShippingCarrierFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiShippingCarrierOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQuerySubServicesConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiSubServiceFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiSubServiceOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQuerySubServiceTypesConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiSubServiceTypeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiSubServiceTypeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryTimeEntryConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiTimeEntryFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiTimeEntryOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryTimeEntryMainConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiTimeEntryFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiTimeEntryOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryTimeEntryStatusConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filter?: Maybe<Array<Maybe<IApiTimeEntryStatusFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiTimeEntryStatusOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryTimeEntryTotalArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiTimeEntryFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiTimeEntryOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryTimeEntryTotalByTypeArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiTimeEntryFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiTimeEntryOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryTimeEntryTypeConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiTimeEntryTypeFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiTimeEntryTypeOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryUserDetailConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiUserDetailFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiUserDetailOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryUsersConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiUserFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiUserOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};


export type IApiQueryVehicleConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  filters?: Maybe<Array<Maybe<IApiVehicleFilter>>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<IApiVehicleOrderBy>;
  sortOrder?: Maybe<IApiSortOrder>;
};

export type IApiReceiptTempRemoveTodo = {
  __typename?: 'ReceiptTempRemoveTodo';
  id?: Maybe<Scalars['ID']>;
};

export type IApiRejectContactInput = {
  id: Scalars['ID'];
};

export type IApiRejectContactPayload = {
  __typename?: 'RejectContactPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRejectTimeEntryInput = {
  id: Scalars['ID'];
};

export type IApiRemoveAddressInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveAddressPayload = {
  __typename?: 'RemoveAddressPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveCertificationAgencyInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveCertificationAgencyPayload = {
  __typename?: 'RemoveCertificationAgencyPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveContactInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveContactPayload = {
  __typename?: 'RemoveContactPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveDocumentApprovalInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveDocumentApprovalPayload = {
  __typename?: 'RemoveDocumentApprovalPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveDocumentCategoryInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveDocumentCollectionInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveDocumentCollectionPayload = {
  __typename?: 'RemoveDocumentCollectionPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveDocumentInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveDocumentSnapshotInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveDocumentSnapshotPayload = {
  __typename?: 'RemoveDocumentSnapshotPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveEmailInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveEmailPayload = {
  __typename?: 'RemoveEmailPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveEvidenceBillingFrequencyInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveEvidenceBillingFrequencyPayload = {
  __typename?: 'RemoveEvidenceBillingFrequencyPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveEvidenceBillingInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveEvidenceInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveEvidencePayload = {
  __typename?: 'RemoveEvidencePayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveEvidenceStatusInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveEvidenceStatusPayload = {
  __typename?: 'RemoveEvidenceStatusPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveEvidenceTransferInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveEvidenceTransferPayload = {
  __typename?: 'RemoveEvidenceTransferPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveInvestigationDetailInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveInvestigationDetailPayload = {
  __typename?: 'RemoveInvestigationDetailPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveInvestigationPriorityInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveInvestigationPriorityPayload = {
  __typename?: 'RemoveInvestigationPriorityPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveInvestigationRestrictionPayload = {
  __typename?: 'RemoveInvestigationRestrictionPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveInvestigationRoleInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveInvestigationRolePayload = {
  __typename?: 'RemoveInvestigationRolePayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveInvestigationStatusInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveInvestigationStatusPayload = {
  __typename?: 'RemoveInvestigationStatusPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemoveMultipleInvestigationRestrictionInput = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type IApiRemovePermissionRoleUserInput = {
  roleId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type IApiRemovePhoneInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemovePhonePayload = {
  __typename?: 'RemovePhonePayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiRemovePhotoFolderInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemovePhotoThumbnailInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveRequestInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveRequestStatusInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveRequestTypeInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveServiceInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveServiceStatusInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveServiceTypeInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveSubServiceInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveUserDetailInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveUserInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiRemoveUserPayload = {
  __typename?: 'RemoveUserPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiReplaceLowResPhotoInput = {
  filename: Scalars['String'];
  s3Uri?: Maybe<Scalars['String']>;
  uri: Scalars['String'];
};

export type IApiReplaceLowResPhotosInput = {
  folderId: Scalars['ID'];
  photos?: Maybe<Array<IApiReplaceLowResPhotoInput>>;
};

export type IApiReportSent = IApiNode & {
  __typename?: 'ReportSent';
  count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  ReportEditor?: Maybe<Scalars['JSON']>;
  sendDate?: Maybe<Scalars['Date']>;
};

export type IApiReportSentConnection = {
  __typename?: 'ReportSentConnection';
  edges?: Maybe<Array<Maybe<IApiReportSentEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiReportSentEdge = {
  __typename?: 'ReportSentEdge';
  cursor: Scalars['String'];
  node: IApiReportSent;
};

export type IApiRequest = IApiNode & {
  __typename?: 'Request';
  Address?: Maybe<IApiAddress>;
  authorizedBy?: Maybe<Scalars['String']>;
  Contact?: Maybe<IApiContact>;
  createdAt: Scalars['Date'];
  CreatedBy?: Maybe<IApiUser>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  notes?: Maybe<Scalars['String']>;
  RecentHistory?: Maybe<IApiRequestStatus>;
  RequestType?: Maybe<IApiRequestType>;
  Status?: Maybe<Scalars['String']>;
  StatusHistory?: Maybe<Array<Maybe<IApiRequestStatus>>>;
  updatedAt: Scalars['Date'];
};

export type IApiRequestEdge = {
  __typename?: 'RequestEdge';
  cursor: Scalars['String'];
  node: IApiRequest;
};

export type IApiRequestFilter = {
  type?: Maybe<IApiRequestFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiRequestFilterType {
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  Incomplete = 'INCOMPLETE',
  Investigation = 'INVESTIGATION',
  Own = 'OWN',
  Priority = 'PRIORITY',
  Status = 'STATUS',
  Type = 'TYPE',
  User = 'USER'
}

export enum IApiRequestOrderBy {
  CreatedAt = 'CREATED_AT',
  RequestType = 'REQUEST_TYPE'
}

export type IApiRequestsConnection = {
  __typename?: 'RequestsConnection';
  edges?: Maybe<Array<Maybe<IApiRequestEdge>>>;
  pageInfo?: Maybe<IApiPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiRequestStatus = IApiNode & {
  __typename?: 'RequestStatus';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  CreatedBy?: Maybe<IApiUser>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  Request?: Maybe<IApiRequest>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiRequestStatusConnection = {
  __typename?: 'RequestStatusConnection';
  edges?: Maybe<Array<Maybe<IApiRequestStatusEdge>>>;
  pageInfo?: Maybe<IApiPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiRequestStatusEdge = {
  __typename?: 'RequestStatusEdge';
  cursor: Scalars['String'];
  node: IApiRequestStatus;
};

export type IApiRequestStatusFilter = {
  type?: Maybe<IApiRequestStatusFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiRequestStatusFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  Name = 'NAME',
  Own = 'OWN',
  Request = 'REQUEST'
}

export enum IApiRequestStatusNames {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

export enum IApiRequestStatusOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiRequestType = IApiNode & {
  __typename?: 'RequestType';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  Requests?: Maybe<Array<Maybe<IApiRequest>>>;
  updatedAt: Scalars['Date'];
};

export type IApiRequestTypeEdge = {
  __typename?: 'RequestTypeEdge';
  cursor: Scalars['String'];
  node: IApiRequestType;
};

export type IApiRequestTypeFilter = {
  type?: Maybe<IApiRequestTypeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiRequestTypeFilterType {
  Request = 'REQUEST'
}

export enum IApiRequestTypeOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type IApiRequestTypesConnection = {
  __typename?: 'RequestTypesConnection';
  edges?: Maybe<Array<Maybe<IApiRequestTypeEdge>>>;
  pageInfo?: Maybe<IApiPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiRiskType = {
  __typename?: 'RiskType';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export enum IApiRiskTypeName {
  ApartmentBuilding = 'APARTMENT_BUILDING',
  Auto = 'AUTO',
  Barn = 'BARN',
  Boat = 'BOAT',
  Commercial = 'COMMERCIAL',
  EvidenceStorageOnly = 'EVIDENCE_STORAGE_ONLY',
  Garage = 'GARAGE',
  HeavyEquipment = 'HEAVY_EQUIPMENT',
  HvacInvestigation = 'HVAC_INVESTIGATION',
  LightningStrikeInvestigation = 'LIGHTNING_STRIKE_INVESTIGATION',
  MultiFamilyHome = 'MULTI_FAMILY_HOME',
  Other = 'OTHER',
  PrivateCase = 'PRIVATE_CASE',
  SingleFamilyHome = 'SINGLE_FAMILY_HOME'
}

export type IApiRiskTypeTotal = {
  __typename?: 'RiskTypeTotal';
  count?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type IApiS3FileInput = {
  ContentType: Scalars['String'];
  fileName: Scalars['String'];
  FolderId?: Maybe<Scalars['ID']>;
  InvestigationId?: Maybe<Scalars['ID']>;
  type: IApiUploadTypes;
  UserId?: Maybe<Scalars['ID']>;
};

export type IApiSaveAddressInput = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  postal?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  TypeId?: Maybe<Scalars['ID']>;
};

export type IApiSendSmsToInvestigator = {
  __typename?: 'SendSMSToInvestigator';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type IApiSendSmsToInvestigatorInput = {
  StaffId: Scalars['ID'];
};

export type IApiService = IApiNode & {
  __typename?: 'Service';
  AssignedUsers?: Maybe<Array<Maybe<IApiUser>>>;
  authorizedBy?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  dueDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  notes?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  StatusHistory?: Maybe<Array<Maybe<IApiServiceStatus>>>;
  SubServices?: Maybe<Array<Maybe<IApiSubService>>>;
  Type?: Maybe<IApiServiceType>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiServiceEdge = {
  __typename?: 'ServiceEdge';
  cursor: Scalars['String'];
  node: IApiService;
};

export type IApiServiceFilter = {
  type?: Maybe<IApiServiceFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiServiceFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  AssignedUser = 'ASSIGNED_USER',
  Investigation = 'INVESTIGATION',
  StatusName = 'STATUS_NAME',
  Type = 'TYPE'
}

export enum IApiServiceOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiServicesConnection = {
  __typename?: 'ServicesConnection';
  edges?: Maybe<Array<Maybe<IApiServiceEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiServiceStatus = IApiNode & {
  __typename?: 'ServiceStatus';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  CreatedBy?: Maybe<IApiUser>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  Service?: Maybe<IApiService>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiServiceStatusConnection = {
  __typename?: 'ServiceStatusConnection';
  edges?: Maybe<Array<Maybe<IApiServiceStatusEdge>>>;
  pageInfo?: Maybe<IApiPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiServiceStatusEdge = {
  __typename?: 'ServiceStatusEdge';
  cursor: Scalars['String'];
  node: IApiServiceStatus;
};

export type IApiServiceStatusFilter = {
  type?: Maybe<IApiServiceStatusFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiServiceStatusFilterType {
  Name = 'NAME',
  Service = 'SERVICE'
}

export enum IApiServiceStatusOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type IApiServiceType = IApiNode & {
  __typename?: 'ServiceType';
  clientApprovalRequired?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nefcoApprovalRequired?: Maybe<Scalars['Boolean']>;
  RequiredDocuments?: Maybe<Array<Maybe<IApiDocumentType>>>;
  Services?: Maybe<Array<Maybe<IApiService>>>;
  SubServiceTypes?: Maybe<Array<Maybe<IApiSubServiceType>>>;
  updatedAt: Scalars['Date'];
};

export type IApiServiceTypeEdge = {
  __typename?: 'ServiceTypeEdge';
  cursor: Scalars['String'];
  node: IApiServiceType;
};

export type IApiServiceTypeFilter = {
  type?: Maybe<IApiServiceTypeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiServiceTypeFilterType {
  Service = 'SERVICE',
  ViewListName = 'VIEW_LIST_NAME'
}

export enum IApiServiceTypeOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type IApiServiceTypesConnection = {
  __typename?: 'ServiceTypesConnection';
  edges?: Maybe<Array<Maybe<IApiServiceTypeEdge>>>;
  pageInfo?: Maybe<IApiPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiSettings = IApiNode & {
  __typename?: 'Settings';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  value?: Maybe<Scalars['String']>;
};

export type IApiSettingsConnection = {
  __typename?: 'settingsConnection';
  edges?: Maybe<Array<Maybe<IApiSettingsEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiSettingsEdge = {
  __typename?: 'SettingsEdge';
  cursor: Scalars['String'];
  node: IApiSettings;
};

export type IApiSettingsFilter = {
  type?: Maybe<IApiSettingsFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiSettingsFilterType {
  Key = 'KEY',
  Keys = 'KEYS'
}

export enum IApiSettingsOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiShippingCarrier = IApiNode & {
  __typename?: 'ShippingCarrier';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  Transfers?: Maybe<Array<Maybe<IApiEvidenceTransfer>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiShippingCarrierConnection = {
  __typename?: 'ShippingCarrierConnection';
  edges?: Maybe<Array<Maybe<IApiShippingCarrierEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiShippingCarrierEdge = {
  __typename?: 'ShippingCarrierEdge';
  cursor: Scalars['String'];
  node: IApiShippingCarrier;
};

export type IApiShippingCarrierFilter = {
  type?: Maybe<IApiShippingCarrierFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiShippingCarrierFilterType {
  Name = 'NAME'
}

export enum IApiShippingCarrierOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type IApiSignedUrlResponse = {
  __typename?: 'SignedUrlResponse';
  key?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
};

export enum IApiSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type IApiSubscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['String']>;
  notificationAdded?: Maybe<Scalars['Boolean']>;
  photoDownloadProgress?: Maybe<IApiPhotoDownloadProgressResponse>;
  refreshInProgressInvestigation?: Maybe<Scalars['Boolean']>;
  refreshInvestigationDocumentShare?: Maybe<Scalars['ID']>;
  refreshPendingInvestigation?: Maybe<Scalars['Boolean']>;
  userAssigned?: Maybe<IApiUser>;
};

export type IApiSubService = IApiNode & {
  __typename?: 'SubService';
  AssignedUser?: Maybe<IApiUser>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  isComplete?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  Service?: Maybe<IApiService>;
  Type?: Maybe<IApiSubServiceType>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiSubServiceEdge = {
  __typename?: 'SubServiceEdge';
  cursor: Scalars['String'];
  node: IApiSubService;
};

export type IApiSubServiceFilter = {
  type?: Maybe<IApiSubServiceFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiSubServiceFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  AssignedUser = 'ASSIGNED_USER',
  Investigation = 'INVESTIGATION',
  Service = 'SERVICE',
  Type = 'TYPE'
}

export enum IApiSubServiceOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type IApiSubServicesConnection = {
  __typename?: 'SubServicesConnection';
  edges?: Maybe<Array<Maybe<IApiSubServiceEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiSubServiceType = IApiNode & {
  __typename?: 'SubServiceType';
  canToggleVisibility?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  RequiredDocuments?: Maybe<Array<Maybe<IApiDocumentType>>>;
  ServiceType?: Maybe<IApiServiceType>;
  SubServices?: Maybe<Array<Maybe<IApiSubService>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IApiSubServiceTypeEdge = {
  __typename?: 'SubServiceTypeEdge';
  cursor: Scalars['String'];
  node: IApiSubServiceType;
};

export type IApiSubServiceTypeFilter = {
  type?: Maybe<IApiSubServiceTypeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiSubServiceTypeFilterType {
  Name = 'NAME',
  Service = 'SERVICE'
}

export enum IApiSubServiceTypeOrderBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type IApiSubServiceTypesConnection = {
  __typename?: 'SubServiceTypesConnection';
  edges?: Maybe<Array<Maybe<IApiSubServiceTypeEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiTemplateDownloadGenerateInput = {
  DocumentTypeIds: Array<Maybe<Scalars['ID']>>;
  InvestigationId: Scalars['ID'];
  UploadUris?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type IApiTimeEntry = IApiNode & {
  __typename?: 'TimeEntry';
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  hours: Scalars['Float'];
  id: Scalars['ID'];
  Investigation?: Maybe<IApiInvestigation>;
  InvoiceItems?: Maybe<Array<Maybe<IApiInvoiceItem>>>;
  nonBillableHours?: Maybe<Scalars['Float']>;
  paidDate?: Maybe<Scalars['Date']>;
  Status?: Maybe<IApiTimeEntryStatus>;
  Type: IApiTimeEntryType;
  updatedAt: Scalars['Date'];
  User: IApiUser;
  workday: Scalars['Date'];
};

export type IApiTimeEntryConnection = {
  __typename?: 'TimeEntryConnection';
  edges?: Maybe<Array<Maybe<IApiTimeEntryEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiTimeEntryEdge = {
  __typename?: 'TimeEntryEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiTimeEntry>;
};

export type IApiTimeEntryFilter = {
  type?: Maybe<IApiTimeEntryFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiTimeEntryFilterType {
  CreatedatDate = 'CREATEDAT_DATE',
  DateRange = 'DATE_RANGE',
  Employee = 'EMPLOYEE',
  Investigation = 'INVESTIGATION',
  Invoiced = 'INVOICED',
  InvoicedDate = 'INVOICED_DATE',
  Paid = 'PAID',
  PaidDate = 'PAID_DATE',
  PayPeriod = 'PAY_PERIOD',
  PayRollPaidDateLocal = 'PAY_ROLL_PAID_DATE_LOCAL',
  Payroll = 'PAYROLL',
  Search = 'SEARCH',
  Status = 'STATUS',
  Type = 'TYPE',
  ViewBookkeepingView = 'VIEW_BOOKKEEPING_VIEW',
  ViewListView = 'VIEW_LIST_VIEW',
  ViewPayrollTotal = 'VIEW_PAYROLL_TOTAL',
  Week = 'WEEK',
  Workday = 'WORKDAY',
  Year = 'YEAR'
}

export enum IApiTimeEntryOrderBy {
  CreatedAt = 'CREATED_AT',
  Employee = 'EMPLOYEE',
  Investigation = 'INVESTIGATION',
  InvoicedDate = 'INVOICED_DATE',
  PaidDate = 'PAID_DATE',
  Quanity = 'QUANITY',
  Type = 'TYPE',
  Workday = 'WORKDAY'
}

export type IApiTimeEntryStatus = IApiNode & {
  __typename?: 'TimeEntryStatus';
  createdAt: Scalars['Date'];
  createdById: IApiUser;
  id: Scalars['ID'];
  name: Scalars['String'];
  timeEntry: IApiTimeEntry;
  updatedAt: Scalars['Date'];
};

export type IApiTimeEntryStatusConnection = {
  __typename?: 'TimeEntryStatusConnection';
  edges?: Maybe<Array<Maybe<IApiTimeEntryStatusEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiTimeEntryStatusEdge = {
  __typename?: 'TimeEntryStatusEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IApiTimeEntryStatus>;
};

export type IApiTimeEntryStatusFilter = {
  type?: Maybe<IApiTimeEntryStatusFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiTimeEntryStatusFilterType {
  Status = 'STATUS'
}

export type IApiTimeEntryStatusName = {
  __typename?: 'TimeEntryStatusName';
  name?: Maybe<IApiTimeEntryStatusName>;
};

export enum IApiTimeEntryStatusNames {
  Approved = 'APPROVED',
  Invoiced = 'INVOICED',
  Pending = 'PENDING'
}

export type IApiTimeEntryStatusOrderBy = {
  type?: Maybe<IApiTimeEntryStatusOrderByType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiTimeEntryStatusOrderByType {
  CreatedAt = 'CREATED_AT'
}

export type IApiTimeEntryTotal = {
  __typename?: 'TimeEntryTotal';
  billableHours?: Maybe<Scalars['Float']>;
  nonBillableHours?: Maybe<Scalars['Float']>;
  totalHours?: Maybe<Scalars['Float']>;
};

export type IApiTimeEntryTotalByType = {
  __typename?: 'TimeEntryTotalByType';
  hours?: Maybe<Scalars['Float']>;
  hoursPaid?: Maybe<Scalars['Float']>;
  hoursUnpaid?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};

export type IApiTimeEntryTotalByTypeSummary = {
  __typename?: 'TimeEntryTotalByTypeSummary';
  summary?: Maybe<Array<Maybe<IApiTimeEntryTotalByType>>>;
};

export type IApiTimeEntryType = IApiNode & {
  __typename?: 'TimeEntryType';
  activated?: Maybe<Scalars['Boolean']>;
  additionalDetails?: Maybe<Scalars['String']>;
  appliesTo?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  isFlatRate?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nonStandard?: Maybe<Scalars['Boolean']>;
  qbAccount?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  summary?: Maybe<Scalars['JSON']>;
  timeEntry?: Maybe<Array<Maybe<IApiTimeEntry>>>;
  updatedAt: Scalars['Date'];
};

export type IApiTimeEntryTypeConnection = {
  __typename?: 'TimeEntryTypeConnection';
  edges?: Maybe<Array<Maybe<IApiTimeEntryTypeEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiTimeEntryTypeData = {
  __typename?: 'TimeEntryTypeData';
  hours?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  nonBillableHours?: Maybe<Scalars['Float']>;
  type?: Maybe<IApiTimeEntryType>;
};

export type IApiTimeEntryTypeEdge = {
  __typename?: 'TimeEntryTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<IApiTimeEntryType>;
};

export type IApiTimeEntryTypeFilter = {
  type?: Maybe<IApiTimeEntryTypeFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiTimeEntryTypeFilterType {
  Active = 'ACTIVE',
  AppliesTo = 'APPLIES_TO',
  CreatedatDate = 'CREATEDAT_DATE',
  EntryDate = 'ENTRY_DATE',
  EntryInvoiced = 'ENTRY_INVOICED',
  EntryInvoicedDate = 'ENTRY_INVOICED_DATE',
  EntryStaff = 'ENTRY_STAFF',
  Id = 'ID',
  Paid = 'PAID',
  PaidDate = 'PAID_DATE',
  Summary = 'SUMMARY'
}

export enum IApiTimeEntryTypeOrderBy {
  AppliesTo = 'APPLIES_TO',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type IApiTokenUser = {
  __typename?: 'TokenUser';
  email: Scalars['String'];
  id: Scalars['ID'];
  msGraphId: Scalars['ID'];
};

export type IApiUpdateAddressInput = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPrimary?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  postal?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  TypeId?: Maybe<Scalars['ID']>;
};

export type IApiUpdateAddressTypeInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiUpdateAndReviewContactInput = {
  BranchAssignment?: Maybe<IApiAddNewContactBranchAssignmentInput>;
  company?: Maybe<IApiCustomCompanyReview>;
  CompanyAssignment?: Maybe<Scalars['ID']>;
  companyTypeId?: Maybe<Scalars['ID']>;
  contactPhone?: Maybe<IApiAddPhoneInput>;
  contactPhoneUpdate?: Maybe<IApiUpdatePhoneInput>;
  existingContactId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  removeCompanyId?: Maybe<Scalars['ID']>;
};

export type IApiUpdateBillingRuleCategoryInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiUpdateBillingRuleInput = {
  ExpenseItemId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  TimeEntryTypeId?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['Float']>;
};

export type IApiUpdateBillingRuleTypeInput = {
  CategoryId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiUpdateBranchInput = {
  additionalInformation?: Maybe<Scalars['String']>;
  Addresses?: Maybe<Array<Maybe<IApiAddAddressInput>>>;
  CompanyId?: Maybe<Scalars['ID']>;
  Contacts?: Maybe<Array<Maybe<Scalars['ID']>>>;
  Emails?: Maybe<Array<Maybe<IApiAddEmailInput>>>;
  id: Scalars['ID'];
  isPrimary?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  Phones?: Maybe<Array<Maybe<IApiAddPhoneInput>>>;
};

export type IApiUpdateCertificationAgencyInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiUpdateCertificationInput = {
  Comments?: Maybe<Array<Maybe<Scalars['ID']>>>;
  DocumentId?: Maybe<Scalars['ID']>;
  expiresAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  issuedAt: Scalars['Date'];
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  TypeId: Scalars['ID'];
  User: Scalars['ID'];
};

export type IApiUpdateCertificationTypeInput = {
  CertificationAgencyId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isRestriction?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  requiresRenewal?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type IApiUpdateCommentInput = {
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type IApiUpdateCompanyByInvoiceInput = {
  invoiceId: Scalars['ID'];
  qbListId: Scalars['String'];
};

export type IApiUpdateCompanyInput = {
  additionalInfo?: Maybe<Scalars['String']>;
  AddMainOffice?: Maybe<IApiAddBranchInput>;
  billingInstructions?: Maybe<Scalars['String']>;
  BillingRules?: Maybe<Array<Maybe<IApiAddBillingRuleInput>>>;
  BranchIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ContactIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  description?: Maybe<Scalars['String']>;
  enterPropertyEnabled?: Maybe<Scalars['Boolean']>;
  ExcludedStaffIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  FlatRates?: Maybe<Array<Maybe<IApiAddFlatRateInput>>>;
  id: Scalars['ID'];
  MainOffice?: Maybe<IApiUpdateBranchInput>;
  name: Scalars['String'];
  qbListId?: Maybe<Scalars['String']>;
  recordedInterviewNotification?: Maybe<Scalars['Boolean']>;
  RequiredCertificationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  RequiredServiceIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  TypeId?: Maybe<Scalars['ID']>;
};

export type IApiUpdateCompanyTypeInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiUpdateContactBranchAssignmentInput = {
  BranchId: Scalars['ID'];
  ContactId: Scalars['ID'];
  endDate?: Maybe<Scalars['Date']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type IApiUpdateContactByInvoiceInput = {
  invoiceId: Scalars['ID'];
  qbListId: Scalars['String'];
};

export type IApiUpdateContactInput = {
  AddBranch?: Maybe<IApiAddContactBranchAssignmentInput>;
  additionalInformation?: Maybe<Scalars['String']>;
  Addresses?: Maybe<Array<Maybe<IApiAddAddressInput>>>;
  AddressIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  BranchAssignments?: Maybe<Array<Maybe<IApiUpdateContactBranchAssignmentInput>>>;
  BranchIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  claimNumber?: Maybe<Scalars['String']>;
  companyNotRequired?: Maybe<Scalars['Boolean']>;
  EmailIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  Emails?: Maybe<Array<Maybe<IApiAddEmailInput>>>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  independentAdjuster?: Maybe<Scalars['Boolean']>;
  insuredName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  PhoneIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  Phones?: Maybe<Array<Maybe<IApiAddPhoneInput>>>;
  policyNumber?: Maybe<Scalars['String']>;
  qbListId?: Maybe<Scalars['String']>;
  Role?: Maybe<Scalars['ID']>;
  suffix?: Maybe<Scalars['String']>;
  thirdPartyAdministrator?: Maybe<Scalars['Boolean']>;
};

export type IApiUpdateContactRoleInput = {
  id: Scalars['ID'];
  isPrivate?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type IApiUpdateDocumentApprovalInput = {
  comment: Scalars['String'];
  id: Scalars['ID'];
};

export type IApiUpdateDocumentCategoryInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiUpdateDocumentCollectionInput = {
  collectionStatus?: Maybe<Scalars['String']>;
  DocumentIds: Array<Maybe<Scalars['ID']>>;
  id: Scalars['ID'];
};

export type IApiUpdateDocumentInput = {
  advanceReport?: Maybe<Scalars['Boolean']>;
  caption?: Maybe<Scalars['String']>;
  EditorId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  InvestigationId?: Maybe<Scalars['ID']>;
  s3Uri?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  TypeId?: Maybe<Scalars['ID']>;
  uri?: Maybe<Scalars['String']>;
};

export type IApiUpdateDocumentShareInput = {
  BillToId?: Maybe<Scalars['ID']>;
  ClientId?: Maybe<Scalars['ID']>;
  DocumentId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  InvestigationInsuredId?: Maybe<Scalars['ID']>;
  UserId?: Maybe<Scalars['ID']>;
};

export type IApiUpdateDocumentSnapshotInput = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type IApiUpdateDocumentTemplateInput = {
  id: Scalars['ID'];
  oneDriveItemId: Scalars['ID'];
};

export type IApiUpdateDocumentTypeInput = {
  CategoryId?: Maybe<Scalars['ID']>;
  defaultTitle?: Maybe<Scalars['String']>;
  DocumentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id: Scalars['ID'];
  isCourtDocument?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  SubServiceTypeId?: Maybe<Scalars['ID']>;
  templateId?: Maybe<Scalars['String']>;
  TemplateId?: Maybe<Scalars['ID']>;
  templateName?: Maybe<Scalars['String']>;
};

export type IApiUpdateDownloadZipUriHookInput = {
  DocumentId: Scalars['ID'];
  InvestigationId: Scalars['ID'];
  s3Uri?: Maybe<Scalars['String']>;
  uploadError?: Maybe<Scalars['String']>;
};

export type IApiUpdateEmailInput = {
  address?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPrimary?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  TypeId?: Maybe<Scalars['ID']>;
};

export type IApiUpdateEmailTypeInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiUpdateEvidenceBillingFrequencyInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiUpdateEvidenceBillingInput = {
  billable?: Maybe<Scalars['Boolean']>;
  BillingFrequencyId?: Maybe<Scalars['ID']>;
  currentExpensed?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  override?: Maybe<Scalars['Boolean']>;
  palletCount?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type IApiUpdateEvidenceInput = {
  comment?: Maybe<Scalars['String']>;
  Comments?: Maybe<Array<Maybe<IApiAddCommentInput>>>;
  description?: Maybe<Scalars['String']>;
  evidenceNumber?: Maybe<Scalars['Int']>;
  EvidenceStorageLocationId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  InvestigationId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  Status?: Maybe<IApiEvidenceStatusType>;
  SubServiceID?: Maybe<Scalars['ID']>;
  takenFrom?: Maybe<Scalars['String']>;
};

export type IApiUpdateEvidenceStatusInput = {
  comment: Scalars['String'];
  id: Scalars['ID'];
  name: IApiEvidenceStatusType;
};

export type IApiUpdateEvidenceStorageLocationInput = {
  additionalDetails?: Maybe<Scalars['String']>;
  Address?: Maybe<IApiAddAddressInput>;
  companyName?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  hasDetails?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isNefco?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  Phone?: Maybe<IApiAddPhoneInput>;
  type?: Maybe<Scalars['String']>;
};

export type IApiUpdateEvidenceTransferInput = {
  comment?: Maybe<Scalars['String']>;
  DocumentId?: Maybe<Scalars['ID']>;
  EvidenceIds: Array<Maybe<Scalars['ID']>>;
  evidenceRow?: Maybe<Scalars['String']>;
  evidenceSection?: Maybe<Scalars['String']>;
  FromLocationId: Scalars['ID'];
  id: Scalars['ID'];
  receivedComment?: Maybe<Scalars['String']>;
  receivedDate?: Maybe<Scalars['Date']>;
  ShippingCarrierId: Scalars['ID'];
  ToLocationId: Scalars['ID'];
  trackingNumber?: Maybe<Scalars['String']>;
};

export type IApiUpdateExpenseCategoryInput = {
  details?: Maybe<Scalars['String']>;
  ExpensePurposeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiUpdateExpenseInput = {
  authorizedBy?: Maybe<Scalars['String']>;
  authorizedDate?: Maybe<Scalars['Date']>;
  billableQuantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  DocumentId?: Maybe<Scalars['ID']>;
  expenseDate?: Maybe<Scalars['Date']>;
  ExpenseItemId?: Maybe<Scalars['ID']>;
  ExpensePurposeId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  InvestigationId?: Maybe<Scalars['ID']>;
  nefcoVehicle?: Maybe<Scalars['Int']>;
  nonBillableQuantity?: Maybe<Scalars['Float']>;
  outOfPocket?: Maybe<Scalars['Int']>;
  paidDate?: Maybe<Scalars['Date']>;
  receipts?: Maybe<Array<Maybe<Scalars['ID']>>>;
  status?: Maybe<Scalars['Int']>;
  UserId?: Maybe<Scalars['ID']>;
};

export type IApiUpdateExpenseItemInput = {
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  descriptionRequired?: Maybe<Scalars['Boolean']>;
  ExpenseApplicationId: Scalars['String'];
  ExpenseCategoryId: Scalars['String'];
  ExpenseTypeId: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  value?: Maybe<Scalars['Decimal']>;
};

export type IApiUpdateExpensePurposeInput = {
  active?: Maybe<Scalars['Boolean']>;
  details?: Maybe<Scalars['String']>;
  ExpenseCategoryIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IApiUpdateFlatRateInput = {
  RiskTypeId?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['Float']>;
};

export type IApiUpdateInsuredPartyInput = {
  Addresses?: Maybe<Array<Maybe<IApiAddAddressInput>>>;
  contactName?: Maybe<Scalars['String']>;
  Emails?: Maybe<Array<Maybe<IApiAddEmailInput>>>;
  id: Scalars['ID'];
  insuredName?: Maybe<Scalars['String']>;
  Phones?: Maybe<Array<Maybe<IApiAddPhoneInput>>>;
  suffix?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type IApiUpdateInvestigationDetailInput = {
  billingInstructionNotes?: Maybe<Scalars['String']>;
  budgetAmount?: Maybe<Scalars['Decimal']>;
  cause?: Maybe<IApiInvestigationDetailCause>;
  claimNumber?: Maybe<Scalars['String']>;
  evidenceGatheringComplete?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  InvestigationId?: Maybe<Scalars['ID']>;
  linkedInvestigationNotes?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  otherContactName?: Maybe<Scalars['String']>;
  otherContactPhone?: Maybe<Scalars['String']>;
  partyNotes?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  riskDescription?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
  staffNotes?: Maybe<Scalars['String']>;
  vehicleNotes?: Maybe<Scalars['String']>;
};

export type IApiUpdateInvestigationInput = {
  billingInstructions?: Maybe<Scalars['String']>;
  BillToBranchId?: Maybe<Scalars['ID']>;
  BillToId?: Maybe<Scalars['ID']>;
  ClientBranchId?: Maybe<Scalars['ID']>;
  ClientId?: Maybe<Scalars['ID']>;
  CompanyId?: Maybe<Scalars['ID']>;
  conflictCheckRun?: Maybe<Scalars['Boolean']>;
  Detail?: Maybe<IApiUpdateInvestigationDetailInput>;
  DocumentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  docusketchRequested?: Maybe<Scalars['Boolean']>;
  EvidenceBillingIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  EvidenceIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  History?: Maybe<IApiAddInvestigationHistoryInput>;
  id: Scalars['ID'];
  InsuredId?: Maybe<Scalars['ID']>;
  InvestigationAddress?: Maybe<IApiAddOrUpdateAddressInput>;
  isIncendiary?: Maybe<Scalars['Boolean']>;
  isOngoing?: Maybe<Scalars['Boolean']>;
  isRejected?: Maybe<Scalars['Boolean']>;
  isRetainerReceived?: Maybe<Scalars['Boolean']>;
  isSymbility?: Maybe<Scalars['Boolean']>;
  isXactimate?: Maybe<Scalars['Boolean']>;
  LinkedInvestigationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  LossAddress?: Maybe<IApiUpdateAddressInput>;
  lossDate?: Maybe<Scalars['Date']>;
  nefcoNumber?: Maybe<Scalars['String']>;
  NefcoOfficeId?: Maybe<Scalars['ID']>;
  newBillTo?: Maybe<Scalars['Boolean']>;
  newClient?: Maybe<Scalars['Boolean']>;
  onHold?: Maybe<Scalars['Boolean']>;
  PartyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  PriorityId?: Maybe<Scalars['ID']>;
  receivedDate?: Maybe<Scalars['Date']>;
  RequestIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  riskDescription?: Maybe<Scalars['String']>;
  RiskTypeId?: Maybe<Scalars['ID']>;
  SceneContactId?: Maybe<Scalars['ID']>;
  ServiceIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  StaffIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  status?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  VehicleIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type IApiUpdateInvestigationOnSceneInput = {
  CreatedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  InvestigationId: Scalars['ID'];
  source?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  UserId: Scalars['ID'];
};

export type IApiUpdateInvestigationOnSceneScheduledDate = {
  data?: Maybe<Array<Maybe<IApiInvestigationOnSceneDate>>>;
  removeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type IApiUpdateInvestigationPartyInput = {
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
};

export type IApiUpdateInvestigationPriorityInput = {
  id: Scalars['ID'];
  InvestigationId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type IApiUpdateInvestigationRoleInput = {
  id: Scalars['ID'];
  PermissionRoleId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type IApiUpdateInvestigationStaffInput = {
  clientRequested?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  RoleId?: Maybe<Scalars['ID']>;
  scheduledSceneExamDate?: Maybe<Scalars['Date']>;
};

export type IApiUpdateInvestigationStatusInput = {
  comment: Scalars['String'];
  CreatedById: Scalars['ID'];
  id: Scalars['ID'];
  InvestigationId: Scalars['ID'];
  name: Scalars['String'];
  requiresFollowUp?: Maybe<Scalars['Boolean']>;
};

export type IApiUpdateInvoiceInput = {
  BillToBranchId?: Maybe<Scalars['ID']>;
  BillToId?: Maybe<Scalars['ID']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  InvoiceLines?: Maybe<Array<Maybe<IApiInvoiceLineInput>>>;
  invoiceNumber?: Maybe<Scalars['String']>;
  InvoiceQbErrors?: Maybe<Array<Maybe<IApiAddInvoiceQbErrorInput>>>;
  memo?: Maybe<Scalars['String']>;
  qbExported?: Maybe<Scalars['Boolean']>;
  qbStatus?: Maybe<Scalars['String']>;
  qbTicketId?: Maybe<Scalars['String']>;
};

export type IApiUpdateNefcoOfficeFromQbInput = {
  invoiceId: Scalars['ID'];
  qbListId?: Maybe<Scalars['String']>;
};

export type IApiUpdateNefcoOfficeInput = {
  Address?: Maybe<IApiAddAddressInput>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type IApiUpdateNotificationInput = {
  id: Scalars['ID'];
  viewed: Scalars['Boolean'];
};

export type IApiUpdateNotificationMethodSettingInput = {
  id: Scalars['ID'];
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type IApiUpdateNotificationMethodSettingInputV2 = {
  data?: Maybe<Array<Maybe<IApiUpdateNotificationSettingForRole>>>;
};

export type IApiUpdateNotificationSettingForRole = {
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isSystem?: Maybe<Scalars['Boolean']>;
  NotificationTypeId: Scalars['ID'];
  PermissionRoleId: Scalars['ID'];
  sendAll?: Maybe<Scalars['Boolean']>;
};

export type IApiUpdatePermissionRoleInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  TimeEntryTypeId?: Maybe<Scalars['ID']>;
};

export type IApiUpdatePhoneInput = {
  extension?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPrimary?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  TypeId?: Maybe<Scalars['ID']>;
};

export type IApiUpdatePhoneTypeInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiUpdatePhotoFolderInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IApiUpdatePhotoInput = {
  caption?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSizeKB?: Maybe<Scalars['Int']>;
  FolderId?: Maybe<Scalars['ID']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isEvidence?: Maybe<Scalars['Boolean']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  lowResS3Uri?: Maybe<Scalars['String']>;
  lowResUri?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  s3Uri?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type IApiUpdatePhotoThumbnailInput = {
  fileSizeKB?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  Photo?: Maybe<Scalars['ID']>;
  scale?: Maybe<Scalars['Decimal']>;
  uri?: Maybe<Scalars['String']>;
};

export type IApiUpdateProgressInput = {
  error?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Float']>;
  uniqueId?: Maybe<Scalars['String']>;
  UserId?: Maybe<Scalars['ID']>;
  zipUrl?: Maybe<Scalars['String']>;
};

export type IApiUpdateRequestInput = {
  Address?: Maybe<IApiUpdateAddressInput>;
  authorizedBy?: Maybe<Scalars['String']>;
  ContactId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  InvestigationId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  RequestTypeId?: Maybe<Scalars['ID']>;
};

export type IApiUpdateRequestStatusInput = {
  comment?: Maybe<Scalars['String']>;
  CreatedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  RequestId?: Maybe<Scalars['ID']>;
};

export type IApiUpdateRequestTypeInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiUpdateScheduledDateInput = {
  date: Scalars['Date'];
  id: Scalars['ID'];
};

export type IApiUpdateServiceInput = {
  AssignedUserIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  authorizedBy?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  InvestigationId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  TypeId?: Maybe<Scalars['ID']>;
};

export type IApiUpdateServiceStatusInput = {
  commment?: Maybe<Scalars['String']>;
  CreatedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  ServiceId?: Maybe<Scalars['ID']>;
};

export type IApiUpdateServiceTypeInput = {
  clientApprovalRequired?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nefcoApprovalRequired?: Maybe<Scalars['Boolean']>;
  RequiredDocumentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  SubServiceTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type IApiUpdateSettingsInput = {
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type IApiUpdateShippingCarrierInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type IApiUpdateSubServiceInput = {
  AssignedUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  InvestigationId?: Maybe<Scalars['ID']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  ServiceId?: Maybe<Scalars['ID']>;
  TypeId?: Maybe<Scalars['ID']>;
};

export type IApiUpdateSubServiceTypeInput = {
  canToggleVisibility: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  RequiredDocuments: Array<Maybe<Scalars['ID']>>;
  ServiceType: Scalars['ID'];
};

export type IApiUpdateTimeEntryInput = {
  description?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  InvestigationId?: Maybe<Scalars['ID']>;
  nonBillableHours?: Maybe<Scalars['Float']>;
  paidDate?: Maybe<Scalars['Date']>;
  TypeId?: Maybe<Scalars['ID']>;
  UserId?: Maybe<Scalars['ID']>;
  workday?: Maybe<Scalars['Date']>;
};

export type IApiUpdateTimeEntryTypeInput = {
  activated?: Maybe<Scalars['Boolean']>;
  additionalDetails?: Maybe<Scalars['String']>;
  appliesTo?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nonStandard?: Maybe<Scalars['Boolean']>;
  qbAccount?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

export type IApiUpdateUserDetailInput = {
  additionalInformation?: Maybe<Scalars['String']>;
  driversLicenseNumber?: Maybe<Scalars['String']>;
  driversLicenseState?: Maybe<Scalars['String']>;
  emergencyContactInfo?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  fullTime?: Maybe<Scalars['Boolean']>;
  hasNefcoCreditCard?: Maybe<Scalars['Boolean']>;
  hasNefcoVehicle?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  payRateHourly?: Maybe<Scalars['Float']>;
  requiredHoursQuarterly?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  UserId?: Maybe<Scalars['ID']>;
  vehicleInsuranceCompany?: Maybe<Scalars['String']>;
  vehicleRegistrationState?: Maybe<Scalars['String']>;
};

export type IApiUpdateUserInput = {
  AddressIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  CertificationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  email?: Maybe<Scalars['String']>;
  EmailIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  msGraphId?: Maybe<Scalars['ID']>;
  nefcoUserId?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  notificationEnabled?: Maybe<Scalars['Boolean']>;
  PermissionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  PhoneIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  Positions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  RegionalManagerId?: Maybe<Scalars['ID']>;
  ServiceIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  suffix?: Maybe<Scalars['String']>;
  TechReviewerId?: Maybe<Scalars['ID']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twilioPhoneNumber?: Maybe<Scalars['String']>;
};

export type IApiUpdateUserOnSceneInput = {
  sceneStatus: IApiUserOnScene;
  twilioPhoneNumber: Scalars['String'];
};

export type IApiUpdateUserProfileInput = {
  Addresses?: Maybe<Array<Maybe<IApiAddAddressInput>>>;
  AssignToRegionalUsers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  AssignToUsers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  email?: Maybe<Scalars['String']>;
  Emails?: Maybe<Array<Maybe<IApiAddEmailInput>>>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  msGraphId?: Maybe<Scalars['ID']>;
  nickname?: Maybe<Scalars['String']>;
  PermissionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  Phones?: Maybe<Array<Maybe<IApiAddPhoneInput>>>;
  Positions?: Maybe<Array<Maybe<IApiUserPositionInput>>>;
  RegionalManagerId?: Maybe<Scalars['ID']>;
  Restrictions?: Maybe<Array<Maybe<IApiUserRestrictions>>>;
  Services?: Maybe<Array<Maybe<IApiAddServiceInput>>>;
  suffix?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twilioPhoneNumber?: Maybe<Scalars['String']>;
  UserDetail?: Maybe<IApiUpdateUserDetailInput>;
  Vehicles?: Maybe<Array<Maybe<IApiAddVehicleInput>>>;
};

export type IApiUpdateVehicleInput = {
  id: Scalars['ID'];
  insuranceCompany?: Maybe<Scalars['String']>;
  InvestigationId?: Maybe<Scalars['ID']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  UserId?: Maybe<Scalars['ID']>;
  vehicleType?: Maybe<Scalars['String']>;
  vinNumber?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type IApiUploadMultipartPartUrlInput = {
  key: Scalars['String'];
  partNumber: Scalars['Float'];
  uploadId: Scalars['String'];
};

export enum IApiUploadTypes {
  Certificates = 'CERTIFICATES',
  Documents = 'DOCUMENTS',
  DocumentsDownloads = 'DOCUMENTS_DOWNLOADS',
  Evidence = 'EVIDENCE',
  Expenses = 'EXPENSES',
  ExpensesUnlinked = 'EXPENSES_UNLINKED',
  Photos = 'PHOTOS',
  Signature = 'SIGNATURE'
}

export type IApiUser = IApiNode & {
  __typename?: 'User';
  Addresses?: Maybe<Array<Maybe<IApiAddress>>>;
  AssignedRegionalStaff?: Maybe<Array<Maybe<IApiUser>>>;
  AssignedStaff?: Maybe<Array<Maybe<IApiUser>>>;
  Certifications?: Maybe<Array<Maybe<IApiCertification>>>;
  createdAt?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  Emails?: Maybe<Array<Maybe<IApiEmail>>>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  InvestigationRoles?: Maybe<Array<Maybe<IApiInvestigationStaff>>>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  msGraphId?: Maybe<Scalars['ID']>;
  msGraphToken?: Maybe<Scalars['String']>;
  nefcoUserId?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  notificationEnabled?: Maybe<Scalars['Boolean']>;
  OnSceneHistory?: Maybe<Array<Maybe<IApiOnScene>>>;
  OnSceneUserHistory?: Maybe<IApiOnScene>;
  PermissionRoles?: Maybe<Array<Maybe<IApiPermissionRole>>>;
  Phones?: Maybe<Array<Maybe<IApiPhone>>>;
  Positions?: Maybe<Array<Maybe<IApiInvestigationRole>>>;
  RegionalManager?: Maybe<IApiUser>;
  RegionalManagerId?: Maybe<Scalars['ID']>;
  Restrictions?: Maybe<Array<Maybe<IApiCertification>>>;
  Roles?: Maybe<Array<Maybe<IApiPermissionRole>>>;
  Services?: Maybe<Array<Maybe<IApiSubService>>>;
  States?: Maybe<Array<Maybe<Scalars['String']>>>;
  suffix?: Maybe<Scalars['String']>;
  TechReviewer?: Maybe<IApiUser>;
  TechReviewerId?: Maybe<Scalars['ID']>;
  TimeEntries?: Maybe<Array<Maybe<IApiTimeEntry>>>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twilioPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  UserDetail?: Maybe<IApiUserDetail>;
  UserSignature?: Maybe<Array<Maybe<IApiUserSignature>>>;
  Vehicles?: Maybe<Array<Maybe<IApiVehicle>>>;
};


export type IApiUserTimeEntriesArgs = {
  fromDate?: Maybe<Scalars['Date']>;
  toDate?: Maybe<Scalars['Date']>;
};

export type IApiUserAvailabilityInput = {
  endTime: Scalars['String'];
  msGraphId: Scalars['String'];
  startTime: Scalars['String'];
  timezone: Scalars['String'];
  userEmails: Array<Scalars['String']>;
};

export type IApiUserDetail = IApiNode & {
  __typename?: 'UserDetail';
  additionalInformation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  driversLicenseNumber?: Maybe<Scalars['String']>;
  driversLicenseState?: Maybe<Scalars['String']>;
  emergencyContactInfo?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  fullTime?: Maybe<Scalars['Boolean']>;
  hasNefcoCreditCard?: Maybe<Scalars['Boolean']>;
  hasNefcoVehicle?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  payRateHourly?: Maybe<Scalars['Float']>;
  remainingHoursQuarterly?: Maybe<Scalars['Float']>;
  requiredHoursQuarterly?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  User?: Maybe<IApiUser>;
  vehicleInsuranceCompany?: Maybe<Scalars['String']>;
  vehicleRegistrationState?: Maybe<Scalars['String']>;
};

export type IApiUserDetailConnection = {
  __typename?: 'UserDetailConnection';
  edges?: Maybe<Array<Maybe<IApiUserDetailEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiUserDetailEdge = {
  __typename?: 'UserDetailEdge';
  cursor: Scalars['String'];
  node: IApiUserDetail;
};

export type IApiUserDetailFilter = {
  type?: Maybe<IApiUserDetailFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiUserDetailFilterType {
  FullTime = 'FULL_TIME',
  Status = 'STATUS'
}

export enum IApiUserDetailOrderBy {
  CreatedAt = 'CREATED_AT'
}

export enum IApiUserDetailStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  OnLeave = 'ON_LEAVE'
}

export type IApiUserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node: IApiUser;
};

export type IApiUserFilter = {
  type?: Maybe<IApiUserFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiUserFilterType {
  AddressType = 'ADDRESS_TYPE',
  Availability = 'AVAILABILITY',
  Certification = 'CERTIFICATION',
  CertificationStatus = 'CERTIFICATION_STATUS',
  Eligibility = 'ELIGIBILITY',
  EntryDateRange = 'ENTRY_DATE_RANGE',
  EntryMonth = 'ENTRY_MONTH',
  EntryYear = 'ENTRY_YEAR',
  ExcludeAssignedToRegionalManager = 'EXCLUDE_ASSIGNED_TO_REGIONAL_MANAGER',
  ExcludeAssignedToTechReviewer = 'EXCLUDE_ASSIGNED_TO_TECH_REVIEWER',
  ExpenseDateRange = 'EXPENSE_DATE_RANGE',
  ExpenseFilterOutForUser = 'EXPENSE_FILTER_OUT_FOR_USER',
  ExpenseMileageType = 'EXPENSE_MILEAGE_TYPE',
  ExpenseMonth = 'EXPENSE_MONTH',
  ExpensePaidDateIsNull = 'EXPENSE_PAID_DATE_IS_NULL',
  ExpenseStatus = 'EXPENSE_STATUS',
  ExpenseYear = 'EXPENSE_YEAR',
  IsFullTime = 'IS_FULL_TIME',
  IsInactive = 'IS_INACTIVE',
  NotUserId = 'NOT_USER_ID',
  Payroll = 'PAYROLL',
  Position = 'POSITION',
  Proximity = 'PROXIMITY',
  RequiredHours = 'REQUIRED_HOURS',
  Search = 'SEARCH',
  SelectedRegionalStaff = 'SELECTED_REGIONAL_STAFF',
  SelectedTechReviwerStaff = 'SELECTED_TECH_REVIWER_STAFF',
  State = 'STATE',
  Status = 'STATUS',
  TimeEntry = 'TIME_ENTRY',
  UserId = 'USER_ID',
  UserIdIn = 'USER_ID_IN',
  ViewAssignmentStaffRole = 'VIEW_ASSIGNMENT_STAFF_ROLE',
  ViewAssignmentView = 'VIEW_ASSIGNMENT_VIEW',
  ViewBookkeepingReport = 'VIEW_BOOKKEEPING_REPORT',
  ViewListView = 'VIEW_LIST_VIEW',
  ViewPayrollReport = 'VIEW_PAYROLL_REPORT',
  ViewStaffUser = 'VIEW_STAFF_USER',
  ViewUserWithPosition = 'VIEW_USER_WITH_POSITION'
}

export enum IApiUserOnScene {
  InTransit = 'InTransit',
  OffScene = 'OffScene',
  OnScene = 'OnScene',
  Scheduled = 'Scheduled'
}

export enum IApiUserOrderBy {
  CertificationExpiration = 'CERTIFICATION_EXPIRATION',
  CreatedAt = 'CREATED_AT',
  Email = 'EMAIL',
  Firstname = 'FIRSTNAME',
  Lastname = 'LASTNAME',
  Proximity = 'PROXIMITY'
}

export type IApiUserPositionInput = {
  InvestigationRoleId: Scalars['ID'];
  UserId: Scalars['ID'];
};

export type IApiUserRestrictions = {
  CertificationTypeId: Scalars['ID'];
  expiresAt: Scalars['Date'];
  issuedAt: Scalars['Date'];
  status: Scalars['String'];
  TypeId: Scalars['ID'];
  UserId: Scalars['ID'];
};

export type IApiUsersConnection = {
  __typename?: 'UsersConnection';
  edges?: Maybe<Array<Maybe<IApiUserEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiUserSignature = IApiNode & {
  __typename?: 'UserSignature';
  createdAt?: Maybe<Scalars['Date']>;
  CreatedBy?: Maybe<IApiUser>;
  id: Scalars['ID'];
  s3Uri: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  url?: Maybe<Scalars['String']>;
  User?: Maybe<IApiUser>;
};

export type IApiUserSignatureInput = {
  s3Uri: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  UserId: Scalars['ID'];
};

export type IApiUserWithDistance = {
  __typename?: 'UserWithDistance';
  distance?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<IApiUser>;
};

export type IApiVehicle = IApiNode & {
  __typename?: 'Vehicle';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  insuranceCompany?: Maybe<Scalars['String']>;
  Investigation?: Maybe<IApiInvestigation>;
  make: Scalars['String'];
  model: Scalars['String'];
  policyNumber?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  User?: Maybe<IApiUser>;
  vehicleType?: Maybe<Scalars['String']>;
  vinNumber?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type IApiVehicleConnection = {
  __typename?: 'VehicleConnection';
  edges?: Maybe<Array<Maybe<IApiVehicleEdge>>>;
  pageInfo: IApiPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IApiVehicleEdge = {
  __typename?: 'VehicleEdge';
  cursor: Scalars['String'];
  node: IApiVehicle;
};

export type IApiVehicleFilter = {
  type?: Maybe<IApiVehicleFilterType>;
  value?: Maybe<Scalars['String']>;
};

export enum IApiVehicleFilterType {
  Assigned = 'ASSIGNED',
  AssignedAndOwn = 'ASSIGNED_AND_OWN',
  CreatedAt = 'CREATED_AT',
  Investigation = 'INVESTIGATION',
  Own = 'OWN',
  User = 'USER'
}

export enum IApiVehicleOrderBy {
  CreatedAt = 'CREATED_AT',
  User = 'USER'
}
