import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { take } from 'rxjs/operators';
import { LocalStorageService } from '../portal-services';

@Injectable({ providedIn: 'root' })
export class PortalAuthGuard  {
  constructor(
    private router: Router,
    private local: LocalStorageService,
    private auth0: AuthService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const user = await await this.auth0.idTokenClaims$.pipe(take(1)).toPromise();
      if (user?.email) this.local.setValueFromSession('formemail', user?.email);
      let email = user?.email || this.local.getValueFromSession('formemail') || this.local.getValueFromSession('clientemail');
      if (email) {
        // logged in so return true
        return true;
      }
    } catch (e) { }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/portal/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
