import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';

import {
  CompanyInformationComponent,
  CompanyListComponent,
  CompanyBranchListComponent,
  CompanyBranchInformationComponent,
  CompanyManagementComponent,
  CompanyCreateUpdateComponent
} from './';
import { CompanyBranchCreateModalComponent } from './company-branch-create-modal/company-branch-create-modal.component';
import { CompanyBranchContactSelectComponent } from './company-branch-contact-select/company-branch-contact-select.component';

@NgModule({
  declarations: [
    CompanyListComponent,
    CompanyBranchListComponent,
    CompanyBranchInformationComponent,
    CompanyManagementComponent,
    CompanyInformationComponent,
    CompanyCreateUpdateComponent,
    CompanyBranchCreateModalComponent,
    CompanyBranchContactSelectComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: []
})
export class CompaniesModule { }
