import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { groupBy } from '@progress/kendo-data-query';
import { cloneDeep } from 'lodash';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { ReactiveFormHelper } from 'src/app/shared/helpers/reactive-form.class';
import { IState } from 'src/app/shared/interfaces/state.interfaces';
import { IApiAddEmailInput, IApiAddInsuredPartyInput, IApiAddPhoneInput, IApiAddressType, IApiEmailType, IApiPhoneType, IApiUpdateInsuredPartyInput } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { removeTypename } from 'src/app/shared/rxjs.pipes';
import { AddressTypeService, ContactService, EmailTypeService, PhoneTypeService, PortalService, StateService } from 'src/app/shared/services';
import { InsuredPartyService } from 'src/app/shared/services/insured-party/insured-party.service';

@Component({
  selector: 'app-investigation-insured-party-modal',
  templateUrl: './investigation-insured-party-modal.component.html',
  styleUrls: ['./investigation-insured-party-modal.component.scss']
})
export class InvestigationInsuredPartyModalComponent extends DialogContentBase implements OnInit {

  @Input() public set party(value: any) {
    this._party = value;
  }

  @Input() public set data(value: any) {
    this._data = value;
  }

  public stateList: Array<IState>;
  public stateFiltered: Array<IState>;
  public groupedDataStates: any;
  public addressTypes: IApiAddressType[] = [];
  public phoneTypes: IApiPhoneType[] = [];
  public emailTypes: IApiEmailType[] = [];
  public _party: any;
  public _data: any;
  public isAdd: boolean =false;

  // this.fb.group({
  //   number : ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
  //   TypeId: ['', [Validators.required]],
  // });

  public form = this.fb.group({
    insuredType: [true, [Validators.required]],
    insuredName: ['', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
    suffix: [''],
    nickName: [''],
    companyName: [''],
    contactName: [''],
    other: [''],
    Phones: this.fb.array([]),
    Addresses: this.fb.array([]),
    Emails: this.fb.array([])

  });

  constructor(
    public dialog: DialogRef,
    public fb: UntypedFormBuilder,
    public stateService: StateService,
    public loader: LoaderService,
    public phoneTypeService: PhoneTypeService,
    private addressTypeService: AddressTypeService,
    private emailTypeService: EmailTypeService,
    private loaderService: LoaderService,
    private notifications: NotificationsService,
    private insuredPartyService: InsuredPartyService
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    this.phoneTypeService.get().pipe(
      unwrapConnection(),
      removeTypename()
    ).subscribe((types) => {
      this.phoneTypes = types;
    });
    this.addressTypeService.get().pipe(
      unwrapConnection(),
    ).subscribe((types) => {
      this.addressTypes = types;
    });
    this.emailTypeService.get().pipe(
      unwrapConnection(),
      removeTypename()
    ).subscribe((types) => {
      this.emailTypes = types;
    });
    this.stateList = this.stateService.allStates;
    this.stateFiltered = this.stateService.allStates;
    this.groupedDataStates = groupBy(this.stateFiltered, [
      { field: "type" },
    ]);

    if (this._party) {
      this.setFormData();
    } else {
      if(this._data?.isAdd){
        this.isAdd = true;
      }
      this.addAdditionalContactToList();
      if(this._data?.lossAddress){
        this.addAddresses(this._data?.lossAddress);
      }else{
        this.addAddresses();
      }
      this.addAdditionalEmailsToList();
    }
  }

  public setFormData() {
    if (this._party?.contactName) {
      this.changedInsuredType(false)
      this.form.get('insuredType').patchValue(false)
      // set company name and contact name
      this.form.get('companyName').patchValue(this._party?.insuredName || '');
      this.form.get('contactName').patchValue(this._party?.contactName?.trim());
    } else {
      this.changedInsuredType(true)
      this.form.get('insuredType').patchValue(true);
      this.form.get('insuredName').patchValue(this._party?.insuredName?.trim());
    }

    this.form.get('suffix').patchValue(this._party?.suffix || '');
    this.form.get('nickName').patchValue(this._party?.title || '');

    if (this._party?.Addresses?.length) {
      this._party?.Addresses?.map(address => this.addAddresses(address));
    } else {
      this.addAddresses();
    }

    if (this._party?.Phones?.length) {
      const phones = this._party?.Phones?.sort((a, b) => b.isPrimary - a.isPrimary);
      phones?.map(phone => this.addAdditionalContactToList(phone));
    } else {
      this.addAdditionalContactToList();
    }

    if (this._party?.Emails?.length) {
      const Emails = this._party?.Emails?.sort((a, b) => b.isPrimary - a.isPrimary);
      Emails?.map(email => this.addAdditionalEmailsToList(email));
    } else {
      this.addAdditionalEmailsToList()
    }
  }

  public handleFilter(value) {
    this.stateFiltered = this.stateList.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    this.groupedDataStates = groupBy(this.stateFiltered, [
      { field: "type" },
    ]);
  }

  public changedInsuredType(type: boolean) {
    if (type) {
      this.form.get('insuredName').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.patchValue({ companyName: '' });
      this.form.patchValue({ contactName: '' });
      this.form.get('companyName').clearValidators();
      this.form.get('contactName').clearValidators();
    } else {
      this.form.patchValue({ insuredName: '' });
      this.form.get('insuredName').clearValidators();
      this.form.get('companyName').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.form.get('contactName').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
    }
    this.form.get('insuredName').updateValueAndValidity();
    this.form.get('companyName').updateValueAndValidity();
    this.form.get('contactName').updateValueAndValidity();
  }

  get PhonesFieldAsFormArray(): UntypedFormArray {
    return this.form.get('Phones') as UntypedFormArray;
  }

  public addAdditionalContact(phone): any {
    return this.fb.group({
      number: [
        phone ? phone?.number : "",
        [ReactiveFormHelper.noWhitespaceValidator],
      ],
      TypeId: [phone ? phone?.TypeId : "", []],
      type: [phone ? phone?.type: "", [ReactiveFormHelper.noWhitespaceValidator]],
      extension: [
        phone ? phone?.extension : "",
        [
          Validators.minLength(0),
          Validators.maxLength(5),
          ReactiveFormHelper.noWhitespaceValidator,
          ReactiveFormHelper.numberOnly,
        ],
      ],
      isPrimary: [
        phone?.isPrimary
          ? phone?.isPrimary
          : this.PhonesFieldAsFormArray?.length === 0
          ? true
          : false,
      ],
    });
  }

  public addAdditionalContactToList(phone = {}): void {
    this.PhonesFieldAsFormArray.push(this.addAdditionalContact(phone));
  }

  public removeaddAdditionalContact(i: number): void {
    this.PhonesFieldAsFormArray.removeAt(i);
  }

  get EmailsFieldAsFormArray(): UntypedFormArray {
    return this.form.get('Emails') as UntypedFormArray;
  }

  public addAdditionalEmails(email): any {
    return this.fb.group({
      address: [email ? email?.address : '', [ReactiveFormHelper.emailValidator, ReactiveFormHelper.noWhitespaceValidator]],
      TypeId: [email ? email?.TypeId : '', []],
      isPrimary: [email?.isPrimary ? email?.isPrimary : (this.EmailsFieldAsFormArray?.length === 0 ? true : false)]
    });
  }

  public addAdditionalEmailsToList(email = {}): void {
    this.EmailsFieldAsFormArray.push(this.addAdditionalEmails(email));
  }

  public removeaddAdditionalEmails(i: number): void {
    this.EmailsFieldAsFormArray.removeAt(i);
  }


  get AddressesFieldAsFormArray(): UntypedFormArray {
    return this.form.get('Addresses') as UntypedFormArray;
  }

  public addAddress(address): any {
    return this.fb.group({
      TypeId: [address ? address?.Type?.id : '', [Validators.required]],
      address1: [address ? address?.address1 : '', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      address2: [address ? address?.address2 : ''],
      city: [address ? address?.city : '', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      state: [address ? address?.state : '', [Validators.required]],
      postal: [address ? address?.postal : '', [Validators.required, ReactiveFormHelper.noWhitespaceValidator]],
      country: [""]
    });
  }

  public addAddresses(address = {}): void {
    this.AddressesFieldAsFormArray.push(this.addAddress(address));
  }

  public removeAddress(i: number): void {
    this.AddressesFieldAsFormArray.removeAt(i);
  }

  public onClose(text: string): void {
    this.dialog.close(text);
  }

  public createInsured() {
    if (this.form.valid) {
      let addresses = this.form.value?.Addresses;
      if (addresses?.length) {
        addresses = addresses?.map(add => {
          const country = ReactiveFormHelper.findCountryByStateCode(this.stateFiltered, add.state);
          return {
            ...add, country: country
          }
        })
      }
      let emails = this.form.value?.Emails || [];
      let phones = this.form.value?.Phones || [];
      const payload: IApiAddInsuredPartyInput = {
        insuredName: this.form.value.insuredType === true ? this.form.value.insuredName : this.form.value.companyName,
        contactName: this.form.value.insuredType === true ? "" : this.form.value.contactName,
        suffix: this.form.value?.suffix,
        title: this.form.value?.nickName,
        Emails: emails.filter((item: IApiAddEmailInput) => item.TypeId && item.address),
        Phones: phones.filter((item: IApiAddPhoneInput) => item.TypeId && item.number),
        Addresses: addresses,
      };
      this.loaderService.show$(
        this.insuredPartyService.add(payload).pipe(
          this.notifications.snackbarErrorPipe(),
        )
      ).subscribe((response) => this.dialog.close(response));
    } else {
      this.form?.markAllAsTouched();
    }
  }

  public updateInsured() {
    if (this.form.valid) {
      let addresses = this.form.value?.Addresses;
      if (addresses?.length) {
        addresses = addresses?.map(add => {
          const country = ReactiveFormHelper.findCountryByStateCode(this.stateFiltered, add.state);
          return {
            ...add, country: country
          }
        })
      }

      let emails = this.form.value?.Emails || [];
      let phones = this.form.value?.Phones || [];

      const payload: IApiUpdateInsuredPartyInput = {
        id: this._party?.id,
        insuredName: this.form.value.insuredType === true ? this.form.value.insuredName : this.form.value.companyName,
        contactName: this.form.value.insuredType === true ? "" : this.form.value.contactName,
        suffix: this.form.value?.suffix,
        title: this.form.value?.nickName,
        Emails: emails.filter((item: IApiAddEmailInput) => item.TypeId && item.address),
        Phones: phones.filter((item: IApiAddPhoneInput) => item.TypeId && item.number),
        Addresses: addresses,
      };
      this.loaderService.show$(
        this.insuredPartyService.update(payload).pipe(
          this.notifications.snackbarErrorPipe(),
        )
      ).subscribe((response) => this.dialog.close(response));
    } else {
      this.form?.markAllAsTouched();
    }
  }

  public setEmailListValidator (type: string, value: string, refValue: string, i: number) {
    if (type === 'address') {
      if (value && !refValue) {
        this.EmailsFieldAsFormArray.controls[i].get('TypeId').setValidators([Validators.required])
      } else if (!value && refValue) {
        this.EmailsFieldAsFormArray.controls[i].get('address').setValidators([Validators.required, ReactiveFormHelper.emailValidator, ReactiveFormHelper.noWhitespaceValidator])
      } else if (value) {
        this.EmailsFieldAsFormArray.controls[i].get('address').setValidators([Validators.required, ReactiveFormHelper.emailValidator, ReactiveFormHelper.noWhitespaceValidator])
      } else {
        this.EmailsFieldAsFormArray.controls[i].get('TypeId').clearValidators();
      }
    } else {
      if (value && !refValue) {
        this.EmailsFieldAsFormArray.controls[i].get('address').setValidators([Validators.required, ReactiveFormHelper.emailValidator, ReactiveFormHelper.noWhitespaceValidator])
      } else if (!value && refValue) {
        this.EmailsFieldAsFormArray.controls[i].get('TypeId').setValidators([Validators.required])
      } else {
        this.EmailsFieldAsFormArray.controls[i].get('address').clearValidators();
      }
    }
    this.EmailsFieldAsFormArray.controls[i].get('address').updateValueAndValidity();
    this.EmailsFieldAsFormArray.controls[i].get('TypeId').updateValueAndValidity();
  }

  public setPhoneListValidator (type: string, value: string, refValue: string, i: number) {
    if (type === 'number') {
      if (value && !refValue) {
        this.PhonesFieldAsFormArray.controls[i].get('TypeId').setValidators([Validators.required])
      } else if (!value && refValue) {
        this.PhonesFieldAsFormArray.controls[i].get('number').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator, Validators.pattern(/^\d{10}$/)])
      } else if (value) {
        this.PhonesFieldAsFormArray.controls[i].get('number').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator, Validators.pattern(/^\d{10}$/)])
      } else {
        this.PhonesFieldAsFormArray.controls[i].get('TypeId').clearValidators();
      }
    } else {
      if (value && !refValue) {
        this.PhonesFieldAsFormArray.controls[i].get('number').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator, Validators.pattern(/^\d{10}$/)])
      } else if (!value && refValue) {
        this.PhonesFieldAsFormArray.controls[i].get('TypeId').setValidators([Validators.required])
      } else {
        this.PhonesFieldAsFormArray.controls[i].get('number').clearValidators();
      }
    }
    this.PhonesFieldAsFormArray.controls[i].get('number').updateValueAndValidity();
    this.PhonesFieldAsFormArray.controls[i].get('TypeId').updateValueAndValidity();
  }

}
