import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { ReactiveFormHelper } from 'src/app/shared/helpers/reactive-form.class';

@Component({
  selector: 'app-loss-details-special-instructions',
  templateUrl: './loss-details-special-instructions.component.html',
  styleUrls: ['./loss-details-special-instructions.component.scss']
})
export class LossDetailsSpecialInstructionsComponent implements OnInit {

  @Input() formGroupName: string;
  lossDetailsSpecialInstructions: UntypedFormGroup;

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.lossDetailsSpecialInstructions = this.rootFormGroup.control.get(this.formGroupName) as UntypedFormGroup;
    this.lossDetailsSpecialInstructions.addValidators([
      ReactiveFormHelper.atLeastOneChecked("docusketchRequested", "isXactimate", "isSymbility"),
    ]);
  }

}
