import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  CompanyManagementComponent,
  CompanyListComponent,
  CompanyCreateUpdateComponent,
  CompanyBranchInformationComponent
} from '../shared/companies';

import { ContactsListComponent } from '../shared/contacts';
import { CertificationsManagementComponent } from '../shared/certifications';
import { InvestigationListComponent } from '../shared/investigations/investigation-list/investigation-list.component';
import { StaffListComponent } from '../shared/staff/staff-list/staff-list.component';

import { BookkeepingManagementComponent, BookkeepingEvidenceBillingComponent } from '../shared/bookkeeping';
import { InvestigationCreateComponent, InvestigationEditComponent } from '../shared/investigations';
import { BookkeepingInvoiceListComponent } from "../shared/bookkeeping/bookkeeping-invoice-list/bookkeeping-invoice-list.component";

import { BookkeepingPayrollManagementComponent } from '../shared/bookkeeping/bookkeeping-payroll-management/bookkeeping-payroll-management.component';
import { BookkeepingExpenseReportsTabsComponent } from '../shared/bookkeeping/bookkeeping-expense-reports/bookkeeping-expense-reports-tabs/bookkeeping-expense-reports-tabs.component';
import { BookkeepingTimeAndExpensesComponent } from '../shared/bookkeeping/bookkeeping-time-and-expenses/bookkeeping-time-and-expenses.component';
import { PermissionsRolesListComponent } from './permissions/permissions-roles-list/permissions-roles-list.component';
import { PermissionsManagementComponent } from './permissions/permissions-management/permissions-management.component';
import { CarriersListComponent } from '../shared/carriers/carriers-list/carriers-list.component';
import { StaffManagementComponent } from '../shared/staff';

import { DocumentTypeListComponent } from "../shared/documents/document-type-list/document-type-list.component";
import { EvidenceManagementComponent } from '../shared/evidence/evidence-management/evidence-management.component';
import { InvestigationResolver } from "src/app/shared/route.resolvers";
import { PermissionGuard, CanDeactivateAddFormDirty, CanDeactivateEditFormDirty, MultiPermissionGuard } from "src/app/shared/route.guards";
import { DocumentTemplatingComponent } from './document-templating/document-templating.component';
import { PermissionActions, PermissionCategories } from 'src/app/shared/modules/graphql/constants/permission.constants';
import { investigation, investigationAssignStaffUpdate, investigationBillingInformationUpdate, investigationCreate, investigationInformationUpdate, investigationLinkedInvestigationUpdate, investigationPartiesUpdate, investigationVehiclesUpdate } from 'src/app/shared/helpers/auth-config/investigations.config';
import { AccessRestrictedModule } from '../access-restricted/access-restricted.module';
import { NotificationsManagementComponent } from '../shared/notifications';
import { DownloadMobileAppComponent } from './download-mobile-app/download-mobile-app.component';
import { SettingsComponent } from '../shared/settings/settings/settings.component';
import { NefcoOfficeListComponent } from '../shared/nefco-offices/nefco-office-list/nefco-office-list.component';

const routes: Routes = [
  {
      path: "settings",
      component: SettingsComponent
  },
  {
    path: "bookkeeping",
    children: [
      {
        path: "management",
        component: BookkeepingManagementComponent
      },
      {
        path: "invoice/list",
        component: BookkeepingInvoiceListComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.BOOKKEEPING_INVOICE_LIST, appliedPermissions: { All: [PermissionActions.LIST]}}
      },
      {
        path: "payroll",
        component: BookkeepingPayrollManagementComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.BOOKKEEPING_PAYROLL, appliedPermissions: { All: [PermissionActions.LIST]}}
      },
      {
        path: "expenses",
        component: BookkeepingExpenseReportsTabsComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.BOOKKEEPING_EXPENSE_REPORT, appliedPermissions: { All: [PermissionActions.LIST]}}
      },
      {
        path: "evidence/billing",
        component: BookkeepingEvidenceBillingComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.BOOKKEEPING_EVIDENCE_BILLING, appliedPermissions: { All: [PermissionActions.LIST]}}
      },
      {
        path: "time",
        component: BookkeepingTimeAndExpensesComponent
      }
    ]
  },
  {
    path: "companies",
    children: [
      {
        path: "management/:companyId",
        component: CompanyManagementComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.COMPANIES, appliedPermissions: { All: [PermissionActions.VIEW] }},
      },
      {
        path: "management/:companyId/branch/:branchId",
        component: CompanyBranchInformationComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.COMPANIES_BRANCHES, appliedPermissions: { All: [PermissionActions.VIEW] }},
      },
      {
        path: "list",
        component: CompanyListComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.COMPANIES, appliedPermissions: { All: [PermissionActions.LIST] }},
      },
      {
        path: "create",
        component: CompanyCreateUpdateComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.COMPANIES, appliedPermissions: { All: [PermissionActions.CREATE] }},
      }
    ]
  },
  {
    path: "contacts",
    children: [
      {
        path: "list",
        component: ContactsListComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.CONTACTS, appliedPermissions: { All: [PermissionActions.LIST], Own: [PermissionActions.LIST]}},
      }
    ]
  },
  {
    path: "certifications",
    children: [
      {
        path: "manage",
        component: CertificationsManagementComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.MANAGE_CERTIFICATIONS, appliedPermissions: { All: [PermissionActions.LIST]}}
      }
    ]
  },
  {
    path: "carriers",
    children: [
      {
        path: "list",
        component: CarriersListComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.MANAGE_CARRIERS, appliedPermissions: { All: [PermissionActions.LIST]}}
      }
    ]
  },
  {
    path: "documents",
    children: [
      {
        path: "list",
        component: DocumentTypeListComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.MANAGE_DOCUMENT_TYPES, appliedPermissions: { All: [PermissionActions.LIST]}}
      }
    ]
  },
  {
    path: "evidence",
    children: [
      {
        path: "manage",
        component: EvidenceManagementComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.MANAGE_EVIDENCE_LOCATIONS, appliedPermissions: { All: [PermissionActions.LIST]}},
      }
    ]
  },
  {
    path: "nefco-offices",
    children: [
      {
        path: "list",
        component: NefcoOfficeListComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.MANAGE_NEFCO_OFFICES, appliedPermissions: { All: [PermissionActions.LIST]}},
      }
    ]
  },
  {
    path: "investigations",
    children: [
      {
        path: "list",
        component: InvestigationListComponent,
        canActivate: [PermissionGuard],
        data: investigation,
      },
      {
        path: "create",
        runGuardsAndResolvers: "always",
        canDeactivate: [CanDeactivateAddFormDirty],
        component: InvestigationCreateComponent,
        canActivate: [PermissionGuard],
        data: investigationCreate,
      },
      {
        path: "edit/:investigationId",
        component: InvestigationEditComponent,
        canDeactivate: [CanDeactivateEditFormDirty],
        runGuardsAndResolvers: "always",
        resolve: {
          investigation: InvestigationResolver
        },
        canActivate: [MultiPermissionGuard],
        data: [investigationInformationUpdate,
          investigationBillingInformationUpdate,
          investigationLinkedInvestigationUpdate,
          investigationVehiclesUpdate,
          investigationPartiesUpdate,
          investigationAssignStaffUpdate
        ],
      },
      {
        path: "create/:investigationId",
        component: InvestigationCreateComponent,
        canDeactivate: [CanDeactivateAddFormDirty],
        runGuardsAndResolvers: "always",
        resolve: {
          investigation: InvestigationResolver
        }
      },
    ]
  },
  {
    path: "staff",
    children: [
      {
        path: "list",
        component: StaffListComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.STAFF, appliedPermissions: { All: [PermissionActions.LIST]}},
      },
      {
        path: "manage", //NOTE: not used
        component: StaffManagementComponent
      }
    ]
  },
  {
    path: "permissions",
    children: [
      {
        path: "list",
        component: PermissionsRolesListComponent,
        canActivate: [PermissionGuard],
        data: { category: PermissionCategories.MANAGE_USER_ROLES, appliedPermissions: { All: [PermissionActions.LIST]}}
      },
      {
        path: "management/:id",
        component: PermissionsManagementComponent
      }
    ]
  },
  {
    path: "notifications",
    component: NotificationsManagementComponent
  },
  {
    path: "download",
    component: DownloadMobileAppComponent,
    canActivate: [PermissionGuard],
    data: { category: PermissionCategories.MOBILE_APPLICATION_DOWNLOAD, appliedPermissions: { All: [PermissionActions.DOWNLOAD]}}
  },
  {
    path: 'templating',
    component: DocumentTemplatingComponent,
    canActivate: [PermissionGuard],
    data: { category: PermissionCategories.MANAGE_DOCUMENT_TEMPLATING, appliedPermissions: { All: [PermissionActions.VIEW]}}
  },
  {
    path: "access-restricted",
    loadChildren: () => AccessRestrictedModule
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminModuleRouter { }
