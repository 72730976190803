import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import moment from 'moment';
import { AuthService, DocumentService, DocumentTypeService, ExpenseService } from 'src/app/shared/services';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { IApiAddExpenseInput, IApiDocumentType, IApiExpense, IApiExpenseFilter, IApiExpenseFilterType, IApiExpenseItem, IApiExpenseMileageTotal, IApiExpenseOrderBy, IApiInvestigation, IApiTimeEntry, IApiUpdateExpenseInput } from "src/app/shared/modules/graphql/types/types";
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { filter, switchMap } from 'rxjs/operators';
import { ExpenseDataSource } from 'src/app/shared/services/expenses';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IInvestigationTimeExpenseModalData, InvestigationTimeAndExpModalKendoComponent } from '../../investigations/investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { expenseInvestigationExpensesDelete, expenseInvestigationExpensesDeleteOwn, expenseInvestigationExpensesUpdate, expenseInvestigationExpensesUpdateOwn, expenseInvestigationMileageDelete, expenseInvestigationMileageDeleteOwn, expenseInvestigationMileageUpdate, expenseInvestigationMileageUpdateOwn } from 'src/app/shared/helpers/auth-config/time-expenses.config';

interface IUpdateExpenseInputAndReceipt extends IApiUpdateExpenseInput {
  Receipt?: {
    url: string,
    filename: string,
    size: number
  };
}

@Component({
  selector: 'app-time-exp-expenses-table-kendo',
  templateUrl: './time-exp-expenses-table-kendo.component.html',
  styleUrls: ['./time-exp-expenses-table-kendo.component.scss']
})
export class TimeExpExpensesTableKendoComponent implements OnInit, OnChanges {

  public authConfig = {
    expenseInvestigationExpensesUpdate,
    expenseInvestigationExpensesUpdateOwn,
    expenseInvestigationExpensesDelete,
    expenseInvestigationExpensesDeleteOwn,
    expenseInvestigationMileageUpdate,
    expenseInvestigationMileageUpdateOwn,
    expenseInvestigationMileageDelete,
    expenseInvestigationMileageDeleteOwn
  };

  @ViewChild("expenseInvestigationExpensesUpdate") expenseInvestigationExpensesUpdatePermission;
  @ViewChild("expenseInvestigationExpensesUpdateOwn") expenseInvestigationExpensesUpdateOwnPermission;
  @ViewChild("expenseInvestigationExpensesDelete") expenseInvestigationExpensesDeletePermission;
  @ViewChild("expenseInvestigationExpensesDeleteOwn") expenseInvestigationExpensesDeleteOwnPermission;
  @ViewChild("expenseInvestigationMileageUpdate") expenseInvestigationMileageUpdatePermission;
  @ViewChild("expenseInvestigationMileageUpdateOwn") expenseInvestigationMileageUpdateOwnPermission;
  @ViewChild("expenseInvestigationMileageDelete") expenseInvestigationMileageDeletePermission;
  @ViewChild("expenseInvestigationMileageDeleteOwn") expenseInvestigationMileageDeleteOwnPermission;

  @Input() userId: string = "";
  @Input() investigations: IApiInvestigation[];
  @Input() expenseItems: IApiExpenseItem[];
  @Input() investigationId?: string = '';
  @Input() isMileage: boolean = false;
  @Input() investigationView: boolean = false;
  @Input() reloadExpense: boolean = false;
  @Input() rangerAndWeekFilters: { dateRangeFilter: string, dateFilterYear: string, dateFilterWeek: string } = null;

  public displayedColumns = ["date", "invId", "expense", "purpose", "qty", "receipt", "auth", "paid", "actions"];

  // Private variables
  private _expenses: IApiExpense[] = [];
  private _expenseFilter: IApiExpenseFilter[] = [];
  private _selectedInvestigation = null;
  private _expenseForm: IApiAddExpenseInput;
  private _mileageForm: IApiAddExpenseInput;

  // Variables for filters
  public dateFilterType = "expense";
  private dateFilterWeek: string = (moment().isoWeek()).toString();
  private dateFilterMonth: string = (moment().month() + 1).toString();
  private dateFilterYear: string = (moment().year()).toString();
  private dateRangeFilter: string;
  // Document Upload
  public documentTypes: IApiDocumentType[];

  public get selectedInvestigation() {
    return this._selectedInvestigation;
  }
  public set selectedInvestigation(val) {
    this._selectedInvestigation = val;
  }

  public get mileageForm() {
    return this._mileageForm;
  }
  public set mileageForm(val) {
    this._mileageForm = val;
  }

  public get expenseForm() {
    return this._expenseForm;
  }
  public set expenseForm(val) {
    this._expenseForm = val;
  }

  public get expenses() {
    return this._expenses;
  }
  public set expenses(val: IApiExpense[]) {
    this._expenses = val;
  }

  public expenseMileageCount: IApiExpenseMileageTotal;

  public sort: SortDescriptor[] = [{
    field: 'EXPENSE_DATE',
    dir: 'desc'
  }];

  private _dataSource: ExpenseDataSource;

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }
  public authenticatedUserId = null;

  constructor(
    private expenseService: ExpenseService,
    private notifications: NotificationsService,
    private documentService: DocumentService,
    private documentTypeService: DocumentTypeService,
    private loader: LoaderService,
    private dialogService: DialogService,
    public auth: AuthService,
  ) {
    this.dataSource = new ExpenseDataSource(this.expenseService);
    this.dataSource.listPage.limit = 5;
    this.dataSource.listPage.sortOrder = SortOrder.DESC;
    this.dataSource.listPage.orderBy = IApiExpenseOrderBy.ExpenseDate;
    this.loader.attachObservable(this.dataSource.loading$);

    this.expenseForm = {
      expenseDate: null,
      billableQuantity: null,
      nonBillableQuantity: null,
      nefcoVehicle: 0,
      outOfPocket: 0,
      authorizedBy: '',
      authorizedDate: null,
      description: '',
      ExpenseItemId: '',
      ExpensePurposeId: '',
      InvestigationId: null,
      paidDate: null
    };

    this.mileageForm = {
      expenseDate: null,
      billableQuantity: null,
      nonBillableQuantity: null,
      nefcoVehicle: 0,
      outOfPocket: 0,
      authorizedBy: '',
      authorizedDate: null,
      description: '',
      ExpenseItemId: '',
      ExpensePurposeId: '',
      InvestigationId: null,
      paidDate: null
    };
  }

  public applyFilters() {
    if (this.dateRangeFilter) {
      this.setExpenseFilters(null, IApiExpenseFilterType.ExpenseMonth);
      this.setExpenseFilters(null, IApiExpenseFilterType.ExpenseYear)
      this.setExpenseFilters(null, IApiExpenseFilterType.ExpensePaidDate);
      this.setExpenseFilters(this.dateRangeFilter, IApiExpenseFilterType.DateRange)
    } else {
      this.setExpenseFilters(null, IApiExpenseFilterType.DateRange)
      if (this.dateFilterType === "expense") {
        this.setExpenseFilters(this.dateFilterMonth, IApiExpenseFilterType.ExpenseMonth);
        this.setExpenseFilters(this.dateFilterYear, IApiExpenseFilterType.ExpenseYear)
        this.setExpenseFilters(null, IApiExpenseFilterType.ExpensePaidDate);
      }
      else {
        this.setExpenseFilters(null, IApiExpenseFilterType.ExpenseYear)
        this.setExpenseFilters(null, IApiExpenseFilterType.ExpenseMonth);
        this.setExpenseFilters(this.dateFilterMonth, IApiExpenseFilterType.ExpensePaidDate);
      }
    }
    this.getExpenses();
  }

  public dateRangeChange(event) {
    this.dateRangeFilter = event;
    this.applyFilters();
  }

  public dateChange(event) {
    this.dateRangeFilter = '';
    this.dateFilterYear = (event.year()).toString();
    this.dateFilterMonth = (event.month() + 1).toString();
    this.applyFilters();
  }

  public investigationChanged(event) {
    if (event)
      this.setExpenseFilters(event, IApiExpenseFilterType.Investigation);
    else
      this.dataSource.removeFilter(IApiExpenseFilterType.Investigation);
    this.getExpenses();
  }

  public editExpense(item?: IApiExpense) {
    const expenseItem = (item as IApiExpense)?.expenseDate ? { ...item } : {
      id: null,
      nefcoVehicle: 0,
      expenseDate: new Date(),
      outOfPocket: 0,
      billableQuantity: null,
      nonBillableQuantity: null,
      ExpenseItem: { id: null, name: null },
      ExpensePurpose: { id: null, name: null }
    };

    const data: IInvestigationTimeExpenseModalData = {
      investigationId: item.Investigation?.id,
      nefcoNumber: item.Investigation?.nefcoNumber,
      expense: expenseItem as IApiExpense,
      time: null,
      staff: null,
      adminView: false,
      showStaffMember: false,
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpModalKendoComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpModalKendoComponent;
    dialogInstance.data = data;
    dialogInstance.documentTypes = this.documentTypes;
    dialogInstance.investigations = this.investigations;
    dialogInstance.expenseItems = this.expenseItems;
    dialogInstance.investigationView = this.investigationView;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) this.getExpenses();
    })
  }

  public deleteExpense(entry) {

    this.notifications.kendoConfirm(
      "Are you sure you want to delete this expense entry?",
      "Delete Expense Entry?",
      "No, Don’t Delete",
      "Yes, Delete"
    )
      .pipe(
        filter((v) => !!v),
        switchMap(() => this.expenseService.remove(entry.id))
      )
      .subscribe(() => {
        this.getExpenses();
      });
  }

  private setExpenseFilters(value: string | undefined, type: IApiExpenseFilterType) {
    const filtersCopy = this._expenseFilter.filter(f => f.type !== type);
    this._expenseFilter = [...filtersCopy, {
      type: type,
      value: value
    }]
    this._expenseFilter.map(item => {
      this.dataSource.applyFilter(item.type, item.value);
    })
  }

  private getExpenses() {
    this.getExpenseMileageTotal()
    this.dataSource.load()
    this.dataSource?.contents$.subscribe((res) => {
      this.expenses = res;
      setTimeout(() => {
        try {
          document.getElementsByTagName('body')?.[0].click();
        } catch (error) { }
      }, 100);
    });
  }

  private getExpenseMileageTotal() {
    this.expenseService.getExpenseMileageTotal(this.dataSource.lastFilters, this.pageOptions.getPageOptions())
      .subscribe(response => this.expenseMileageCount = response);

  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'EXPENSE_DATE';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.getExpenses();
  }

  ngOnInit() {
    this.auth.authenticatedUser.subscribe((u) => this.authenticatedUserId = u.id);
    if (this.investigationId && this.isMileage === false) {
      this.setExpenseFilters(this.userId, IApiExpenseFilterType.User);
      this.setExpenseFilters(this.investigationId, IApiExpenseFilterType.Investigation);
      this.setExpenseFilters(`${this.isMileage}`, IApiExpenseFilterType.Mileage);
      /* All Default */
      // this.setExpenseFilters(this.dateFilterWeek, IApiExpenseFilterType.ExpenseWeek)
    } else if (this.investigationId && this.isMileage === true) {
      this.setExpenseFilters(this.userId, IApiExpenseFilterType.User);
      this.setExpenseFilters(this.investigationId, IApiExpenseFilterType.Investigation);
      this.setExpenseFilters(`${this.isMileage}`, IApiExpenseFilterType.Mileage);
      /* All Default */
      // this.setExpenseFilters(this.dateFilterWeek, IApiExpenseFilterType.ExpenseWeek)
    } else {
      this.setExpenseFilters(this.userId, IApiExpenseFilterType.User);
      this.setExpenseFilters(this.dateFilterMonth, IApiExpenseFilterType.ExpenseMonth);
      this.setExpenseFilters(this.dateFilterYear, IApiExpenseFilterType.ExpenseYear);
    }

    this.getExpenses();

    // Document Types
    this.documentTypeService.get([], { sortOrder: SortOrder.ASC, limit: -1 }).pipe(
      unwrapConnection(),
    ).subscribe((result) => {
      this.documentTypes = result;
    });
  }

  ngOnChanges(data: any): void {
    if (data?.reloadExpense?.previousValue === false && data?.reloadExpense?.currentValue === true) {
      this.getExpenses();
    }
    if (data?.rangerAndWeekFilters?.currentValue?.dateRangeFilter) {
      this.setExpenseFilters(data?.rangerAndWeekFilters?.currentValue?.dateRangeFilter, IApiExpenseFilterType.DateRange);
      this.setExpenseFilters(null, IApiExpenseFilterType.ExpenseWeek);
      this.setExpenseFilters(null, IApiExpenseFilterType.ExpenseYear);
      this.getExpenses();
    }
    if (data?.rangerAndWeekFilters?.currentValue?.dateFilterWeek && data?.rangerAndWeekFilters?.currentValue?.dateFilterYear) {
      this.setExpenseFilters(null, IApiExpenseFilterType.DateRange);
      this.setExpenseFilters(data?.rangerAndWeekFilters?.currentValue?.dateFilterWeek, IApiExpenseFilterType.ExpenseWeek);
      this.setExpenseFilters(data?.rangerAndWeekFilters?.currentValue?.dateFilterYear, IApiExpenseFilterType.ExpenseYear);
      this.getExpenses();
    }
    if (!data?.rangerAndWeekFilters?.currentValue?.dateRangeFilter
      && !data?.rangerAndWeekFilters?.currentValue?.dateFilterWeek
      && !data?.rangerAndWeekFilters?.currentValue?.dateFilterYear
      && data?.rangerAndWeekFilters?.previousValue) {
      this.setExpenseFilters(null, IApiExpenseFilterType.ExpenseWeek);
      this.setExpenseFilters(null, IApiExpenseFilterType.ExpenseYear);
      this.setExpenseFilters(null, IApiExpenseFilterType.DateRange);
      this.getExpenses();
    }
  }

  // check permission for edit
  public checkEditPermission = (row) => {
    if (this.isMileage) {
      if (this.expenseInvestigationMileageUpdatePermission?.enabled || (this.expenseInvestigationMileageUpdateOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
      else return false;
    } else {
      if (this.expenseInvestigationExpensesUpdatePermission?.enabled || (this.expenseInvestigationExpensesUpdateOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
      else return false;
    }
  }

  // check permission for delete
  public checkDeletePermission = (row) => {
    if (this.isMileage) {
      if (this.expenseInvestigationMileageDeletePermission?.enabled || (this.expenseInvestigationMileageDeleteOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
      else return false;
    } else {
      if (this.expenseInvestigationExpensesDeletePermission?.enabled || (this.expenseInvestigationExpensesDeleteOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
      else return false;
    }
  }
}
