import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import _, { filter, isArray, zip } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedService } from 'src/app/common/shared.service';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { GoogleMapService } from 'src/app/shared/modules/google-maps/google-map.service';
import { IApiInvestigationFilter, IApiInvestigationFilterType, IApiInvestigationStatusNames, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';

interface FilterWithZipChange {
  center: any,
  filters: IApiInvestigationFilter[]
}

@Component({
  selector: 'app-geo-fire-report-filters',
  templateUrl: './geo-fire-report-filters.component.html',
  styleUrls: ['./geo-fire-report-filters.component.scss']
})
export class GeoFireReportFiltersComponent implements OnInit {

  @ViewChild("startDateInput") startDateInput: any;
  @ViewChild("endDateInput") endDateInput: any;
  @ViewChild('startDateRange', { static: false }) startDateRange: any;
  @ViewChild('endDateRange', { static: false }) endDateRange: any;

  @Output() clearFilter = new EventEmitter<IApiInvestigationFilter[]>();
  @Output() applyFilter = new EventEmitter<IApiInvestigationFilter[]>();
  @Output() applyFilterWithCenter = new EventEmitter<FilterWithZipChange>();
  @Output() setMapCenter = new EventEmitter<any>();


  private persistFilters = true;
  private _filters: IApiInvestigationFilter[] = [];
  public filterTypes = IApiInvestigationFilterType;
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public investigationStatuses = IApiInvestigationStatusNames;

  // Selected tags
  selectedZipCodes = [];

  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }

  public get validZipCodes() {
    return this.selectedZipCodes?.filter(code => code.isValid)?.map(code => code.zip)
  }

  public get invalidZipCodes() {
    return this.selectedZipCodes?.filter(code => !code.isValid && code?.formatted_address)?.map(code => code.zip)
  }

  public get validZipCodesWithAddress() {
    return this.selectedZipCodes?.filter(code => code.isValid);
  }

  public showZipModel: boolean = false;

  public range = { startDate: null, endDate: null };
  public showAllFilters = false;
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private googleService: GoogleMapService,
  ) { }

  async ngOnInit(): Promise<void> {
    if (!this.persistFilters) {
      await this.router.navigate([], { relativeTo: this.activatedRoute });
      return;
    }

    const queryParams = this.sharedService.getLocalStorageByKey('GEO_FIRE_REPORT')?.reduce((p, { type, value }) => {
      p[type] = value;
      return p;
    }, {});

    await this.router.navigate([], { relativeTo: this.activatedRoute, queryParams });

    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.filters = this.mapQueryParamsToFilters(queryParams);
      this.emitFilters();
    });
  }

  public setFilters(value: string | undefined, type: IApiInvestigationFilterType): void {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: isArray(value) && value?.length ? JSON.stringify(value) : !value?.length ? null : value
    }] : filtersCopy;
  }

  public filterValue(filter: IApiInvestigationFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value === "true" || value === "false" || isArray(value)
      ? JSON.parse(value)
      : typeof value === "string" && value
        ? value
        : null;
  }

  public appliedFilters(): void {
    if (this.persistFilters) {
      this.updateQueryParams();
    }
    this.applyFilter.emit(this.filters);
  }

  public clearAll(): void {
    this.filters = [];
    this.range = { startDate: null, endDate: null };
    if (this.persistFilters) {
      this.updateQueryParams();
    }
    this.selectedZipCodes = [];
    this.clearFilter.emit(this.filters);
  }

  public filterByIdValue(filter: IApiInvestigationFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value ? JSON.parse(value) : null;
  }


  public setVal(event: any, type: IApiInvestigationFilterType) {
    let val = null;
    if (event?.length > 0) {
      val = JSON.stringify(event);
    }
    this.setFilters(val, type);
  }

  private updateQueryParams() {
    this.sharedService.setLocalStorageByKey('GEO_FIRE_REPORT', this._filters);
    const queryParams = this.sharedService.getLocalStorageByKey('GEO_FIRE_REPORT')?.reduce((p, { type, value }) => {
      p[type] = value;
      return p;
    }, {});
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams });
  }

  private mapQueryParamsToFilters(queryParams: Record<string, any>): Array<{ type: IApiInvestigationFilterType; value: any }> {
    return Object.entries(queryParams).map(([key, value]) => {
      if (key === IApiInvestigationFilterType.CreatedAt) {
        const { startDate, endDate } = JSON.parse(value);
        this.range = {
          startDate: new Date(dayjs(startDate).utc().format('MM/DD/YYYY HH:mm:ss')),
          endDate: new Date(dayjs(endDate).utc().format('MM/DD/YYYY HH:mm:ss')),
        };
      }
      return { type: key as IApiInvestigationFilterType, value };
    });
  }

  private emitFilters(): void {
    if (this.filters.length > 0) {
      this.applyFilter.emit(this.filters);
    } else {
      this.clearFilter.emit(this.filters);
    }
  }

  public updateDateFilters() {
    const startDate = this.startDateInput?.value;
    const endDate = this.endDateInput?.value;
    this.range = { startDate, endDate };
    if (startDate && endDate) {
      const dates = {
        startDate: NefcoDateHelper.toUtcStartOfDay(startDate, true),
        endDate: NefcoDateHelper.toUtcStartOfDay(endDate, false),
      };
      this.setFilters(
        JSON.stringify({
          startDate: dates.startDate,
          endDate: dates.endDate,
        }),
        IApiInvestigationFilterType.ReceivedDate
      );
    } else {
      this.range = { startDate: null, endDate: null };
      this.setFilters(null, IApiInvestigationFilterType.ReceivedDate);
    }
  }

  public focusDateInput(start = true) {
    if (this.startDateRange && start) {
      setTimeout(() => {
        this.startDateRange?.focus();
      }, 1);
    } else {
      setTimeout(() => {
        this.endDateRange?.focus();
      }, 1);
    }
  }

  private setZipFilterWithValidValues() {
    if (this.validZipCodes?.length) {
      this.setFilters(JSON.stringify(this.validZipCodes), IApiInvestigationFilterType.LossZip)
    } else {
      this.filters = this.filters?.filter(f => f.type !== IApiInvestigationFilterType.LossZip);
    }
    this.showZipModel = true;
    let center= null;
    if(this.selectedZipCodes?.length){
      center = this.selectedZipCodes[this.selectedZipCodes?.length - 1].location;
    }
    this.applyFilterWithCenter.emit({
      filters: this.filters?.length ? this.filters : [],
      center
    });
  }

  public onZipChange(zipCodes) {
    const latest = zipCodes?.findIndex(code => !code?.isValid && !code?.formatted_address);
    if (latest >= 0) {
      this.googleService.validatePostalCode(zipCodes[latest]?.zip).subscribe(
        (result) => {
          if (result) {
            this.selectedZipCodes[latest].isValid = true;
            this.selectedZipCodes[latest].formatted_address = result?.formatted_address;
            this.selectedZipCodes[latest].location = result?.geometry?.location;
          } else {
            this.selectedZipCodes[latest].isValid = false;
            this.selectedZipCodes[latest].formatted_address = 'NOT_VALID';
          }
          this.setZipFilterWithValidValues()
        },
        (error) => {
          this.setZipFilterWithValidValues()
          this.selectedZipCodes[latest].isValid = false;
          this.selectedZipCodes[latest].formatted_address = 'NOT_VALID';
          console.error('Error validating ZIP code:', error);
        }
      );
    } else {
      this.setZipFilterWithValidValues()
      // this.setFilters(JSON.stringify(this.selectedZipCodes), IApiInvestigationFilterType.LossZip);
    }
  }

  public valueNormalizer = (text$: Observable<string>) =>
    text$.pipe(
      map((text: string) => {
        // search for matching item to avoid duplicates

        // search in values
        const matchingValue: any = this.selectedZipCodes?.find((item: any) => {
          return item["zip"].toLowerCase() === text.toLowerCase();
        });

        if (matchingValue) {
          return matchingValue; // return the already selected matching value and the component will remove it
        }

        // search in data
        const matchingItem: any = this.selectedZipCodes?.find((item: any) => {
          return item["zip"].toLowerCase() === text.toLowerCase();
        });

        if (matchingItem) {
          return matchingItem;
        } else {
          return {
            isValid: false,
            zip: text,
            formatted_address: "",
            location: null
          };
        }
      })
    );
}
