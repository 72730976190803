import { LoaderService } from './../../../../shared/modules/loader/loader.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationsService } from "src/app/shared/modules/notifications/notifications.service";
import { EvidenceStorageLocationService, EvidenceStorageLocationDataSource } from 'src/app/shared/services/evidence/';
import { IApiEvidenceStorageLocationFilterType, IApiEvidenceStorageLocation, IApiEvidenceStorageLocationFilter } from 'src/app/shared/modules/graphql/types/types';
import { EvidenceStorageLocationModalComponent } from '../evidence-storage-location-modal/evidence-storage-location-modal.component';
import _, { isArray } from 'lodash';
import { Router } from '@angular/router';
import { manageEvidenceLocationsCreate, manageEvidenceLocationsUpdate, manageEvidenceLocationsDelete, manageEvidenceLocationsDeactivate } from "../../../../shared/helpers/auth-config/manage.config";
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/shared/services';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-evidence-storage-location',
  templateUrl: './evidence-storage-location.component.html',
  styleUrls: ['./evidence-storage-location.component.scss']
})
export class EvidenceStorageLocationComponent implements OnInit {

  public authConfig = {
    manageEvidenceLocationsCreate,
    manageEvidenceLocationsUpdate,
    manageEvidenceLocationsDelete,
    manageEvidenceLocationsDeactivate
  }

  public dataSource: EvidenceStorageLocationDataSource;
  public evidenceStorageLocationList: IApiEvidenceStorageLocation[] = [];
  public get pageOptions() {
    return this.dataSource.listPage;
  }

  public selectedStatus = null;
  public selectedLocations = null;

  public actionItems = [];

  private _filters: IApiEvidenceStorageLocationFilter[] = [];
  filterTypes = IApiEvidenceStorageLocationFilterType;
  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }

  public sort: SortDescriptor[] = [{
    field: 'NAME',
    dir: 'asc'
  }];

  constructor(
    private loader: LoaderService,
    private router: Router,
    private notifications: NotificationsService,
    private evidenceStorageLocations: EvidenceStorageLocationService,
    private authService: AuthService,
    private dialog: DialogService) { }

  // events
  public openAddNewLocationModal() {
    const dialog: DialogRef = this.dialog.open({
      content: EvidenceStorageLocationModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    dialog.result.subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.load();
      }
    });
  }

  public edit(data: IApiEvidenceStorageLocation) {
    const dialog: DialogRef = this.dialog.open({
      content: EvidenceStorageLocationModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as EvidenceStorageLocationModalComponent;
    dialogInstance.data = data;
    dialog.result.subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.load();
      }
    });
  }

  public toggleActivation({ id }: IApiEvidenceStorageLocation) {
    this.loader.show$(this.evidenceStorageLocations.toggleActivation(id))
      .subscribe(res => {
        this.load();
      }, (err) => {
        if (err?.message?.includes('You cannot deactivate this location as it still contains evidence.')) {
          this.notifications.kendoConfirm(
            err?.message,
            "Error Deactivating The Location",
            "Cancel",
            "Go to Evidence",
            550
          ).subscribe((res) => {
            if (res) {
              this.router.navigate(['evidence/list'], {
                queryParams: {
                  locationId: id
                }
              });
            }
          });
        } else {
          this.notifications.alert("Error toggling activation")
        }
      });
  }

  public deleteLocation(id): void {
    this.notifications.kendoConfirm(
      'Are you sure you want to delete this item?',
      "Confirm Delete",
      "No",
      "Yes",
      550
    ).subscribe((res) => {
      if (res) {
        this.loader.show$(this.evidenceStorageLocations.remove(id))
          .subscribe(res => {
            this.notifications.notify("Evidence location deleted successfully!");
            this.load(false);
          }, (err) => {
            if (err?.message?.includes('You cannot delete this location as it still contains evidence.')) {
              this.notifications.kendoConfirm(
                err?.message,
                "Error Deleting The Location",
                "Cancel",
                "Go to Evidence",
                550
              ).subscribe((res) => {
                if (res) {
                  this.router.navigate(['evidence/list'], {
                    queryParams: {
                      locationId: id
                    }
                  });
                }
              });
            } else {
              this.notifications.alert(err?.message);
            }
          });
      }
    });

  }

  public load(reset = true) {
    if (this.selectedStatus === null) this.dataSource.removeFilter(IApiEvidenceStorageLocationFilterType.IsActive);
    else this.dataSource.applyFilter(IApiEvidenceStorageLocationFilterType.IsActive, this.selectedStatus.toString());
    if (reset === true) {
      this.dataSource.pagingReset();
    } else if (Number.isInteger((this.dataSource.listPage?.totalCount - 1) / this.dataSource.listPage.limit)) {
      if (this.dataSource.listPage.currentPageIndex > 0) {
        this.dataSource.listPage.currentPageIndex = (this.dataSource.listPage.currentPageIndex - 1);
      } else {
        this.dataSource.pagingReset();
      }
    }

    this.dataSource.load();
  }

  ngOnInit() {
    this.dataSource = new EvidenceStorageLocationDataSource(this.evidenceStorageLocations);
    this.dataSource.applyFilter(IApiEvidenceStorageLocationFilterType.ViewWithoutTransfers, 'true');
    this.loader.attachObservable(this.dataSource.loading$);
    this.dataSource?.contents$.subscribe((res) => {
      this.evidenceStorageLocationList = res;
    });
    this.load();

    forkJoin([
      this.authService.hasCategoryPermission(this.authConfig.manageEvidenceLocationsUpdate.category, this.authConfig.manageEvidenceLocationsUpdate.appliedPermissions),
      this.authService.hasCategoryPermission(this.authConfig.manageEvidenceLocationsDeactivate.category, this.authConfig.manageEvidenceLocationsDeactivate.appliedPermissions),
      this.authService.hasCategoryPermission(this.authConfig.manageEvidenceLocationsDelete.category, this.authConfig.manageEvidenceLocationsDelete.appliedPermissions)
    ]).subscribe(([update, deactivate, del]) => {
      if (update) this.actionItems.push({ text: "Edit" });
      if (deactivate) {
        this.actionItems.push({ text: "Activate" });
        this.actionItems.push({ text: "Deactivate" });
      }
      if (del) {
        this.actionItems.push({ text: "Delete" });

      }
    });
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'NAME';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  }


  public filterValue(filter: IApiEvidenceStorageLocationFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters?.find(({ type }) => type === filter)?.value;
    return value === "true" || value === "false" || isArray(value)
      ? JSON.parse(value)
      : typeof value === "string" && value
        ? value
        : null;
  }

  public setFilters(value: string | undefined, type: IApiEvidenceStorageLocationFilterType): void {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const parsedValue = isArray(value) && value?.length ? JSON.stringify(value) : !value?.length ? null : value;
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: parsedValue
    }] : filtersCopy;
    this.dataSource.applyFilter(type, parsedValue);
  }

  clearAll() {
    this.selectedLocations = null;
    this.filters = [];
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.dataSource.pagingReset();
    this.load();
  }

  itemSelected(event, item) {
    switch (event?.text) {
      case "Edit":
        this.edit(item)
        break;
      case "Activate":
      case "Deactivate":
        this.toggleActivation(item)
        break;
      case "Delete":
        this.deleteLocation(item.id)
        break;
    }
  }

  getActionItem(dataItem) {
    let items = [];
    if (dataItem?.isActive) {
      items = this.actionItems?.filter(item => item.text !== 'Activate')
    } else {
      items = this.actionItems?.filter(item => item.text !== 'Deactivate')
    }
    return items;
  }

  setSelectedStatus(status) {
    this.selectedStatus = status;
    this.load();
  }
}
