import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { catchError, take } from "rxjs/operators";
import { InvestigationService, ContactService, AuthService, CompanyService } from 'src/app/shared/services';
import { IApiCompany, IApiContact, IApiInvestigation } from './modules/graphql/types/types';
import { removeTypename } from "./rxjs.pipes";
import { LoaderService } from './modules/loader/loader.service';
import { Capacitor } from '@capacitor/core';
const isMobile = Capacitor.isNativePlatform();


@Injectable()
export class PermissionsResolver {
  constructor(
    private auth: AuthService
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.auth.assignedCategories.pipe(
      take(1)
    );
  }
}

@Injectable()
export class InvestigationResolver {
  constructor(
    private investigationService: InvestigationService,
    private loader: LoaderService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.loader.show$(isMobile ? this.investigationService.getInvestigationByIdMobile(route.params.investigationId, true) : this.investigationService.getById(route.params.investigationId)).pipe(
      removeTypename(),
      catchError((err: any) => {
        if (err?.message === 'Permission denied.') { this.router.navigate(['admin/access-restricted']); }
        else if (err?.message?.includes('Access Restricted For User.')) {
          const nefcoNumber = err?.message?.split("NEFCO_NUMBER:");
          this.router.navigate(['admin/access-restricted'], {
            queryParams: {
              nefcoNumber: nefcoNumber?.length ? nefcoNumber[1] : "Unassigned"
            }
          });
        }
        else {
          return err;
        }
      })
    );
  }
}

@Injectable()
export class ContactResolver {
  constructor(
    private contactService: ContactService
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.contactService.getById(route.params.contactId).pipe(
      removeTypename()
    );
  }
}
