import { NefcoPagedDataSource } from "src/app/shared/helpers/nefco-data-source.class";
import { IApiInvestigationRestriction, IApiInvestigationRestrictionConnection, IApiInvestigationRestrictionFilter } from "src/app/shared/modules/graphql/types/types";
import { InvestigationRestrictionService } from "./investigation-restriction.service";
import { DataSource } from "@angular/cdk/collections";
import { ApiConnection } from "src/app/shared/decorators/@ApiConnection.decorator";

export class InvestigationRestrictionDataSource extends NefcoPagedDataSource<IApiInvestigationRestriction, InvestigationRestrictionService, IApiInvestigationRestrictionConnection> implements DataSource<IApiInvestigationRestriction> {
  @ApiConnection()
  public load(filters: IApiInvestigationRestrictionFilter[] = []): Promise<IApiInvestigationRestrictionConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
