<kendo-formfield>
  <kendo-label class="asterisk" text="NEFCO Office"></kendo-label>
  <kendo-dropdownlist [data]="nefcoOffices" textField="name" valueField="id" fillMode="outline"
    (valueChange)="handleSelectionChange($event)" [valuePrimitive]="true" [name]="'nefcoOffice'" #nefcoOffice="ngModel"
    [(ngModel)]="nefcoOfficeId" [required]="required"
    [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem?.name}} </span>
      <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem?.name}} </span>
    </ng-template>
    <ng-template kendoDropDownListNoDataTemplate>
    </ng-template>
  </kendo-dropdownlist>
  <kendo-formerror *ngIf="!nefcoOffice?.valid && (nefcoOffice?.dirty || nefcoOffice?.touched)">
    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    <span *ngIf="nefcoOffice?.errors?.required">Nefco office is required</span>
  </kendo-formerror>
</kendo-formfield>
