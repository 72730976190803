import { AfterViewInit, Component, Output, ViewChild, EventEmitter, Input, OnInit } from '@angular/core';
import { DateRangePopupComponent, DateRangeService } from '@progress/kendo-angular-dateinputs';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsFilterType, IApiInvestigationRoleNames, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { isArray } from 'lodash';
import { SharedService } from 'src/app/common/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';

@Component({
  selector: 'app-investigation-metrics-filters',
  templateUrl: './investigation-metrics-filters.component.html',
  styleUrls: ['./investigation-metrics-filters.component.scss']
})
export class InvestigationMetricsFiltersComponent implements OnInit, AfterViewInit {
  @ViewChild('startDateRange', { static: false }) startDateRange: any;
  @ViewChild('endDateRange', { static: false }) endDateRange: any;
  @ViewChild("dateRangePicker", { read: DateRangeService }) public service: DateRangeService;
  @ViewChild("endDateInput") endDateInput: any;
  @ViewChild("startDateInput") startDateInput: any;
  @ViewChild("dateRangePickerPopup", { static: true }) public dateRangePickerPopup: DateRangePopupComponent;

  @Output() clearFilter = new EventEmitter<IApiInvestigationMetricsFilter[]>();
  @Output() applyFilter = new EventEmitter<IApiInvestigationMetricsFilter[]>();
  @Output() export = new EventEmitter<any>();
  @Input() investigationFilter: boolean = false;
  @Input() editorFilter: boolean = false;
  @Input() persistFilters: boolean = true;
  @Input() showNefcoOfficeFilter: boolean = true;
  @Input() exportReport: boolean = false;

  private _filters: IApiInvestigationMetricsFilter[] = [];
  public filterTypes = IApiInvestigationMetricsFilterType;

  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }

  public range = { startDate: null, endDate: null };
  public userRoleName = IApiInvestigationRoleNames;
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public showAllFilters = false;
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  async ngOnInit(): Promise<void> {
    if (!this.persistFilters) {
      await this.router.navigate([], { relativeTo: this.activatedRoute });
      return;
    }

    /* If the Nefco Office filter is not shown, remove it from the filters */
    if(!this.showNefcoOfficeFilter) {
      let queryParams = this.sharedService.getLocalStorageByKey('INVESTIGATION_METRICS')?.reduce((p, { type, value }) => {
        p[type] = value;
        return p;
      }, {});

      if (queryParams && queryParams?.length > 0) {
        delete queryParams[IApiInvestigationMetricsFilterType.NefcoOffice];
  
        this._filters = Object.keys(queryParams).map(key => ({
          type: key as IApiInvestigationMetricsFilterType,
          value: queryParams[key]
        }));
  
        this.sharedService.setLocalStorageByKey('INVESTIGATION_METRICS', this._filters);
      }
    }

    const queryParams = this.sharedService.getLocalStorageByKey('INVESTIGATION_METRICS')?.reduce((p, { type, value }) => {
      p[type] = value;
      return p;
    }, {});

    await this.router.navigate([], { relativeTo: this.activatedRoute, queryParams});

    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.filters = this.mapQueryParamsToFilters(queryParams);
      this.emitFilters();
    });
  }

  ngAfterViewInit() {
    this.service.activeRangeEnd$.subscribe((data) => {
      if (data === "start") {
        setTimeout(() => {
          if (this.range.startDate) {
            const dates = {
              startDate: NefcoDateHelper.toUtcStartOfDay(this.range.startDate, true),
              endDate: NefcoDateHelper.toUtcStartOfDay(this.range.endDate, false),
            };
            this.setFilters(
              JSON.stringify({
                startDate: dates.startDate,
                endDate: dates.endDate,
              }),
              IApiInvestigationMetricsFilterType.ReceivedDate
            );
            this.dateRangePickerPopup.toggle(false);
          }
        }, 100);
      }
    });
  }

  public updateDateFilters() {
    const startDate = this.startDateInput?.value;
    const endDate = this.endDateInput?.value;

    if (startDate && endDate) {
      const dates = {
        startDate: NefcoDateHelper.toUtcStartOfDay(startDate, true),
        endDate: NefcoDateHelper.toUtcStartOfDay(endDate, false),
      };
      this.setFilters(
        JSON.stringify({
          startDate: dates.startDate,
          endDate: dates.endDate,
        }),
        IApiInvestigationMetricsFilterType.ReceivedDate
      );
    } else {
      this.range = { startDate: null, endDate: null };
      this.setFilters(null, IApiInvestigationMetricsFilterType.ReceivedDate);
    }
  }

  public focusDateInput(start = true) {
    if (this.startDateRange && start) {
      setTimeout(() => {
        this.startDateRange?.focus();
      }, 1);
    } else {
      setTimeout(() => {
        this.endDateRange?.focus();
      }, 1);
    }
  }

  public setFilters(value: string | undefined, type: IApiInvestigationMetricsFilterType): void {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: isArray(value) && value?.length ? JSON.stringify(value) : !value?.length ? null : value
    }] : filtersCopy;
  }

  public filterValue(filter: IApiInvestigationMetricsFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value === "true" || value === "false" || isArray(value)
      ? JSON.parse(value)
      : typeof value === "string" && value
      ? value
      : null;
  }

  public appliedFilters(): void {
    if (this.persistFilters) {
      this.updateQueryParams();
    }
    this.applyFilter.emit(this.filters);
  }

  public clearAll(): void {
    this.filters = [];
    this.range = { startDate: null, endDate: null };
    if (this.persistFilters) {
      this.updateQueryParams();
    }
    this.clearFilter.emit(this.filters);
  }

  public filterByIdValue(filter: IApiInvestigationMetricsFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value ? JSON.parse(value) : null;
  }


  public setVal(event: any, type: IApiInvestigationMetricsFilterType) {
    let val = null;
    if (event?.length > 0) {
      val = JSON.stringify(event);
    }
    this.setFilters(val, type);
  }

  private updateQueryParams() {
    this.sharedService.setLocalStorageByKey('INVESTIGATION_METRICS', this._filters);
    const queryParams = this.sharedService.getLocalStorageByKey('INVESTIGATION_METRICS')?.reduce((p, { type, value }) => {
      p[type] = value;
      return p;
    }, {});
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams });
  }

  private mapQueryParamsToFilters(queryParams: Record<string, any>): Array<{ type: IApiInvestigationMetricsFilterType; value: any }> {
    return Object.entries(queryParams).map(([key, value]) => {
      if (key === IApiInvestigationMetricsFilterType.ReceivedDate) {
        const { startDate, endDate } = JSON.parse(value);
        this.range = {
          startDate: new Date(dayjs(startDate).utc().format('MM/DD/YYYY HH:mm:ss')),
          endDate: new Date(dayjs(endDate).utc().format('MM/DD/YYYY HH:mm:ss')),
        };
      }
      return { type: key as IApiInvestigationMetricsFilterType, value };
    });
  }

  private emitFilters(): void {
    if (this.filters.length > 0) {
      this.applyFilter.emit(this.filters);
    } else {
      this.clearFilter.emit(this.filters);
    }
  }

  public exportXlsx(){
    this.export.emit(Math.random());
  }
}
