import { Component, Inject, Input, OnInit } from '@angular/core';
import { IApiAddContactBranchAssignmentInput, IApiBranch, IApiCompany, IApiContact, IApiContactFilterType, IApiUpdateContactBranchAssignmentInput } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { CompanyService, ContactService, StateService } from 'src/app/shared/services';
import { ContactDataSource } from 'src/app/shared/services/contact/contact.datasource';
import { CreateUpdateContactComponent, ICreateUpdateContactData } from '../../contacts/create-update-contact/create-update-contact.component';
import { filter } from 'rxjs/operators';
import { SharedService } from 'src/app/common/shared.service';
import _ from 'lodash';
import { DialogContentBase, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';

export interface ICompanyBranchContactSelectData {
  company: IApiCompany;
  branchId?: string;
}

@Component({
  selector: 'app-company-branch-contact-select',
  templateUrl: './company-branch-contact-select.component.html',
  styleUrls: ['./company-branch-contact-select.component.scss']
})
export class CompanyBranchContactSelectComponent extends DialogContentBase implements OnInit {

  @Input() public data: ICompanyBranchContactSelectData;
  private _dataSource: ContactDataSource;
  public displayedColumns = ["checked", "name", "position", "branch", "role", "emails", "phones"];
  public searchValue = "";

  public content: IApiContact[] = [];

  public selectedBranch: string = null;
  public state = null;
  public company = null;
  public companies: IApiCompany[] = [];
  public contacts: IApiContact[] = [];

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public sort: SortDescriptor[] = [{
    field: 'NAME',
    dir: 'asc'
  }];

  constructor(
    private contactService: ContactService,
    private loader: LoaderService,
    public dialog: DialogRef,
    public stateService: StateService,
    private companyService: CompanyService,
    private notification: NotificationsService,
    private sharedService: SharedService,
    private dialogService: DialogService,
  ) {
    super(dialog);
  }

  public ngOnInit(): void {
    this._dataSource = new ContactDataSource(this.contactService);
    this.loader.attachObservable(this._dataSource.loading$);
    this.dataSource.contents$.subscribe((content) => this.content = content);
    // Preset selected branch if it has been provided.
    if (this.data.branchId) this.selectedBranch = this.data.branchId;
    this.dataSource?.contents$.subscribe((res) => {
      this.contacts = res;
    });

  }

  public selectContact(contact: IApiContact) {
    if (!this.selectedBranch) return this.notification.alert("Please select a branch");
    const assignment: IApiAddContactBranchAssignmentInput = {
      startDate: new Date(),
      BranchId: this.selectedBranch,
      ContactId: contact.id
    };
    this.loader.show$(
      this.contactService.update({ id: contact.id, AddBranch: assignment })
    ).subscribe(() => this.close(true));
  }

  public createNewContact() {
    if (!this.selectedBranch) return this.notification.alert("Please select a branch");
    const data: ICreateUpdateContactData = {
      branchId: this.selectedBranch,
      companyRoles: true
    };
    const dialog: DialogRef = this.dialogService.open({
      content: CreateUpdateContactComponent,
      width: '80%',
      maxWidth: '90%',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as CreateUpdateContactComponent;
    dialogInstance.data = data;
    dialog.result
      .pipe(filter((v) => !_.isEmpty(v)))
      .subscribe(() => {
        this.sharedService.afterCreateContact()
      });
    this.close(false);
  }

  public load() {
    if (this.searchValue) this.dataSource.applyFilter(IApiContactFilterType.Search, this.searchValue);
    if (this.state) this.dataSource.applyFilter(IApiContactFilterType.State, this.state);
    if (this.company) this.dataSource.applyFilter(IApiContactFilterType.Company, this.company);
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public close(result = false){
    this.dialog.close(result);
  }
  onCompanyChange(event) {
    this.company = event?.id || null;
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'NAME';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  }


}
