import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from "@angular/common/http";

import { NotificationsService } from "./notifications.service";
import { FormsModule } from "@angular/forms";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { KendoModelComponent } from './kendo-model/kendo-model.component';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { SharedModule } from '../../shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    DialogsModule,
    ButtonsModule,
    SharedModule
  ],
  declarations: [
    KendoModelComponent
  ],
  exports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    NotificationsService
  ]
})

export class NotificationsModule {
  static forRoot(): ModuleWithProviders<NotificationsModule> {
    return {
      ngModule: NotificationsModule,
      providers: [
        NotificationsService
      ]
    };
  }
 }
