import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { IApiAddMultipleInvestigationRestrictionInput, IApiInvestigationRestrictionConnection, IApiInvestigationRestrictionFilter, IApiRemoveMultipleInvestigationRestrictionInput } from "../../../types/types";
import { IGqlBaseOptions } from "../../../interfaces";
import { Observable } from "rxjs";
import { connectionQuery } from "../../../helpers.class";
import { addInvestigationRestrictionMutation, investigationRestrictionsConnectionQuery, removeInvestigationRestrictionMutation } from "./investigation-restriction.operation";
import { safeResolve } from "../../../rxjs";

@Injectable()
export class GqlInvestigationRestrictionService {

  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiInvestigationRestrictionFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationRestrictionConnection> {
    return this.apollo.query(connectionQuery(investigationRestrictionsConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addInvestigationRestriction(input: IApiAddMultipleInvestigationRestrictionInput) {
    return this.apollo.mutate({
      mutation: addInvestigationRestrictionMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeInvestigationRestriction(input: IApiRemoveMultipleInvestigationRestrictionInput) {
    return this.apollo.mutate({
      mutation: removeInvestigationRestrictionMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
