import { connectionQuery } from '../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../interfaces/base.interfaces';
import { addMutation, updateMutation, removeMutation, NefcoOfficesConnectionQuery } from "./nefco-office.operations";
import { IApiAddNefcoOfficeInput, IApiNefcoOffice, IApiNefcoOfficeConnection, IApiNefcoOfficeFilter, IApiNefcoOfficeFilterType, IApiUpdateNefcoOfficeInput} from "../../types/types";

@Injectable()
export class GqlNefcoOfficeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getAll(filters: IApiNefcoOfficeFilter[], options: IGqlBaseOptions): Observable<IApiNefcoOfficeConnection> {
    const viewType = filters.find(obj =>
      obj.type === IApiNefcoOfficeFilterType.ViewOnlyNameAndActive
    );
    return this.apollo.query(connectionQuery(NefcoOfficesConnectionQuery(viewType), filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddNefcoOfficeInput): Observable<IApiNefcoOffice> {
    return this.apollo.mutate({
      mutation: addMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }


  public update(input: IApiUpdateNefcoOfficeInput): Observable<IApiNefcoOffice> {
    return this.apollo.mutate({
      mutation: updateMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public remove(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
