import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";
import { IApiNefcoOfficeFilterType } from '../../types/types';

const nodeFragment = `
  id
  name
  isActive
  createdAt
  updatedAt
  Address {
    id
    Type {
      id
      name
    }
    address1
    address2
    address3
    city
    state
    postal
    country
  }
  AssignedInvestigationCount
`;

const viewOnlyNameAndActiveFragment = `
  id
  name
  isActive
`;

export const NefcoOfficesConnectionQuery = (view = null) => {
  if (view && view?.type) {
    switch (view.type) {
      case IApiNefcoOfficeFilterType.ViewOnlyNameAndActive:
        return gql`${buildConnectionQuery("NefcoOffice", "nefcoOfficeConnection", viewOnlyNameAndActiveFragment)}`;
      default:
        return gql`${buildConnectionQuery("NefcoOffice", "nefcoOfficeConnection", nodeFragment)}`;
    } 
  } else {
    return gql`${buildConnectionQuery("NefcoOffice", "nefcoOfficeConnection", nodeFragment)}`;
  }
}

export const getShippingCarrierByIdQuery = gql`query getShippingCarrierById($id: String!) {
  getShippingCarrierById(id: $id) { ${ nodeFragment } }
}`;

export const addMutation = gql`mutation AddNefcoOffice($input: AddNefcoOfficeInput!) {
  addNefcoOffice(input: $input) { ${ nodeFragment } }
}`;

export const updateMutation = gql`mutation UpdateNefcoOffice($input: UpdateNefcoOfficeInput!) {
  updateNefcoOffice(input: $input) { ${ nodeFragment } }
}`;

export const removeMutation = gql`mutation RemoveNefcoOffice($id: ID!) {
  removeNefcoOffice(id: $id)
}`;