import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../modules/graphql/interfaces/base.interfaces';
import { IApiAddNefcoOfficeInput, IApiNefcoOfficeFilter, IApiNefcoOfficeConnection, IApiNefcoOffice, IApiUpdateNefcoOfficeInput } from "src/app/shared/modules/graphql/types/types";
import { ListPage } from "src/app/shared/helpers/list-page.class";
import { GqlNefcoOfficeService } from "../../modules/graphql/services";

@Injectable()
export class NefcoOfficeService {

  constructor(
    private gqlNefcoOfficeService: GqlNefcoOfficeService
  ) { }

  public get(filters?: IApiNefcoOfficeFilter[], options?: IGqlBaseOptions): Observable<IApiNefcoOfficeConnection> {
    return this.gqlNefcoOfficeService.getAll(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddNefcoOfficeInput): Observable<IApiNefcoOffice> {
    return this.gqlNefcoOfficeService.add(input);
  }

  public update(input: IApiUpdateNefcoOfficeInput): Observable<IApiNefcoOffice> {
    return this.gqlNefcoOfficeService.update(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlNefcoOfficeService.remove(id);
  }
}
