import { Component, OnInit } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { isArray } from 'lodash';
import { filter } from 'rxjs/operators';
import { investigationRestrictionsDelete } from 'src/app/shared/helpers/auth-config/investigations.config';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiInvestigationRestriction, IApiInvestigationRestrictionFilter, IApiInvestigationRestrictionFilterType, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { InvestigationRestrictionService } from 'src/app/shared/services';
import { InvestigationRestrictionDataSource } from 'src/app/shared/services/investigation/investigation-restriction/investigation-restriction.datasource';

@Component({
  selector: 'app-investigation-restriction',
  templateUrl: './investigation-restriction.component.html',
  styleUrls: ['./investigation-restriction.component.scss']
})
export class InvestigationRestrictionComponent implements OnInit {
  public authConfig = {
    investigationRestrictionsDelete
  }
  private _dataSource: InvestigationRestrictionDataSource;
  private _filters: IApiInvestigationRestrictionFilter[] = [];
  public filterTypes = IApiInvestigationRestrictionFilterType;

  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  public restrictions: IApiInvestigationRestriction[];

  public sort: SortDescriptor[] = [{
    field: 'CREATED_AT',
    dir: 'desc'
  }];

  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }

  public searchValue = '';
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  constructor(
    private investigationRestrictionService: InvestigationRestrictionService,
    private loaderService: LoaderService,
    private notifications: NotificationsService
  ) {

  }

  ngOnInit() {
    this.dataSource = new InvestigationRestrictionDataSource(this.investigationRestrictionService);
    this.loaderService.attachObservable(this.dataSource.loading$);
    this.dataSource?.contents$.subscribe((res) => {
      this.restrictions = res;
    });
    this.load();
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'CREATED_AT';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  }

  public filterValue(filter: IApiInvestigationRestrictionFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return (value === "true" || value === "false") ? JSON.parse(value) : (value) ? value : null;
  }

  public setFilters(value: string | undefined, type: IApiInvestigationRestrictionFilterType) {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: isArray(value) && value?.length ? JSON.stringify(value) : !value?.length ? null : value
    }] : filtersCopy;
  }

  public clearFilter() {
    this.filters?.forEach(({ type }) => this.dataSource.removeFilter(type));
    this.filters = [];
    this.load();
  }

  public applyFilter() {
    this.filters?.forEach(filter => this.dataSource.applyFilter(filter.type, filter.value));
    console.log(this.dataSource.lastFilters)
    this.load();
  }

  load() {
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public filterByIdValue(filter: IApiInvestigationRestrictionFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value ? JSON.parse(value) : null;
  }

  public removeInvestigationRestriction(data) {
    if (!data?.id) {
      this.notifications.error(`Data not found!`);
    }
    const userName = `${data?.User?.firstName} ${data?.User?.lastName}`;
    const ids = {
      ids: [data?.id]
    }
    this.notifications
      .kendoConfirm(
        `Are you sure you want to remove restriction for ${userName}?`,
        "Remove restriction",
        "No, Don’t Remove",
        "Yes, Remove",
        550
      )
      .pipe(
        filter((response) => !!response),
        this.loaderService.showPipe(this.investigationRestrictionService.remove(ids)),
        this.notifications.snackbarErrorPipe()
      )
      .subscribe(() => {
        this.notifications.notify(`Restriction for ${userName} is removed successfully`);
        this.load();
      }, error => {
        this.notifications.error(`Unable to remove Restriction for ${userName}, please try again later!`);
      });
  }

}
