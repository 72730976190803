
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlInvestigationService } from 'src/app/shared/modules/graphql/services';
import { IApiAddInvestigationV2Input, IApiGeoFireReportByStateConnection, IApiInvestigationAssignmentSms, IApiInvestigationAssignmentSmsInput, IApiInvestigationConnection, IApiUserWithDistance } from '../../modules/graphql/types/types';
import {
  IApiAddInvestigationInput, IApiInvestigation,
  IApiUpdateInvestigationInput, IApiInvestigationFilter
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "../../helpers/list-page.class";


@Injectable()
export class InvestigationService {

  constructor(
    private gqlInvestigationService: GqlInvestigationService
  ) { }

  public get(filters?: IApiInvestigationFilter[], options?: IGqlBaseOptions): Observable<IApiInvestigationConnection> {
    return this.gqlInvestigationService.getInvestigations(filters, options || new ListPage());
  }

  public add(input: IApiAddInvestigationInput): Observable<IApiInvestigation> {
    return this.gqlInvestigationService.addInvestigation(input);
  }

  public addV2(input: IApiAddInvestigationV2Input): Observable<IApiInvestigation> {
    return this.gqlInvestigationService.addInvestigationV2(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlInvestigationService.removeInvestigation(id);
  }

  public update(input: IApiUpdateInvestigationInput): Observable<IApiInvestigation> {
    return this.gqlInvestigationService.updateInvestigation(input);
  }

  public restore(id: string): Observable<boolean> {
    return this.gqlInvestigationService.restoreInvestigation(id);
  }

  public reject(id: string): Observable<boolean> {
    return this.gqlInvestigationService.rejectInvestigation(id);
  }

  public getById(id: string): Observable<IApiInvestigation> {
    return this.gqlInvestigationService.getInvestigationById(id);
  }

  public getInvestigationByIdMobile(id: string, isMobile: boolean = false): Observable<IApiInvestigation> {
    return this.gqlInvestigationService.getInvestigationByIdMobile(id, isMobile);
  }

  public getByIdForSidebar(id: string): Observable<IApiInvestigation> {
    return this.gqlInvestigationService.getInvestigationByIdForSidebar(id);
  }
  public getInvestigationByIdForGeoFire(id: string): Observable<IApiInvestigation> {
    return this.gqlInvestigationService.getInvestigationByIdForGeoFire(id);
  }

  public markViewed(id: string): Observable<boolean> {
    return this.gqlInvestigationService.markInvestigationViewed(id);
  }

  public investigationAssignmentSMS(input: IApiInvestigationAssignmentSmsInput): Observable<IApiInvestigationAssignmentSms> {
    return this.gqlInvestigationService.investigationAssignmentSMS(input);
  }

  public updateInvestigationStaffScheduledDate(input: any): Observable<boolean> {
    return this.gqlInvestigationService.updateInvestigationStaffScheduledDate(input)
  }

  public getClosestInvestigatorByInvestigationIdQuery(id: string): Observable<IApiUserWithDistance> {
    return this.gqlInvestigationService.getClosestInvestigatorByInvestigationIdQuery(id);
  }

  public refreshPendingInvestigation(): Observable<boolean> {
    return this.gqlInvestigationService.refreshPendingInvestigation();
  }

  public getGeoFireReportByState(filters?: IApiInvestigationFilter[]): Observable<IApiGeoFireReportByStateConnection> {
    return this.gqlInvestigationService.geoFireReportByState(filters);
  }

  public refreshInProgressInvestigation(): Observable<boolean> {
    return this.gqlInvestigationService.refreshInProgressInvestigation();
  }
}
