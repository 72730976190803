import { Component, OnInit } from '@angular/core';
import { filter, switchMap } from 'rxjs/operators';
import { IApiNefcoOffice, IApiNefcoOfficeOrderBy } from '../../../../shared/modules/graphql/types/types';
import { NotificationsService } from '../../../../shared/modules/notifications/notifications.service';
import { LoaderService } from '../../../../shared/modules/loader/loader.service';
import { NefcoOfficeFormModalComponent } from '../nefco-office-form-modal/nefco-office-form-modal.component';
import { NefcoOfficeDataSource } from "src/app/shared/services/nefco-office/nefco-office.datasource";
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { NefcoOfficeService } from 'src/app/shared/services/nefco-office/nefco-office.service';
import { manageNefcoOfficeCreate, manageNefcoOfficeUpdate, manageNefcoOfficeDelete } from "../../../../shared/helpers/auth-config/manage.config";


@Component({
  selector: 'app-nefco-office-list',
  templateUrl: './nefco-office-list.component.html',
  styleUrls: ['./nefco-office-list.component.scss']
})
export class NefcoOfficeListComponent implements OnInit {

  public authConfig = {
    manageNefcoOfficeCreate,
    manageNefcoOfficeUpdate,
    manageNefcoOfficeDelete
  }

  public orderByOptions = IApiNefcoOfficeOrderBy;
  private _dataSource: NefcoOfficeDataSource;
  public get dataSource(): NefcoOfficeDataSource {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }
  public sort: SortDescriptor[] = [{
    field: 'NAME',
    dir: 'asc'
  }];

  constructor(
    private loader: LoaderService,
    private notifications: NotificationsService,
    private nefcoOfficeService: NefcoOfficeService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this._dataSource = new NefcoOfficeDataSource(this.nefcoOfficeService);
    this._dataSource.listPage.orderBy = this.orderByOptions.Name;
    this._dataSource.listPage.sortOrder = SortOrder.ASC;
    this.loader.attachObservable(this._dataSource.loading$);
    this.load();
  }

  private load() {
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'NAME';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  }

  public deleteOffice(id: string) {
    this.notifications
      .kendoConfirm("Really delete this NEFCO office?")
      .pipe(
        filter((v) => !!v),
        switchMap(() => this.nefcoOfficeService.remove(id)),
        this.notifications.snackbarPipe("NEFCO office deleted")
      )
      .subscribe(
        () => this.load(),
        (err) => {
          if (err?.message?.includes('This NEFCO office can not be deleted')) {
            this.notifications.alert(err?.message, 'Cannot Delete NEFCO Office', 'Ok', '40%');
          } else {
            this.notifications.snackbarError(err?.message);
          }
        }
      );
  }

  public addOrUpdate(data?: IApiNefcoOffice) {
    const dialog: DialogRef = this.dialogService.open({
      content: NefcoOfficeFormModalComponent,
      width: 600,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as NefcoOfficeFormModalComponent;
    dialogInstance.data = data;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.load();
      }
    })
  }
}
