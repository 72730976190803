import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IPayPeriod } from 'src/app/shared/interfaces/time-entry.interfaces';
import { IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { IPayRollDay } from '../time-kendo-card/time-kendo-card.component';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import dayjs from 'dayjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/common/shared.service';
@UntilDestroy()
@Component({
  selector: "app-bookkeeping-payroll-filter",
  templateUrl: "./bookkeeping-payroll-filter.component.html",
  styleUrls: ["./bookkeeping-payroll-filter.component.scss"],
})
export class BookkeepingPayrollFilterComponent implements OnInit {
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public _selectedTimeEntries = new SelectionModel<IPayRollDay>(true, []);

  @Input() selectedTab: string;
  @Input() payPeriod: IPayPeriod;

  /* maxDate = PayDate */
  public maxDate: Date = dayjs().isBefore(
    dayjs(NefcoDateHelper.currentPayPeriod(new Date()).payDate).subtract(
      2,
      "week"
    )
  )
    ? dayjs(NefcoDateHelper.currentPayPeriod(new Date()).payDate)
        .subtract(2, "week")
        .toDate()
    : NefcoDateHelper.currentPayPeriod(new Date()).payDate;
  /* maxDate = PayDate */
  
  public _userId: string;
  set userId(val: string) {
    this._userId = val;
  }
  get userId() {
    return this._userId;
  }
  @Input() set selectedTimeEntries(value: any) {
    this._selectedTimeEntries = value;
  }

  get selectedTimeEntries() {
    return this._selectedTimeEntries;
  }

  @Output() dateRangeChange = new EventEmitter<string>();
  @Output() userSelectionChange = new EventEmitter<string>();
  @Output() downloadCsv = new EventEmitter<boolean>();
  @Output() markAsPaid = new EventEmitter<boolean>();
  @Output() markAllAsPaid = new EventEmitter<boolean>();
  @Output() clearAll = new EventEmitter<boolean>();

  private getPayPeriod(date: Date) {
    return NefcoDateHelper.payPeriodbyPaidDate(date);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const { date = null, userId = null } = params;
      this.payPeriod = this.getPayPeriod(date ? new Date(date) : new Date(this.maxDate));
      this.userId = userId;
    });
  }

  onDateRangeChanged(direction) {
    if (
      this.maxDate.toDateString() === this.payPeriod?.payDate?.toDateString() &&
      direction === "forward"
    ) {
      return;
    }
    this.dateRangeChange.emit(direction);
  }

  setUser(event) {
    this.userSelectionChange.emit(event);
  }

  downloadCsvAction(value) {
    this.downloadCsv.emit(value);
  }

  markAsPaidAction() {
    this.markAsPaid.emit(true);
  }

  markAllAsPaidAction() {
    this.markAllAsPaid.emit(true);
  }

  clearFilter() {
    this.sharedService.removeLocalStorageByKey("BOOKKEEPING_PAYROLL");
    this.userId = null;
    this.clearAll.emit(true);
  }
}
