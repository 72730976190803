import { unwrapConnection } from "src/app/shared/rxjs.pipes";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
  InvestigationPriorityService,
  RequestService,
  RequestTypeService,
} from "src/app/shared/services";
import {
  IApiRequest,
  IApiRequestFilter,
  IApiRequestFilterType,
  IApiRequestOrderBy,
  IApiRequestType,
  IApiUserFilterType,
} from "src/app/shared/modules/graphql/types/types";
import { RequestDataSource } from "src/app/shared/services/request/request.datasource";
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { InvestigationRequestModalComponent } from "../../investigations";
import {
  investigationDocumentsCreate,
  investigationReportsCreate,
  investigationRequestsCreate,
} from "src/app/shared/helpers/auth-config/investigations.config";
import { SortDescriptor } from "@progress/kendo-data-query";
import { SortOrder } from "src/app/shared/modules/graphql/enums/generic.enums";
import {
  InvestigationStatusNames,
  RequestStatuses,
} from "src/app/shared/helpers/helper";
import _ from "lodash";
import { SharedService } from "src/app/common/shared.service";
import { TABS } from "../dashboard-tabs/dashboard-tabs.component";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogCloseResult, DialogRef, DialogService } from "@progress/kendo-angular-dialog";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-dashboard-requests-table",
  templateUrl: "./dashboard-requests-table.component.html",
  styleUrls: ["./dashboard-requests-table.component.scss"],
})
export class DashboardRequestsTableComponent implements OnInit {
  public investigationStatusNames = InvestigationStatusNames;
  public RequestStatuses = RequestStatuses;

  public authConfig = {
    investigationDocumentsCreate,
    investigationReportsCreate,
    investigationRequestsCreate,
  };

  @Output() total = new EventEmitter<number>();

  private previousFilters: IApiRequestFilter[] = [];
  private _filters: IApiRequestFilter[] = [];
  public filterTypes = IApiRequestFilterType;

  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }

  public dataSource: RequestDataSource;

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public users = [];
  public staffMember = null;
  public priority: any; // InvestigationPriorities.LOW | InvestigationPriorities.MEDIUM | InvestigationPriorities.HIGH;
  public investigationPriorities;
  public request: any = null;

  public displayedColumns = [
    "submitted",
    "invId",
    "priority",
    "invStatus",
    "lossLocation",
    "assigned",
    "requestType",
    "comments",
    "reqStatus",
    "actions",
  ];

  // selection lists
  public requestTypes: IApiRequestType[] = [];

  public sortOptions = IApiRequestOrderBy;

  public sort: SortDescriptor[] = [
    {
      field: "CREATED_AT",
      dir: "asc",
    },
  ];

  public showComments = false;
  public expandedDetailKeys: any[] = [];
  public tableData: IApiRequest[] = [];
  public userViewFilter = IApiUserFilterType.ViewStaffUser;

  constructor(
    private requestService: RequestService,
    private requestTypeService: RequestTypeService,
    private loader: LoaderService,
    private investigationPriorityService: InvestigationPriorityService,
    private sharedService: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService
  ) {
    this.dataSource = new RequestDataSource(this.requestService);

    this.dataSource?.contents$.subscribe((res) => {
      this.tableData = res;
    });
  }

  public async ngOnInit() {
    this.investigationPriorityService
      .get([], {})
      .pipe(unwrapConnection())
      .subscribe((p) => (this.investigationPriorities = p));
    this.loader.attachObservable(this.dataSource.loading$);
    this.requestTypeService
      .get()
      .pipe(unwrapConnection())
      .subscribe((types) => (this.requestTypes = types));

    // Default filter for this page, shouldn't be removed
    this.dataSource.applyFilter(IApiRequestFilterType.Incomplete, "COMPLETE");
    this.dataSource.listPage.orderBy = IApiRequestOrderBy.CreatedAt;

    const queryParams = this.sharedService.getLocalStorageByKey(TABS.PENDING_REQUESTS)?.reduce((p, { type, value }) => {
      p[type] = value;
      return p;
    }, {});

    await this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: {tab: TABS.PENDING_REQUESTS, ...queryParams}});
    
    this.activatedRoute.queryParams
    .subscribe((queryParams) => {
      Object.keys(queryParams).forEach((k: IApiRequestFilterType) => {
        if (Object.values(IApiRequestFilterType).includes(k)) {
          const value = queryParams[k];
          this.setFilters(value, k);
        }
      });
      this.loadFilters(this.filters);
    });

    this.load();
  }

  public load(val?: string, filterType?: any) {
    this.dataSource.applyFilter(filterType, val);
    this.dataSource.pagingReset();
    this.dataSource.load().then(() => {
      this.toggleCommentExpand(this.showComments);
      this.total.emit(this.pageOptions.totalCount);
    });
  }

  public updateRequest(rowData: IApiRequest) {
    const { Investigation } = rowData;
    // typename will cause this to fail when we try to update
    delete rowData.Investigation.LossAddress.__typename;
    delete rowData.Investigation.LossAddress.Type;
    // trigger modal to update request
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationRequestModalComponent,
      width: "60%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationRequestModalComponent;
    dialogInstance.data = { ...rowData, Address: Investigation.LossAddress };
    dialog.result
      .pipe(
        filter((v: DialogCloseResult) => {
          return _.isEmpty(v) ? false : !!v;
        })
      )
      .subscribe((res) => {
        if (res) {
          this.load();
        }
      });
  }

  public toggleCommentExpand(show: boolean): void {
    this.showComments = show;
    if (!this.showComments) {
      this.expandedDetailKeys = [];
    } else {
      this.expandedDetailKeys = this.tableData.map(
        (item: IApiRequest) => item?.id
      );
    }
  }

  public displayComments(dataItem: IApiRequest) {
    return dataItem?.Investigation?.Comments.length > 0;
  }

  public filterValue(filter: IApiRequestFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value === "true" || value === "false" ? JSON.parse(value) : value;
  }

  public setFilters(value: string | undefined, type: IApiRequestFilterType) {
    const hasValue = (val: any) => val !== undefined || val !== null; // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this.filters.filter((f) => f.type !== type);
    this.filters = hasValue(value)
      ? [
          ...filtersCopy,
          {
            type: type,
            value: value,
          },
        ]
      : filtersCopy;
  }

  public loadFilters(filterList: IApiRequestFilter[], load = true) {
    // If no new filters, return
    if (_.isEqual(filterList, this.previousFilters)) return;
    this.previousFilters.forEach(({ type }) =>
      this.dataSource.removeFilter(type)
    ); // Remove previous filters and only apply the ones that came in
    filterList.forEach(({ type, value }) =>
      this.dataSource.applyFilter(type, value)
    );
    if (load) this.load();
    this.previousFilters = filterList;
  }

  public filterTable() {
    this.loadFilters(this.filters);

    this.sharedService.setLocalStorageByKey(TABS.PENDING_REQUESTS, this.filters);
    const queryParams = this.sharedService.getLocalStorageByKey(TABS.PENDING_REQUESTS)?.reduce((p, { type, value }) => {
      p[type] = value;
      return p;
    }, {});
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: {tab: TABS.PENDING_REQUESTS, ...queryParams} });
  }

  public clearFilter() {
    this.filters = [];
    this.filterTable();
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = "CREATED_AT";
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  };

  public pageChange(event) {
    this.showComments = false;
    this.pageOptions?.paginate(event);
  }
}
