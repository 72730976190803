<section>
  <h4 class="page-title">Geo Fire Report</h4>
  <kendo-card width="100%">
    <div class="content-wrapper">
      <app-geo-fire-report-filters (applyFilter)="changeFilters($event)" (setMapCenter)="setMapCenterZip($event)" (applyFilterWithCenter)="filterWithCenter($event)"
        (clearFilter)="changeFilters($event)"></app-geo-fire-report-filters>
      <div class="centered-map-container ">
        <app-geo-google-maps [states]="states" [centerLocation]="centerLocation" *ngIf="!!mapsLoaded" (mapSetting)="boundChanged($event)"></app-geo-google-maps>
      </div>
    </div>
  </kendo-card>
</section>
