import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { IApiAddNefcoOfficeInput, IApiNefcoOffice, IApiUpdateNefcoOfficeInput } from '../../../../shared/modules/graphql/types/types';
import { NotificationsService } from '../../../../shared/modules/notifications/notifications.service';
import { NefcoOfficeService } from 'src/app/shared/services';

@Component({
  selector: 'app-nefco-office-form-modal',
  templateUrl: './nefco-office-form-modal.component.html',
  styleUrls: ['./nefco-office-form-modal.component.scss']
})
export class NefcoOfficeFormModalComponent extends DialogContentBase implements OnInit {
  @ViewChild('form', { static: false }) form: NgForm;
  
  @Input() data: IApiNefcoOffice;
  public model: IApiUpdateNefcoOfficeInput | IApiAddNefcoOfficeInput;

  constructor(
    private nefcoOfficeService: NefcoOfficeService,
    private notifications: NotificationsService,
    public dialogRef: DialogRef
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    const {
      name = "",
      isActive = true,
      Address = null
    } = this.data || {};

    const {
      Type: AddressType,
      address1,
      address2,
      address3,
      city,
      state,
      postal,
      country = "United States"
    } = Address || {};

    this.model = {
      name,
      isActive,
      Address: {
        TypeId: AddressType ? AddressType.id : null,
        address1: address1 || '',
        address2: address2 || '',
        address3: address3 || '',
        city: city || '',
        state: state || '',
        postal: postal || '',
        country
      },
    };
  }
  onSubmit() {
    if (this.form?.invalid) {
      this.form.form.markAllAsTouched();
      return;
    };
    const { id = null } = this.data || {};
    if (id) this.update();
    else this.add();
  }

  public close(action = false) {
    return this.dialogRef.close(action);
  }

  public update() {
    this.model = {
      id: this.data.id,
      ...this.model,
    };

    this.nefcoOfficeService.update(this.model as IApiUpdateNefcoOfficeInput)
    .subscribe(() => {
      this.notifications.notify('Nefco branch Updated');
      this.close(true);
    }, (err) => {
      if (err?.message?.includes('This NEFCO office can not be inactive as')) {
        this.notifications.alert(err?.message, 'Cannot Inactive NEFCO Office');
      } else {
        this.notifications.snackbarError(err?.message);
      }
    });
  }

  public add() {
    this.nefcoOfficeService.add(this.model as IApiAddNefcoOfficeInput).pipe(
      this.notifications.snackbarErrorPipe("Error adding nefco office")
    ).subscribe(() => {
      this.notifications.notify('Nefco branch Addded');
      this.close(true)
    });
  }
}
