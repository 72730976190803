import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ReactiveFormHelper } from 'src/app/shared/helpers/reactive-form.class';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { LocalStorageService, PortalService } from 'src/app/shared/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private fb: UntypedFormBuilder,
    public auth: AuthService,
    private portalService: PortalService,
    private router: Router,
    private loader: LoaderService,
    private local: LocalStorageService,
    private notifications: NotificationsService
  ) {}

  public showAlert: boolean = false;

  public form = this.fb.group({
    email: ['', [Validators.required, Validators.email, Validators.compose([ReactiveFormHelper.emailValidator])]]
  });

  public onSubmit() {
    if (this.form.valid) {
      const formEmail = this.form?.value?.email?.toLowerCase();
      if (this.showAlert) {
        this.loader.show$(this.portalService.createUserAndResetPassword(formEmail)).pipe(this.notifications.snackbarErrorPipe()).subscribe((res) => {
          if (res.data.createUserAndResetPassword) {
            this.router.navigate(['portal/alert']);
          }
        })
      } else {
        this.local.setValueFromSession('clientemail', formEmail);
        this.loader.show$(
          this.portalService.checkClientExist(formEmail)).subscribe(res => {
            if (res?.contact && !res?.clientDetail?.email) {
              this.showAlert = true;
            } else if (res?.clientDetail?.email) {
              this.auth.loginWithRedirect({
                authorizationParams: {
                  login_hint: formEmail,
                  redirect_uri: window.origin + '/portal/intake'
                }
              });
            } else {
              this.local.setValueFromSession('formemail', formEmail);
              this.local.removeValueFromSession('clientemail');
              this.router.navigate(['portal/intake']);
            }
          });
      }
    } else {
      this.showAlert = false;
      this.form.markAllAsTouched();
    }
  }

}
