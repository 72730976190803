import { IApiExpenseItem, IApiExpenseType, IApiInvoiceFilterType, IApiInvoiceItemFilterType, IApiTimeEntryType } from './../../../../shared/modules/graphql/types/types';
import { InvoiceItemService } from './../../../../shared/services/invoice/invoice-item/invoice-item.service';
import { InvoiceItemDataSource } from './../../../../shared/services/invoice/invoice-item/invoice-item.datasource';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { AuthService, DocumentService, DocumentTypeService, ExpenseService, InvoiceService, TimeEntryService, TimeEntryTypeService } from "src/app/shared/services";
import { InvestigationTimeAndExpQuickbooksModalComponent } from '../investigation-time-and-exp-quickbooks-modal/investigation-time-and-exp-quickbooks-modal.component';
import { IApiAddExpenseInput, IApiAddTimeEntryInput, IApiDocumentType, IApiDocumentTypeOrderBy, IApiExpense, IApiExpenseFilterType, IApiExpenseOrderBy, IApiInvestigation, IApiInvestigationStaff, IApiInvoice, IApiTimeEntry, IApiTimeEntryFilterType, IApiUpdateExpenseInput, IApiUpdateTimeEntryInput, IApiUser } from "src/app/shared/modules/graphql/types/types";
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { ActivatedRoute } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { PermissionActions, PermissionCategories } from 'src/app/shared/modules/graphql/constants/permission.constants';
import { IEnforcePermissionConfig, EnforcePermissionDisplayModes, EnforcePermissionDirective } from 'src/app/shared/directives/enforce-permission.directive';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  expenseInvestigationExpensesAll,
  expenseInvestigationExpensesOwn,
  expenseInvestigationExpensesAllAssigned,
  timeEntryInvestigationHoursListOwnAssign,
  expenseInvestigationExpensesListOwnAssign,
  expenseInvestigationMileageListOwnAssign,
  timeEntryInvestigationHoursListAll,
  expenseInvestigationMileageListAll,
  expenseInvestigationExpensesListAll,
  expenseInvestigationExpensesCreate,
  timeEntryInvestigationHoursCreate,
  expenseInvestigationMileageCreateOwnAssign,
  timeEntryInvestigationHoursCreateOwnAssign,
  expenseInvestigationMileageCreate
} from "../../../../shared/helpers/auth-config/time-expenses.config";
import { TimeEntryDataSource } from 'src/app/shared/services/time-entry';
import { ExpenseDataSource, ExpenseItemService } from 'src/app/shared/services/expenses';
import { InvoiceDataSource } from "src/app/shared/services/invoice/invoice.datasource";
import { forkJoin } from 'rxjs';
import { SortDescriptor } from '@progress/kendo-data-query';
import { FormExpenseType } from '../investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { IInvestigationTimeExpenseModalData, InvestigationTimeAndExpModalKendoComponent } from '../investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component';
import { ExpenseActionType } from 'src/app/shared/modules/graphql/enums/expense.enums';

interface IUpdateExpenseInputAndReceipt extends IApiUpdateExpenseInput {
  Receipt?: {
    key: string,
    filename: string,
    size: number
  };
}
@UntilDestroy()
@Component({
  selector: 'app-investigation-time-and-exp',
  templateUrl: './investigation-time-and-exp.component.html',
  styleUrls: ['./investigation-time-and-exp.component.scss'],
})

export class InvestigationTimeAndExpComponent implements OnInit, AfterViewInit {

  @ViewChild("timeEntryInvestigationHoursCreate") timeEntryInvestigationHoursCreatePermission;
  @ViewChild("expenseInvestigationMileageCreate") expenseInvestigationMileageCreatePermission;
  @ViewChild("expenseInvestigationExpensesCreate") expenseInvestigationExpensesCreatePermission;
  @ViewChild("perm") permissionDirective: EnforcePermissionDirective;
  @Output() reload = new EventEmitter<boolean>();
  @Input() public tables: any;
  @Input() public investigation: IApiInvestigation;
  public rangerAndWeekFilters: {dateRangeFilter: string, dateFilterYear: string, dateFilterWeek: string} = null;

  public authConfig = {
    expenseInvestigationExpensesAll: {
      ...expenseInvestigationExpensesAll,
      displayMode: EnforcePermissionDisplayModes.StandAlone
    },
    expenseInvestigationExpensesOwn,
    expenseInvestigationExpensesAllAssigned,
    timeEntryInvestigationHoursListOwnAssign,
    expenseInvestigationExpensesListOwnAssign,
    expenseInvestigationMileageListOwnAssign,
    timeEntryInvestigationHoursListAll,
    expenseInvestigationMileageListAll,
    expenseInvestigationExpensesListAll,
    timeEntryInvestigationHoursCreate,
    expenseInvestigationExpensesCreate,
    expenseInvestigationMileageCreateOwnAssign,
    timeEntryInvestigationHoursCreateOwnAssign,
    expenseInvestigationMileageCreate
  };

  public timeEntry: IApiUpdateTimeEntryInput = {
    id: null,
    TypeId: null,
    workday: null,
    hours: null,
    UserId: null,
    InvestigationId: null,
    description: ''
  };

  public expenseEntry: IApiUpdateExpenseInput = {
    id: null,
    ExpensePurposeId: null,
    expenseDate: null,
    ExpenseItemId: null,
    outOfPocket: 1,
    billableQuantity: null,
    description: '',
  };
  // Data Sources
  private _investigations = null;
  private _expenses: ExpenseDataSource = null;
  private _expensesTotal = [];
  public expensesVisible: IApiExpense[] = [];
  private _mileage: ExpenseDataSource = null;
  private _mileageTotal = [];
  private _time: TimeEntryDataSource = null;
  private _timeTotal = [];
  public timeVisible: IApiTimeEntry[] = [];

  private _invoices: InvoiceDataSource = null;
  private _invoiceItems: InvoiceItemDataSource = null;

  public mileageForm = {
    expenseDate: null,
    billableQuantity: null,
    nefcoVehicle: null,
    outOfPocket: 0,
    ExpenseItemId: null,
    ExpensePurposeId: null
  };

  public get investigations() {
    return this._investigations;
  }
  public set investigations(val) {
    this._investigations = val;
  }

  // Get Expenses / Mileage
  public get expenses(): ExpenseDataSource {
    return this._expenses;
  }

  public set expenses(val) {
    this._expenses = val;
  }

  public get expensesPageOptions() {
    if (!this._expenses) return null;
    return this._expenses.listPage;
  }

  // Get total selected raw
  public get totalselectedRaw(): number {
    return this.selectedExpenses?.length + this.selectedTime?.length;
  }

  // Get Mileage
  public get mileage(): ExpenseDataSource {
    return this._mileage;
  }

  public set mileage(val) {
    this._mileage = val;
  }

  public get mileagePageOptions() {
    if (!this._mileage) return null;
    return this._mileage.listPage;
  }

  // Get Time / Hours
  public get time(): TimeEntryDataSource {
    return this._time;
  }
  public set time(val) {
    this._time = val;
  }

  public get timePageOptions() {
    if (!this._time) return null;
    return this._time.listPage;
  }

  // Get Invoices & Invoice Items
  public get invoices(): InvoiceDataSource {
    return this._invoices;
  }

  public get invoicesPageOptions() {
    if (!this._invoices) return null;
    return this._invoices.listPage;
  }

  public get invoiceItems(): InvoiceItemDataSource {
    return this._invoiceItems;
  }

  public get invoiceItemsPageOptions() {
    if (!this._invoiceItems) return null;
    return this._invoiceItems.listPage;
  }

  // Get evidence expenses if they exist
  public get evidenceExpenses(): IApiExpense[] {
    return this.investigation?.Expenses?.sort((a, b) => (a.expenseDate > b.expenseDate) ? 1 : -1).filter(({ ExpenseItem: { id } }) => id === this.evidenceExpenseItemId);
  }

  // Selections
  public selectedExpenses = [];
  public selectedTime = [];
  public selectedEvidence = [];

  // User Info
  public staffMember = "";
  public user = "";
  public users: IApiUser[] = [];

  // Get Current User
  private _isAdmin = false;
  public get isAdmin(): boolean {
    return this._isAdmin;
  }
  public set isAdmin(val) {
    this._isAdmin = val;
  }

  // Filters for Invoices
  public timeExpenseTypeFilter: IApiExpenseType[] | IApiTimeEntryType[] = [];
  private _evidenceExpenseItemId: string;
  private get evidenceExpenseItemId() {
    return this._evidenceExpenseItemId;
  }
  public invoiceNumFilter: string = '';
  public invoiceStaffMember = "";
  public showDateEntered: boolean = false;
  public clearSelection: boolean = false;
  public createRecord: string;
  public expenseType: ExpenseActionType | null;

  public get IApiInvoiceItemFilterType() {
    return IApiInvoiceItemFilterType;
  }

  // Document Upload
  public documentTypes: [IApiDocumentType];

  // Table Arrays
  public timeAndExpHoursAllOnColumns = ["date", "staff", "type", "entered"];
  public timeAndExpHoursOwnColumns = ["date", "type", "entered"];

  public hourTotalsColumns = ["total", "blank", "entered"];
  public mileageTotalsColumns = ["total", "estimated", "entered"];

  public timeAndExpMileageAllOnColumns = ["date", "staff", "estimated", "entered"];
  public timeAndExpMileageOwnColumns = ["date", "estimated", "entered"];

  public adminConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.INVESTIGATION,
    appliedPermissions: {
      All: [PermissionActions.VIEW, PermissionActions.CREATE, PermissionActions.UPDATE],
      AllAssigned: [],
      Assigned: [],
      Own: []
    },
    displayMode: EnforcePermissionDisplayModes.StandAlone
  };
  public allOnAssignConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.INVESTIGATION,
    appliedPermissions: {
      All: [],
      AllAssigned: [PermissionActions.VIEW],
      Assigned: [],
      Own: []
    },
    displayMode: EnforcePermissionDisplayModes.StandAlone
  };

  public refreshHour: boolean = false;

  // admin columns
  public adminOutstandingColumns = ["date", "staff", "type", "purpose", "billable", "non_billable", "description", "paid", "approval", "actions"];
  public invoiceColumns = ["date", "desc", "amount", "invoicedBy", "qbErrors", "qbSubmitted", "edit", "invoice_num"];
  public invoiceItemColumns = ["date", "staff", "type", "purpose", "billable", "non_billable", "description", "paid", "approval", "invoice_num", "actions"];
  public timeAndExpEvidenceAllColumns = ["date", "description", "pallets", "entered_by", "rate", "billable", "schedule", "invoice_num"];

  // used for both time and mileage
  public timeColumnsOwn = ["date", "type", "entered", "edit"];
  public timeColumnsAllOnAssign = ["date", "staff", "type", "entered", "edit"];
  public timeColumnsAll = ["date", "staff", "type", "purpose", "billable", "non_billable", "description", "paid", "approval"];

  public expenseColumnsOwn = ["date", "expense", "purpose", "qty", "receipt", "edit"];
  public expenseColumnsAllOnAssign = ["date", "staff", "expense", "purpose", "qty", "receipt", "edit"];

  public mileageColumnsOwn = ["date", "estimated", "entered", "edit"];
  public mileageColumnsAllOnAssign = ["date", "staff", "estimated", "entered", "edit"];
  public employeeFilter = "";

  public expenseItems: IApiExpenseItem[] = null;
  public reloadExpense: boolean = false;
  constructor(
    private expenseService: ExpenseService,
    private timeEntryService: TimeEntryService,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private notificationService: NotificationsService,
    private documentService: DocumentService,
    private documentTypeService: DocumentTypeService,
    private invoiceService: InvoiceService,
    private invoiceItemService: InvoiceItemService,
    private expenseItemService: ExpenseItemService,
    private timeEntryTypeService: TimeEntryTypeService,
    private auth: AuthService,
    private dialogService: DialogService,
  ) {
  }


  public openDialog(item?: IApiExpense | IApiTimeEntry, editQtyOnly = false, adminView = true) {

    const expenseItem = (item as IApiExpense)?.expenseDate ? { ...item } : {
      id: null,
      nefcoVehicle: 0,
      expenseDate: new Date(),
      outOfPocket: this.expenseType === ExpenseActionType.ADD_EXPENSE ? 1 : 0,
      billableQuantity: null,
      nonBillableQuantity: null,
      ExpenseItem: { id: null, name: null },
      ExpensePurpose: { id: null, name: null },
      description: null,
      Investigation: this.investigation,
    };

    const timeItem = (item as IApiTimeEntry)?.workday ? { ...item } : {
      id: null,
      workday: new Date(),
      Type: { id: null, createdAt: new Date(), updatedAt: new Date() },
      hours: 0,
      description: '',
      User: { id: null, email: null },
      createdAt: new Date(),
      updatedAt: new Date()
    };

    const data: IInvestigationTimeExpenseModalData = {
      investigationId: this.investigation.id,
      nefcoNumber: this.investigation.nefcoNumber,
      expense: expenseItem as IApiExpense,
      time: timeItem as IApiTimeEntry,
      staff: this.investigation?.InvestigationStaff,
      editQtyOnly,
      adminView,
      selectedExpenseType: this.createRecord,
      showStaffMemberNew: true,
      showStaffMember: false,
      actionType: item ? null : this.expenseType
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpModalKendoComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpModalKendoComponent;
    dialogInstance.data = data;
    dialogInstance.documentTypes = this.documentTypes;
    dialogInstance.investigations = this.investigations;
    dialogInstance.investigationStaffList = this.staffSort(this.investigation?.InvestigationStaff);
    dialogInstance.expenseItems = this.expenseItems;
    dialogInstance.investigationView = true;
    dialogInstance.staffMember = item?.User?.id;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.reload.emit(true);
        this.resetSelect();
        this.load();
      };
    })
  }

  // edit hours entry
  public editTime(item?: IApiTimeEntry) {
    const timeItem = (item as IApiTimeEntry)?.workday
      ? { ...item }
      : {
        id: null,
        Type: null,
        workday: new Date(),
        hours: null,
        nonBillableHours: null,
        User: null,
      };

    const data: IInvestigationTimeExpenseModalData = {
      investigationId: this.investigation.id,
      nefcoNumber: item?.Investigation?.nefcoNumber,
      expense: null,
      time: timeItem as IApiTimeEntry,
      staff: null,
      adminView: true,
      showStaffMemberNew: true
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpModalKendoComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpModalKendoComponent;
    dialogInstance.data = data;
    dialogInstance.documentTypes = [];
    dialogInstance.investigations = this.investigations;
    dialogInstance.investigationStaffList = this.staffSort(this.investigation?.InvestigationStaff);
    dialogInstance.expenseItems = [];
    dialogInstance.investigationView = true;
    dialogInstance.staffMember = item?.User?.id;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.reload.emit(true);
        this.time.load()
      };
    })
  }

  // edit expenses / milage
  public editExpense(item?: IApiExpense) {

    const expenseItem = (item as IApiExpense)?.expenseDate ? { ...item } : {
      id: null,
      nefcoVehicle: 0,
      expenseDate: new Date(),
      outOfPocket: 0,
      billableQuantity: null,
      nonBillableQuantity: null,
      ExpenseItem: { id: null, name: null },
      ExpensePurpose: { id: null, name: null }
    };

    const data: IInvestigationTimeExpenseModalData = {
      investigationId: item.Investigation?.id,
      nefcoNumber: item.Investigation?.nefcoNumber,
      expense: expenseItem as IApiExpense,
      time: null,
      staff: null,
      adminView: true,
      showStaffMember: false,
      showStaffMemberNew: true
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpModalKendoComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpModalKendoComponent;
    dialogInstance.data = data;
    dialogInstance.documentTypes = this.documentTypes;
    dialogInstance.investigations = this.investigations;
    dialogInstance.expenseItems = this.expenseItems;
    dialogInstance.investigationView = true;
    dialogInstance.investigationStaffList = this.staffSort(this.investigation?.InvestigationStaff);
    dialogInstance.staffMember = item?.User?.id;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.reload.emit(true);
        this.expenses.load()
      };
    })
  }

  public openQbDialog(invoice?: IApiInvoice, disableEdit = false) {

    const selectedItems = {
      evidence: this.selectedEvidence,
      time: this.selectedTime,
      expenses: this.selectedExpenses
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpQuickbooksModalComponent,
      width: "90%",
      autoFocusedElement: 'invoiceNumber',
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpQuickbooksModalComponent;
    dialogInstance.data = {
      investigation: {
        ...this.investigation,
        TimeEntries: this.investigation?.TimeEntries.filter(obj => selectedItems.time.includes(obj.id)),
        Expenses: this.investigation?.Expenses.filter(obj => selectedItems.expenses.includes(obj.id)),
        Evidence: this.investigation?.Evidence.filter(obj => selectedItems.evidence.includes(obj.id)),
        Mileage: this.investigation?.Mileage.filter(obj => selectedItems.expenses.includes(obj.id))
      },
      invoice,
      disableEdit
    };
    dialog.result.subscribe((res) => {
      if (res === true) {
        this.reload.emit(true);
        this.resetSelect();
        this.load();
      }
    });
  }

  public addEntryMenu = [];

  ngAfterViewInit() {
    if (this.timeEntryInvestigationHoursCreatePermission?.enabled) {
      this.addEntryMenu.push({text: 'Add Hours'});
    }
    if (this.expenseInvestigationMileageCreatePermission?.enabled) {
      this.addEntryMenu.push({text: 'Add Mileage'});
    }
    if (this.expenseInvestigationExpensesCreatePermission?.enabled) {
      this.addEntryMenu.push({text: 'Add Expense'});
    }
  }

  ngOnInit() {

    // Initializing Time
    this._time = new TimeEntryDataSource(this.timeEntryService);
    this.time.applyFilter(IApiTimeEntryFilterType.Investigation, this.investigation?.id);
    if (this.authConfig.expenseInvestigationExpensesAll) this.time.applyFilter(IApiTimeEntryFilterType.Invoiced, 'false');
    this._time.listPage.limit = 5;
    this.time.contents$.pipe(untilDestroyed(this)).subscribe((content) => {
      this.timeVisible = content;
      this.clearSelection = false;
      this.loader.hide();
    });

    // Initializing Expenses
    this._expenses = new ExpenseDataSource(this.expenseService);
    this.expenses.applyFilter(IApiExpenseFilterType.Investigation, this.investigation?.id);
    this._expenses.listPage.limit = 5;
    this.expenses.contents$.pipe(untilDestroyed(this)).subscribe((content) => {
      this.expensesVisible = content;
      this.loader.hide();
    });

    this._invoices = new InvoiceDataSource(this.invoiceService);
    this.loader.attachObservable(this._invoices.loading$);
    this._invoices.applyFilter(IApiInvoiceFilterType.Investigation, this.investigation?.id);
    this._invoices.listPage.limit = 5;

    this._invoiceItems = new InvoiceItemDataSource(this.invoiceItemService);
    this.loader.attachObservable(this._invoiceItems.loading$);
    this._invoiceItems.applyFilter(IApiInvoiceItemFilterType.Investigation, this.investigation?.id);
    this._invoiceItems.listPage.limit = 5;

    // Initializing Mileage, NonAdmin Only
    this._mileage = new ExpenseDataSource(this.expenseService);
    this.mileage.applyFilter(IApiExpenseFilterType.Investigation, this.investigation?.id);
    this.mileage.applyFilter(IApiExpenseFilterType.Mileage, "true");
    this._mileage.listPage.limit = 5;

    // Apply Filters if Admin/NonAdmin User
    this.auth.hasCategoryPermission(this.adminConfig.category, this.adminConfig.appliedPermissions)
      .pipe(untilDestroyed(this))
      .subscribe((enabled) => {
        if (enabled) {
          this.expenses.applyFilter(IApiExpenseFilterType.IsInvoiced, 'false');
          this._isAdmin = true;
          this.employeeFilter = null;
        } else {
          this.time.applyFilter(IApiTimeEntryFilterType.Employee, "");
          this.employeeFilter = "";
          this.expenses.applyFilter(IApiExpenseFilterType.User, "");
          this.expenses.applyFilter(IApiExpenseFilterType.Mileage, "false");
          this.mileage.applyFilter(IApiExpenseFilterType.User, "");
        }
      });

    // Load Data Sources
    this.load();

    // Document Types
    this.documentTypeService.get([], { sortOrder: SortOrder.ASC, orderBy: IApiDocumentTypeOrderBy.Name, limit: -1 }).pipe(
      unwrapConnection(),
    ).subscribe((result) => {
      this.documentTypes = result;
    });

    // Load Time / Expense filter types
    this.loader.show$(
      forkJoin([
        this.expenseItemService.get().pipe(unwrapConnection()),
        this.timeEntryTypeService.get().pipe(unwrapConnection())
      ])
    ).subscribe(([expenseTypes, timeEntryTypes]) => {
      const typeEntries = [...expenseTypes, ...timeEntryTypes];
      this.expenseItems = expenseTypes || [];
      this._evidenceExpenseItemId = expenseTypes.find((obj) => obj.name === 'Annual Evidence Storage').id;
      this.timeExpenseTypeFilter = typeEntries.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    });
  }

  public itemSelected($event) {
    switch ($event?.text) {
      case 'Add Hours':
        this.createRecord = FormExpenseType.HOURS;
        this.expenseType = ExpenseActionType.ADD_TIME
        break;
      case 'Add Expense':
        this.createRecord = FormExpenseType.EXPENSE;
        this.expenseType = ExpenseActionType.ADD_EXPENSE
        break;
      case 'Add Mileage':
        this.createRecord = FormExpenseType.MILEAGE;
        this.expenseType = ExpenseActionType.ADD_MILEAGE
        break;
      default:
        this.createRecord = '';
        this.expenseType = null;
        break;
    }
    if (!this.createRecord) {
      return
    }
    this.openDialog();
  }

  public sortChange = (e: SortDescriptor[]) => {
    if (e && e?.[0]?.dir) {
      this._time.listPage.orderBy = e?.[0]?.field;
      this._time.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this._time.listPage.orderBy = 'WORKDAY';
      this._time.listPage.sortOrder = SortOrder.ASC;
    }
    this.loader.show();
    this.time.load();
  }

  public sortExpenceChange = (e: SortDescriptor[]) => {
    if (e && e?.[0]?.dir) {
      this._expenses.listPage.orderBy = e?.[0]?.field;
      this._expenses.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this._expenses.listPage.orderBy = 'EXPENSE_DATE';
      this._expenses.listPage.sortOrder = SortOrder.ASC;
    }
    this.loader.show();
    this.expenses.load();
  }

  public pageChange = (event) => {
    this.loader.show();
    this.timePageOptions?.paginate(event)
  }

  public pageExpenceChange = (event) => {
    this.loader.show();
    this.expensesPageOptions?.paginate(event)
  }

  // Apply User Filter, Admin View
  public setUser(value) {
    this.time.applyFilter(IApiTimeEntryFilterType.Employee, value);
    this.employeeFilter = value;
    this.expenses.applyFilter(IApiExpenseFilterType.User, value);
    this.resetSelect();
    this.load();
  }

  // Apply Invoice Filter, Admin View
  public filterInvoice(filterType: IApiInvoiceItemFilterType, value: string) {
    if (value) this._invoiceItems.applyFilter(filterType, value);
    else this._invoiceItems.removeFilter(filterType);
    this.load();
  }

  // Time, Mileage, Expense Totals, NonAdmin View
  public get timeEntryTotal() {
    return this._timeTotal.reduce((p, c) => p + c.hours + c.nonBillableHours, 0);
  }
  public get mileageTotal() {
    return this._mileageTotal.reduce((p, c) => p + c.billableQuantity + c.nonBillableQuantity, 0);
  }
  public get expensesTotal() {
    return this._expensesTotal.reduce((p, c) => p + c.billableQuantity + c.nonBillableQuantity, 0);
  }

  public addTime(event: IApiUpdateTimeEntryInput) {
    this.refreshHour = false;
    const { investigationId } = this.route.snapshot.params;
    const input = {
      ...event,
      InvestigationId: investigationId
    };
    delete input.id;
    this.loader.show$(
      this.timeEntryService.add(input as IApiAddTimeEntryInput).pipe(
        this.notificationService.snackbarPipe("Time entry added."),
        this.notificationService.snackbarErrorPipe("Error adding time entry.")
      )
    ).subscribe((val) => {
      this.timeEntry = {
        id: null,
        TypeId: null,
        workday: new Date(),
        hours: null,
        UserId: null,
        InvestigationId: null,
        description: ''
      };
      this.refreshHour = true;
      this.reload.emit(true);
      this.resetSelect();
    });
  }

  public async addExpense(e: IUpdateExpenseInputAndReceipt, mileage = false) {
    this.reloadExpense = false;
    const { investigationId } = this.route.snapshot.params;
    const key = e.Receipt ? e.Receipt.key : null;
    const filename = e.Receipt ? e.Receipt.filename : null;

    let DocumentId = null;

    if (!!key) {
      await this.documentService.add({
        uri: '',
        s3Uri: key,
        title: filename,
        caption: filename,
        TypeId: this.documentTypes.filter(t => t.name === "Other")[0].id,
      }).pipe(
        this.notificationService.snackbarErrorPipe("Error uploading document; please try again")
      ).toPromise().then((r) => {
        DocumentId = r.id;
      });
    }

    const input = {
      ...e,
      DocumentId,
      InvestigationId: investigationId,
    };
    mileage ? input.outOfPocket = 0 : input.nefcoVehicle = 0;
    delete input.Receipt;
    delete input.id;
    this.loader.show$(
      this.expenseService.add(input as IApiAddExpenseInput).pipe(
        this.notificationService.snackbarPipe("Expense/Mileage item added."),
        this.notificationService.snackbarErrorPipe("Error adding expense/mileage item.")
      )
    ).subscribe((val) => {

      const { ExpensePurposeId, ExpenseItemId } = this.mileageForm;

      this.expenseEntry = {
        id: null,
        ExpensePurposeId: null,
        expenseDate: new Date(),
        ExpenseItemId: null,
        outOfPocket: 1,
        billableQuantity: null,
        description: ''
      };
      this.mileageForm = {
        expenseDate: new Date(),
        billableQuantity: null,
        nefcoVehicle: null,
        outOfPocket: 0,
        ExpenseItemId,
        ExpensePurposeId
      };
      this.reload.emit(true);
      this.resetSelect();
      this.load();
      this.reloadExpense = true;
    });

  }

  private resetSelect() {
    this.selectedExpenses = [];
    this.selectedTime = [];
    this.timeVisible = [];
    this.clearSelection = true;
  }

  public removeEntry(entry) {
    this.notificationService.kendoConfirm("Are you sure you want to delete this entry?").pipe(
      filter((v) => !!v),
      switchMap(() => {
        if (entry?.ExpenseItem) {
          return this.loader.show$(
            this.expenseService.remove(entry.id)
          );
        }
        else {
          return this.loader.show$(
            this.timeEntryService.remove(entry.id)
          );
        }
      })
    ).subscribe(() => {
      this.reload.emit(true);
      this.resetSelect();
      this.load();
    });
  }

  public load() {

    // If Admin call time and expenses api
    if (this.isAdmin) {
      this.invoices.pagingReset();
      this.invoiceItems.pagingReset();
      this.time.pagingReset();
      this.expenses.pagingReset();
      this.time.load();
      this.expenses.load();
      this.invoices.load();
      this.invoiceItems.load();
    }


    // If NonAdmin, recalculate totals
    if (!this.isAdmin) {

      // Total Hours
      // this.timeEntryService.get([
      //   { type: IApiTimeEntryFilterType.Employee, value: "" },
      //   { type: IApiTimeEntryFilterType.Investigation, value: this.investigation?.id }
      // ], { sortOrder: SortOrder.ASC, orderBy: IApiTimeEntryOrderBy.Employee, limit: -1 }).pipe(
      //   unwrapConnection(),
      // ).subscribe((result) => {
      //   this._timeTotal = result;
      // });

      // Total Mileage
      this.expenseService.get([
        { type: IApiExpenseFilterType.User, value: "" },
        { type: IApiExpenseFilterType.Mileage, value: "true" },
        { type: IApiExpenseFilterType.Investigation, value: this.investigation?.id }
      ], { sortOrder: SortOrder.ASC, orderBy: IApiExpenseOrderBy.CreatedAt, limit: -1 }).pipe(
        unwrapConnection(),
      ).subscribe((result) => {
        this._mileageTotal = result;
      });

      // Total Expenses, no Mileage
      this.expenseService.get([
        { type: IApiExpenseFilterType.User, value: "" },
        { type: IApiExpenseFilterType.Mileage, value: "false" },
        { type: IApiExpenseFilterType.Investigation, value: this.investigation?.id }
      ], { sortOrder: SortOrder.ASC, orderBy: IApiExpenseOrderBy.ExpenseDate, limit: -1 }).pipe(
        unwrapConnection(),
      ).subscribe((result) => {
        this._expensesTotal = result;
      });
    }
  }

  public staffSort(list: IApiInvestigationStaff[]) {
    if (!list || !list.length) return [];
    let list_ = [...list];
    list_.map(a => {
      a['userName'] = `${a?.User?.firstName} ${a?.User?.lastName}`;
      a['userId'] = a?.User?.id;
    });
    return list_.sort((a, b) => a.User?.lastName > b.User.lastName ? 1 : -1);
  }

  public calculateAmount(invoice: IApiInvoice) {
    return (invoice?.InvoiceLines || []).reduce((prev, { rate, quantity }) => {
      prev += rate * quantity;
      return prev;
    }, 0);
  }

  public resubmitInvoice(invoice: IApiInvoice) {
    this.notificationService.kendoConfirm("Are you sure this invoice is ready for resubmission?").pipe(
      filter(v => !!v),
      switchMap(() => this.invoiceService.resubmit(invoice.id)),
      this.notificationService.snackbarErrorPipe("Error resubmitting invoice"),
      this.notificationService.snackbarPipe("Invoice resubmitted!")
    ).subscribe(() => this.load());
  }

  public deleteInvoice(invoice: IApiInvoice) {
    this.notificationService.kendoConfirm("Are you sure you want to delete this invoice?").pipe(
      filter(v => !!v),
      switchMap(() => this.invoiceService.remove(invoice.id)),
      this.notificationService.snackbarErrorPipe("Error deleting invoice"),
      this.notificationService.snackbarPipe("Invoice deleted.")
    ).subscribe(() => {
      this.reload.emit(true);
      this.resetSelect();
      this.load();
    });
  }

  public addEvidenceExpense() {
    const input: IApiAddExpenseInput = {
      ExpenseItemId: this.evidenceExpenseItemId,
      InvestigationId: this.investigation.id,
      billableQuantity: (parseInt(this.investigation?.EvidenceBilling?.palletCount, 10) || 0) * (this.investigation?.EvidenceBilling?.rate || 0) * (parseInt(this.investigation?.EvidenceBilling?.BillingFrequency?.months, 10) || 0)
    };

    this.notificationService.kendoConfirm("Are you sure you want to add the current evidence items to an expense? This action will overwrite an existing evidence expense that hasn't been invoiced yet.").pipe(
      filter(v => !!v),
      switchMap(() => this.expenseService.add(input)),
      this.notificationService.snackbarErrorPipe(),
      this.notificationService.snackbarPipe("Evidence expense successfully added")
    ).subscribe(() => {
      this.reload.emit(true);
      this.resetSelect();
      this.load();
    });
  }

  public changedFilter($event): void {
    this.rangerAndWeekFilters = $event;
  };

}
