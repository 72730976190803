<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationPhotosDownload" #investigationPhotosDownload="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationPhotos" #investigationPhotos="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationPhotoShareCreate" #investigationPhotoShareCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationDocumentsListView" #investigationDocumentsListView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationDocumentsDownload" #investigationDocumentsDownload="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationDocumentShareCreate" #investigationDocumentShareCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationRequestsCreate" #investigationRequestsCreate="appEnforcePermission"></div>
<!-- Permissions -->
<div class="flexContainer headerBar page-header">
  <!-- Title and investigation number -->
  <div>
    <h4 class="page-title mb-0">
      Investigation# {{ investigation.nefcoNumber}}
    </h4>
  </div>
  <!-- icon section -->
  <div class="headerIcons flexContainer">
    <div class="equal" *ngIf="investigationDocumentsListView?.enabled && investigationRequestsCreate?.enabled">
      <button kendoButton themeColor="secondary" fillMode="link" iconClass="fa-solid fa-clipboard-check fa-lg"
        (click)="openRequestModal()">
      </button>
    </div>
    <div class="equal" *ngIf="investigationPhotos?.enabled && investigationPhotosDownload?.enabled">
      <button kendoButton themeColor="secondary" fillMode="link" iconClass="fa-solid fa-image fa-lg"
        (click)="openPhotoModal()">
      </button>
    </div>
    <div class="equal" *ngIf="investigationDocumentsListView?.enabled && investigationDocumentsDownload?.enabled">
      <button kendoButton themeColor="secondary" fillMode="link" iconClass="fa-solid fa-file-arrow-down fa-lg"
        (click)="openDocumentsModal()">
      </button>
    </div>
    <div class="equal"
      *ngIf="(investigationPhotos?.enabled && investigationPhotoShareCreate?.enabled) || (investigationDocumentsListView?.enabled && investigationDocumentShareCreate?.enabled)">
      <button kendoButton themeColor="secondary" fillMode="link" iconClass="fa-solid fa-share-nodes fa-lg"
        (click)="openShareFilesModal()">
      </button>
    </div>
    <div class="equal right-icon-none">
      <button kendoButton themeColor="secondary" fillMode="link" iconClass="fa-solid fa-arrow-up-right-from-square fa-lg"
        (click)="openReport()">
      </button>
    </div>
  </div>
</div>
<section class="flexContainer">

  <kendo-tabstrip #tabstrip class="investigation-tabs" [tabAlignment]="'justify'"
  (tabSelect)="changeTab($event)"
  >
    <kendo-tabstrip-tab [selected]="selectedTab === 0">
      <ng-template kendoTabTitle>
        <i class="fa-solid fa-clipboard-list fa-lg"></i>
        <span>DETAILS</span>
      </ng-template>
      <ng-template kendoTabContent>
          <div>
              <!-- Details Component -->
              <app-investigation-detail (reload)="reload($event)" [investigation]="investigation"></app-investigation-detail>
          </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [selected]="selectedTab === 1">
      <ng-template kendoTabTitle>
        <i class="fa-solid fa-clipboard-check fa-lg"></i>
        <span>SERVICES</span>
      </ng-template>
      <ng-template kendoTabContent>
          <div>
            <!-- Services Component -->
            <app-investigation-services-header (reload)="reload()" [investigation]="investigation"></app-investigation-services-header>
          </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [selected]="selectedTab === 2">
      <ng-template kendoTabTitle>
        <i class="fa-solid fa-fire fa-lg"></i>
        <span>EVIDENCE</span>
      </ng-template>
      <ng-template kendoTabContent>
          <div>
            <!-- Evidence Component -->
            <app-evidence [investigation]="investigation" [userId]="userId" (reload)="reload()"></app-evidence>
          </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [selected]="selectedTab === 3">
      <ng-template kendoTabTitle>
        <i class="fa-solid fa-image fa-lg"></i>
        <span>PHOTOS</span>
      </ng-template>
      <ng-template kendoTabContent>
          <div>
            <!-- Photos component -->
            <app-investigation-photos [investigation]="investigation" (reload)="reload()"></app-investigation-photos>
          </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [selected]="selectedTab === 4">
      <ng-template kendoTabTitle>
        <i class="fa-solid fa-folder fa-lg"></i>
        <span>DOCUMENTS</span>
      </ng-template>
      <ng-template kendoTabContent>
          <div>
            <!-- Documents component -->
            <app-investigation-documents [investigation]="investigation" (changed)="handleUploadsChanged($event)" (reload)="reload()"></app-investigation-documents>
          </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [selected]="selectedTab === 5">
      <ng-template kendoTabTitle>
        <i class="fa-solid fa-file-invoice-dollar fa-lg"></i>
        <span>TIME &amp; EXPENSES</span>
      </ng-template>
      <ng-template kendoTabContent>
          <div>
            <!-- Time entry component -->
            <app-investigation-time-and-exp [investigation]="investigation" (reload)="reload()"></app-investigation-time-and-exp>
          </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</section>