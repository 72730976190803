<div [appEnforcePermission]="authConfig.investigationRestrictionsDelete"
  #investigationRestrictionsDelete="appEnforcePermission"></div>
<section>
  <div class="page-filters">
    <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" [cols]="['repeat(4,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <div class="kendo-formfield left-search-icon">
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-textbox #search [(ngModel)]="searchValue" (valueChange)="setFilters($event, filterTypes.Search)"
              placeholder="Search" fillMode="outline" [clearButton]="false"
              [value]="filterValue(filterTypes.Search || null)">
            </kendo-textbox>
          </div>
        </div>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <app-user-multi-select-kendo label="" [userViewFilter]="userViewFilter" placeholder="Search by staff member"
            [selectedId]="filterByIdValue(filterTypes.User)"
            (selectionChanged)="setFilters($event, filterTypes.User)"></app-user-multi-select-kendo>
        </kendo-gridlayout-item>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="filter-actions" [colSpan]="2" [col]="0" [row]="0">
        <div class="applied-filter mr-16">
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="applyFilter()">Apply
              Filters</kendo-button>
          </div>
        </div>
        <div class="clear-filter mt-5">
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearFilter()">Clear
              All</kendo-button>
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <section>
      <kendo-grid [data]="dataSource.contents$ | async" [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset"
        [pageable]="{
          buttonCount: 6,
          type: 'numeric',
          pageSizes: [5, 10, 25, 100],
          previousNext: true,
          responsive: true,
          position: 'bottom'
        }" (pageChange)="pageOptions?.paginate($event)" [sortable]="true" [sort]="sort"
        (sortChange)="sortChange($event)" [navigatable]="false">
        <kendo-grid-column field="CREATED_AT" title="Date/Time" [width]="10" [sortable]="true"
          [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <p>
              {{ dataItem?.createdAt | customDate : "MM/dd/yyyy" }}
              <br>
              {{ dataItem?.createdAt | customDate : "hh:mm a" }}
            </p>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="NEFCO_NUMBER" title="ID #" [width]="10" [sortable]="true"
          [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a target="_blank" [routerLink]="['/investigations/', dataItem?.Investigation?.id]" tel=""
              class="text-decoration"> {{dataItem?.Investigation?.nefcoNumber || 'Unassigned'}}</a>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="STAFF_MEMBER" title="Staff Member" [width]="35" [sortable]="false"
          [headerClass]="'nocursor'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <p>
              {{dataItem?.User | fullName}}
            </p>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="TYPE" title="Type" [width]="35" [sortable]="false" [headerClass]="'nocursor'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container *ngIf="dataItem?.User?.Roles?.length else noValue">
              <p class="mb-0" *ngFor="let role of dataItem?.User?.Roles">{{role?.name}}</p>
            </ng-container>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="ACTION" title="Action" [width]="10" [sortable]="false" [headerClass]="'nocursor'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button class="actions" kendoButton themeColor="error" fillMode="link"
              *ngIf="investigationRestrictionsDelete?.enabled" [iconClass]="'fa-solid fa-trash'" [size]="'large'"
              (click)="removeInvestigationRestriction(dataItem)"></button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </section>
  </div>
  <ng-template #noValue>-</ng-template>
