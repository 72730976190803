<section>
  <kendo-card width="100%" class="mb-16">
    <kendo-card-header style="padding: 5px 16px;">
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
            <kendo-gridlayout-item class="select-All mb-0">
                <kendo-formfield>
                    <div class="k-checkbox-wrap select-All">
                        <input
                            #selectAllChecked
                            type="checkbox"
                            kendoCheckBox
                            [(ngModel)]="selectAllEntries"
                            [ngModelOptions]="{standalone: true}"
                        />
                        <kendo-label
                        [for]="selectAllChecked"
                        class="k-checkbox-label font-weight-600 checkbox-margin ml-0"
                        style="margin-bottom: 0"
                        text="Select All"
                        ></kendo-label>
                    </div>
                </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="report-total mb-0">
                <div>
                    <span class="total-expenses">Total Hours: {{(overallTimeTotal?.hours || 0) | number: '1.2-2'}}</span> <br/>
                    <span class="total-selected">Total Selected: {{((getSelectedTotal?.totalPaid || 0) + (getSelectedTotal?.totalUnpaid || 0)) | number: '1.2-2'}}</span> <br />
                    <span><a href="javascript:void(0)" (click)="viewTotalDetailsOpen(true)">View Total Details</a></span>
                </div>
            </kendo-gridlayout-item>
        </kendo-gridlayout>
    </kendo-card-header>
  </kendo-card>
  <p *ngIf="!users?.length" class="text-center">No records available.</p>
  <app-time-kendo-card *ngFor="let user of users" [user]="user" [payPeriod]="payPeriod" [selectAllEntries]="selectAllEntries" (payTimeEntries)="toggleSelected($event)"></app-time-kendo-card>
  <kendo-datapager
  [pageSize]="pageOptions?.limit"
  [skip]="pageOptions?.offset"
  [total]="(dataSource?.pageInfo$ | async)?.totalCount"
  (pageChange)="pageChange($event);selectResetEvent();"
  class="custom-paginate pl-15 pr-15"
  >
  <ng-template kendoDataPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <div class="k-pager-numbers-wrap">
      <kendo-datapager-prev-buttons></kendo-datapager-prev-buttons>
      <kendo-datapager-numeric-buttons [buttonCount]="5"></kendo-datapager-numeric-buttons>
      <kendo-datapager-next-buttons></kendo-datapager-next-buttons>
    </div>
    <kendo-datapager-info></kendo-datapager-info>
    <kendo-datapager-page-sizes [pageSizes]="[5, 10]"></kendo-datapager-page-sizes>
  </ng-template>
</kendo-datapager>
<!-- View Details -->
<kendo-dialog #totalDetails *ngIf="showViewTotalDetails" [minWidth]="600" [width]="600"
[dialogRef]="totalDetails" (appClickOutside)="viewTotalDetailsOpen(false);">
  <kendo-dialog-titlebar (close)="viewTotalDetailsOpen(false)">
    <div class="modal-title">
      <span>Total Details</span>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body total-details">
    <kendo-gridlayout [rows]="['auto']" [cols]="['100%']" [gap]="{rows: 5, cols: 16}"
      [cols]="['repeat(1,minmax(0,1fr))']" class="total mt-8">
      <kendo-gridlayout-item class="text-right mb-8 mr-16 mt-8">
        <h6 class="mb-0"><small class="mr-8">Total</small><span>{{overallTimeTotal?.hours}}</span></h6>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <hr />

    <kendo-gridlayout [rows]="['auto']" [cols]="['100%']" [gap]="{rows: 12, cols: 16}"
      [cols]="['repeat(2,minmax(0,1fr))']" class="mt-8">
      <ng-container *ngFor="let timeTotal of timeTotals">
        <kendo-gridlayout-item class="mb-0 ml-16">
          <h6 class="mb-0"><small [innerHTML]="timeTotal.name | timeNameShort"></small></h6>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="text-right mb-0 mr-16">
          <h6 class="mb-0" [innerHTML]="timeTotal.hours"></h6>
        </kendo-gridlayout-item>
      </ng-container>
    </kendo-gridlayout>
  </div>
</kendo-dialog>
</section>
