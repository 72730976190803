<div [dialogRef]="dialog" (appClickOutside)="dialog.close()">
  <kendo-dialog-titlebar (close)="dialog.close()">
    <ng-container *ngIf="investigation; else unlinked">
      <div class="modal-title">
        <span>{{ !data?.disableEdit ? "Send" : "Sent" }} to Quickbooks</span>
        <div class="modal-subtitle">Investigation# {{ investigation?.nefcoNumber || investigation?.id }}</div>
      </div>
    </ng-container>
    <ng-template #unlinked>
      <div class="modal-title">
        <span>Unlinked Entry</span>
      </div>
    </ng-template>
  </kendo-dialog-titlebar>
  <section>
    <kendo-card [width]="'100%'">
      <kendo-card-body>
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 0, cols: 16}" [cols]="['repeat(6,minmax(0,1fr))']">
          <kendo-gridlayout-item class="mb-0">
              <h2>Invoice Number</h2>
              <p>
                <kendo-formfield *ngIf="!data?.disableEdit; else viewOnly_InvoiceNum;">
                  <div>
                    <kendo-textbox
                      id="invoiceNumber"
                      [(ngModel)]="invoiceNumber"
                      placeholder="Invoice Number"
                      fillMode="outline"
                      [required]="true"
                      [maxlength]="11"
                      [clearButton]="false">
                    </kendo-textbox>
                  </div>
                </kendo-formfield>
                <ng-template #viewOnly_InvoiceNum><p>{{ data?.invoice?.invoiceNumber }}</p></ng-template>
              </p>
          </kendo-gridlayout-item>

          <ng-container>
          <kendo-gridlayout-item [colSpan]="5" style="border-left: 1px solid grey;" class="pl-16 mb-0">
            <h2>Special Billing Instructions:</h2>
            <p>
              {{ investigation?.Detail?.specialInstructions || '-' }}
            </p>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item class="mb-0">
              <h2>Client</h2>
              <p class="mb-0">{{ investigation?.Client?.firstName }} {{ investigation?.Client?.lastName }}</p>
              <p class="mb-0">{{ investigation?.ClientBranch?.Company?.name }}</p>
              <p>{{ investigation?.ClientBranch?.name }}</p>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item [colSpan]="5"  style="border-left: 1px solid grey;" class="pl-16 mb-0">
              <h2>Evidence Billing:</h2>
              <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 0, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
                <kendo-gridlayout-item class="mb-0">
                  <h6>
                    Bill Rate:
                  </h6>
                  <p [innerHTML]="investigation?.EvidenceBilling?.rate"></p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <h6>
                    Billing Schedule:
                  </h6>
                  <p [innerHTML]="investigation?.EvidenceBilling?.BillingFrequency?.name"></p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <h6>
                    Billing Date:
                  </h6>
                  <p *ngIf="investigation?.EvidenceBilling?.startDate else noValue" [innerHTML]="investigation?.EvidenceBilling?.startDate | customDate: 'MM-dd-yyyy'"></p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <h6>
                    Billable:
                  </h6>
                  <p>{{investigation?.EvidenceBilling?.billable ? 'Yes' : 'No'}}</p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <h6>
                    Notes:
                  </h6>
                  <p *ngIf="investigation?.EvidenceBilling?.notes else noValue" [innerHTML]="investigation?.EvidenceBilling?.notes"></p>
                </kendo-gridlayout-item>
              </kendo-gridlayout>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item class="mb-0">
            <h2>Bill To</h2>
            <ng-container *ngIf="!data?.invoice; else invoiceBillTo">
              <p class="mb-0">{{ investigation?.BillTo?.firstName }} {{ investigation?.BillTo?.lastName }}</p>
              <p class="mb-0" *ngIf="investigation?.BillToBranch?.Company?.name" [innerHTML]="investigation?.BillToBranch?.Company?.name"></p>
              <p *ngIf="investigation?.BillToBranch?.BillingAddress; else billToAddress" [innerHTML]="investigation?.BillToBranch?.BillingAddress | addressFormat"></p>
              <ng-template #billToAddress>
                <p *ngIf="investigation?.BillTo?.BillingAddress" [innerHTML]="investigation?.BillTo?.BillingAddress | addressFormat"></p>
              </ng-template>
            </ng-container>
            <ng-template #invoiceBillTo>
              <p class="mb-0">{{ data.invoice?.BillTo?.firstName }} {{ data.invoice?.BillTo?.lastName }}</p>
              <p class="mb-0" *ngIf="data?.invoice?.BillToBranch?.Company?.name" [innerHTML]="data?.invoice?.BillToBranch?.Company?.name"></p>
              <p *ngIf="data?.invoice?.BillToBranch?.BillingAddress; else billToAddress" [innerHTML]="data?.invoice?.BillToBranch?.BillingAddress | addressFormat"></p>
              <ng-template #billToAddress>
                <p *ngIf="data?.invoice?.BillTo?.BillingAddress" [innerHTML]="data?.invoice?.BillTo?.BillingAddress | addressFormat"></p>
              </ng-template>
            </ng-template>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item [colSpan]="5"  style="border-left: 1px solid grey;" class="pl-16 mb-0">
              <h2>Billing Rate Overides:</h2>
              <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 0, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']"
                *ngIf="investigation?.Company?.BillingRules?.length else noValue">
                <ng-container *ngFor="let rule of investigation?.Company?.BillingRules">
                  <kendo-gridlayout-item class="mb-0">
                    <h6>
                      Category:
                    </h6>
                    <p [innerHTML]="rule?.ExpenseItem?.id ? 'Expense' : 'Hours'"></p>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item class="mb-0">
                    <h6>
                      Type:
                    </h6>
                    <p [innerHTML]="rule?.ExpenseItem?.id ? rule?.ExpenseItem?.name : rule?.TimeEntryType?.name"></p>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item class="mb-0">
                    <h6>
                      Rate:
                    </h6>
                    <p> ${{ rule.value }} /hr</p>
                  </kendo-gridlayout-item>
                </ng-container>
              </kendo-gridlayout>
            </kendo-gridlayout-item>

            <!-- Empty for space -->
            <kendo-gridlayout-item class="mb-0">
              <h2>NEFCO Office</h2>
              <p class="mb-0">{{investigation?.NefcoOffice?.name}}</p>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item [colSpan]="5"  style="border-left: 1px solid grey;" class="pl-16 mb-0">
              <h2>Flat Rate for Standard Investigations:</h2>
              <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 0, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']"
                *ngIf="investigation?.Company?.FlatRates?.length else noValue">
                <ng-container *ngFor="let rate of investigation?.Company?.FlatRates">
                  <kendo-gridlayout-item class="mb-0">
                    <h6>
                      Risk Type:
                    </h6>
                    <p [innerHtml]="rate.Risk.name"></p>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item class="mb-0">
                    <h6>
                      Rate:
                    </h6>
                    <p> ${{ rate.value }} </p>
                  </kendo-gridlayout-item>
                </ng-container>
              </kendo-gridlayout>
            </kendo-gridlayout-item>

            <!-- Empty for space -->
            <kendo-gridlayout-item class="mb-0">
            </kendo-gridlayout-item>

            <kendo-gridlayout-item [colSpan]="5"  style="border-left: 1px solid grey;" class="pl-16 mb-0">
              <h2>Budget Specified:</h2>
              <p *ngIf="investigation?.Detail?.budgetAmount else noValue"> {{ investigation?.Detail?.budgetAmount | currency }}</p>
            </kendo-gridlayout-item>

            <!-- Empty for space -->
            <kendo-gridlayout-item class="mb-0">
            </kendo-gridlayout-item>

            <kendo-gridlayout-item [colSpan]="5"  style="border-left: 1px solid grey;" class="pl-16 mb-0">
              <h2>Investigation Billing Instruction:</h2>
              <p class="mb-0" *ngIf="investigation?.billingInstructions else noValue"> {{ investigation.billingInstructions }} </p>
              <p  *ngIf="investigation?.Detail?.billingInstructionNotes else noValue"> {{ investigation?.Detail?.billingInstructionNotes }} </p>
            </kendo-gridlayout-item>
            </ng-container>
        </kendo-gridlayout>
      </kendo-card-body>
    </kendo-card>

    <div class="mt-16 mb-16" *ngIf="!data?.disableEdit && !this.data.invoice">
      <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" [disabled]="selectedLines.length < 2 || !sameType" (click)="combine()">Combine Selected</kendo-button>
      <kendo-button kendoButton themeColor="error" fillMode="solid" size="medium" [disabled]="!selectedLines.length" class="ml-16" (click)="deleteLines()">Delete Selected</kendo-button>
    </div>

    <kendo-grid
      #dataTable
      class="quickbook-invoice-list td-16-8 mt-16 one-px-border"
      [data]="invoiceLines"
      [pageable]="false"
      [sortable]="false"
      [navigatable]="false"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
    >
      <kendo-grid-column
        field="Select"
        title="#"
        [width]="8"
        [sortable]="false"
        [headerClass]="'nocursor'"
        *ngIf="!data.disableEdit && !data.invoice"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium"
              iconClass="fa-solid fa-grip-lines" class="mr-16 example-drag-cursor" cdkDrag cdkDragLockAxis="y"></kendo-button>
            <div class="k-checkbox-wrap">
              <input
                kendoCheckBox
                name="select"
                type="checkbox"
                [(ngModel)]="dataItem.selected" />
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
          field="Date"
          title="Date"
          [width]="14"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="kendo-field mb-0">
              <kendo-formfield *ngIf="!data.disableEdit; else viewOnly_date;">
                <kendo-datepicker
                    id="expenseDate"
                    name="expenseDate"
                    calendarType="classic"
                    [fillMode]="'outline'"
                    placeholder="Expense Date"
                    format="MM/dd/yyyy"
                    [(ngModel)]="dataItem.createdAt"
                    formatPlaceholder="formatPattern"
                    #expenseDate="ngModel"
                ></kendo-datepicker>
              </kendo-formfield>
              <ng-template #viewOnly_date><span *ngIf="dataItem.createdAt" [innerHtml]="dataItem.createdAt | customDate: 'MM/dd/yyyy'"></span></ng-template>
            </div>
          </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="Staff"
        title="Staff"
        [width]="12"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="">{{dataItem?.staffNames || '-'}}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="Description"
        title="Description"
        [width]="14"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="kendo-field mb-0">
            <kendo-formfield *ngIf="!data?.disableEdit; else viewOnly_desc;">
              <kendo-textarea #descriptionField [(ngModel)]="dataItem.description" name="description"
                placeholder="Enter description" fillMode="outline" class="min-height-96" resizable="both"
                #description="ngModel" appNoWhiteSpace maxlength="4000">
              </kendo-textarea>
            </kendo-formfield>
            <ng-template #viewOnly_desc><span>{{dataItem.description}}</span></ng-template>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="Qty"
        title="Qty"
        [width]="10"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="kendo-field mb-0">
            <kendo-formfield *ngIf="!data?.disableEdit; else viewOnly_qty;">
              <div class="numeric-text-suffix">
                  <kendo-numerictextbox placeholder="Enter Qty" name="qty" fillMode="outline"
                  [autoCorrect]="true" [(ngModel)]="dataItem.quantity" [min]="0">
                  </kendo-numerictextbox>
              </div>
            </kendo-formfield>
            <ng-template #viewOnly_qty><span>{{dataItem.quantity}}</span></ng-template>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="Type"
        title="Type"
        [width]="10"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="kendo-field mb-0">
            <kendo-formfield *ngIf="!data?.disableEdit; else viewOnly_type;">
              <kendo-dropdownlist #category
                [data]="[{val: formExpenseType.MILEAGE, text: 'Mileage'}, {val: formExpenseType.EXPENSE, text: 'Expense'}, {val: formExpenseType.HOURS, text: 'Hours'}]"
                textField="text" valueField="val" [valuePrimitive]="true"
                [(ngModel)]="dataItem.ExpenseType.name" fillMode="outline" [filterable]="false"
                [popupSettings]="{appendTo: 'root', animate: false, width: 250 }">
                <ng-template kendoDropDownListValueTemplate let-dataItemDropDown>
                  <span class="not-placeholder"> {{ dataItemDropDown?.text }} </span>
                </ng-template>
              </kendo-dropdownlist>
            </kendo-formfield>
            <ng-template #viewOnly_type>{{dataItem?.ExpenseType?.name || dataItem?.expenseType}}</ng-template>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="QbAcct"
        title="Qb Acct"
        [width]="10"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="kendo-field mb-0">
            <kendo-formfield *ngIf="!data?.disableEdit; else viewOnly_qbAccount;">
              <kendo-dropdownlist #qbAcct
                [data]="qbAccounts | keyvalue"
                textField="value" valueField="value" [valuePrimitive]="true"
                [(ngModel)]="dataItem.qbAccount" fillMode="outline" [filterable]="false"
                [popupSettings]="{appendTo: 'root', animate: false, width: 250 }">
                <ng-template kendoDropDownListValueTemplate let-dataItemDropDown>
                  <span class="not-placeholder"> {{ dataItemDropDown?.value }} </span>
                </ng-template>
              </kendo-dropdownlist>
            </kendo-formfield>
            <ng-template #viewOnly_qbAccount>{{dataItem?.qbAccount}}</ng-template>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="Rate"
        title="Rate"
        [width]="10"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="kendo-field mb-0">
            <kendo-formfield *ngIf="!data?.disableEdit; else viewOnly_rate;">
              <div class="numeric-text-suffix">
                  <kendo-numerictextbox placeholder="Enter Rate" name="rate" fillMode="outline"
                  [autoCorrect]="false" [(ngModel)]="dataItem.rate">
                  </kendo-numerictextbox>
              </div>
            </kendo-formfield>
            <ng-template #viewOnly_rate>{{dataItem?.rate}}</ng-template>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="Total"
        title="Total"
        [width]="8"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{dataItem.quantity * dataItem.rate | currency}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate>
          <span style="margin-left: 4px;">{{total | currency}}</span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>

    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 0, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']" class="mt-16">
      <kendo-gridlayout-item>
        <kendo-formfield *ngIf="!data?.disableEdit; else viewOnly_details;">
          <kendo-textarea [(ngModel)]="details" name="details" placeholder="Invoice detail / instructions ..."
            fillMode="outline" class="min-height-96" resizable="both" appNoWhiteSpace>
          </kendo-textarea>
        </kendo-formfield>
        <ng-template #viewOnly_details>
          <h2>Invoice Detail / Instructions</h2>
          <p>{{ data?.invoice?.details || '-' }}</p>
        </ng-template>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </section>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="dialog.close()">
      {{ !data?.disableEdit ? "Cancel" : "Close" }}
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" *ngIf="!data.disableEdit"
      [disabled]="!investigation?.BillTo?.firstName || !(total > 0)" (click)="send()">
      {{ investigation?.id ? "Send" : "Save" }}
    </button>
  </kendo-dialog-actions>
</div>

<ng-template #noValue>
  <p>-</p>
</ng-template>
