<section>
  <h4 class="page-title">{{ nefcoNumber ? "Investigation #" + nefcoNumber : "Access Restricted" }} </h4>
  <div class="content-wrapper p-0">
    <div class="data-block" [ngClass]="{'width-400': nefcoNumber}">
      <ng-container *ngIf="!nefcoNumber; else invDescription">
        <h2>Access Restricted</h2>
        <p>You do not have access to this page. <br /> Please contact your NEFCO administrator for more information.</p>
        <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="goToDashboard()">Go Back
          to
          Dashboard</kendo-button>
      </ng-container>
      <ng-template #invDescription>
        <h2>Access Restricted</h2>
        <p>You are not able to view details on Investigation #{{ nefcoNumber }}. Please contact a NEFCO admin for
          assistance.</p>
      </ng-template>
    </div>
  </div>
</section>
