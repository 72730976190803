<div [dialogRef]="dialog" (appClickOutside)="dialog.close(false)">
    <kendo-dialog-titlebar (close)="dialog.close(false)">
        <div class="modal-title">
            <span>Edit NEFCO Office</span>
            <div class="modal-subtitle">
                Investigation ID: {{investigation?.nefcoNumber || 'Unassigned'}}
            </div>
        </div>
    </kendo-dialog-titlebar>
    <section>
        <kendo-gridlayout class="mt-16" [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
            <kendo-gridlayout-item>
                <kendo-label text="Select a NEFCO Office."></kendo-label>
                <br />
                <br />
                <app-nefco-office-select [(ngModel)]="nefcoOfficeId"></app-nefco-office-select>
            </kendo-gridlayout-item>
        </kendo-gridlayout>
    </section>
    <kendo-dialog-actions layout="end">
        <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="dialog.close(false)">
            Cancel
        </button>
        <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="save()">
            Save
        </button>
    </kendo-dialog-actions>
</div>